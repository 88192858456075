import React from 'react';
import LinkerCarouselComponent from '../index';
import { LTAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class LinkerCarousel extends React.Component {
  render() {
    return <LinkerCarouselComponent {...this.props} urlToUse={LTAG_PAGE_LINK} />;
  }
}

export default LinkerCarousel;
