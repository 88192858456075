import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

/**
 * @class Component that renders a list of items , each with title and description text
 * @param {object} props Object containing data needed for the component.
 *
 */

class History extends React.Component {
  render() {
    const { fields } = this.props;

    if (!fields) {
      return <div className='History componentContainer' />;
    }

    const BackgroundColor = fields.BackgroundColor,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      cssClass = classNames('History componentContainer', {
        WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
      });

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className={cssClass}>
          <div className='History-Container'>
            <Placeholder name='jss-history-item' rendering={this.props.rendering} />
          </div>
        </div>
      </div>
    );
  }
}

export default History;
