import React from 'react';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import CloseIcon from '../../Common/Icons/CloseIcon';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import ArrowRight from '../../Common/Icons/ArrowRight';
import ZoomIn from '../../Common/Icons/ZoomIn';
import ZoomOut from '../../Common/Icons/ZoomOut';
import PlayIcon from '../../Common/Icons/PlayIcon';
import Icon360 from '../../Common/Icons/Icon360';

/**
 *  Component that renders a video in a lightbox
 */

class ProductsLightBox extends React.PureComponent {
  state = {
    zoomLevel: 1,
    zoomIncrementValue: 0.25,
    defaultZoomLevel: 1,
    maxZoomLevel: 3,
    imgPosition: { x: 0, y: 0 }
  };

  componentDidUpdate(prevProps, prevState) {
    const { src } = this.props;
    if (src !== prevProps.src) {
      this.setState({ zoomLevel: 1 });
    }
    if (this.state.zoomLevel !== prevState.zoomLevel) {
      this.setImageStyles(prevState.zoomLevel);
    }
  }

  setImageStyles = oldZoomLevel => {
    const zoomImgElement = document.getElementById('zoom-img');
    if (zoomImgElement) {
      const oldImgWidth = zoomImgElement.getBoundingClientRect().width,
        oldImgHeight = zoomImgElement.getBoundingClientRect().height,
        oldLeft = parseFloat(zoomImgElement.style.left, 10) || 0,
        oldTop = parseFloat(zoomImgElement.style.top, 10) || 0,
        { zoomLevel, defaultZoomLevel } = this.state,
        new_width = zoomLevel > oldZoomLevel ? oldImgWidth * 1.25 : oldImgWidth * 0.75,
        new_heigth = zoomLevel > oldZoomLevel ? oldImgHeight * 1.25 : oldImgHeight * 0.75;

      let newTop = 0,
        newLeft = 0;
      if (zoomLevel !== defaultZoomLevel) {
        newLeft = oldLeft - (new_width - oldImgWidth) / 2;
        newTop = oldTop - (new_heigth - oldImgHeight) / 2;
      }

      zoomImgElement.style.left = newLeft + 'px';
      zoomImgElement.style.top = newTop + 'px';
      zoomImgElement.style.width = new_width + 'px';
      zoomImgElement.style.height = new_heigth + 'px';
    }
  };

  zoomIncrement = () => {
    const { zoomLevel, zoomIncrementValue, maxZoomLevel } = this.state;
    let newZoomLevel = zoomLevel + zoomIncrementValue;
    newZoomLevel = newZoomLevel >= maxZoomLevel ? maxZoomLevel : newZoomLevel;
    this.setState({ zoomLevel: newZoomLevel });
  };

  zoomDecrement = () => {
    const { zoomLevel, zoomIncrementValue, defaultZoomLevel } = this.state;
    let newZoomLevel = zoomLevel - zoomIncrementValue;
    newZoomLevel = newZoomLevel <= defaultZoomLevel ? defaultZoomLevel : newZoomLevel;
    this.setState({ zoomLevel: newZoomLevel });
  };

  startDragging = e => {
    const zoomContainerElement = document.getElementById('zoom-container'),
      zoomImgElement = document.getElementById('zoom-img');
    const { zoomLevel, defaultZoomLevel } = this.state;
    if (zoomLevel <= defaultZoomLevel) {
      return;
    }

    const x_cursor = e.changedTouches ? e.changedTouches[0].clientX : e.clientX,
      y_cursor = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

    this.setState({
      imgPosition: {
        x: x_cursor - zoomImgElement.offsetLeft,
        y: y_cursor - zoomImgElement.offsetTop
      }
    });

    zoomContainerElement.addEventListener('mousemove', this.whileDrag);
    zoomContainerElement.addEventListener('touchmove', this.whileDrag);
  };

  stopDragging = () => {
    const zoomContainerElement = document.getElementById('zoom-container');
    if (zoomContainerElement) {
      zoomContainerElement.removeEventListener('mousemove', this.whileDrag);
      zoomContainerElement.removeEventListener('touchmove', this.whileDrag);
    }
  };

  whileDrag = e => {
    const { imgPosition } = this.state,
      zoomImgElement = document.getElementById('zoom-img');
    if (zoomImgElement) {
      zoomImgElement.style.left = e.pageX - imgPosition.x + 'px';
      zoomImgElement.style.top = e.pageY - imgPosition.y + 'px';
    }
  };

  getImage() {
    const imageSrc = this.props.src;
    if (!imageSrc) {
      return null;
    }
    const { zoomLevel, defaultZoomLevel, maxZoomLevel } = this.state;
    const zoomInClasses = classNames('ProductsLightBox-ContentWrapper-MediaWrapper-Zoom-In', {
        'zoom-opacity': zoomLevel === maxZoomLevel
      }),
      zoomOutClasses = classNames('ProductsLightBox-ContentWrapper-MediaWrapper-Zoom-Out', {
        'zoom-opacity': zoomLevel === defaultZoomLevel
      });
    return (
      <React.Fragment>
        <div
          id='zoom-container'
          className='ProductsLightBox-ContentWrapper-MediaWrapper-Image'
          onTouchStart={this.startDragging}
          onMouseDown={this.startDragging}
          onMouseUp={this.stopDragging}
          onMouseOut={this.stopDragging}
          onBlur={this.stopDragging}
          onTouchEnd={this.stopDragging}
          role='button'
          tabIndex='0'
        >
          <img
            key={imageSrc}
            id='zoom-img'
            draggable={false}
            sizes='(min-width: 320px) and (max-width: 767px) 727px,(min-width: 768px) and (max-width: 959px) 919px,1180px'
            srcSet={`${imageSrc}?imwidth=320 320w, ${imageSrc}?imwidth=768 768w, ${imageSrc}?imwidth=960 960w, ${imageSrc}?imwidth=1440 1440w`}
            onDragStart={() => {
              return false;
            }}
            src={imageSrc}
            alt={this.props.title}
            loading='lazy'
          />
        </div>
        <div className='ProductsLightBox-ContentWrapper-MediaWrapper-Zoom'>
          <div
            id='zoomin'
            className={zoomInClasses}
            onClick={this.zoomIncrement}
            onKeyDown={e => {
              if (e.target === 13) this.zoomIncrement();
            }}
            role='button'
            tabIndex='0'
          >
            <ZoomIn />
          </div>
          <div
            id='zoomout'
            className={zoomOutClasses}
            onClick={this.zoomDecrement}
            onKeyDown={e => {
              if (e.target === 13) this.zoomDecrement();
            }}
            role='button'
            tabIndex='0'
          >
            <ZoomOut />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getVideo() {
    return (
      <div className='ProductsLightBox-ContentWrapper-MediaWrapper-Video'>
        <video key={this.props.src} width='100%' height='100%' controls autoPlay>
          <source src={this.props.src} type='video/mp4' />
          <track src='' kind='captions' lang='en' label='english_captions' />
          Your browser does not support HTML5 video.
        </video>
      </div>
    );
  }
  get3DObject() {
    return (
      <div className='ProductsLightBox-ContentWrapper-MediaWrapper-Image'>
        <model-viewer src={this.props.src} auto-rotate camera-controls alt='A 3D Object' />
      </div>
    );
  }

  getIcon = itemType => {
    if (itemType === 'video/mp4') {
      return <PlayIcon onClick={this.props.mainImageClicked} />;
    } else if (itemType === '3Dobject') {
      return <Icon360 onClick={this.props.mainImageClicked} />;
    }
  };

  getLightboxThumbnails = () => {
    return this.props.allMedias.map((media, index) => {
      const cssClass = classNames('ProductsLightBox-ContentWrapper-Navigation-ImageWrapper', {
        'ProductsLightBox-ContentWrapper-Navigation-ImageWrapper noOpacity':
          media.url === this.props.src && media.src !== null
      });
      return (
        <div
          key={'nav' + index}
          className={cssClass}
          onClick={() => this.props.navElementClicked(index, media)}
          onKeyPress={() => this.props.navElementClicked(index, media)}
          onMouseEnter={() => this.props.navElementClicked(index, media)}
          role='button'
          tabIndex='0'
        >
          {this.getIcon(media.type)}
          {media.thumbnail ? <img src={media.thumbnail + '?imwidth=100'} alt={media.type} loading='lazy' /> : null}
        </div>
      );
    });
  };

  getLightboxDots() {
    return this.props.allMedias.map((media, index) => {
      const cssClass = classNames('ProductsLightBox-ContentWrapper-Navigation-DotWrapper-Dot', {
        'ProductsLightBox-ContentWrapper-Navigation-DotWrapper-Dot active':
          media.url === this.props.src && media.src !== null
      });
      return (
        <div key={'dot' + index} className='ProductsLightBox-ContentWrapper-Navigation-DotWrapper'>
          <div
            onClick={() => this.props.navElementClicked(index, media)}
            onKeyPress={() => this.props.navElementClicked(index, media)}
            role='button'
            tabIndex='0'
            className={cssClass}
          />
        </div>
      );
    });
  }

  getContent = () => {
    switch (this.props.mediaType) {
      case 'image/jpeg':
        return this.getImage();
      case 'video/mp4':
        return this.getVideo();
      case '3Dobject':
        return this.get3DObject();
      default:
        return this.getImage();
    }
  };
  render() {
    const cssClass = classNames('ProductsLightBox', {
      'ProductsLightBox ProductsLightBox-BlackBackground': this.props.mediaType === 'video/mp4'
    });
    return (
      <div className={cssClass}>
        <div className='ProductsLightBox-ContentWrapper'>
          <div className='ProductsLightBox-ContentWrapper-Headline'>
            {this.props.title}
            <div
              className='ProductsLightBox-ContentWrapper-Headline-Close'
              onClick={this.props.onClick}
              onKeyDown={e => {
                if (e.keyCode === 13) this.props.onClick();
              }}
              role='button'
              tabIndex='0'
            >
              <CloseIcon />
            </div>
          </div>
          <div className='ProductsLightBox-ContentWrapper-MediaWrapper componentContainer'>
            <div
              className='ProductsLightBox-ContentWrapper-MediaWrapper-ArrowLeft'
              onClick={this.props.leftArrowClicked}
              onKeyDown={e => {
                if (e.keyCode === 13) this.props.leftArrowClicked();
              }}
              role='button'
              tabIndex='0'
            >
              <ArrowLeft />
            </div>
            {this.getContent()}
            <div
              className='ProductsLightBox-ContentWrapper-MediaWrapper-ArrowRight'
              onClick={this.props.rightArrowClicked}
              onKeyDown={e => {
                if (e.keyCode === 13) this.props.rightArrowClicked();
              }}
              role='button'
              tabIndex='0'
            >
              <ArrowRight />
            </div>
          </div>
          <div className='ProductsLightBox-ContentWrapper-Navigation'>
            <Visible xs sm>
              {this.getLightboxDots()}
            </Visible>
            <Visible md lg xl>
              {this.getLightboxThumbnails()}
            </Visible>
          </div>
        </div>
      </div>
    );
  }
}
export default ProductsLightBox;
