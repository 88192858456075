import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../../../Common/CookieSettings/CookieConstants';
import { ShopProduct, ProductData, ShopProductVariant } from '../types';
import { priceToNumber } from './ecomAnalyticsCommon';

const getViewItemEvent = (product: ShopProduct, variant: ShopProductVariant, productData?: ProductData) => {
  const currency = variant.price.currencyCode;
  const value = priceToNumber(variant.price.amount);

  return {
    event: 'view_item',
    ecommerce: {
      currency,
      value,
      affiliation: Cookies.get(COUNTRY_CODE)?.toLowerCase() ?? 'de',
      items: [
        {
          // required fields
          item_id: variant.id,

          // recommended fields
          item_name: productData?.name || product.title,
          item_brand: productData?.brand ?? null,
          item_category: productData?.category ?? null,
          item_category2: productData?.subcategory ?? null,
          price: priceToNumber(variant.price.amount),
          quantity: 1,

          // custom fields (up to 27)
          sku: variant.sku,
          compareAtPrice: variant.compareAtPrice?.amount ? priceToNumber(variant.compareAtPrice.amount) : null,
          condition: product.condition?.value ?? null
        }
      ]
    }
  };
};

export const pushViewItemEvent = (
  product: ShopProduct,
  variant: ShopProductVariant,
  productData?: ProductData
): void => {
  const event = getViewItemEvent(product, variant, productData);

  window.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer?.push(event);
};
