import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList, { SearchResultListFields } from '../../SearchResultList';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { getCoveoSitecoreSource } from '../../../../AppGlobals';
import { useCoveoQuery } from '../../CoveoQuery';
import { CoveoContext } from '../../context/CoveoContext';
import CategorFacet from '../../CategoryFacet';
import FacetsModal from '../../FacetsModal';
import FloatingFilterToggle from '../../FloatingFilterToggle';
import FacetManager from '../../FacetManager';

export const StoriesListerComponent = () => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, coveoSiteName, locale }
  } = useContext(SearchEngineContext);
  const { buildFacetController, querySummaryController } = useCoveoQuery({
    engine,
    aq: `@source=="${getCoveoSitecoreSource()}" AND @z95xlanguage=="${locale}"`,
    cq: `@site=="${coveoSiteName}" OR @storyz32xbrands="${coveoSiteName}"`,
    fieldsToInclude: SearchResultListFields
  });
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine]);

  const facets = [
    {
      field: 'category',
      label: i18n.t('SEARCH | Facets category')
    },
    {
      field: 'region',
      label: i18n.t('SEARCH | Facets region')
    }
  ];

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <div className='SearchResults__Main'>
        <FloatingFilterToggle />
        <FacetsModal hideOnDesktop={true} foldable={false} />
        <FacetManager
          staticOnDesktop={true}
          engine={engine}
          hasResults={(state.hasResults || state.firstSearchExecuted) && !state.isLoading}
        >
          {facets.map(facet => {
            const facetController = buildFacetController(facet.field);

            return <CategorFacet key={facet.field} controller={facetController} title={facet.label} id={facet.field} />;
          })}
        </FacetManager>
        {state.hasResults && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList engine={engine} query={state.query} siteName={siteName} language={locale} />
      </div>
    </div>
  );
};

export default StoriesListerComponent;
