import { Result, SearchEngine, buildInteractiveResult } from '@coveo/headless';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export type LinkOrigin = 'lister' | 'other';

export type ResultLinkProps = {
  href: string;
  text?: string;
  icon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  origin?: LinkOrigin;
  engine: SearchEngine;
  result: Result;
};

export const ResultLink = ({
  className,
  href,
  text,
  icon,
  children,
  origin,
  engine,
  result,
  ...rest
}: ResultLinkProps) => {
  const [location, setLocation] = useState('');

  const interactiveResultController = buildInteractiveResult(engine, {
    options: { result }
  });

  useEffect(() => {
    setLocation(`${window.location.pathname}${window.location.search}${window.location.hash}`);
  }, [window.location.hash]);

  return (
    <Link
      className={`ResultLink ${className}`}
      title={text}
      to={{
        pathname: href,
        state: { from: location, origin }
      }}
      onClick={() => interactiveResultController.select()}
      {...rest}
    >
      {children ? (
        children
      ) : (
        <>
          {text}
          {icon}
        </>
      )}
    </Link>
  );
};

export default ResultLink;
