import React, { ComponentType, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Visible } from 'react-grid-system';
import PenIcon from '../../../Common/Icons/PenIcon';
import ConfirmationDialog from '../../../ConfirmationDialog';
import type { TextField } from '../../../../utils/types';
import type { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { getDeleteEndpoint } from '../../../../AppGlobals';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { logOutRedirect } from '../../../../utils/Utils';

type WithRouterHistory = {
  history: RouteComponentProps['history'];
};

type AccountInformationsProps = WithRouterHistory & {
  fields: {
    data: {
      item: {
        accountInformationsLabel: TextField;
        emailLabel: TextField;
        passwordLabel: TextField;
        deleteAccountLabel: TextField;
        editEmailLink: { jss: LinkField };
        editPasswordLink: { jss: LinkField };
        popupOkLabel: TextField;
        popupCloseLabel: TextField;
        popupDeleteLabel: TextField;
        popupRequestDeletionLabel: TextField;
        popupCancelLabel: TextField;
        editLabel: TextField;
        accountDeletionDenialText: TextField;
        accountDeletionDenialTitle: TextField;
        accountDeletionErrorText: TextField;
        accountDeletionErrorTitle: TextField;
        accountDeletionSuccessText: TextField;
        accountDeletionSuccessTitle: TextField;
        accountDeletionWarningText: TextField;
        accountDeletionWarningTitle: TextField;
        accountDeletionRequestLink: { jss: LinkField };
      };
      userQuery: any;
    };
  };
};

const AccountInformations: React.FC<AccountInformationsProps> = props => {
  const [visibleModal, setVisibleModal] = useState<'' | 'Approval' | 'Successful' | 'Error' | 'Conflict'>('');

  const deleteAccount = () => {
    requestAccountDeletion();
  };

  const closeModal = () => {
    setVisibleModal('');
  };

  const requestAccountDeletion = async () => {
    let status = 0;
    try {
      const response = await fetch(getDeleteEndpoint(), {
        method: 'DELETE',
        credentials: 'include'
      });
      status = response.status;
    } catch (error) {
      console.error('DELETE error', error);
      status = 500;
    }

    if (status >= 200 && status < 300) {
      setVisibleModal('Successful');
      return 200;
    } else if (status === 409) {
      setVisibleModal('Conflict');
    } else {
      setVisibleModal('Error');
    }
  };

  const goToDeletionForm = () => {
    if (accountDeletionRequestLink?.jss?.value?.href) {
      props.history?.push(accountDeletionRequestLink.jss.value.href);
    }
  };

  const getEditButtonLink = (link: LinkField, label: TextField) => {
    if (!(link && link.value && link.value.href)) {
      return;
    }
    return (
      <div className='AccountInformations-Row-Edit'>
        <a href={link.value.href}>
          <PenIcon />
          <Text tag='div' className='AccountInformations-Row-Edit-Text' field={label} />
        </a>
      </div>
    );
  };

  const getAccountEmail = (label: any, email: any) => {
    return (
      <React.Fragment>
        <Text tag='div' className='AccountInformations-Row-Labels' field={label} />
        <div className='AccountInformations-Row-TextColumn'>{email}</div>
      </React.Fragment>
    );
  };

  const getAccountPassword = (label: any) => {
    return (
      <React.Fragment>
        <Text tag='div' className='AccountInformations-Row-Labels' field={label} />
        <div className='AccountInformations-Row-TextColumn'>
          &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
        </div>
      </React.Fragment>
    );
  };

  if (!(props.fields && props.fields.data && props.fields.data.item && props.fields.data.userQuery)) {
    return null;
  }

  const {
    item: {
      accountInformationsLabel,
      emailLabel,
      passwordLabel,
      deleteAccountLabel,
      editEmailLink,
      editPasswordLink,
      popupDeleteLabel,
      popupCancelLabel,
      popupOkLabel,
      popupCloseLabel,
      popupRequestDeletionLabel,
      editLabel,
      accountDeletionDenialText,
      accountDeletionDenialTitle,
      accountDeletionErrorText,
      accountDeletionErrorTitle,
      accountDeletionSuccessText,
      accountDeletionSuccessTitle,
      accountDeletionWarningText,
      accountDeletionWarningTitle,
      accountDeletionRequestLink
    },
    userQuery: { email }
  } = props.fields.data;
  const hasDeletionFormLink = !!accountDeletionRequestLink?.jss?.value?.href ?? false;

  return (
    <div className='AccountInformations'>
      <Text tag='h3' className='AccountInformations-Title' field={accountInformationsLabel} />

      <div className='AccountInformations-Row'>
        <Visible sm xs>
          <div>{getAccountEmail(emailLabel, email)}</div>
        </Visible>
        <Visible xl lg md>
          {getAccountEmail(emailLabel, email)}
        </Visible>
        {getEditButtonLink(editEmailLink.jss, editLabel)}
      </div>

      <div className='AccountInformations-Row'>
        <Visible sm xs>
          <div>{getAccountPassword(passwordLabel)}</div>
        </Visible>
        <Visible xl lg md>
          {getAccountPassword(passwordLabel)}
        </Visible>
        {getEditButtonLink(editPasswordLink.jss, editLabel)}
      </div>
      <div
        onClick={() => setVisibleModal('Approval')}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setVisibleModal('Approval');
          }
        }}
        role='button'
        tabIndex={0}
        aria-label='Open Delete Account Approval Overlay'
        className='AccountInformations-Delete'
      >
        <Text field={deleteAccountLabel} />
      </div>

      {/* Approval Modal */}
      {visibleModal === 'Approval' && (
        <ConfirmationDialog
          onClose={closeModal}
          onPrimaryButtonClick={deleteAccount}
          modalTitle={accountDeletionWarningTitle.value}
          primaryButtonLabel={popupDeleteLabel.value}
          secondaryButtonLabel={popupCancelLabel.value}
          onSecondaryButtonClick={closeModal}
        >
          {accountDeletionWarningText && <Text field={accountDeletionWarningText} tag='div' encode={false} />}
        </ConfirmationDialog>
      )}

      {/* Successful modal */}
      {visibleModal === 'Successful' && (
        <ConfirmationDialog
          onClose={logOutRedirect}
          onPrimaryButtonClick={logOutRedirect}
          modalTitle={accountDeletionSuccessTitle.value}
          primaryButtonLabel={popupOkLabel.value}
        >
          {accountDeletionSuccessText && <Text field={accountDeletionSuccessText} tag='div' encode={false} />}
        </ConfirmationDialog>
      )}

      {/* Conflict modal */}
      {visibleModal === 'Conflict' && (
        <ConfirmationDialog
          onClose={closeModal}
          onPrimaryButtonClick={goToDeletionForm}
          modalTitle={accountDeletionDenialTitle.value}
          primaryButtonLabel={hasDeletionFormLink ? popupRequestDeletionLabel.value : undefined}
          secondaryButtonLabel={popupCancelLabel.value}
          onSecondaryButtonClick={closeModal}
        >
          {accountDeletionDenialText && <Text field={accountDeletionDenialText} tag='div' encode={false} />}
        </ConfirmationDialog>
      )}

      {/* Error modal */}
      {visibleModal === 'Error' && (
        <ConfirmationDialog
          onClose={closeModal}
          onPrimaryButtonClick={closeModal}
          modalTitle={accountDeletionErrorTitle.value}
          primaryButtonLabel={hasDeletionFormLink ? popupCloseLabel.value : undefined}
          secondaryButtonLabel={popupRequestDeletionLabel.value}
          onSecondaryButtonClick={goToDeletionForm}
        >
          {accountDeletionErrorText && <Text field={accountDeletionErrorText} tag='div' encode={false} />}
        </ConfirmationDialog>
      )}
    </div>
  );
};

export default withRouter<
  RouteComponentProps & AccountInformationsProps,
  ComponentType<RouteComponentProps & AccountInformationsProps>
>(AccountInformations);
