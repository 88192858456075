import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getWishList, deleteWishList, setCurrentWishListIndex } from '../../../../store/wishlist/actions';
import DeleteIcon from '../../../Common/Icons/DeleteIcon';
import GeneralLink from '../../../Common/GeneralLink';
import RemoveApprovalOverlay from '../../../RemoveApprovalOverlay';
import { setLocalStorage } from '../../../Common/CookieSettings/CookieUtils';
import PenIcon from '../../../Common/Icons/PenIcon';
import CreateWishList from '../CreateWishList';

class AuthenticatedUserWishlists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      listToDelete: null,
      isEditOverlayOpen: false,
      listToEdit: null
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./AuthenticatedUserWishlists.scss');
    // TODO: The MenuIcon component is already doing this, do we really need it?
    this.props.getWishList();
  }

  deleteWishlist = () => {
    this.props.deleteWishList(this.state.listToDelete);
    this.toggleOverlay();
  };

  toggleOverlay = () => {
    this.setState({
      isOverlayOpen: !this.state.isOverlayOpen
    });
  };
  onCloseEditOverlay = () => {
    this.setState({
      isEditOverlayOpen: !this.state.isEditOverlayOpen
    });
  };

  setListToDelete = listName => {
    this.setState({ listToDelete: listName });
  };
  setListToEdit = listName => {
    this.setState({ listToEdit: listName });
  };

  getWhishLists = () => {
    const {
      'Wishlist Items Link': wishlistLink,
      'Created By Leister Label': createdLeisterLabel,
      'Created By You Label': createdYouLabel,
      'Number Article Label': articlesLabel
    } = this.props.fields;
    return this.props.wishLists.map((item, index) => {
      const createdLongDate = new Date(item.addDate),
        date = createdLongDate.getDate() + '.' + (createdLongDate.getMonth() + 1) + '.' + createdLongDate.getFullYear();

      // Don't show the default wishlist if it's empty
      if (item.isDefault && item.itemsCount === 0) {
        return null;
      }

      return (
        <div className='AuthenticatedUserWishlists-Content' key={item.name + index}>
          <div
            onClick={() => {
              this.props.setCurrentWishListIndex(index);
              setLocalStorage('currentWishListIndex', index);
            }}
            onKeyDown={e => {
              if (e.target === 13) {
                this.props.setCurrentWishListIndex(index);
                setLocalStorage('currentWishListIndex', index);
              }
            }}
            role='button'
            tabIndex='0'
          >
            <GeneralLink fields={wishlistLink} id={item.name}>
              <div>
                <div className='WishlistComponent-Name'>{item.name}</div>

                <div className='WishlistComponent-Details'>
                  <Text field={item.isDefault ? createdLeisterLabel : createdYouLabel} />
                  &#58; {date} &#124;&nbsp;
                  <Text field={articlesLabel} />
                  &#58;&nbsp;{item.itemsCount}
                </div>
              </div>
            </GeneralLink>
          </div>

          <div className='AuthenticatedUserWishlists-Content-Actions'>
            <div
              className='AuthenticatedUserWishlists-Content-Edit'
              onClick={() => {
                this.onCloseEditOverlay();
                this.setListToEdit(item.name);
              }}
              onKeyDown={e => {
                if (e.target === 13) {
                  this.onCloseEditOverlay();
                  this.setListToEdit(item.name);
                }
              }}
              role='button'
              tabIndex='0'
              aria-label='Open Rename Overlay'
            >
              <PenIcon />
            </div>
            <div
              className='AuthenticatedUserWishlists-Content-Delete'
              onClick={() => {
                this.toggleOverlay();
                this.setListToDelete(item.name);
              }}
              onKeyDown={event => {
                if (event.target === 13) {
                  this.toggleOverlay();
                  this.setListToDelete(item.name);
                }
              }}
              role='button'
              tabIndex='0'
              aria-label='Open Approval Overlay'
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const {
      'Delete Button Label': deleteButtonLabel,
      'Overlay Title': overlayTitle,
      'Cancel Delete Label': cancelDeleteLabel
    } = this.props.fields;

    return (
      <React.Fragment>
        {this.props.wishLists && this.getWhishLists()}
        <div>
          {/* NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component */}
          <RemoveApprovalOverlay
            onClose={this.toggleOverlay}
            isOverlay={this.state.isOverlayOpen}
            onClick={this.deleteWishlist}
            DeleteButtonLabel={deleteButtonLabel}
            DeleteText={overlayTitle}
            CancelDeleteLabel={cancelDeleteLabel}
          />
        </div>

        {this.state.isEditOverlayOpen && (
          <CreateWishList
            fields={this.props.fields}
            editOverlay
            editInputPlaceholder={this.state.listToEdit}
            onClose={() => this.onCloseEditOverlay()}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    wishLists: state.wishlistProducts && state.wishlistProducts.wishLists
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getWishList: () => dispatch(getWishList()),
    deleteWishList: listName => dispatch(deleteWishList(listName)),
    setCurrentWishListIndex: index => dispatch(setCurrentWishListIndex(index))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedUserWishlists);
