import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import AppRoot from './AppRoot';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import i18ninit from './i18n';
import { setConfiguration } from 'react-grid-system';
import { setAppLanguage } from './components/Common/CookieSettings/SetLanguageData';
import { getSitecoreDefaultLanguage, getSitecoreGraphqlEndpoint } from './AppGlobals';
import { SsrState } from './RouteHandler';

setConfiguration({
  breakpoints: [320, 768, 960, 1440],
  gutterWidth: 15
});

// Note (DSz): Theoretically, a Sitecore context should be passed to setAppLanguage. However, since this function will
// be invoked before any initialization happened, we aren't passing anything, effecitvely resulting in the fallback behaviour of the getLanguage.
setAppLanguage().then(() => {
  /* eslint-disable no-underscore-dangle */

  let renderFunction = ReactDOM.render;
  let initLanguage = getSitecoreDefaultLanguage();

  /*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
  let __JSS_STATE__: SsrState = null;
  const ssrRawJson = document.getElementById('__JSS_STATE__');
  if (ssrRawJson) {
    __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
  }
  if (__JSS_STATE__) {
    // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
    renderFunction = ReactDOM.hydrate;

    // set i18n language SSR state language instead of static config default language
    initLanguage = __JSS_STATE__.sitecore.context.language;
  }

  /*
  GraphQL Data
  The Apollo Client needs to be initialized to make GraphQL available to the JSS app.
  Not using GraphQL? Remove this, and the ApolloContext from `AppRoot`.
*/
  // Apollo supports SSR of GraphQL queries, so like JSS SSR, it has an object we can pre-hydrate the client cache from
  // to avoid needing to re-run GraphQL queries after the SSR page loads
  const initialGraphQLState = __JSS_STATE__ && __JSS_STATE__.APOLLO_STATE ? __JSS_STATE__.APOLLO_STATE : null;

  const graphQLClient = GraphQLClientFactory(getSitecoreGraphqlEndpoint(), false, initialGraphQLState);
  const width = window.innerWidth;
  if (width < 960) {
    initLanguage = null;
  }

  /*
  App Rendering
*/
  // initialize the dictionary, then render the app
  // note: if not making a multlingual app, the dictionary init can be removed.
  // __JSS_STATE__ ? __JSS_STATE__.sitecore.context.language : "en"  --> for localhost to work when no language is provided
  // @ts-ignore 2nd argument is in fact optional, it's used on SSR only
  i18ninit(initLanguage)
    .then(() => Loadable.preloadReady())
    .then(() => {
      // HTML element to place the app into
      const rootElement = document.getElementById('root');

      renderFunction(
        <AppRoot
          path={window.location.pathname}
          Router={BrowserRouter}
          graphQLClient={graphQLClient}
          ssrState={__JSS_STATE__}
        />,
        rootElement
      );
    });
});
