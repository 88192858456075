import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import CardsCarousel from '../../Common/CardsCarousel/CardsCarousel';
import ArrowRight from '../../Common/Icons/ArrowRight';
import { removeWhiteSpaces, get768ImageFromSrcSet } from '../../../utils/Utils';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';

class StoriesPromotionBoxComponent extends React.Component {
  getCards() {
    return this.props.StoriesPromotionBox.stories.storiestype.map((item, index) => {
      const { gtm, title, image, date, category, linkerButtonLabel } = item,
        img768 =
          image && image.srcSet
            ? get768ImageFromSrcSet(image.srcSet)
            : image && image.src
            ? image.src + '?imwidth=768'
            : '',
        pageUrl = item[this.props.urlToUse];
      return (
        <div className='StoriesPromotionBox-Card' key={index + title}>
          <RoutableSitecoreLink
            link={{
              value: { href: pageUrl || '#' }
            }}
            className={
              'StoriesPromotionBox-Card-Image click_content_block_stories_carousel ' +
              removeWhiteSpaces(gtm ? gtm + '-Image' : null)
            }
          >
            <img src={img768} alt={(image && image.alt) || ''} loading='lazy' />
          </RoutableSitecoreLink>

          <div className='StoriesPromotionBox-Card-Content'>
            <div className='StoriesPromotionBox-Card-Content-Category'>{category}</div>
            <div className='StoriesPromotionBox-Card-Content-Date'>{date}</div>

            <div className='StoriesPromotionBox-Card-Content-Title'>{title}</div>
            <RoutableSitecoreLink
              link={{
                value: {
                  href: pageUrl || '#'
                }
              }}
              className={
                'StoriesPromotionBox-Card-Content-Link click_content_block_stories_carousel ' +
                removeWhiteSpaces(gtm || null)
              }
            >
              {linkerButtonLabel}
              <ArrowRight />
            </RoutableSitecoreLink>
          </div>
        </div>
      );
    });
  }
  getCarouselOrCards(nrOfItemsToShow) {
    const cssClass = classNames({
      'StoriesPromotionBox-MobileContainer': nrOfItemsToShow === 1,
      'StoriesPromotionBox-TabletContainer': nrOfItemsToShow === 2,
      'StoriesPromotionBox-DesktopContainer': nrOfItemsToShow === 3
    });

    if (this.props.StoriesPromotionBox.stories.storiestype.length > nrOfItemsToShow) {
      return <CardsCarousel nrOfItemsToShow={nrOfItemsToShow}>{this.getCards()}</CardsCarousel>;
    }
    return <div className={cssClass}>{this.getCards()}</div>;
  }

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground stories' />;
    }

    const { Title, Subtitle, 'Background Color': BackgroundColor } = this.props.fields,
      inlineStyle = {
        background:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      isBackgroundBlack = inlineStyle.background === '#000',
      cssClass = classNames('StoriesPromotionBox  carouselComponentContainer click_content_block_stories_carousel', {
        WhiteColorStyle: isBackgroundBlack
      }),
      grayImage = classNames({
        grayBackgroundImage: inlineStyle.background === null
      });
    return (
      <div className={grayImage} style={inlineStyle}>
        <div className={cssClass}>
          <HeadlineWithTitleAndSubtitle
            isTextWhite={isBackgroundBlack}
            title={Title}
            subtitle={Subtitle}
            extraClasses='StoriesPromotionBox-Headline'
          />
          <Visible lg xl>
            {this.getCarouselOrCards(3)}
          </Visible>
          <Visible md>{this.getCarouselOrCards(2)}</Visible>
          <Visible xs sm>
            {this.getCarouselOrCards(1)}
          </Visible>
        </div>
      </div>
    );
  }
}

const hasItems = function (props) {
  const { StoriesPromotionBox, fields } = props;
  if (
    !StoriesPromotionBox ||
    !StoriesPromotionBox.stories ||
    !StoriesPromotionBox.stories.storiestype ||
    StoriesPromotionBox.stories.storiestype.length < 1 ||
    !fields
  ) {
    return false;
  }
  return true;
};

export default withSitecoreContext()(WithStickyMenu(StoriesPromotionBoxComponent, hasItems));
