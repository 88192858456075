import React from 'react';
import DownloadItem from './DownloadItem/index';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import { removeWhiteSpaces } from '../../../utils/Utils';
import ArrowDown from '../Icons/ArrowDown';

/**
 * @method DownloadTeaser
 * @description  A class that render the Download box whith his downloadable items
 * @returns jxs items
 */
class DownloadTeaser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      multiplier: 1
    };
  }

  /**
   * @method getDownloadItems
   * @description return as many jsx elements as the result from (multiplier * 3), or all of them if the result is higher than the number of items
   * @returns jxs items
   */
  getDownloadItems = downloadItems => {
    if (downloadItems) {
      const itemsToShow = 3 * this.state.multiplier;

      return downloadItems.slice(0, itemsToShow).map((item, index) => {
        return <DownloadItem key={index} fields={item} />;
      });
    }
  };

  /**
   * @method incrementMultiplier
   * @description increment multiplier from old state whith one
   */
  incrementMultiplier = () => {
    this.setState(state => ({
      multiplier: state.multiplier + 1
    }));
  };

  /**
   * @method getShowNextLabel
   * @description Display the label "Show x more" if there are more download items than showed
   * @returns jxs item or empty string
   */
  getShowNextLabel = (basicFieldProps, downloadItems) => {
    const numberOfItems = 3;
    const remainingItems = downloadItems.length - this.state.multiplier * numberOfItems;
    if (remainingItems > 0) {
      const numberToShow = remainingItems > numberOfItems ? numberOfItems : remainingItems;
      return (
        <div
          className='DownloadTeaser-ShowMore'
          role='button'
          tabIndex={0}
          onClick={this.incrementMultiplier}
          onKeyUp={e => {
            if (e.key === 13) {
              this.incrementMultiplier();
            }
          }}
        >
          <ArrowDown />
          <Text field={basicFieldProps.Show} /> {numberToShow} <Text field={basicFieldProps.More} />
        </div>
      );
    }
    return '';
  };

  render() {
    const propsFields = this.props.fields && this.props.fields.data ? this.props.fields.data : null,
      downloadItems =
        propsFields && propsFields.getDownloadItemsByDownloadBoxIdQuery
          ? propsFields.getDownloadItemsByDownloadBoxIdQuery
          : [],
      basicFieldProps = propsFields && propsFields.item ? propsFields.item : null;

    if (!basicFieldProps || downloadItems.length < 1) {
      return <div className='componentContainerMobile whiteBackground' />;
    }

    const inlineStyle = {
        backgroundColor:
          basicFieldProps.backgroundColor?.jss?.fields?.ColorCode?.value &&
          basicFieldProps.backgroundColor.jss.fields.ColorCode.value !== ''
            ? basicFieldProps.backgroundColor.jss.fields.ColorCode.value
            : null
      },
      cssClass = classNames('DownloadTeaser-Container', {
        WhiteColorStyle: inlineStyle.backgroundColor && inlineStyle.backgroundColor === '#000'
      });

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='componentContainerMobile'>
          <div className={cssClass}>
            <div
              id={removeWhiteSpaces(basicFieldProps.Title.value || null)}
              className='DownloadTeaser click_content_block_downloads'
            >
              <div className='DownloadTeaser-Headline'>
                <Text className='DownloadTeaser-Headline-Title' tag='h2' field={basicFieldProps.Title} />
                <RichText field={basicFieldProps.Description} />
              </div>
              {this.getDownloadItems(downloadItems)}
            </div>
            {this.getShowNextLabel(basicFieldProps, downloadItems)}
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadTeaser;
