import React from 'react';
import { connect } from 'react-redux';
import { setInitialData, setCurrentComparedCategory } from '../../../../store/compare/actions';
import {
  setProductForGetAQuoteLink,
  setDataForProductQuote,
  setIsLinkForQuote
} from '../../../../store/product/actions';
import CompareProducts from '../CompareProducts';
import CompareStickyMenu from '../CompareStickyMenu';

class CompareProductsWithStickyMenu extends React.Component {
  /**
   * @method  removeProductFromStore delete product from store/redux
   */

  removeProductFromStore = productId => {
    const { initialData, updateInitialData } = this.props;

    const data = JSON.parse(JSON.stringify(initialData)).filter(item => item.id !== productId);

    updateInitialData(data);

    if (JSON.stringify(data).indexOf(this.props.selectedCategory) === -1) {
      this.props.updateSelectedCategory(null);
    }
  };

  render() {
    const {
      initialData,
      selectedCategory,
      setProductForGetAQuoteLink,
      fields,
      favouriteContact,
      setDataForProductQuote,
      setIsLinkForQuote
    } = this.props;
    if (!initialData) {
      return <div />;
    }
    return (
      <React.Fragment>
        <CompareProducts
          initialData={initialData}
          selectedCategory={selectedCategory}
          fields={fields}
          getQuoteLink={setProductForGetAQuoteLink}
          removeProductFromStore={this.removeProductFromStore}
          favouriteContact={favouriteContact}
          productToQuote={setDataForProductQuote}
          linkForQuote={setIsLinkForQuote}
        />
        <CompareStickyMenu
          fields={fields}
          initialData={initialData}
          selectedCategory={selectedCategory}
          getQuoteLink={setProductForGetAQuoteLink}
          removeProductFromStore={this.removeProductFromStore}
          favouriteContact={favouriteContact}
          productToQuote={setDataForProductQuote}
          linkForQuote={setIsLinkForQuote}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateInitialData: compareProducts => dispatch(setInitialData(compareProducts)),
    setProductForGetAQuoteLink: link => dispatch(setProductForGetAQuoteLink(link)),
    updateSelectedCategory: category => dispatch(setCurrentComparedCategory(category)),
    setDataForProductQuote: productData => dispatch(setDataForProductQuote(productData)),
    setIsLinkForQuote: isLink => dispatch(setIsLinkForQuote(isLink))
  };
};

export default connect(null, mapDispatchToProps)(CompareProductsWithStickyMenu);
