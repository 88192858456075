import React from 'react';
import Sticky from 'react-sticky-el';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import ArrowUp from '../../../Common/Icons/ArrowUp';
import CompareStickyMenuCard from './CompareStickyMenuCard';

class CompareStickyMenu extends React.Component {
  constructor() {
    super();
    this.state = { isSticky: false };
  }
  componentDidMount() {
    document.documentElement.classList.add('smoothScroll');
    window.addEventListener('scroll', this.handleStickyMenu.bind(this), {
      passive: true
    });
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('smoothScroll');
    window.removeEventListener('scroll', this.handleStickyMenu);
  }

  /**
   * @method goTop scroll page to the top
   */

  goTop = () => {
    if (window.pageYOffset > 0) {
      window.scrollTo(0, 0);
    }
  };

  /**
   * @method handleStickyMenu display or not  CompareStickyMenu , when scroll
   */

  handleStickyMenu() {
    const compareProducts = document.querySelector('.CompareProducts');
    if (compareProducts) {
      const compareProductsHeight = compareProducts.clientHeight;
      let newScrollPosition = window.pageYOffset;
      if (newScrollPosition >= compareProductsHeight + 70) {
        this.setState({ isSticky: true });
      } else {
        this.setState({ isSticky: false });
      }
    }
  }

  /**
   * @method getStickyProducts return a list of CompareStickyMenuCard according to the selected category
   */

  getStickyProducts = () => {
    const {
        initialData,
        selectedCategory,
        fields,
        removeProductFromStore,
        getQuoteLink,
        favouriteContact,
        productToQuote,
        linkForQuote
      } = this.props,
      dataToDisplay = initialData.filter(item => [item.category, item.categoryEn].includes(selectedCategory));

    return dataToDisplay
      .slice(0, 4)
      .map((item, index) => {
        return (
          <CompareStickyMenuCard
            fields={fields}
            item={item}
            key={index + item.masterProductName}
            onClick={removeProductFromStore}
            getAQuoteLink={getQuoteLink}
            favouriteContact={favouriteContact}
            setProductToQuote={productToQuote}
            quoteValidation={linkForQuote}
          />
        );
      })
      .filter(item => item !== null);
  };

  render() {
    const { 'Top Label': topLabel } = this.props.fields,
      cssClassSticky = classNames('CompareStickyMenu', {
        Active: this.state.isSticky
      });

    return (
      <div className='compareZIndexWithRelativePosition'>
        <Sticky topOffset={-80} className={cssClassSticky}>
          <div className='CompareStickyMenu-Container'>
            <div className='CompareStickyMenu-Top'>
              <div
                onClick={this.goTop}
                onKeyDown={e => {
                  if (e.target === 13) this.goTop();
                }}
                role='button'
                tabIndex='0'
                className='CompareStickyMenu-Top-Container'
              >
                <ArrowUp />
                <Text field={topLabel} tag='span' />
              </div>
            </div>
            {this.getStickyProducts()}
          </div>
        </Sticky>
      </div>
    );
  }
}

export default CompareStickyMenu;
