import React from 'react';
import SolutionCard from '../SolutionCard/index';
class DesktopGallery extends React.PureComponent {
  render() {
    const { fields } = this.props,
      { 'Solution Heroes': SolutionHeroes } = fields;

    if (!fields || !SolutionHeroes || SolutionHeroes.length < 1) {
      return null;
    }

    const colOne = [],
      colTwo = [],
      colThree = [],
      solutionHeroes = SolutionHeroes;
    let flag = 'one';
    for (let i = 0; i < solutionHeroes.length; i++) {
      switch (flag) {
        case 'one':
          colOne.push(
            <SolutionCard
              key={i}
              exploreMoreLabel={this.props.exploreMoreLabel}
              data={solutionHeroes[i]}
              urlToUse={this.props.urlToUse}
            />
          );
          flag = 'two';
          break;
        case 'two':
          colTwo.push(
            <SolutionCard
              key={i}
              exploreMoreLabel={this.props.exploreMoreLabel}
              data={solutionHeroes[i]}
              urlToUse={this.props.urlToUse}
            />
          );
          flag = 'three';
          break;
        case 'three':
          colThree.push(
            <SolutionCard
              key={i}
              exploreMoreLabel={this.props.exploreMoreLabel}
              data={solutionHeroes[i]}
              urlToUse={this.props.urlToUse}
            />
          );
          flag = 'one';
          break;
        default:
          break;
      }
    }

    return (
      <div className='SolutionCardsGallery-Desktop'>
        <div className='SolutionCardsGallery-Desktop-FirstCol'>{colOne}</div>
        <div className='SolutionCardsGallery-Desktop-SecondCol'>{colTwo}</div>
        <div className='SolutionCardsGallery-Desktop-ThirdCol'>{colThree}</div>
      </div>
    );
  }
}

export default DesktopGallery;
