import { getCookie, setCookie } from './CookieUtils';
import {
  LANG,
  ONE_YEAR,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY,
  DEFAULT_LANGUAGE,
  COUNTRY,
  COUNTRY_CODE,
  REGION
} from './CookieConstants';
import { setLanguage } from '../../../utils/Utils';

function getUserDetails() {
  return null;
}

//function to determine app language
export async function setAppLanguage(sitecoreContext) {
  //get language from cookies
  let cookieLanguage = getCookie(LANG);
  const sitecoreLanguage = getLanguage(sitecoreContext);
  if (cookieLanguage === null) {
    // get browser language
    //TO DO: for country code we need the api from platform
    cookieLanguage = window.navigator.userLanguage || window.navigator.language;
    const newLanguage = cookieLanguage ? cookieLanguage.split('-') : null;
    await getGeoIpData();
    if (newLanguage === null) {
      //TO DO: get user details
      const userDetails = getUserDetails();
      if (userDetails === null) {
        setLanguage(sitecoreLanguage || DEFAULT_LANGUAGE);
      }
    } else {
      setLanguage(sitecoreLanguage || newLanguage[0]);
    }

    return Promise.resolve();
  } else if (sitecoreLanguage !== null && sitecoreLanguage !== cookieLanguage) {
    setLanguage(sitecoreLanguage);
  } else {
    return Promise.resolve();
  }
}

async function getGeoIpData() {
  // Variable for the link - dynamically created
  const linkURL = window.location.origin + '/ResolveCountryByIp.aspx';
  return fetch(linkURL)
    .then(response => response.json())
    .then(data => {
      let code = data.Country;
      if (code && code !== 'N/A') {
        setCookie(COUNTRY_CODE, code, ONE_YEAR);
      } else {
        setCookie(COUNTRY_CODE, DEFAULT_COUNTRY_CODE, ONE_YEAR);
        code = DEFAULT_COUNTRY_CODE;
      }

      return code;
    })
    .catch(() => {
      setCookie(COUNTRY_CODE, DEFAULT_COUNTRY_CODE, ONE_YEAR);
      setCookie(COUNTRY, DEFAULT_COUNTRY, ONE_YEAR);
      return null;
    });
}

export function getAppLocalizationDetails(sitecoreContext) {
  const lang = getLanguage(sitecoreContext),
    newLang = lang.split('-');
  return {
    lang: newLang[0],
    region: getCookie(REGION),
    countryCode: getCookie(COUNTRY_CODE) || DEFAULT_COUNTRY_CODE
  };
}

export function getLanguage(sitecoreContext) {
  const context = sitecoreContext;

  let sitecoreCookie;
  if (context && context.site && context.site.name) {
    sitecoreCookie = getCookie(context.site.name.toLowerCase() + '#lang');
  }

  if (sitecoreCookie && sitecoreCookie !== getCookie(LANG)) {
    setCookie(LANG, sitecoreCookie, ONE_YEAR);
  }

  return sitecoreCookie || (context && context.language) || getCookie(LANG) || 'en';
}
