import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  isOpen: false,
  openFlayout: null,
  layout: null,
  sectionId: null,
  productsJson: null,
  submenuItems: null,
  dataOfAllSubmenuItems: null,
  redirectedPageId: null,
  productsMenuEndpoint: null
};

/**
 * @method menuFlyout
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const menuFlyout = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS_JSON_DATA:
      return {
        ...state,
        productsJson: action.productsJson,
        productsMenuEndpoint: action.endpoint
      };
    case actionTypes.SET_ALL_SUBITEMS_DATA:
      return {
        ...state,
        submenuItems: action.menuJson,
        dataOfAllSubmenuItems: action.dataOfAllSubmenuItems
      };
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        sectionId: action.sectionId,
        layout: action.layout,
        openFlayout: action.opededFlayout === state.openFlayout ? null : action.opededFlayout,
        isOpen: (action.opededFlayout === state.openFlayout ? null : action.opededFlayout) === null ? false : true,
        showImage: action.showImage,
        redirectedPageId: null
      };
    case actionTypes.SET_REDIRECT_PAGE_ID:
      return {
        ...state,
        redirectedPageId: action.redirectedPageId
      };
    default:
      return state;
  }
};
