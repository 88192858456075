import React, { Fragment } from 'react';
import MapPinIcon from '../../../../Icons/MapPin';
import MedicalIcon from '../../../../Icons/Medical';
import Text from '../../../../Text';
import { EventDetailsProps } from '../types';
import { EventDate } from './EventDate';

export const EventDetails = ({
  eventTitle,
  eventCity,
  eventCountry,
  eventIndustries,
  eventStartDate,
  eventEndDate,
  language,
  eventVenue
}: EventDetailsProps): JSX.Element => {
  const regionNameValue = eventCountry?.fields?.RegionName;

  return (
    <ul className='EventInformationSection-DetailsRow'>
      {(eventCity?.value || regionNameValue?.value) && (
        <li data-testid='event-place'>
          <MapPinIcon />
          <div className='EventInformationSection-DetailsRow-Content wrap-context-wrapper'>
            {eventVenue?.value && (
              <span className='wrap-context'>
                <Text field={eventVenue} />
                {(eventCity.value || regionNameValue?.value) && <span>,&nbsp;</span>}
              </span>
            )}
            <span className='wrap-context'>
              <Text field={eventCity} />
              {eventCity?.value && regionNameValue?.value && (
                <span className='EventInformationSection-DetailsSeparator'>/</span>
              )}
              {regionNameValue && <Text field={regionNameValue} />}
            </span>
          </div>
        </li>
      )}
      <li data-testid='event-date'>
        <EventDate language={language} eventStartDate={eventStartDate} eventEndDate={eventEndDate} />
      </li>
      {eventIndustries && eventIndustries.length && (
        <li data-testid='event-industries'>
          <MedicalIcon />
          {eventIndustries.map((industry: any, i: number) => {
            const renderSeparator = i !== eventIndustries.length - 1 ? ', ' : '';
            return (
              <Fragment key={i}>
                <Text field={industry?.fields['Event Industry Name']} />
                {renderSeparator}
              </Fragment>
            );
          })}
        </li>
      )}
    </ul>
  );
};
