import React, { useContext } from 'react';
import i18n from 'i18next';
import { getResultUrl, getResultImageSrc, getResultTitle, getResultCategory } from '../ResultItemUtils';
import { Link } from 'react-router-dom';
import { ActionType, OmniboxContext } from '../context/OmniboxContext';
import { Result, SearchEngine } from '@coveo/headless';
import { type KnownSiteNamesType } from '../../../Constants/KnownSiteNames';
import classNames from 'classnames';
import EnterIcon from '../../Common/Icons/EnterIcon';
import { normalizeDownloadPagePath } from '../../Product/AllDownloads/getDownloadsProductAndArticle';

export const InstantResultsProperties = [
  'category',
  'variantarticlenumber',
  'variantarticlenumberraw',
  'variantnametranslated',
  'variantname',
  'imgsrc',
  'mainpicture320',
  'pimclassificationtranslated',
  'masterproductrelativeurl',
  'masterproductname',
  'productassetnamenormalized',
  'ez120xpertisepageherotitle',
  'storypageherotitle',
  'itemurlaz120xetris',
  'itemurlleistergroup',
  'itemurlweldy',
  'itemurlleistertechnologies',
  'solutionpageherotitle',
  'careerz32xpagez32xtitle',
  'universalpageherotitle',
  'title',
  'isphasedout'
];

export type InstantResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
  selected: boolean;
  downloadPagePath: string;
};

export const InstantResultItem: React.FC<InstantResultItemProps> = ({
  result,
  siteName,
  selected,
  downloadPagePath
}) => {
  const raw = result.raw;
  const { dispatch } = useContext(OmniboxContext);
  const isPhasedOut = raw.isphasedout === 'true';
  let downloadPageLink = `${normalizeDownloadPagePath(downloadPagePath)}/${raw.productassetnamenormalized}`;

  if (raw.variantarticlenumberraw) {
    downloadPageLink = `${downloadPageLink}/${raw.variantarticlenumberraw}`;
  }

  const url = isPhasedOut ? downloadPageLink : getResultUrl(raw, siteName);

  return url ? (
    <>
      <input type='radio' defaultChecked={selected} className='visually-hidden' name='search' value={url} id={url} />
      <Link
        to={url}
        className={classNames('InstantResultItem', {
          InstantResultItem__selected: selected
        })}
        onClick={() => {
          dispatch({ type: ActionType.TOGGLE_SEARCH_BAR });
        }}
      >
        <span className='InstantResultItem__image'>
          <img src={getResultImageSrc(raw)} alt={getResultTitle(raw)} />
        </span>
        <label className='InstantResultItem__text' htmlFor={url}>
          <span className='InstantResultItem__category'>{getResultCategory(raw)}</span>
          <span className='InstantResultItem__title'>{getResultTitle(raw)}</span>
          {isPhasedOut && <span className='PhasedOut__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Phased out')}</span>}
        </label>
        <EnterIcon className='InstantResultItem__icon' />
      </Link>
    </>
  ) : null;
};

export default InstantResultItem;
