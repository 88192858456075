import React from 'react';
import Icon, { IconPropsOnIconWrapper } from './index';
import { IconNames } from './newIcons-types';
import { Field } from '@sitecore-jss/sitecore-jss/types/dataModels';

export type SitecoreIconProps = IconPropsOnIconWrapper & {
  icon: { fields: { Reference: Field<string> } };
};

/**
 * This component resolves the reference to the icon name and calls the icon component.
 */
export const SitecoreIcon = (props: SitecoreIconProps): JSX.Element | null => {
  const { icon, className: sitecoreIconClassName = '', ...rest } = props;

  if (icon === null || icon === undefined) {
    return null;
  }

  // Take the new field reference
  const referenceName = ('fields' in icon && icon?.fields?.Reference?.value.toLowerCase()) as IconNames;
  const iconName: IconNames | '' = referenceName ? referenceName : '';

  if (typeof iconName !== 'string') {
    console.error('SitecoreIcon: iconName is not a string: ', iconName);
    return null;
  }

  return iconName ? <Icon name={iconName} className={`${sitecoreIconClassName} sitecore-icon`} {...rest} /> : null;
};

export default Icon;
