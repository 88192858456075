import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';

const ImageLeftTeaser = props => {
  return (
    <Row className='ImageLeftTeaser'>
      <Col sm={12} xs={12} lg={6}>
        <Image className='ImageLeftTeaser-img' field={props.fields.Image} />
      </Col>
      <Col sm={12} xs={12} lg={6} className='ImageLeftTeaser-right'>
        <div className='ImageLeftTeaser-right-wrapper'>
          <Text field={props.fields.Headline} tag='span' className='ImageLeftTeaser-right-headline' />
          <RichText field={props.fields.Description} className='ImageLeftTeaser-right-description' />
          <div className='ImageLeftTeaser-right-button'>
            <RoutableSitecoreLink link={props.fields.Link} className='ImageLeftTeaser-right-button-link' />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ImageLeftTeaser;
