import React from 'react';
import { Image, Text, RichText, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import EmailGreyIcon from '../../Common/Icons/EmailGreyIcon';
import gql from 'graphql-tag';
import GraphQLData from '../../../lib/GraphQLData';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import GeneralLink from '../../Common/GeneralLink';

const getBackToInfoForStoryAndCareerQuery = gql`
  query getBackToInfoForStoryAndCareerQuery($dataSource: String, $lang: String) {
    getBackToInfoForStoryAndCareerQuery(currentItemId: $dataSource, lang: $lang) {
      url
      description
    }
  }
`;

/**
 * @class Component that renders a back link, title, image , category, date ,text ,
 * an optional share email icon and a placeholder for share social icons
 * @param {object} props Object containing data needed for the component.
 *
 */

class HeroStory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLink: ''
    };
  }

  componentDidMount() {
    const currentLink = window.location.href;
    this.setState({ currentLink: currentLink });
  }
  showLinkBack = () => {
    const { 'Back To Label': buttonText } = this.props.fields;

    const queryData =
      this.props.queryHeroData && this.props.queryHeroData.getBackToInfoForStoryAndCareerQuery
        ? this.props.queryHeroData.getBackToInfoForStoryAndCareerQuery
        : {};
    const { url: backLink, description: backLinkDescription } = queryData;

    if (!backLink || !backLinkDescription) {
      return null;
    }

    return (
      <GeneralLink fields={{ value: { href: backLink } }} className='HeroStory-Button'>
        <ArrowLeft />
        <Text field={buttonText} />
        &nbsp;{backLinkDescription}
      </GeneralLink>
    );
  };
  showDate = () => {
    const { Date: date } = this.props.fields;
    const { language } = this.props.sitecoreContext;

    if (date.value) {
      // for consistency reasons, same approach of data formatting was used like the one for the story teaser in the story page list
      const renderingDate = new Date(date.value).toLocaleDateString(language === 'en' ? 'en-uk' : language, {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });
      return <span className='HeroStory-Date'>{renderingDate}</span>;
    }
    return null;
  };
  showShareEmail = () => {
    const { Title, 'Show Email Share': showEmailShare } = this.props.fields;
    if (showEmailShare.value) {
      return (
        <a
          href={`mailto:?subject=${Title.value}&body=${Title.value}%0D%0A${this.state.currentLink}`}
          className='HeroStory-EmailShare click_button_share'
          target='blank'
        >
          <EmailGreyIcon />
        </a>
      );
    }
    return null;
  };

  render() {
    if (!this.props.fields) {
      return <div className='HeroStory-Container componentContainerHero' />;
    }

    const { fields } = this.props,
      { Category, Text: leadText, Image: img, Title } = fields,
      categoryText =
        Category && Category.fields && Category.fields['Category Tag'] ? Category.fields['Category Tag'] : null;

    return (
      <div className='HeroStory-Container componentContainerHero'>
        <div className='HeroStory'>
          {this.showLinkBack()}
          <Text className='HeroStory-Title' tag='h1' field={Title} />
          <Image className='HeroStory-Image' field={img} />

          <div className='HeroStory-SocialLinkWrapper'>
            <Placeholder name='jss-share-social-link' rendering={this.props.rendering} />
            {this.showShareEmail()}
          </div>

          <div className='HeroStory-CategoryWrapper'>
            <Text className='HeroStory-Category' tag='span' field={categoryText} />
            {this.showDate()}
          </div>
          <RichText field={leadText} className='HeroStory-LeadText' />
        </div>
      </div>
    );
  }
}

export default GraphQLData(getBackToInfoForStoryAndCareerQuery, {
  name: 'queryHeroData',
  options: props => ({
    variables: {
      dataSource: props.sitecoreContext && props.sitecoreContext.itemId ? props.sitecoreContext.itemId : null,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withSitecoreContext()(HeroStory));
