import * as React from 'react';
import { Cart, CartItem, ShopifyLineError } from '../lib/shopify/types';

export interface CartState {
  cart?: Cart;
  cartItem?: CartItem;
  isCartOpen?: boolean;
  isUpdating?: boolean;
  isCartNotificationOpen?: boolean;
  userErrors: ShopifyLineError[];
  isBundle: boolean;
  bundleInfo: {
    title: string;
    amount: string;
    currencyCode: string;
    amountOfItems: number;
  };
}

export const initialState: CartState = {
  cart: undefined,
  cartItem: undefined,
  isCartOpen: false,
  isUpdating: false,
  isCartNotificationOpen: false,
  userErrors: [] as ShopifyLineError[],
  isBundle: false,
  bundleInfo: {
    title: '',
    amount: '0',
    currencyCode: '',
    amountOfItems: 0
  }
};

export interface CartContextInterface {
  state: CartState;
  dispatch: React.Dispatch<CartAction>;
}

const CartContext = React.createContext<CartContextInterface>({
  state: {
    cart: undefined,
    cartItem: undefined,
    isCartOpen: false,
    isUpdating: false,
    isCartNotificationOpen: false,
    userErrors: [] as ShopifyLineError[],
    isBundle: false,
    bundleInfo: {
      title: '',
      amount: '0',
      currencyCode: '',
      amountOfItems: 0
    }
  },
  dispatch: () => undefined
});

export enum ActionType {
  SET_CART = 'SET_CART',
  OPEN_CART = 'OPEN_CART',
  CLOSE_CART = 'CLOSE_CART',
  CART_UPDATING = 'CART_UPDATING',
  CART_UPDATED = 'CART_UPDATED',
  OPEN_CART_NOTIFICATION = 'OPEN_CART_NOTIFICATION',
  CLOSE_CART_NOTIFICATION = 'CLOSE_CART_NOTIFICATION',
  ERROR_WHEN_ADDING_TO_CART = 'ERROR_WHEN_ADDING_TO_CART',
  CLOSE_CART_LINE_ERROR = 'CLOSE_CART_LINE_ERROR'
}

export type CartAction = {
  type: ActionType;
  payload?: {
    cart?: Cart;
    cartItem?: CartItem;
    userErrors?: ShopifyLineError[];
    variantId?: string;
    isBundle?: boolean;
    bundleInfo?: {
      title: string;
      amount: string;
      currencyCode: string;
      amountOfItems: number;
    };
  };
};

const cartReducer = (state: CartState, action: CartAction): typeof initialState => {
  switch (action.type) {
    case ActionType.SET_CART: {
      return {
        ...state,
        cart: action.payload?.cart
      };
    }

    case ActionType.OPEN_CART: {
      return {
        ...state,
        isCartOpen: true
      };
    }

    case ActionType.CLOSE_CART: {
      return {
        ...state,
        isCartOpen: false
      };
    }

    case ActionType.CART_UPDATING: {
      return {
        ...state,
        isUpdating: true
      };
    }

    case ActionType.CART_UPDATED: {
      return {
        ...state,
        isUpdating: false
      };
    }

    case ActionType.OPEN_CART_NOTIFICATION: {
      return {
        ...state,
        isCartNotificationOpen: true,
        cartItem: action.payload?.cartItem,
        isBundle: action.payload?.isBundle ?? false,
        bundleInfo: action.payload?.bundleInfo ?? { ...initialState.bundleInfo }
      };
    }

    case ActionType.CLOSE_CART_NOTIFICATION: {
      return {
        ...state,
        isCartNotificationOpen: false
      };
    }

    case ActionType.ERROR_WHEN_ADDING_TO_CART: {
      if (!action.payload?.userErrors) {
        return state;
      }

      return {
        ...state,
        userErrors: [
          ...state.userErrors.filter(
            error => !action.payload?.userErrors?.some(userError => userError.variantId === error.variantId)
          ),
          ...action.payload.userErrors
        ]
      };
    }

    case ActionType.CLOSE_CART_LINE_ERROR: {
      if (action.payload?.variantId === null || action.payload?.variantId === undefined) {
        return state;
      }

      return {
        ...state,
        userErrors: state.userErrors.filter(error => error.variantId !== action.payload?.variantId)
      };
    }

    default:
      throw new Error();
  }
};

const CartContextProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = React.useReducer(cartReducer, initialState as CartState);
  const value = { state, dispatch };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartContext, CartContextProvider };
