import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import InfoIcon from '../../../../../Common/Icons/InfoIcon';
import DeviceCard from '../../../MyAccountDeviceList/DeviceList/DeviceCard';

class NewAddedDeviceList extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./NewAddedDeviceList.scss');
  }

  getDevices = () => {
    const { newAddedDevices, fields, deleteDevice } = this.props;

    return newAddedDevices.map((item, index) => {
      return <DeviceCard key={index + item.name} fields={fields} item={item} hasDelete onDeleteDevice={deleteDevice} />;
    });
  };

  render() {
    const { newDeviceLabel, confirmButtonLabel, addManyDeviceTooltip } = this.props.fields;
    return (
      <div className='NewAddedDeviceList componentContainer'>
        <div className='NewAddedDeviceList-NumberOfDevices'>
          {this.props.newAddedDevices.length}&nbsp;
          <Text field={newDeviceLabel} />
        </div>
        <div>{this.getDevices()}</div>
        <div className='NewAddedDeviceList-Bottom'>
          <div className='NewAddedDeviceList-Tooltip'>
            <InfoIcon />
            <Text field={addManyDeviceTooltip} />
          </div>
          <div
            onClick={this.props.onConfirmClick}
            onKeyDown={e => {
              if (e.target === 13) this.props.onConfirmClick();
            }}
            role='button'
            tabIndex='0'
            className='NewAddedDeviceList-ConfirmButton'
          >
            <Text field={confirmButtonLabel} />
          </div>
        </div>
      </div>
    );
  }
}

export default NewAddedDeviceList;
