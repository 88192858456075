import { FacetOption } from '../../Facet';

/**
 * Sorts the workload options based on their values.
 * @param {FacetOption} a - The first workload option to compare.
 * @param {FacetOption} b - The second workload option to compare.
 * @returns {number} - A negative number if a should be sorted before b, a positive number if b should be sorted before a, or 0 if they are equal.
 */
export default function sortEventDateOptions(a: FacetOption, b: FacetOption): number {
  // Value is "December 2024|2024-12"
  // The first part is the label, the second part is the value to sort by
  if ('value' in a.value && 'value' in b.value) {
    const aDateValues = a.value.value.split('|');
    const bDateValues = b.value.value.split('|');
    const aValue = parseInt(aDateValues[1]?.replace('-', '')) || 0;
    const bValue = parseInt(bDateValues[1]?.replace('-', '')) || 0;
    return aValue - bValue;
  }
  return 0;
}
