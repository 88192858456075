import * as actionTypes from './actionTypes';
import { COMPARE_PRODUCT_ENDPOINT } from '../../components/Product/productendpoint';

/**
 * @method compareProductsData
 * @description set an object on redux state with all the necessary data for compare product page
 * @returns {object} Return an object.
 */
export const getCompareProductsData = (productList, language, tenant) => {
  let uniqueProductList = [...new Set(productList)];
  return dispatch => {
    fetch(COMPARE_PRODUCT_ENDPOINT(language, tenant), {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uniqueProductList)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setInitialData(responseJson));
      })
      .catch(() => {
        throw new Error('Something went wrong');
      });
  };
};

export const setInitialData = resultJson => {
  return {
    type: actionTypes.SET_INITIAL_DATA,
    initialData: resultJson
  };
};

export const setCurrentComparedCategory = resultJson => {
  // besides redux, save also in localStorage. From some links (like inside coveo rendering), there will be
  // a hard refresh, the store is not 'persistent', so all the redux data will be deleted on hard refresh.
  // In that case, the data from localStorage will be used
  if (typeof window !== 'undefined' && window?.localStorage) {
    window.localStorage.setItem('compareCategory', resultJson);
  }
  return {
    type: actionTypes.SET_CURRENT_COMPARED_CATEGORY,
    selectedCategory: resultJson
  };
};
if (typeof window !== 'undefined') {
  window.setCurrentComparedCategory = setCurrentComparedCategory;
}
