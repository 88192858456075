import * as actionTypes from './actionTypes';
import { PRODUCT_ITEMS_ENDPOINT } from '../../components/Product/productendpoint';
import { getSitecoreApiHost } from '../../AppGlobals';

const setDataLayer = (name, id, category) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    ecommerce: {
      detail: {
        actionField: { list: category }, // 'detail' actions have an optional list property.
        products: [
          {
            name: name, // Name or ID is required.
            id: id,
            category: category
          }
        ]
      }
    }
  });
};

/**
 * @method productsData
 * @description set an object on redux state with all the necessary data for product page
 * @returns {object} Return an object.
 */
export const productsData = (endpoint, articleNumberFromUrl, countryCode, language, tenant) => {
  return dispatch => {
    dispatch(setLoading()); // Indicate loading start
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setProductDataAction(responseJson));
        if (responseJson && responseJson.MasterProductID) {
          const { MasterProductENName, MasterProductID, CategoryEn } = responseJson;

          setDataLayer(MasterProductENName, MasterProductID, CategoryEn);

          dispatch(
            setVariantsData(
              PRODUCT_ITEMS_ENDPOINT(responseJson.MasterProductID, countryCode, language, tenant),
              articleNumberFromUrl
            )
          );
        }
      })
      .catch(error => {
        dispatch(clearLoading()); // Ensure loading is cleared on error
        throw new Error('Something went wrong');
      });
  };
};

/**
 * @method shortProductData
 * @description set an object on redux state with short product data - for example for product download page
 * @returns {object} Return an object.
 */
export const shortProductData = (productName, endpoint) => {
  return dispatch => {
    dispatch(setShortProductDataLoadingAction(productName));

    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch(setShortProductDataLoadingErrorAction());
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setShortProductDataLoadedAction(productName));
        dispatch(setShortProductDataAction(responseJson));
      })
      .catch(error => {
        dispatch(setShortProductDataLoadingErrorAction());
        throw new Error('Something went wrong');
      });
  };
};

export const setVariantsData = (endpoint, articleNumberFromUrl) => {
  return dispatch => {
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(variants => {
        dispatch(setVariants(variants));

        const selectedVariant =
          variants.find(variant =>
            variant && variant.articleNumber ? variant.articleNumber === articleNumberFromUrl : null
          ) || null;
        dispatch(setSelectedVariant(selectedVariant));
        dispatch(clearLoading()); // Indicate loading end
      })
      .catch(error => {
        dispatch(clearLoading()); // Ensure loading is cleared on error
        throw new Error('Something went wrong');
      });
  };
};

export const setProductDataAction = resultJson => {
  return {
    type: actionTypes.SET_PRODUCT_DATA,
    productData: resultJson
  };
};

export const setShortProductDataLoadingAction = productName => {
  return {
    type: actionTypes.SET_SHORT_PRODUCT_DATA_LOADING,
    productName
  };
};

export const setShortProductDataLoadedAction = productName => {
  return {
    type: actionTypes.SET_SHORT_PRODUCT_DATA_LOADED,
    productName
  };
};

export const setShortProductDataLoadingErrorAction = () => {
  return {
    type: actionTypes.SET_SHORT_PRODUCT_DATA_LOAD_ERROR
  };
};

export const setShortProductDataAction = resultJson => {
  return {
    type: actionTypes.SET_SHORT_PRODUCT_DATA,
    shortProductData: resultJson
  };
};

export const setSelectedVariant = selectedVariant => {
  return {
    type: actionTypes.SET_SELECTED_VARIANT,
    selectedVariant
  };
};

export const setVariants = variants => {
  return {
    type: actionTypes.SET_VARIANTS,
    variants
  };
};

export const setAllDownloads = (productKey, endpoint) => {
  return dispatch => {
    dispatch(setAllDownloadsLoadingAction(productKey));
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch(setAllDownloadsLoadingErrorAction());
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setAllDownloadsLoadedAction(productKey));
        dispatch(setAllDownloadsAction(responseJson));
      })
      .catch(error => {
        dispatch(setAllDownloadsLoadingErrorAction());
        throw new Error('Something went wrong');
      });
  };
};

export const setAllDownloadsLoadingAction = productKey => {
  return {
    type: actionTypes.SET_ALL_DOWNLOADS_LOADING,
    productKey
  };
};

export const setAllDownloadsLoadedAction = productKey => {
  return {
    type: actionTypes.SET_ALL_DOWNLOADS_LOADED,
    productKey
  };
};

export const setAllDownloadsLoadingErrorAction = () => {
  return {
    type: actionTypes.SET_ALL_DOWNLOADS_LOAD_ERROR
  };
};

const setAllDownloadsAction = allDownloads => {
  return {
    type: actionTypes.SET_ALL_DOWNLOADS,
    allDownloads
  };
};

export const setProductForGetAQuoteLink = productPageLink => {
  return {
    type: actionTypes.SET_PRODUCT_PAGE_FOR_GET_A_QUOTE,
    productPageLink
  };
};

export const setDataForProductQuote = productDataForQuote => {
  return {
    type: actionTypes.SET_DATA_FOR_PRODUCT_QUOTE,
    productDataForQuote
  };
};

export const setIsLinkForQuote = isLinkForQuote => {
  return {
    type: actionTypes.SET_IS_LINK_FOR_QUOTE,
    isLinkForQuote
  };
};
export const setIsLinkForExpert = isLinkForExpert => {
  return {
    type: actionTypes.SET_IS_LINK_FOR_EXPERT,
    isLinkForExpert
  };
};

export const setVideoCampaign = (productName, language) => {
  const productKey = `${productName}-${language}`;

  return dispatch => {
    dispatch(setVideoCampaignLoadingAction(productKey));
    fetch(`${getSitecoreApiHost()}/api/MasterProducts/getVideoCampaignItems?name=${productName}&lang=${language}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch(setVideoCampaignLoadingErrorAction());
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setVideoCampaignAction(responseJson));
        dispatch(setVideoCampaignLoadedAction(productKey));
      })
      .catch(error => {
        dispatch(setVideoCampaignLoadingErrorAction());
        throw new Error('Something went wrong');
      });
  };
};

export const setVideoCampaignLoadingAction = productKey => {
  return {
    type: actionTypes.SET_VIDEO_CAMPAIGN_LOADING,
    productKey
  };
};

export const setVideoCampaignLoadedAction = productKey => {
  return {
    type: actionTypes.SET_VIDEO_CAMPAIGN_LOADED,
    productKey
  };
};

export const setVideoCampaignLoadingErrorAction = () => {
  return {
    type: actionTypes.SET_VIDEO_CAMPAIGN_LOAD_ERROR
  };
};

const setVideoCampaignAction = videoCampaignData => {
  const restrictedVideos = videoCampaignData.filter(video => video.isPrivateNoDownload);
  const howToVideos = videoCampaignData.filter(video => !video.isPrivateNoDownload);

  return {
    type: actionTypes.SET_VIDEO_CAMPAIGN,
    restrictedVideos,
    howToVideos
  };
};

export const setAmountOfBundles = amountOfBundles => {
  return {
    type: actionTypes.SET_AMOUNT_OF_BUNDLES,
    amountOfBundles
  };
};

export const resetProductDetails = () => {
  return {
    type: actionTypes.RESET_PRODUCT_DETAILS
  };
};

// Action Creators for Loading State
export const setLoading = () => ({ type: actionTypes.SET_LOADING });
export const clearLoading = () => ({ type: actionTypes.CLEAR_LOADING });
