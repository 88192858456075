import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  filters: [],
  isOpen: false,
  coordinates: [],
  currentCoordinates: [],
  bounds: null,
  previousId: null,
  searchLocation: null,
  mainContactPoint: null
};

export const salesAndServiceFilter = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SALES_AND_SERVICE_FILTER:
      return {
        ...state,
        filters: action.filters
      };
    case actionTypes.SET_SALES_AND_SERVICE_FILTER_OPEN_STATUS:
      return {
        ...state,
        isOpen: action.isOpen
      };
    case actionTypes.SET_COORDINATES:
      return {
        ...state,
        coordinates: action.coordinates
      };
    case actionTypes.SET_CURRENT_COORDINATES:
      return {
        ...state,
        currentCoordinates: action.coordinates
      };
    case actionTypes.SET_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      };
    case actionTypes.SET_PREVIOUS_PAGE_ID:
      return {
        ...state,
        previousId: action.previousId
      };
    case actionTypes.SET_SEARCH_LOCATION: {
      return {
        ...state,
        searchLocation: action.searchLocation
      };
    }
    case actionTypes.SET_MAIN_CONTACT_POINT: {
      return {
        ...state,
        mainContactPoint: action.mainContactPoint
      };
    }

    default:
      return state;
  }
};
