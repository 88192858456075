import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsWeldingMachinesPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsWeldingMachinesSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Welding-Machines',
  LeisterGroup: 'Welding-Machines',
  LeisterTechnologies: 'Welding-Machines',
  Weldy: 'Welding-Machines'
};

export const WeldingMachinesFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimweldingtechnology',
    label: () => i18n.t('SEARCH | Facets pimweldingtechnology'),
    multi: false
  },
  {
    field: 'pimweldingseamtype',
    label: () => i18n.t('SEARCH | Facets pimweldingseamtype')
  },
  {
    field: 'pimstationaryoperation',
    label: () => i18n.t('SEARCH | Facets pimstationaryoperation'),
    multi: false
  },
  {
    field: 'pimdatarecordinglqs',
    label: () => i18n.t('SEARCH | Facets pimdatarecordinglqs'),
    multi: false
  },
  {
    field: 'pimspeedma',
    label: () => i18n.t('SEARCH | Facets pimspeedma'),
    numeric: true,
    unit: 'm/min'
  }
];
