import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PenIcon from '../../../Common/Icons/PenIcon';

class CompanyInformations extends React.Component {
  getRow(label, value) {
    return (
      <div className='CompanyInformations-Row'>
        <Text tag='div' className='CompanyInformations-Row-Labels' field={label} />
        <div className='CompanyInformations-Row-TextColumn'>{value}</div>
      </div>
    );
  }

  render() {
    if (
      !(this.props.fields && this.props.fields.data && this.props.fields.data.item && this.props.fields.data.userQuery)
    ) {
      return null;
    }

    const {
      item: { industryLabel, editCompanyInformationLink, companyInformationLabel, editLabel },
      userQuery: { industry }
    } = this.props.fields.data;

    return (
      <div className='CompanyInformations'>
        <div className='CompanyInformations-Headline'>
          <Text tag='h3' className='CompanyInformations-Headline-Title' field={companyInformationLabel} />
          <div className='CompanyInformations-Headline-EditSection'>
            <a href={editCompanyInformationLink.jss.value.href}>
              <PenIcon />
              <Text tag='div' className='CompanyInformations-Headline-EditSection-Text' field={editLabel} />
            </a>
          </div>
        </div>
        {this.getRow(industryLabel, industry)}
      </div>
    );
  }
}
export default CompanyInformations;
