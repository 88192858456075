import React from 'react';
import i18n from 'i18next';

export function ProductVariantSelectorTitle({ count }: { count: number }): JSX.Element {
  return (
    <h1 className='ProductVariantSelectorTitle'>
      <div className='ProductVariantSelectorTitle__Title'>{i18n.t('PRODUCT | Select variant')}</div>
      <div className='ProductVariantSelectorTitle__Count'>({count})</div>
    </h1>
  );
}

export default ProductVariantSelectorTitle;
