import React from 'react';
/**
 * @class Component that renders a border line
 *
 */
const SalesAndServicesBorderLine = () => {
  return (
    <div className='componentContainerHero'>
      <div className='SalesAndServicesBorderLine' />
    </div>
  );
};

export default SalesAndServicesBorderLine;
