import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { CoveoProductsMicroOpticsPipelines, CoveoProductsMicroOpticsSearchHubs } from './MicroOpticsLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import MicroOpticsListerComponent from './MicroOpticsLister';

export const MicroOpticsLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsMicroOpticsPipelines[siteName],
    searchHub: CoveoProductsMicroOpticsSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <MicroOpticsListerComponent />
    </CoveoComponent>
  );
};

export default MicroOpticsLister;
