import { FacetOption } from '../../Facet';

/**
 * Sorts the workload options based on their values.
 * @param {FacetOption} a - The first workload option to compare.
 * @param {FacetOption} b - The second workload option to compare.
 * @returns {number} - A negative number if a should be sorted before b, a positive number if b should be sorted before a, or 0 if they are equal.
 */
export default function sortWorkLoadOptions(a: FacetOption, b: FacetOption): number {
  let aFirstValue: number;
  let aSecondValue: number;
  let bFirstValue: number;
  let bSecondValue: number;
  const getNumbersOutOfString = new RegExp(/\d{2,3}/g);

  // Workload options do have "FacetValue"'s, not "NumericFacetValue"'s
  if ('value' in a.value && 'value' in b.value) {
    // Extract the values out of the strings, which looks like "70-80%"
    const aValues = a.value.value.match(getNumbersOutOfString);
    aFirstValue = aValues && aValues[0] ? parseInt(aValues[0]) : 0;
    aSecondValue = aValues && aValues[1] ? parseInt(aValues[1]) : aFirstValue;

    const bValues = b.value.value.match(getNumbersOutOfString);
    bFirstValue = bValues && bValues[0] ? parseInt(bValues[0]) : 0;
    bSecondValue = bValues && bValues[1] ? parseInt(bValues[1]) : bFirstValue;

    // If first values match, compare the second ones (fallback to its first value)
    if (aFirstValue === bFirstValue) {
      return bSecondValue - aSecondValue;
    }
    return bFirstValue - aFirstValue;
  }
  return 0;
}
