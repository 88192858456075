import React from 'react';
import { LoadingErrorProps } from './types';
import Button from '../Button/Button';

const LoadingError = ({ buttonTitle, message, onClick }: LoadingErrorProps): JSX.Element => (
  <div className='loading-error'>
    {message && <div className='loading-error__message'>{message}</div>}
    {buttonTitle && onClick && (
      <div className='loading-error__button'>
        <Button buttonTitle={buttonTitle} onClick={onClick} />
      </div>
    )}
  </div>
);

export default LoadingError;
