/* eslint no-console: "off" */
import * as actionTypes from './actionTypes';

export const setDropdownForms = data => {
  return {
    type: actionTypes.SET_DROPDOWN_FORMS,
    data
  };
};

export const setContactPointInfoId = contactPointInfoId => {
  return {
    type: actionTypes.SET_CONTACT_POINT_INFO_ID,
    contactPointInfoId
  };
};

export const setContactPointInfo = (contactPointInfoIsOptOutForFormMailSubmission, contactPointInfoEmail) => {
  return {
    type: actionTypes.SET_CONTACT_POINT_INFO_EMAIL,
    contactPointInfoIsOptOutForFormMailSubmission,
    contactPointInfoEmail
  };
};

export const setSalesContactEmail = (currentInfoSalesEmail, currentInfoSalesPageId) => {
  return {
    type: actionTypes.SET_SALES_CONTACT_EMAIL,
    currentInfoSalesEmail,
    currentInfoSalesPageId
  };
};

export const setStatusToContactChange = (isActive, relativePath, lastActiveFormTitle) => {
  return {
    type: actionTypes.SET_STATUS_TO_CONTACT_CHANGE,
    isActive,
    relativePath,
    lastActiveFormTitle
  };
};

export const setCareerFormData = jobData => {
  return { type: actionTypes.SET_CAREER_FORM_DATA, jobData };
};

export const setJobInformationHidden = jobInfo => {
  return {
    type: actionTypes.SET_JOB_INFORMATION_HIDDEN,
    jobInfo
  };
};
