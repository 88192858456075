import React from 'react';
import { ShopProductVariant } from '../../../../Shopify/lib/shopify/types';
import Price from '../../../../Shopify/Price/Price';
import classNames from 'classnames';
import { CellTextAlign } from '../..';

export function PriceCell({
  shopProductVariant,
  textAlign = 'left'
}: {
  shopProductVariant: ShopProductVariant;
  textAlign?: CellTextAlign;
}): JSX.Element {
  return (
    <div
      className={classNames('PriceCell', {
        [`PriceCell--${textAlign}`]: textAlign
      })}
    >
      {shopProductVariant?.compareAtPrice?.amount && (
        <Price
          amount={shopProductVariant?.compareAtPrice?.amount}
          currencyCode={shopProductVariant?.compareAtPrice?.currencyCode}
          type='stroken-through'
          className='PriceCell__CompareAtPrice'
        />
      )}
      <Price
        amount={shopProductVariant.price.amount}
        currencyCode={shopProductVariant.price.currencyCode}
        className={classNames({
          'PriceCell__Price--discount': !!shopProductVariant?.compareAtPrice?.amount
        })}
      />
    </div>
  );
}

export default PriceCell;
