import * as actionTypes from './actionTypes';

const initialState = {
  lang: 'en'
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_CHANGED:
      return {
        ...state,
        lang: action.lang
      };
    case actionTypes.ADD_LANGUAGES:
      return {
        ...state,
        languages: action.languages
      };
    case actionTypes.ADD_REGIONS:
      return {
        ...state,
        regions: action.regions
      };
    default:
      return state;
  }
};
