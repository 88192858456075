import React, { useEffect, useRef, useState } from 'react';
import { Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { t } from 'i18next';
import Text from '../../Common/Text';
import { isEmailValid } from '../../../utils/formValidations';
import { getSitecoreGraphqlEndpoint } from '../../../AppGlobals';
import { checkIfUserIsLoggedIn } from '../../../utils/Utils';
/**
 *  Component renders the newsletter signup section
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

const NewsletterSubscription = props => {
  const { rendering = {}, sitecoreContext } = props;
  const { fields = {} } = rendering;

  // use to keep track if the submit button was presses, it will remain as true after (used for validatoin)
  const [isFormSubmitTriggered, setIsFormSubmitTriggered] = useState(false);
  // holds the error message, if there is one
  const [errorMessage, setErrorMessage] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const renderLabelText = t('NEWSLETTER_SUBSCRIPTION | Email field');
  const errorMessageInvalidEmail = t('FORM_VALIDATION | Invalid Email');

  const btnContainerRef = useRef(null);

  const handleOnLoadGetUserData = async () => {
    const query = `query {
			xProfileQuery {
                email
            }
		}`;

    const apiURL = `${getSitecoreGraphqlEndpoint()}&sc_lang=${sitecoreContext.language}`;

    await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
      .then(response => response.json())
      .then(data => {
        const email = data?.data?.xProfileQuery?.email;
        if (email) {
          setEmailValue(email);
        }
      });
  };

  // validate email
  const handleOnChangeInputEmailValue = e => {
    const newValue = e.target.value;

    setEmailValue(newValue);

    // if there is an error message displayed, and now the email looks ok, remove the error message
    const isValid = isEmailValid(newValue);
    if (isValid && errorMessage) {
      setErrorMessage('');
    }

    // if the form submission was triggered, show error message on the fly
    if (isFormSubmitTriggered && !isValid) {
      setErrorMessage(errorMessageInvalidEmail);
    }
  };

  const handleOnClick = e => {
    // mark the form as triggered
    setIsFormSubmitTriggered(true);

    const isValid = isEmailValid(emailValue);

    setErrorMessage(isValid ? '' : errorMessageInvalidEmail);
    if (!isValid) {
      e.preventDefault();
    }
  };

  const handleOnKeyDown = e => {
    if (e.key === 'Enter' && btnContainerRef?.current) {
      // if enter is pressed while focused on input, trigger the link
      const btnLink = btnContainerRef.current.querySelector('a');
      if (btnLink) {
        btnLink.click();
      }
    }
  };

  useEffect(() => {
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      handleOnLoadGetUserData();
    }
  }, [handleOnLoadGetUserData, sitecoreContext]);

  const renderErrorMessage = errorMessage && (
    <div className='invalid'>
      <p>{errorMessage}</p>
    </div>
  );

  const formattedLinkWithEmailInQueryString = {
    ...fields['CTA Link'],
    value: {
      ...fields['CTA Link']?.value,
      href: `${fields['CTA Link']?.value?.href}?email=${emailValue}`
    }
  };

  return (
    <div className='NewsletterSubscriptionContainer'>
      <div className='componentContainer'>
        <div className='NewsletterSubscription'>
          <div className='NewsletterSubscription_Header'>
            <h2>
              <Text field={fields.Text} />
            </h2>
            <h3>
              <Text field={fields.Title} />
            </h3>
          </div>
          <div className='NewsletterSubscription_Form SitecoreForm_V2'>
            <div className='NewsletterSubscription_InputCol'>
              <div className='InputV2_Container'>
                <input
                  className={`${emailValue ? 'hasValue' : ''}`}
                  id='email'
                  name='email'
                  onChange={handleOnChangeInputEmailValue}
                  placeholder=''
                  type='email'
                  value={emailValue}
                  onKeyDown={handleOnKeyDown}
                />
                <label htmlFor='email' className='email'>
                  {renderLabelText}
                </label>
                {renderErrorMessage}
              </div>
            </div>
            <div className='NewsletterSubscription_Btn'>
              <div className='GeneralLink' ref={btnContainerRef}>
                <Link field={formattedLinkWithEmailInQueryString} onClick={handleOnClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(NewsletterSubscription);
