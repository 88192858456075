import React, { useEffect } from 'react';
import { CheckboxProps } from './types';
import classNames from 'classnames';

const Checkbox = ({
  onChange,
  enabled = true,
  className,
  checkedLabel,
  uncheckedLabel = '',
  initialChecked = false,
  children
}: CheckboxProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [checked, setChecked] = React.useState(!!initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <label className={classNames('Checkbox', className)}>
      {enabled && (
        <div className='Checkbox__check'>
          <input
            type='checkbox'
            onChange={event => {
              onChange && onChange(event);
              setChecked(event.target.checked);
            }}
            checked={checked}
          />
          <span className='checkmark'></span>
          {checkedLabel || uncheckedLabel ? (
            <span className='visually-hidden'>{checked ? checkedLabel : uncheckedLabel || checkedLabel}</span>
          ) : null}
        </div>
      )}
      {children}
    </label>
  );
};
export default Checkbox;
