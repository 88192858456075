import React from 'react';
import { Image, Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import GeneralLink from '../../Common/GeneralLink/index';

/**
 * @class Component that renders a link, title, image, text
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class UniversalHero extends React.Component {
  /**
   * @method showLinkBack displays back link if it exist in data
   */

  showLinkBack = () => {
    const { 'Back Link': BackLink, 'Back To Label': BackToLabel } = this.props.fields;

    if (BackLink && BackLink.value.href) {
      return (
        <GeneralLink fields={BackLink}>
          <ArrowLeft />
          {BackToLabel.value + ' ' + BackLink.value.text}
        </GeneralLink>
      );
    }
    return null;
  };

  showCaption = () => {
    const caption = this.props.fields.Caption ? this.props.fields.Caption.value : null;

    if (caption) {
      return <span className='UniversalHero-Caption'>{caption}</span>;
    }
    return null;
  };

  render() {
    if (!this.props.fields) {
      return <div className='UniversalHero-Container componentContainerHero' />;
    }
    const { fields } = this.props,
      { Title, Text: Description, Image: Img, 'Back Link': BackLink } = fields,
      cssTitleClass = classNames('UniversalHero-Title', {
        'UniversalHero-Title-TopSpace': BackLink && BackLink.value.href
      });
    return (
      <div className='UniversalHero-Container componentContainerHero'>
        <div className='UniversalHero'>
          {this.showLinkBack()}
          <Text className={cssTitleClass} tag='h1' field={Title} />
          <Image className='UniversalHero-Image' field={Img} />
          {this.showCaption()}
          <div className='UniversalHero-SocialLinkWrapper'>
            <Placeholder name='jss-share-social-link' rendering={this.props.rendering} />
          </div>
          <RichText field={Description} className='UniversalHero-Description' />
        </div>
      </div>
    );
  }
}

export default UniversalHero;
