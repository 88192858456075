import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setSalesAndServiceFilter } from '../../../../store/salesandservicefilters/actions';

class SalesAndServicePointsFilterCategory extends React.Component {
  componentDidMount() {
    this.setIntialFiltersWhenCommingWithProducts();
  }

  setIntialFiltersWhenCommingWithProducts = () => {
    const { isLinkForExpert, isLinkForQuote, productDataForQuote, wishlistProducts } = this.props,
      filtersToSet = [];

    if (isLinkForExpert || isLinkForQuote) {
      if (productDataForQuote && productDataForQuote.solutions && productDataForQuote.solutions.length) {
        for (let i = 0; i < productDataForQuote.solutions.length; i++) {
          const keyToPush = isLinkForExpert
            ? productDataForQuote.solutions[i].Key
            : productDataForQuote.solutions[i].key;
          if (keyToPush) {
            filtersToSet.push(keyToPush.toLowerCase());
          }
        }
      } else if (wishlistProducts && wishlistProducts.length) {
        for (let i = 0; i < wishlistProducts.length; i++) {
          for (let j = 0; j < wishlistProducts[i].solutions.length; j++) {
            filtersToSet.push(wishlistProducts[i].solutions[j].key.toLowerCase());
          }
        }
      }
      this.props.setFilters(filtersToSet);
    }
  };

  addOrRemoveFilters = filter => {
    const currentFilters = this.props.storeFilters,
      lowerCaseFilter = filter.toLowerCase();
    let filtersToSet = JSON.parse(JSON.stringify(currentFilters));

    if (currentFilters.indexOf(lowerCaseFilter) > -1) {
      filtersToSet = filtersToSet.filter(item => item !== lowerCaseFilter);
    } else {
      filtersToSet.push(lowerCaseFilter);
    }

    this.props.setFilters(filtersToSet);
  };

  isChecked = value => {
    return this.props.storeFilters.indexOf(value.toLowerCase()) > -1;
  };

  generateFilters = filters => {
    if (filters && filters.length > 0) {
      return filters.map((item, index) => {
        const { fields } = item;
        return (
          <div key={fields.Name.value + index}>
            <input
              type='checkbox'
              value={fields.Id.value}
              checked={this.isChecked(fields.Id.value)}
              onChange={() => undefined}
            />
            <span
              className='checkmark'
              onClick={() => {
                this.addOrRemoveFilters(fields.Id.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) this.addOrRemoveFilters(fields.Id.value);
              }}
              role='button'
              tabIndex='0'
            />
            <Text tag='span' className='SalesAndServicePointsFilterCategory-Filters-Title' field={fields.Name} />
          </div>
        );
      });
    }
  };

  render() {
    const { fields, isOpen } = this.props;
    if (!fields) {
      return <div className='SalesAndServicePointsFilterCategory' />;
    }

    const { 'Main Title': mTitle, 'Filter Items': fItems } = fields,
      cssClass = classNames('SalesAndServicePointsFilterCategory', {
        'SalesAndServicePointsFilterCategory-Hide': !isOpen
      });

    return (
      <div className={cssClass}>
        <Text tag='span' className='SalesAndServicePointsFilterCategory-Title' field={mTitle} />
        <div className='SalesAndServicePointsFilterCategory-Filters'>{this.generateFilters(fItems)}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeFilters: state.salesAndServiceFilter.filters,
    isOpen: state.salesAndServiceFilter.isOpen,
    isLinkForQuote: state.productDetails.isLinkForQuote,
    isLinkForExpert: state.productDetails.isLinkForExpert,
    productDataForQuote: state.productDetails.productDataForQuote,
    wishlistProducts:
      state.wishlistProducts && state.wishlistProducts.wishlistItems ? state.wishlistProducts.wishlistItems : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilters: items => dispatch(setSalesAndServiceFilter(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAndServicePointsFilterCategory);
