import React, { useEffect } from 'react';
import i18n from 'i18next';
import Dialog from '../../Common/Dialog';
import { ShopProduct, ShopProductVariant } from '../lib/shopify/types';
import Table, { Column } from '../../Common/Table';
import ProductVariantSelectorTitle from './ProductVariantSelectorTitle';
import Toggle from '../../Common/Toggle/Toggle';
import { RouteComponentProps } from 'react-router';
import SelectVariantMobile from '../SelectVariantMobile';
import { useViewportSize } from '../../../hooks/useViewportSize';
import { sortProductsByAvailability, sortProductsBySKU } from '../lib/shopify/leisterHelpers';

export type TechSpec = {
  name: string;
  value: string;
};

export type ProductVariantSelectorData = {
  id: number;
  articleNumber: string;
  shopProduct: ShopProduct;
  shopProductVariant?: ShopProductVariant;
  techSpec: TechSpec[];
  isInOnlineShop: boolean;
  url: string;
  tags?: string[];
  highlighted?: boolean;
  [key: string]: string | string[] | number | boolean | ShopProduct | ShopProductVariant | TechSpec[] | undefined;
};

type ProductVariantSelectorProps = {
  data: ProductVariantSelectorData[];
  columns: Column<ProductVariantSelectorData>[];
  isOpen?: boolean;
  onClose?: () => void;
  history: RouteComponentProps['history'];
  shouldRenderShop?: boolean;
};

export function ProductVariantSelector({
  data,
  columns,
  isOpen = false,
  onClose = () => undefined,
  history,
  shouldRenderShop = false
}: ProductVariantSelectorProps): JSX.Element {
  const { viewportIsDesktop } = useViewportSize();
  const [filteredData, setFilteredData] = React.useState(data);

  useEffect(() => {
    data.sort((a, b) => sortProductsBySKU(a, b));
    data.sort((a, b) => sortProductsByAvailability(a.shopProductVariant, b.shopProductVariant));
    data.sort((a, b) => {
      // if someof the prices are not there, we don't want to sort by price
      if (!a.shopProductVariant?.price?.amount || !b.shopProductVariant?.price?.amount) {
        return 0;
      }

      return parseFloat(a.shopProductVariant?.price.amount) - parseFloat(b.shopProductVariant?.price.amount);
    });
    setFilteredData(data);
  }, [data]);

  function handleRowClick(row: ProductVariantSelectorData) {
    if (row.shopProduct?.condition?.value === 'used') {
      // Add special attribution to the demo products URL
      history.push(`${row.url}?condition=used`);
    } else {
      history.push(row.url);
    }
    onClose();
  }

  return (
    <Dialog
      isOpen={isOpen}
      title={viewportIsDesktop ? <ProductVariantSelectorTitle count={data.length} /> : null}
      onClose={onClose}
    >
      {viewportIsDesktop ? (
        <div className='ProductVariantSelector__Content'>
          <Toggle
            onChange={event => {
              const checked = event.target.checked;
              setFilteredData(data.filter(variant => (checked ? variant.isInOnlineShop : true)));
            }}
            label={i18n.t('PRODUCT | Filter available in online shop')}
          />
          <div className='ProductVariantSelector__Table'>
            <Table<ProductVariantSelectorData> data={filteredData} columns={columns} onRowClick={handleRowClick} />
          </div>
        </div>
      ) : (
        <div className='ProductVariantSelector__Content ProductVariantSelector__Content--Mobile'>
          {shouldRenderShop && (
            <>
              <div className='ProductVariantSelector__title Dialog__title Dialog__title--mobile'>
                {i18n.t('SHOP | Available in online shop mobile title')} (
                {filteredData.filter(variant => variant.isInOnlineShop).length})
              </div>
              {filteredData
                .filter(variant => variant.isInOnlineShop)
                .map(variant => (
                  <SelectVariantMobile
                    key={variant.id}
                    row={variant}
                    onClick={url => {
                      history.push(url);
                      onClose();
                    }}
                  />
                ))}
            </>
          )}
          <div className='ProductVariantSelector__title Dialog__title Dialog__title--mobile'>
            {i18n.t('SHOP | More variants mobile title')} (
            {filteredData.filter(variant => !variant.isInOnlineShop).length})
          </div>
          {filteredData
            .filter(variant => !variant.isInOnlineShop)
            .map((variant, index) => (
              <SelectVariantMobile
                key={variant.id}
                row={variant}
                onClick={url => {
                  history.push(url);
                  onClose();
                }}
              />
            ))}
        </div>
      )}
    </Dialog>
  );
}

export default ProductVariantSelector;
