import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GreenCircleCheckIcon from '../../Common/Icons/GreenCircleCheckIcon';

class SuccessMessage extends React.Component {
  render() {
    if (!this.props.fields) {
      return <div className='SuccessMessage' />;
    }
    return (
      <div className='SuccessMessage'>
        <div className='SuccessMessage-Rectangle' />
        <div className='SuccessMessage-Content'>
          <GreenCircleCheckIcon />
          <div className='SuccessMessage-TextWrapper'>
            <Text field={this.props.fields.Title} tag='span' className='SuccessMessage-Title' />
            <Text field={this.props.fields.Text} tag='span' className='SuccessMessage-Text' />
          </div>
        </div>
      </div>
    );
  }
}

export default SuccessMessage;
