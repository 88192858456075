import { getProductApiHost, getApiGatewayHost } from '../AppGlobals';

export const apiGatewayHost = getApiGatewayHost();

export const prodApiHostName = getProductApiHost();

export const REDIRECT_PAGE = 'RedirectPage';

export const SUCCESS_STATUS_CODE = 200;

export const NOT_FOUND_STATUS_CODE = 404;

export const CONFLICT_ERROR_CODE = 409;

export const ALREADY_IN_ACCOUNT_STATUS_CODE = 418;

export const IN_OTHER_ACCOUNT_STATUS_CODE = 419;

export const SERVER_ERROR_STATUS_CODE = 500;

export const FORBIDDEN_STATUS_CODE = 403;
