import React from 'react';

// Improves the existing <Text /> component from '@sitecore-jss/sitecore-jss-react' version 15
// by fixing the multiline rendering. The fix was based on the link below, but without the typescript part
// https://github.com/Sitecore/jss/blob/v16.0.0/packages/sitecore-jss-react/src/components/Text.tsx

const Text = ({ field, tag = undefined, editable = undefined, encode = undefined, ...otherProps }) => {
  if (!field || (!field.editable && !field.value)) {
    return null;
  }

  // can't use editable value if we want to output unencoded
  if (!encode) {
    // eslint-disable-next-line no-param-reassign
    editable = false;
  }

  const value = (field.editable && editable ? field.editable : field.value) || '';

  let output = [value];

  // when value isn't formatted, we should format line breaks
  if (!field.editable && value) {
    const splitted = value.split('\n');

    // this was missing in the v15 release,
    // which caused the text to be rendered twice, but added in v16
    if (splitted.length) {
      output = [];
    }

    splitted.forEach((str, i) => {
      const isLast = i === splitted.length - 1;

      output.push(str);

      if (!isLast) {
        output.push(<br key={i} />);
      }
    });
  }

  const setDangerously = (field.editable && editable) || !encode;

  let children = null;
  const htmlProps = {
    ...otherProps
  };

  if (setDangerously) {
    htmlProps.dangerouslySetInnerHTML = {
      __html: output
    };
  } else {
    children = output;
  }

  if (tag || setDangerously) {
    return React.createElement(tag || 'span', htmlProps, children);
  }
  return <React.Fragment>{children}</React.Fragment>;
};

Text.defaultProps = {
  editable: true,
  encode: true
};

Text.displayName = 'Text';

export default Text;
