import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { LANG } from '../../../../Common/CookieSettings/CookieConstants';
import { getCookie, removeProductFromCompareCookie } from '../../../../Common/CookieSettings/CookieUtils';
import GeneralLink from '../../../../Common/GeneralLink';
import { removeWhiteSpaces, addProductOnDataLayer } from '../../../../../utils/Utils';
import { getSitecoreApiHost } from '../../../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../../../AppRoutes';

/**
 * @class CompareStickyMenuCard  renders a product  with  clickable title and a  link
 */

class CompareStickyMenuCard extends React.Component {
  /**
   * @method  getTitle returns a clickable text
   */

  getTitle = link => {
    const { masterProductName, masterProductNameEn, id, categoryEn, brandName, articleNumber } = this.props.item;
    return (
      <div
        onClick={() => {
          addProductOnDataLayer(id, masterProductNameEn, categoryEn, link, brandName, articleNumber);
        }}
        onKeyDown={e => {
          if (e.target === 13)
            addProductOnDataLayer(id, masterProductNameEn, categoryEn, link, brandName, articleNumber);
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink
          fields={{
            value: {
              href: link
            }
          }}
          className='CompareStickyMenu-Title'
        >
          {masterProductName}
        </GeneralLink>
      </div>
    );
  };

  /**
   * @method  getButtomLink returns a link with onClick event
   */

  getButtomLink = (
    link,
    dealerClass,
    label,
    onClickMethod,
    onClickSetProductToQuote,
    productLink,
    onClickQuoteValidation
  ) => {
    return (
      <div
        onClick={() => {
          this.methodsForBottomLinks(onClickMethod, productLink, onClickSetProductToQuote, onClickQuoteValidation);
        }}
        onKeyDown={e => {
          if (e.target === 13)
            this.methodsForBottomLinks(onClickMethod, productLink, onClickSetProductToQuote, onClickQuoteValidation);
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink fields={link} className='CompareStickyMenu-Link' clickClassName={dealerClass}>
          <Text field={label} />
        </GeneralLink>
      </div>
    );
  };

  methodsForBottomLinks = (method, link, productQuote, quoteValidate) => {
    const { articleNumber, masterProductName, picture, solutions } = this.props.item,
      productDataToQuote = {
        name: masterProductName,
        articleNo: articleNumber,
        picture: picture && picture.url,
        solutions: solutions
      };
    method(link);
    if (productQuote) {
      productQuote(productDataToQuote);
    }
    if (quoteValidate) {
      quoteValidate(true);
    }
  };

  /**
   * @method getStickyProducts return a list of CompareStickyMenuCard according to the selected category
   */

  getStickyProducts = () => {
    const {
        'Find A Dealer Label': dealerLabel,
        'Choose An Option Label': chooseOptionLabel,
        'Find A Dealer Link': dealerLink,
        'Get A Quote Label': quoteLabel,
        'Get A Quote Page Link': getAQuotePageLink
      } = this.props.fields,
      currentLang = getCookie(LANG),
      { articleNumber, masterProductNameEn } = this.props.item,
      productLink = '/' + currentLang + PRODUCT_PAGE_ROUTE + '/' + masterProductNameEn.replace(/ /g, '-');
    if (articleNumber) {
      const selectedProductLink = productLink + '/' + articleNumber,
        { favouriteContact } = this.props,
        getAQuoteFormLink = getAQuotePageLink && getAQuotePageLink.value && getAQuotePageLink.value.href,
        clickClass =
          'button_find_dealer_compare_list ' + removeWhiteSpaces(masterProductNameEn + articleNumber || null);
      if (favouriteContact) {
        return (
          <React.Fragment>
            {this.getTitle(selectedProductLink)}
            {this.getButtomLink(
              { value: { href: getAQuoteFormLink } },
              clickClass,
              quoteLabel,
              this.props.getAQuoteLink,
              this.props.setProductToQuote,
              getSitecoreApiHost() + selectedProductLink,
              this.props.quoteValidation
            )}
          </React.Fragment>
        );
      }
      return (
        <div>
          {this.getTitle(selectedProductLink)}
          {this.getButtomLink(
            dealerLink,
            clickClass,
            dealerLabel,
            this.props.getAQuoteLink,
            this.props.setProductToQuote,
            getSitecoreApiHost() + selectedProductLink,
            this.props.quoteValidation
          )}
        </div>
      );
    }
    return (
      <div>
        {this.getTitle(productLink)}
        {this.getButtomLink({ value: { href: productLink } }, '', chooseOptionLabel, this.deleteProductFromCompareList)}
      </div>
    );
  };

  /**
   * @method  deleteProductFromCompareList delete product from compare list
   */

  deleteProductFromCompareList = () => {
    const { item, onClick } = this.props;

    //remove product from cookies
    removeProductFromCompareCookie(item.id);

    //remove product from store
    if (onClick) {
      onClick(item.id);
    }
  };

  render() {
    return <div className='CompareStickyMenu-Item'>{this.getStickyProducts()}</div>;
  }
}

export default CompareStickyMenuCard;
