import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PenIcon from '../../../Common/Icons/PenIcon';

class PersonalInformations extends React.Component {
  getRow(label, value) {
    return (
      <div className='PersonalInformations-Row'>
        <Text tag='div' className='PersonalInformations-Row-Labels' field={label} />
        <div className='PersonalInformations-Row-TextColumn'>{value}</div>
      </div>
    );
  }

  render() {
    if (
      !(this.props.fields && this.props.fields.data && this.props.fields.data.item && this.props.fields.data.userQuery)
    ) {
      return null;
    }

    const {
      item: {
        personalInformationsLabel,
        nameLabel,
        customerNumberLabel,
        displayNameLabel,
        editPersonalInformationsLink,
        editLabel
      },
      userQuery: { customernumber, displayname, firstname }
    } = this.props.fields.data;

    return (
      <div className='PersonalInformations'>
        <div className='PersonalInformations-Headline'>
          <Text tag='h3' className='PersonalInformations-Headline-Title' field={personalInformationsLabel} />
          <div className='PersonalInformations-Headline-EditSection'>
            <a href={editPersonalInformationsLink.jss.value.href}>
              <PenIcon />
              <Text tag='div' className='PersonalInformations-Headline-EditSection-Text' field={editLabel} />
            </a>
          </div>
        </div>
        {this.getRow(nameLabel, firstname)}
        {this.getRow(customerNumberLabel, customernumber)}
        {this.getRow(displayNameLabel, displayname)}
      </div>
    );
  }
}
export default PersonalInformations;
