import React from 'react';
import { Visible } from 'react-grid-system';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import DeleteIcon from '../../../Common/Icons/DeleteIcon';
import GeneralLink from '../../../Common/GeneralLink';
import { removeProductFromCompareCookie, getCookie } from '../../../Common/CookieSettings/CookieUtils';
import { LANG } from '../../../Common/CookieSettings/CookieConstants';
import { removeWhiteSpaces, addProductOnDataLayer } from '../../../../utils/Utils';
import { getSitecoreApiHost } from '../../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../../AppRoutes';
/**
 * @class CompareProductCard  renders a product  with image, clickable title, description, link and a delete button
 */

class CompareProductCard extends React.Component {
  /**
   * @method  getButtomLink returns a link with onClick event
   */

  getButtomLink = (
    link,
    dealerClass,
    label,
    onClickMethod,
    onClickSetProductToQuote,
    productLink,
    onClickQuoteValidation
  ) => {
    return (
      <div
        tabIndex='0'
        role='button'
        onClick={() => {
          this.methodsForBottomLinks(onClickMethod, productLink, onClickSetProductToQuote, onClickQuoteValidation);
        }}
        onKeyDown={e => {
          if (e.target === 13)
            this.methodsForBottomLinks(onClickMethod, productLink, onClickSetProductToQuote, onClickQuoteValidation);
        }}
      >
        <GeneralLink fields={link} className='CompareProductCard-Link' clickClassName={dealerClass}>
          <Text field={label} />
        </GeneralLink>
      </div>
    );
  };

  methodsForBottomLinks = (method, link, productQuote, quoteValidate) => {
    const { articleNumber, masterProductName, picture, solutions } = this.props.item,
      productDataToQuote = {
        name: masterProductName,
        articleNo: articleNumber,
        picture: picture && picture.url,
        solutions: solutions
      };

    method(link);
    if (productQuote) {
      productQuote(productDataToQuote);
    }
    if (quoteValidate) {
      quoteValidate(true);
    }
  };
  /**
   * @method  displayArticleNumber returns a text
   */

  displayArticleNumber = () => {
    const { articleNumber } = this.props.item,
      { 'Article Number Label': articleLabel } = this.props.fields;
    return (
      <span className='CompareProductCard-Article'>
        <Text field={articleLabel} />
        &nbsp;
        {articleNumber}
      </span>
    );
  };

  /**
   * @method  getBottomDetails displays a text and a link, according to data
   */

  getBottomDetails = () => {
    const { articleNumber, noOfVariants, masterProductNameEn } = this.props.item,
      {
        'Choose An Option Label': chooseOptionLabel,
        'Find A Dealer Label': dealerLabel,
        'Options Available Label': optionsLabel,
        'Find A Dealer Link': dealerLink,
        'Get A Quote Label': quoteLabel,
        'Get A Quote Page Link': getAQuotePageLink
      } = this.props.fields,
      currentLang = getCookie(LANG),
      articleNumberUrl = articleNumber ? articleNumber.replace('.', '-') : '',
      chooseOptionsLink =
        '/' + currentLang + PRODUCT_PAGE_ROUTE + '/' + masterProductNameEn.replace(/ /g, '-') + '/' + articleNumberUrl;

    if (articleNumber) {
      const { favouriteContact } = this.props,
        getAQuoteFormLink = getAQuotePageLink && getAQuotePageLink.value && getAQuotePageLink.value.href,
        measuringClassname =
          'button_find_dealer_compare_list ' + removeWhiteSpaces(masterProductNameEn + articleNumber || null);
      if (favouriteContact) {
        return (
          <React.Fragment>
            {this.displayArticleNumber()}
            {this.getButtomLink(
              { value: { href: getAQuoteFormLink } },
              measuringClassname,
              quoteLabel,
              this.props.getAQuoteLink,
              this.props.setProductToQuote,
              getSitecoreApiHost() + chooseOptionsLink,
              this.props.quoteValidation
            )}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {this.displayArticleNumber()}
          {this.getButtomLink(
            dealerLink,
            measuringClassname,
            dealerLabel,
            this.props.getAQuoteLink,
            this.props.setProductToQuote,
            getSitecoreApiHost() + chooseOptionsLink,
            this.props.quoteValidation
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <span className='CompareProductCard-Options'>
          {noOfVariants}&nbsp;
          <Text field={optionsLabel} />
        </span>
        {this.getButtomLink(
          { value: { href: chooseOptionsLink } },
          '',
          chooseOptionLabel,
          this.deleteProductFromCompareList
        )}
      </React.Fragment>
    );
  };

  /**
   * @method  getDeleteButton returns a deleten button
   */

  getDeleteButton = () => {
    return (
      <div
        className='CompareProductCard-DeleteButton'
        onClick={this.deleteProductFromCompareList}
        onKeyDown={e => {
          if (e.target === 13) this.deleteProductFromCompareList();
        }}
        role='button'
        tabIndex='0'
        aria-label='Remove Product from compare list'
      >
        <DeleteIcon />
      </div>
    );
  };

  /**
   * @method  getImage returns an image
   */

  getImage() {
    const { picture } = this.props.item;
    if (picture) {
      return (
        <div className='CompareProductCard-Image'>
          <Image field={{ value: { src: picture.url } }} />
        </div>
      );
    }
  }

  /**
   * @method  getTitle  returns a clickable text
   */

  getTitle = () => {
    const currentLang = getCookie(LANG),
      { articleNumber, masterProductName, masterProductNameEn, categoryEn, id, brandName } = this.props.item,
      articleNumberUrl = articleNumber ? articleNumber.replace('.', '-') : '',
      productUrl =
        '/' + currentLang + PRODUCT_PAGE_ROUTE + '/' + masterProductNameEn.replace(/ /g, '-') + '/' + articleNumberUrl;
    return (
      <div
        onClick={() => {
          addProductOnDataLayer(id, masterProductNameEn, categoryEn, productUrl, brandName, articleNumber);
        }}
        onKeyDown={e => {
          if (e.target === 13)
            addProductOnDataLayer(id, masterProductNameEn, categoryEn, productUrl, brandName, articleNumber);
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink
          fields={{
            value: {
              href: productUrl
            }
          }}
          className='CompareProductCard-Title'
        >
          {masterProductName}
        </GeneralLink>
      </div>
    );
  };

  /**
   * @method  deleteProductFromCompareList delete product from compare list
   */

  deleteProductFromCompareList = () => {
    const { item, onClick } = this.props;

    //remove product from cookies
    removeProductFromCompareCookie(item.id);

    //remove product from store
    if (onClick) {
      onClick(item.id);
    }
  };

  render() {
    return (
      <div className='CompareProductCard'>
        <div className='CompareProductCard-Container'>
          <Visible lg xl>
            {this.getDeleteButton()}
            {this.getImage()}
            {this.getTitle()}
          </Visible>
          <Visible xs sm md>
            <div className='CompareProductCard-Heading'>
              {this.getTitle()}
              {this.getDeleteButton()}
            </div>
          </Visible>
          <span className='CompareProductCard-Description'>{this.props.item.masterProductMetaDescription}</span>
          {this.getBottomDetails()}
        </div>
      </div>
    );
  }
}

export default CompareProductCard;
