import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import ArrowDown from '../../Common/Icons/ArrowDown';
import getStickyMenuAnchor from '../../../utils/getStickyMenuAnchor';
import { TextField } from '../../../utils/types';

type Props = { label: TextField; sitecoreContext: any };

const ProductDetailsLabel: React.FC<Props> = props => {
  const productDetailsAnchor = getStickyMenuAnchor('ProductDetails', props.sitecoreContext);
  return (
    <div className='ProductDetailsLabel'>
      <a href={`#${productDetailsAnchor}`}>
        <ArrowDown />
        <Text field={props.label} />
      </a>
    </div>
  );
};

export default withSitecoreContext()(ProductDetailsLabel);
