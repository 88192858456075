import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsInfraredHeatersPipelines,
  CoveoProductsInfraredHeatersSearchHubs
} from './InfraredHeatersLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import InfraredHeatersListerComponent from './InfraredHeatersLister';

export const InfraredHeatersLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsInfraredHeatersPipelines[siteName],
    searchHub: CoveoProductsInfraredHeatersSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <InfraredHeatersListerComponent />
    </CoveoComponent>
  );
};

export default InfraredHeatersLister;
