import React from 'react';
import { Placeholder, withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { setCareerFormData, setJobInformationHidden } from '../../../store/contactForms/actions';
import { setProductForGetAQuoteLink, setIsLinkForQuote, setDataForProductQuote } from '../../../store/product/actions';
import Dropdown from '../../Common/Dropdown/index';
import { setValueInput, checkIfUserIsLoggedIn } from '../../../utils/Utils';
import { setWishlistProductsAnonymous, setCurrentWishListName } from '../../../store/wishlist/actions';
import { setDeviceInfoToContactPage } from '../../../store/mydevices/actions';
import ContactProductCard from '../ContactProductCard/index';
import { getCookie, getSessionStorage, getLocalStorage } from '../../Common/CookieSettings/CookieUtils';
import { WISHLIST, LANG } from '../../Common/CookieSettings/CookieConstants';
import ChevronDown from '../../Common/Icons/ChevronDown';
import ContactCareerCard from '../ContactCareerCard';
import { getSitecoreApiHost } from '../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';

class ContactTwoColumnNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWishlist: { name: '', title: '' },
      itemsProductsToShow: 3,
      uploadInputToShow: 0,
      currentForm: null,
      isRedirect: false
    };
  }

  componentDidUpdate() {
    const { 'Is Success Page': successPage } = this.props.sitecoreContext.route.fields;
    if (!(successPage && successPage.value)) {
      const { sitecoreContext, fields, productDataForQuote, wishlistItems, setProductForGetAQuoteLink } = this.props,
        { 'Get A Quote Form': quoteForm } = fields,
        { 'Contact Form': contactForm } = sitecoreContext.route.fields;

      this.setProductUrlInHiddenInput();
      this.setJobInformationHiddenInput();
      this.setDeviceInfoHiddenInput();
      this.displayAllFileUpload();
      this.setEmailInHiddenInput();
      if (contactForm.value === quoteForm.value && !productDataForQuote) {
        this.getDataForGetAQuote();
      }
      if (contactForm.value === quoteForm.value && wishlistItems.length && !productDataForQuote) {
        setProductForGetAQuoteLink(this.getProductsLink(wishlistItems) || ' ');
      }
    }
  }

  componentWillUnmount() {
    const {
      setIsLinkForQuote,
      setCurrentWishListName,
      setDataForProductQuote,
      setCareerFormData,
      setJobInformationHidden,
      setDeviceInfoToContactPage
    } = this.props;
    if (!window._CHANGE_MY_CONTACT_) {
      setIsLinkForQuote(false);
      setDataForProductQuote(null);
      setCurrentWishListName(null);
      setDeviceInfoToContactPage(null);
    } else {
      window._CHANGE_MY_CONTACT_ = false;
    }
    setCareerFormData(null);
    setJobInformationHidden(null);
  }
  getDropdown = () => {
    const { dropdownForms, fields, sitecoreContext } = this.props,
      { 'Contact Form': contactForm } = sitecoreContext.route.fields,
      currentFormCheck = dropdownForms.find(item => item.form === contactForm.value),
      currentForm = currentFormCheck && currentFormCheck.title ? currentFormCheck : dropdownForms[0];
    if (!dropdownForms || dropdownForms.length < 1) {
      return (
        <Dropdown
          label={fields['Your Concern'].value}
          currentItem={{ title: '' }}
          items={dropdownForms || []}
          onSet={this.onSetForm}
          height='auto'
        />
      );
    }

    return (
      <Dropdown
        label={fields['Your Concern'].value}
        currentItem={currentForm}
        items={dropdownForms || []}
        onSet={this.onSetForm}
        height='auto'
      />
    );
  };

  onSetForm = item => {
    this.setState({
      currentForm: item.link,
      isRedirect: true,
      uploadInputToShow: 0
    });
  };
  getDataForGetAQuote = () => {
    const {
      sitecoreContext,
      currentWishlistName,
      setCurrentWishListName,
      currentWishListIndex,
      setWishlistProductsAnonymous,
      wishlistItems
    } = this.props;
    const { currentWishlist } = this.state;
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      if (currentWishlist.name === '') {
        if (currentWishlistName) {
          this.setState({
            currentWishlist: {
              name: currentWishlistName,
              title: currentWishlistName + ' (' + wishlistItems.length + ')'
            }
          });
          setCurrentWishListName(currentWishlistName);
        } else {
          const wishListIndex = currentWishListIndex || getLocalStorage('currentWishListIndex') || 0;
          const { wishLists } = this.props;
          if (wishListIndex !== undefined && wishLists[wishListIndex]) {
            const wishlistName = wishLists[wishListIndex].name + ' (' + wishLists[wishListIndex].items.length + ')';
            this.setState({
              currentWishlist: { name: wishLists[wishListIndex].name, title: wishlistName }
            });
            setCurrentWishListName(wishLists[wishListIndex].name);
          }
        }
      }
    } else {
      // Anonymous user
      const cookieWishlistData = getCookie(WISHLIST) ? JSON.parse(getCookie(WISHLIST)) : {};
      const hasCookieWishlist = Object.keys(cookieWishlistData).length;
      if (hasCookieWishlist) {
        const cookieWishlistName = Object.keys(cookieWishlistData)[0];
        const cookieWishlist = cookieWishlistData[cookieWishlistName];
        const cookieWishlistTitle = cookieWishlistName + ' (' + cookieWishlist.length + ')';
        // No wishlist set yet in redux
        if (wishlistItems.length === 0) {
          setWishlistProductsAnonymous(cookieWishlistName, undefined, sitecoreContext.site.name);
          setCurrentWishListName(cookieWishlistName);
        }
        // No local currentWishlist set yet
        if (currentWishlist.title === '') {
          this.setState({
            currentWishlist: {
              name: cookieWishlistName,
              title: cookieWishlistTitle
            }
          });
        }
      }
    }
  };

  /**
   * @method checkIFWeHaveInput
   * @description  check if the element exists in html and returns the element
   * @param {string} selector the css selector of the checked element
   */

  checkIFWeHaveInput = async selector => {
    while (document.querySelector(selector) === null && document.querySelector('form') === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }

    return document.querySelector(selector);
  };

  async setEmailInHiddenInput() {
    const { currentInfoPointEmail, personEmail, currentInfoPointIsOptOutForFormMailSubmission } = this.props,
      personContact = getSessionStorage('person') ? JSON.parse(getSessionStorage('person')) : null;

    const currentInfoPointEmailOptOutAware = currentInfoPointIsOptOutForFormMailSubmission ? '' : currentInfoPointEmail;

    if (currentInfoPointEmail !== null) {
      const emailInputElement = await this.checkIFWeHaveInput('.ContactInfoEmail input'),
        emailOriginalInputElement = await this.checkIFWeHaveInput('.ContactInfoEmailOriginal input'),
        detailsInputElement = await this.checkIFWeHaveInput('.ContactInfoDetails input');

      setValueInput(
        emailInputElement,
        (personEmail && personEmail.value) ||
          (personContact && personContact.email && personContact.email.value) ||
          // emailInputElement might be set to an empty string (see currentInfoPointEmailOptOutAware) if the sales and service point is opted out (to prevent sending mails through SC form submit action)
          currentInfoPointEmailOptOutAware
      );
      setValueInput(
        emailOriginalInputElement,
        (personEmail && personEmail.value) ||
          (personContact && personContact.email && personContact.email.value) ||
          // emailOriginalInputElement should always be set to currentInfoPointEmail (to ensure we have a value to send to SAP, even if the sales and service point is opted out)
          currentInfoPointEmail
      );

      setValueInput(detailsInputElement, this.setInputDetails());
    }
  }

  async displayFileUpload(fileInputClass) {
    const inputFileElement = await this.checkIFWeHaveInput(fileInputClass);
    if (inputFileElement) {
      inputFileElement.addEventListener('change', this.onInputFileChange);
      const closeIcon = document.createElement('span'),
        textClose = document.createTextNode('+');
      closeIcon.classList.add('FormFileUploadClose');
      closeIcon.appendChild(textClose);
      if (!inputFileElement.nextElementSibling) {
        inputFileElement.insertAdjacentElement('afterend', closeIcon);
      }
      closeIcon.addEventListener('click', this.onFileClose);
    }
  }
  onInputFileChange = e => {
    const element = e.target,
      closeElement = element.nextElementSibling;
    if (element.value !== '') {
      element.style.display = 'inline-block';
      closeElement.style.display = 'inline-block';
    } else {
      element.style.display = 'none';
      closeElement.style.display = 'none';
    }
  };

  onFileClose = e => {
    const element = e.target,
      inputFile = element.previousElementSibling;
    inputFile.value = '';
    inputFile.style.display = 'none';
    element.style.display = 'none';
  };

  displayAllFileUpload = () => {
    this.displayFileUpload('.Form-FileUpload.File-0 input');
    this.displayFileUpload('.Form-FileUpload.File-1 input');
    this.displayFileUpload('.Form-FileUpload.File-2 input');
    this.displayFileUpload('.Form-FileUpload.File-3 input');
    this.displayFileUpload('.Form-FileUpload.File-4 input');
    const allInputUpload = document.querySelectorAll('div.Form-FileUpload');
    [...allInputUpload].forEach((item, index) => {
      if (index === this.state.uploadInputToShow) {
        item.style.display = 'block';
      }
    });

    this.getLoadMoreUploadButton();
  };

  incrementDisplayedUploadInput = () => {
    const numberToIncrement = 1;
    this.setState(state => ({
      uploadInputToShow: state.uploadInputToShow + numberToIncrement
    }));
  };

  async getLoadMoreUploadButton() {
    const buttonFileElement = await this.checkIFWeHaveInput('.LoadMoreUploadInput p');
    if (buttonFileElement) {
      buttonFileElement.addEventListener('click', this.incrementDisplayedUploadInput);
    }
    const numberOfInputFileUpload = document.querySelectorAll('div.Form-FileUpload').length;
    if (buttonFileElement && numberOfInputFileUpload === this.state.uploadInputToShow + 1) {
      buttonFileElement.style.display = 'none';
    }
  }
  /**
   * @description sets the details for the input according to condition
   */

  setInputDetails = () => {
    const { contactInfoDetails, contactPerson } = this.props,
      { name, street, number, city, country } = contactInfoDetails,
      { name: personName, salesPoint } = contactPerson,
      personContact = getSessionStorage('person') ? JSON.parse(getSessionStorage('person')) : null;

    if (contactPerson && salesPoint && contactPerson.email) {
      const {
          City: personCity,
          Street: personStreet,
          'Street Number': streetNumber,
          Country
        } = salesPoint && salesPoint.fields,
        personCountry = Country && Country.fields.RegionName;
      return this.setDetails(
        personName,
        personStreet && personStreet.value,
        streetNumber && streetNumber.value,
        personCity && personCity.value,
        personCountry && personCountry.value
      );
    } else if (personContact && personContact.salesPoint && personContact.email) {
      const {
          City: personCity,
          Street: personStreet,
          'Street Number': streetNumber,
          Country
        } = personContact.salesPoint && personContact.salesPoint.fields,
        { name: personName } = personContact,
        personCountry = Country && Country.fields.RegionName;
      return this.setDetails(
        personName,
        personStreet && personStreet.value,
        streetNumber && streetNumber.value,
        personCity && personCity.value,
        personCountry && personCountry.value
      );
    }
    return this.setDetails(name, street, number, city, country);
  };

  /**
   * @description concatenates the values of the details object
   */

  setDetails = (name, street, number, city, country) =>
    name +
    this.setComma(name) +
    street +
    ' ' +
    number +
    this.setComma(number || street) +
    city +
    this.setComma(city && country) +
    country;

  /**
   * @description display comma or not, according to data
   */

  setComma = item => (item ? ', ' : '');

  async setProductUrlInHiddenInput() {
    const { productPageLinkForGetAQuote } = this.props,
      productLinkInputElement = await this.checkIFWeHaveInput('.ProductLink input');
    if (
      productPageLinkForGetAQuote &&
      productLinkInputElement &&
      productLinkInputElement.value !== productPageLinkForGetAQuote
    ) {
      setValueInput(productLinkInputElement, productPageLinkForGetAQuote);
    }
  }
  async setJobInformationHiddenInput() {
    const { jobInfoHidden } = this.props,
      jobInformationInputElement = await this.checkIFWeHaveInput('.JobInformation input');
    if (jobInfoHidden && jobInformationInputElement && jobInformationInputElement.value !== jobInfoHidden) {
      setValueInput(jobInformationInputElement, jobInfoHidden);
    }
  }
  async setDeviceInfoHiddenInput() {
    const { deviceInfo } = this.props,
      deviceInfoInputElement = await this.checkIFWeHaveInput('.DeviceInformation input');
    if (
      deviceInfo &&
      deviceInfo.deviceInfoHidden &&
      deviceInfoInputElement &&
      deviceInfoInputElement.value !== deviceInfo.deviceInfoHidden
    ) {
      setValueInput(deviceInfoInputElement, deviceInfo.deviceInfoHidden);
    }
  }

  getWishlistDropdownItems = () => {
    const { sitecoreContext, wishlistItems } = this.props;
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      return this.props.wishLists.map(item => {
        const numberOfProductsInList = item.items.length,
          wishlistName = item.name + ' (' + numberOfProductsInList + ')';
        return { name: item.name, title: wishlistName, products: item.items };
      });
    } else {
      // Anonymous user
      const cookieWishlistData = getCookie(WISHLIST) ? JSON.parse(getCookie(WISHLIST)) : {};
      const hasCookieWishlist = Object.keys(cookieWishlistData).length;
      if (hasCookieWishlist) {
        const cookieWishlistName = Object.keys(cookieWishlistData)[0];
        const cookieWishlistTitle = cookieWishlistName + ' (' + wishlistItems.length + ')';

        return [{ name: cookieWishlistName, title: cookieWishlistTitle, products: wishlistItems.length }];
      }
      return [];
    }
  };

  getWishlistDropdown = () => {
    const dropdownItems = this.getWishlistDropdownItems();
    if (!this.props.productDataForQuote && dropdownItems.length) {
      return (
        <Dropdown
          label={this.props.fields['Wishlist Label'].value}
          currentItem={this.state.currentWishlist}
          items={dropdownItems}
          onSet={this.onSetWishlist}
          height='auto'
        />
      );
    }
  };

  onSetWishlist = item => {
    const { sitecoreContext, setCurrentWishListName } = this.props;
    this.setState({ currentWishlist: item });
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      setCurrentWishListName(item.name);
    }
  };

  getProductsList = () => {
    const { wishlistItems, fields, productDataForQuote, isLinkForQuote } = this.props,
      dataToDisplay = productDataForQuote && isLinkForQuote ? [productDataForQuote] : wishlistItems;
    if (dataToDisplay.length) {
      return dataToDisplay.slice(0, this.state.itemsProductsToShow).map(item => {
        return !!item.articleNo && <ContactProductCard key={item.articleNo} item={item} fields={fields} />;
      });
    }
  };

  getMoreButtonForWishlistProducts = () => {
    const { wishlistItems, productDataForQuote, fields } = this.props,
      remainingItems = wishlistItems.length - this.state.itemsProductsToShow;
    if (remainingItems > 0 && !productDataForQuote) {
      return (
        <div
          onClick={this.incrementDisplayedItems}
          onKeyDown={e => {
            if (e.target === 13) this.incrementDisplayedItems();
          }}
          role='button'
          tabIndex='0'
          className='ContactTwoColumn-GetAQuoteMoreButton'
        >
          <ChevronDown />
          <Text field={fields['Show More Button Label']} tag='span' />
        </div>
      );
    }
    return <div />;
  };
  incrementDisplayedItems = () => {
    const numberToIncrement = 1;
    this.setState(state => ({
      itemsProductsToShow: state.itemsProductsToShow + numberToIncrement
    }));
  };

  getProductsLink = data => {
    const currentLang = getCookie(LANG);

    let links = [];
    data.map(item => {
      const link =
        getSitecoreApiHost() +
        '/' +
        currentLang +
        PRODUCT_PAGE_ROUTE +
        '/' +
        item.masterProductNameEn.replace(/ /g, '-') +
        '/' +
        item.articleNo.replace('.', '-');
      links.push(link);

      return links;
    });
    return links.join('; ');
  };

  getCareerCard = () => {
    const { careerFormData } = this.props;
    if (careerFormData) {
      return <ContactCareerCard item={careerFormData} />;
    }
  };

  getProductSupportCard = () => {
    const { deviceInfo, fields } = this.props;
    if (deviceInfo) {
      return <ContactProductCard item={deviceInfo} fields={fields} />;
    }
  };

  getExpertProductCard = () => {
    const { fields, productDataForQuote, isLinkForExpert } = this.props;
    if (productDataForQuote && isLinkForExpert) {
      return <ContactProductCard item={productDataForQuote} fields={fields} />;
    }
  };

  render() {
    if (!this.props.fields || !this.props.rendering) {
      return <Row nogutter className='ContactTwoColumn componentContainer' />;
    }
    const { currentForm, isRedirect } = this.state;

    if (currentForm && isRedirect) {
      this.setState({ isRedirect: false });
      return <Redirect to={currentForm} />;
    }
    const { fields, sitecoreContext } = this.props,
      {
        'Get A Quote Form': quoteForm,
        'Work At Leister Form': careerForm,
        'Product Support Form': supportForm,
        'Talk To An Expert Form': talkToAnExpertForm,
        'Work at Leister Group Form': careerGroupForm,
        'Work at Axetris Form': careerAxetrisForm
      } = fields,
      { 'Contact Form': contactForm, 'Is Success Page': successPage } = sitecoreContext.route.fields;

    return (
      <Row nogutter className='ContactTwoColumn componentContainer'>
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          {!(successPage && successPage.value) && (
            <React.Fragment>
              {this.getDropdown()}
              {contactForm.value === quoteForm.value && (
                <React.Fragment>
                  <div>{this.getWishlistDropdown()}</div>
                  <div className='ContactTwoColumn-ProductsContainer'>{this.getProductsList()}</div>
                  <div>{this.getMoreButtonForWishlistProducts()}</div>
                </React.Fragment>
              )}
              {(contactForm.value === careerForm.value ||
                contactForm.value === careerGroupForm.value ||
                contactForm.value === careerAxetrisForm.value) &&
                this.getCareerCard()}
              {contactForm.value === supportForm.value && this.getProductSupportCard()}
              {talkToAnExpertForm && contactForm.value === talkToAnExpertForm.value && this.getExpertProductCard()}
            </React.Fragment>
          )}

          <Placeholder name='jss-form' rendering={this.props.rendering} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={1} xl={1} />
        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
          <Placeholder name='jss-contact-info' rendering={this.props.rendering} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentInfoPointIsOptOutForFormMailSubmission: state.contactForms.currentInfoPointIsOptOutForFormMailSubmission,
    currentInfoPointEmail: state.contactForms.currentInfoPointEmail,
    productPageLinkForGetAQuote: state.productDetails.productPageLinkForGetAQuote,
    personEmail: state.contactPerson && state.contactPerson.email,
    contactInfoDetails: state.contactInfoDetails,
    contactPerson: state.contactPerson,
    wishLists: state.wishlistProducts && state.wishlistProducts.wishLists ? state.wishlistProducts.wishLists : [],
    wishlistItems:
      state.wishlistProducts && state.wishlistProducts.wishlistItems ? state.wishlistProducts.wishlistItems : [],
    currentWishlistName: state.wishlistProducts && state.wishlistProducts.currentWishListName,
    currentWishListIndex:
      state.wishlistProducts && state.wishlistProducts.currentWishListIndex
        ? state.wishlistProducts.currentWishListIndex
        : 0,
    productDataForQuote: state.productDetails.productDataForQuote,
    careerFormData: state.contactForms.careerFormData,
    jobInfoHidden: state.contactForms.jobInfoHidden,
    deviceInfo: state.myDevices && state.myDevices.deviceInfo,
    dropdownForms: state.contactForms.dropdownForms,
    isLinkForQuote: state.productDetails.isLinkForQuote,
    isLinkForExpert: state.productDetails.isLinkForExpert
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setWishlistProductsAnonymous: (name, data, tenant) => dispatch(setWishlistProductsAnonymous(name, data, tenant)),
    setProductForGetAQuoteLink: link => dispatch(setProductForGetAQuoteLink(link)),
    setIsLinkForQuote: isLink => dispatch(setIsLinkForQuote(isLink)),
    setDataForProductQuote: productData => dispatch(setDataForProductQuote(productData)),
    setCurrentWishListName: name => dispatch(setCurrentWishListName(name)),
    setCareerFormData: jobData => dispatch(setCareerFormData(jobData)),
    setJobInformationHidden: jobInfo => dispatch(setJobInformationHidden(jobInfo)),
    setDeviceInfoToContactPage: deviceInfo => dispatch(setDeviceInfoToContactPage(deviceInfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(ContactTwoColumnNew)));
