import { useState, useEffect, useContext } from 'react';
import { useCoveoQuery } from '../../Coveo/CoveoQuery';
import { CoveoProductCarouselCardType, CoveoProductCarouselProps } from './types';
import { SearchEngineContext } from '../context/SearchEngineContext';
import { SearchEngine, buildResultsPerPage } from '@coveo/headless';

const DEFAULT_NUMBER_OF_CARD = 15;

const fieldsToInclude = [
  'variantarticlenumberraw',
  'pimproductid',
  'variantnametranslated',
  'variantname',
  'pimmetadescription',
  'mainpicture320',
  'mainpicture',
  'pimclassificationtranslated',
  'masterproductrelativeurl',
  'pimbrandname',
  'masterproductname',
  'productassetnamenormalized',
  'pimclassificationinitialen',
  'isphasedout'
];

const dataFormRequestIntoDataToDisplay = (results: any) => {
  return results.map((item: any) => {
    const { variantarticlenumberraw: articleNumber, ...itemData } = item.raw;

    return {
      Id: itemData.pimproductid,
      Name: articleNumber ? itemData.variantnametranslated : itemData.masterproductname,
      NameEn: articleNumber ? itemData.variantname : itemData.masterproductname,
      Description: itemData.pimmetadescription || '',
      ImageUrl: itemData.mainpicture320 || itemData.mainpicture,
      CategoryEn: articleNumber
        ? itemData?.pimclassificationtranslated
          ? itemData.pimclassificationtranslated.join()
          : ''
        : itemData?.pimclassificationinitialen
        ? itemData.pimclassificationinitialen.join()
        : '',
      ProductUrl: itemData.masterproductrelativeurl,
      pimbrandname: itemData.pimbrandname,
      articleNumber: articleNumber ? articleNumber : undefined
    };
  });
};

export const useCoveoProductCarouselData = (engine: SearchEngine, props: CoveoProductCarouselProps) => {
  const {
    state: { locale }
  } = useContext(SearchEngineContext);
  const { Query, 'Number Of Products': resultsNumber } = props.fields || {};

  let query = Query?.value ? `${Query.value} AND ` : '';
  query += '@z95xlanguage==' + locale;

  const cardsToShowCount = resultsNumber?.value ? +resultsNumber.value : DEFAULT_NUMBER_OF_CARD;

  const { resultListController, search } = useCoveoQuery({
    engine,
    fieldsToInclude,
    initUrlManager: false
  });

  buildResultsPerPage(engine, {
    initialState: { numberOfResults: cardsToShowCount }
  });

  const [resultListState, setResultListState] = useState(resultListController.state);
  const [productCarouselData, setCoveoProductCarouselData] = useState<CoveoProductCarouselCardType[]>([]);

  buildResultsPerPage(engine, {
    initialState: { numberOfResults: cardsToShowCount }
  });

  useEffect(() => {
    if (resultListState?.results?.length) {
      const dataToDisplay = dataFormRequestIntoDataToDisplay(resultListState.results);

      setCoveoProductCarouselData(dataToDisplay.slice(0, cardsToShowCount));
    }
  }, [resultListState, setCoveoProductCarouselData, cardsToShowCount]);

  useEffect(() => {
    resultListController.subscribe(() => setResultListState(resultListController.state));
  }, [resultListController, setResultListState]);

  useEffect(() => search(query), [search]);

  return {
    productCarouselData
  };
};
