import React from 'react';
import i18n from 'i18next';
import ConfirmationDialog from '../../ConfirmationDialog';
import { deleteAddress } from '../../../store/accountInformation/actions';
import { connect } from 'react-redux';

const DeleteAddress = ({
  onClose,
  onConfirm,
  deleteAddress,
  addressId
}: {
  addressId: string;
  onClose: () => void;
  onConfirm: () => void;
  deleteAddress: (addressId: string) => void;
}): JSX.Element => {
  return (
    <ConfirmationDialog
      onClose={onClose}
      onPrimaryButtonClick={() => {
        deleteAddress(addressId);
        onConfirm();
      }}
      modalTitle={i18n.t('ADDRESS_MANAGEMENT | Delete address title')}
      primaryButtonLabel={i18n.t('ADDRESS_MANAGEMENT | Delete address confirm')}
      secondaryButtonLabel={i18n.t('ADDRESS_MANAGEMENT | Delete address cancel')}
      onSecondaryButtonClick={onClose}
      transparentOverlay
    >
      {i18n.t('ADDRESS_MANAGEMENT | Delete address info')}
    </ConfirmationDialog>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => {
  return {
    deleteAddress: (addressId: string) => dispatch(deleteAddress(addressId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddress);
