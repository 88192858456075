import { BreadcrumbManager, BreadcrumbValue, FacetValue, NumericFacetValue } from '@coveo/headless';
import i18n from 'i18next';
import React, { useState, useEffect, useContext } from 'react';
import ClearIcon from '../../Common/Icons/Clear';
import { formatRange } from '../RangeFacet';
import { AccessoryCategoryFacet } from '../Lister/Products/AccessoriesLister/AccessoriesLister.settings';
import { SearchContext } from '../context/SearchContext';
import { scrollToTopOfSearchResults } from '../SearchResults/scrollToSearchResults';

type FacetBreadcrumbsProps = {
  controller: BreadcrumbManager;
};

const SkipFacets = [AccessoryCategoryFacet];

export const FacetBreadcrumbs: React.FC<FacetBreadcrumbsProps> = ({ controller }) => {
  const [state, setState] = useState(controller.state);
  const { state: searchState } = useContext(SearchContext);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  if (!state?.hasBreadcrumbs) {
    return null;
  }

  function deselectBreadcrumb(breadcrumb: BreadcrumbValue<FacetValue | NumericFacetValue>) {
    breadcrumb.deselect();
    scrollToTopOfSearchResults();
  }

  return (
    <ul className='FacetBreadcrumb__List'>
      {state.facetBreadcrumbs
        .filter(facet => !SkipFacets.includes(facet.facetId))
        .map(facet =>
          facet.values.map(breadcrumb => (
            <li key={`${facet.facetId}${breadcrumb.value.value}`} className='FacetBreadcrumb__List-Item'>
              <button
                onClick={() => deselectBreadcrumb(breadcrumb)}
                className='FacetBreadcrumb'
                aria-label={i18n.t('SEARCH | Facets Breadcrumb Remove')}
              >
                <span className='FacetBreadcrumb__Text'>{breadcrumb.value.value}</span>
                <span className='FacetBreadcrumb__Clear'>
                  <ClearIcon />
                </span>
              </button>
            </li>
          ))
        )}
      {state.numericFacetBreadcrumbs.map(facet => {
        const precision = searchState.numericFacets.find(f => f.field === facet.facetId)?.precision;
        const unit = searchState.numericFacets.find(f => f.field === facet.facetId)?.unit;

        return facet.values.map(breadcrumb => (
          <li
            key={`${facet.facetId}${formatRange(breadcrumb.value.start, breadcrumb.value.end, precision, unit)}`}
            className='FacetBreadcrumb__List-Item'
          >
            <button
              onClick={() => deselectBreadcrumb(breadcrumb)}
              className='FacetBreadcrumb'
              aria-label={i18n.t('SEARCH | Facets Breadcrumb Remove')}
            >
              <span className='FacetBreadcrumb__Text'>
                {formatRange(breadcrumb.value.start, breadcrumb.value.end, precision, unit)}
              </span>
              <span className='FacetBreadcrumb__Clear'>
                <ClearIcon />
              </span>
            </button>
          </li>
        ));
      })}
    </ul>
  );
};

export default FacetBreadcrumbs;
