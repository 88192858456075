import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import CardsCarousel from '../../Common/CardsCarousel/CardsCarousel';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';
import SimilarProductCard from './SimilarProductCard/index';

class SimilarProducts extends React.Component {
  getCards() {
    return this.props.similarProductsData.map((item, index) => (
      <SimilarProductCard fields={this.props.fields} key={index + item.Name} currentItem={item} />
    ));
  }

  getCarouselOrCards(nrOfItemsToShow) {
    if (this.props.similarProductsData.length > nrOfItemsToShow) {
      return <CardsCarousel nrOfItemsToShow={nrOfItemsToShow}>{this.getCards()}</CardsCarousel>;
    }

    const cssClass = classNames({
      'SimilarProducts-MobileContainer': nrOfItemsToShow === 1,
      'SimilarProducts-TabletContainer': nrOfItemsToShow === 2,
      'SimilarProducts-DesktopContainer': nrOfItemsToShow === 3
    });

    return <div className={cssClass}>{this.getCards()}</div>;
  }

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }
    const { Title, Subtitle, 'Background Color': BackgroundColor } = this.props.fields,
      inlineStyle = {
        background:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      isBackgroundBlack = inlineStyle.background === '#000',
      cssClass = classNames('SimilarProducts carouselComponentContainer click_content_block_similar_product_carousel', {
        WhiteColorStyle: isBackgroundBlack
      }),
      grayImage = classNames({
        grayBackgroundImage: inlineStyle.background === null
      });
    return (
      <div className={grayImage} style={inlineStyle}>
        <div className={cssClass}>
          <HeadlineWithTitleAndSubtitle
            extraClasses='SimilarProducts-Headline'
            isTextWhite={isBackgroundBlack}
            title={Title}
            subtitle={Subtitle}
          />
          <Visible lg xl>
            {this.getCarouselOrCards(3)}
          </Visible>
          <Visible md>{this.getCarouselOrCards(2)}</Visible>
          <Visible xs sm>
            {this.getCarouselOrCards(1)}
          </Visible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    similarProductsData: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.similarProducts
      : null
  };
};

const hasItems = function (props) {
  const { similarProductsData, fields } = props;
  if (!similarProductsData || !similarProductsData.length || !fields) {
    return false;
  }
  return true;
};

export default connect(mapStateToProps)(WithStickyMenu(SimilarProducts, hasItems));
