import React from 'react';
import LinkerCarouselComponent from '../index';
import { AXAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class AXAGLinkerCarousel extends React.Component {
  render() {
    return <LinkerCarouselComponent {...this.props} urlToUse={AXAG_PAGE_LINK} />;
  }
}

export default AXAGLinkerCarousel;
