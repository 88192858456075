import React from 'react';
import i18n from 'i18next';
import { AddressCardProps } from './types';
import classNames from 'classnames';
import Tag from '../../Common/Tag/Tag';
import Button from '../../Common/Button/Button';
import DeleteAddress from './DeleteAddress';
import EditAddress from './EditAddress';
import { connect } from 'react-redux';

const AddressCard = ({ address, countries, provinces }: AddressCardProps): JSX.Element => {
  const {
    id,
    company,
    isDefault,
    firstName = '',
    lastName,
    phone,
    address1,
    address2,
    zip,
    city,
    countryCode,
    provinceCode
  } = address;
  const isCompany = company ? true : false;
  const personName = `${firstName || ''} ${lastName || ''}`.trim();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);

  const editAddress = () => {
    setIsEditDialogOpen(true);
  };

  const deleteAddress = () => {
    setIsDeleteDialogOpen(true);
  };

  return (
    <div className='AddressCard'>
      <div className='AddressCard__header'>
        <div className='AddressCard__type'>
          {isCompany ? i18n.t('ADDRESS_MANAGEMENT | Company label') : i18n.t('ADDRESS_MANAGEMENT | Personal label')}
        </div>
        {isDefault && <Tag type='secondary'>{i18n.t('ADDRESS_MANAGEMENT | Default address tag')}</Tag>}
      </div>
      <div className='AddressCard__info'>
        {company && <div className='AddressCard__company-name'>{company}</div>}
        <div
          className={classNames({
            'AddressCard__personal-title': !isCompany
          })}
        >
          {personName}
        </div>
        {phone && <div>{phone}</div>}
        {address1 && <div>{address1}</div>}
        {address2 && <div>{address2}</div>}
        {zip && (
          <div>
            {zip} {city}
          </div>
        )}
        {countryCode && (
          <div className='AddressCard__country'>
            {countries?.find(country => country.value === countryCode)?.label ?? countryCode}
          </div>
        )}
        {provinceCode && (
          <div>
            {provinces.find(province => province.CountryCode === countryCode && province.ProvinceCode === provinceCode)
              ?.Name ?? provinceCode}
          </div>
        )}
      </div>
      <div className='AddressCard__actions'>
        <Button
          className='AddressCard__edit-button AddressCard__edit-button--desktop'
          icon='icon-web-16-pen'
          size='no-size-small'
          variant='ghost'
          iconSize={16}
          buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Edit button')}
          onClick={editAddress}
        />
        <Button
          className='AddressCard__delete-button AddressCard__delete-button--desktop'
          icon='icon-web-16-trash'
          size='no-size-small'
          variant='ghost'
          iconSize={16}
          buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Delete button')}
          onClick={deleteAddress}
        />
        <Button
          className='AddressCard__edit-button AddressCard__edit-button--mobile'
          icon='icon-web-24-pen'
          size='no-size'
          variant='ghost'
          iconSize={24}
          square
          buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Edit button')}
          onClick={editAddress}
        />
        <Button
          className='AddressCard__delete-button AddressCard__delete-button--mobile'
          icon='icon-web-24-trash'
          size='no-size'
          variant='ghost'
          iconSize={24}
          square
          buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Delete button')}
          onClick={deleteAddress}
        />
      </div>
      <EditAddress isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} address={address} />
      {isDeleteDialogOpen && id && (
        <DeleteAddress
          onConfirm={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          addressId={id}
        />
      )}
    </div>
  );
};

export { AddressCard };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  const { countries } = state;

  return {
    countries: countries?.countries || [],
    provinces: countries?.provinces || []
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressCard);
