import i18n from 'i18next';
import React, { ComponentType, useEffect, useState } from 'react';
import { SHOPIFY_MULTIPASS_TOKEN_ROUTE } from '../../../Shopify/lib/constants';
import { getCustomerAccessToken, getCustomerOrders } from '../../../Shopify/lib/shopify/orderHelpers';
import { ShopifyOrder } from '../../../Shopify/lib/shopify/types';
import Table, { Column } from '../../../Common/Table';
import AlertBox from '../../../Common/AlertBox';
import OrderListMobile from '../OrderListMobile';
import {
  extractShopifyOrderIdForNavigation,
  getLocalizedFinancialStatus,
  getOrderDetailPageUrl,
  getOrderListContextHint
} from '../lib/utils';
import Markdown from 'react-markdown';
import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../../Common/CookieSettings/CookieConstants';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useViewportSize } from '../../../../hooks/useViewportSize';

export type MyAccountOrderListProps = {
  fields: {
    'Detail page target'?: {
      id?: string;
      url?: string;
    };
  };
  history: RouteComponentProps['history'];
};

export type OrderListData = ShopifyOrder & {
  financialStatusText: string;
  detailPageUrl: string;
};

export const MyAccountOrderList: React.FC<MyAccountOrderListProps> = props => {
  const [multipassToken, setMultipassToken] = useState<string | undefined>();
  const [customerAccessToken, setCustomerAccessToken] = useState<string | undefined>();
  const [orders, setOrders] = useState<OrderListData[] | undefined>();
  const [orderLoading, setOrderLoading] = useState<boolean>(true);

  const { viewportIsDesktop } = useViewportSize();

  const columnDef: Column<OrderListData>[] = [
    {
      key: 'orderNumber',
      title: i18n.t('ORDERS | Order Number'),
      renderer: 'bold-text',
      textVariant: 'primary',
      sortBy: 'numerical'
    },
    {
      key: 'processedAt',
      title: i18n.t('ORDERS | Order Date'),
      renderer: 'date',
      sortKey: 'processedAt',
      sortBy: 'date'
    },
    {
      key: 'financialStatusText',
      title: i18n.t('ORDERS | Order Payment Status'),
      renderer: 'text',
      sortBy: 'alphabetical',
      sortKey: 'financialStatus'
    },
    {
      key: 'fulfillmentStatus',
      title: i18n.t('ORDERS | Order Fulfillment Status'),
      renderer: 'fulfillment-status',
      sortBy: 'alphabetical'
    },
    {
      key: 'totalPrice',
      title: i18n.t('ORDERS | Order Total'),
      renderer: 'money',
      sortBy: 'numerical',
      textAlign: 'right'
    }
  ];

  const handleOrderClick = (order: OrderListData) => {
    props.history.push(order.detailPageUrl);
  };

  useEffect(() => {
    async function fetchMultipassToken() {
      const multipassResponse = await fetch(SHOPIFY_MULTIPASS_TOKEN_ROUTE());

      if (multipassResponse.status === 200) {
        const token = await multipassResponse.text();
        setMultipassToken(token);
      } else {
        setOrderLoading(false);
      }
    }

    fetchMultipassToken();
  }, []);

  useEffect(() => {
    async function fetchCustomerAccessToken() {
      if (multipassToken) {
        const accessToken = await getCustomerAccessToken(multipassToken);
        if (accessToken) {
          setCustomerAccessToken(accessToken);
        } else {
          setOrderLoading(false);
        }
      }
    }

    fetchCustomerAccessToken();
  }, [multipassToken]);

  useEffect(() => {
    async function fetchCustomerOrders() {
      if (customerAccessToken) {
        const orders = await getCustomerOrders(customerAccessToken);
        if (orders) {
          const ordersExtended: OrderListData[] = orders?.map(order => ({
            ...order,
            financialStatusText: getLocalizedFinancialStatus(order.financialStatus),
            detailPageUrl: getOrderDetailPageUrl(
              extractShopifyOrderIdForNavigation(order.id),
              props.fields['Detail page target']?.url ?? ''
            )
          }));

          ordersExtended.sort((a, b) => b.orderNumber - a.orderNumber);

          setOrders(ordersExtended);
        }
        setOrderLoading(false);
      }
    }

    fetchCustomerOrders();
  }, [customerAccessToken, props.fields]);

  return (
    <>
      {viewportIsDesktop && (
        <div className='MyAccountOrderList__Container componentContainer'>
          {orders && orders.length > 0 ? (
            <>
              {getAlertBox()}

              <Table<OrderListData>
                data={orders}
                columns={columnDef}
                onRowClick={row => {
                  handleOrderClick(row);
                }}
                equalWidthColumns={true}
              />
            </>
          ) : orderLoading ? (
            getLoadingIndicator()
          ) : (
            getNoOrders()
          )}
        </div>
      )}

      {!viewportIsDesktop && (
        <div>
          {orders && orders.length > 0 ? (
            <>
              <div className='MyAccountOrderList__AlertBoxWrapperMobile'>{getAlertBox()}</div>

              <OrderListMobile
                orders={orders}
                onClick={row => {
                  handleOrderClick(row);
                }}
              />
            </>
          ) : orderLoading ? (
            getLoadingIndicator()
          ) : (
            <div className='MyAccountOrderList__NoOrdersWrapperMobile'>{getNoOrders()}</div>
          )}
        </div>
      )}
    </>
  );

  function getLoadingIndicator() {
    return <div className='MyAccountOrderList__Loading'>{i18n.t('ORDERS | Loading Orders')}</div>;
  }

  function getNoOrders() {
    return (
      <div className='MyAccountOrderList__NoOrders'>
        <h2>{i18n.t('ORDERS | No Orders Title')}</h2>
        <Markdown className='Markdown--support-links'>{i18n.t('ORDERS | No Orders Text') || ''}</Markdown>
      </div>
    );
  }

  function getAlertBox() {
    return (
      <AlertBox>
        <Markdown className='Markdown--support-links'>
          {getOrderListContextHint(Cookies.get(COUNTRY_CODE) ?? '')}
        </Markdown>
      </AlertBox>
    );
  }
};

export default withRouter<
  RouteComponentProps & MyAccountOrderListProps,
  ComponentType<RouteComponentProps & MyAccountOrderListProps>
>(MyAccountOrderList);
