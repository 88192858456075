import React from 'react';
import { connect } from 'react-redux';
import { RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Visible } from 'react-grid-system';
import { setMyDevicesList, setCurrentDevicesList } from '../../../../store/mydevices/actions';
import DeviceFilters from './DeviceFilters';
import AddDeviceButton from './AddDeviceButton';
import DeviceList from './DeviceList';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';

class MyAccountDeviceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { devicesToShow: 5 };
  }

  incrementDisplayedItems = () => {
    const numberToIncrement = 5;
    this.setState(state => ({
      devicesToShow: state.devicesToShow + numberToIncrement
    }));
  };
  getFilterList = () => undefined;

  componentDidMount() {
    this.props.getMyDevicesList(getLanguage(this.props.sitecoreContext));
    // eslint-disable-next-line no-unused-expressions
    import('./MyAccountDeviceList.scss');
  }

  getNoDeviceDisplay = () => {
    const {
      fields: { 'No Devices Text': noDevices },
      fields
    } = this.props;
    return (
      <div className='MyAccountDeviceList-NoDevice'>
        <RichText field={noDevices} />
        <AddDeviceButton fields={fields} hasBackground />
      </div>
    );
  };
  getMoreButton = () => {
    const { 'Load More Label': loadLabel } = this.props.fields,
      numberOfDevice = this.props.myDevices.length,
      remainingNumber = numberOfDevice - this.state.devicesToShow;
    if (remainingNumber > 0) {
      return (
        <div
          onClick={this.incrementDisplayedItems}
          onKeyDown={e => {
            if (e.target === 13) this.incrementDisplayedItems();
          }}
          role='button'
          tabIndex='0'
          className='MyAccountDeviceList-Bottom-LoadMore'
        >
          <Text field={loadLabel} />
        </div>
      );
    }
    return <div />;
  };

  render() {
    const { fields, myDevices, initialMyDevices, setCurrentDevicesList } = this.props;

    return (
      <div className='MyAccountDeviceList-Container'>
        <div className='MyAccountDeviceList componentContainer'>
          {(fields && initialMyDevices && initialMyDevices.length && (
            <div>
              <div className='MyAccountDeviceList-Heading'>
                <DeviceFilters
                  fields={fields}
                  initialDevices={initialMyDevices}
                  updateCurrentDeviceList={setCurrentDevicesList}
                />
                <AddDeviceButton fields={fields} />
              </div>

              <DeviceList fields={fields} devices={myDevices} noDevicesToShow={this.state.devicesToShow} />
              <div className='MyAccountDeviceList-Bottom'>
                <Visible xs sm>
                  {this.getMoreButton()}
                </Visible>
                <AddDeviceButton fields={fields} hasBackground />
                <Visible md lg xl>
                  {this.getMoreButton()}
                </Visible>
              </div>
            </div>
          )) ||
            (fields && this.getNoDeviceDisplay()) ||
            ''}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    myDevices: state.myDevices.currentDeviceList,
    initialMyDevices: state.myDevices.initialDeviceList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMyDevicesList: language => dispatch(setMyDevicesList(language)),
    setCurrentDevicesList: data => dispatch(setCurrentDevicesList(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(MyAccountDeviceList));
