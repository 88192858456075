import React from 'react';
import i18n from 'i18next';
import DeleteIcon from '../Icons/DeleteIcon';
import CloseIconSmall from '../Icons/CloseIconSmall';

interface InlineConfirmationDialogProps {
  message?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const InlineConfirmationDialog: React.FC<InlineConfirmationDialogProps> = ({
  message = i18n.t('SHOP | Inline confirmation dialog default message'),
  cancelText = i18n.t('SHOP | Inline confirmation dialog cancel text'),
  confirmText = i18n.t('SHOP | Inline confirmation dialog confirm text'),
  onConfirm,
  onCancel
}) => {
  return (
    <div className='InlineConfirmationDialog'>
      <div className='InlineConfirmationDialog__Message'>
        <p>{message}</p>
      </div>

      <div className='InlineConfirmationDialog__Actions'>
        <button
          type='button'
          onClick={onCancel}
          className='DeleteItemButton DeleteItemButton--cancel'
          data-testid='confirm-action-cancel'
        >
          <CloseIconSmall color='red' />
          <span>{cancelText}</span>
        </button>
        <button
          type='button'
          onClick={onConfirm}
          className='DeleteItemButton DeleteItemButton--confirm'
          data-testid='confirm-action-confirm'
        >
          <DeleteIcon />
          <span>{confirmText}</span>
        </button>
      </div>
    </div>
  );
};

export default InlineConfirmationDialog;
