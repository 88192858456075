import React from 'react';
import GeneralLink from '../../Common/GeneralLink';
import { SitecoreIcon } from '../../Common/Icon/SitecoreIcon';
/**
 * @class Component that renders a social link in the Footer component.
 *
 * @param {object} props Object that contains the image data for the component.
 *
 * @returns Returns an image.
 */
const SocialLink = props => {
  const { Link, Icon } = props.fields;
  return (
    <div className='SocialLink'>
      <div className='SocialLink-item'>
        <GeneralLink fields={Link}>
          <SitecoreIcon icon={Icon} hoverIcon color='black-30' size={24} />
        </GeneralLink>
      </div>
    </div>
  );
};

export default SocialLink;
