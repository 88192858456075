import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CompareBox from '../../HeroActionButton/CompareBox/index';
import GeneralLink from '../../../Common/GeneralLink/index';
import { cutStringWithDots, removeWhiteSpaces, addProductOnDataLayer } from '../../../../utils/Utils';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import {
  setProductToCompareOnCookie,
  removeProductFromCompareCookie,
  getCookie,
  getRawValueFromCompare
} from '../../../Common/CookieSettings/CookieUtils';
import { COMPARE } from '../../../Common/CookieSettings/CookieConstants';
import { PRODUCT_PAGE_ROUTE } from '../../../../AppRoutes';

class SimilarProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCompareOpen: false
    };
  }

  compareActionTriggered = e => {
    e.stopPropagation();
    const { currentItem } = this.props;
    if (e.target.checked) {
      setProductToCompareOnCookie(currentItem.CategoryEn, currentItem.Id);
      this.setState({ isCompareOpen: true });
    } else {
      removeProductFromCompareCookie(currentItem.Id);
      this.setState({ isCompareOpen: false });
    }
  };

  onCompareClose = () => {
    this.setState({ isCompareOpen: false });
  };

  getCardContentCompare = () => {
    const { fields, currentItem } = this.props,
      cookieCompare = getCookie(COMPARE),
      cookieCompareList = cookieCompare ? JSON.parse(cookieCompare) : {},
      productCategory = currentItem.CategoryEn ? currentItem.CategoryEn.split(',') : [],
      checkedNoOnComparelist = productCategory.find(
        item => (cookieCompareList[item] && cookieCompareList[item].length < 4) || !cookieCompareList[item]
      ),
      compareListId = getRawValueFromCompare(),
      checkAlreadyAdded = compareListId.some(element => element === currentItem.Id);

    return (
      <React.Fragment>
        <div
          className='SimilarProducts-Card-Content-CompareBoxWrapper'
          onClick={e => {
            e.preventDefault();
          }}
          onKeyDown={e => {
            if (e.target === 13) e.preventDefault();
          }}
          role='button'
          tabIndex='0'
        >
          <CompareBox
            fields={fields}
            isCompareOpen={this.state.isCompareOpen}
            onClose={this.onCompareClose}
            handleClickOutside={this.onCompareClose}
            checkedNoOfProducts={checkedNoOnComparelist}
            categoryEn={currentItem.CategoryEn}
          />
        </div>
        <label className='SimilarProducts-Card-Content-Compare'>
          <input type='checkbox' onChange={this.compareActionTriggered} checked={checkAlreadyAdded} />
          <span className='checkmark' />
          <Text field={fields['Compare Label']} />
        </label>
      </React.Fragment>
    );
  };

  render() {
    const { currentItem } = this.props;
    const productUrl = currentItem.NameEn
      ? '/' + getLanguage(this.props.sitecoreContext) + PRODUCT_PAGE_ROUTE + '/' + currentItem.NameEn.replace(/ /g, '-')
      : '#';
    return (
      <div className='SimilarProducts-Card'>
        <div
          onClick={() => {
            addProductOnDataLayer(
              currentItem.Id,
              currentItem.NameEn,
              currentItem.CategoryEn,
              productUrl,
              currentItem.BrandName
            );
          }}
          onKeyDown={e => {
            if (e.target === 13)
              addProductOnDataLayer(
                currentItem.Id,
                currentItem.NameEn,
                currentItem.CategoryEn,
                productUrl,
                currentItem.BrandName
              );
          }}
          role='button'
          tabIndex='0'
        >
          <GeneralLink
            clickClassName={'content_block_similar_product_carousel ' + removeWhiteSpaces(currentItem.NameEn || null)}
            fields={{
              value: { href: productUrl }
            }}
          >
            <div className='SimilarProducts-Card-Image'>
              {currentItem.ImageUrl ? (
                <img src={currentItem.ImageUrl + '?imwidth=320'} alt={currentItem.Name} loading='lazy' />
              ) : null}
            </div>

            <div className='SimilarProducts-Card-Content'>
              <div className='SimilarProducts-Card-Content-Title'>{currentItem.Name}</div>
              <div className='SimilarProducts-Card-Content-Description'>
                {cutStringWithDots(currentItem.Description, 120)}
              </div>
            </div>
          </GeneralLink>
        </div>
        {this.getCardContentCompare()}
      </div>
    );
  }
}

export default withSitecoreContext()(SimilarProductCard);
