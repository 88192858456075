import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 *  Component that renders a title under an oveline. overline color changes according to data
 * @param {object} props Object containing data needed for the component.
 *
 */

const HeroTitleWithOverline = props => {
  const { fields } = props;

  if (!fields) {
    return <div className='HeroTitleWithOverline componentContainer' />;
  }

  const inlineStyle = {
    color:
      fields['Overline Color'] && fields['Overline Color'].fields.ColorCode.value !== ''
        ? fields['Overline Color'].fields.ColorCode.value
        : null
  };
  return (
    <div className='HeroTitleWithOverline componentContainer' style={inlineStyle}>
      <div className='HeroTitleWithOverline-Container'>
        <Text field={fields.Overline} tag='span' className='HeroTitleWithOverline-Overline' />
        <Text field={fields.Title} tag='h1' className='HeroTitleWithOverline-Title' />
      </div>
    </div>
  );
};

export default HeroTitleWithOverline;
