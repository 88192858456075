import { useContext } from 'react';
import { CookieConsentContext, CookieConsentContextState } from './CookieContext';

export const useCookieConsent = (): CookieConsentContextState => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
};
