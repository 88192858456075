import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsIndustrialHotAirDevicesPipelines,
  CoveoProductsIndustrialHotAirDevicesSearchHubs
} from './IndustrialHotAirDevicesLister.settings';
import IndustrialHotAirDevicesListerComponent from './IndustrialHotAirDevicesLister';
import CoveoComponent from '../../../CoveoComponent';

export const IndustrialHotAirDevicesLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsIndustrialHotAirDevicesPipelines[siteName],
    searchHub: CoveoProductsIndustrialHotAirDevicesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <IndustrialHotAirDevicesListerComponent />
    </CoveoComponent>
  );
};

export default IndustrialHotAirDevicesLister;
