import React from 'react';
import classNames from 'classnames';

class FilterToggle extends React.Component {
  render() {
    const { filterValue, filterSelected, filterDisabled } = this.props;
    const filterClass = classNames('FilterToggle', {
      FilterDisabled: filterDisabled,
      FilterActive: filterSelected
    });

    return (
      <div className={filterClass}>
        <div className='FilterToggle-text'>{filterValue}</div>
      </div>
    );
  }
}

export default FilterToggle;
