import * as actionTypes from './actionTypes';

export const setStickyItemOnRedux = (stickyTitle, currentLanguage) => {
  return {
    type: actionTypes.SET_STICKY_MENU_ITEM,
    stickyTitle,
    currentLanguage
  };
};

export const resetStickyMenu = () => {
  return {
    type: actionTypes.RESET_STICKY_MENU_DATA
  };
};

export const checkIfRemovedFromStickyMenu = stickyTitle => {
  return {
    type: actionTypes.DELETE_STICKY_MENU_ITEM,
    stickyTitle
  };
};
