import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

/**
 * @class Component that renders a container for  a list of items with text and checkmark
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class RichTextWithCheckmarks extends React.Component {
  render() {
    if (!this.props.fields) {
      return <div className='RichTextWithCheckmarks componentContainer' />;
    }
    const BackgroundColor = this.props.fields.BackgroundColor;
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
    };
    const cssClass = classNames('RichTextWithCheckmarks componentContainer', {
      WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
    });
    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className={cssClass}>
          <div className='RichTextWithCheckmarks-Container'>
            <Placeholder name='jss-checkmarks-item' rendering={this.props.rendering} />
          </div>
        </div>
      </div>
    );
  }
}

export default RichTextWithCheckmarks;
