import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { CSSTransition } from 'react-transition-group';
import UpDown from '../../../UpDown/index';
import classNames from 'classnames';

class ContentElementAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  onItemClick = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    if (!this.props.fields) {
      return <div className='ContentAccordionElements-Item' />;
    }
    const { Title, Text: description } = this.props.fields;
    const cssClass = classNames('ContentAccordionElements-Description', {
      'ContentAccordionElements-Description-ExtraSpace': this.state.isOpen
    });

    const cssClassText = classNames('ContentAccordionElements-Item-Title', {
      'ContentAccordionElements-Item-Title-Active': this.state.isOpen
    });
    const cssClassItem = classNames('ContentAccordionElements-Item', {
      'ContentAccordionElements-Item-WithoutTitle': !Title || Title.value === ''
    });
    const cssClassChevron = classNames('ContentAccordionElements-Item-Chevron', {
      'ContentAccordionElements-Item-Chevron-Active': this.state.isOpen
    });

    return (
      <div className='ContentAccordionElements-Item-BottomBorder'>
        <div
          className={cssClassItem}
          onClick={this.onItemClick}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onItemClick();
          }}
          role='button'
          tabIndex='0'
        >
          <Text className={cssClassText} field={Title} tag='div' />
          <div className={cssClassChevron}>
            <UpDown isUp={this.state.isOpen} />
          </div>
        </div>
        <CSSTransition in={this.state.isOpen} timeout={500} classNames='ContentAccordionElements-Description'>
          <RichText className={cssClass} field={description} />
        </CSSTransition>
      </div>
    );
  }
}

export default ContentElementAccordion;
