import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../../Common/GeneralLink';
import MapPinIcon from '../../../Common/Icons/MapPin';
import EmailIcon from '../../../Common/Icons/EmailIcon';
import { removeWhiteSpaces } from '../../../../utils/Utils';

export class FindADealer extends React.Component {
  getTooltip(label, labelClass) {
    if (label && label.value) {
      return <span className={'HeroActionButton' + labelClass}>{label.value}</span>;
    }
  }
  getButton = (link, GetAQuoteLabel, quoteValidation, icon) => {
    const { getAQuoteMethods } = this.props;
    return (
      <div
        onClick={() => {
          getAQuoteMethods();
          quoteValidation(true);
        }}
        onKeyDown={e => {
          if (e.target === 13) getAQuoteMethods();
          quoteValidation(true);
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink
          fields={link}
          className='HeroActionButton-Button'
          clickClassName={'click_button_get_quote_pdp ' + removeWhiteSpaces(this.props.measuringClassname || null)}
        >
          {icon}
          {GetAQuoteLabel && GetAQuoteLabel.value ? GetAQuoteLabel.value : null}
        </GeneralLink>
      </div>
    );
  };

  getLabel = () => {
    const {
        variants,
        selectedVariant,
        getAQuotePageLink,
        quoteValidationParameter,
        shouldDisplay,
        favouriteContact,
        expertValidationParameter,
        fields
      } = this.props,
      {
        'Find a Dealer': linkDealer,
        'Message Disable Button': messageLabel,
        'Get a Quote Label': GetAQuoteLabel,
        'Talk to an Expert': linkExpert,
        'Talk to an Expert Label': TalkToAnExpertLabel,
        'Find a Dealer Label': FindADealerLabel,
        'Get in Touch Label': getInTouchLabel
      } = fields;

    if (shouldDisplay || (variants && variants.length > 0 && selectedVariant)) {
      if (favouriteContact) {
        return this.getButton(
          { value: { href: getAQuotePageLink } },
          GetAQuoteLabel,
          quoteValidationParameter,
          <EmailIcon />
        );
      }

      return this.getButton(linkDealer, FindADealerLabel, quoteValidationParameter, <MapPinIcon />);
    } else if (!variants || (variants.length === 0 && linkExpert)) {
      if (favouriteContact) {
        return this.getButton(linkExpert, getInTouchLabel, expertValidationParameter, <MapPinIcon />);
      }
      return this.getButton(linkDealer, TalkToAnExpertLabel, expertValidationParameter, <MapPinIcon />);
    } else if (variants && variants.length > 0 && !selectedVariant) {
      return (
        <div className='HeroActionButton-Disable'>
          <MapPinIcon />
          {this.getTooltip(messageLabel, '-Disable-Tooltip')}
          <Text field={FindADealerLabel} />
        </div>
      );
    }
  };
  render() {
    return <div className='HeroActionButton-ButtonWrapper click_button_find_dealer_pdp'>{this.getLabel()}</div>;
  }
}

export default FindADealer;
