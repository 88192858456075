import React from 'react';
import AccessoriesOmniboxComponent from './AccessoriesOmnibox';
import CoveoComponent from '../CoveoComponent';

export type AccessoriesOmniboxProps = {
  selectAccessory: (selectAccessory: string, selectedAccessoryName: string) => void;
};

export const AccessoriesOmnibox: React.FC<AccessoriesOmniboxProps> = props => {
  const searchEngineProps = {
    pipeline: 'SearchProductOnAccessoriesPage'
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <AccessoriesOmniboxComponent {...props} />
    </CoveoComponent>
  );
};

export default AccessoriesOmnibox;
