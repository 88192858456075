import React, { useContext } from 'react';
import { SearchEngineContext } from '../context/SearchEngineContext';
import { CoveoOmniboxPipelines, CoveoOmniboxSearchHubs } from '../CoveoSettings';
import { CoveoOmniboxComponent } from './CoveoOmnibox';
import CoveoComponent from '../CoveoComponent';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const CoveoOmnibox = (props: any) => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoOmniboxPipelines[siteName],
    searchHub: CoveoOmniboxSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <CoveoOmniboxComponent {...props} />
    </CoveoComponent>
  );
};

export default withSitecoreContext()(CoveoOmnibox);
