import React from 'react';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { Link } from '@sitecore-jss/sitecore-jss-react';

interface ConditionalLinkProps {
  field?: LinkField;
  className?: string;
  children: JSX.Element;
}

const ConditionalLink = ({ children, field, className, ...rest }: ConditionalLinkProps): JSX.Element => {
  return field?.value?.href ? (
    <Link field={field} className={className} {...rest}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default ConditionalLink;
