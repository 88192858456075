import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink/index';

/**
 *  Component that renders an image, title, text and a link button
 * @param  {object} props Object containing data needed for the component.
 */

const MyLeisterRegistrationPromotion = props => {
  const fields = props.fields;

  if (!fields) {
    return <div className='MyLeisterRegistrationPromotion' />;
  }

  const { Image: Img, Title, Button, 'Button Text': buttonText, Text: txt } = fields;

  return (
    <div className='whiteBackground'>
      <Row nogutter className='MyLeisterRegistrationPromotion componentContainerMobile'>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='MyLeisterRegistrationPromotion-Column'>
          <div className='MyLeisterRegistrationPromotion-LeftWrapper'>
            <Text className='MyLeisterRegistrationPromotion-Title' field={Title} tag='div' />
            <Text className='MyLeisterRegistrationPromotion-Text' field={txt} tag='div' />
            <GeneralLink fields={Button}>
              <Text field={buttonText} />
            </GeneralLink>
          </div>
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='MyLeisterRegistrationPromotion-Column'>
          <Image field={Img} className='MyLeisterRegistrationPromotion-Image' />
        </Col>
      </Row>
    </div>
  );
};

export default MyLeisterRegistrationPromotion;
