export const productVariantFragment = /* GraphQL */ `
  fragment productVariant on ProductVariant {
    id
    sku
    availableForSale
    currentlyNotInStock
    quantityAvailable
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
  }
`;

export const variantsOnProductFragment = /* GraphQL */ `
  fragment variants on Product {
    variants(first: 250) {
      edges {
        node {
          ...productVariant
        }
      }
    }
  }
  ${productVariantFragment}
`;
