import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { deleteDevice, setDeviceInfoToContactPage } from '../../../../../../store/mydevices/actions';
import DeleteIcon from '../../../../../Common/Icons/DeleteIcon';
import RemoveApprovalOverlay from '../../../../../../components/RemoveApprovalOverlay';
import GeneralLink from '../../../../../Common/GeneralLink';
import { getCookie } from '../../../../../Common/CookieSettings/CookieUtils';
import { LANG } from '../../../../../Common/CookieSettings/CookieConstants';
import ThreeDotsIcon from '../../../../../Common/Icons/ThreeDotsIcon';
import CreateWishList from '../../../../../Product/WishlistComponent/CreateWishList';
import CloseIcon from '../../../../../Common/Icons/CloseIcon';
import { addProductOnDataLayer } from '../../../../../../utils/Utils';
import { PRODUCT_PAGE_ROUTE } from '../../../../../../AppRoutes';
import { normalizeDownloadPagePath } from '../../../../../Product/AllDownloads/getDownloadsProductAndArticle';

class DeviceCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      isOptionsOverlayOpen: false,
      isInventoryOverlayOpen: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./DeviceCard.scss');
  }

  /**
   * has different functionality according to component props
   */
  toggleOverlay = () => {
    const { articleNumber, serialNumber } = this.props.item,
      { hasApprovalOverlay, onDeleteDevice } = this.props;
    if (hasApprovalOverlay) {
      this.setState({ isOverlayOpen: !this.state.isOverlayOpen });
    } else {
      onDeleteDevice(articleNumber, serialNumber);
    }
  };

  toggleOptionsOverlay = () => {
    this.setState({
      isOptionsOverlayOpen: !this.state.isOptionsOverlayOpen
    });
  };

  toggleInventoryOverlay = () => {
    this.setState({
      isInventoryOverlayOpen: !this.state.isInventoryOverlayOpen
    });
  };

  getTooltip(label) {
    if (label && label.value) {
      return <span className='DeviceCard-ProductInfo-Delete-Tooltip'>{label.value}</span>;
    }
  }

  getDeviceCardLink = () => {
    const currentLang = getCookie(LANG),
      { masterProductNameEn, articleNumber } = this.props.item,
      link = masterProductNameEn
        ? '/' +
          currentLang +
          PRODUCT_PAGE_ROUTE +
          '/' +
          masterProductNameEn.replace(/ /g, '-') +
          '/' +
          articleNumber.replace('.', '-')
        : '#';

    return link;
  };

  deleteDeviceFromDeviceList = () => {
    const { articleNumber, serialNumber } = this.props.item;

    this.props.deleteDevice(articleNumber, serialNumber);
  };
  handleClickOutside = e => {
    const { articleNumber, serialNumber } = this.props.item,
      itemElement = document.getElementById(articleNumber + serialNumber),
      svgElement = itemElement ? itemElement.querySelector('svg') : null,
      pathElement = itemElement ? itemElement.querySelector('svg path') : null;
    if (itemElement !== e.target && svgElement !== e.target && pathElement !== e.target) {
      this.setState({ isOptionsOverlayOpen: false });
    }
  };

  setDeviceInfoOnRedux = () => {
    const { articleNumber, serialNumber, image, name, masterProductNameEn } = this.props.item,
      reduxInfo = {
        name: name,
        articleNo: articleNumber,
        picture: image && image.url,
        nameEN: masterProductNameEn,
        serialNo: serialNumber,
        deviceInfoHidden: name + ', ArticleNo. ' + articleNumber + ', SerialNo. ' + serialNumber
      };
    this.props.setDeviceInfoToContactPage(reduxInfo);
  };

  setPageForBackLink = () => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('mydevices', 'true');
    }
  };
  getOptionsOverlayDesktop = () => {
    return (
      <OutsideClickHandler onOutsideClick={this.handleClickOutside}>{this.getOptionsOverlay()}</OutsideClickHandler>
    );
  };

  getOptionsOverlayMobile = () => {
    const { 'Options Label': optionsLabel, articleNumberLabel } = this.props.fields,
      { name, articleNumber, image } = this.props.item,
      img = image && image.url ? image.url + '?imwidth=100' : null;
    return (
      <div className='DeviceCard-ProductInfo-Options-Overlay-Mobile'>
        <div className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Header'>
          <Text field={optionsLabel} />
          <div
            onClick={this.toggleOptionsOverlay}
            onKeyDown={e => {
              if (e.target === 13) {
                this.this.toggleOptionsOverlay();
              }
            }}
            role='button'
            tabIndex='0'
          >
            <CloseIcon />
          </div>
        </div>
        <div className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Device'>
          <div className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Device-Image'>
            <img src={img} alt={image && image.title} loading='lazy' />
          </div>
          <div className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Device-Details'>
            <span className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Device-Name'>{name}</span>
            <span className='DeviceCard-ProductInfo-Options-Overlay-Mobile-Device-Article'>
              <Text field={articleNumberLabel} />
              :&nbsp;{articleNumber}
            </span>
          </div>
        </div>
        {this.getOptionsOverlay()}
      </div>
    );
  };

  getOptionsOverlay = () => {
    const {
        'Change Inventory Label': changeInvestoryLabel,
        'Service Request Label': serviceLabel,
        'Downloads Label': downloadsLabel,
        'Product Support Form Link': productSupportFormLink
      } = this.props.fields,
      downloadPageRoute = normalizeDownloadPagePath(
        this.props.sitecoreContext?.productDownloads?.productDownloadsDetailPage ?? ''
      ),
      { masterProductNameEn, articleNumber } = this.props.item,
      downloadPageLink = masterProductNameEn
        ? downloadPageRoute + '/' + masterProductNameEn.replace(/ /g, '-') + '/' + articleNumber.replace('.', '-')
        : '#';

    return (
      <div className='DeviceCard-ProductInfo-Options-Overlay'>
        <div
          onClick={this.toggleInventoryOverlay}
          onKeyDown={e => {
            if (e.target === 13) {
              this.toggleInventoryOverlay();
            }
          }}
          role='button'
          tabIndex='0'
          aria-label='Open Inventory Overlay'
          className='DeviceCard-ProductInfo-Options-Overlay-Item'
        >
          <Text field={changeInvestoryLabel} />
        </div>
        <div
          onClick={() => this.setDeviceInfoOnRedux()}
          onKeyDown={e => {
            if (e.target === 13) {
              this.setDeviceInfoOnRedux();
            }
          }}
          role='button'
          tabIndex='0'
        >
          <GeneralLink fields={productSupportFormLink} className='DeviceCard-ProductInfo-Options-Overlay-Item'>
            <Text field={serviceLabel} />
          </GeneralLink>
        </div>
        <div
          onClick={this.setPageForBackLink}
          onKeyDown={e => {
            if (e.target === 13) {
              this.setPageForBackLink();
            }
          }}
          role='button'
          tabIndex='0'
        >
          <GeneralLink
            className='DeviceCard-ProductInfo-Options-Overlay-Item'
            fields={{ value: { href: downloadPageLink } }}
          >
            <Text field={downloadsLabel} />
          </GeneralLink>
        </div>
      </div>
    );
  };

  render() {
    const {
        name,
        serialNumber,
        articleNumber,
        image,
        inventoryNumber,
        category,
        masterProductNameEn,
        pimId,
        brandName
      } = this.props.item,
      {
        serialNumberLabel,
        articleNumberLabel,
        inventoryNumberLabel,
        deleteTooltip,
        'Delete Button Label': deleteButtonLabel,
        'Delete Overlay Text': deleteOverlayText,
        'Cancel Delete Label': cancelDeleteLabel
      } = this.props.fields,
      img = image && image.url ? image.url + '?imwidth=100' : null,
      inventoryName = inventoryNumber ? inventoryNumber : '',
      deviceUrl = this.getDeviceCardLink(),
      productId = pimId ? pimId : '#';

    return (
      <div className='DeviceCard'>
        <div className='DeviceCard-ProductInfo'>
          <div
            onClick={() => {
              addProductOnDataLayer(productId, masterProductNameEn, category, deviceUrl, brandName, articleNumber);
            }}
            onKeyDown={e => {
              if (e.target === 13)
                addProductOnDataLayer(productId, masterProductNameEn, category, deviceUrl, brandName, articleNumber);
            }}
            role='button'
            tabIndex='0'
          >
            <GeneralLink
              fields={{
                value: { href: deviceUrl }
              }}
            >
              <div className='DeviceCard-ProductInfo-Image'>
                <img src={img} alt={image && image.title} loading='lazy' />
              </div>
              <div className='DeviceCard-ProductInfo-Details'>
                <div className='DeviceCard-ProductInfo-Details-Name'>{name}</div>
                <div className='DeviceCard-ProductInfo-Details-SubName' />
                <div className='DeviceCard-ProductInfo-Details-SerialNumber'>
                  <Text field={serialNumberLabel} />
                  :&nbsp;
                  {serialNumber}
                </div>
                <div className='DeviceCard-ProductInfo-Details-Delimiter'>|</div>
                <div className='DeviceCard-ProductInfo-Details-ArticleNumber'>
                  <Text field={articleNumberLabel} />
                  :&nbsp;
                  {articleNumber}
                </div>
                {inventoryNumber && (
                  <React.Fragment>
                    <div className='DeviceCard-ProductInfo-Details-Delimiter'>|</div>

                    <div className='DeviceCard-ProductInfo-Details-InventoryNumber'>
                      <Text field={inventoryNumberLabel} />
                      :&nbsp;
                      <span className='DeviceCard-ProductInfo-Details-InventoryNumber-Value'>{inventoryNumber}</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </GeneralLink>
          </div>
          <div className='DeviceCard-ProductInfo-Buttons'>
            {this.props.hasOptions && (
              <div
                className='DeviceCard-ProductInfo-Options'
                id={articleNumber + serialNumber}
                onClick={this.toggleOptionsOverlay}
                onKeyDown={e => {
                  if (e.target === 13) {
                    this.toggleOptionsOverlay();
                  }
                }}
                role='button'
                tabIndex='0'
                aria-label='Open Options Overlay'
              >
                <ThreeDotsIcon />
                {this.state.isOptionsOverlayOpen && (
                  <React.Fragment>
                    <Visible xs sm md>
                      {this.getOptionsOverlayMobile()}
                    </Visible>
                    <Visible lg xl>
                      {this.getOptionsOverlayDesktop()}
                    </Visible>
                  </React.Fragment>
                )}
                {this.state.isInventoryOverlayOpen && (
                  <CreateWishList
                    fields={this.props.fields}
                    inventoryOverlay
                    onClose={() => {
                      this.toggleInventoryOverlay();
                    }}
                    inventoryNamePlaceholder={inventoryName}
                    article={articleNumber}
                    serial={serialNumber}
                  />
                )}
              </div>
            )}
            {this.props.hasDelete && (
              <div
                className='DeviceCard-ProductInfo-Delete'
                onClick={this.toggleOverlay}
                onKeyDown={e => {
                  if (e.target === 13) {
                    this.toggleOverlay();
                  }
                }}
                role='button'
                tabIndex='0'
                aria-label='Open Approval Overlay'
              >
                <DeleteIcon />
                {this.getTooltip(deleteTooltip)}
                {/* NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component */}
                <RemoveApprovalOverlay
                  onClose={this.toggleOverlay}
                  isOverlay={this.state.isOverlayOpen}
                  onClick={this.deleteDeviceFromDeviceList}
                  DeleteButtonLabel={deleteButtonLabel}
                  DeleteText={deleteOverlayText}
                  CancelDeleteLabel={cancelDeleteLabel}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialMyDevices: state.myDevices.initialDeviceList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteDevice: (articleNumber, serialNumber) => dispatch(deleteDevice(articleNumber, serialNumber)),
    setDeviceInfoToContactPage: deviceInfo => dispatch(setDeviceInfoToContactPage(deviceInfo))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(DeviceCard));
