import React, { useEffect } from 'react';
import i18n from 'i18next';
import AddressCard from '../AddressCard/AddressCard';
import PlusIcon from '../../Common/Icons/PlusIcon';
import { connect } from 'react-redux';
import { ADDRESS_LIST_ENDPOINT } from '../../Product/productendpoint';
import { addressListData } from '../../../store/accountInformation/actions';
import Spinner from '../../Common/Spinner';
import LoadingError from '../../Common/LoadingError/LoadingError';
import { MyAddressListProps } from './types';
import EditAddress from '../AddressCard/EditAddress';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getCountries } from '../../../store/countries/actions';

const MyAddressList = ({
  countries,
  addressListState,
  setAddressList,
  addressListData,
  getCountries,
  sitecoreContext
}: MyAddressListProps): JSX.Element => {
  const language = sitecoreContext?.language;
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);

  useEffect(() => {
    setAddressList();
    getCountries(language);
  }, [setAddressList, getCountries, language]);

  // address list still loading or countries not fetched yet
  if (addressListState === 'loading' || countries.length === 0) {
    return (
      <div className='MyAddressList'>
        <Spinner />
      </div>
    );
  }

  if (addressListState === 'error') {
    return (
      <LoadingError
        onClick={setAddressList}
        message={i18n.t('ADDRESS_MANAGEMENT | Error fetching address list')}
        buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Error fetching address list try again')}
      />
    );
  }

  return (
    <div className='MyProfileComponent'>
      <div className='MyProfileComponent-Wrapper'>
        <div className='MyAddressList'>
          <button className='MyAddressList__add-address-button' onClick={() => setIsCreateDialogOpen(true)}>
            <PlusIcon />
            {i18n.t('ADDRESS_MANAGEMENT | Add new address button')}
          </button>
          {addressListData
            .sort(
              (addressA, addressB) =>
                new Date(addressB.lastModifiedUtc ?? '').getTime() - new Date(addressA.lastModifiedUtc ?? '').getTime()
            )
            .map((address, index) => (
              <div key={index} className='MyAddressList__address-card'>
                <AddressCard address={address} />
              </div>
            ))}
          {addressListData.length > 0 && (
            <button
              className='MyAddressList__add-address-button MyAddressList__add-address-button--mobile'
              onClick={() => setIsCreateDialogOpen(true)}
            >
              <PlusIcon />
              {i18n.t('ADDRESS_MANAGEMENT | Add new address button')}
            </button>
          )}
          <EditAddress isOpen={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)} />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  const { accountInformation, countries } = state;

  return {
    addressListData: accountInformation?.addressList?.data || [],
    addressListState: accountInformation?.addressList?.state || 'initial',
    countries: countries?.countries || []
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => {
  return {
    setAddressList: () => dispatch(addressListData(ADDRESS_LIST_ENDPOINT)),
    getCountries: (language: string) => dispatch(getCountries(language))
  };
};

export default withSitecoreContext()(connect(mapStateToProps, mapDispatchToProps)(MyAddressList));
