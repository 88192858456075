import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import RemoveApprovalOverlay from '../../../../../../RemoveApprovalOverlay';

class ErrorMessageWithSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { supportPageOverlay: false };
  }
  onSupportClick = () => {
    this.setState({ supportPageOverlay: !this.state.supportPageOverlay });
  };
  onLeavePageClick = () => {
    const { productSupportLink } = this.props.fields;
    window.location.href = productSupportLink && productSupportLink.jss.value.href;
    this.onSupportClick();
  };
  render() {
    const {
      errorMessageWithSupport,
      productSupportLabel,
      leavePageOverlayButtonLabel,
      cancelOverlayButtonLabel,
      leavePageOverlayText
    } = this.props.fields;
    return (
      <div className='AddDevice-ErrorWrapper'>
        <Text className='AddDevice-ErrorWrapper-Message' field={errorMessageWithSupport} tag='span' />
        <div
          onClick={this.onSupportClick}
          onKeyDown={e => {
            if (e.target === 13) this.onSupportClick();
          }}
          role='button'
          tabIndex='0'
          aria-label='Opens Overlay'
          className='AddDevice-ErrorWrapper-Button'
        >
          <Text field={productSupportLabel} />
        </div>
        {/* NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component */}
        <RemoveApprovalOverlay
          isOverlay={this.state.supportPageOverlay}
          onClose={this.onSupportClick}
          onClick={this.onLeavePageClick}
          DeleteButtonLabel={leavePageOverlayButtonLabel}
          DeleteText={leavePageOverlayText}
          CancelDeleteLabel={cancelOverlayButtonLabel}
        />
      </div>
    );
  }
}

export default ErrorMessageWithSupport;
