import classNames from 'classnames';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../SearchResultList';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { getCoveoProductsSource } from '../../../../AppGlobals';
import { useCoveoQuery } from '../../CoveoQuery';
import { CoveoContext } from '../../context/CoveoContext';
import CategorFacet from '../../CategoryFacet';
import FacetsModal from '../../FacetsModal';
import FloatingFilterToggle from '../../FloatingFilterToggle';
import FacetManager from '../../FacetManager';
import PageOmnibox from '../../PageOmnibox';
import DownloadResultItem from '../../ResultItem/DownloadResultItem';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { normalizeDownloadPagePath } from '../../../Product/AllDownloads/getDownloadsProductAndArticle';

type DownloadsListerComponentProps = {
  history?: RouteComponentProps['history'];
  sitecoreContext?: any;
};

export const DownloadsListerComponent: React.FC<DownloadsListerComponentProps> = ({ history, sitecoreContext }) => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);

  const coveoProductSource = getCoveoProductsSource();

  const { buildFacetController, querySummaryController, searchBoxController, instantResultsController, search } =
    useCoveoQuery({
      engine,
      aq: `@source=="${coveoProductSource}" AND @tenant=="${siteName}" AND @z95xlanguage=="${locale}"`,
      cq: ''
    });

  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine, querySummaryController]);

  const facets = [
    {
      field: 'pimclassificationtranslated',
      label: i18n.t('SEARCH | Facets pimclassificationtranslated')
    },
    {
      field: 'pimapplications',
      label: i18n.t('SEARCH | Facets pimapplications'),
      hasFilter: true
    }
  ];

  return (
    <>
      <PageOmnibox
        instantResultsController={instantResultsController}
        searchBoxController={searchBoxController}
        search={search}
        onSuggestionClick={result => {
          const raw = result.raw;
          const isPhasedOut = raw.isphasedout === 'true';
          const downloadPagePath = sitecoreContext?.productDownloads?.productDownloadsDetailPage || '';
          let downloadPageLink = `${normalizeDownloadPagePath(downloadPagePath)}/${raw.productassetnamenormalized}`;

          if (raw.variantarticlenumberraw) {
            downloadPageLink = `${downloadPageLink}/${raw.variantarticlenumberraw}`;
          }

          const url = isPhasedOut
            ? downloadPageLink
            : `${normalizeDownloadPagePath(downloadPagePath)}/${raw.productassetnamenormalized}`;

          history?.push(url);
        }}
      />
      <div
        className={classNames('DownloadsLister', {
          'SearchResults--executed': state.firstSearchExecuted
        })}
      >
        <div className='SearchResults__Main'>
          <FloatingFilterToggle />
          <FacetsModal hideOnDesktop={true} foldable={false} />
          <FacetManager
            staticOnDesktop={true}
            engine={engine}
            hasResults={(state.hasResults || state.firstSearchExecuted) && !state.isLoading}
          >
            {facets.map(facet => {
              const facetController = buildFacetController(facet.field);

              return (
                <CategorFacet
                  key={facet.field}
                  controller={facetController}
                  title={facet.label}
                  id={facet.field}
                  hasFilter={facet.hasFilter}
                />
              );
            })}
          </FacetManager>
          {state.hasResults && (
            <div className='SearchResults__Header'>
              <span className='SearchResults__NumberOfResults'>
                {state.total} {i18n.t('SEARCH | Total Results')}
              </span>
            </div>
          )}
          <SearchResultList
            className='SearchResultList--downloads'
            layout='List'
            engine={engine}
            query={state.query}
            siteName={siteName}
            language={locale}
            ItemRendering={DownloadResultItem}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter<
  RouteComponentProps & DownloadsListerComponentProps,
  ComponentType<RouteComponentProps & DownloadsListerComponentProps>
>(withSitecoreContext()(DownloadsListerComponent));
