import React from 'react';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  emptyWishlist,
  deleteProductFromWishList,
  getWishList,
  setCurrentWishListName,
  setWishlistProductsAnonymous
} from '../../../../store/wishlist/actions';
import {
  setProductForGetAQuoteLink,
  setDataForProductQuote,
  setIsLinkForQuote
} from '../../../../store/product/actions';

import { getFavoriteContactId } from '../../../../store/favoriteContact/actions';
import { getCookie, deleteCookie, getLocalStorage, setLocalStorage } from '../../../Common/CookieSettings/CookieUtils';
import { WISHLIST, LANG } from '../../../Common/CookieSettings/CookieConstants';
import GeneralLink from '../../../Common/GeneralLink';
import ShareIcon from '../../../Common/Icons/ShareIcon';
import PrintIcon from '../../../Common/Icons/PrintIcon';
import ArrowLeft from '../../../Common/Icons/ArrowLeft';
import DeleteIconLarge from '../../../Common/Icons/DeleteIconLarge';
import ItemProductWishlist from '../ItemProductWishlist';
import RemoveApprovalOverlay from '../../../../components/RemoveApprovalOverlay';
import { removeWhiteSpaces, checkIfUserIsLoggedIn } from '../../../../utils/Utils';
import PenIcon from '../../../Common/Icons/PenIcon';
import CreateWishList from '../CreateWishList';
import { getSitecoreApiHost } from '../../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../../AppRoutes';

class WishlistItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      isEditOverlayOpen: false
    };
  }

  async componentDidMount() {
    const {
      wishLists,
      sitecoreContext,
      setCurrentWishListName,
      setDataForProductQuote,
      getFavoriteContactId,
      favouriteContactId,
      setWishlistProductsAnonymous,
      getWishList
    } = this.props;

    const wishlistName = this.getWishListName();

    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      if (wishLists.length === 0) {
        await getWishList();
      }
    } else {
      setWishlistProductsAnonymous(wishlistName.value, undefined, sitecoreContext.site.name);
    }

    getFavoriteContactId(favouriteContactId, sitecoreContext);

    setLocalStorage('currentPageTitle', wishlistName.value);
    setCurrentWishListName(wishlistName.value);
    setDataForProductQuote(null);
  }

  componentDidUpdate(prevProps) {
    const { wishLists, setProductForGetAQuoteLink } = this.props;
    setProductForGetAQuoteLink(this.getProductsLink());

    const wishlistName = this.getWishListName();
    if (prevProps.wishLists.length !== wishLists.length) {
      this.props.setCurrentWishListName(wishlistName.value);
    }
    setLocalStorage('currentPageTitle', wishlistName.value);
  }

  getProductsLink = () => {
    const currentLang = getCookie(LANG);
    const { wishlistData } = this.props;
    let links = [];
    wishlistData.map(item => {
      const link =
        getSitecoreApiHost() +
        '/' +
        currentLang +
        PRODUCT_PAGE_ROUTE +
        '/' +
        item.masterProductNameEn.replace(/ /g, '-') +
        '/' +
        item.articleNo.replace('.', '-');
      links.push(link);

      return links;
    });
    return links.join('; ');
  };

  getItems() {
    const { wishlistData, fields } = this.props;

    return wishlistData.map(item => {
      return (
        item.articleNo && (
          <ItemProductWishlist
            key={item.articleNo}
            item={item}
            fields={fields}
            currentWishListIndex={this.props.currentWishListIndex}
            wishLists={this.props.wishLists}
          />
        )
      );
    });
  }
  onDeleteListClick = () => {
    if (checkIfUserIsLoggedIn(this.props.sitecoreContext)) {
      const { wishlistData, wishLists, currentWishListIndex } = this.props,
        wishListName = wishLists[currentWishListIndex].name,
        itemsLength = wishlistData ? wishlistData.length : 0;

      for (let i = 0; i < itemsLength; i++) {
        this.props.deleteProduct(wishListName, wishlistData[i].masterProductNameEn, wishlistData[i].variantId);
      }
      this.toggleOverlay();
    } else {
      deleteCookie(WISHLIST);
      this.props.emptyWishlist();
      this.toggleOverlay();
    }
  };
  getTooltip(label, labelClass) {
    if (label && label.value) {
      return <span className={'WishlistItems-ActionButtons' + labelClass}>{label.value}</span>;
    }
  }
  getQuoteButton = (link, label) => {
    const currentWishlistName = this.getWishListName(),
      { setIsLinkForQuote } = this.props;
    return (
      <div
        onClick={() => setIsLinkForQuote(true)}
        onKeyDown={e => {
          if (e.target === 13) setIsLinkForQuote(true);
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink
          className='WishlistItems-ActionButtons-QuoteButton'
          fields={link}
          clickClassName={
            'click_button_get_quote_wishlist ' +
            removeWhiteSpaces((currentWishlistName && currentWishlistName.value) || null)
          }
        >
          <Text field={label} />
        </GeneralLink>
      </div>
    );
  };
  displayQuoteButton = () => {
    const {
        'Ask Quote Button Label': askQuoteLabel,
        'Get A Quote Page Link': getAQuotePageLink,
        'Find A Dealer Label': dealerLabel,
        'Find A Dealer Link': dealerLink
      } = this.props.fields,
      getAQuoteLink = getAQuotePageLink && getAQuotePageLink.value && getAQuotePageLink.value.href,
      { favouriteContactId } = this.props;

    if (favouriteContactId) {
      return this.getQuoteButton({ value: { href: getAQuoteLink } }, askQuoteLabel);
    }
    return this.getQuoteButton(dealerLink, dealerLabel);
  };

  getActionsButtons() {
    const {
        'Share Tooltip Label': shareTooltip,
        'Print Tooltip Label': printTooltip,
        'Delete Tooltip Label': deleteTooltip,
        'Rename Tooltip Label': renameTooltip
      } = this.props.fields,
      { wishlistData, sitecoreContext } = this.props,
      displayEdit = this.displayEditWishlist();
    if (wishlistData && wishlistData.length > 0) {
      return (
        <div className='WishlistItems-ActionButtons'>
          {this.displayQuoteButton()}
          <div className='WishlistItems-ActionButtons-Buttons'>
            <div className='WishlistItems-ActionButtons-Share'>
              <ShareIcon />
              {this.getTooltip(shareTooltip, '-Share-Tooltip')}
            </div>
            <div className='WishlistItems-ActionButtons-Print'>
              <ReactToPrint
                trigger={() => (
                  <div>
                    <PrintIcon />
                    {this.getTooltip(printTooltip, '-Print-Tooltip')}
                  </div>
                )}
                content={() => this.componentRef}
              />
            </div>
            <div
              className='WishlistItems-ActionButtons-Delete'
              onClick={this.toggleOverlay}
              onKeyDown={e => {
                if (e.target === 13) {
                  this.toggleOverlay();
                }
              }}
              role='button'
              tabIndex='0'
              aria-label='Open Approval Overaly'
            >
              <DeleteIconLarge hoverIcon />
              {this.getTooltip(deleteTooltip, '-Delete-Tooltip')}
            </div>
            {checkIfUserIsLoggedIn(sitecoreContext) && displayEdit && (
              <div
                className='WishlistItems-ActionButtons-Edit'
                onClick={this.toggleEditOverlay}
                onKeyDown={e => {
                  if (e.target === 13) {
                    this.toggleEditOverlay();
                  }
                }}
                role='button'
                tabIndex='0'
                aria-label='Open Edit Overaly'
              >
                {/* TODO: We only have a small pen 16x16, this will scale it up */}
                <PenIcon />
                {this.getTooltip(renameTooltip, '-Edit-Tooltip')}
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  toggleOverlay = () => {
    this.setState({ isOverlayOpen: !this.state.isOverlayOpen });
  };
  toggleEditOverlay = () => {
    this.setState({ isEditOverlayOpen: !this.state.isEditOverlayOpen });
  };

  getWishListName = () => {
    if (checkIfUserIsLoggedIn(this.props.sitecoreContext)) {
      // Authenticated user gets the name from the currentWishListIndex or from the localStorage, first item in wishLists or empty string
      const { currentWishListIndex, wishLists } = this.props,
        wishListIndex = currentWishListIndex || getLocalStorage('currentWishListIndex') || 0;
      return wishLists.length > 0 ? { value: wishLists[wishListIndex].name } : { value: '' };
    } else {
      // Anonymous gets the name from the according sitecore field or empty string
      const { 'Wishlist Items Name': wishlistName } = this.props.fields;
      return wishlistName?.fields?.['Wishlist Name'] ?? { value: '' };
    }
  };

  displayEditWishlist = () => {
    const { sitecoreContext } = this.props;
    return checkIfUserIsLoggedIn(sitecoreContext);
  };

  render() {
    if (!this.props.fields) {
      return <div className='WishlistItems componentContainer' />;
    }

    const { wishlistData, fields } = this.props,
      {
        'Back To Label': backTo,
        'Products Number Label': productsLabel,
        'Delete Approval Label': deleteText,
        'Delete Button Description': deleteButtonLabel,
        'Cancel Delete Label': cancelButtonLabel
      } = fields,
      { isEditOverlayOpen, isOverlayOpen } = this.state,
      numberOfProducts = wishlistData.length,
      wishlistName = this.getWishListName();

    return (
      <div className='WishlistItems componentContainer'>
        <GeneralLink fields={{ value: { href: './' } }} className='WishlistItems-BackButton'>
          <ArrowLeft />
          <Text field={backTo} />
        </GeneralLink>
        <Text className='WishlistItems-Title' field={wishlistName} tag='h1' />
        <div className='WishlistItems-NumberContainer'>
          <div className='WishlistItems-Number'>
            {numberOfProducts}&nbsp;
            <Text tag='span' field={productsLabel} />
          </div>
        </div>
        <div ref={el => (this.componentRef = el)}>{this.getItems()}</div>
        {this.getActionsButtons()}
        {/* NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component */}
        <RemoveApprovalOverlay
          onClose={this.toggleOverlay}
          isOverlay={isOverlayOpen}
          onClick={() => this.onDeleteListClick()}
          DeleteButtonLabel={deleteButtonLabel}
          DeleteText={deleteText}
          CancelDeleteLabel={cancelButtonLabel}
        />
        {isEditOverlayOpen && (
          <CreateWishList
            fields={fields}
            editOverlay
            editInputPlaceholder={wishlistName && wishlistName.value}
            onClose={() => this.toggleEditOverlay()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    wishlistData:
      state.wishlistProducts && state.wishlistProducts.wishlistItems ? state.wishlistProducts.wishlistItems : [],
    currentWishListIndex:
      state.wishlistProducts && state.wishlistProducts.currentWishListIndex
        ? state.wishlistProducts.currentWishListIndex
        : 0,
    wishLists: state.wishlistProducts && state.wishlistProducts.wishLists ? state.wishlistProducts.wishLists : [],
    favouriteContactId: state.favoriteContact && state.favoriteContact.favoriteContactId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setWishlistProductsAnonymous: (name, data, tenant) => dispatch(setWishlistProductsAnonymous(name, data, tenant)),
    setProductForGetAQuoteLink: link => dispatch(setProductForGetAQuoteLink(link)),
    emptyWishlist: () => dispatch(emptyWishlist()),
    getWishList: () => dispatch(getWishList()),
    deleteProduct: (listName, productName, variantId) =>
      dispatch(deleteProductFromWishList(listName, productName, variantId)),
    getFavoriteContactId: (id, sitecoreContextFields) => dispatch(getFavoriteContactId(id, sitecoreContextFields)),
    setCurrentWishListName: name => dispatch(setCurrentWishListName(name)),
    setDataForProductQuote: productData => dispatch(setDataForProductQuote(productData)),

    setIsLinkForQuote: isLink => dispatch(setIsLinkForQuote(isLink))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(WishlistItems));
