import React from 'react';
import StickyMenu from '../../Common/StickyMenu/index';

/*
after some code reworks on this component it was almost the same as sticky menu  
this is the reason of having this component like this
*/
class ProductStickyMenu extends React.Component {
  render() {
    return <StickyMenu fields={this.props.fields} />;
  }
}

export default ProductStickyMenu;
