import React from 'react';
import { FaSearch } from 'react-icons/fa';
import Dropdown from '../../../../Common/Dropdown';

class DeviceFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: null,
      searchedValue: ''
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./DeviceFilters.scss');
  }

  getDropdownValues = () => {
    const {
      initialDevices,
      fields: { 'All Label': allLabel }
    } = this.props;
    const category = initialDevices.map(item => item.category),
      concatValues = [].concat([allLabel.value]).concat(...category),
      uniqueCategory = [...new Set(concatValues)];

    return uniqueCategory.map(item => {
      return {
        title: item
      };
    });
  };

  onDropdownSet = item => {
    const {
      initialDevices,
      updateCurrentDeviceList,
      fields: { 'All Label': allLabel }
    } = this.props;
    let categoryItems = [];
    if (this.state.searchedValue === '') {
      if (item.title === allLabel.value) {
        categoryItems = initialDevices;
      } else {
        categoryItems = initialDevices.filter(element => element.category === item.title);
      }
    } else {
      if (item.title === allLabel.value) {
        categoryItems = initialDevices.filter(element => {
          return this.searchFunction(element, this.state.searchedValue);
        });
      } else {
        categoryItems = initialDevices
          .filter(element => element.category === item.title)
          .filter(device => {
            return this.searchFunction(device, this.state.searchedValue);
          });
      }
    }

    updateCurrentDeviceList(categoryItems);
    this.setState({
      selectedCategory: item
    });
  };

  searchFunction = (element, searchValue) => {
    return Object.keys(element).some(key => {
      return (
        element[key] &&
        element[key].toString().toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 &&
        key !== 'image' &&
        key !== 'category'
      );
    });
  };

  onSearch = event => {
    const {
        initialDevices,
        updateCurrentDeviceList,
        fields: { 'All Label': allLabel }
      } = this.props,
      { selectedCategory } = this.state,
      isAll = !selectedCategory || selectedCategory.title === allLabel.value;
    let result = [];

    if (event.target.value === '' && isAll) {
      result = initialDevices;
    } else {
      const searchData = isAll
        ? initialDevices
        : initialDevices.filter(element => {
            return element.category === selectedCategory.title;
          });

      result = searchData.filter(item => {
        return this.searchFunction(item, event.target.value);
      });
    }

    updateCurrentDeviceList(result);
    this.setState({
      searchedValue: event.target.value
    });
  };

  render() {
    const { 'Search Label': search, 'Product Category Label': productCategory } = this.props.fields,
      dopdownItems = this.getDropdownValues();
    return (
      <div className='DeviceFilters'>
        <div className='DeviceFilters-Search'>
          <input
            type='text'
            className='DeviceFilters-Search-Input'
            placeholder={search.value}
            onChange={this.onSearch}
            value={this.state.searchedValue}
          />
          <span className='DeviceFilters-Search-Icon'>
            <FaSearch />
          </span>
        </div>
        <div className='DeviceFilters-ProductLine'>
          {(dopdownItems && dopdownItems.length > 2 && (
            <Dropdown
              label={productCategory.value}
              currentItem={this.state.selectedCategory || dopdownItems[0]}
              items={dopdownItems}
              onSet={this.onDropdownSet}
            />
          )) ||
            (dopdownItems.length === 2 && (
              <div className='DeviceFilters-ProductLine-DisableDropdown'>
                <Dropdown label={productCategory.value} currentItem={this.state.selectedCategory || dopdownItems[1]} />
              </div>
            )) ||
            ''}
        </div>
      </div>
    );
  }
}

export default DeviceFilters;
