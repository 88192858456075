import React from 'react';
import { Row, Col, Visible } from 'react-grid-system';
import classNames from 'classnames';

/**
 * BenefitsWithImages component with left right image display options
 */
class BenefitsWithImages extends React.Component {
  /**
   * the function that return the content with image
   */
  getImageContent = (item, position) => {
    const imageSrc = item.img;
    const cssClass = classNames('Benefits-Image', {
      'Benefits-Right': position
    });
    return (
      <Col sm={12} md={12} lg={7} xl={7} className={cssClass}>
        {imageSrc ? (
          <img
            sizes='(min-width: 320px) and (max-width: 767px) 727px,(min-width: 768px) and (max-width: 959px) 919px,1180px'
            srcSet={`${imageSrc}?imwidth=320 320w, ${imageSrc}?imwidth=768 768w, ${imageSrc}?imwidth=960 960w, ${imageSrc}?imwidth=1440 1440w`}
            src={imageSrc}
            className='Benefits-Image-Item'
            alt=''
            loading='lazy'
          />
        ) : null}
      </Col>
    );
  };

  /**
   * the function that return the content with text
   */
  getTextContent = (item, position) => {
    const { subTitle, title, description } = item;
    const cssClass = classNames('Benefits-NOPadding-Right', {
      'Benefits-Left': position
    });

    return (
      <Col sm={12} md={12} lg={4} xl={4} className={cssClass}>
        <div className='Benefits-TextContent'>
          <div className='Benefits-TextContent-Rectangle' />

          <div className='Benefits-TextContent-Category'>{subTitle}</div>
          <div className='Benefits-TextContent-Title'>{title}</div>
          <div className='Benefits-TextContent-Description'>{description}</div>
        </div>
      </Col>
    );
  };

  getElements = isMobile => {
    return this.props.benefitsData.map((item, index) => {
      const position = index & 1 ? true : false;

      if (!position) {
        return (
          <Row nogutter className='Benefits' key={index + item.title}>
            {this.getImageContent(item, position)}
            <Col sm={0} md={0} lg={1} xl={1} />
            {this.getTextContent(item, position)}
          </Row>
        );
      } else if (isMobile) {
        return (
          <Row nogutter className='Benefits' key={index + item.title}>
            {this.getImageContent(item, false)}
            <Col sm={0} md={0} lg={1} xl={1} />
            {this.getTextContent(item, false)}
          </Row>
        );
      }

      return (
        <Row nogutter className='Benefits' key={index + item.title}>
          {this.getTextContent(item, position)}
          <Col sm={0} md={0} lg={1} xl={1} />
          {this.getImageContent(item, position)}
        </Row>
      );
    });
  };

  render() {
    if (!this.props.benefitsData) {
      return <div className='whiteBackground' />;
    }

    return (
      <React.Fragment>
        <Visible lg xl>
          {this.getElements()}
        </Visible>
        <Visible xs sm md>
          {this.getElements(true)}
        </Visible>
      </React.Fragment>
    );
  }
}

export default BenefitsWithImages;
