import AllDownloads from '../AllDownloads';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import WithStickyMenu from '../../../hoc/WithStickyMenu';

const hasItems = function (props) {
  const { allDownloads } = props;
  const amountOfInfoDownloads = allDownloads?.info?.items?.length ?? 0;
  const amountOfMediaDownloads = allDownloads?.media?.items?.length ?? 0;
  const hasDownloads = amountOfInfoDownloads + amountOfMediaDownloads > 0;

  if (hasDownloads) {
    return true;
  }
  return false;
};

const mapStateToProps = state => {
  return {
    allDownloads: state.productDetails.allDownloads.items
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSitecoreContext()(WithStickyMenu(AllDownloads, hasItems)));
