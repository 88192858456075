import React from 'react';
import { connect } from 'react-redux';
import { setBounds } from '../../../store/salesandservicefilters/actions';
import { GoogleMap } from '@react-google-maps/api';
import MarkerList from './MarkerList';
import { MapScriptLoader, silver_style } from '../../../lib/MapScriptLoader';

let self = null;

class SalesAndServicePointsMap extends React.Component {
  constructor(props) {
    super(props);
    const { fields } = this.props;
    const defaultLatitude =
      fields &&
      fields.data &&
      fields.data.datasource &&
      fields.data.datasource.defaultLatitude &&
      fields.data.datasource.defaultLatitude.value
        ? Number(fields.data.datasource.defaultLatitude.value)
        : 0;
    const defaultLongitude =
      fields &&
      fields.data &&
      fields.data.datasource &&
      fields.data.datasource.defaultLongitude &&
      fields.data.datasource.defaultLongitude.value
        ? Number(fields.data.datasource.defaultLongitude.value)
        : 0;
    this.state = {
      lat: defaultLatitude,
      lng: defaultLongitude
    };

    self = this;
  }

  componentDidMount() {
    this.getGeoIpData();
  }

  getGeoIpData = () => {
    // Variable for the link - dynamically created
    const linkURL = window.location.origin + '/ResolveCountryByIp.aspx';
    return fetch(linkURL)
      .then(response => response.json())
      .then(data => {
        const latitude = data.Latitude,
          longitude = data.Longitude;

        if (latitude && latitude !== '' && longitude && longitude !== '') {
          this.setState({
            lat: Number(latitude),
            lng: Number(longitude),
            shouldCenterMap: true
          });
        }
      })
      .catch(() => {
        return null;
      });
  };

  onIdle() {
    const bounds = this.getBounds();
    if (bounds) {
      self.props.setNewBound(bounds);
    }
  }

  render() {
    const location = this.props.location || {
        lat: this.state.lat,
        lng: this.state.lng
      },
      { fields } = this.props,
      restrictedUser =
        fields &&
        fields.data &&
        fields.data.restrictedContactQuery &&
        fields.data.restrictedContactQuery.isRestrictedUser,
      zoomLevel =
        fields &&
        fields.data &&
        fields.data.datasource &&
        fields.data.datasource.defaultZoomLevel &&
        fields.data.datasource.defaultZoomLevel.value
          ? parseInt(fields.data.datasource.defaultZoomLevel.value)
          : 8;

    return (
      <MapScriptLoader>
        <GoogleMap
          id='map'
          mapContainerClassName='SalesAndServicePointsMap'
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          center={location}
          zoom={zoomLevel}
          options={{ styles: silver_style }}
          onIdle={this.onIdle}
        >
          <MarkerList isRestrictedUser={restrictedUser} />
        </GoogleMap>
      </MapScriptLoader>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.salesAndServiceFilter.searchLocation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewBound: bounds => dispatch(setBounds(bounds))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAndServicePointsMap);
