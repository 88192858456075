import React from 'react';
import { CoveoProductCarouselComponent } from './CoveoProductCarousel';
import { CoveoProductCarouselProps } from './types';
import CoveoComponent from '../CoveoComponent';
import WithStickyMenu from '../../../hoc/WithStickyMenu';

export const CoveoProductCarousel: React.FC<CoveoProductCarouselProps> = props => {
  const { SearchHub } = props.fields || {};
  const searchHub = SearchHub?.value ? SearchHub.value : 'CoveoProductCarousel';

  const searchEngineProps = {
    pipeline: 'ProductsPushApiPipeline',
    searchHub
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <CoveoProductCarouselComponent {...props} />
    </CoveoComponent>
  );
};

export default WithStickyMenu(CoveoProductCarousel, () => true);
