import React, { Fragment } from 'react';
import { FieldValidationErrors } from './field-validation-errors';
import { Label } from './label';

const Checkbox = props => {
  const { field, value, onChange, tracker, errors } = props;
  return (
    <Fragment>
      <Label {...props}>
        <input
          type='checkbox'
          className={field.model.cssClass}
          id={field.valueField.id}
          name={field.valueField.name}
          value='true'
          checked
          onChange={e => handleOnChange(field, e.target.checked, onChange)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
        />
      </Label>
      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

function handleOnChange(field, fieldValue, callback) {
  callback(field.valueField.name, fieldValue, true, []);
}

export default Checkbox;
