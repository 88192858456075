import React, { useEffect } from 'react';
import { getLocalStorage } from '../Common/CookieSettings/CookieUtils';

/**
 * RedirectUrl component is used by RedirectPage when a user is send back from azure
 * b2c login to know on which page we should redirect the user
 */
const RedirectUrl = function () {
  useEffect(function () {
    let redirectURL = getLocalStorage('currentPageLocation') || '/';

    // if currentPageLocation is set in local storage then we should send the user to that page
    // the timeout is necessary for MSAL to set user data
    setTimeout(() => {
      window.location.href = window.location.origin + redirectURL;
    }, 500);
  }, []);

  return <div>Please Wait ...</div>;
};

export default RedirectUrl;
