import * as actionTypes from './actionTypes';
import {
  SAVE_FAVOURITE_CONTACT,
  DELETE_FAVOURITE_CONTACT,
  GET_FAVOURITE_CONTACT
} from '../../Constants/FavouriteContact';
import { FORBIDDEN_STATUS_CODE } from '../../Constants/General';
import { checkIfUserIsLoggedIn, logOutRedirect } from '../../utils/Utils';

export const getFavoriteContactId = (id, sitecoreContextFields) => {
  return dispatch => {
    const endpoint = GET_FAVOURITE_CONTACT(),
      isUser = checkIfUserIsLoggedIn(sitecoreContextFields);
    if (endpoint && !id && isUser) {
      fetch(endpoint)
        .then(response => {
          if (response.ok) {
            return response.text();
          } else if (response.status === FORBIDDEN_STATUS_CODE) {
            logOutRedirect();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          if (responseJson) {
            dispatch(addFavouriteContactIdOnRedux(responseJson));
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

export const addFavouriteContactId = contactPointId => {
  return dispatch => {
    const endpoint = SAVE_FAVOURITE_CONTACT(contactPointId);
    if (endpoint) {
      fetch(endpoint)
        .then(response => {
          if (response.ok) {
            return response.text();
          } else if (response.status === FORBIDDEN_STATUS_CODE) {
            logOutRedirect();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          if (responseJson) {
            dispatch(addFavouriteContactIdOnRedux(responseJson));
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

export const deleteFavouriteContactId = contactPointId => {
  return dispatch => {
    const endpoint = DELETE_FAVOURITE_CONTACT(contactPointId);
    if (endpoint) {
      fetch(endpoint)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === FORBIDDEN_STATUS_CODE) {
            logOutRedirect();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          if (responseJson) {
            dispatch(deleteFavouriteContactIdOnRedux());
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

export const setFavoriteContactData = (query, endpoint, contactId) => {
  return dispatch => {
    if (endpoint) {
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { id: contactId }
        })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(dataJson => {
          if (dataJson) {
            if (dataJson && dataJson.data && dataJson.data.item) {
              dispatch(setFavoriteContactDataOnRedux(dataJson.data.item));
            }
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

const setFavoriteContactDataOnRedux = favoriteContactData => {
  return {
    type: actionTypes.SET_FAVORITE_CONTACT_DATA,
    favoriteContactData
  };
};

export const addFavouriteContactIdOnRedux = favoriteContactId => {
  return {
    type: actionTypes.ADD_FAVORITE_CONTACT_ID,
    favoriteContactId
  };
};

const deleteFavouriteContactIdOnRedux = () => {
  return {
    type: actionTypes.DELETE_FAVORITE_CONTACT_ID
  };
};
