import React from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';

export const NotFound = ({ fields }) => (
  <div className='NotFound'>
    <div className='NotFound-ErrorCode'>
      <Text field={fields.ErrorCode} />
    </div>
    <div className='NotFound-Title'>
      <Text field={fields.Title} />
    </div>
    <div className='NotFound-Description'>
      <Text field={fields.Description} />
    </div>
    <div className='NotFound-Buttons'>
      <div className='NotFound-Buttons-Home-Link'>
        <Link field={fields.HomeLink} />
      </div>
      <div className='NotFound-Buttons-Contact-Link'>
        <Link field={fields.ContactLink} />
      </div>
    </div>
  </div>
);

export default NotFound;
