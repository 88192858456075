// Import redux utils
import { compose, applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { reducer } from './language/reducer';
import { menuFlyout } from './menuflyout/reducer';
import { config } from '../config';
import { mobileMenu } from './mobileMenu/reducer';
import { accountInformation } from './accountInformation/reducer';
import { productDetails } from './product/reducer';
import { stickyMenu } from './stickyMenu/reducer';
import { contactForms } from './contactForms/reducer';
import { salesAndServiceFilter } from './salesandservicefilters/reducer';
import { contactPerson } from './contactPerson/reducer';
import { contactInfoDetails } from './contactInfoDetails/reducer';
import { wishlistProducts } from './wishlist/reducer';
import { compareProduct } from './compare/reducer';
import { myDevices } from './mydevices/reducer';
import { favoriteContact } from './favoriteContact/reducer';
import { countries } from './countries/reducer';

const rootReducer = combineReducers({
  languageInfo: reducer,
  menuFlyout,
  mobileMenu,
  accountInformation,
  productDetails,
  stickyMenu,
  contactForms,
  salesAndServiceFilter,
  contactPerson,
  contactInfoDetails,
  wishlistProducts,
  compareProduct,
  myDevices,
  favoriteContact,
  countries
});

let composeEnhancers = compose;
let createStoreM = null;

if (
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  (process.env.NODE_ENV === 'development' || config.env === 'dev')
) {
  // In Redux devtools mode: inspect the store with the Redux devtools browser extension.
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  createStoreM = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));
} else {
  createStoreM = createStore(rootReducer, applyMiddleware(ReduxThunk));
}

export const store = createStoreM;

export const createStoreWithPreloadedData = preloadedData => {
  return createStore(rootReducer, preloadedData, applyMiddleware(ReduxThunk));
};
