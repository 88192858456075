import { NumericFacet, NumericFacetValue } from '@coveo/headless';
import React, { useContext, useEffect, useState } from 'react';
import { SearchContext, ActionType } from '../context/SearchContext';
import Facet, { FacetOption } from '../Facet';
import { scrollToTopOfSearchResults } from '../SearchResults/scrollToSearchResults';

type RangeFacetProps = {
  controller: NumericFacet;
  title: string;
  id: string;
  precision?: number;
  unit: string;
  isVisible?: boolean;
};

export const formatRange = (start: number, end: number, precision?: number, unit?: string) =>
  start === end
    ? `${start.toFixed(precision)}${unit}`
    : `${start.toFixed(precision)}${unit}-${end.toFixed(precision)}${unit}`;

export const RangeFacet: React.FC<RangeFacetProps> = ({ id, controller, title, precision, unit, isVisible }) => {
  const { dispatch } = useContext(SearchContext);
  const [state, setState] = useState(controller.state);
  const [selectedValuesText, setSelectedValuesText] = useState('');
  const [options, setOptions] = useState<FacetOption[]>([]);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  useEffect(() => {
    dispatch({
      type: ActionType.REGISTER_NUMERIC_FACET,
      payload: {
        field: id,
        unit,
        precision
      }
    });
  }, []);

  useEffect(() => {
    const selected = state.values.filter(value => controller.isValueSelected(value));

    setSelectedValuesText(selected.map(value => formatRange(value.start, value.end, precision, unit)).join(', '));

    if (state.values.length) {
      setOptions(
        state.values
          .filter(value => value.numberOfResults > 0)
          .map(value => ({
            value,
            numberOfResults: value.numberOfResults,
            label: formatRange(value.start, value.end, precision, unit),
            selected: controller.isValueSelected(value)
          }))
      );
    } else {
      setOptions([]);
    }

    dispatch({
      type: ActionType.UPDATE_FACET_SELECTED_COUNT,
      payload: {
        id,
        selected
      }
    });
  }, [state]);

  function toggleSelect(value: NumericFacetValue) {
    controller.toggleSelect(value);
    scrollToTopOfSearchResults();
  }

  return (
    <Facet
      isLoading={state.isLoading}
      options={options}
      title={title}
      selectedValuesText={selectedValuesText}
      toggleSelect={toggleSelect}
      isVisible={isVisible}
    />
  );
};

export default RangeFacet;
