import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../HeadlineWithTitleAndSubtitle/index';

/**
 * @class Component that renders a title, subtitle and a list of page linker components
 *
 * @param {object} props Object containing the Text (for RichText)
 *
 * @returns jsx
 */
class PageLinkerList extends React.Component {
  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground componentContainer' />;
    }
    const { Title, Subtitle, BackgroundColor } = this.props.fields,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      isBackgroundBlack = BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000';

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='PageLinkerList componentContainer'>
          <HeadlineWithTitleAndSubtitle
            extraClasses='PageLinkerList-Headline'
            isTextWhite={isBackgroundBlack}
            title={Title}
            subtitle={Subtitle}
          />
          <div className='PageLinkerList-Placeholder'>
            <Placeholder name='jss-page-linker-list' rendering={this.props.rendering} />
          </div>
        </div>
      </div>
    );
  }
}

const hasItems = function (props) {
  const { fields } = props;
  if (!fields) {
    return false;
  }
  return true;
};

export default WithStickyMenu(PageLinkerList, hasItems);
