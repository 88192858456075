import * as ActionTypes from './actionTypes';

//define the default state
const initialState = {
  isMobileMenuOpen: false,
  openAccordion: null,
  openAccordionSecondLevel: null,
  isBreadcrumbVisible: false,
  underlinedMenuItem: null,
  urlOnOpenMenu: null
};

/**
 * @method mobileMenu
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */
export const mobileMenu = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MOBILE_MENU: {
      const newUrlOnOpenMenu = !state.urlOnOpenMenu && !state.isMobileMenuOpen ? window.location.href : null;
      return {
        ...state,
        openAccordion: null,
        openAccordionSecondLevel: null,
        isMobileMenuOpen: !state.isMobileMenuOpen,
        urlOnOpenMenu: newUrlOnOpenMenu
      };
    }
    case ActionTypes.CHANGE_OPEN_ACCORDION:
      return {
        ...state,
        openAccordion: action.openedAccordion === state.openAccordion ? null : action.openedAccordion,
        openAccordionSecondLevel: null
      };
    case ActionTypes.CHANGE_SECOND_OPEN_ACCORDION:
      return {
        ...state,
        openAccordionSecondLevel:
          action.secondOpenedAccordion === state.openAccordionSecondLevel ? null : action.secondOpenedAccordion
      };
    case ActionTypes.SET_BREADCRUMB_TO_VISIBLE:
      return state.isBreadcrumbVisible
        ? state
        : {
            ...state,
            isBreadcrumbVisible: true
          };
    case ActionTypes.REMOVE_BREADCRUMB_VISIBLE_STATE:
      return state.isBreadcrumbVisible
        ? {
            ...state,
            isBreadcrumbVisible: false,
            underlinedMenuItem: null
          }
        : state;
    case ActionTypes.SET_UNDERLINE_MENU_ITEM:
      return {
        ...state,
        underlinedMenuItem: action.crumbId
      };

    default:
      return state;
  }
};
