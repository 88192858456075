export default `query ContactInfoQuery($id: String) {
  item(path: $id) {
    ... on SalesAndServicePointHero {
      kindOfPartner {
        targetItem {
          name: field(name: "Name") {
            value
          }
          id: field(name: "Id") {
            value
          }
          icon: field(name: "Icon") {
            jss
          }
        }
      }
      solutionProvider {
        targetItems {
          field(name: "id") {
            value
          }
          name: field(name: "Name") {
            value
          }
        }
      }
      companyName {
        value
      }
      street {
        value
      }
      streetNumber {
        value
      }
      streetAddendum {
        value
      }
      city {
        value
      }
      email {
        value
      }
      zipCode {
        value
      }
      country {
        targetItem {
          field(name: "RegionName") {
            value
          }
        }
      }
      phone {
        value
      }
      contactPageItems {
        targetItems {
          form: field(name: "Form") {
            value
          }
          link: field(name: "Link") {
            jss
          }
          name: field(name: "Name") {
              value
          }
        }
      }
      isOptOutForFormMailSubmission {
        value
      }
    }
  }
}`;
