import React, { useCallback, useState } from 'react';
import CloseIcon from '../Common/Icons/CloseIcon';
import Button from '../Common/Button/Button';
import './ConfirmationDialog.scss';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

type ConfirmationDialogProps = {
  modalTitle?: string;
  modalText?: string;
  onClose: () => void;
  primaryButtonLabel?: string;
  onPrimaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  children?: React.ReactNode;
  transparentOverlay?: boolean;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  modalTitle,
  modalText,
  onClose,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  children,
  transparentOverlay = false
}) => {
  const [primaryButtonIsLoading, setPrimaryButtonIsLoading] = useState(false);
  const [secondaryButtonIsLoading, setSecondaryButtonIsLoading] = useState(false);

  const primaryButtonClicked = useCallback(() => {
    setPrimaryButtonIsLoading(true);
    onPrimaryButtonClick && onPrimaryButtonClick();
  }, [onPrimaryButtonClick]);

  const secondaryButtonClicked = useCallback(() => {
    setSecondaryButtonIsLoading(true);
    onSecondaryButtonClick && onSecondaryButtonClick();
  }, [onSecondaryButtonClick]);

  return createPortal(
    <div
      className={classNames('ConfirmationDialog', {
        'ConfirmationDialog-TransparentOverlay': transparentOverlay
      })}
    >
      <div className='ConfirmationDialog-DetailsContainer'>
        <div className='ConfirmationDialog-Heading'>
          {modalTitle && <span className='ConfirmationDialog-Title'>{modalTitle}</span>}
          <CloseIcon onClick={onClose} />
        </div>
        {/* We can render the modal text or the children of the component to keep this component flexible and easy to use */}
        {modalText && <div className='ConfirmationDialog-Text'>{modalText}</div>}
        {children && <div className='ConfirmationDialog-Text'>{children}</div>}
        <div className='ConfirmationDialog-Buttons'>
          {primaryButtonLabel && (
            <Button
              variant='primary'
              buttonTitle={primaryButtonLabel}
              onClick={primaryButtonClicked}
              isLoading={primaryButtonIsLoading}
            />
          )}
          {secondaryButtonLabel && (
            <Button
              variant='secondary'
              buttonTitle={secondaryButtonLabel}
              onClick={secondaryButtonClicked}
              isLoading={secondaryButtonIsLoading}
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmationDialog;
