import i18n from 'i18next';
import { DownloadType, DownloadsList } from './types';

const getDownloadListData = (
  selectedTab: string,
  allDownloads: Record<DownloadType, DownloadsList> | null,
  isUserLoggedIn: boolean,
  currentCategory: string | null,
  currentLanguage: string | null
): DownloadsList | null => {
  let downloadList: DownloadsList | null = null;

  if (selectedTab === 'info') {
    downloadList = allDownloads?.info ?? null;
  }

  if (selectedTab === 'media') {
    downloadList = allDownloads?.media ?? null;
  }

  if (selectedTab === 'all') {
    const infoCategories = allDownloads?.info?.categories || [];
    const mediaCategories = isUserLoggedIn ? allDownloads?.media?.categories || [] : [];
    const categories = [...infoCategories, ...mediaCategories].sort((a, b) => a.localeCompare(b));
    const infoLanguages = allDownloads?.info?.languages || [];
    const mediaLanguages = allDownloads?.media?.languages || [];
    const languages = [...infoLanguages, ...mediaLanguages];
    let mediaItems = isUserLoggedIn ? allDownloads?.media?.items || [] : [];

    mediaItems = mediaItems.map(item => {
      item.isVisibleForAllLanguages = true;

      return item;
    });

    const items = isUserLoggedIn ? allDownloads?.info?.items.concat(mediaItems) || [] : allDownloads?.info?.items || [];

    downloadList = {
      categories,
      items,
      languages
    };
  }

  if (downloadList) {
    downloadList.filteredItems = downloadList.items.filter(
      item =>
        (currentCategory === item.category ||
          currentCategory === null ||
          currentCategory === i18n.t('PRODUCT_DOWNLOADS | All')) &&
        (currentLanguage === null ||
          item.isVisibleForAllLanguages ||
          (item.language && item.language.includes(currentLanguage)) ||
          currentLanguage === i18n.t('PRODUCT_DOWNLOADS | All')) &&
        item.approvedForWeb
    );
  }

  return downloadList;
};

export default getDownloadListData;
