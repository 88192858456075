import React from 'react';
import { EventAddToCalendarProps } from '../types';
import { downloadCalendar } from './DownloadCalendar';
import Button from '../../../../Button/Button';
import i18n from 'i18next';

export const EventAddToCalendar = ({
  eventTitle,
  eventStartDate,
  eventEndDate,
  eventCity,
  eventCountry,
  eventVenue,
  eventAddress,
  eventLinkDirections,
  eventBoothInformation,
  eventLinkOrganizer,
  eventLinkBooth
}: EventAddToCalendarProps): JSX.Element | null => {
  const startDateText = eventStartDate.value || '';
  const endDateText = eventEndDate.value || '';
  const startDateObj = new Date(startDateText);
  const endDateObj = new Date(endDateText);
  const regionName = eventCountry?.fields?.RegionName;

  return (
    <div className='EventAddToCalendar' data-testid='event-add-to-calendar'>
      <Button
        buttonTitle={i18n.t('EVENT_DETAIL | Add To Calendar')}
        onClick={() =>
          downloadCalendar({
            eventTitle,
            startDateObj,
            endDateObj,
            eventCity,
            regionName,
            eventVenue,
            eventAddress,
            eventLinkDirections,
            eventBoothInformation,
            eventLinkOrganizer,
            eventLinkBooth
          })
        }
        isLoading={false}
        data-testid='ics-download'
      />
    </div>
  );
};
