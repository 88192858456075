import React, { Fragment } from 'react';
import Spinner from '../../../../Common/Spinner';

// NOTE: onButtonClick is not a submit handler;
// it signals to the parent form which button invoked the submit action
// (which is important for multi-step forms where multiple submits can occur i.e. back/forward)

const Button = ({ field, onButtonClick, isLoading }) => (
  <Fragment>
    <button
      type='submit'
      value={field.model.title}
      name={field.buttonField.name}
      id={field.buttonField.id}
      onClick={() => onButtonClick(field.buttonField.name)}
      disabled={isLoading}
    >
      <span>
        <span>{field.model.title}</span>
        {isLoading && <Spinner />}
      </span>
    </button>
  </Fragment>
);

export default Button;
