import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { checkAndGetDevice, setDeviceErrorCode } from '../../../../../../store/mydevices/actions';
import PlusIcon from '../../../../../Common/Icons/PlusIcon';
import SuccessIcon from '../../../../../Common/Icons/SuccessIcon';
import {
  NOT_FOUND_STATUS_CODE,
  ALREADY_IN_ACCOUNT_STATUS_CODE,
  IN_OTHER_ACCOUNT_STATUS_CODE,
  SERVER_ERROR_STATUS_CODE
} from '../../../../../../../src/Constants/General';
import ErrorMessageWithSupport from './ErrorMessageWithSupport';

const SerialNumberRequiredDigits = 10;
const ArticleNumberRequiredDigits = 7;

class AddDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serialNumberValue: '',
      articleNumberValue: '',
      inventoryNumberValue: '',
      checkArticleNumber: false,
      checkSerialNumber: false,
      isSerialValid: false,
      isArticleValid: false,
      isAlreadyAdded: false
    };
  }
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./AddDevice.scss');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newAddedDevicesList.length !== this.props.newAddedDevicesList.length) {
      this.setState({
        serialNumberValue: '',
        articleNumberValue: '',
        inventoryNumberValue: '',
        checkArticleNumber: false,
        checkSerialNumber: false,
        isSerialValid: false,
        isArticleValid: false
      });
    }
  }
  addingClassOnInputChange = inputTarget => {
    const parentClasses = inputTarget.parentNode.classList;
    if (inputTarget.value !== '') {
      if (!parentClasses.contains('floating')) {
        parentClasses.add('floating');
      }
    }
  };

  onArticleInputChange = evt => {
    const element = evt.target;
    this.addingClassOnInputChange(element);
    this.setState({ articleNumberValue: element.value });
    if (element.value.length === ArticleNumberRequiredDigits && !element.value.includes('-')) {
      this.setState({ checkArticleNumber: true });
    } else {
      this.setState({ checkArticleNumber: false });
    }
  };

  onSerialInputChange = evt => {
    const element = evt.target;
    this.addingClassOnInputChange(element);
    this.setState({ serialNumberValue: element.value });
    if (element.value.length === SerialNumberRequiredDigits && !element.value.includes('-')) {
      this.setState({ checkSerialNumber: true });
    } else {
      this.setState({ checkSerialNumber: false });
    }
  };

  onInventoryInputChange = evt => {
    const element = evt.target;
    this.addingClassOnInputChange(element);
    this.setState({ inventoryNumberValue: element.value });
  };

  onInputFocus = evt => {
    const element = evt.target;
    if (element) {
      const parentClasses = element.parentNode.classList;
      if (!parentClasses.contains('floating')) {
        parentClasses.add('floating');
      }
    }
  };
  onInputBlur = evt => {
    const element = evt.target;
    if (element) {
      if (!(element.value.trim() !== '')) {
        const parentClasses = element.parentNode.classList;
        if (parentClasses.contains('floating')) {
          parentClasses.remove('floating');
        }
      }
    }
  };

  getInput = (label, onInputChange, required, mask, hasValue, isValid, checked) => {
    const validationCssClass = classNames('AddDevice-InputContainer-ValidationError', {
        Active: isValid && !checked
      }),
      inputCssClass = classNames('AddDevice-InputContainer-Input', {
        ErrorBorder: isValid && !checked,
        floating: hasValue
      }),
      { validationInputErrorMessage } = this.props.fields;

    return (
      <div className='AddDevice-InputContainer-Wrapper'>
        <div className={inputCssClass}>
          <span className='AddDevice-InputContainer-Input-Label'>
            <Text field={label} />
            {required ? '*' : null}
          </span>
          <InputMask
            mask={mask}
            maskChar='-'
            required={required}
            onChange={onInputChange}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
            value={hasValue}
          />
          {checked ? <SuccessIcon /> : null}
        </div>
        <Text field={validationInputErrorMessage} className={validationCssClass} tag='span' />
      </div>
    );
  };

  displayErrorMessage = () => {
    const { errorAtAddingDevice } = this.props,
      { errorMessageAlreadyInMyDevices, errorMessageAlreadyInOtherAcount, errrorMessageTryLater } = this.props.fields;
    let labelError = null;

    if (errorAtAddingDevice === ALREADY_IN_ACCOUNT_STATUS_CODE) {
      labelError = errorMessageAlreadyInMyDevices;
    } else if (errorAtAddingDevice === IN_OTHER_ACCOUNT_STATUS_CODE) {
      labelError = errorMessageAlreadyInOtherAcount;
    } else if (errorAtAddingDevice === SERVER_ERROR_STATUS_CODE) {
      labelError = errrorMessageTryLater;
    }
    return <Text field={labelError} className='AddDevice-AlreadyAddedErrorMessage Active' tag='div' />;
  };

  addDevice = () => {
    const { checkArticleNumber, checkSerialNumber, serialNumberValue, articleNumberValue, inventoryNumberValue } =
        this.state,
      { checkAndGetDevice, newAddedDevicesList } = this.props;
    const checkNewDevice = newAddedDevicesList.find(
      item => item.articleNumber === articleNumberValue && item.serialNumber === serialNumberValue
    );
    if (checkNewDevice) {
      this.setState({ isAlreadyAdded: true });
    } else {
      if (checkArticleNumber && checkSerialNumber) {
        this.setState({ isAlreadyAdded: false });
        checkAndGetDevice(articleNumberValue, serialNumberValue, inventoryNumberValue);
      } else {
        this.checkRequiredInputsWithError();
        this.props.setDeviceErrorCode(null);
        this.setState({ isAlreadyAdded: false });
      }
    }
  };

  checkRequiredInputsWithError = () => {
    const { checkArticleNumber, checkSerialNumber } = this.state;
    this.setState({
      isSerialValid: !checkSerialNumber,
      isArticleValid: !checkArticleNumber
    });
  };

  render() {
    const {
        addDeviceButtonLabel,
        serialNumberLabel,
        articleNumberLabel,
        inventoryNumberLabel,
        alreadyAddedErrorMessage
      } = this.props.fields,
      {
        checkSerialNumber,
        checkArticleNumber,
        isSerialValid,
        isArticleValid,
        serialNumberValue,
        articleNumberValue,
        inventoryNumberValue,
        isAlreadyAdded
      } = this.state,
      { errorAtAddingDevice, fields } = this.props,
      alreadyAddedCssClass = classNames('AddDevice-AlreadyAddedErrorMessage', {
        Active: isAlreadyAdded
      });

    return (
      <div className='AddDevice'>
        <div className='AddDevice-Wrapper'>
          <div className='AddDevice-InputContainer'>
            {this.getInput(
              serialNumberLabel,
              this.onSerialInputChange,
              true,
              '9999999999',
              serialNumberValue,
              isSerialValid,
              checkSerialNumber
            )}
            {this.getInput(
              articleNumberLabel,
              this.onArticleInputChange,
              true,
              '999.999',
              articleNumberValue,
              isArticleValid,
              checkArticleNumber
            )}
            {this.getInput(inventoryNumberLabel, this.onInventoryInputChange, false, '', inventoryNumberValue)}
          </div>
          <div
            onClick={this.addDevice}
            onKeyDown={e => {
              if (e.target === 13) this.addDevice();
            }}
            role='button'
            tabIndex='0'
            className='AddDevice-Button'
          >
            <PlusIcon />
            <Text field={addDeviceButtonLabel} />
          </div>
        </div>
        {/* for NOT_FOUND_STATUS_CODE is rendered a different error (with overlay) */}
        {(errorAtAddingDevice && errorAtAddingDevice === NOT_FOUND_STATUS_CODE && !isAlreadyAdded && (
          <ErrorMessageWithSupport fields={fields} />
        )) ||
          ''}
        {(errorAtAddingDevice && !isAlreadyAdded && this.displayErrorMessage()) || ''}
        <Text field={alreadyAddedErrorMessage} className={alreadyAddedCssClass} tag='div' />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    newAddedDevicesList: state.myDevices.newAddedDevicesList,
    errorAtAddingDevice: state.myDevices.errorAtAddingDevice
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkAndGetDevice: (articleNumber, serialNumber, inventoryNumber) =>
      dispatch(checkAndGetDevice(articleNumber, serialNumber, inventoryNumber)),
    setDeviceErrorCode: errorCode => dispatch(setDeviceErrorCode(errorCode))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
