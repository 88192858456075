import React from 'react';
import CoveoComponent from '../../../CoveoComponent';
import WeldingMachinesListerComponent from './WeldyWeldingMachinesLister';

export const WeldyWeldingMachinesLister: React.FC = () => {
  const searchEngineProps = {
    pipeline: 'SearchProduct',
    searchHub: 'Welding-Machines'
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WeldingMachinesListerComponent />
    </CoveoComponent>
  );
};

export default WeldyWeldingMachinesLister;
