import React, { useContext } from 'react';
import i18n from 'i18next';
import DeleteIcon from '../../../Common/Icons/DeleteIcon';
import { CartContext } from '../../context/CartContext';

interface DeleteItemButtonProps {
  deleteAction: () => void;
}

const DeleteItemButton: React.FC<DeleteItemButtonProps> = ({ deleteAction }) => {
  const { state } = useContext(CartContext);
  return (
    <button
      type='button'
      disabled={state.isUpdating}
      onClick={() => deleteAction()}
      className='DeleteItemButton'
      data-testid='remove-item'
    >
      <DeleteIcon />
      <span>{i18n.t('SHOP | Cart remove item')}</span>
    </button>
  );
};

export default DeleteItemButton;
