import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Visible } from 'react-grid-system';
import { Text, RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { setSalesContactEmail } from '../../../store/contactForms/actions';
import { setContactInfoDetails } from '../../../store/contactInfoDetails/actions';
import Checkmark from '../../Common/Icons/Checkmark';
import GeneralLink from '../../Common/GeneralLink/index';
import TelephoneIcon from '../../Common/Icons/TelephoneIcon';
import EmailIcon from '../../Common/Icons/EmailIcon';
import ShopIcon from '../../Common/Icons/ShopIcon';
import WebIcon from '../../Common/Icons/WebIcon';
import { removeWhiteSpaces } from '../../../utils/Utils';
import SocialLink from '../../Footer/SocialLink';
import HomeIcon from '../../Common/Icons/HomeIcon';

/**
 * @method SalesAndServicePointDetailPageHero
 *
 * @class Component that renders the Sales and Services Page hero
 *
 * @returns jsx
 */

class SalesAndServicePointDetailPageHero extends React.Component {
  componentDidMount() {
    this.setDataOnRedux();
  }

  componentDidUpdate() {
    this.setDataOnRedux();
  }

  /**
   * @description sets page Id and first email value  on redux
   */

  setDataOnRedux() {
    const {
        currentInfoSalesEmail,
        currentInfoSalesPageId,
        setSalesContactEmail,
        sitecoreContext,
        setContactInfoDetails
      } = this.props,
      pageId = sitecoreContext.itemId,
      componentName = 'SalesAndServicePointDetailPageHero',
      component = sitecoreContext.route.placeholders['jss-main'].find(
        item => item.componentName === componentName && item.fields && item.fields.Email && item.fields.Email.value
      );

    if (
      component &&
      (component.fields.Email.value !== currentInfoSalesEmail || currentInfoSalesPageId !== pageId) &&
      !(currentInfoSalesEmail && currentInfoSalesPageId === pageId)
    ) {
      const { City, Street, 'Street Number': StreetNumber, 'Company Name': companyName } = component.fields,
        Country = component.fields.Country && component.fields.Country.fields.RegionName,
        contactDetails = {
          name: companyName && companyName.value,
          street: Street && Street.value,
          number: StreetNumber && StreetNumber.value,
          city: City && City.value,
          country: Country && Country.value
        };

      setSalesContactEmail(component.fields.Email.value, pageId);
      setContactInfoDetails(contactDetails);
    }
  }

  /**
   * @description renders one or more  text elements with checkmark , according to data
   */

  getHighlightsCheckmarksItems(highlights) {
    return highlights.map((item, index) => {
      return (
        <div
          key={'highlights' + index}
          className='SalesAndServicePointDetailPageHero-Container-Content-Highlights-Items-Item'
        >
          <Checkmark /> <RichText field={item.fields.Text} />
        </div>
      );
    });
  }

  /**
   * @description display the heighlights  element if there are heightlist items selected
   */

  getHighlights() {
    const { Highlights, 'Highlights Label': HighlightsLabel } = this.props.fields,
      hightlight = Highlights ? Highlights : [];
    if (hightlight.length > 0) {
      return (
        <div className='SalesAndServicePointDetailPageHero-Container-Content-Highlights'>
          <Text
            className='SalesAndServicePointDetailPageHero-Container-Content-Highlights-Label'
            field={HighlightsLabel}
            tag='div'
          />
          <div className='SalesAndServicePointDetailPageHero-Container-Content-Highlights-Items'>
            {this.getHighlightsCheckmarksItems(hightlight)}
          </div>
        </div>
      );
    }
  }

  /**
   * @description renders a link with a logo image , according to data
   */

  getLogoImage(imgFields, imgLink) {
    return (
      <GeneralLink fields={imgLink} className='SalesAndServicePointDetailPageHero-Container-Logo-Image'>
        <Image field={imgFields} />
      </GeneralLink>
    );
  }

  /**
   * @description renders a phone link , a text and an icon , according to data
   */

  getPhone = () => {
    const { Phone } = this.props.fields;

    if (Phone && Phone.value) {
      return (
        <GeneralLink
          className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Number'
          fields={{
            value: { href: 'tel:' + removeWhiteSpaces(Phone.value) }
          }}
        >
          <TelephoneIcon />
          <Text field={Phone} />
        </GeneralLink>
      );
    }

    return null;
  };

  /**
   * @description renders a phone link , a text  and an icon, according to data
   */

  getFax() {
    const { Fax } = this.props.fields;
    if (Fax && Fax.value) {
      return (
        <GeneralLink
          className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Number'
          fields={{
            value: { href: 'tel:' + removeWhiteSpaces(Fax.value) }
          }}
        >
          <span className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Number-Fax' />
          <Text field={Fax} />
        </GeneralLink>
      );
    }
  }

  /**
   * @description renders a link with text and an icon , according to data
   */

  getShop = shopLink => {
    if (shopLink) {
      return (
        <GeneralLink
          fields={shopLink}
          className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Link'
        >
          <ShopIcon />
          <Text field={{ value: shopLink.value.text }} />
        </GeneralLink>
      );
    }
    return null;
  };

  /**
   * @description renders a link with text and an icon , according to data
   */

  getWebsite = websiteLink => {
    if (websiteLink) {
      return (
        <GeneralLink
          fields={websiteLink}
          className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Link'
        >
          <WebIcon />
          <Text field={{ value: websiteLink.value.text }} />
        </GeneralLink>
      );
    }
    return null;
  };

  /**
   * @description renders an email link with text and an icon , according to data
   */

  getEmail = () => {
    const { Email } = this.props.fields;
    if (Email && Email.value) {
      return (
        <GeneralLink
          fields={{ value: { href: 'mailto:' + Email.value } }}
          className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items-Email'
        >
          <EmailIcon />
          <Text field={Email} />
        </GeneralLink>
      );
    }
    return null;
  };

  /**
   * @description renders a link with text and an icon , according to data
   */

  getHowToFindUs = () => {
    const { 'How To Find Us': findUs, 'Page Selection': pageSelection } = this.props.fields;
    const findUsLabel =
      pageSelection && pageSelection.fields && pageSelection.fields['How To Find Us']
        ? pageSelection.fields['How To Find Us']
        : { value: '' };
    if (findUs) {
      return (
        <GeneralLink fields={findUs} className='SalesAndServicePointDetailPageHero-Container-Content-Adress-Link'>
          <HomeIcon />
          <Text field={findUsLabel} />
        </GeneralLink>
      );
    }
    return null;
  };

  /**
   * @description renders one or more SocialLink components , according to data
   */

  getSocialIconsItems() {
    const socialIcons = this.props.fields.SocialMediaIcons ? this.props.fields.SocialMediaIcons : [],
      items = socialIcons.map((element, index) => {
        return <SocialLink fields={element.fields} key={index + element.id} />;
      });

    return items;
  }

  /**
   * @description display the SocialItems  element if there are SocialLink items selected
   */

  getSocialIcon() {
    const socialIcons = this.props.fields.SocialMediaIcons ? this.props.fields.SocialMediaIcons : [];
    if (socialIcons.length > 0) {
      return (
        <div className='SalesAndServicePointDetailPageHero-Container-Content-Contact-SocialItems'>
          {this.getSocialIconsItems()}
        </div>
      );
    }
  }

  render() {
    if (!this.props.fields) {
      return <div className='SalesAndServicePointDetailPageHero componentContainerHero' />;
    }

    const {
      'Address Label': AddressLabel,
      City,
      Street,
      'Street Addendum': StreetAddendum,
      'Street Number': StreetNumber,
      'Zip Code': ZipCode,
      'Contact Label': ContactLabel,
      'Company Name': companyName,
      Logo1,
      Logo2,
      Logo3,
      Logo1Url,
      Logo2Url,
      Logo3Url,
      Website1,
      Website2,
      Website3,
      Shop1,
      Shop2,
      Shop3
    } = this.props.fields;
    const Country =
      this.props.fields.Country && this.props.fields.Country.fields.RegionName
        ? this.props.fields.Country.fields.RegionName
        : { value: '' };

    return (
      <div className='SalesAndServicePointDetailPageHero componentContainerHero'>
        <div className='SalesAndServicePointDetailPageHero-Container'>
          <div className='SalesAndServicePointDetailPageHero-Container-Content'>
            <Visible sm>
              <div className='SalesAndServicePointDetailPageHero-Container-Logo'>
                {this.getLogoImage(Logo1, Logo1Url)}
                {this.getLogoImage(Logo2, Logo2Url)}
                {this.getLogoImage(Logo3, Logo3Url)}
              </div>
            </Visible>
            <div className='SalesAndServicePointDetailPageHero-Container-Content-Wrapper'>
              <div className='SalesAndServicePointDetailPageHero-Container-Content-Adress'>
                <div className='SalesAndServicePointDetailPageHero-Container-Content-Adress-Label'>
                  <Text field={AddressLabel} />
                </div>
                <div className='SalesAndServicePointDetailPageHero-Container-Content-Adress-Items'>
                  <Text
                    field={companyName}
                    tag='div'
                    className='SalesAndServicePointDetailPageHero-Container-Content-Adress-Items-CompanyName'
                  />
                  <div>
                    <Text field={Street} />
                    &nbsp;
                    <Text field={StreetNumber} />
                  </div>
                  <Text field={StreetAddendum} />
                  <div>
                    <Text field={ZipCode} />
                    &#44;&nbsp;
                    <Text field={City} />
                  </div>
                  <div>
                    <Text field={Country} />
                  </div>
                  {this.getHowToFindUs()}
                </div>
              </div>
              <div className='SalesAndServicePointDetailPageHero-Container-Content-Contact'>
                <div className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Label'>
                  <Text field={ContactLabel} />
                </div>
                <div className='SalesAndServicePointDetailPageHero-Container-Content-Contact-Items'>
                  {this.getPhone()}
                  {this.getFax()}
                  {this.getEmail()}
                  {this.getWebsite(Website1)}
                  {this.getWebsite(Website2)}
                  {this.getWebsite(Website3)}
                  {this.getShop(Shop1)}
                  {this.getShop(Shop2)}
                  {this.getShop(Shop3)}
                  {this.getSocialIcon()}
                </div>
              </div>
              {this.getHighlights()}
            </div>
            <Visible md lg xl>
              <div className='SalesAndServicePointDetailPageHero-Container-Logo'>
                {this.getLogoImage(Logo1, Logo1Url)}
                {this.getLogoImage(Logo2, Logo2Url)}
                {this.getLogoImage(Logo3, Logo3Url)}
              </div>
            </Visible>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentInfoSalesEmail: state.contactForms.currentInfoSalesEmail,
    currentInfoSalesPageId: state.contactForms.currentInfoSalesPageId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSalesContactEmail: (currentInfoSalesEmail, currentInfoSalesPageId) =>
      dispatch(setSalesContactEmail(currentInfoSalesEmail, currentInfoSalesPageId)),
    setContactInfoDetails: contactInfoDetails => dispatch(setContactInfoDetails(contactInfoDetails))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSitecoreContext()(withRouter(SalesAndServicePointDetailPageHero)));
