import React, { useContext } from 'react';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { CoveoStoriesPipelines, CoveoStoriesSearchHubs } from '../../CoveoSettings';
import CoveoComponent from '../../CoveoComponent';
import StoriesListerComponent from './StoriesLister';

export const StoriesLister = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);
  const searchEngineProps = {
    pipeline: CoveoStoriesPipelines[siteName],
    searchHub: CoveoStoriesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <StoriesListerComponent />
    </CoveoComponent>
  );
};

export default StoriesLister;
