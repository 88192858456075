import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CategoryPipeDate = props => (
  <div className='CategoryPipeDate'>
    <Text className='category' tag='span' field={props.fields.Category} />
    <span className='pipe-bar'>|</span>
    <Text className='creationDate' tag='span' field={props.fields.CreationDate} />
  </div>
);

export default CategoryPipeDate;
