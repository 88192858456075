import React from 'react';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PlusIcon from '../../../../Common/Icons/PlusIcon';
import GeneralLink from '../../../../Common/GeneralLink';

class AddDeviceButton extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./AddDeviceButton.scss');
  }

  render() {
    const { hasBackground } = this.props,
      { 'New Device Label': addDeviceLabel, 'New Device Link': addDeviceUrl } = this.props.fields,
      cssClass = classNames('AddDeviceButton', {
        'AddDeviceButton-No-Background': !hasBackground,
        'AddDeviceButton-With-Background': hasBackground
      });

    return (
      <div className={cssClass}>
        <GeneralLink fields={addDeviceUrl}>
          <PlusIcon /> <Text field={addDeviceLabel} />
        </GeneralLink>
      </div>
    );
  }
}

export default AddDeviceButton;
