import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

class USPAccessories extends React.Component {
  generateUSPList = () => {
    const { metric } = this.props.usp;

    return metric.slice(0, 5).map((item, index) => {
      const cssClass = classNames('USPAccessories-Item', {
        'USPAccessories-Item-WithBackground': index & 1
      });

      return (
        <div key={index + item.name} className={cssClass}>
          <div className='USPAccessories-Item-Name'>{item.name}</div>
          <div className='USPAccessories-Item-Value'>{item.value}</div>
        </div>
      );
    });
  };

  render() {
    const { usp } = this.props;
    return <div className='USPAccessories'>{usp && usp.metric ? this.generateUSPList() : ''}</div>;
  }
}

const MapStateToProps = state => {
  return {
    usp: state.productDetails.masterProductData ? state.productDetails.masterProductData.technicalSpecs : null
  };
};

export default connect(MapStateToProps)(USPAccessories);
