import React from 'react';
import PageLinkerAutomaticallyComponent from '../index';
import { LAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class LAGPageLinkerAutomatically extends React.Component {
  render() {
    return <PageLinkerAutomaticallyComponent {...this.props} urlToUse={LAG_PAGE_LINK} />;
  }
}

export default LAGPageLinkerAutomatically;
