import React from 'react';
import { LTAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';
import UniversalImageSlider from '../UniversalImageSlider';

export default class LAGImageSlider extends React.Component {
  render() {
    return (
      <UniversalImageSlider
        leftImageWidth={66.66}
        imageClass='ImageSlider-Container-Multiple'
        fields={this.props.fields}
        urlToUse={LTAG_PAGE_LINK}
      />
    );
  }
}
