import React from 'react';
import { FaSearch } from 'react-icons/fa';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import PenIcon from '../../Common/Icons/PenIcon';

/**
 * component that renders a dropdown list and an optional search bar
 */

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newItems: [],
      searchText: '',
      isOpen: false,
      isAddNewClicked: false,
      addItemInputValue: null
    };
  }

  /* dropdown closes at click outside the dropdown container*/

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
        isAddNewClicked: !this.state.isAddNewClicked
      });
    }
  };

  // Click event - sets the clicked element
  onItemClick = element => {
    this.props.onSet(element);
  };

  // Lists the options for dropdown (filtered in case of the search input)

  getOptions = () => {
    const itemsArray =
      this.state.newItems.length >= 0 && this.state.searchText !== '' ? this.state.newItems : this.props.items || [];

    return itemsArray.map((element, index) => {
      const optionItemCss = classNames('Dropdown-Options-Item', {
        'Dropdown-Options-Item Active': element.title === this.props.currentItem.title
      });

      return (
        <li key={element.key || element.title + index}>
          <div
            className={optionItemCss}
            onClick={this.onItemClick.bind(this, element)}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onItemClick.bind(this, element);
            }}
            role='button'
            tabIndex='0'
          >
            {element.title}
          </div>
        </li>
      );
    });
  };

  onAddNewItemLabelClick = event => {
    event.stopPropagation();
    this.setState({
      isAddNewClicked: !this.state.isAddNewClicked,
      isOpen: true
    });
  };

  onInputClick = event => {
    event.stopPropagation();
  };

  onAddInputValueChanged = event => {
    this.setState({
      addItemInputValue: event.target.value
    });
  };

  handleInputCreate = () => {
    const { onCreate } = this.props,
      { addItemInputValue, isAddNewClicked } = this.state;
    onCreate(addItemInputValue);
    this.setState({
      isAddNewClicked: !isAddNewClicked,
      isOpen: false
    });
  };

  getAddNew() {
    const { addNewItemLabel, onCreate } = this.props;

    if (this.state.isAddNewClicked && addNewItemLabel && addNewItemLabel.title) {
      return (
        <li>
          <div
            className='Dropdown-Options-Item Input'
            onClick={this.onInputClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onInputClick();
            }}
            role='button'
            tabIndex='0'
          >
            <input type='text' onChange={this.onAddInputValueChanged} placeholder={addNewItemLabel.title} />
            <div
              onClick={this.handleInputCreate}
              onKeyDown={e => {
                if (e.target === 13) {
                  this.handleInputCreate();
                }
              }}
              role='button'
              tabIndex='0'
            >
              <PenIcon />
            </div>
          </div>
        </li>
      );
    } else if (addNewItemLabel && addNewItemLabel.title && onCreate) {
      return (
        <li>
          <div
            className='Dropdown-Options-Item'
            onClick={this.onAddNewItemLabelClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onAddNewItemLabelClick();
            }}
            role='button'
            tabIndex='0'
          >
            {addNewItemLabel.title}
          </div>
        </li>
      );
    }

    return null;
  }

  // Filters the initial list of elements using the user's input.
  onSearch = event => {
    const itemArray = this.props.items.filter(element =>
      element.title.toLowerCase().includes(event.target.value.toLowerCase())
    );
    this.setState({
      newItems: itemArray,
      searchText: event.target.value
    });
  };

  // Displays the input search if needed.
  getSearchInput() {
    if (this.props.hasSearch) {
      return (
        <div className='Dropdown-Search'>
          <input type='text' className='Dropdown-Search-Input' onChange={this.onSearch} value={this.state.searchText} />
          <span className='Dropdown-Search-Icon'>
            <FaSearch />
          </span>
        </div>
      );
    }
  }

  // Click event - shows/hides the dropdown
  onDropdownClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      searchText: !this.state.isOpen ? '' : this.state.searchText,
      isAddNewClicked: false
    });

    const { onDropdownClick } = this.props;

    if (onDropdownClick) {
      onDropdownClick();
    }
  };

  render() {
    const { label, currentItem, height } = this.props,
      dropdownOptionClass = classNames('Dropdown', {
        'Dropdown-Active': this.state.isOpen
      });

    return (
      <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
        <div className={dropdownOptionClass}>
          <div
            className='Dropdown-Wrapper'
            onClick={this.onDropdownClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onDropdownClick();
            }}
            role='button'
            tabIndex='0'
          >
            <span className='Dropdown-Wrapper-Label'>{label}</span>
            <span className='Dropdown-SelectedOption'>{currentItem.title}</span>
            <span className='Dropdown-Pointer' />
          </div>
          <div className='Dropdown-Options'>
            {this.getSearchInput()}
            <SimpleBarReact style={{ maxHeight: height || 130 }}>
              <div
                onClick={this.onDropdownClick}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.onDropdownClick();
                }}
                role='button'
                tabIndex='0'
              >
                <ul>
                  {this.getOptions()}
                  {this.getAddNew()}
                </ul>
              </div>
            </SimpleBarReact>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

export default Dropdown;
