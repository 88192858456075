import React from 'react';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import CardsCarousel from '../CardsCarousel/CardsCarousel';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import { removeWhiteSpaces, cutStringWithDots, get768ImageFromSrcSet } from '../../../utils/Utils';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../HeadlineWithTitleAndSubtitle/index';

class LinkerCarouselComponent extends React.Component {
  constructor(props) {
    super(props);

    const displayBehaviourFieldName = 'Display Behaviour';
    const carouselDisplayBehaviourFieldName = 'Carousel Display Behaviour';

    this.displayBehaviour =
      this.props.fields &&
      this.props.fields[displayBehaviourFieldName] &&
      this.props.fields[displayBehaviourFieldName].fields &&
      this.props.fields[displayBehaviourFieldName].fields[carouselDisplayBehaviourFieldName] &&
      this.props.fields[displayBehaviourFieldName].fields[carouselDisplayBehaviourFieldName].value;
    this.maximumNumberOfCardsToDisplay = this.displayBehaviour ? (this.displayBehaviour === 'Reduced' ? 2 : 3) : 3; // Hard fallback for when the field is not set
  }

  getCards() {
    const exploreMoreLabel = this.props.fields['Explore More Label'] ? this.props.fields['Explore More Label'] : null;

    return this.props.fields.LinkerCarouselElements.map((item, index) => {
      if (item.fields) {
        const { 'ClickText GTM English': gtm, Text: txt, Image: img, Title, Overline } = item.fields,
          img768 =
            img.value && img.value.srcSet ? get768ImageFromSrcSet(img.value.srcSet) : img.value.src + '?imwidth=768',
          PageSelection = item.fields[this.props.urlToUse] || null,
          url = !!PageSelection && PageSelection.value && PageSelection.value.href !== '' ? PageSelection : null,
          truncatedText = cutStringWithDots(txt && txt.value ? txt.value : null, 140),
          idVal = removeWhiteSpaces(gtm && gtm.value ? gtm.value : null);

        return (
          <div className='LinkerCarousel-Card' key={index + item.id}>
            <RoutableSitecoreLink
              link={url}
              dataId={idVal}
              className={'LinkerCarousel-Card-Link click_content_block_content_page_linker ' + idVal}
            >
              <div className='LinkerCarousel-Card-Image'>
                <img src={img768} alt={(img.value && img.value.alt) || ''} loading='lazy' />
              </div>

              <div className='LinkerCarousel-Card-Content'>
                <Text tag='div' className='LinkerCarousel-Card-Content-Category' field={Overline} />
                <Text tag='div' className='LinkerCarousel-Card-Content-Title' field={Title} />
                <RichText
                  className='LinkerCarousel-Card-Content-Description'
                  tag='div'
                  field={{
                    value: truncatedText || ''
                  }}
                />

                <Text className='LinkerCarousel-Card-Content-Link-Text' field={exploreMoreLabel} tag='div' />
              </div>
            </RoutableSitecoreLink>
          </div>
        );
      }
      return null;
    });
  }

  getCarouselOrCards(nrOfItemsToShow) {
    if (this.props.fields.LinkerCarouselElements.length > nrOfItemsToShow) {
      return (
        <CardsCarousel
          nrOfItemsToShow={nrOfItemsToShow}
          responsiveRule={{
            0: { items: 1 },
            760: { items: 2 },
            960: {
              items: this.maximumNumberOfCardsToDisplay
            }
          }}
        >
          {this.getCards()}
        </CardsCarousel>
      );
    }

    const cssClass = classNames({
      'LinkerCarousel-OneVisibleContainer': nrOfItemsToShow === 1,
      'LinkerCarousel-TwoVisibleContainer': nrOfItemsToShow === 2,
      'LinkerCarousel-ThreeVisibleContainer': nrOfItemsToShow === 3
    });
    return <div className={cssClass}>{this.getCards()}</div>;
  }

  getCardsBasedOnWindowWidth = () => {
    return (
      <React.Fragment>
        <Visible lg xl>
          {this.getCarouselOrCards(this.maximumNumberOfCardsToDisplay)}
        </Visible>
        <Visible md>{this.getCarouselOrCards(2)}</Visible>
        <Visible xs sm>
          {this.getCarouselOrCards(1)}
        </Visible>
      </React.Fragment>
    );
  };

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }

    const { Title, Subtitle, BackgroundColor, Description } = this.props.fields;

    const inlineStyle = {
        backgroundColor:
          BackgroundColor &&
          BackgroundColor.fields &&
          BackgroundColor.fields.ColorCode &&
          BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      isBackgroundBlack = BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000',
      cssClass = classNames('LinkerCarousel carouselComponentContainer click_content_block_content_page_linker', {
        WhiteColorStyle: isBackgroundBlack
      });

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className={cssClass}>
          <HeadlineWithTitleAndSubtitle
            isTextWhite={isBackgroundBlack}
            extraClasses='LinkerCarousel-Headline'
            title={Title}
            subtitle={Subtitle}
          />
          <RichText field={Description} className='LinkerCarousel-Description' />
          {this.getCardsBasedOnWindowWidth()}
        </div>
      </div>
    );
  }
}

const hasItems = function (props) {
  const { fields } = props;
  if (!fields || !fields.LinkerCarouselElements || fields.LinkerCarouselElements.length < 1) {
    return false;
  }
  return true;
};

export default WithStickyMenu(LinkerCarouselComponent, hasItems);
