import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

/**
 * Simple component that renders the title and subtitle from sitecore in one line on big screen
 */
class HeadlineWithTitleAndSubtitle extends React.Component {
  getSubtitle(subtitle) {
    if (subtitle && subtitle.value) {
      return <Text tag='div' className='HeadlineWithTitleAndSubtitle-Subtitle' field={subtitle} />;
    } else if (typeof subtitle === 'string') {
      return <div className='HeadlineWithTitleAndSubtitle-Subtitle'>{subtitle}</div>;
    }
  }
  render() {
    const { title, subtitle, isTextWhite, extraClasses } = this.props;
    if ((!title && !subtitle) || (title && title.value === '' && subtitle && subtitle.value === '')) {
      return <div className='whiteBackground' />;
    }
    const cssClass = classNames('HeadlineWithTitleAndSubtitle', {
      [`${extraClasses}`]: extraClasses,
      'HeadlineWithTitleAndSubtitle-WhiteText': isTextWhite
    });
    return (
      <React.Fragment>
        <div className={cssClass}>
          <Text tag='h2' className='HeadlineWithTitleAndSubtitle-Title' field={title} />
          {this.getSubtitle(subtitle)}
        </div>
      </React.Fragment>
    );
  }
}

export default HeadlineWithTitleAndSubtitle;
