import React from 'react';
import classNames from 'classnames';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import CloseIcon from '../../../../../Common/Icons/CloseIcon';

class OverlayFindSerialAndArticle extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./OverlayFindSerialAndArticle.scss');
  }
  render() {
    const { isOverlay, onClose, fields } = this.props;
    const cssClass = classNames('OverlayFindSerialAndArticle', {
        'OverlayFindSerialAndArticle-Active': isOverlay
      }),
      { whereFindSerialNumberTitle, whereFindSerialNumberDescription, whereFindSerialNumberImage } = fields,
      img = whereFindSerialNumberImage && whereFindSerialNumberImage.jss;
    return (
      <div className={cssClass}>
        <div className='OverlayFindSerialAndArticle-DetailsContainer'>
          <div
            className='OverlayFindSerialAndArticle-Close'
            onClick={onClose}
            onKeyDown={e => {
              if (e.target === 13) onClose();
            }}
            role='button'
            tabIndex='0'
            aria-label='Close Overlay'
          >
            <CloseIcon />
          </div>
          <Text field={whereFindSerialNumberTitle} tag='div' className='OverlayFindSerialAndArticle-Title' />
          <Text
            field={whereFindSerialNumberDescription}
            tag='div'
            className='OverlayFindSerialAndArticle-Description'
          />
          <div className='OverlayFindSerialAndArticle-Image'>
            <Image field={img} />
          </div>
        </div>
      </div>
    );
  }
}

export default OverlayFindSerialAndArticle;
