import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import AllDownloadItem from '../AllDownloadItem/index';
import ArrowDown from '../../../Common/Icons/ArrowDown';
import i18n from 'i18next';
import { DownloadItem, DownloadsList } from '../types';
import { useDesktopDevice } from '../../../../hooks/useDevice';

const numberToIncrementWith = 5;

type ProductCategoryItemsProps = {
  downloadList: DownloadsList;
  category: string;
  toggleItemInBulkDownload: (url: string, checked: boolean) => void;
  bulkDownloadItems?: DownloadItem[];
  currentLanguage: string | null;
};

const ProductCategoryItems: React.FC<ProductCategoryItemsProps> = ({
  downloadList,
  category,
  toggleItemInBulkDownload,
  bulkDownloadItems,
  currentLanguage
}) => {
  const AllLabel = i18n.t('PRODUCT_DOWNLOADS | All');
  const [itemsToShow, setItemsToShow] = useState(5);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const isBulkDownloadAllowed = useDesktopDevice();

  useEffect(() => {
    const itemsToDisplay = downloadList?.filteredItems ?? [];
    const filteredItems = itemsToDisplay.filter(item => item.category === category);

    setSelectAllChecked(filteredItems.every(item => bulkDownloadItems?.some(bulkItem => bulkItem.url === item.url)));
  }, [downloadList, category, bulkDownloadItems, currentLanguage]);

  const getDownloadItems = (filteredItems: DownloadItem[]) => {
    const ItemRelationLabel = { value: i18n.t('PRODUCT_DOWNLOADS | Item Relation') };

    return filteredItems
      .slice(0, itemsToShow)
      .map((downloadItem, index) => (
        <AllDownloadItem
          key={`${downloadItem.title}-${index}`}
          downloadItem={downloadItem}
          ItemRelationLabel={ItemRelationLabel}
          toggleItemInBulkDownload={toggleItemInBulkDownload}
          initialChecked={bulkDownloadItems?.some(item => item.url === downloadItem.url)}
        />
      ));
  };

  /**
   * @method incrementItemsToShow
   * @description increment multiplier from old state whith five
   */
  const incrementItemsToShow = () => {
    setItemsToShow(itemsToShow => itemsToShow + numberToIncrementWith);
  };

  const getShowMoreButton = (filteredItems: DownloadItem[]) => {
    const ShowLabel = i18n.t('PRODUCT_DOWNLOADS | Show');
    const MoreLabel = i18n.t('PRODUCT_DOWNLOADS | More');
    const lengthMinusCurentItems = filteredItems.length - itemsToShow;

    if (lengthMinusCurentItems < 1) return;
    return (
      <div
        className='ProductCategoryItems-ShowMore'
        role='button'
        tabIndex={0}
        onClick={incrementItemsToShow}
        onKeyUp={e => {
          if (e.key === '13') {
            incrementItemsToShow();
          }
        }}
      >
        <ArrowDown />
        <Text field={{ value: ShowLabel }} />
        &nbsp;
        {lengthMinusCurentItems < numberToIncrementWith ? lengthMinusCurentItems : numberToIncrementWith}
        &nbsp;
        <Text field={{ value: MoreLabel }} />
      </div>
    );
  };

  const toggleItemsInCategory = (checked: boolean) => {
    downloadList?.filteredItems
      ?.filter(
        item =>
          item.category === category &&
          (item.isVisibleForAllLanguages ||
            currentLanguage === AllLabel ||
            (item.language && currentLanguage && item.language.includes(currentLanguage)))
      )
      .forEach(item => {
        toggleItemInBulkDownload(item.url, checked);
      });
  };

  const itemsToDisplay = downloadList?.filteredItems ?? [];
  const filteredItems = itemsToDisplay.filter(item => item.category === category);
  const filteredItemsLength = filteredItems.length;

  if (filteredItemsLength < 1) {
    return null;
  }

  return (
    <div className='ProductCategoryItems'>
      <label
        className={isBulkDownloadAllowed ? 'ProductCategoryItems-Label--Select-All' : 'ProductCategoryItems-Label'}
      >
        <h3 className='ProductCategoryItems-Title'>
          {isBulkDownloadAllowed && (
            <>
              <input
                type='checkbox'
                onChange={event => {
                  setSelectAllChecked(event.target.checked);
                  toggleItemsInCategory(event.target.checked);
                }}
                checked={selectAllChecked}
              />
              <span className='checkmark'></span>
            </>
          )}
          {category} <span>({filteredItemsLength})</span>
        </h3>
      </label>
      {getDownloadItems(filteredItems)}
      {getShowMoreButton(filteredItems)}
    </div>
  );
};

export default ProductCategoryItems;
