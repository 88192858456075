import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsInfraredHeatersPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsInfraredHeatersSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Infrared-Heaters',
  LeisterGroup: 'Infrared-Heaters',
  LeisterTechnologies: 'Infrared-Heaters',
  Weldy: 'Infrared-Heaters'
};

export const InfraredHeatersFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications'),
    multi: false
  },
  {
    field: 'pimpowerma',
    label: () => i18n.t('SEARCH | Facets pimpowerma'),
    numeric: true,
    unit: 'W'
  },
  {
    field: 'pimlength',
    label: () => i18n.t('SEARCH | Facets pimlength'),
    numeric: true,
    unit: 'mm'
  },
  {
    field: 'pimwidth',
    label: () => i18n.t('SEARCH | Facets pimwidth'),
    numeric: true,
    unit: 'mm'
  }
];
