import React from 'react';
import i18n from 'i18next';
import GreenCircleCheckIconSmall from '../../../Common/Icons/GreenCircleCheckIconSmall';
import QuestionCircle from '../../../Common/Icons/QuestionCircle';
import WarningCircle from '../../../Common/Icons/WarningCircle';
import { ShopProduct } from '../../lib/shopify/types';

const limitedStockThreshold = 5;

interface Props {
  product: ShopProduct;
}

const StockInformation: React.FC<Props> = ({ product }): JSX.Element => {
  let content = null;
  const variant = product.variants[0];

  const limitedStockWarning =
    variant?.quantityAvailable && variant.quantityAvailable <= limitedStockThreshold ? (
      <span className='StockInformation__LimitedStockWarning'>
        {i18n.t('SHOP | Limited stock warning', { counter: variant.quantityAvailable })}
      </span>
    ) : null;

  if (variant?.currentlyNotInStock && variant?.availableForSale) {
    content = (
      <div className='StockInformation StockInformation--CurrentlyNotInStock'>
        <WarningCircle />
        <span className='StockInformation__Label'>{i18n.t('SHOP | Currently not in stock')}</span>
      </div>
    );
  } else if (variant?.availableForSale) {
    content = (
      <div className='StockInformation StockInformation--InStock'>
        <GreenCircleCheckIconSmall />
        <span className='StockInformation__Label'>{i18n.t('SHOP | In stock')}</span>
        {limitedStockWarning}
      </div>
    );
  } else {
    content = (
      <div className='StockInformation StockInformation--NotInStock'>
        <QuestionCircle color='black-30' />
        <span className='StockInformation__Label'>{i18n.t('SHOP | Not In stock')}</span>
      </div>
    );
  }

  return content;
};

export default StockInformation;
