import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useScrollDirection } from '../../../utils/customHooks';

import ArrowUp from '../Icons/ArrowUp';
import ChevronDown from '../../Common/Icons/ChevronDown';
import { removeWhiteSpaces } from '../../../utils/Utils';

const widthBreakpointForShowingOnlyLinksInDropdown = 640;

const StickyMenu = props => {
  const { fields, stickyMenuData, sitecoreContext, title } = props;
  const { 'Menu Title': MenuTitle, More, Top } = fields;

  const { width } = useWindowSize();

  // will contain all the links
  const [listOfLinks, setListOfLinks] = useState([]);

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  // list of links in the dropdown
  const [listOfDropdownLinks, setListOfDropdownLinks] = useState([]);

  const refMenuContainer = useRef(null);

  const scrollDirection = useScrollDirection();
  //
  //
  // event handlers

  // smooth scrolling to the top of the page
  const handleOnClickScrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0
    });
  };
  const handleOnKeyDown = e => {
    if (e.key === 'Enter') handleOnClickScrollToTop();
  };

  const handleOnClickHideSubmenuItems = () => {
    setIsDropdownMenuOpen(false);
  };

  const handleOnClickToggleSubmenuItems = () => {
    setIsDropdownMenuOpen(!isDropdownMenuOpen);
  };

  const handleOnKeyPressToggleSubmenuItems = e => {
    if (e.key === 'Enter') {
      setIsDropdownMenuOpen(!isDropdownMenuOpen);
    }
  };

  //
  //
  // hooks
  useEffect(() => {
    document.documentElement.classList.add('smoothScroll');

    return () => {
      document.documentElement.classList.remove('smoothScroll');
    };
  }, []);

  useEffect(() => {
    // when the component received new data, store the links for future use
    const rawData = sitecoreContext?.route?.placeholders?.['jss-main'];

    // if no data, exit
    if (!rawData) {
      return null;
    }

    // extract the list of items and store it for rendering
    const newListOfLinks = [];
    rawData.forEach((x, i) => {
      const item = x?.fields?.['Sticky Menu Title'];
      const itemName = item?.value;
      const { 'Sticky Menu Title': stickyMenuTitle } = x?.fields || {};
      const titleValue = stickyMenuTitle?.value || '';

      if (itemName && stickyMenuData.includes(itemName)) {
        newListOfLinks.push({
          key: i,
          hrefId: removeWhiteSpaces(titleValue),
          menuTitle: stickyMenuTitle,
          title: titleValue
        });
      }
    });

    setListOfLinks(newListOfLinks);
  }, [sitecoreContext.route.placeholders, stickyMenuData]);

  useLayoutEffect(() => {
    let newListOfDropdownLinks = [];

    const childrenArrayEls = refMenuContainer?.current?.children;
    const childrenTotalNumber = childrenArrayEls?.length;

    // if no children, exit
    if (!childrenTotalNumber) {
      return () => undefined;
    }
    // store the "y" position of the first element
    // all the elements that have a different 'y' position are on a different row,
    let firstRowYPosition = childrenArrayEls[0].getBoundingClientRect().y;

    // show links in the row as many as possible, and move the rest in the dropdown
    // but for smaller screens, move all the links in the dropdown
    if (widthBreakpointForShowingOnlyLinksInDropdown < width) {
      for (let i = 0; i < childrenTotalNumber; i++) {
        const isElementOnTheFirstRow = childrenArrayEls[i].getBoundingClientRect().y === firstRowYPosition;

        childrenArrayEls[i].classList[isElementOnTheFirstRow ? 'remove' : 'add']('hidden');
        if (!isElementOnTheFirstRow) {
          newListOfDropdownLinks.push(listOfLinks[i]);
        }
      }
    } else {
      newListOfDropdownLinks = [...listOfLinks];
    }

    setListOfDropdownLinks(newListOfDropdownLinks);
  }, [isDropdownMenuOpen, listOfLinks, refMenuContainer?.current, width]);

  //
  //
  // renderings

  // if there are no items to render, exit here
  if (!stickyMenuData?.length) {
    return <div className='zIndexWithRelativePosition click_sticky_menu_navigation' />;
  }

  const renderScrollTopButton = (
    <div
      className='StickyMenu-Wrapper-Top'
      onClick={handleOnClickScrollToTop}
      onKeyDown={handleOnKeyDown}
      role='button'
      tabIndex='0'
    >
      <Text tag='div' className='StickyMenu-Wrapper-Top-Label' field={Top} />
      <ArrowUp />
    </div>
  );

  const renderTitle = MenuTitle ? <Text field={MenuTitle} /> : title;

  const renderItems = listOfLinks?.map((x, i) => {
    return (
      <a href={`#${removeWhiteSpaces(x.title)}`} key={x.key} title={x.title}>
        <Text field={x.menuTitle} />
      </a>
    );
  });

  const renderSubmenuItems = listOfDropdownLinks?.map((x, i) => {
    return (
      <a onClick={handleOnClickHideSubmenuItems} href={`#${removeWhiteSpaces(x.title)}`} key={x.key} title={x.title}>
        <Text field={x.menuTitle} />
      </a>
    );
  });

  const stickyDropdownMenuClassName = `StickyMenu-Wrapper-Items-Dropdown ${isDropdownMenuOpen ? 'Opened' : ''}`;
  const renderStickyDropdownMenu = renderSubmenuItems?.length > 0 && (
    <div className='StickyMenu-Wrapper-Items-Dropdown-Container'>
      <OutsideClickHandler onOutsideClick={handleOnClickHideSubmenuItems}>
        <div className={stickyDropdownMenuClassName}>
          <div
            className='StickyMenu-Wrapper-Items-Dropdown-Header'
            onClick={handleOnClickToggleSubmenuItems}
            onKeyDown={handleOnKeyPressToggleSubmenuItems}
            role='button'
            tabIndex='0'
          >
            <Text field={More} />
            <ChevronDown />
          </div>
          <div className={'StickyMenu-Wrapper-Items-Dropdown-Options'}>{renderSubmenuItems}</div>
        </div>
      </OutsideClickHandler>
    </div>
  );

  const isMenuContainerStickyClassName = scrollDirection === 'up' ? 'isStickyForMobile' : '';
  const containerCSSClassNames = `zIndexWithRelativePosition click_sticky_menu_navigation ${isMenuContainerStickyClassName}`;

  // for smaller size screens, add a class to the main row links 'isTransparent', to be hidden and moved off screens
  const classNameStickyMenuWrapperItems = `StickyMenu-Wrapper-Items ${
    widthBreakpointForShowingOnlyLinksInDropdown > width ? 'isTransparent' : ''
  }`;

  return (
    <div className={containerCSSClassNames}>
      <div className='StickyMenu'>
        <div className='StickyMenu-Lines'>
          <div className='StickyMenu-Wrapper'>
            <div className='StickyMenu-Wrapper-Title'>
              <div className='StickyMenu-Wrapper-Title-Text'>{renderTitle}</div>
            </div>
            <div className={classNameStickyMenuWrapperItems} ref={refMenuContainer}>
              {renderItems}
            </div>
            {renderStickyDropdownMenu}
            {renderScrollTopButton}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ productDetails, stickyMenu }) => {
  const { stickyMenuData } = stickyMenu;
  const { MasterProductName: title, SubCategory: subCategory } = productDetails?.masterProductData || {};

  return {
    title,
    stickyMenuData,
    subCategory
  };
};

export default connect(mapStateToProps)(withSitecoreContext()(StickyMenu));
