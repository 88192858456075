import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../../../lib/GraphQLData';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import StoriesPromotionBoxComponent from '../../StoriesPromotionBoxComponent';
import { StoriesQueryString } from '../StoriesPromotionBoxQuery';

const StoriesQuery = StoriesQueryString('lagUrl');

class LAGStoriesPromotionBox extends React.Component {
  render() {
    return <StoriesPromotionBoxComponent {...this.props} urlToUse='lagUrl' />;
  }
}

export default GraphQLData(StoriesQuery, {
  name: 'StoriesPromotionBox',
  options: props => ({
    variables: {
      dataSource: props.rendering.dataSource,
      lang: getLanguage(props.sitecoreContext) || 'en'
    }
  })
})(withSitecoreContext()(LAGStoriesPromotionBox));
