import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import classNames from 'classnames';
import { removeWhiteSpaces } from '../../../utils/Utils';
import GeneralLink from '../GeneralLink';
import HeadlineWithTitleAndSubtitle from '../HeadlineWithTitleAndSubtitle/index';

/**
 * CareerPromotionBox component with images and jobs available
 */
class CareerPromotionBox extends React.Component {
  getImages = career => {
    if (!career || !career.careertype) {
      return <React.Fragment />;
    }

    return career.careertype
      .map((item, index) => {
        const { careerItem, title, pageUrl, lagPageUrl, axagPageUrl } = item,
          urlItem = pageUrl ? pageUrl : lagPageUrl ? lagPageUrl : axagPageUrl || '',
          cssClass = classNames('CareerPromotionBox-Image', {
            'CareerPromotionBox-Hide': index > 0
          });
        if (!urlItem) {
          return null;
        }
        return (
          <Col sm={12} md={4} lg={4} xl={4} className={cssClass} key={index + title}>
            <Image className='CareerPromotionBox-Image-Item' field={careerItem.Image.jss} />
          </Col>
        );
      })
      .filter(item => item !== null);
  };

  getCareers = career => {
    if (!career || !career.careertype) {
      return <React.Fragment />;
    }
    return career.careertype
      .map((item, index) => {
        const { city, country, title, pageUrl, lagPageUrl, axagPageUrl, workingtime, gtm } = item,
          slash = workingtime ? ' / ' : '',
          comma = city && country ? ', ' : '',
          urlItem = pageUrl ? pageUrl : lagPageUrl ? lagPageUrl : axagPageUrl || '';

        if (!urlItem) {
          return null;
        }

        return (
          <Col xl={12} className='CareerPromotionBox-Careers-Wrapper-Item' key={index + title}>
            <GeneralLink
              fields={{ value: { href: urlItem } }}
              className='CareerPromotionBox-Careers-Wrapper-Item-Link'
              clickClassName={removeWhiteSpaces(gtm || null) + ' click_content_block_promotion_news_careers'}
            >
              <div>
                <Text className='CareerPromotionBox-Careers-Wrapper-Item-Title' tag='span' field={{ value: city }} />
                <span className='CareerPromotionBox-Careers-Wrapper-Item-Separator'>{comma}</span>
                <Text className='CareerPromotionBox-Careers-Wrapper-Item-Title' tag='span' field={{ value: country }} />
              </div>
              <div className='CareerPromotionBox-Careers-Wrapper-Item-JobDescription'>
                <Text tag='span' field={{ value: title }} />
                <span>{slash}</span>
                <Text tag='span' field={{ value: workingtime }} />
              </div>
            </GeneralLink>
          </Col>
        );
      })
      .filter(item => item !== null);
  };

  render() {
    const propsFields = this.props.fields;
    if (!propsFields || !propsFields.data || !propsFields.data.career || !propsFields.data.item) {
      return <div className='CareerPromotionBox' />;
    }
    const { BackgroundColor, BoldTitle, StandardTitle, Button, ButtonText, Gtm } = propsFields.data.item;

    let colorOfBackground = null;

    if (BackgroundColor?.jss?.fields?.ColorCode?.value && BackgroundColor?.jss?.fields?.ColorCode?.value !== '') {
      colorOfBackground = BackgroundColor.jss.fields.ColorCode.value;
    }

    const inlineStyle = {
        backgroundColor: colorOfBackground
      },
      isHeadlineWhite = colorOfBackground === '#000';

    return (
      <div style={inlineStyle} className='CareerPromotionBox click_content_block_promotion_news_careers'>
        <HeadlineWithTitleAndSubtitle
          extraClasses='componentContainer CareerPromotionBox-HeadlinePadding'
          isTextWhite={isHeadlineWhite}
          title={BoldTitle}
          subtitle={StandardTitle}
        />

        <Visible lg xl>
          <Row nogutter className='CareerPromotionBox-Careers-Images componentContainer'>
            {this.getImages(propsFields.data.career)}
          </Row>
        </Visible>
        <Row nogutter className='CareerPromotionBox-Careers-Wrapper componentContainer'>
          {this.getCareers(propsFields.data.career)}
        </Row>
        <div className='CareerPromotionBox-Careers-ButtonWrapper componentContainer'>
          <GeneralLink
            className='CareerPromotionBox-Careers-ButtonWrapper-ButtonLink'
            fields={Button.jss}
            clickClassName={
              removeWhiteSpaces((Gtm && Gtm.value) || null) + ' click_content_block_promotion_news_careers'
            }
          >
            <Text field={ButtonText} />
          </GeneralLink>
        </div>
      </div>
    );
  }
}

export default CareerPromotionBox;
