import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

class FormWrapper extends React.Component {
  render() {
    return (
      <div className='FormWrapper componentContainer'>
        <Placeholder name='jss-single-form-wrapper' rendering={this.props.rendering} />
      </div>
    );
  }
}

export default FormWrapper;
