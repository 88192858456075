import React, { useContext } from 'react';
import i18n from 'i18next';
import FilterIcon from '../../Common/Icons/Filter';
import { ActionType, SearchContext } from '../context/SearchContext';
import classNames from 'classnames';
import { useViewportSize } from '../../../hooks/useViewportSize';
import FacetBreadcrumbs from '../FacetBreadcrumbs';
import { BreadcrumbManager } from '@coveo/headless';
import ChevronUpSmall from '../../Common/Icons/ChevronUpSmall';
import ChevronDownSmall from '../../Common/Icons/ChevronDownSmall';

type FacetsModalProps = {
  hideOnDesktop?: boolean;
  breadcrumbManagerController?: BreadcrumbManager;
  foldable?: boolean;
};

export const FacetsModal: React.FC<FacetsModalProps> = ({
  foldable = true,
  hideOnDesktop = false,
  breadcrumbManagerController
}) => {
  const { state: searchState, dispatch } = useContext(SearchContext);
  const { viewportIsMobile } = useViewportSize();
  const labelText = i18n.t('SEARCH | Facets Mobile Label');
  const numberOfSelectedFilters = searchState.selectedFacets
    ? Object.values(searchState.selectedFacets).reduce((acc, curr) => acc + curr.length, 0)
    : 0;
  const label = numberOfSelectedFilters > 0 ? `${labelText} (${numberOfSelectedFilters})` : labelText;

  const toggleFacets = () => {
    dispatch({
      type: !viewportIsMobile ? ActionType.TOGGLE_FACETS : ActionType.OPEN_FACETS
    });
  };

  return (
    <div
      className={classNames('FacetsModal__Container', {
        'FacetsModal__Container--hide-on-desktop': hideOnDesktop,
        'FacetsModal__Container--foldable': foldable
      })}
    >
      <div className='FacetsModal'>
        <button className='FacetsModal__Action' onClick={toggleFacets}>
          <FilterIcon />
          <span className='FacetsModal__Action-Title'>{label}</span>
          <span className='FacetsModal__Action-Chevron'>
            {searchState.isFacetsModalOpen ? <ChevronUpSmall /> : <ChevronDownSmall />}
          </span>
        </button>
        {breadcrumbManagerController && <FacetBreadcrumbs controller={breadcrumbManagerController} />}
      </div>
    </div>
  );
};

export default FacetsModal;
