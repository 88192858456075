import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Visible } from 'react-grid-system';
import { connect } from 'react-redux';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import { changeOpenAccordionAction } from '../../../store/mobileMenu/actions';
import Accordion from '../Accordion/index';
import { normalizeSitecoreId } from '../../../utils/Utils';

/**
 * @class Component that renders the tabs used in the main navigation menu.
 *
 * @param {object} fields Object containing the data (links) needed for the component.
 *
 * Returns HTML.
 */
class MenuTabs extends React.Component {
  getTabsContent() {
    if (this.props.rendering.fields.TabsItems && this.props.rendering.fields.TabsItems.length > 0) {
      let activeCompany = '';
      let activeCompanyId = '';
      const tabsContent = this.props.rendering.fields.TabsItems.map((item, index) => {
        let itemClass = 'MenuTabs-tab';
        const urlString = item.fields.Link.value.href;

        if (urlString && urlString.indexOf('http') < 0) {
          itemClass = 'MenuTabs-tab active';
          activeCompany = item.fields.Title.value;
          activeCompanyId = normalizeSitecoreId(item.id);
        }

        return (
          <div key={index + item.id} className={itemClass}>
            <RoutableSitecoreLink link={item.fields.Link.value}>{item.fields.Title.value}</RoutableSitecoreLink>
          </div>
        );
      });
      const returnedObject = {
        tabsContent: tabsContent,
        activeCompany: activeCompany,
        activeCompanyId: activeCompanyId
      };
      return returnedObject;
    }
    return null;
  }

  /**
   * @method getHeaderAccordionText
   * @description render the sites header from accordion on mobile menu
   * @param {string} activeCompany
   */
  getHeaderAccordionText(activeCompany) {
    return (
      <React.Fragment>
        <div className='Accordion-Header-Label'>
          <Text field={this.props.rendering.fields['Mobile Company Label']} />
        </div>
        <div className='Accordion-Header-Company'>{activeCompany}</div>
      </React.Fragment>
    );
  }

  render() {
    const tabsFunctionResult = this.getTabsContent();
    if (!tabsFunctionResult) {
      return <div className='MenuTabs' />;
    }
    const tabsContent = tabsFunctionResult.tabsContent,
      activeCompany = tabsFunctionResult.activeCompany,
      activeCompanyId = tabsFunctionResult.activeCompanyId;

    return (
      <React.Fragment>
        <div className='MenuTabs'>
          <Visible xs sm md>
            <Accordion
              accordionClass='Accordion'
              headerClicked={() => this.props.changeOpenAccordion(activeCompanyId)}
              openAccordion={this.props.openAccordion}
              accordionId={activeCompanyId}
              headerContent={this.getHeaderAccordionText(activeCompany)}
              accordionBody={tabsContent}
            />
          </Visible>

          <Visible lg xl>
            {tabsContent}
          </Visible>
        </div>
      </React.Fragment>
    );
  }
}
const MapStateToProps = state => {
  return {
    openAccordion: state.mobileMenu.openAccordion
  };
};
const MapDispatchToProps = dispatch => {
  return {
    changeOpenAccordion: activeCompany => dispatch(changeOpenAccordionAction(activeCompany))
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(MenuTabs);
