import React from 'react';
import { LinkButtonProps } from './types';
import classNames from 'classnames';
import ArrowRight from '../Icons/ArrowRight';

const LinkButton = ({
  title,
  className,
  beforeIcon,
  withArrow = true,
  onClick,
  size = 'regular'
}: LinkButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button
    className={classNames(
      'link-button',
      {
        'link-button--small': size === 'small'
      },
      className
    )}
    type='button'
    onClick={onClick}
  >
    {beforeIcon ? <span className='link-button__before-icon'>{beforeIcon}</span> : null}
    {title && <span>{title}</span>}
    {withArrow && <ArrowRight />}
  </button>
);

export default LinkButton;
