import { KnownSiteNames } from '../../../Constants/KnownSiteNames';

export const LANG = 'lang';
export const ONE_YEAR = 360;
export const DEFAULT_COUNTRY_CODE = 'CH';
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_COUNTRY = 'Switzerland';
export const DEFAULT_REGION = 'Europe';
export const COUNTRY = 'country';
export const COUNTRY_CODE = 'country_code';
export const REGION = 'region';
export const WISHLIST = 'wishlist';
export const COMPARE = 'compare';
export const SHOPIFY_CART = 'cartId';
export const SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME = 'shopifyTestAccessKey';
export const SITE_NAME = 'siteName';

export const DEFAULT_COOKIES_DATA = {
  lang: DEFAULT_LANGUAGE,
  country: DEFAULT_COUNTRY,
  country_code: DEFAULT_COUNTRY_CODE,
  region: DEFAULT_REGION,
  compare: '{}'
};

const getCookieNamesForLangOnAllTenants = () => {
  const cookieNames = [];
  Object.keys(KnownSiteNames).forEach(tenant => {
    cookieNames.push(`${tenant.toLowerCase()}#${LANG}`);
  });
  return cookieNames;
};

export const COOKIE_NECESSARY = {
  necessary: [
    LANG,
    COUNTRY,
    COUNTRY_CODE,
    REGION,
    'redirectURL',
    COMPARE,
    WISHLIST,
    SHOPIFY_CART,
    SITE_NAME,
    ...getCookieNamesForLangOnAllTenants()
  ]
};

export const DEFAULT_DATA = {
  country: {
    RegionCode: {
      value: 'CH'
    },
    RegionName: {
      value: 'Switzerland'
    },
    DefaultLanguage: {
      id: 'af584191-45c9-4201-8740-5409f4cf8bdd',
      url: '/sitecore/system/Languages/en',
      fields: {
        Charset: {
          value: ''
        },
        'Code page': {
          value: ''
        },
        Dictionary: {
          value: 'en-US.tdf'
        },
        Encoding: {
          value: ''
        },
        'Fallback Language': {
          value: ''
        },
        Iso: {
          value: 'en'
        },
        'Regional Iso Code': {
          value: ''
        },
        'WorldLingo Language Identifier': {
          value: ''
        },
        LanguageName: {
          value: ''
        }
      }
    },
    Languages: [
      {
        id: 'af584191-45c9-4201-8740-5409f4cf8bdd',
        fields: {
          Charset: {
            value: ''
          },
          'Code page': {
            value: ''
          },
          Dictionary: {
            value: 'en-US.tdf'
          },
          Encoding: {
            value: ''
          },
          'Fallback Language': {
            value: ''
          },
          Iso: {
            value: 'en'
          },
          'Regional Iso Code': {
            value: ''
          },
          'WorldLingo Language Identifier': {
            value: ''
          },
          LanguageName: {
            value: ''
          }
        }
      }
    ]
  },
  getdefaultLanguage: 'en',
  languages: [
    {
      id: 'af584191-45c9-4201-8740-5409f4cf8bdd',
      fields: {
        Charset: {
          value: ''
        },
        'Code page': {
          value: ''
        },
        Dictionary: {
          value: 'en-US.tdf'
        },
        Encoding: {
          value: ''
        },
        'Fallback Language': {
          value: ''
        },
        Iso: {
          value: 'en'
        },
        'Regional Iso Code': {
          value: ''
        },
        'WorldLingo Language Identifier': {
          value: ''
        },
        LanguageName: {
          value: ''
        }
      }
    }
  ],
  region: {
    'Available Countries': [
      {
        id: 'f891b1d8-1410-43cb-9f9c-20f8d772ce69',
        fields: {
          RegionCode: {
            value: 'CH'
          },
          RegionName: {
            value: 'Switzerland'
          },
          DefaultLanguage: {
            id: '0c5b57b6-73e0-430a-a1b4-ad5ea49ccd21',
            url: '/sitecore/system/Languages/de',
            fields: {
              Charset: {
                value: ''
              },
              'Code page': {
                value: ''
              },
              Dictionary: {
                value: 'de-DE.tdf'
              },
              Encoding: {
                value: 'utf-8'
              },
              'Fallback Language': {
                value: 'en'
              },
              Iso: {
                value: 'de'
              },
              'Regional Iso Code': {
                value: 'de'
              },
              'WorldLingo Language Identifier': {
                value: ''
              },
              LanguageName: {
                value: 'Deutsch'
              }
            }
          },
          Languages: [
            {
              id: 'af584191-45c9-4201-8740-5409f4cf8bdd',
              fields: {
                Charset: {
                  value: ''
                },
                'Code page': {
                  value: ''
                },
                Dictionary: {
                  value: 'en-US.tdf'
                },
                Encoding: {
                  value: ''
                },
                'Fallback Language': {
                  value: ''
                },
                Iso: {
                  value: 'en'
                },
                'Regional Iso Code': {
                  value: ''
                },
                'WorldLingo Language Identifier': {
                  value: ''
                },
                LanguageName: {
                  value: ''
                }
              }
            },
            {
              id: '0c5b57b6-73e0-430a-a1b4-ad5ea49ccd21',
              fields: {
                Charset: {
                  value: ''
                },
                'Code page': {
                  value: ''
                },
                Dictionary: {
                  value: 'de-DE.tdf'
                },
                Encoding: {
                  value: 'utf-8'
                },
                'Fallback Language': {
                  value: 'en'
                },
                Iso: {
                  value: 'de'
                },
                'Regional Iso Code': {
                  value: 'de'
                },
                'WorldLingo Language Identifier': {
                  value: ''
                },
                LanguageName: {
                  value: 'Deutsch'
                }
              }
            },
            {
              id: '2fed6ba6-8832-474f-ad03-0bde9881c04c',
              fields: {
                Charset: {
                  value: 'iso-8859-1'
                },
                'Code page': {
                  value: '65001'
                },
                Dictionary: {
                  value: 'fr-FR.tdf'
                },
                Encoding: {
                  value: 'utf-8'
                },
                'Fallback Language': {
                  value: 'en'
                },
                Iso: {
                  value: 'fr'
                },
                'Regional Iso Code': {
                  value: 'fr'
                },
                'WorldLingo Language Identifier': {
                  value: ''
                },
                LanguageName: {
                  value: 'French'
                }
              }
            },
            {
              id: '9a7d772a-e667-49ce-8755-37a36dec01c7',
              fields: {
                Charset: {
                  value: 'iso-8859-1'
                },
                'Code page': {
                  value: '65001'
                },
                Dictionary: {
                  value: 'it-IT.tdf'
                },
                Encoding: {
                  value: 'utf-8'
                },
                'Fallback Language': {
                  value: 'en'
                },
                Iso: {
                  value: 'it'
                },
                'Regional Iso Code': {
                  value: 'it'
                },
                'WorldLingo Language Identifier': {
                  value: ''
                },
                LanguageName: {
                  value: 'Italian'
                }
              }
            }
          ]
        }
      }
    ],
    RegionCode: {
      value: 'EU'
    },
    RegionName: {
      value: 'Europe'
    },
    DefaultLanguage: {
      id: 'af584191-45c9-4201-8740-5409f4cf8bdd',
      url: '/sitecore/system/Languages/en',
      fields: {
        Charset: {
          value: ''
        },
        'Code page': {
          value: ''
        },
        Dictionary: {
          value: 'en-US.tdf'
        },
        Encoding: {
          value: ''
        },
        'Fallback Language': {
          value: ''
        },
        Iso: {
          value: 'en'
        },
        'Regional Iso Code': {
          value: ''
        },
        'WorldLingo Language Identifier': {
          value: ''
        },
        LanguageName: {
          value: ''
        }
      }
    },
    Languages: []
  }
};
