import { CoveoSitePipelinesType, CoveoSiteSearchHubsType } from '../../../CoveoSettings';

export const CoveoProductsWaferProcessingServicesPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsWaferProcessingServicesSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Wafer-Processing-Services',
  LeisterGroup: 'Wafer-Processing-Services',
  LeisterTechnologies: 'Wafer-Processing-Services',
  Weldy: 'Wafer-Processing-Services'
};
