import { RouteComponentProps } from 'react-router';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';
import {
  filterProductsByAvailableForSale,
  getShopProductsConditionNew,
  sortShopProductVariantsByPrice
} from '../../Shopify/lib/shopify/leisterHelpers';
import { ShopProduct } from '../../Shopify/lib/shopify/types';
import { ProductItem } from '../types';

function getProductWithLowestProductItemPimId(productItems: ProductItem[]): ProductItem {
  return productItems.reduce((prev: ProductItem, current: ProductItem) => (prev.id < current.id ? prev : current));
}

function navigateToProductDetailsPage(
  history: RouteComponentProps['history'],
  language: string,
  productTitle: string,
  articleNumber: string
): void {
  const masterProductPageUrl = `/${language}${PRODUCT_PAGE_ROUTE}/${productTitle.replace(/ /g, '-')}`;

  history.push(`${masterProductPageUrl}/${articleNumber.replace('.', '-')}`);
}

/**
 * Get the article number of the product item to redirect to.
 * This case is needed, if we want to redirect from product detail page to product item detail page.
 *
 * The logic is as follows:
 * 1. If there is no shop or no shop products, we navigate to the product item with the lowest PIM ID
 * 2. If there is a shop, we first accept products with condition "new"
 * 3. We filter out products that are not available for sale
 * 4. If there are products available for sale, we use those, otherwise we stay on the conditionNew products
 * 5. We sort the products by price
 * 6. If there is only one product variant, we navigate to it
 * 7. If there are multiple product variants, we navigate to the cheapest one
 * 8. If there are multiple product variants with the same price, we navigate to the one with the lowest PIM ID
 *
 * @param productItems
 * @param shopProducts
 * @returns
 */
// TODO: We should filter out phased out products from the productItems array
function getProductItemArticleNumberForProductPageRedirect(
  productItems: ProductItem[],
  shopProducts: ShopProduct[]
): string | undefined {
  const productItemWithLowestId = getProductWithLowestProductItemPimId(productItems);
  // TODO: We should filter out phased out products from the productItems array, but we don't have the info in there

  // If there is a shop, but no available products to sell, just search for product item with lowest id and navigate to it
  if (!shopProducts?.length) {
    if (productItemWithLowestId) {
      return productItemWithLowestId.articleNumber;
    }
  } else {
    // If there is a shop
    // We only accept products with condition "new"
    const shopProductsConditionNew = getShopProductsConditionNew(shopProducts);
    let shopProductsForRedirect = shopProductsConditionNew;

    // Filter out products that are not available for sale
    const shopProductsAvailableForSale = filterProductsByAvailableForSale(shopProductsConditionNew);

    // If there are products available for sale, use those, otherwise we stay on the conditionNew products
    if (shopProductsAvailableForSale.length) {
      shopProductsForRedirect = shopProductsAvailableForSale;
    }

    const shopProductVariants = shopProductsForRedirect.flatMap(product => product.variants);
    const sortedProductVariants = sortShopProductVariantsByPrice(shopProductVariants);
    // Depending on the amount of Variants and their prices, we need to consider different cases
    if (sortedProductVariants.length === 0) {
      // If there's no available product to sell, navigate to the lowest Pim ID product
      if (productItemWithLowestId) {
        return productItemWithLowestId.articleNumber;
      }
    } else if (
      // If its the only product variant
      sortedProductVariants.length === 1 ||
      // or it is cheaper than the next one, navigate to it (because it is the cheapest)
      sortedProductVariants[0].price.amount < sortedProductVariants[1].price.amount
    ) {
      return sortedProductVariants[0].sku ?? undefined;
    } else if (sortedProductVariants.length > 1) {
      // If there are multiple products with the same price, go to the product with the lower Pim ID
      const productVariantsWithTheSameLowestPrice = sortedProductVariants.filter(
        productVariant => productVariant.price.amount === sortedProductVariants[0].price.amount
      );
      const productItemsOfProductVariantsWithTheSameLowestPrice = productVariantsWithTheSameLowestPrice.flatMap(
        productVariant => productItems.filter(productItem => productItem.articleNumber === productVariant.sku)
      );

      // Get the lowest PIM ID from the products which share the same lowest price
      const productItemWithLowestProductItemPimId = getProductWithLowestProductItemPimId(
        productItemsOfProductVariantsWithTheSameLowestPrice
      );
      // Return its article number
      if (productItemWithLowestProductItemPimId) {
        return productItemWithLowestProductItemPimId.articleNumber;
      }
    }
  }
  return undefined;
}

export {
  getProductWithLowestProductItemPimId,
  navigateToProductDetailsPage,
  getProductItemArticleNumberForProductPageRedirect
};
