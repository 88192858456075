import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-grid-system';
import classNames from 'classnames';
import GeneralLink from '../../../Common/GeneralLink/index';
import { removeWhiteSpaces, getSmallestImageCrop } from '../../../../utils/Utils';

/**
 * @class Component that renders the layout (3 col title) for the flyout menu.
 *
 * @param {object} items Object containing the data (items) needed for the component.
 *
 * Returns HTML.
 */
class TwoSectionsLayout extends React.Component {
  /**
   * @method getImage
   * @description If the show image props is set then display the image from the sitecore otherwise display the default gray square
   * @param {object} item Object that contains or not the item image
   *
   * Returns the image or the default gray square in a jsx format.
   */
  getImage(item) {
    if (this.props.showImage) {
      const { Image: img } = item.fields;
      if (img && img.value.src && img.value.src !== '') {
        const smallestImg = getSmallestImageCrop(img.value.srcSet);
        return (
          <img
            src={smallestImg ? smallestImg : img.value.src + '?imwidth=100'}
            alt={img.value.alt ? img.value.alt : ''}
            loading='lazy'
            className='TwoSectionsLayout-Item-Sitecore-Image'
          />
        );
      }
      return <div className='TwoSectionsLayout-Item-Image' />;
    }
  }

  /**
   * @method getColData
   * @description Function that get items and put them in one column
   *
   * Returns HTML containing all of the above items.
   */
  getColData(itemData, rowNr, collNr) {
    if (itemData && itemData.fields && itemData.fields.NavigationElements) {
      const itemClass = classNames('TwoSectionsLayout-Item', {
        'TwoSectionsLayout-Item-NoImage': this.props.showImage === false
      });
      const items = itemData.fields.NavigationElements.slice(0, rowNr).map((item, index) => {
        const { 'ClickText GTM English': gtm } = item.fields,
          gtmText = gtm && gtm.value ? removeWhiteSpaces(gtm.value) : '';

        if (!item.fields.Link?.value?.href) {
          // do not render the link or the <Col /> if there is no "HREF" value
          // ex: on protected pages, the link data will be return, without HREF,
          // but the <Col /> will render with a min-height, which doesn't lok great in the design
          return null;
        }

        return (
          <Col key={item.fields.Title.value + index} className={itemClass} md={collNr} xl={collNr}>
            <GeneralLink fields={item.fields.Link} clickClassName={'main_menu_sub_level ' + gtmText}>
              {this.getImage(item)}
              <Text field={item.fields.Title} tag='div' className={'TwoSectionsLayout-Item-Title'} />
            </GeneralLink>
          </Col>
        );
      });

      return (
        <React.Fragment>
          <Row xl={rowNr} className='TwoSectionsLayout-Section-Row'>
            {items}
          </Row>
        </React.Fragment>
      );
    }

    return null;
  }

  render() {
    if (!this.props.items) {
      return null;
    }
    const firstCol = this.props.items[0] || null,
      secondCol = this.props.items[1] || null,
      firstColTitle = firstCol && firstCol.fields && firstCol.fields.HeadLine ? firstCol.fields.HeadLine : '',
      secondColTitle = secondCol && secondCol.fields && secondCol.fields.HeadLine ? secondCol.fields.HeadLine : '';

    return (
      <React.Fragment>
        <Row nogutter>
          <Col md={8} xl={8}>
            <Row nogutter>
              <Col xl={12}>
                <Text field={firstColTitle} className='TwoSectionsLayout-Section-Title' tag='span' />
              </Col>
            </Row>
            {this.getColData(firstCol, 8, 6)}
          </Col>
          <Col md={4} xl={4}>
            <Row nogutter>
              <Col xl={12}>
                <Text field={secondColTitle} className='TwoSectionsLayout-Section-Title' tag='span' />
              </Col>
            </Row>
            {this.getColData(secondCol, 4, 12)}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TwoSectionsLayout;
