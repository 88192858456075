import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Container } from 'react-grid-system';
import i18n from 'i18next';

type SearchResultHeadingProps = {
  query: string;
};

export const SearchResultHeading = ({ query }: SearchResultHeadingProps) => (
  <Container className='SearchResultHeading' style={{ paddingLeft: 0, paddingRight: 0 }}>
    <Row nogutter className='componentContainer'>
      <Col xs={12} md={12} lg={6} xl={6}>
        <React.Fragment>
          <Text
            field={{ value: i18n.t('SEARCH | Search Result Heading text') }}
            tag='h1'
            className='SearchResultHeading__Label'
          />
          <span className='SearchResultHeading__Query'>{query}</span>
        </React.Fragment>
      </Col>
    </Row>
  </Container>
);

export default SearchResultHeading;
