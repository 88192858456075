import React from 'react';
import { Form, FieldTypes } from '../SitecoreJSSReactForms';
import { FloatLabel } from './FloatLabel';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getSitecoreApiHost, getSitecoreApiKey } from '../../AppGlobals';

const LabelComponent = props => (
  <label className={props.field.model.labelCssClass} htmlFor={props.field.valueField.id}>
    {props.field.model.fieldTypeItemId === FieldTypes.Checkbox && (
      <input
        type='checkbox'
        value={'true'}
        name={props.field.model.name}
        className={props.field.model.cssClass}
        id={props.field.valueField.id}
        onChange={function (e) {
          return props.onChange(props.field.valueField.name, e.target.checked, true, []);
        }}
        onFocus={function () {
          return props.tracker.onFocusField(props.field, props.value);
        }}
        onBlur={function () {
          return props.tracker.onBlurField(props.field, props.value, props.errors);
        }}
      />
    )}

    <RichText
      field={{
        value: props.field.model.title + (props.field.model.required ? '&#42' : '')
      }}
    />
  </label>
);

const WrapperComponent = props =>
  props.field.model.cssClass === 'Form-Text' ? (
    <div className={props.field.model.cssClass}>
      <RichText field={{ value: props.field.model.text }} />
    </div>
  ) : (
    <div className={props.field.model.cssClass}>{props.children}</div>
  );

class GenericForm extends React.Component {
  componentDidMount() {
    this.checkIfStyleAdded();
  }
  componentDidUpdate() {
    this.checkIfStyleAdded();
  }

  checkIfStyleAdded() {
    //TODO change this teporary solution with a flag when we have data set it on false
    FloatLabel.init();
    const spanInput = document.querySelector('.SitecoreForm span.checkmark');
    if (!spanInput) {
      this.addStyleToCheckboxAndRadio();
    }
  }

  addStyleToCheckboxAndRadio = () => {
    const checkboxInputs = document.querySelectorAll(
      '.SitecoreForm input[type="checkbox"], .SitecoreForm input[type="radio"]'
    );

    [...checkboxInputs].forEach(element => {
      const checkmarkSpan = document.createElement('span');
      checkmarkSpan.classList.add('checkmark');
      element.insertAdjacentElement('afterend', checkmarkSpan);
    });
  };

  extractOptions = () => {
    if (this.props.fields) {
      return this.props.fields['Contact Form Item'].map(item => {
        return {
          title: item.fields.Title.value,
          form: item.fields.Form
        };
      });
    }
  };

  onFormSet = item => {
    this.setState({
      currentItem: item
    });
  };

  render() {
    return (
      <div className='SitecoreForm'>
        <Form
          form={this.props.fields}
          sitecoreApiHost=''
          sitecoreApiKey={getSitecoreApiKey()}
          labelComponent={LabelComponent}
          fieldWrapperComponent={WrapperComponent}
        />
      </div>
    );
  }
}

export default GenericForm;
