import React from 'react';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';

/**
 * @class Component that renders a link.
 *
 * @param {object} props Object containing the link data needed for the component.
 *
 * Returns jsx markup containing a link wrapped in a div container.
 */
const GeneralLink = props => {
  if (props.fields) {
    const linkFields =
      props.fields.value && props.fields.value.href ? props.fields : props.fields.Link ? props.fields.Link : null;

    if (linkFields !== null) {
      return (
        <div className={props.className ? 'GeneralLink ' + props.className : 'GeneralLink'} onClick={props?.onClick}>
          <RoutableSitecoreLink link={linkFields} id={props.id} className={props.clickClassName}>
            {props.children}
          </RoutableSitecoreLink>
        </div>
      );
    }

    return null;
  }

  return null;
};

export default GeneralLink;
