import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import CardsCarousel from '../../Common/CardsCarousel/CardsCarousel';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';
import CompatibleProductsCard from './CompatibleProductsCard/index';

class CompatibleProducts extends React.Component {
  getCards() {
    return this.props.compatibleProductsData.map((item, index) => (
      <CompatibleProductsCard fields={this.props.fields} key={index + item.Name} currentItem={item} />
    ));
  }
  getCarouselOrCards(nrOfItemsToShow) {
    if (this.props.compatibleProductsData.length > nrOfItemsToShow) {
      return <CardsCarousel nrOfItemsToShow={nrOfItemsToShow}>{this.getCards()}</CardsCarousel>;
    }

    const cssClass = classNames({
      'CompatibleProducts-MobileContainer': nrOfItemsToShow === 1,
      'CompatibleProducts-TabletContainer': nrOfItemsToShow === 2,
      'CompatibleProducts-DesktopContainer': nrOfItemsToShow === 3
    });

    return <div className={cssClass}>{this.getCards()}</div>;
  }

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }

    const { Title, Subtitle, BackgroundColor } = this.props.fields,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      isBackgroundBlack = BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000',
      cssClass = classNames(
        'CompatibleProducts carouselComponentContainer click_content_block_compatible_product_carousel',
        {
          WhiteColorStyle: isBackgroundBlack
        }
      );
    return (
      <div className='grayBackgroundImage' style={inlineStyle}>
        <div className={cssClass}>
          <HeadlineWithTitleAndSubtitle
            isTextWhite={isBackgroundBlack}
            extraClasses='CompatibleProducts-Headline'
            title={Title}
            subtitle={Subtitle}
          />
          <Visible lg xl>
            {this.getCarouselOrCards(3)}
          </Visible>
          <Visible md>{this.getCarouselOrCards(2)}</Visible>
          <Visible xs sm>
            {this.getCarouselOrCards(1)}
          </Visible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    compatibleProductsData: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.CompatibleProducts
      : null
  };
};

const hasItems = function (props) {
  const { compatibleProductsData } = props;
  if (!compatibleProductsData || !compatibleProductsData.length) {
    return false;
  }
  return true;
};

export default connect(mapStateToProps)(WithStickyMenu(CompatibleProducts, hasItems));
