import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class Component that renders a label in the Footer component.
 *
 * @param {object} props Object that contains the data for a label.
 *
 * @returns Returns HTML.
 */
const FooterLabel = props => (
  <div className='FooterLabel'>
    <Text field={props.fields.Slogan} />
  </div>
);

export default FooterLabel;
