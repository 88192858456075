import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

/**
 * @class Component that renders a text.
 *
 * @param {object} props Object containing the data (text) needed for the component.
 *
 * Returns jsx markup containing a RichText component wrapped in a div.
 */
const RichTextTeaser = props => {
  if (!props.fields) {
    return <div className='RichTextTeaser' />;
  }
  const propValue = props.fields,
    backgroundColorProp =
      propValue.BackgroundColor && propValue.BackgroundColor.fields.ColorCode.value !== ''
        ? propValue.BackgroundColor.fields.ColorCode.value
        : null,
    inlineStyle = {
      backgroundColor: backgroundColorProp
    },
    cssClass = classNames('RichTextTeaser componentContainer', {
      WhiteColorStyle: propValue.BackgroundColor && propValue.BackgroundColor.fields.ColorCode.value === '#000'
    });

  return (
    <div className='whiteBackground' style={inlineStyle}>
      <div className={cssClass}>
        <RichText className='RichTextTeaser-Text' field={propValue.Text} />
      </div>
    </div>
  );
};

export default RichTextTeaser;
