import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../../../Common/CookieSettings/CookieConstants';
import { Cart } from '../types';
import { getCartCurrency, getProductById, getVariantById, priceToNumber } from './ecomAnalyticsCommon';

const getViewCartEvent = (cart: Cart) => {
  return {
    event: 'view_cart',
    ecommerce: {
      currency: getCartCurrency(cart),
      value: priceToNumber(cart.cost.subtotalAmount.amount ?? '0.0'),
      affiliation: Cookies.get(COUNTRY_CODE)?.toLowerCase() ?? 'de',
      items: cart.lines.map(line => {
        const product = getProductById(cart, line.merchandise.id);

        if (!product) {
          return null;
        }

        const variant = getVariantById(product, line.merchandise.id);

        if (!variant) {
          return null;
        }

        return {
          // required fields
          item_id: line.merchandise.id,

          // recommended fields
          item_name: line._leisterProductName?.value ?? null,
          item_brand: line._leisterProductBrand?.value ?? null,
          item_category: line._leisterProductCategory?.value ?? null,
          item_category2: line._leisterProductSubcategory?.value ?? null,
          price: priceToNumber(variant.price.amount),
          quantity: line.quantity,

          // custom fields (up to 27)
          sku: variant.sku,
          compareAtPrice: variant.compareAtPrice?.amount ? priceToNumber(variant.compareAtPrice?.amount) : null,
          condition: product.condition?.value ?? null
        };
      })
    }
  };
};

export const pushViewCartEvent = (cart: Cart): void => {
  const event = getViewCartEvent(cart);

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(event);
};
