import React from 'react';
import Sticky from 'react-sticky-el';
import classNames from 'classnames';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Logo from '../../Common/Logo/index';
import MenuFlayout from '../MenuFlyout/index';
import MenuTabs from '../MenuTabs/index';
import MenuInfo from '../MenuInfo/index';
import IconsMenu from '../IconsMenu/index';
import SubMenuItems from '../SubMenuItems/index';
import GeneralLink from '../../Common/GeneralLink';
import { OmniboxContext } from '../../Coveo/context/OmniboxContext.tsx';

class DesktopMenu extends React.Component {
  static contextType = OmniboxContext;

  render() {
    const { isOmniboxBoxOpened } = this.context.state;
    const { rendering, homeUrl } = this.props,
      cssClass = classNames('Menu-Global-Searchbox', {
        Active: isOmniboxBoxOpened
      });
    return (
      <React.Fragment>
        <div className='Menu-header-wrapper'>
          <div className='Menu-header'>
            <MenuTabs rendering={rendering} />
            <MenuInfo rendering={rendering} />
          </div>
        </div>
        <Sticky className='Menu-Content-Sticky'>
          <div className='Menu-Content-Wrapper'>
            <div className='Menu-Content'>
              <GeneralLink className='Menu-Content-Logo' fields={{ value: { href: homeUrl } }}>
                <Logo logo={rendering.fields.Logo} />
              </GeneralLink>
              <SubMenuItems subMenuItems={rendering.fields.TabsItems} />
              <IconsMenu
                rendering={rendering}
                fields={rendering.fields}
                showMyAccount={rendering.fields.ShowMyAccount}
                showFavorites={rendering.fields.ShowFavorites}
              />
            </div>
          </div>
          <MenuFlayout />
          <div className={cssClass}>
            <Placeholder name='jss-custom-global-searchbox' rendering={rendering} />
          </div>
        </Sticky>
      </React.Fragment>
    );
  }
}

export default DesktopMenu;
