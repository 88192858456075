import React from 'react';
import { useScrollDirection } from '../../../utils/customHooks.js';
import classNames from 'classnames';

export const FacetStickyWrapper: React.FunctionComponent = ({ children }) => {
  const scrollDirection = useScrollDirection();

  return (
    <div
      className={classNames('FacetStickyWrapper', {
        isStickyForTablet: scrollDirection === 'up'
      })}
    >
      {children}
    </div>
  );
};
