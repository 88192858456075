import i18n from 'i18next';
import React from 'react';
import { OrderLineItemData } from '../MyAccountOrderDetails';
import Price from '../../../Shopify/Price/Price';

interface LineItemsMobileProps {
  lineItems: OrderLineItemData[];
}

export const LineItemsMobile: React.FC<LineItemsMobileProps> = ({ lineItems }) => {
  return (
    <>
      {lineItems.map((lineItem, index) => (
        // In special situations with bundles, the variant id could appear multiple times, that's why we add the index as well
        <div key={`${lineItem.id}__${index}`} className='LineItemsMobile__Item'>
          <div className='LineItemsMobile__Item__Title'>
            {lineItem.imageWithText.imageUrl && (
              <img
                src={lineItem.imageWithText.imageUrl}
                alt={lineItem.imageWithText.imageAlt || lineItem.imageWithText.text}
              />
            )}
            <span>{lineItem.imageWithText.text}</span>
          </div>

          <div className='LineItemsMobile__Item__Properties'>
            <div className='LineItemsMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Line Item Article Number')}</dt>
              <dd>{lineItem.articleNumber}</dd>
            </div>

            <div className='LineItemsMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Line Item Price')}</dt>
              <dd>
                <Price amount={lineItem.price.amount} currencyCode={lineItem.price.currencyCode} />
              </dd>
            </div>

            <div className='LineItemsMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Line Item Quantity')}</dt>
              <dd>{lineItem.quantity}</dd>
            </div>

            <div className='LineItemsMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Line Item Total')}</dt>
              <dd>
                <Price amount={lineItem.totalPrice.amount} currencyCode={lineItem.totalPrice.currencyCode} />
              </dd>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default LineItemsMobile;
