import React from 'react';
import classnames from 'classnames';
import { ImageVideoHeroComponentProps } from './types';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useImageVideoHeroSlider from './useImageVideoHeroSlider';
import VideoSlide from './VideoSlide';
import ImageSlide from './ImageSlide';
import TextBox from './TextBox';
import ProgressBar from './ProgressBar';
import ConditionalLink from '../ConditionalLink';
import Icon from '../../Common/Icon';

const addEndListener = (node: HTMLElement, done: () => void) => node.addEventListener('transitionend', done, false);

const ImageVideoHeroComponent: React.FC<ImageVideoHeroComponentProps> = ({ slides }) => {
  const { activeSlide, activeSlideIndex, isSlider, toPrev, toNext } = useImageVideoHeroSlider(slides);

  const imageElement = activeSlide?.image?.jss?.value;
  const videoElement = activeSlide?.video?.jss?.value;
  const callToActionElement = activeSlide?.callToAction?.jss;
  const overlineElement = activeSlide?.overline?.value;
  const titleElement = activeSlide?.title?.value;

  const handlers = useSwipeable({
    onSwipedLeft: toNext,
    onSwipedRight: toPrev
  });

  return (
    <div
      className={classnames('ImageVideoHero', {
        'ImageVideoHero--slider': isSlider
      })}
      data-testid='image-video-hero'
      {...handlers}
    >
      {isSlider ? (
        <>
          <button
            className='ImageVideoHero__button ImageVideoHero__button--prev'
            type='button'
            onClick={toPrev}
            data-testid='button-prev'
          >
            <Icon name='icon-web-24-arrow-left' />
            <span className='visually-hidden'>Previous slide</span>
          </button>
          <button
            className='ImageVideoHero__button ImageVideoHero__button--next'
            type='button'
            onClick={toNext}
            data-testid='button-next'
          >
            <Icon name='icon-web-24-arrow-right' />
            <span className='visually-hidden'>Next slide</span>
          </button>
        </>
      ) : null}
      <TransitionGroup component={null}>
        <CSSTransition
          key={activeSlide?.key ?? activeSlide?.title?.value}
          classNames='ImageVideoHero__item'
          addEndListener={addEndListener}
        >
          <ConditionalLink className='ImageVideoHero__container' field={callToActionElement} data-testid='link'>
            <>
              <div className='ImageVideoHero__slide' data-testid='slide'>
                {videoElement?.src ? (
                  <VideoSlide video={videoElement.src} poster={imageElement?.src} data-testid='video-slide' />
                ) : (
                  <ImageSlide
                    src={imageElement?.src}
                    alt={imageElement?.alt}
                    sizes={imageElement?.sizes}
                    srcSet={imageElement?.srcSet}
                    data-testid='image-slide'
                  />
                )}
              </div>
              <div className='ImageVideoHero__bottom'>
                <TextBox
                  title={titleElement}
                  overline={overlineElement}
                  linkText={callToActionElement?.value?.text}
                  className='ImageVideoHero__text-box'
                  data-testid='text-box'
                />
              </div>
            </>
          </ConditionalLink>
        </CSSTransition>
      </TransitionGroup>

      {isSlider ? (
        <div className='ImageVideoHero__progress-bar'>
          <ProgressBar activeSlideIndex={activeSlideIndex} amountOfSlides={slides.length} />
        </div>
      ) : null}
    </div>
  );
};

export default ImageVideoHeroComponent;
