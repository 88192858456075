import React, { useEffect, useState } from 'react';
import { SearchResultItemProps } from './types';
import i18n from 'i18next';
import CloseIcon from '../../../Common/Icons/CloseIcon';
import { compareCheckboxEvent, openCloseCompareBox } from '../../ProductCompare';
import { Link } from 'react-router-dom';
import { buildInteractiveResult } from '@coveo/headless';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import Button from '../../../Common/Button/Button';
import AddToCart from '../../../Shopify/AddToCart';
import Tag from '../../../Common/Tag/Tag';
import { getCookie } from '../../../Common/CookieSettings/CookieUtils';
import GreenDotCheckmarkIcon from '../../../Common/Icons/GreenDotCheckmarkIcon';
import Cookies from 'js-cookie';
import { COUNTRY_CODE, SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME } from '../../../Common/CookieSettings/CookieConstants';
import { renderShop } from '../../../../AppGlobals';
import Price from '../../../Shopify/Price/Price';

export const ProductResultItem: React.FC<SearchResultItemProps> = ({
  result,
  engine,
  downloadPagePath,
  language,
  siteName
}) => {
  const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase() || 'de';
  const shopifyTestAccessKey = Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME);

  const [shouldRenderShop, setShouldRenderShop] = useState(false);

  const { raw } = result;
  const pimproductid = raw.pimproductid as string;
  const title = raw.variantname ? (raw.variantname as string) : (raw.title as string);
  const pimclassificationinitialen = raw.pimclassificationinitialen as string[];
  const masterproductrelativeurl = raw.masterproductrelativeurl as string;
  const mainpicture = raw.mainpicture320 ? (raw.mainpicture320 as string) : (raw.mainpicture as string);
  const pimbrandname = raw.pimbrandname as string;
  const variantnametranslated = raw.variantnametranslated ? raw.variantnametranslated : raw.masterproductname;
  const pimmetadescription = raw.pimmetadescription;
  const subDescription = raw.pimmetasubdescription ? raw.pimmetasubdescription : raw.variantarticlenumberraw || '';
  const urlHasAlreadyArticleNumber = subDescription !== '';
  const isPhasedOut = raw.isphasedout === 'true';
  const pimamountarticles = (raw.pimamountarticles as number) || 0;
  const noShopTargetArticleNumber = (raw['target_article_number'] as string) || ''; // Target article number if there is no shop
  const productType = (raw['pimproducttype'] as string) || '';
  const shopifyItemsInShop = (raw[`shopify_${countryCode}_items_in_shop`] as number) || 0;
  const shopifyTargetArticleNumber = (raw[`shopify_${countryCode}_target_article_number`] as string) || '';
  const shopifyCheapestPrice = (raw[`shopify_${countryCode}_cheapest_price`] as string) || '';
  const shopifyCurrencyCode = (raw[`shopify_${countryCode}_currency_code`] as string) || '';
  const shopifyHasDemoDevice = raw[`shopify_${countryCode}_has_demo_device`] === 'true' || false;
  const shopifyHasBundle = raw[`shopify_${countryCode}_has_bundle`] === 'true' || false;
  const shopifyLargestSale = (raw[`shopify_${countryCode}_largest_sale`] as number) || 0;
  const shopifyGids = (raw[`shopify_${countryCode}_gids`] as string[]) || [];
  const isCustomizedSolution = (!subDescription && !pimamountarticles) || pimamountarticles === 0;
  const isAccessoryProductItem = productType === 'accessories';
  let variantsTag = '';

  if (pimamountarticles > 1) {
    variantsTag = i18n.t('SHOP | Variant selector variants text', { count: pimamountarticles });

    if (shopifyItemsInShop > 0) {
      variantsTag = `${variantsTag}, ${shopifyItemsInShop} ${i18n.t('SHOP | Variant selector variants in shop')}`;
    }
  } else if (isCustomizedSolution) {
    variantsTag = i18n.t('SHOP | Customized solution');
  }

  // Calculate largest sale output
  let largestSaleOutput = '';
  // If the number is negative, its not a discount and we don't want to show it
  const hasLargestSale = shopifyLargestSale > 0;
  const largestSalePercentageOutput = `${shopifyLargestSale * 100}%`;
  if (hasLargestSale && shopifyItemsInShop > 1) {
    // Up sell text only when more than 1 product
    largestSaleOutput = i18n.t('SHOP | Tag up sell discount', { discountPercentage: largestSalePercentageOutput });
  } else if (hasLargestSale && shopifyItemsInShop === 1) {
    largestSaleOutput = `-${largestSalePercentageOutput}`;
  }

  let productInfoMessage = '';

  // There is a shop for the country, product has variants and there are no items in the shop
  if (shouldRenderShop && pimamountarticles > 0 && shopifyItemsInShop === 0) {
    productInfoMessage = i18n.t('SHOP | Lister not in shop message');
  }

  // There is a shop for the country, product has no variants and there are no items in the shop - so custom article
  if (shouldRenderShop && !pimamountarticles) {
    productInfoMessage = i18n.t('SHOP | Lister on request message');
  }

  let productDownloadPageUrl = `${downloadPagePath}/${raw.productassetnamenormalized}`;

  if (raw.variantarticlenumberraw) {
    productDownloadPageUrl = `${productDownloadPageUrl}/${raw.variantarticlenumberraw}`;
  }

  // Take shopify target article number if it exists, otherwise take the no shop target article number
  const targetArticleNumber = shopifyTargetArticleNumber || noShopTargetArticleNumber || '';

  let productItemUrl = masterproductrelativeurl;

  // Accessory Items do have article number already in masterproductrelativeurl.
  // On the variants-search result page, also product items already have the article number.
  // The only way to track this right now, seems to be the subDescription fields, which then contains as well the article number.
  // In these cases, we don't need to add the targetArticleNumber in addition.
  if (!urlHasAlreadyArticleNumber) {
    productItemUrl += `/${targetArticleNumber.replace('.', '-')}`;
  }
  const productUrl = isPhasedOut ? productDownloadPageUrl : productItemUrl;

  const addProductOnDataLayerOnClick = (
    productID: string,
    productName: string,
    productCategory: string[],
    productUrl: string,
    pimbrandname: string,
    articleNumber?: string
  ) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list: productCategory },
          products: [
            {
              name: productName,
              id: productID,
              brand: pimbrandname,
              category: productCategory,
              articleNumber: articleNumber
            }
          ]
        }
      }
    });

    return false;
  };

  const [inComparison, setInComparison] = useState(false);

  useEffect(() => {
    const compareListCookie = JSON.parse(getCookie('compare'));

    pimclassificationinitialen?.forEach(category => {
      if (compareListCookie?.[category]?.includes(pimproductid)) {
        setInComparison(true);
      }
    });
  }, [pimproductid, pimclassificationinitialen]);

  useEffect(() => {
    async function fetchData() {
      const shouldWeRenderAShop = await renderShop(countryCode, shopifyTestAccessKey, siteName);

      setShouldRenderShop(shouldWeRenderAShop);
    }
    fetchData();
  }, [countryCode, shopifyTestAccessKey, siteName]);

  const interactiveResultController = buildInteractiveResult(engine, {
    options: { result }
  });

  return (
    <div className='ProductResultItem AnalyticsClass'>
      <Link
        to={productUrl}
        className='ProductResultItem__Link CoveoResultLink'
        onClick={() => {
          addProductOnDataLayerOnClick(
            pimproductid,
            title,
            pimclassificationinitialen,
            masterproductrelativeurl,
            pimbrandname
          );

          interactiveResultController.select();
        }}
      >
        <div className='ProductResultItem__Tags'>
          {hasLargestSale ? <Tag>{largestSaleOutput}</Tag> : null}
          {shopifyHasBundle && !isAccessoryProductItem ? (
            <Tag type='secondary'>{i18n.t('SHOP | Bundle available')}</Tag>
          ) : null}
          {shopifyHasDemoDevice && !isAccessoryProductItem ? (
            <Tag type='secondary'>{i18n.t('SHOP | Demo device available')}</Tag>
          ) : null}
        </div>
        <img src={mainpicture} loading='lazy' decoding='async' alt='' />
        <div className='ProductResultItem__Wrapper'>
          <h3 className='ProductResultItem__Wrapper-Title'>{variantnametranslated}</h3>
          {isPhasedOut ? <div className='PhasedOut__Badge'>{i18n.t('PRODUCT_DOWNLOADS | Phased out')}</div> : null}
          <div className='ProductResultItem__Wrapper-Description'>
            {' '}
            {pimmetadescription}
            {subDescription && <div className='ProductResultItem__Wrapper-SubDescription'>{subDescription}</div>}
          </div>
          {isPhasedOut ? (
            <div className='ProductResultItem__Wrapper-Downloads ResultLink--important'>
              {i18n.t('PRODUCT_DOWNLOADS | Downloads label')}
              <ArrowRight />
            </div>
          ) : null}
        </div>
      </Link>
      {!isPhasedOut ? (
        <>
          <div className='ProductResultItem__Variant-Tags'>
            {variantsTag ? <Tag type='secondary'>{variantsTag}</Tag> : null}
          </div>
          <div className='ProductResultItem__Buy-And-Compare'>
            {shopifyCheapestPrice && (
              <div className='ProductResultItem__From-Price'>
                <Price
                  type={hasLargestSale && shopifyItemsInShop === 1 ? 'sell' : ''}
                  amount={shopifyCheapestPrice}
                  currencyCode={shopifyCurrencyCode}
                  prefix={shopifyItemsInShop > 1 ? i18n.t('SHOP | Price up sell prefix') : null}
                />
              </div>
            )}
            {productInfoMessage && <div className='ProductResultItem__Info-Message'>{productInfoMessage}</div>}
            <div className='ProductResultItem__Wrapper-Compare-Container'>
              <div id={`${pimproductid}_COMPARE_BOX`} className='CompareBox' style={{ display: 'none' }}>
                <div className='CompareBox-Heading'>
                  {i18n.t('SEARCH | Compare box title')}
                  <div
                    className='CompareBox-Close'
                    onClick={() => openCloseCompareBox(pimproductid)}
                    role='button'
                    tabIndex={0}
                    onKeyDown={e => e.key === 'Enter' && openCloseCompareBox(pimproductid)}
                  >
                    <CloseIcon />
                  </div>
                </div>
                <div className='CompareBox-Text Compare-Text-More'>{i18n.t('SEARCH | Compare box description')}</div>
                <div className='CompareBox-Text Compare-Text-Exceded'>{i18n.t('SEARCH | Compare now exceeded')}</div>
                <div role='button' tabIndex={0}>
                  <div className='GeneralLink CompareBox-AddButton'>
                    <a className='' href='\Comparison'>
                      {i18n.t('SEARCH | Compare now label')}
                    </a>
                  </div>
                </div>
              </div>

              <div className='ProductResultItem__Wrapper-Compare'>
                <Button
                  onClick={() => {
                    compareCheckboxEvent(pimclassificationinitialen, pimproductid, () => {
                      setInComparison(true);
                    });
                  }}
                  buttonTitle={i18n.t('SEARCH | Compare label')}
                  square
                  icon='icon-web-24-compare'
                  iconSize={24}
                  variant='ghost'
                  size='no-size'
                />
                {inComparison && (
                  <span className='ProductResultItem__Compare-CheckIcon'>
                    <GreenDotCheckmarkIcon />
                  </span>
                )}
              </div>
            </div>
            {shopifyItemsInShop > 0 ? (
              <AddToCart
                gids={shopifyGids}
                availableForSale={shopifyItemsInShop > 0}
                quantity={1}
                hideButtonText
                ghostButton
                productName={raw.productassetnamenormalized as string}
                language={language}
                siteName={siteName}
                masterProductPageUrl={masterproductrelativeurl}
                productItemUrl={productItemUrl}
              />
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ProductResultItem;
