import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../../../Common/CookieSettings/CookieConstants';
import { Cart, CartItemAttribute } from '../types';
import {
  getCartCurrency,
  getTotalAddedValue,
  getProductById,
  getVariantById,
  priceToNumber
} from './ecomAnalyticsCommon';

const getAddToCartEvent = (
  cart: Cart,
  lines: { merchandiseId: string; quantity: number; attributes: CartItemAttribute[] }[]
) => {
  return {
    event: 'add_to_cart',
    ecommerce: {
      currency: getCartCurrency(cart),
      value: getTotalAddedValue(cart, lines),
      affiliation: Cookies.get(COUNTRY_CODE)?.toLowerCase() ?? 'de',
      items: lines.map(line => {
        const product = getProductById(cart, line.merchandiseId);

        if (!product) {
          return null;
        }

        const variant = getVariantById(product, line.merchandiseId);

        if (!variant) {
          return null;
        }

        return {
          // required fields
          item_id: line.merchandiseId,

          // recommended fields
          // The fields can now be taken from the line attributes, as they already hold the required information
          item_name: line.attributes?.find(attr => attr.key === '_leisterProductName')?.value || null,
          item_brand: line.attributes?.find(attr => attr.key === '_leisterProductBrand')?.value || null,
          item_category: line.attributes?.find(attr => attr.key === '_leisterProductCategory')?.value || null,
          item_category2: line.attributes?.find(attr => attr.key === '_leisterProductSubcategory')?.value || null,
          price: priceToNumber(variant.price.amount),
          quantity: line.quantity,

          // custom fields (up to 27)
          sku: variant.sku,
          compareAtPrice: variant.compareAtPrice?.amount ? priceToNumber(variant.compareAtPrice.amount) : null,
          condition: product.condition?.value ?? null
        };
      })
    }
  };
};

export const pushAddToCartEvent = (
  cart: Cart,
  lines: { merchandiseId: string; quantity: number; attributes: CartItemAttribute[] }[]
): void => {
  const event = getAddToCartEvent(cart, lines);

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(event);
};
