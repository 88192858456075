import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

/**
 * component that renders a wrapper for a placeholder
 */
class SalesAndServicePointsFiltersWrapper extends React.Component {
  render() {
    return (
      <div className='SalesAndServicePointsFiltersWrapper'>
        <Placeholder name='jss-sales-and-service-points-filters' rendering={this.props.rendering} />
      </div>
    );
  }
}
export default SalesAndServicePointsFiltersWrapper;
