import React from 'react';
import i18n from 'i18next';
import { OrderListData } from '../MyAccountOrderList';
import FulfillmentStatusTag from '../FulfillmentStatusTag';
import Price from '../../../Shopify/Price/Price';
import LinkButton from '../../../Common/LinkButton/LinkButton';

export function OrderListMobile({
  orders,
  onClick
}: {
  orders: OrderListData[];
  onClick?: (order: OrderListData) => void;
}): JSX.Element {
  return (
    <div className='OrderListMobile'>
      {orders.map(order => (
        <div
          key={order.id}
          className='OrderListMobile__Item'
          onClick={() => onClick && onClick(order)}
          onKeyDown={() => onClick && onClick(order)}
          role='button'
          tabIndex={0}
        >
          <div className='OrderListMobile__Item__Title'>{order.orderNumber}</div>

          <div className='OrderListMobile__Item__Properties'>
            <div className='OrderListMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Order Date')}</dt>
              <dd>{new Date(order.processedAt).toLocaleDateString()}</dd>
            </div>

            <div className='OrderListMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Order Payment Status')}</dt>
              <dd>{order.financialStatusText}</dd>
            </div>

            <div className='OrderListMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Order Fulfillment Status')}</dt>
              <dd>
                <FulfillmentStatusTag fulfillmentStatus={order.fulfillmentStatus} />
              </dd>
            </div>

            <div className='OrderListMobile__Item__Property'>
              <dt>{i18n.t('ORDERS | Order Total')}</dt>
              <dd>
                <Price amount={order.totalPrice.amount} currencyCode={order.totalPrice.currencyCode} />
              </dd>
            </div>
          </div>

          <LinkButton
            className='OrderListMobile__Item__Button'
            withArrow={false}
            title={i18n.t('ORDERS | Order Details')}
            onClick={() => onClick && onClick(order)}
          />
        </div>
      ))}
    </div>
  );
}

export default OrderListMobile;
