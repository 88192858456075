import * as React from 'react';
import { KnownSiteNames, type KnownSiteNamesType } from '../../../Constants/KnownSiteNames';

export interface SearchEngineState {
  locale: string;
  coveoSiteName: string;
  siteName: KnownSiteNamesType;
  searchToken: string;
}

export const initialState: SearchEngineState = {
  locale: '',
  coveoSiteName: '',
  siteName: KnownSiteNames.LeisterTechnologies,
  searchToken: ''
};

export interface SearchEngineContextInterface {
  state: SearchEngineState;
  dispatch: React.Dispatch<SearchAction>;
}

const SearchEngineContext = React.createContext<SearchEngineContextInterface>({
  state: {
    locale: '',
    coveoSiteName: '',
    siteName: KnownSiteNames.LeisterTechnologies,
    searchToken: ''
  },
  dispatch: () => undefined
});

export enum ActionType {}

export type SearchAction = {
  type: ActionType;
};

const searchReducer = (state: SearchEngineState, action: SearchAction): typeof initialState => {
  switch (action.type) {
    default:
      throw new Error();
  }
};

const SearchEngineContextProvider = ({
  children,
  coveoSiteName,
  siteName,
  locale,
  searchToken
}: {
  children: React.ReactNode;
  coveoSiteName: string;
  siteName: KnownSiteNamesType;
  locale: string;
  searchToken: string;
}) => {
  initialState.locale = locale;
  initialState.coveoSiteName = coveoSiteName;
  initialState.searchToken = searchToken;
  initialState.siteName = siteName;
  const [state, dispatch] = React.useReducer(searchReducer, initialState as SearchEngineState);
  const value = { state, dispatch };

  return <SearchEngineContext.Provider value={value}>{children}</SearchEngineContext.Provider>;
};

export { SearchEngineContext, SearchEngineContextProvider };
