import cartFragment from '../fragments/cart';
import { getLanguageContext } from '../inContext/language';

export const addToCartMutation = (): string => /* GraphQL */ `
  mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) ${getLanguageContext()} {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartFragment}
`;

export const createCartMutation = (): string => /* GraphQL */ `
  mutation createCart($lineItems: [CartLineInput!]) ${getLanguageContext()} {
    cartCreate(input: { lines: $lineItems }) {
      cart {
        ...cart
      }
    }
  }
  ${cartFragment}
`;

export const editCartItemsMutation = (): string => /* GraphQL */ `
  mutation editCartItems($cartId: ID!, $lines: [CartLineUpdateInput!]!) ${getLanguageContext()} {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`;

export const removeFromCartMutation = (): string => /* GraphQL */ `
  mutation removeFromCart($cartId: ID!, $lineIds: [ID!]!) ${getLanguageContext()} {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cartFragment}
`;

export const addDiscountCodeToCartMutation = (): string => /* GraphQL */ `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]!) ${getLanguageContext()} {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cartFragment}
`;
