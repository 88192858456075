import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import classNames from 'classnames';
import ChevronLeftSmall from '../Icons/ChevronLeftSmall';
import ChevronRightSmall from '../Icons/ChevronRightSmall';

export type ScrollSnapProps = {
  children: React.ReactNode;
  focusKey?: string;
};

export const ScrollSnap = ({ children, focusKey = '' }: ScrollSnapProps): JSX.Element => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [hasScrollArea, setHasScrollArea] = useState(false);
  const [debounceSourceValue, setDebounceSourceValue] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const [scrollSnapInitialized, setScrollSnapInitialized] = useState<boolean>(false);

  // Scroll event handler function
  const scrollFeatureHandler = () => {
    const scrollWidth = scrollAreaRef?.current?.scrollWidth ?? 0;
    const clientWidth = scrollAreaRef?.current?.clientWidth ?? 0;
    const scrollLeft = Math.round(scrollAreaRef?.current?.scrollLeft ?? 0); // Get the scroll position

    // Calculate the maximum scroll position to consider as scrolled completely to the right
    const maxScrollLeft = scrollWidth - clientWidth;

    // 0 maxScrollLeft means there is no scroll area
    setHasScrollArea(!!maxScrollLeft);

    // Show/hide the left arrow
    setShowLeftArrow(!!scrollLeft);

    // Check if scrolled completely to the right
    setShowRightArrow(scrollLeft < maxScrollLeft);

    // Update the heigth of the inner element in case it changed
    updateHeight();
  };

  // To use react-use debounced function we need to track a changing value
  function handleScroll(event: any) {
    setDebounceSourceValue(event.target.scrollLeft);
  }

  // Get the inner height of the element, to apply it to our arrows
  function updateHeight() {
    if (scrollAreaRef.current) {
      setInnerHeight(scrollAreaRef.current?.clientHeight ?? 84); // 84px as the default, in case something is off here
    }
  }

  // Also for the window resize event we need to track a changing value
  function handleResize() {
    setDebounceSourceValue(window.innerWidth);
  }

  // Debounce the scroll feature
  useDebounce(scrollFeatureHandler, 100, [debounceSourceValue]);

  useEffect(() => {
    const scrollAreaElement = scrollAreaRef.current;
    if (scrollAreaElement) {
      scrollAreaElement.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      return () => {
        scrollAreaElement.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  // Remove scroll snap helper to make whole navigation scrollable again
  useEffect(() => {
    // In case a focus key change triggered this effect, we want to reset the initialized state to false
    // This will make the new active element to get focused again
    setScrollSnapInitialized(false);

    // Remove scroll snap helper to make whole navigation scrollable again
    const timer = setTimeout(() => {
      setScrollSnapInitialized(true);
    }, 500);
    return () => clearTimeout(timer);
  }, [focusKey]);

  return (
    <div className={classNames('ScrollSnap__Container', { 'ScrollSnap--initialized': scrollSnapInitialized })}>
      <div
        style={{ height: `${innerHeight}px` }}
        className={classNames(
          'ScrollSnapArrow ScrollSnapArrow--left',
          { 'ScrollSnapArrow--show': showLeftArrow },
          { 'ScrollSnapArrow--disabled': !hasScrollArea }
        )}
      >
        <ChevronLeftSmall />
      </div>
      <div className='ScrollSnap' ref={scrollAreaRef}>
        {children}
      </div>
      <div
        style={{ height: `${innerHeight}px` }}
        className={classNames(
          'ScrollSnapArrow ScrollSnapArrow--right',
          { 'ScrollSnapArrow--show': showRightArrow },
          { 'ScrollSnapArrow--disabled': !hasScrollArea }
        )}
      >
        <ChevronRightSmall />
      </div>
    </div>
  );
};

export default ScrollSnap;
