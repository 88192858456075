import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CloseIcon from '../Common/Icons/CloseIcon';

// NOTE: Whenever you come here and use this component, please migrate over to the new ConfirmationDialog component

class OverlayDialog extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./OverlayDialog.scss');
  }

  render() {
    const { Title, buttonLabel, onClose, onClick } = this.props;
    return (
      <div className='OverlayDialog OverlayDialog-Active'>
        <div className='OverlayDialog-DetailsContainer'>
          <div className='OverlayDialog-Heading'>
            <Text field={Title} tag='span' className='OverlayDialog-Text' />
            <CloseIcon onClick={onClose} />
          </div>
          {this.props.children}
          <div className='OverlayDialog-Buttons'>
            <div
              className='OverlayDialog-Buttons-DeleteButton'
              onClick={onClick}
              onKeyDown={e => {
                if (e.target === 13) {
                  onClick();
                }
              }}
              role='button'
              tabIndex='0'
            >
              <Text field={buttonLabel} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OverlayDialog;
