import React from 'react';

class RadioInput extends React.Component {
  // Shows the radio buttons and default checks the first one.
  getOptions = () => {
    return this.props.items.map((item, index) => {
      const isChecked = item.code === this.props.checkedItem;

      return (
        <label className='RadioInput-Label' key={item.title + index}>
          <div
            className='RadioInput-Label-Wrapper'
            onClick={() => {
              this.props.onItemClick(item);
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) this.props.onItemClick(item);
            }}
            role='button'
            tabIndex='0'
          >
            {item.title}
            <input type='radio' name='options' checked={isChecked} onChange={() => undefined} />
            <span className='RadioInput-Checkmark' />
          </div>
        </label>
      );
    });
  };

  render() {
    return <div className='RadioInput'>{this.getOptions()}</div>;
  }
}

export default RadioInput;
