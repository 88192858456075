import React from 'react';
import { connect } from 'react-redux';

class USP extends React.Component {
  generateUSPList = () => {
    const { usp } = this.props;
    if (!usp) {
      return null;
    }

    return usp.map((item, index) => {
      return <li key={index + item}>{item}</li>;
    });
  };

  render() {
    return (
      <div className='USP'>
        <ul>{this.generateUSPList()}</ul>
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    usp: state.productDetails.masterProductData ? state.productDetails.masterProductData.USP : null
  };
};

export default connect(MapStateToProps)(USP);
