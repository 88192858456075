import React from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';

class ContentAccordion extends React.Component {
  render() {
    const { fields } = this.props;

    if (!fields) {
      return <div className='ContentAccordion componentContainerMobile' />;
    }

    return (
      <div className='whiteBackground'>
        <Row nogutter justify='center' className='ContentAccordion componentContainerMobile'>
          <Col sm={12} md={12} xl={8} lg={8} className='ContentAccordion-Column'>
            <Text className='ContentAccordion-Column-Title' field={fields.Title} tag='h2' />
            <RichText className='ContentAccordion-Column-Text' field={fields.Text} />
            <Placeholder name='jss-accordion-element' rendering={this.props.rendering} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentAccordion;
