import { getShopifyAppLeisterDiscountBundlesId } from '../../../../../AppGlobals';
import { productVariantFragment } from './variant';

/**
 * Get the product variants that are part of a bundle
 * ATTENTION: Don't add the productWithoutVariantsFragment here, as it is already attached to the getBundleDataById root query.
 * @see getBundleDataByIdQuery
 **/
export const bundledProductVariantsFragment = /* GraphQL */ `
  fragment bundledProductVariants on MetaobjectField {
    references(first: 100) {
      nodes {
        ... on ProductVariant {
          ...productVariant
          product {
            ...productWithoutVariants
          }
        }
      }
    }
  }
  ${productVariantFragment}
`;

// Metafield namespace holds the App ID, which will be different per environment
const metafieldNamespace = `app--${getShopifyAppLeisterDiscountBundlesId()}`;

export const referencedBundlesFragment = /* GraphQL */ `
  fragment referencedBundles on ProductVariant {
    bundleMetafield: metafield(namespace: "${metafieldNamespace}", key: "leister_bundle_discount_references") {
      id
      value
      # namespace
      # key
      # type
      bundles: references(first: 100) {
        nodes {
          ... on Metaobject {
            id
            discountAmount: field(key: "amount") {
              value
            }
            discountName: field(key: "code") {
              value
            }
            productVariants: field(key: "product_variant_references") {
              value
              ...bundledProductVariants
            }
          }
        }
      }
    }
  }
  ${bundledProductVariantsFragment}
`;
