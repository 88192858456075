import React, { useEffect, useState } from 'react';
import { Text, RichText, Image, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import EmailGreyIcon from '../../Common/Icons/EmailGreyIcon';
import PageBackLink from '../PageBackLink';
import { UniversalJobHeroProps } from './types';
import MapPinIcon from '../../Common/Icons/MapPin';
import Home2Icon from '../../Common/Icons/Home2Icon';
import TimeIcon from '../../Common/Icons/TimeIcon';
import i18n from 'i18next';
import { TextField } from '../../../utils/types';

export const UniversalJobHero = (props: UniversalJobHeroProps) => {
  const { sitecoreContext, rendering } = props;
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    setCurrentLink(window.location.href);
  }, []);

  if (!sitecoreContext?.route?.fields) {
    return <div className='componentContainerHero' />;
  }

  const { fields } = sitecoreContext?.route;

  const {
    Title,
    'Job Location': location,
    'Job Work Load': workLoad,
    'Show Email Share': showEmail,
    'Job Company': company,
    'Job Country': country,
    'Job Abstract': abstract,
    'Job Image': img
  } = fields;
  const positionLabel: string = i18n.t('JOB | Open Position');
  const backLinkText: TextField = { value: i18n.t('JOB | Back to') };
  const backFieldData = props?.fields?.data?.getBackToUniversalJobQuery || {};
  const companyField = company && company.fields['Company Name'] ? company.fields['Company Name'] : { value: '' };
  const companyText = company && company.fields['Company Name'] ? company.fields['Company Name'].value : '';
  const countryField = country?.fields?.RegionName ? country.fields.RegionName : { value: '' };
  const workloadField = workLoad?.fields?.['Category Tag'] ? workLoad.fields['Category Tag'] : { value: '' };
  const locationField = location?.fields?.['Category Tag'] ? location.fields['Category Tag'] : { value: '' };

  return (
    <div className='componentContainerHero'>
      <div className='UniversalJobHero'>
        <PageBackLink backFieldData={backFieldData} buttonText={backLinkText} />
        <h1 className='UniversalJobHero__Title'>
          <Text tag='span' field={Title} />
        </h1>
        <span className='UniversalJobHero__Info-Row'>
          <span className='UniversalJobHero__Info-Details'>
            <MapPinIcon />
            <span className='wrap-context-wrapper'>
              {locationField.value && (
                <span className='wrap-context'>
                  <Text tag='span' field={locationField} />
                  {countryField.value && <span>,&nbsp;</span>}
                </span>
              )}
              {countryField.value && (
                <span className='wrap-context'>
                  <Text tag='span' field={countryField} />
                </span>
              )}
            </span>
          </span>
          <span className='UniversalJobHero__Info-Details'>
            <Home2Icon />
            <Text tag='span' field={companyField} />
          </span>
          <span className='UniversalJobHero__Info-Details'>
            <TimeIcon />
            <Text tag='span' field={workloadField} />
          </span>
        </span>
        <Image className='UniversalJobHero__Image' field={img} editable={false} />

        <div className='UniversalJobHero__SocialLinkWrapper'>
          <Placeholder name='jss-share-social-link' rendering={rendering} />
          {showEmail.value ? (
            <a
              href={`mailto:?subject=${positionLabel}%20${locationField.value},%20${countryField.value}%20@%20${companyText}&body=${Title.value}/
						${workloadField.value},%20${locationField.value},%20${countryField.value}%0D%0A${currentLink}`}
              className='UniversalJobHero__EmailShare click_button_share'
              target='blank'
            >
              <EmailGreyIcon />
            </a>
          ) : null}
        </div>
        <RichText field={abstract} className='UniversalJobHero__Description' />
      </div>
    </div>
  );
};

export default withSitecoreContext()(UniversalJobHero);
