import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CardsCarousel from '../../Common/CardsCarousel/CardsCarousel';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';
import CoveoProductCarouselCard from './CoveoProductCarouselCard';
import { getBackgroundStyle, isBlack } from '../../../utils/StylesUtils';
import { useViewportSize } from '../../../hooks/useViewportSize';
import { CoveoProductCarouselCardType, CoveoProductCarouselProps } from './types';
import { hasItems } from '../../../utils/DataUtils';
import Text from '../../Common/Text';
import { useCoveoProductCarouselData } from './CoveoProductCarouselData';
import { CoveoContext } from '../context/CoveoContext';
import { DefaultViewport, ViewportSize } from '../../../context/ViewportContext';

const visibleCardCountOnViewport: { [key in ViewportSize]: number } = {
  mobile: 1,
  tablet: 2,
  desktop: 3
};

export const CoveoProductCarouselComponent: React.FC<CoveoProductCarouselProps> = props => {
  const {
    Title,
    Subtitle,
    'Background Color': BackgroundColor,
    Description,
    'Product Group Label': productGroup
  } = props.fields || {};
  const [visibleItemsCount, setVisibleItemsCount] = useState(visibleCardCountOnViewport[DefaultViewport]);
  const { viewportSize } = useViewportSize();
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const { productCarouselData } = useCoveoProductCarouselData(engine, props);

  useEffect(() => {
    setVisibleItemsCount(visibleCardCountOnViewport[viewportSize]);
  }, [viewportSize]);

  if (!hasItems(props) || !productCarouselData.length) {
    return <div className='whiteBackground' />;
  }

  const inlineStyle = getBackgroundStyle(BackgroundColor);
  const WhiteColorStyle = isBlack(BackgroundColor);
  const NoTitle = !Title?.value && !Subtitle?.value && !Description?.value;

  return (
    <div className='grayBackgroundImage' style={inlineStyle}>
      <div
        className={classNames(
          ' CoveoProductCarousel carouselComponentContainer click_content_block_product_linker_carousel',
          { WhiteColorStyle, NoTitle }
        )}
      >
        <HeadlineWithTitleAndSubtitle
          isTextWhite={WhiteColorStyle}
          extraClasses='CoveoProductCarousel-Headline'
          title={Title}
          subtitle={Subtitle}
        />
        <RichText field={Description} className='CoveoProductCarousel-Description' />
        <div className={classNames('CoveoProductCarousel-ProductGroup', { NoTitle })}>
          <Text field={productGroup} />
          <span className='CoveoProductCarousel-ProductGroup-Number'>({productCarouselData.length})</span>
        </div>
        {productCarouselData.length > visibleItemsCount ? (
          <CardsCarousel nrOfItemsToShow={visibleItemsCount}>
            {productCarouselData.map((item: CoveoProductCarouselCardType) => (
              <CoveoProductCarouselCard fields={props.fields} key={item.Name} currentItem={item} />
            ))}
          </CardsCarousel>
        ) : (
          <div
            className={classNames({
              'CoveoProductCarousel-MobileContainer': viewportSize === 'mobile',
              'CoveoProductCarousel-TabletContainer': viewportSize === 'tablet',
              'CoveoProductCarousel-DesktopContainer': viewportSize === 'desktop'
            })}
          >
            {productCarouselData.map((item: CoveoProductCarouselCardType) => (
              <CoveoProductCarouselCard fields={props.fields} key={item.Name} currentItem={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withSitecoreContext()(WithStickyMenu(CoveoProductCarouselComponent, hasItems));
