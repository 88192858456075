import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SubCategories from './Components/SubCategories/index';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';

class Accessories extends React.Component {
  constructor(props) {
    super(props);
    this.subCategoriesElement = React.createRef();
    this.state = {
      activeIndex: 0,
      activeCategoryName: null
    };
  }

  componentDidMount() {
    this.setDefaultActiveCategoryName();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.activeCategoryName && prevState.activeCategoryName !== '') {
      this.setDefaultActiveCategoryName();
    }
  }

  setDefaultActiveCategoryName() {
    const accessories = this.props.accessories;
    if (accessories && accessories.Categories && accessories.Categories[0] && accessories.Categories[0].category) {
      this.setState({
        activeCategoryName: accessories.Categories[0].category
      });
    }
  }

  onCategoryClick = (index, activeCategoryName) => {
    this.subCategoriesElement.current.handleBack();
    this.setState({
      activeIndex: index,
      activeCategoryName
    });
  };

  getCategories = () => {
    return this.props.accessories.Categories.map((item, index) => {
      const cssClass = classNames('Accessories-Category', {
        'Accessories-Category-Active': this.state.activeIndex === index
      });
      return (
        <div
          className={cssClass}
          key={index + item.category}
          onClick={() => {
            this.onCategoryClick(index, item.category);
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onCategoryClick(index, item.category);
          }}
          role='button'
          tabIndex='0'
        >
          {item.category}
        </div>
      );
    });
  };

  render() {
    if (!hasItems(this.props)) {
      return <div className='click_compatible_accessories_pdp' />;
    }
    const { fields, accessories } = this.props,
      {
        Subtitle,
        Title,
        'Type of Accessory': typeOfTitle,
        'Accessory Article Label': articles,
        'Accessory Detail Page Link': AccessoryDetailPageLink,
        Back,
        Show,
        More
      } = fields;

    const typeOfCategory = {
      value: typeOfTitle.value + ' ' + this.state.activeCategoryName
    };

    let accesoriesSection = null;
    if (accessories) {
      accesoriesSection = (
        <SubCategories
          ref={this.subCategoriesElement}
          accessories={accessories.Categories}
          articleLabel={articles}
          activeCategoryName={this.state.activeCategoryName}
          typeOfCategory={typeOfCategory}
          backButtonLabel={Back.value}
          show={Show.value}
          more={More.value}
          accessoryDetailPageLink={AccessoryDetailPageLink}
        />
      );
    }
    return (
      <div className='Accessories click_compatible_accessories_pdp'>
        <div className='Accessories-Top'>
          <div className='Accessories-Top-Wrapper componentContainer'>
            <HeadlineWithTitleAndSubtitle title={Title} subtitle={Subtitle} />
            <div className='Accessories-Top-Categories'>{this.getCategories()}</div>
          </div>
        </div>
        <div className='Accessories-Bottom componentContainer'>{accesoriesSection}</div>
      </div>
    );
  }
}

const MapStateToProps = state => {
  return {
    accessories: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.ProductAccesories
      : null
  };
};

const hasItems = function (props) {
  const { accessories } = props;
  if (!accessories || !accessories.Categories || !accessories.Categories.length) {
    return false;
  }
  return true;
};

export default connect(MapStateToProps)(WithStickyMenu(Accessories, hasItems));
