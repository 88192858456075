import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { removeCategoryFromCompareCookie } from '../../../../Common/CookieSettings/CookieUtils';
import { setInitialData, setCurrentComparedCategory } from '../../../../../store/compare/actions';
import InfoIcon from '../../../../Common/Icons/InfoIcon';

class CompareCategory extends React.Component {
  toggleCategories = () => {
    const { categoryName, initialData, updateSelectedCategory } = this.props;

    const exampleItemWithTranslations = initialData.find(x => [x.category, x.categoryEn].includes(categoryName));

    //update on redux current selected category
    if (exampleItemWithTranslations.categoryEn) {
      updateSelectedCategory(exampleItemWithTranslations.categoryEn);
    }
  };

  deleteCategoryFromCompareList = event => {
    event.stopPropagation();
    const initialData = JSON.parse(JSON.stringify(this.props.initialData)),
      dataToRemove = initialData.filter(item => {
        return item.category === this.props.categoryName;
      }),
      removeCategoryIds = dataToRemove.map(item => item.id),
      dataToUpdate = initialData.filter(item => {
        return !removeCategoryIds.includes(item.id);
      });

    // remove compared category from cookies
    removeCategoryFromCompareCookie(this.props.categoryName);

    // update initialData on redux widouth the deleted category
    this.props.updateInitialData(dataToUpdate);

    this.props.updateSelectedCategory(null);
  };

  render() {
    const { categoryName, initialData, fields, selectedCategory } = this.props,
      { 'Info Message For Category': categoryMessage } = fields,
      data = JSON.parse(JSON.stringify(initialData)),
      firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : '',
      categoryData = data.filter(item => {
        return item.category === categoryName;
      }),
      infoCssClass = classNames('CompareCategories-CategoryItem-InfoButton', {
        Active: categoryData && categoryData.length > 4
      });

    // get an example item which has 'category' or 'categoryEn' names that match the selected category
    // this will be used below to match if one of these names is the 'selected' current category
    const exampleItemWithTranslations = initialData.find(x => [x.category, x.categoryEn].includes(selectedCategory));
    let isActive = firstSelectedCategory === categoryName;
    if (exampleItemWithTranslations) {
      const arrayOfPossibleNames = [exampleItemWithTranslations.category, exampleItemWithTranslations.categoryEn];
      isActive = arrayOfPossibleNames.includes(categoryName);
    }
    let cssClass = classNames('CompareCategories-CategoryItem', {
      Active: isActive
    });
    return (
      <div
        className={cssClass}
        onClick={this.toggleCategories}
        onKeyDown={e => {
          if (e.target === 13) this.toggleCategories();
        }}
        role='button'
        tabIndex='0'
      >
        <span className='CompareCategories-CategoryItem-Title'>
          <span className='CompareCategories-CategoryItem-Title-Name'>{this.props.categoryName}</span>
          &nbsp;<span>({this.props.noOfProducts})</span>
        </span>
        <div className={infoCssClass}>
          <InfoIcon />
          <span className='CompareCategories-CategoryItem-InfoButton-Message'>
            <Text field={categoryMessage} />
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialData: state.compareProduct.initialData,
    // get selectedCategory from redux or localStorage
    selectedCategory: state.compareProduct.selectedCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateInitialData: compareProducts => dispatch(setInitialData(compareProducts)),
    updateSelectedCategory: category => dispatch(setCurrentComparedCategory(category))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareCategory);
