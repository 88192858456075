import { buildBreadcrumbManager, Facet as HeadlessFacet, SearchEngine } from '@coveo/headless';
import React, { Children, ReactElement, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import CloseIcon from '../../Common/Icons/CloseIcon';
import { ActionType, SearchContext } from '../context/SearchContext';
import classnames from 'classnames';
import { StaticFacets } from '../CoveoSettings';
import { scrollToTopOfSearchResults } from '../SearchResults/scrollToSearchResults';

type BreadcrumbManagerChild = ReactElement<{ controller: HeadlessFacet }>;
type BreadcrumbManagerProps = {
  children: BreadcrumbManagerChild | BreadcrumbManagerChild[];
  hasResults: boolean;
  engine: SearchEngine;
  staticOnDesktop?: boolean;
};

export const FacetManager: React.FC<BreadcrumbManagerProps> = ({
  children,
  hasResults,
  engine,
  staticOnDesktop = false
}) => {
  const facetManagerController = buildBreadcrumbManager(engine);
  const [, setState] = useState(facetManagerController.state);
  const { state: searchState, dispatch } = useContext(SearchContext);

  useEffect(() => facetManagerController.subscribe(() => setState(facetManagerController.state)), []);

  const resetAllFacets = () => {
    facetManagerController.state.facetBreadcrumbs.forEach(facet => {
      if (!StaticFacets.includes(facet.facetId)) {
        facet.values.forEach(facetValue => {
          facetValue.deselect();
          scrollToTopOfSearchResults();
        });
      }
    });

    closeFacetsModal();
  };

  const closeFacetsModal = () => {
    dispatch({
      type: ActionType.CLOSE_FACETS
    });
  };

  const discardChanges = () => {
    const childFacets = Children.toArray(children) as BreadcrumbManagerChild[];

    facetManagerController.state.facetBreadcrumbs.forEach(facet => {
      facet.values.forEach(facetValue => {
        let wasSelected = false;
        const filterFound = !!searchState?.storedSelectedFacets[facet.facetId];

        searchState?.storedSelectedFacets[facet.facetId]?.forEach(storedFacetValue => {
          if (facetValue.value.value === storedFacetValue.value) {
            wasSelected = true;
          }
        });

        if (filterFound && !wasSelected) {
          facetValue.deselect();
        }
      });

      searchState?.storedSelectedFacets[facet.facetId]?.forEach(storedFacetValue => {
        let isStillSelected = false;

        facet.values.forEach(facetValue => {
          if (facetValue.value.value === storedFacetValue.value) {
            isStillSelected = true;
          }
        });

        if (!isStillSelected) {
          childFacets.forEach(childFacet => {
            if (childFacet?.props?.controller?.state?.facetId === facet.facetId) {
              childFacet?.props?.controller?.toggleSelect(storedFacetValue);
            }
          });
        }
      });
    });

    closeFacetsModal();
  };

  return (
    <div
      className={classnames('FacetManager', {
        'FacetManager--opened': searchState.isFacetsModalOpen,
        'FacetManager--loaded': hasResults,
        'FacetManager--static-on-desktop': staticOnDesktop
      })}
    >
      <div className='FacetManager__Top'>
        <div className='FacetManager__Top-Title'>{i18n.t('SEARCH | Facets Title')}</div>
        <button className='FacetManager__Top-Reset' onClick={resetAllFacets}>
          {i18n.t('SEARCH | Facets Reset All')}
        </button>
        <button className='FacetManager__Top-Close' onClick={closeFacetsModal}>
          <CloseIcon />
        </button>
      </div>
      {children}
      <div className='FacetManager__Bottom'>
        <button className='FacetManager__Bottom-Apply' onClick={closeFacetsModal}>
          {i18n.t('SEARCH | Facets Apply')}
        </button>
        <button className='FacetManager__Bottom-Discard' onClick={discardChanges}>
          {i18n.t('SEARCH | Facets Discard')}
        </button>
      </div>
    </div>
  );
};

export default FacetManager;
