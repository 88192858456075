import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Row } from 'react-grid-system';
import classNames from 'classnames';
import { removeWhiteSpaces } from '../../../utils/Utils';
/**
 * @class Component that displays ColumnCategory Component in 3 column grid
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class ThreeColumnCategory extends React.Component {
  render() {
    if (!this.props.fields) {
      return <div className='ThreeColumnCategory-Wrapper componentContainer' />;
    }
    const { 'Anchor Title': anchorTitle } = this.props.fields;
    const BackgroundColor = this.props.fields.BackgroundColor;
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
    };
    const cssClass = classNames('ThreeColumnCategory click_content_block_category_three_column', {
      WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
    });
    return (
      <div className='ThreeColumnCategory-Container whiteBackground' style={inlineStyle}>
        <div className='ThreeColumnCategory-Wrapper componentContainer' style={inlineStyle}>
          <Row nogutter className={cssClass} style={inlineStyle} id={removeWhiteSpaces(anchorTitle.value || null)}>
            <Placeholder name='jss-three-column-category' rendering={this.props.rendering} />
          </Row>
        </div>
      </div>
    );
  }
}

export default ThreeColumnCategory;
