import { Cart, CartItem, Merchandise, ShopProduct, ShopProductVariant } from '../types';

export const getCartCurrency = (cart: Cart): string => cart.cost.totalAmount?.currencyCode ?? 'EUR';

export const getProductById = (cart: Cart, id: string): ShopProduct | undefined =>
  cart.lines.find(line => line.merchandise.id === id)?.merchandise.product;

export const getCartItemById = (cart: Cart, id: string): CartItem | undefined =>
  cart.lines.find(line => line.id === id);

export const getMerchandiseByLineId = (cart: Cart, id: string): Merchandise | undefined =>
  cart.lines.find(line => line.id === id)?.merchandise;

export const getVariantById = (product: ShopProduct, id: string): ShopProductVariant | undefined =>
  product.variants.find(variant => variant.id === id);

export const getVariantByLineId = (product: ShopProduct, id: string): ShopProductVariant | undefined =>
  product.variants.find(variant => variant.id === id);

export const getTotalAddedValue = (cart: Cart, lines: { merchandiseId: string; quantity: number }[]): number => {
  return lines.reduce((total, line) => {
    const product = getProductById(cart, line.merchandiseId);

    if (!product) {
      return total;
    }

    const variant = getVariantById(product, line.merchandiseId);

    if (!variant) {
      return total;
    }

    return total + priceToNumber(variant.price.amount) * line.quantity;
  }, 0.0);
};

export const getTotalRemovedValue = (cart: Cart, lineIds: string[]): number => {
  return lineIds.reduce((total, lineId) => {
    const cartItem = getCartItemById(cart, lineId);

    if (!cartItem) {
      return total;
    }

    const product = getProductById(cart, cartItem.merchandise.id);

    if (!product) {
      return total;
    }

    const variant = getVariantById(product, cartItem.merchandise.id);

    if (!variant) {
      return total;
    }

    return total + priceToNumber(variant.price.amount) * cartItem.quantity;
  }, 0.0);
};

export const priceToNumber = (price: string): number => +parseFloat(price).toFixed(2);
