import React from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import ButtonLink from '../../Common/Button/ButtonLink/ButtonLink';
import { IconNames } from '../../Common/Icon/newIcons-types';
import { ButtonProps } from '../../Common/Button/types';

import {
  setProductForGetAQuoteLink,
  setDataForProductQuote,
  setIsLinkForQuote,
  setIsLinkForExpert
} from '../../../store/product/actions';
import { connect } from 'react-redux';
import { ProductItem } from '../../Product/types';
import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../Common/CookieSettings/CookieConstants';

export type FindADealerActionsStatePropsFromRedux = {
  productItems: ProductItem[];
  selectedVariant?: ProductItem;
  title: string;
  masterProductData: any;
};

export type FindADealerActionsDispatchPropsFromRedux = {
  setProductForGetAQuoteLink: (link: any) => void;
  setDataForProductQuote: (productData: any) => void;
  setIsLinkForQuote: (isLink: boolean) => void;
  setIsLinkForExpert: (isLink: boolean) => void;
};

export type FindADealerActionsComponentProps = {
  hasFavouriteContact: boolean;
  getAQuoteLink: string;
  dealerLink: LinkField;
  expertLink?: LinkField;
  // Forced on accessories page
  forceGetAQuote?: boolean;
  accesoriesArticleNumber?: string;
  shouldRenderShop: boolean;
};

export type FindADealerActionsProps = FindADealerActionsStatePropsFromRedux &
  FindADealerActionsDispatchPropsFromRedux &
  FindADealerActionsComponentProps;

const FindADealerActions = ({
  productItems,
  hasFavouriteContact = false,
  getAQuoteLink,
  dealerLink,
  selectedVariant,
  expertLink,
  forceGetAQuote = false,
  setProductForGetAQuoteLink,
  setDataForProductQuote,
  setIsLinkForQuote,
  setIsLinkForExpert,
  masterProductData,
  title,
  accesoriesArticleNumber,
  shouldRenderShop
}: FindADealerActionsProps): JSX.Element => {
  const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase() || 'de';
  const hasExpertLink = expertLink && expertLink?.value?.href;
  const hasProductItems = productItems?.length > 0;
  const isLinkDisabled = !forceGetAQuote && productItems?.length > 0 && !selectedVariant;
  let linkLabel = '';
  let linkField = null as LinkField | null;
  let infoText = 'Before you can proceed, please select a product variant.';
  let linkIcon = 'icon-web-16-map-pin' as IconNames;
  let linkClickCallback = null as ((value: boolean) => void) | null;
  let buttonLinkVariant = 'secondary' as ButtonProps['variant'];

  const getMeasuringClassname = () => {
    let measuringClassname = '';

    if (forceGetAQuote) {
      measuringClassname = masterProductData && masterProductData.MasterProductENName + accesoriesArticleNumber;
    } else if (selectedVariant) {
      measuringClassname = masterProductData && masterProductData.MasterProductENName + selectedVariant.articleNumber;
    } else {
      measuringClassname = masterProductData && masterProductData.MasterProductENName;
    }
    return removeWhiteSpaces(measuringClassname);
  };

  const getAQuoteClickMethods = () => {
    const { media, Solutions } = masterProductData;
    const imgUrl = media && media[0] && media[0].url;

    if (forceGetAQuote) {
      const accessoryArticleNumber = accesoriesArticleNumber,
        articleNumber = accessoryArticleNumber ? accessoryArticleNumber.replace('-', '.') : '',
        productDataForQuote = {
          name: title,
          articleNo: articleNumber,
          picture: imgUrl,
          solutions: Solutions || []
        };
      setDataForProductQuote(productDataForQuote);
    } else if (selectedVariant) {
      const productDataToQuote = {
        name: title,
        articleNo: selectedVariant.articleNumber,
        picture: selectedVariant.media && selectedVariant.media[0] && selectedVariant.media[0].url,
        solutions: selectedVariant.solutions
      };

      setDataForProductQuote(productDataToQuote);
    } else if (productItems && productItems.length === 0) {
      const productDataToQuote = {
        name: title,
        picture: imgUrl,
        solutions: Solutions || []
      };
      setDataForProductQuote(productDataToQuote);
    } else {
      setProductForGetAQuoteLink(null);
      setDataForProductQuote(null);
    }
  };

  if (forceGetAQuote || (hasProductItems && selectedVariant)) {
    linkClickCallback = setIsLinkForQuote;

    if (hasFavouriteContact) {
      linkIcon = 'icon-web-16-envelope';
      // This is the case for shop and no shop for product item with favourite contact
      // label of a button is the same for both cases
      // With shop: case 4
      // Without shop: case 8
      linkLabel = i18n.t('SHOP | Get a quote link text');
      linkField = {
        value: {
          href: getAQuoteLink
        }
      };
      // But description text is different
      infoText = shouldRenderShop
        ? i18n.t('SHOP | Get a quote your dealer description text') // text from scenario 4
        : i18n.t('SHOP | Get a quote description text no shop'); // text from scenario 8
    } else {
      // This is the case for shop and no shop for product item without favourite contact
      // With shop: case 2
      // Without shop: case 6
      linkLabel = shouldRenderShop ? i18n.t('SHOP | Get a quote link text') : i18n.t('SHOP | Find a dealer link text');
      linkField = shouldRenderShop
        ? {
            value: {
              href: getAQuoteLink
            }
          }
        : dealerLink;
      infoText = shouldRenderShop
        ? i18n.t('SHOP | Get a quote description text', {
            supplier: i18n.t(`SHOP_${countryCode?.toUpperCase()} | Supplier`)
          }) // text from scenario 2
        : i18n.t('SHOP | Find a dealer description text no shop'); // text from scenario 6
    }
  }

  if (!forceGetAQuote && !hasProductItems && hasExpertLink) {
    buttonLinkVariant = 'primary';
    linkClickCallback = setIsLinkForExpert;

    if (hasFavouriteContact) {
      // This is the case for shop and no shop for product (NOT product item) with favourite contact
      // With shop: case 3
      // Without shop: case 7
      linkLabel = i18n.t('SHOP | Talk to your expert link text');
      linkField = expertLink;
      infoText = shouldRenderShop
        ? i18n.t('SHOP | Talk to your expert description text') // text from scenario 3
        : i18n.t('SHOP | Talk to your expert description text no shop'); // text from scenario 7
    } else {
      // This is the case for shop and no shop for product (NOT product item) without favourite contact
      // With shop: case 1
      // Without shop: case 5
      linkLabel = i18n.t('SHOP | Talk to an expert link text');
      linkField = shouldRenderShop ? expertLink : dealerLink;
      infoText = shouldRenderShop
        ? i18n.t('SHOP | Talk to an expert description text', {
            supplier: i18n.t(`SHOP_${countryCode?.toUpperCase()} | Supplier`)
          }) // text from scenario 1
        : i18n.t('SHOP | Find an expert description text no shop'); // text from scenario 5
    }
  }

  return (
    <div
      className={classNames('FindADealer', {
        'FindADealer--important': buttonLinkVariant === 'primary'
      })}
    >
      {infoText && <div className='FindADealer__find-a-dealer-info'>{infoText}</div>}
      <div
        className={classNames('FindADealer__button-link', {
          'FindADealer__link--disabled': isLinkDisabled
        })}
      >
        {isLinkDisabled && (
          <ButtonLink
            icon={linkIcon}
            text={i18n.t('SHOP | Find a dealer link text')}
            disabled
            variant='secondary'
            size='large'
            tooltip={i18n.t('SHOP | Please choose product variant first')}
          />
        )}
        {linkLabel && linkField && (
          <div
            onClick={() => {
              getAQuoteClickMethods();
              linkClickCallback?.(true);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                getAQuoteClickMethods();
                linkClickCallback?.(true);
              }
            }}
            role='button'
            tabIndex={0}
          >
            <ButtonLink
              icon={linkIcon}
              link={linkField}
              text={linkLabel}
              variant={buttonLinkVariant}
              size='large'
              className={'click_button_get_quote_pdp ' + removeWhiteSpaces(getMeasuringClassname() ?? '')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any): FindADealerActionsStatePropsFromRedux => {
  return {
    productItems: state.productDetails ? state.productDetails.variants : null,
    selectedVariant: state.productDetails.selectedVariant,
    title: state.productDetails.masterProductData ? state.productDetails.masterProductData.MasterProductName : null,
    masterProductData: state.productDetails.masterProductData ? state.productDetails.masterProductData : null
  };
};

const mapDispatchToProps = (dispatch: any): FindADealerActionsDispatchPropsFromRedux => {
  return {
    setProductForGetAQuoteLink: (link: any) => dispatch(setProductForGetAQuoteLink(link)),
    setDataForProductQuote: (productData: any) => dispatch(setDataForProductQuote(productData)),
    setIsLinkForQuote: (isLink: boolean) => dispatch(setIsLinkForQuote(isLink)),
    setIsLinkForExpert: (isLink: boolean) => dispatch(setIsLinkForExpert(isLink))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FindADealerActions);
export { FindADealerActions };
