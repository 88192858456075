import {
  ADDRESS_LIST_ENDPOINT,
  CREATE_ADDRESS_ENDPOINT,
  DELETE_ADDRESS_ENDPOINT,
  UPDATE_ADDRESS_ENDPOINT
} from '../../components/Product/productendpoint';
import * as ActionTypes from './actionTypes';

export const toggleMobileMyAccount = () => {
  return {
    type: ActionTypes.TOGGLE_MOBILE_MY_ACCOUNT_MENU
  };
};

export const addressListData = endpoint => {
  return dispatch => {
    dispatch(setAddressListLoadingAction());
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          dispatch(setAddressListLoadingErrorAction());
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setAddressListLoadedAction(responseJson));
      })
      .catch(error => {
        dispatch(setAddressListLoadingErrorAction());
        throw new Error('Something went wrong');
      });
  };
};

export const createAddress = address => {
  return dispatch => {
    fetch(CREATE_ADDRESS_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(address)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        if (responseJson) {
          dispatch(addressListData(ADDRESS_LIST_ENDPOINT));
        }
      })
      .catch(err => {
        throw new Error('Something went wrong');
      });
  };
};

export const updateAddress = address => {
  return dispatch => {
    fetch(UPDATE_ADDRESS_ENDPOINT, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(address)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        if (responseJson) {
          dispatch(addressListData(ADDRESS_LIST_ENDPOINT));
        }
      })
      .catch(err => {
        throw new Error('Something went wrong');
      });
  };
};

export const setAddressListLoadingAction = () => {
  return {
    type: ActionTypes.SET_ADDRESS_LIST_LOADING
  };
};

export const setAddressListLoadedAction = addressList => {
  return {
    type: ActionTypes.SET_ADDRESS_LIST_LOADED,
    addressList
  };
};

export const setAddressListLoadingErrorAction = () => {
  return {
    type: ActionTypes.SET_ADDRESS_LIST_LOAD_ERROR
  };
};

export const deleteAddress = addressId => {
  return dispatch => {
    const endpoint = DELETE_ADDRESS_ENDPOINT(addressId);
    if (endpoint) {
      fetch(endpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          if (responseJson) {
            dispatch(deleteAddressOnRedux(addressId));
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

const deleteAddressOnRedux = addressId => {
  return {
    type: ActionTypes.DELETE_ADDRESS,
    addressId
  };
};
