import { shopifyFetch } from './shopifyFetch';
import {
  ShopifyCustomerAccessTokenCreateWithMultipassOperation,
  ShopifyGetCustomerWithOrderDetailsUsingCustomerAccessTokenOperation,
  ShopifyGetCustomerWithOrdersUsingCustomerAccessTokenOperation,
  ShopifyOrder,
  ShopifyOrderDetails
} from './types';

// Simple implementation which allows us to use a ENV variable to enable/disable debug logging
const DEBUG = process.env.REACT_APP_ENABLE_DEBUG || false;

export async function getCustomerAccessToken(multipassToken: string): Promise<string | undefined> {
  const res = await shopifyFetch<ShopifyCustomerAccessTokenCreateWithMultipassOperation>({
    query: `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
              customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
                customerAccessToken {
                  accessToken
                  expiresAt
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
            }`,
    variables: { multipassToken }
  });

  const result = res.body.data;
  DEBUG && console.log('[orderHelpers] getCustomerAccessToken: ', result);

  return result.customerAccessTokenCreateWithMultipass?.customerAccessToken?.accessToken;
}

export async function getCustomerOrders(customerAccessToken: string): Promise<ShopifyOrder[] | undefined> {
  const res = await shopifyFetch<ShopifyGetCustomerWithOrdersUsingCustomerAccessTokenOperation>({
    query: `query getOrders($customerAccessToken: String!) {
              customer(customerAccessToken: $customerAccessToken) {
                orders(first: 250) {
                  totalCount
                  nodes {
                    id
                    orderNumber
                    processedAt
                    financialStatus
                    fulfillmentStatus
                    totalPrice {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }`,
    variables: { customerAccessToken }
  });

  const result = res.body.data;
  DEBUG && console.log('[orderHelpers] getCustomerOrders: ', result);

  return result.customer.orders.nodes;
}

export async function getOrderDetails(
  customerAccessToken: string,
  orderId: string
): Promise<ShopifyOrderDetails | undefined> {
  const res = await shopifyFetch<ShopifyGetCustomerWithOrderDetailsUsingCustomerAccessTokenOperation>({
    query: `query getOrders($customerAccessToken: String!, $orderQuery: String) {
    customer(customerAccessToken: $customerAccessToken) {
        orders(first: 1, query: $orderQuery) {
            nodes {
                id
                orderNumber
                processedAt
                financialStatus
                fulfillmentStatus
                totalPrice {
                    amount
                    currencyCode
                }
                totalTax {
                    amount
                    currencyCode
                }
                subtotalPrice {
                    amount
                    currencyCode
                }
                currentSubtotalPrice {
                    amount
                    currencyCode
                }
                totalShippingPrice {
                    amount
                    currencyCode
                }
                totalPrice {
                    amount
                    currencyCode
                }
                originalTotalPrice {
                    amount
                    currencyCode
                }
                billingAddress {
                    id
                    address1
                    address2
                    city
                    company
                    country
                    countryCodeV2
                    firstName
                    formatted(withName: true, withCompany: true)
                    formattedArea
                    lastName
                    name
                    phone
                    province
                    provinceCode
                    zip
                    latitude
                    longitude
                }
                shippingAddress {
                    id
                    address1
                    address2
                    city
                    company
                    country
                    countryCodeV2
                    firstName
                    formatted(withName: true, withCompany: true)
                    formattedArea
                    lastName
                    name
                    phone
                    province
                    provinceCode
                    zip
                    latitude
                    longitude
                }
                lineItems(first: 250) {
                    nodes {
                        title
                        variant {
                            id
                            sku
                            image {
                                url
                            }
                        }
                        currentQuantity
                        quantity
                        originalTotalPrice {
                            amount
                            currencyCode
                        }
                        discountedTotalPrice {
                            amount
                            currencyCode
                        }
                    }
                }
            }
        }
    }
}
`,
    variables: { customerAccessToken: customerAccessToken, orderQuery: `id:${orderId}` }
  });

  const result = res.body.data;
  DEBUG && console.log('[orderHelpers] getOrderDetails: ', result);

  return result?.customer?.orders?.nodes?.[0];
}
