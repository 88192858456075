import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../GeneralLink/index';

class StickyMenuItem extends React.Component {
  render() {
    return (
      <GeneralLink fields={this.props.fields['Anchor Link']}>
        <Text field={this.props.fields['Anchor Title']} />
      </GeneralLink>
    );
  }
}

export default StickyMenuItem;
