import { FacetValue, Facet as HeadlessFacet } from '@coveo/headless';
import React, { useContext, useEffect, useState } from 'react';
import { SearchContext, ActionType } from '../context/SearchContext';
import Facet, { FacetOption } from '../Facet';
import { scrollToTopOfSearchResults } from '../SearchResults/scrollToSearchResults';

type CategoryFacetProps = {
  controller: HeadlessFacet;
  title: string;
  id: string;
  multi?: boolean;
  needToHaveMinimumTwoOptions?: boolean;
  isVisible?: boolean;
  sort?: (a: FacetOption, b: FacetOption) => number;
  optionsLabelConverter?: (label: string) => string;
  hasFilter?: boolean;
};

export const CategorFacet = ({
  id,
  controller,
  title,
  needToHaveMinimumTwoOptions,
  isVisible,
  multi = true,
  sort,
  optionsLabelConverter = text => text,
  hasFilter = false
}: CategoryFacetProps): JSX.Element => {
  const { dispatch } = useContext(SearchContext);
  const [state, setState] = useState(controller.state);
  const [selectedValuesText, setSelectedValuesText] = useState('');

  const [options, setOptions] = useState<FacetOption[]>([]);
  useEffect(() => controller.subscribe(() => setState(controller.state)), []);
  useEffect(() => {
    const selected = state.values.filter(value => controller.isValueSelected(value));

    setSelectedValuesText(selected.map(value => optionsLabelConverter(value.value)).join(', '));
    const selectedValues = selected.map(value => value.value);

    if (state.values.length) {
      const values = state.values
        .filter(value => (multi ? true : selectedValues.length ? selectedValues.includes(value.value) : true))
        .map(value => ({
          value: value,
          label: optionsLabelConverter(value.value),
          numberOfResults: value.numberOfResults,
          selected: controller.isValueSelected(value)
        }));

      // Apply sorting if provided
      if (sort) {
        values.sort(sort);
      }

      setOptions(values);
    } else {
      setOptions([]);
    }

    dispatch({
      type: ActionType.UPDATE_FACET_SELECTED_COUNT,
      payload: {
        id,
        selected
      }
    });
  }, [state]);

  function toggleSelect(value: FacetValue) {
    controller.toggleSelect(value);
    scrollToTopOfSearchResults();
  }

  return (
    <Facet
      isLoading={state.isLoading}
      options={options}
      title={title}
      selectedValuesText={selectedValuesText}
      toggleSelect={toggleSelect}
      needToHaveMinimumTwoOptions={needToHaveMinimumTwoOptions}
      isVisible={isVisible}
      hasFilter={hasFilter}
    />
  );
};

export default CategorFacet;
