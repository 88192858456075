import React from 'react';
import Carousel from 'react-alice-carousel';
import ArrowRight from '../../Common/Icons/ArrowRight';
import ArrowLeft from '../../Common/Icons/ArrowLeft';

class ProductsCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      itemsInSlide: this.props.isMobile ? 1 : 4,
      responsive: { 0: { items: 1 }, 960: { items: 4 } },
      galleryItems: this.props.children
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children) {
      const newKeys = this.props.children.map(child => child.key);

      const oldKeys = prevProps.children.map(child => child.key);
      if (
        JSON.stringify(newKeys) !== JSON.stringify(oldKeys) ||
        prevProps.isLightBoxOpen !== this.props.isLightBoxOpen
      ) {
        this.setState({
          galleryItems: this.props.children
        });
      }
    }
  }

  slidePrevPage = () => {
    const { currentIndex, itemsInSlide } = this.state;
    if (currentIndex !== 0) {
      const nextItemIndex = currentIndex - itemsInSlide >= 0 ? currentIndex - itemsInSlide : 0;
      this.setState({
        currentIndex: nextItemIndex
      });
    }
  };

  slideNextPage = () => {
    const { itemsInSlide, currentIndex, galleryItems } = this.state;
    let nextItemIndex = currentIndex + itemsInSlide;
    if (nextItemIndex < galleryItems.length) {
      this.setState({
        currentIndex: nextItemIndex
      });
    }
  };

  checkIfIsTheLastIndex() {
    const { currentIndex, galleryItems } = this.state;
    if (!this.props.isMobile) {
      return currentIndex + 4 >= galleryItems.length ? true : false;
    } else {
      return galleryItems.length - 1 >= 0 && currentIndex === galleryItems.length - 1 ? true : false;
    }
  }

  handleOnSlideChange = event => {
    const { itemsInSlide, item } = event;
    this.setState({ itemsInSlide, currentIndex: item });
  };

  render() {
    const { currentIndex, responsive, galleryItems } = this.state;
    if (!galleryItems || galleryItems.length < 1) {
      return null;
    }
    const arrowLeftDisabled = currentIndex === 0 ? 'ArrowDisabled' : '';
    const arrowRightDisabled = this.checkIfIsTheLastIndex() ? 'ArrowDisabled' : '';
    return (
      <React.Fragment>
        <div className='ProductsCarousel'>
          <div
            className={'ProductsCarousel-ArrowLeft ' + arrowLeftDisabled}
            onClick={this.slidePrevPage}
            onKeyDown={e => {
              if (e.keyCode === 13) this.slidePrevPage();
            }}
            role='button'
            tabIndex='0'
          >
            <ArrowLeft />
          </div>
          <Carousel
            infinite={false}
            items={galleryItems}
            dotsDisabled={this.props.disableDots ? this.props.disableDots : false}
            slideToIndex={currentIndex}
            responsive={responsive}
            onInitialized={this.handleOnSlideChange}
            onSlideChanged={this.handleOnSlideChange}
            onResized={this.handleOnSlideChange}
          />
          <div
            className={'ProductsCarousel-ArrowRight ' + arrowRightDisabled}
            onClick={() => this.slideNextPage()}
            onKeyDown={e => {
              if (e.keyCode === 13) this.slideNextPage();
            }}
            role='button'
            tabIndex='0'
          >
            <ArrowRight />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProductsCarousel;
