import React from 'react';
import CloseIcon from '../../../Common/Icons/CloseIcon';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../../Common/GeneralLink';
import { connect } from 'react-redux';
import { setCurrentComparedCategory } from '../../../../store/compare/actions';

class CompareBox extends React.Component {
  updateSelectedCategory(categoryEn) {
    if (categoryEn) {
      this.props.updateSelectedCategory(categoryEn);
    }
  }
  render() {
    const {
        'Compare Card Title': compareCardTitle,
        'Compare Card Text': compareCardText,
        'Compare Card Button Label': compareCardButtonLabel,
        'Compare Page Link': linkComparePage,
        'Compare Card Text Reached Limit': compareCardTextReachedLimit
      } = this.props.fields,
      { isCompareOpen, onClose, handleClickOutside, checkedNoOfProducts, categoryEn, shouldDisplay, subCategoryEn } =
        this.props,
      cssWishlistCardClass = classNames('CompareBox', {
        'CompareBox-Active': isCompareOpen
      }),
      cardText = checkedNoOfProducts ? compareCardText : compareCardTextReachedLimit,
      compareCategory = shouldDisplay ? subCategoryEn : categoryEn;
    return (
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <div className={cssWishlistCardClass}>
          <div className='CompareBox-Heading'>
            <Text field={compareCardTitle} />
            <CloseIcon
              onClick={onClose}
              onKeyDown={e => {
                if (e.keyCode === 13) onClose();
              }}
              role='button'
              tabIndex='0'
            />
          </div>
          <Text field={cardText} tag='div' className='CompareBox-Text' />
          <div
            onClick={() => this.updateSelectedCategory(compareCategory)}
            onKeyDown={e => {
              if (e.keyCode === 13) this.updateSelectedCategory(compareCategory);
            }}
            role='button'
            tabIndex='0'
          >
            <GeneralLink className='CompareBox-AddButton' fields={linkComparePage}>
              <Text field={compareCardButtonLabel} />
            </GeneralLink>
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedCategory: category => dispatch(setCurrentComparedCategory(category))
  };
};

export default connect(null, mapDispatchToProps)(CompareBox);
