import React from 'react';
import LinkerCarouselComponent from '../index';
import { WELDY_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class WELDYLinkerCarousel extends React.Component {
  render() {
    return <LinkerCarouselComponent {...this.props} urlToUse={WELDY_PAGE_LINK} />;
  }
}

export default WELDYLinkerCarousel;
