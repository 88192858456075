import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsExtrusionWeldersPipelines,
  CoveoProductsExtrusionWeldersSearchHubs
} from './ExtrusionWeldersLister.settings';
import ExtrusionWeldersListerComponent from './ExtrusionWeldersLister';
import CoveoComponent from '../../../CoveoComponent';

export const ExtrusionWeldersLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsExtrusionWeldersPipelines[siteName],
    searchHub: CoveoProductsExtrusionWeldersSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <ExtrusionWeldersListerComponent />
    </CoveoComponent>
  );
};

export default ExtrusionWeldersLister;
