import { Tab } from '@coveo/headless';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActionType, SearchContext } from '../context/SearchContext';
import classNames from 'classnames';

export type SearchResultTabProps = {
  id: string;
  value: string[];
  caption: string;
  controller: Tab;
  formatCount: (count: number) => string;
};

export const SearchResultTab = ({ id, caption, value, controller, formatCount }: SearchResultTabProps): JSX.Element => {
  const { state: searchState, dispatch } = useContext(SearchContext);
  const [resultsCount, setResultsCount] = useState<number | null>(null);
  const tabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setResultsCount(
      searchState?.tabResultsCount?.reduce((acc, curr) => {
        return value.includes(curr.value) ? acc + curr.numberOfResults : acc;
      }, 0)
    );
  }, [searchState?.tabResultsCount]);

  const [state, setState] = useState(controller.state);

  useEffect(
    () =>
      controller.subscribe(() => {
        if (controller.state.isActive) {
          dispatch({
            type: ActionType.SET_ACTIVE_TAB,
            payload: { id }
          });
        }

        setState(controller.state);
      }),
    []
  );

  useEffect(() => {
    if (state.isActive) {
      tabRef.current?.focus();
    }
  }, [state]);

  return (
    <button
      className={classNames(
        'SearchResultTab',
        { 'SearchResultTab--active': state.isActive },
        { 'ScrollSnap--focus': state.isActive }
      )}
      title={caption}
      onClick={() => controller.select()}
      ref={tabRef}
    >
      <span className='SearchResultTab__Text'>{caption}</span>
      {resultsCount !== null && resultsCount >= 0 ? (
        <span className='SearchResultTab__Text tab-count'>{formatCount(resultsCount)}</span>
      ) : null}
    </button>
  );
};

export default SearchResultTab;
