import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  initialData: [],
  selectedCategory: typeof window !== 'undefined' ? window?.localStorage?.getItem('compareCategory') || null : null
};

/**
 * @method compareProduct
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const compareProduct = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_DATA:
      return {
        ...state,
        initialData: action.initialData
      };
    case actionTypes.SET_CURRENT_COMPARED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory
      };
    default:
      return state;
  }
};
