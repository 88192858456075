import React from 'react';
import UniversalImageSlider from '../UniversalImageSlider';
import { LAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

export default class LAGImageSlider extends React.Component {
  render() {
    return (
      <UniversalImageSlider
        leftImageWidth={84}
        imageClass='ImageSlider-Container-Multiple WiderImage'
        fields={this.props.fields}
        urlToUse={LAG_PAGE_LINK}
      />
    );
  }
}
