import imageFragment from './image';
import { variantsOnProductFragment } from './variant';
import deliveryTimeFragment from './deliveryTime';
import conditionFragment from './condition';

/**
 * Fragment for the product without variants
 *
 * Be careful when applying this multiple times into a single query, as we do in getProductVariantsByGidsQuery.
 * The fragment itself can only be included once, otherwise the query will fail. This includes all other fragments like image, deliveryTime and condition.
 */
export const productWithoutVariantsFragment = /* GraphQL */ `
  fragment productWithoutVariants on Product {
    id
    handle
    availableForSale
    ...deliveryTime
    ...condition
    title
    featuredImage {
      ...image
    }
  }
  ${imageFragment}
  ${deliveryTimeFragment}
  ${conditionFragment}
`;

/**
 * Fragment for the product with variants
 */
const productFragment = /* GraphQL */ `
  fragment product on Product {
    ...productWithoutVariants
    ...variants
  }
  ${productWithoutVariantsFragment}
  ${variantsOnProductFragment}
`;

export default productFragment;
