import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useClickAway, useDebounce } from 'react-use';
import { getCoveoProductsSource } from '../../../AppGlobals';
import { useCoveoQuery } from '../CoveoQuery';
import { SearchEngineContext } from '../context/SearchEngineContext';
import SuggestionResultItem, { SuggestionInstantResultsProperties } from '../SuggestionResultItem';
import { getHashParam } from '../UrlManager';
import { AccessoriesOmniboxProps } from '.';
import { CoveoContext } from '../context/CoveoContext';
import { getResultTitle } from '../ResultItemUtils';
import { Result } from '@coveo/headless';
import Autosuggest from '../../Common/Autosuggest/Autosuggest';
import SearchIcon from '../../Common/Icons/SearchIcon';
import i18n from 'i18next';

const NumberOfMinCharsForInstantSearch = 3;

export const AccessoriesOmniboxComponent: React.FC<AccessoriesOmniboxProps> = ({ selectAccessory }) => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);
  const coveoProductsSource = getCoveoProductsSource();
  const { instantResultsController, searchBoxController } = useCoveoQuery({
    engine,
    fieldsToInclude: SuggestionInstantResultsProperties,
    initUrlManager: false,
    cq: '',
    aq: `@tenant=="${siteName}" AND @z95xlanguage=="${locale}" AND (
      (@source=="${coveoProductsSource}"))`
  });

  const [query, setQuery] = useState('');
  const [selectedAccessory, setSelectedAccessory] = useState('');
  const [, cancel] = useDebounce(
    () => {
      searchBoxController.updateText(query);
    },
    250,
    [query]
  );
  const omniboxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [instantResultsState, setStateInstantResultsState] = useState(instantResultsController.state);
  const [instantResultsOpened, setInstantResultsOpened] = useState(false);
  const [, setSearchBox] = useState(searchBoxController.state);

  const handleInstantResultsState = useCallback(() => {
    if (instantResultsController.state.results.length > 0) {
      setInstantResultsOpened(true);
    } else {
      setInstantResultsOpened(false);
    }

    setStateInstantResultsState(instantResultsController.state);
  }, [instantResultsController]);

  useEffect(() => {
    searchBoxController.subscribe(() => setSearchBox(searchBoxController.state));
  }, [searchBoxController]);

  useEffect(() => {
    const selectedAccessoryName = getHashParam('accessoryName');

    setSelectedAccessory(selectedAccessoryName || '');
  }, []);

  // closing instant results on click outside
  useClickAway(omniboxRef, () => {
    if (instantResultsOpened) {
      setInstantResultsOpened(false);
    }
  });

  // close instant results on empty query
  useEffect(() => {
    if (!query) {
      setInstantResultsOpened(false);
    }
  }, [query]);

  // subscribe to searchbox controller - update instant results controller on searchbox change
  useEffect(
    () =>
      searchBoxController.subscribe(() => {
        // if query is different than searchbox query - it means that standaloneSearchBoxController got it's value form URL query, no need to process anything, as it's not user input
        if (inputRef?.current?.value !== searchBoxController.state.value) {
          return;
        }

        setSearchBox(searchBoxController.state);

        // if query is longer than 3 chars - check instant results state
        if (searchBoxController.state.value.length >= NumberOfMinCharsForInstantSearch) {
          // if query is different than instant results query - update instant results query
          if (instantResultsController.state.q !== searchBoxController.state.value) {
            instantResultsController.updateQuery(searchBoxController.state.value);
          } else {
            // if query is the same - try to use previous results
            handleInstantResultsState();
          }
        } else {
          // if query is shorter than 3 chars - hide instant results
          setInstantResultsOpened(false);
        }
      }),
    [searchBoxController, setSearchBox, instantResultsController, handleInstantResultsState]
  );

  // subscribe to instant results controller - on results toggle instant results flyout
  useEffect(
    () => instantResultsController.subscribe(() => handleInstantResultsState()),
    [instantResultsController, setStateInstantResultsState, handleInstantResultsState]
  );

  // handle clear input
  const clearInput = () => {
    setQuery('');
    setSelectedAccessory('');
    selectAccessory('', '');
    searchBoxController.updateText('');
    cancel();
  };

  const clearInputAndFocus = () => {
    clearInput();

    inputRef?.current?.focus();
  };

  // handle input change - ony key down
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAccessory('');
    setQuery(e.target.value);
  };

  return (
    <div className='PageOmnibox__background-wrapper'>
      <div className='PageOmnibox' ref={omniboxRef}>
        <div className='PageOmnibox__wrapper'>
          <Autosuggest
            componentName='PageOmnibox'
            inputIcon={<SearchIcon className='PageOmnibox__search-icon' />}
            handleInputChange={handleInputChange}
            inputRef={inputRef}
            instantResultsOpened={instantResultsOpened}
            instantResultsState={instantResultsState}
            value={selectedAccessory || query}
            preselectedSuggestion={0}
            minCharsToSearch={NumberOfMinCharsForInstantSearch}
            renderItem={(result: Result, selected: boolean) => (
              <SuggestionResultItem
                selected={selected}
                raw={result.raw}
                selectAccessory={selectAccessory}
                onClick={(accessoryName: string) => {
                  setSelectedAccessory(accessoryName);
                  setInstantResultsOpened(false);
                }}
              />
            )}
            setInstantResultsOpened={setInstantResultsOpened}
            submit={() => {
              if (instantResultsState.results.length > 0) {
                const { raw } = instantResultsState.results[0];

                selectAccessory(raw.pimproductid as string, getResultTitle(raw));
                setSelectedAccessory(getResultTitle(raw));
                setInstantResultsOpened(false);
              }
            }}
            clearInputAndFocus={clearInputAndFocus}
            onSelect={(selectedResult: Result) => {
              const raw = selectedResult.raw;

              selectAccessory(raw.pimproductid as string, getResultTitle(raw));
              setSelectedAccessory(getResultTitle(raw));
              setInstantResultsOpened(false);
            }}
            placeholderText={i18n.t('SEARCH | Accessories Searchbox placeholder')}
          />
        </div>
      </div>
    </div>
  );
};

export default AccessoriesOmniboxComponent;
