import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import FooterGeneralLink from '../Footer General Link';
import { FooterColumnProps } from './types';

/**
 * @class Component that renders a column for the Footer component.
 */
const FooterColumn = ({ fields, itemFields }: FooterColumnProps) => (
  <div className='FooterColumn'>
    {fields && (
      <>
        <Text field={fields.ColumnHeadline} tag='span' className='FooterColumn-title' />
        {itemFields.map(element => (
          <FooterGeneralLink fields={element.fields} key={element.id} />
        ))}
      </>
    )}
  </div>
);

export default FooterColumn;
