import React from 'react';
import GeneralLink from '../../../../GeneralLink';
import ArrowRight from '../../../../Icons/ArrowRight';
import { EventLinkBoothProps } from '../types';
import { getLinkLabel } from './LinkUtils';

export const EventLinkBooth = ({ eventLinkBooth }: EventLinkBoothProps) => {
  const label = getLinkLabel(eventLinkBooth);

  return label ? (
    <div className='EventInformationSection-LinkRow' data-testid='event-booth-link'>
      {/*
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
      <GeneralLink className='LinkElement-Link' fields={eventLinkBooth}>
        {label}
        <ArrowRight />
      </GeneralLink>
    </div>
  ) : null;
};
