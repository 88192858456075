import React from 'react';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import { deleteSessionStorage } from '../../Common/CookieSettings/CookieUtils';
import { setContactPersonData } from '../../../store/contactPerson/actions';

/**
 * @class Component that renders the menu information.
 *
 * @param {object} props Object containing the data (links and placeholders) needed for the component.
 *
 * Returns HTML.
 */
const MenuInfo = props => {
  const { TopMenuLinks } = props.rendering.fields,
    onContactClick = link => {
      deleteSessionStorage('contactPointInfoId');
      deleteSessionStorage('person');
      props.resetContactPersonData();
      window.location.href = link.value.href;
    };
  let content = TopMenuLinks.map((item, index) => {
    const { Link, 'Text Link': textLink } = item.fields;
    if (Link.value.href.toLowerCase().includes('contact')) {
      return (
        <div
          key={index + item.id}
          onClick={() => {
            onContactClick(Link);
          }}
          onKeyDown={e => {
            if (e.target === 13) {
              onContactClick(Link);
            }
          }}
          role='button'
          tabIndex='0'
          className='MenuInfo-item'
        >
          <Text field={textLink} />
        </div>
      );
    }
    return (
      <div key={index + item.id} className='MenuInfo-item'>
        <RoutableSitecoreLink link={Link}>
          <Text field={textLink} />
        </RoutableSitecoreLink>
      </div>
    );
  });
  return (
    <div className='MenuInfo'>
      {content}
      <div className='MenuInfo-item'>
        <Placeholder name='select-language' rendering={props.rendering} />
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    resetContactPersonData: () => dispatch(setContactPersonData(null))
  };
};
export default connect(null, mapDispatchToProps)(MenuInfo);
