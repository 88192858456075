import React from 'react';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import {
  toggleWishlist,
  setAmountOfWishlistItemsAnonymousUser,
  addProductToWishList
} from './../../../store/wishlist/actions';
import PrintIcon from '../../Common/Icons/PrintIcon';
import ShareIcon from '../../Common/Icons/ShareIcon';
import GreenDotCheckmarkIcon from '../../Common/Icons/GreenDotCheckmarkIcon';
import ShareBox from './ShareBox';
import WishlistBox from './WishlistBox';
import {
  setProductToWishlistOnCookie,
  setProductToCompareOnCookie,
  getCookie,
  getRawValueFromCompare
} from './../../Common/CookieSettings/CookieUtils';
import { checkIfUserIsLoggedIn, removeWhiteSpaces } from '../../../utils/Utils';
import { WISHLIST, COMPARE } from './../../Common/CookieSettings/CookieConstants';
import WishlistButton from './WishlistButton';
import CompareBox from './CompareBox';
import CompareIcon from '../../Common/Icons/CompareIcon';

class HeroActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShareOpen: false,
      isWishlistOpen: false,
      isCompareOpen: false
    };
  }

  getTooltip(label, labelClass, openBox) {
    if (label && label.value && !openBox) {
      return <span className={'HeroActionButton' + labelClass}>{label.value}</span>;
    }
  }

  getCompareButton = (label, compareMethod, category) => {
    const { masterProductData, fields, shouldDisplay } = this.props,
      cookieCompare = getCookie(COMPARE),
      cookieCompareList = cookieCompare ? JSON.parse(cookieCompare) : {},
      productCategory = category ? category.split(',') : [],
      check = productCategory.find(
        item =>
          (masterProductData && cookieCompareList[item] && cookieCompareList[item].length < 4) ||
          (masterProductData && !cookieCompareList[item])
      ),
      measuringClassname = this.getMeasuringClassname();
    return (
      <React.Fragment>
        <div
          className={
            'HeroActionButton-Icon HeroActionButton-Compare button_product_comparison_pdp ' + measuringClassname
          }
          onClick={() => {
            compareMethod();
          }}
          onKeyDown={e => {
            if (e.target === 13) compareMethod();
          }}
          role='button'
          tabIndex='0'
          aria-label='Open/Close Compare Card'
        >
          <CompareIcon />

          {this.getTooltip(label, '-CompareLabel', this.state.isCompareOpen)}
        </div>
        <CompareBox
          fields={fields}
          isCompareOpen={this.state.isCompareOpen}
          handleClickOutside={this.handleCompareClickOutside}
          onClose={this.onCompareClick}
          checkedNoOfProducts={check}
          categoryEn={masterProductData && masterProductData.CategoryEn}
          subCategoryEn={masterProductData && masterProductData.SubCategoryEn}
          shouldDisplay={shouldDisplay}
        />
      </React.Fragment>
    );
  };

  checkProductInCompareList = id => {
    const rawCompareList = getRawValueFromCompare();
    return rawCompareList.find(item => item === id);
  };

  getCheckCompareAlreadyAdded = () => {
    const { masterProductData, selectedVariant, shouldDisplay, accessoriesId } = this.props;
    let id = 0;

    if (shouldDisplay) {
      id = accessoriesId;
    } else if (selectedVariant) {
      id = selectedVariant.id;
    } else {
      id = masterProductData && masterProductData.MasterProductID;
    }
    return this.checkProductInCompareList(id);
  };

  getMeasuringClassname = () => {
    const { masterProductData, selectedVariant, shouldDisplay, accesoriesArticleNumber } = this.props;

    let measuringClassname = '';
    if (shouldDisplay) {
      measuringClassname = masterProductData && masterProductData.MasterProductENName + accesoriesArticleNumber;
    } else if (selectedVariant) {
      measuringClassname = masterProductData && masterProductData.MasterProductENName + selectedVariant.articleNumber;
    } else {
      measuringClassname = masterProductData && masterProductData.MasterProductENName;
    }
    return removeWhiteSpaces(measuringClassname);
  };

  displayCompareButton = () => {
    const { 'Compare Tooltip Label': compareLabel, 'Compare Already Added Tooltip Label': alreadyAddedTooltip } =
        this.props.fields,
      { shouldDisplay, masterProductData } = this.props,
      checkAlreadyAdded = this.getCheckCompareAlreadyAdded();
    let productCategory = '';
    if (shouldDisplay) {
      productCategory = masterProductData && masterProductData.SubCategoryEn;
    } else {
      productCategory = masterProductData && masterProductData.CategoryEn;
    }

    if (checkAlreadyAdded) {
      return (
        <div className='HeroActionButton-Compare-Wrapper'>
          {this.getCompareButton(alreadyAddedTooltip, this.onCompareClick, productCategory)}
          <span className='HeroActionButton-Compare-CheckIcon'>
            <GreenDotCheckmarkIcon />
          </span>
        </div>
      );
    }

    return (
      <div className='HeroActionButton-Compare-Wrapper'>
        {this.getCompareButton(compareLabel, this.setProductOnCompareCookie, productCategory)}
      </div>
    );
  };

  handleShareClickOutside = e => {
    const shareElement = document.querySelector('.HeroActionButton-Icon.HeroActionButton-Share'),
      shareIconElement = document.querySelector('.HeroActionButton-Share-Icon');

    if (this.state.isShareOpen && shareElement !== e.target && shareIconElement !== e.target) {
      this.setState({ isShareOpen: false });
    }
  };
  handleWishlistClickOutside = e => {
    const wishlistElement = document.querySelector('.HeroActionButton-Icon.HeroActionButton-Wishlist'),
      wishlistIconElement = document.querySelector('.HeroActionButton-Icon.HeroActionButton-Wishlist svg');

    if (this.state.isWishlistOpen && wishlistElement !== e.target && wishlistIconElement !== e.target) {
      this.setState({ isWishlistOpen: false });
    }
  };
  handleCompareClickOutside = e => {
    const compareElement = document.querySelector('.HeroActionButton-Icon.HeroActionButton-Compare'),
      compareIconElement = document.querySelector('.HeroActionButton-Icon.HeroActionButton-Compare svg');
    if (this.state.isCompareOpen && compareElement !== e.target && compareIconElement !== e.target) {
      this.setState({ isCompareOpen: false });
    }
  };

  onCompareClick = () => {
    this.setState({ isCompareOpen: !this.state.isCompareOpen });
  };

  onShareClick = () => {
    this.setState({ isShareOpen: !this.state.isShareOpen });
  };

  onWishListClick = id => {
    const { sitecoreContext, fields, titleEN, setAmountOfWishlistItemsAnonymousUser, toggleWishlist } = this.props;
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      this.setState({ isWishlistOpen: !this.state.isWishlistOpen });
    } else {
      const { Wishlists } = fields,
        wishlistName =
          Wishlists && Wishlists.fields && Wishlists.fields['Wishlist Name']
            ? Wishlists.fields['Wishlist Name'].value
            : ' ';
      setProductToWishlistOnCookie(wishlistName, titleEN, id);
      setAmountOfWishlistItemsAnonymousUser(WISHLIST);
      toggleWishlist();
    }
  };

  getProductDownloadUrl = () => {
    const { technicalDatasheet, shouldDisplay, selectedVariant, variants, accesoriesArticleNumber } = this.props;

    if (shouldDisplay && variants && accesoriesArticleNumber) {
      const currentAccessory = variants.find(item => item.articleNumber === accesoriesArticleNumber.replace('-', '.'));
      return currentAccessory?.technicalDatasheet?.url;
    } else if (selectedVariant) {
      return selectedVariant?.technicalDatasheet?.url;
    }

    return technicalDatasheet?.url;
  };

  getPrintButton = () => {
    const { 'Print Label': printLabel } = this.props.fields,
      measuringClassname = this.getMeasuringClassname(),
      productDownloadUrl = this.getProductDownloadUrl();

    return (
      <a
        href={productDownloadUrl}
        download
        target='blank'
        className={'HeroActionButton-Print-Download click_button_print_pdp ' + measuringClassname}
      >
        <div className='HeroActionButton-Icon HeroActionButton-Print'>
          <PrintIcon />
          {this.getTooltip(printLabel, '-PrintLabel')}
        </div>
      </a>
    );
  };

  getWishlistButton = (cssClassName, hasVariant, productId) => {
    const { 'Wishlist Tooltip': wishlistTooltip, 'Wishlist Tooltip Message': wishlistTooltipMessage } =
        this.props.fields,
      wishlistMeasuringClassname = this.getMeasuringClassname();
    return (
      <div>
        <WishlistButton
          cssClass={cssClassName}
          onClick={() => {
            if (!hasVariant) {
              return this.onWishListClick(productId);
            }
            return null;
          }}
          getTooltip={this.getTooltip(
            !hasVariant ? wishlistTooltip : wishlistTooltipMessage,
            '-WishlistLabel',
            this.state.isWishlistOpen || this.props.variants.length === 0
          )}
        />
        <WishlistBox
          handleClickOutside={this.handleWishlistClickOutside}
          {...this.props}
          onWishlistClick={this.onWishListClick}
          isWishlistOpen={this.state.isWishlistOpen}
          onAddClick={listName => this.setProductOnWishlistCookie(listName, productId)}
          measuringClassname={wishlistMeasuringClassname}
        />
      </div>
    );
  };

  displayWishlistButton() {
    const { variants, selectedVariant, shouldDisplay, accessoriesId } = this.props,
      cssWishListClass = classNames('HeroActionButton-Icon HeroActionButton-Wishlist', {
        'HeroActionButton-Wishlist-Disable': !selectedVariant
      });

    if (shouldDisplay) {
      return this.getWishlistButton('HeroActionButton-Icon HeroActionButton-Wishlist', false, accessoriesId);
    }
    if (variants) {
      return this.getWishlistButton(cssWishListClass, !selectedVariant, selectedVariant && selectedVariant.id);
    }
  }

  setProductOnWishlistCookie = (listName, id) => {
    const { titleEN, toggleWishlist, addProductToWishList, sitecoreContext } = this.props;

    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      addProductToWishList(listName, titleEN, id);
      toggleWishlist();
      this.onWishListClick();
    }
  };

  setProductOnCompareCookie = () => {
    const { selectedVariant, masterProductData, shouldDisplay, accessoriesId } = this.props;
    let id = 0;
    let category = '';
    if (shouldDisplay) {
      id = accessoriesId;
      category = masterProductData.SubCategoryEn ? masterProductData.SubCategoryEn : '';
    } else if (selectedVariant) {
      id = selectedVariant.id;
      category = masterProductData.CategoryEn;
    } else {
      id = masterProductData.MasterProductID;
      category = masterProductData.CategoryEn;
    }
    setProductToCompareOnCookie(category, id);
    this.onCompareClick();
  };

  render() {
    const { 'Share Label': shareLabel, Wishlists } = this.props.fields,
      productDownloadUrl = this.getProductDownloadUrl();
    return (
      <React.Fragment>
        <div className='HeroActionButton'>
          <div className='HeroActionButton-IconsWrapper'>
            <div className='HeroActionButton-Compare-Wrapper'>{this.displayCompareButton()}</div>
            {Wishlists && (
              <div className='HeroActionButton-WishlistWrapper click_button_wishlist_pdp'>
                {this.displayWishlistButton()}
              </div>
            )}
            {productDownloadUrl && this.getPrintButton()}
            <div className='HeroActionButton-ShareWrapper'>
              <div
                className='HeroActionButton-Icon HeroActionButton-Share'
                onClick={this.onShareClick}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.onShareClick();
                }}
                role='button'
                tabIndex='0'
              >
                <ShareIcon className='HeroActionButton-Share-Icon' />

                {this.getTooltip(shareLabel, '-ShareLabel', this.state.isShareOpen)}
              </div>
              <Visible xl lg md>
                <ShareBox
                  handleClickOutside={this.handleShareClickOutside}
                  {...this.props}
                  onShareClick={this.onShareClick}
                  isShareOpen={this.state.isShareOpen}
                />
              </Visible>
            </div>
          </div>
        </div>
        <Visible sm>
          <ShareBox
            handleClickOutside={this.handleShareClickOutside}
            {...this.props}
            onShareClick={this.onShareClick}
            isShareOpen={this.state.isShareOpen}
          />
        </Visible>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    variants: state.productDetails ? state.productDetails.variants : null,
    selectedVariant: state.productDetails.selectedVariant,
    title: state.productDetails.masterProductData ? state.productDetails.masterProductData.MasterProductName : null,
    titleEN: state.productDetails.masterProductData ? state.productDetails.masterProductData.MasterProductENName : null,
    technicalDatasheet: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.technicalDatasheet
      : null,
    masterProductData: state.productDetails.masterProductData ? state.productDetails.masterProductData : null
  };
};
const mapDispatchToProps = dispatch => {
  return {
    toggleWishlist: () => dispatch(toggleWishlist()),
    setAmountOfWishlistItemsAnonymousUser: wishlistCookie =>
      dispatch(setAmountOfWishlistItemsAnonymousUser(wishlistCookie)),
    addProductToWishList: (listName, prodName, variantId) =>
      dispatch(addProductToWishList(listName, prodName, variantId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSitecoreContext()(HeroActionButton)));
