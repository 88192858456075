import React from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { resetStickyMenu } from '../../../store/stickyMenu/actions';

class SolutionHero extends React.Component {
  constructor(props) {
    super(props);
    this.props.resetStickyMenu();
    this.state = { menuHeight: 168, breadcrumbHeight: 0, windowWidth: 0 };
  }
  /**
   * @method componentDidMount
   * @description set menu height and window width on state
   *   and add window event listeners for resize and scroll
   */
  componentDidMount() {
    const menuElement = document.querySelector('.Menu');

    if (menuElement) {
      menuElement.parentNode.style.height = this.state.menuHeight - 56 + 'px';
    }
    window.addEventListener('resize', this.resize);
    window.addEventListener('scroll', this.scroll);
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidUpdate(prevProps, prevState) {
    const { isBreadcrumbVisible } = this.props;
    if (isBreadcrumbVisible && !prevState.breadcrumbHeight) {
      this.setState({
        menuHeight: prevState.menuHeight + 40,
        breadcrumbHeight: 40
      });
    } else if (!isBreadcrumbVisible && prevState.breadcrumbHeight) {
      this.setState({
        menuHeight: prevState.menuHeight - 40,
        breadcrumbHeight: 0
      });
    }

    if (this.state.menuHeight !== prevState.menuHeight) {
      this.scroll();
    }
  }

  /**
   * @method resize
   * @description on rezise change window width from state
   */
  resize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  /**
   * @method scroll
   * @description change index and position for menu depending on scroll position
   */
  scroll = () => {
    const menuElement = document.querySelector('.Menu');
    if (this.state.windowWidth <= 960) {
      //for mobile and tablet we keep this styles
      menuElement.style.position = 'relative';
      menuElement.parentNode.style.height = 'auto';
    } else {
      const scrollPosition = window.scrollY;
      //if scroll position is higher than (window height - menu height)
      // then change menu position to relative and sticky menu index to get sticky menu shadow under page menu
      if (scrollPosition > window.innerHeight - this.state.menuHeight) {
        menuElement.style.position = 'relative';
        menuElement.parentNode.style.height = 'auto';
        //else if scroll position is lower than (window height - menu height)
        // then change menu position to fixed and sticky menu index to get sticky menu shadow above breadcrumb
      } else if (scrollPosition < window.innerHeight - this.state.menuHeight && !this.props.isOpenMenuFlyout) {
        menuElement.style.position = 'fixed';
        menuElement.parentNode.style.height = this.state.menuHeight - 56 + 'px';
      }
    }
  };

  /**
   * @method componentWillUnmount
   * @description remove old listeners
   */
  componentWillUnmount() {
    const menuElement = document.querySelector('.Menu');

    if (menuElement) {
      menuElement.parentNode.removeAttribute('style');
      menuElement.removeAttribute('style');
    }
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('scroll', this.scroll);
  }

  /**
   * @method getInlineStyle
   * @description get height and positions and distance from top
   */
  getInlineStyle = withPosition => {
    const inlineStyle =
      this.state.windowWidth < 960
        ? {
            height: 'auto',
            position: 'relative',
            top: 'unset'
          }
        : {
            height: 'calc(100vh - ' + this.state.menuHeight + 'px)',
            position: 'fixed',
            top: this.state.menuHeight - 56 + 'px'
          };
    if (!withPosition) {
      delete inlineStyle.position;
    }
    return inlineStyle;
  };

  getHeadline(Overline, Title) {
    if (Overline || Title) {
      return (
        <div className='SolutionHero-Headline'>
          <div className='SolutionHero-Headline-Wrapper'>
            <Text tag='div' className='SolutionHero-Headline-Overline' field={Overline} />
            <Text tag='h1' className='SolutionHero-Headline-Title' field={Title} />
          </div>
        </div>
      );
    }
  }

  render() {
    if (!this.props.fields) {
      return <React.Fragment />;
    }

    const { Image: Img, Overline, Title } = this.props.fields;

    return (
      <div className='SolutionHero-HeightPlaceholder' style={this.getInlineStyle(false)}>
        <div className='SolutionHero' style={this.getInlineStyle(true)}>
          {this.getHeadline(Overline, Title)}
          <Image field={Img} className='SolutionHero-Image' />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpenMenuFlyout: state.menuFlyout.isOpen,
    isBreadcrumbVisible: state.mobileMenu.isBreadcrumbVisible
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetStickyMenu: () => dispatch(resetStickyMenu())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SolutionHero);
