import React from 'react';
import { Money } from '../../../../Shopify/lib/shopify/types';
import Price from '../../../../Shopify/Price/Price';
import classNames from 'classnames';
import { CellTextAlign } from '../..';

export function MoneyCell({ money, textAlign = 'left' }: { money: Money; textAlign?: CellTextAlign }): JSX.Element {
  return (
    <div
      className={classNames('MoneyCell', {
        [`MoneyCell--${textAlign}`]: textAlign
      })}
    >
      <Price amount={money.amount} currencyCode={money.currencyCode} />
    </div>
  );
}

export default MoneyCell;
