import React from 'react';
import CloseIcon from '../Icons/CloseIcon';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import PlayIcon from '../Icons/PlayIcon';
import WithStickyMenu from '../../../hoc/WithStickyMenu';

/**
 * Video promotion box component
 */
class VideoPromotionBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  /**
   * @method popupbox
   * @description Used to show video in a lightbox
   */
  popupbox(videoPromotionFields) {
    if (!this.state.isOpen) {
      return;
    }

    const { Video, Title } = videoPromotionFields;
    return (
      <div className='VideoPromotionBox-Overlay'>
        <div className='VideoPromotionBox-Overlay-Details componentContainer'>
          <div className='VideoPromotionBox-Overlay-Details-Name'>{Title.value}</div>
          <div className='VideoPromotionBox-Overlay-Details-Close'>
            <div
              className='VideoPromotionBox-Overlay-Details-Close-Button'
              onClick={this.openPopupbox}
              onKeyDown={e => {
                if (e.keyCode === 13) this.openPopupbox();
              }}
              role='button'
              tabIndex='0'
              aria-label='Close video'
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className='VideoPromotionBox-Overlay-Video componentContainer'>
          <video width='auto' height='auto' controls autoPlay preload='none'>
            <source src={Video.value.src} type='video/mp4' />
            <track src='' kind='captions' lang='en' label='english_captions' />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    );
  }

  /**
   * @method openPopupbox
   * @description Used to show or hide video lightbox
   */
  openPopupbox = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const propsFields = this.props.fields ? this.props.fields : null;
    if (!hasItems(this.props)) {
      return <div className='click_content_block_promotion_video whiteBackground' />;
    }

    const videoPromotionFields = propsFields.VideoPromotionBoxElements[0].fields;
    const { Category, Title, Image: img } = videoPromotionFields;

    return (
      <div className='click_content_block_promotion_video whiteBackground'>
        <div
          className='VideoPromotionBox'
          onClick={this.openPopupbox}
          onKeyDown={e => {
            if (e.keyCode === 13) this.openPopupbox();
          }}
          role='button'
          tabIndex='0'
          aria-label='Open video'
        >
          <Image field={img} />
          <div className='VideoPromotionBox-Play'>
            <PlayIcon />
          </div>
          <div className='VideoPromotionBox-Content componentContainer'>
            <div className='VideoPromotionBox-Content-Rectangle' />
            <div className='VideoPromotionBox-Content-Category'>{Category.value}</div>
            <div className='VideoPromotionBox-Content-Title'>{Title.value}</div>
          </div>
        </div>
        {this.popupbox(videoPromotionFields)}
      </div>
    );
  }
}

const hasItems = function (props) {
  const { fields } = props;
  if (
    !fields ||
    !fields.VideoPromotionBoxElements ||
    !fields.VideoPromotionBoxElements[0] ||
    !fields.VideoPromotionBoxElements[0].fields
  ) {
    return false;
  }
  return true;
};

export default WithStickyMenu(VideoPromotionBox, hasItems);
