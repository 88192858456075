import React from 'react';
import i18n from 'i18next';

type NoResultsProps = {
  query: string;
  noResultsText?: string;
};

export const NoResults: React.FC<NoResultsProps> = ({ query, noResultsText }) => {
  return (
    <div className='NoResults'>
      <h2 className='NoResults__Text'>{noResultsText || i18n.t('SEARCH | No Results')}</h2>
      <div className='NoResults__Query'>{query}</div>
    </div>
  );
};

export default NoResults;
