import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

class ProductCard extends React.Component {
  showLabel = () => {
    const { fields } = this.props;

    const classCheck = fields.Label.value ? 'ProductCard-header-label' : 'ProductCard-header-noLabel';

    return (
      <div className={classCheck}>
        <Text tag='span' field={fields.Label} />
      </div>
    );
  };

  render() {
    const { fields } = this.props;
    return (
      <div className='ProductCard'>
        <div className='ProductCard-header'>
          {this.showLabel()}
          <Image className='ProductCard-header-image' field={fields.Image} />
        </div>

        <div className='ProductCard-details'>
          <Text tag='div' className='ProductCard-details-title' field={fields.Title} />

          <RichText className='ProductCard-details-description' field={fields.Description} />
        </div>
        <div className='ProductCard-bottom'>
          <Text tag='div' className='ProductCard-bottom-price' field={fields.Price} />
          <label className='ProductCard-bottom-checkbox'>
            <input type='checkbox' />
            <span className='checkmark' />
            Compare
          </label>
        </div>
      </div>
    );
  }
}

export default ProductCard;
