import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsLaserSystemsPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsLaserSystemsSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Infrared-Heaters',
  LeisterGroup: 'Infrared-Heaters',
  LeisterTechnologies: 'Infrared-Heaters',
  Weldy: 'Infrared-Heaters'
};

export const LaserSystemsFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications'),
    multi: false
  },
  {
    field: 'pimproductcategory',
    label: () => i18n.t('SEARCH | Facets pimproductcategory')
  }
];
