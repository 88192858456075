import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  name: null,
  email: null,
  salesPoint: null
};

export const contactPerson = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_PERSON_DATA:
      return {
        ...state,
        name: action.name,
        email: action.email,
        phone: action.phone,
        salesPoint: action.salesPoint
      };
    default:
      return state;
  }
};
