import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink/index';

const BackToLink = props => (
  <div className='BackToLink'>
    <Image field={props.fields.BackToImage} />
    <GeneralLink fields={props.fields.BackToLink} />
  </div>
);

export default BackToLink;
