import React from 'react';
import { ImageVideoHeroProps, ImageVideoHeroSlide } from './types';
import ImageVideoHeroComponent from './ImageVideo Hero';
import ImageSlide from './ImageSlide';
import VideoSlide from './VideoSlide';

const ImageVideoHero: React.FC<ImageVideoHeroProps> = props => {
  const {
    fields: {
      data: { pageProps, datasource }
    }
  } = props;
  const slides: ImageVideoHeroSlide[] = [];

  // Take pageProps as first slide, if we don't have sliders or if we want to use the page as first slide
  if (
    !datasource ||
    (datasource && datasource?.usePageAsFirstElement?.boolValue) ||
    !datasource?.items?.targetItems?.length
  ) {
    slides.push({ ...pageProps, key: 'pageSlide' });
  }

  // Attach all sliders
  if (datasource?.items?.targetItems?.length) {
    datasource.items?.targetItems.forEach((slide, index) => {
      slides.push({ ...slide, key: index + '' });
    });
  }

  return (
    <>
      <ImageVideoHeroComponent slides={slides} />
      <div className='ImageVideoHero__slide-preload' data-testid='preloaded'>
        {slides.map(slide => {
          const imageElement = slide?.image?.jss?.value;
          const videoElement = slide?.video?.jss?.value;

          return videoElement?.src ? (
            <VideoSlide
              key={slide?.key}
              video={videoElement.src}
              poster={imageElement?.src}
              data-testid='video-slide-preloaded'
            />
          ) : (
            <ImageSlide
              key={slide?.key}
              src={imageElement?.src}
              alt={imageElement?.alt}
              sizes={imageElement?.sizes}
              srcSet={imageElement?.srcSet}
              data-testid='image-slide-preloaded'
            />
          );
        })}
      </div>
    </>
  );
};

export default ImageVideoHero;
