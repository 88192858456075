import React from 'react';
import { Placeholder, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import GeneralLink from '../../Common/GeneralLink/index';
import { normalizeSitecoreId, checkIfUserIsLoggedIn } from '../../../utils/Utils';
import { GET_NUMBER_OF_DEVICES_ENDPOINT } from '../../../Constants/Devices';
import { setNumberOfDevices } from '../../../store/mydevices/actions';
import ScrollSnap from '../../Common/ScrollSnap';

class MyAccountTabsComponent extends React.Component {
  componentDidMount() {
    const { setNumberOfDevices, numberOfDevices, sitecoreContext } = this.props,
      isUserLoggedIn = checkIfUserIsLoggedIn(sitecoreContext);
    if (isUserLoggedIn) {
      setNumberOfDevices(numberOfDevices);
    }
  }

  getNumberOfItems = item => {
    const { 'Is My Devices': IsMyDevices, 'Is Wishlist Tab': isWishlistTab } = item.fields,
      { numberOfDevices, amountOfAllWishlistItems } = this.props;

    if (IsMyDevices.value && numberOfDevices) {
      return ' (' + numberOfDevices + ')';
    } else if (isWishlistTab.value && amountOfAllWishlistItems) {
      return ' (' + amountOfAllWishlistItems + ')';
    }
    return '';
  };

  getTabs = () => {
    const { 'Tab Items': TabItems, 'Active Tab': ActiveTab } = this.props.fields,
      itemsToMap = TabItems ? TabItems : [],
      activeTabId = normalizeSitecoreId(ActiveTab.id),
      isUserLoggedIn = checkIfUserIsLoggedIn(this.props.sitecoreContext);

    return itemsToMap.map((item, index) => {
      const { Name, Link, 'inactive and greyed out when user not signed in': inactiveIfNotSignedIn } = item.fields,
        cssClass = classNames(
          'MyAccountTabsComponent-TabsWrapper-Container-Tab',
          {
            'Active ScrollSnap--focus':
              activeTabId === normalizeSitecoreId(item.id) && (isUserLoggedIn || !inactiveIfNotSignedIn.value)
          },
          {
            Disabled: !isUserLoggedIn && inactiveIfNotSignedIn.value
          }
        );

      if (!isUserLoggedIn && inactiveIfNotSignedIn.value) {
        return (
          <div key={index + item.id} className={cssClass}>
            <Text field={Name} />
          </div>
        );
      }

      return (
        <GeneralLink key={index + item.id} className={cssClass} fields={Link}>
          <Text field={Name} />
          {this.getNumberOfItems(item)}
        </GeneralLink>
      );
    });
  };

  render() {
    const {
      fields: { 'Tab Items': TabItems },
      rendering
    } = this.props;
    if (!TabItems) {
      return <div className='MyAccountTabsComponent-NoData' />;
    }

    return (
      <div className='MyAccountTabsComponent'>
        <div className='MyAccountTabsComponent-TabsWrapper'>
          <ScrollSnap>
            <div className='MyAccountTabsComponent-TabsWrapper-Container'>{this.getTabs()}</div>
          </ScrollSnap>
        </div>
        <div className='MyAccountTabsComponent-Content'>
          <Placeholder name='jss-my-account-tab-content' rendering={rendering} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    numberOfDevices: state.myDevices.numberOfDevices,
    amountOfAllWishlistItems: state.wishlistProducts && state.wishlistProducts.amountOfAllWishlistItems
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNumberOfDevices: numberOfDevices =>
      dispatch(setNumberOfDevices(GET_NUMBER_OF_DEVICES_ENDPOINT(), numberOfDevices))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(MyAccountTabsComponent));
