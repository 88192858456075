import React from 'react';

const DefaultError = props => {
  const { formErrors, fieldErrors } = props;

  const renderFormError = formErrors.map((error, index) => (
    <div className='invalid' key={`formError-${index}`}>
      {error}
    </div>
  ));

  // adding the message inside "dangerouslySetInnerHTML" will solve all the escaped HTML characters
  const renderFieldError = fieldErrors.map((error, index) => (
    <div className='invalid' key={`fieldError-${index}`}>
      {error.state.errors.map(message => (
        <div
          key={message}
          dangerouslySetInnerHTML={{
            __html: `<div>${message}</div>`
          }}
        ></div>
      ))}
    </div>
  ));

  return (
    <div className='form-errors'>
      {renderFormError}
      {renderFieldError}
    </div>
  );
};

export { DefaultError };
