export const PRODUCT_PAGE_ROUTE = '/product';
/**
 * See the following for more details
 * https://dev.azure.com/leister/myLeister/_git/myLeister-Web/pullrequest/476
 * https://ltagrnd.atlassian.net/browse/LAGWEB-1285
 * */
export const PRODUCT_PAGE_EXACT_ROUTE = '/product/';
export const ALL_DOWNLOADS_PAGE_ROUTE = '/downloadsPage';
export const CONST_CONTACT = '/contact';
export const WISHLIST_PAGE_ROUTE = '/Wishlist';
export const DELETE_ACCOUNT_ROUTE = '/delete-account';
