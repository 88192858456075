import React, { useEffect } from 'react';
import i18n from 'i18next';
import SlideOver from '../../Common/SlideOver';
import { useViewportSize } from '../../../hooks/useViewportSize';
import AddToCartTeaser from '../AddToCartTeaser';
import { ProductData, ShopProduct, ShopProductVariant } from '../lib/shopify/types';
import { ProductItem } from '../../Product/types';
import VariantSelector from '../../Product/VariantSelector';
import { filterProductsBySkuAndReduceProductVariants } from '../lib/shopify/leisterHelpers';

export function AddToCartSlideOver({
  shopProducts,
  shopProductVariants,
  productItems,
  productData,
  isOpen,
  onClose,
  masterProductPageUrl
}: {
  // add proper product variants when we have them
  shopProducts: ShopProduct[];
  shopProductVariants: ShopProductVariant[];
  productItems: ProductItem[];
  productData?: ProductData;
  isOpen: boolean;
  masterProductPageUrl: string;
  onClose: () => void;
}): JSX.Element | null {
  const longTitle = i18n.t('SHOP | Add to cart slideover title', { count: shopProductVariants.length });
  const shortTitle = i18n.t('SHOP | Add to cart slideover title short');
  const { viewportIsMobile } = useViewportSize();
  const [title, setTitle] = React.useState('');

  useEffect(() => {
    setTitle(viewportIsMobile ? shortTitle : longTitle);
  }, [viewportIsMobile, longTitle, shortTitle]);

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} title={title} variant='wide'>
      <div className='AddToCartSlideOver__main'>
        <ul className='AddToCartSlideOver__items'>
          {shopProductVariants.map((shopProductVariant, i) => {
            const productItem = productItems.find(item => item.articleNumber === shopProductVariant.sku);
            const sku = shopProductVariant.sku || productItem?.articleNumber;

            if (!sku) return null;
            const shopProduct = filterProductsBySkuAndReduceProductVariants(shopProducts, sku)[0];

            if (!productItem || !shopProductVariant.availableForSale) {
              return null;
            }

            return (
              <li key={i} className='AddToCartSlideOver__item'>
                <AddToCartTeaser
                  shopProduct={shopProduct}
                  shopProductVariant={shopProductVariant}
                  productItem={productItem}
                  onAddToCart={onClose}
                  productData={productData}
                />
              </li>
            );
          })}
        </ul>
        <div className='AddToCartSlideOver__bottom'>
          <VariantSelector
            defaultProductItems={productItems}
            shopProducts={shopProducts}
            masterProductPageUrl={masterProductPageUrl}
            triggerText={i18n.t('SHOP | Open variant selector')}
          />
        </div>
      </div>
    </SlideOver>
  );
}

export default AddToCartSlideOver;
