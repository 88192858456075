import cartFragment from '../fragments/cart';
import { getLanguageContext } from '../inContext/language';

export const getCartQuery = (): string => /* GraphQL */ `
    query getCart($cartId: ID!) ${getLanguageContext()} {
      cart(id: $cartId) {
        ...cart
      }
    }
    ${cartFragment}
  `;
