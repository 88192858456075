/* eslint no-console: "off" */
import * as actionTypes from './actionTypes';

/**
 * @method changeLayout
 * @description Action that chenge the layout for menu flyout.
 * @param payload {object} Contains deteils about layout and the source of layout.
 * @returns {object} Return an object.
 */
export const changeLayout = payload => {
  return {
    type: actionTypes.CHANGE_LAYOUT,
    layout: payload.layout,
    sectionId: payload.sectionId,
    productsJson: payload.productsJson,
    opededFlayout: payload.opededFlayout,
    showImage: payload.showImage
  };
};

/**
 * @method setMenuDataAction
 * @description set an object on redux state with all the necessary data for submenuItems and for the Flyout Items
 * @param {object} menuJson
 * @returns {object} Return an object.
 */
export const setMenuDataAction = (menuJson, dataOfAllSubmenuItems) => {
  return {
    type: actionTypes.SET_ALL_SUBITEMS_DATA,
    menuJson,
    dataOfAllSubmenuItems
  };
};

/**
 * @method setRedirectPageId
 * @description set the flyout to open after redirect
 * @param {string} redirectedPage
 * @returns {object} Return an object.
 */
export const setRedirectPageId = redirectedPageId => {
  return {
    type: actionTypes.SET_REDIRECT_PAGE_ID,
    redirectedPageId
  };
};

/**
 * @method setProductsJsonData
 * @description set an object on redux state with all the necessary data for products flyout items
 * @param {object} menuJson
 * @returns {object} Return an object.
 */
export const setProductsJsonData = endpoint => {
  return dispatch => {
    fetch(endpoint, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        if (responseJson.SubItems) {
          dispatch(setProductsJsonDataAction(responseJson.SubItems, endpoint));
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
};

export const setProductsJsonDataAction = (resultJson, endpoint) => {
  return {
    type: actionTypes.SET_PRODUCTS_JSON_DATA,
    productsJson: resultJson,
    endpoint
  };
};
