import React from 'react';
import i18n from 'i18next';
import SlideOver from '../../Common/SlideOver';
import EditAddressForm from './EditAddressForm/EditAddressForm';
import { Address } from './types';

const EditAddress = ({
  isOpen,
  onClose,
  address
}: {
  isOpen: boolean;
  address?: Address;
  onClose: () => void;
}): JSX.Element => {
  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      title={
        address?.id
          ? i18n.t('ADDRESS_MANAGEMENT | Edit address title')
          : i18n.t('ADDRESS_MANAGEMENT | Create address title')
      }
    >
      <EditAddressForm address={address} onClose={onClose} />
    </SlideOver>
  );
};

export default EditAddress;
