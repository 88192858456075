import React from 'react';
import { SitecoreContext, SitecoreContextFactory } from '@sitecore-jss/sitecore-jss-react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import componentFactory from './temp/componentFactory';
import RouteHandler, { SsrState } from './RouteHandler';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ALL_DOWNLOADS_PAGE_ROUTE, PRODUCT_PAGE_ROUTE } from './AppRoutes';
import { CartContextProvider } from './components/Shopify/context/CartContext';
import { CookieConsentProvider } from './components/Common/CookieSettings/CookieContext';
import { ViewportProvider } from './context/ViewportContext';
//import { t } from 'i18next'

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

export const productRoutePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/product/:product_name/:article_number?',
  '/:lang([a-z]{2})/product/:product_name/:article_number?',
  '/product/:product_name/:article_number?'
];

export const allDownloadsRoutePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/downloadsPage/:product_name/:article_number',
  '/:lang([a-z]{2})/downloadsPage/:product_name/:article_number',
  '/downloadsPage/:product_name/:article_number',
  '/:lang([a-z]{2}-[A-Z]{2})/downloadsPage/:product_name',
  '/:lang([a-z]{2})/downloadsPage/:product_name',
  '/downloadsPage/:product_name'
];

type AppRootProps = {
  path: string;
  Router: any;
  graphQLClient: ApolloClient<NormalizedCacheObject>;
  ssrState: SsrState;
};

type AppRootState = {
  ssrRenderComplete: boolean;
  contextFactory: SitecoreContextFactory;
};

type SitecoreRouteProps = {
  sitecoreRoute?: string;
};

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
class AppRoot extends React.Component<AppRootProps, AppRootState> {
  public state: AppRootState = {
    ssrRenderComplete: false,
    contextFactory: new SitecoreContextFactory()
  };

  public previousMatchPath = '';

  constructor(props: AppRootProps) {
    super(props);

    if (props.ssrState && props.ssrState.sitecore && props.ssrState.sitecore.route) {
      // set the initial sitecore context data if we got SSR initial state
      this.state.contextFactory.setSitecoreContext({
        route: props.ssrState.sitecore.route,
        itemId: props.ssrState.sitecore.route.itemId,
        ...props.ssrState.sitecore.context
      });
    } else if (props.ssrState) {
      this.state.contextFactory.setSitecoreContext(props.ssrState.sitecore.context);
    } else {
      this.state.contextFactory.setSitecoreContext(null);
    }
  }

  setSsrRenderComplete = (ssrRenderComplete: boolean) =>
    this.setState({
      ssrRenderComplete
    });

  render() {
    const { path, Router, graphQLClient } = this.props;

    const routeRenderFunction = (props: RouteComponentProps<SitecoreRouteProps>) => {
      const waitForUpdatedSitecoreContext = this.hasRouteMatchPathChanged(props);

      return (
        <RouteHandler
          route={props}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          waitForUpdatedSitecoreContext={waitForUpdatedSitecoreContext}
        />
      );
    };

    const professionalRouteRenderFunction = (props: RouteComponentProps<SitecoreRouteProps>) => {
      const waitForUpdatedSitecoreContext = this.hasRouteMatchPathChanged(props);

      const handlerProps = { ...props };
      // this will make it use the product route item for its layout data

      handlerProps.match.params.sitecoreRoute = PRODUCT_PAGE_ROUTE;

      return (
        <RouteHandler
          route={handlerProps}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          waitForUpdatedSitecoreContext={waitForUpdatedSitecoreContext}
        />
      );
    };

    const allDownloadsRouteRenderFunction = (props: RouteComponentProps<SitecoreRouteProps>) => {
      const waitForUpdatedSitecoreContext = this.hasRouteMatchPathChanged(props);
      const handlerProps = { ...props };
      // this will make it use the product route item for its layout data

      handlerProps.match.params.sitecoreRoute = ALL_DOWNLOADS_PAGE_ROUTE;

      return (
        <RouteHandler
          route={handlerProps}
          ssrRenderComplete={this.state.ssrRenderComplete}
          setSsrRenderComplete={this.setSsrRenderComplete}
          waitForUpdatedSitecoreContext={waitForUpdatedSitecoreContext}
        />
      );
    };

    return (
      <CookieConsentProvider>
        <ApolloProvider client={graphQLClient}>
          <SitecoreContext componentFactory={componentFactory} contextFactory={this.state.contextFactory}>
            <Provider store={store}>
              <CartContextProvider>
                <ViewportProvider>
                  <Router
                    onUpdate={() => {
                      if (window.location.href.indexOf('#') < 0) window.scrollTo(0, 0);
                    }}
                    location={path}
                    context={{}}
                  >
                    <Switch>
                      {allDownloadsRoutePatterns.map(routePattern => (
                        <Route key={routePattern} path={routePattern} render={allDownloadsRouteRenderFunction} />
                      ))}
                      {productRoutePatterns.map(routePattern => (
                        <Route key={routePattern} path={routePattern} render={professionalRouteRenderFunction} />
                      ))}
                      {routePatterns.map(routePattern => (
                        <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
                      ))}
                    </Switch>
                  </Router>
                </ViewportProvider>
              </CartContextProvider>
            </Provider>
          </SitecoreContext>
        </ApolloProvider>
      </CookieConsentProvider>
    );
  }

  hasRouteMatchPathChanged(props: RouteComponentProps<SitecoreRouteProps>) {
    let isNewRouteComponent = false;
    if (this.previousMatchPath && this.previousMatchPath !== props.match.path) {
      isNewRouteComponent = true;
    }

    this.previousMatchPath = props.match.path;
    return isNewRouteComponent;
  }
}

export default AppRoot;
