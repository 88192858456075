import React from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import SalesAndServiceCentersBackTo from './SalesAndServiceCentersBackTo';
import SearchLocationInput from './SearchLocationInput';

class SalesAndServiceCentersHolderTwoColumns extends React.Component {
  componentDidMount() {
    this.changeBodyOverflowState();
    window.addEventListener('resize', this.resize);
  }

  /**
   * @method componentWillUnmount
   * @description remove event listener for resize
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    document.body.classList.remove('overflowHiddenOnMapPage');
  }

  /**
   * @method resize
   * @description on rezise change window width from state
   */
  resize = () => {
    this.changeBodyOverflowState();
  };

  changeBodyOverflowState() {
    if (window.innerWidth > 960) {
      document.body.classList.add('overflowHiddenOnMapPage');
    } else {
      document.body.classList.remove('overflowHiddenOnMapPage');
    }
  }

  render() {
    const { fields, sitecoreContext } = this.props;
    if (fields) {
      return <div className='SalesAndServiceCentersHolderTwoColumns' />;
    }

    const searchBoxLabel =
      sitecoreContext && sitecoreContext.route && sitecoreContext.route.fields['Search Box']
        ? sitecoreContext.route.fields['Search Box'].value
        : '';
    return (
      <Row nogutter className='SalesAndServiceCentersHolderTwoColumns'>
        <Visible sm md>
          <Col xl={9} lg={8} md={12} sm={12} className='SalesAndServiceCentersHolderTwoColumns-Right'>
            <SalesAndServiceCentersBackTo fields={this.props.sitecoreContext} />

            <Placeholder name='jss-sales-and-service-points-right' rendering={this.props.rendering} />
          </Col>
        </Visible>
        <Col className='SalesAndServiceCentersHolderTwoColumns-Left' xl={3} lg={4} md={12} sm={12}>
          <Visible lg xl>
            <SalesAndServiceCentersBackTo fields={this.props.sitecoreContext} />
          </Visible>
          <SearchLocationInput searchBoxLabel={searchBoxLabel} />
          <Placeholder name='jss-sales-and-service-points-left' rendering={this.props.rendering} />
        </Col>
        <Visible xl lg>
          <Col xl={9} lg={8} md={12} sm={12} className='SalesAndServiceCentersHolderTwoColumns-Right'>
            <Placeholder name='jss-sales-and-service-points-right' rendering={this.props.rendering} />
          </Col>
        </Visible>
      </Row>
    );
  }
}

export default withSitecoreContext()(SalesAndServiceCentersHolderTwoColumns);
