import Cookies from 'js-cookie';
import { getShopifySettings } from '../../../../AppGlobals';
import { SHOPIFY_GRAPHQL_API_ENDPOINT } from '../constants';
import { isShopifyError } from '../type-guards';
import {
  COUNTRY_CODE,
  SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME,
  SITE_NAME
} from '../../../Common/CookieSettings/CookieConstants';

type ExtractVariables<T> = T extends { variables: any } ? T['variables'] : never;

export async function shopifyFetch<T>({
  cache = 'force-cache',
  headers,
  query,
  tags,
  variables
}: {
  cache?: RequestCache;
  headers?: HeadersInit;
  query: string;
  tags?: string[];
  variables?: ExtractVariables<T>;
}): Promise<{ status: number; body: T } | never> {
  const shopifyTestAccessKey = Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME);
  const currentCountryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();
  const siteName = Cookies.get(SITE_NAME);
  const { domain, storefrontAccessToken } = await getShopifySettings(
    currentCountryCode,
    shopifyTestAccessKey,
    siteName
  );
  const endpoint = `${domain}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;

  if (!domain) {
    return {
      status: 404,
      body: {} as T
    };
  }

  try {
    const result = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
        ...headers
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables })
      }),
      cache,
      ...(tags && { next: { tags } })
    });

    const body = await result.json();

    if (body.errors) {
      throw body.errors[0];
    }

    return {
      status: result.status,
      body
    };
  } catch (e) {
    if (isShopifyError(e)) {
      throw {
        cause: e.cause?.toString() || 'unknown',
        status: e.status || 500,
        message: e.message,
        query
      };
    }

    throw {
      error: e,
      query
    };
  }
}
