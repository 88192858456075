import React from 'react';

class CompareTechnicalSpecs extends React.Component {
  render() {
    return (
      <div className='CompareTechnicalSpecs-Row'>
        <div className='CompareTechnicalSpecs-Item CompareTechnicalSpecs-Name'>
          <span className='CompareTechnicalSpecs-Item-Title'>{this.props.title}</span>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default CompareTechnicalSpecs;
