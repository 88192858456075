import React, { useContext } from 'react';
import CoveoComponent from '../../CoveoComponent';
import GeneralDownloadsListerComponent from './GeneralDownloadsLister';
import { CoveoGeneralDownloadsPageSearchHubs } from '../../CoveoSettings';
import { SearchEngineContext } from '../../context/SearchEngineContext';

export const GeneralDownloadsLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: 'GeneralDownloads',
    searchHub: CoveoGeneralDownloadsPageSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <GeneralDownloadsListerComponent />
    </CoveoComponent>
  );
};

export default GeneralDownloadsLister;
