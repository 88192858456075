export const triggerHashScroll = (): void => {
  // Make sure the hash is decoded, otherwise the querySelector will throw an error!
  const hash = decodeURIComponent(window.location.hash);
  if (!hash) return;

  let retryCount = 0;
  const maxRetries = 15;
  const fastRetries = 5;
  const fastRetryDelay = 250;
  const slowRetryDelay = 500;

  const checkTargetElement = () => {
    if (retryCount >= maxRetries) {
      // Reached maximum retries, stop recalling the function
      return;
    }

    try {
      const targetElement = document.querySelector(hash) as HTMLElement;
      // Hash target not yet found, the content is probably not yet loaded, try again with increasing delay
      if (!targetElement) {
        retryCount++;
        setTimeout(checkTargetElement, retryCount < fastRetries ? fastRetryDelay : slowRetryDelay);
      } else {
        // Makes sure that the scrolling initializes and that it executes immediately
        const currentElementPosition = Math.round(targetElement.offsetTop);
        const newScrollDestination = scrollToElement(currentElementPosition);
        checkElementPosition(targetElement, newScrollDestination);
      }
    } catch (error) {
      // Most likely the hash target is not a valid querySelector, we might have anything in the hash
      console.warn('Error while trying to scroll to hash target:', error);
    }
  };

  checkTargetElement();
};

// Checks if the target element position changes during the scroll, if it does, it scrolls to the new position and checks again
// If the target element position does not change, it waits until the scroll reaches the target position
const checkElementPosition = async (
  targetElement: HTMLElement,
  scrollDestination: number,
  delay = 250
): Promise<void> => {
  const initialElementPosition = Math.round(targetElement.offsetTop);
  await new Promise(resolve => setTimeout(resolve, delay));
  const currentElementPosition = Math.round(targetElement.offsetTop);

  if (currentElementPosition === undefined) {
    throw new Error('Element position not found');
  }

  const currentScrollPosition = Math.round(window.scrollY);
  if (currentElementPosition !== initialElementPosition) {
    // Position of target element has changed, scroll to the new position
    const newScrollDestination = scrollToElement(currentElementPosition);
    checkElementPosition(targetElement, newScrollDestination);
    return;
  } else if (currentScrollPosition !== scrollDestination) {
    // The element position did not change yet, wait until we arrive at its position
    checkElementPosition(targetElement, scrollDestination);
  } else {
    // Done with positioning
  }
};

export const scrollToElement = (
  currentElementPosition: number,
  currentScrollPosition = Math.round(window.scrollY)
): number => {
  // On mobile and tablet, there is a sticky header, so we need to scroll a bit more, when going upwards
  const scrollDirection = currentElementPosition < currentScrollPosition ? 'upwards' : 'downwards';
  const stickyHeaderIsVisible = window.innerWidth < 960 && scrollDirection === 'upwards';

  // Add 70 + 56 px (header + sticky header)
  const scrollCorrection = stickyHeaderIsVisible ? 70 + 56 : 0;
  const scrollDestination = stickyHeaderIsVisible ? currentElementPosition - scrollCorrection : currentElementPosition;

  window.scrollTo({
    top: scrollDestination,
    behavior: 'smooth'
  });

  return scrollDestination;
};
