import React, { useContext, useEffect } from 'react';
import { getCookie } from '../../Common/CookieSettings/CookieUtils';
import { getCart } from '../lib/shopify';
import CartModal from './Modal';
import { ActionType, CartContext } from '../context/CartContext';

export default function Cart(): JSX.Element {
  const { state, dispatch } = useContext(CartContext);
  const cartId = getCookie('cartId');

  useEffect(() => {
    const loader = async () => {
      const cart = await getCart(cartId);

      if (cart !== undefined) {
        dispatch({
          type: ActionType.SET_CART,
          payload: {
            cart
          }
        });
      }
    };

    loader();
  }, [cartId, dispatch]);

  return <CartModal cart={state.cart} />;
}
