const discountsFragment = /* GraphQL */ `
  fragment discounts on Cart {
    discountCodes {
      applicable
      code
    }
    discounts: discountAllocations {
      ... on CartCodeDiscountAllocation {
        code
        discountedAmount {
          currencyCode
          amount
        }
      }
    }
  }
`;

export default discountsFragment;
