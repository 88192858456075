import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../Common/Icons/ChevronDown';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import GeneralLink from '../../Common/GeneralLink/index';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';

const MAX_ITEMS = 9;
const SHOW_MORE_ITEMS = 9;

class SimilarAccessories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfItemsToShow: MAX_ITEMS
    };
  }

  getSimilarAccessories = () => {
    return this.props.similarAccessories.slice(0, this.state.numberOfItemsToShow).map((item, index) => {
      const { ArticleID, ArticleNumber, MasterProductENName } = item,
        link =
          MasterProductENName && ArticleID
            ? {
                href:
                  PRODUCT_PAGE_ROUTE +
                  '/' +
                  MasterProductENName.replace(/ /g, '-') +
                  '/' +
                  ArticleNumber.toString().replace('.', '-')
              }
            : null;
      if (link) {
        const clickClass =
          MasterProductENName && ArticleNumber ? removeWhiteSpaces(MasterProductENName + ArticleNumber) : '';
        return (
          <GeneralLink
            fields={{ value: link }}
            key={ArticleID + index}
            className='SimilarAccessories-Item'
            clickClassName={'similar_accessories_adp ' + clickClass}
          >
            {this.getCommonContent(item)}
          </GeneralLink>
        );
      }
      return (
        <div className='SimilarAccessories-Item' key={ArticleID + index}>
          {this.getCommonContent(item)}
        </div>
      );
    });
  };

  getCommonContent(item) {
    const { ArticleName, ArticleNumber, imageUrl, MasterProductName, SubTitleInfo } = item,
      subTitleValue = SubTitleInfo && SubTitleInfo.value ? SubTitleInfo.value : '';
    return (
      <React.Fragment>
        <div className='SimilarAccessories-Item-Image'>
          {imageUrl ? (
            <img src={imageUrl + '?imwidth=320'} alt={ArticleName} loading='lazy' />
          ) : (
            <div className='SimilarAccessories-Item-Image-Placeholder' />
          )}
        </div>
        <div className='SimilarAccessories-Item-Description'>
          <div className='SimilarAccessories-Item-Description-Title'>{MasterProductName}</div>
          <div className='SimilarAccessories-Item-Description-Details'>{subTitleValue}</div>
          <div className='SimilarAccessories-Item-Description-ArticleNumber'>{ArticleNumber}</div>
        </div>
      </React.Fragment>
    );
  }

  onShowMoreClick = () => {
    this.setState({
      numberOfItemsToShow: this.state.numberOfItemsToShow + SHOW_MORE_ITEMS
    });
  };

  getShowMore = props => {
    if (props.similarAccessories.length > this.state.numberOfItemsToShow) {
      return (
        <div
          className='SimilarAccessories-More'
          onClick={() => {
            this.onShowMoreClick();
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onShowMoreClick();
          }}
          role='button'
          tabIndex='0'
        >
          <ChevronDown />
          <Text field={props.fields['Show More']} />
        </div>
      );
    }
  };

  render() {
    const { fields, subCategory } = this.props;
    return (
      <div className='click_similar_accessories_adp'>
        {hasItems(this.props) ? (
          <div className='SimilarAccessories componentContainer'>
            <div className='SimilarAccessories-Headline'>
              <h2 className='SimilarAccessories-Headline-Title'>
                <Text field={fields.Title} />
                {subCategory && fields['Show Subcategory On Title'] && fields['Show Subcategory On Title'].value
                  ? ' ' + subCategory
                  : ''}
              </h2>
              <Text tag='div' className='SimilarAccessories-Headline-Subtitle' field={fields.Subtitle} />
            </div>
            <div className='SimilarAccessories-Data'>{this.getSimilarAccessories()}</div>
            {this.getShowMore(this.props)}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    similarAccessories: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.SimilarAccessories
      : null,
    subCategory: state.productDetails.masterProductData ? state.productDetails.masterProductData.SubCategory : null
  };
};

const hasItems = function (props) {
  const { similarAccessories } = props;
  if (!similarAccessories || !similarAccessories.length) {
    return false;
  }
  return true;
};

export default connect(mapStateToProps)(WithStickyMenu(SimilarAccessories, hasItems));
