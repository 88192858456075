import { saveAs } from 'file-saver';
import { EventAttributes } from 'ics';
import i18n from 'i18next';
import { TextField } from '../../../../../../utils/types';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { getLinkLabel } from './LinkUtils';

export const getLocation = (eventCity?: TextField, regionName?: TextField, eventVenue?: TextField): string => {
  let location = eventCity?.value || '';

  if (location && regionName?.value) {
    location += ' / ';
  }

  if (regionName?.value) {
    location += regionName.value;
  }

  if (eventVenue?.value) {
    location = `${eventVenue.value}, ${location}`;
  }

  return location;
};

export const getDescription = (
  {
    eventVenue,
    eventAddress,
    eventLinkDirections,
    eventBoothInformation,
    eventLinkOrganizer,
    eventLinkBooth
  }: {
    eventVenue?: TextField;
    eventAddress?: TextField;
    eventLinkDirections?: LinkField;
    eventBoothInformation?: TextField;
    eventLinkOrganizer?: LinkField;
    eventLinkBooth?: LinkField;
  },
  returnHTML?: boolean
): string => {
  const lineBreak = returnHTML ? '<br />' : '\n';

  const venueLabel = returnHTML ? `<b>${i18n.t('EVENT_DETAIL | Venue')}</b>` : i18n.t('EVENT_DETAIL | Venue');
  const venueDetails = eventVenue?.value ? `${lineBreak}${eventVenue.value}` : '';
  const addressDetails = eventAddress?.value
    ? returnHTML
      ? `${lineBreak}${eventAddress.value.replace(/(?:\r\n|\r|\n)/g, '<br>')}`
      : `${lineBreak}${eventAddress.value}`
    : '';
  const venueSection = eventVenue?.value || eventAddress?.value ? `${venueLabel}:${venueDetails}${addressDetails}` : '';

  const boothLabel = returnHTML ? `<b>${i18n.t('EVENT_DETAIL | Booth')}</b>` : i18n.t('EVENT_DETAIL | Booth');
  const boothDetails = eventBoothInformation?.value ? `${lineBreak}${eventBoothInformation.value}` : '';
  const boothSection =
    eventBoothInformation?.value || eventLinkBooth?.value.href ? `${boothLabel}:${boothDetails}` : '';

  const directionsLabel = returnHTML
    ? `<b>${i18n.t('EVENT_DETAIL | Directions')}</b>`
    : i18n.t('EVENT_DETAIL | Directions');
  const directionsLink = eventLinkDirections?.value?.href
    ? returnHTML
      ? `${lineBreak}<a href="${eventLinkDirections.value.href}">${getLinkLabel(eventLinkDirections)}</a>`
      : `${lineBreak}${eventLinkDirections.value.href}`
    : '';
  const directionsSection = eventLinkDirections?.value.href ? `${directionsLabel}:${directionsLink}` : '';

  const organizerLink = eventLinkOrganizer?.value?.href
    ? returnHTML
      ? `<a href="${eventLinkOrganizer.value.href}">${getLinkLabel(eventLinkOrganizer)}</a>`
      : `${getLinkLabel(eventLinkOrganizer)}:${lineBreak}${eventLinkOrganizer.value.href}`
    : '';

  const boothLink = eventLinkBooth?.value?.href
    ? returnHTML
      ? `<a href="${eventLinkBooth.value.href}">${getLinkLabel(eventLinkBooth)}</a>`
      : `${getLinkLabel(eventLinkBooth)}:${lineBreak}${eventLinkBooth.value.href}`
    : '';

  const sections = [];

  if (directionsSection) {
    sections.push(directionsSection);
  }

  if (venueSection) {
    sections.push(venueSection);
  }

  if (boothSection) {
    sections.push(boothSection);
  }

  if (organizerLink) {
    sections.push(organizerLink);
  }

  if (boothLink) {
    sections.push(boothLink);
  }

  return `${sections.join(`${lineBreak}${lineBreak}`)}`;
};

export const downloadCalendar = ({
  eventTitle,
  startDateObj,
  endDateObj,
  eventCity,
  regionName,
  eventVenue,
  eventAddress,
  eventLinkDirections,
  eventBoothInformation,
  eventLinkOrganizer,
  eventLinkBooth
}: {
  eventTitle: TextField;
  startDateObj: Date;
  endDateObj: Date;
  eventCity?: TextField;
  regionName?: TextField;
  eventVenue?: TextField;
  eventAddress?: TextField;
  eventLinkDirections?: LinkField;
  eventBoothInformation?: TextField;
  eventLinkOrganizer?: LinkField;
  eventLinkBooth?: LinkField;
}): void => {
  const location = getLocation(eventCity, regionName, eventVenue);

  import('ics').then(ics => {
    // we need to add one day for full day events in ics: https://stackoverflow.com/questions/47151477/ics-file-when-imported-in-outlook-shows-wrong-end-date-when-no-start-end-time
    const newEndDate = new Date(endDateObj.getTime());
    newEndDate.setDate(newEndDate.getDate() + 1);

    const event: EventAttributes = {
      start: [startDateObj.getFullYear(), startDateObj.getMonth() + 1, startDateObj.getDate()],
      end: [newEndDate.getFullYear(), newEndDate.getMonth() + 1, newEndDate.getDate()],
      title: eventTitle.value,
      location,
      url: window?.location?.href,
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
      classification: 'PUBLIC',
      description: getDescription({
        eventVenue,
        eventAddress,
        eventLinkDirections,
        eventBoothInformation,
        eventLinkOrganizer,
        eventLinkBooth
      }),
      htmlContent: getDescription(
        {
          eventVenue,
          eventAddress,
          eventLinkDirections,
          eventBoothInformation,
          eventLinkOrganizer,
          eventLinkBooth
        },
        true
      )
    };

    ics.createEvent(event, (error, value) => {
      const blob = new Blob([value], {
        type: 'text/calendar;charset=utf-8'
      });
      saveAs(blob, 'event-schedule.ics');
    });
  });
};
