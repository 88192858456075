import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

class HistoryItem extends React.Component {
  render() {
    const { fields } = this.props;

    if (!fields) {
      return <div className='HistoryItem' />;
    }

    const { Title, Description } = fields;

    return (
      <div className='HistoryItem'>
        <RichText field={Title} className='HistoryItem-Title' />
        <RichText field={Description} className='HistoryItem-Description' />
      </div>
    );
  }
}

export default HistoryItem;
