import i18n from 'i18next';
import { SearchFacet } from '../../../CoveoSettings';

export const WeldingMachinesFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimweldingtechnology',
    label: () => i18n.t('SEARCH | Facets pimweldingtechnology'),
    multi: false
  },
  {
    field: 'pimweldingseamtype',
    label: () => i18n.t('SEARCH | Facets pimweldingseamtype')
  },
  {
    field: 'pimstationaryoperation',
    label: () => i18n.t('SEARCH | Facets pimstationaryoperation'),
    multi: false
  },
  {
    field: 'pimdatarecordinglqs',
    label: () => i18n.t('SEARCH | Facets pimdatarecordinglqs'),
    multi: false
  },
  {
    field: 'pimspeedma',
    label: () => i18n.t('SEARCH | Facets pimspeedma'),
    numeric: true,
    unit: 'm/min'
  }
];
