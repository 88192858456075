import classNames from 'classnames';
import React from 'react';
import { CellTextAlign, CellTextVariant } from '../..';

export function BoldTextCell({
  text,
  textAlign = 'left',
  textVariant = 'regular'
}: {
  text: string;
  textAlign?: CellTextAlign;
  textVariant?: CellTextVariant;
}): JSX.Element {
  return (
    <div
      className={classNames('BoldTextCell', {
        [`BoldTextCell--${textAlign}`]: textAlign,
        'BoldTextCell--primary': textVariant === 'primary'
      })}
    >
      {text}
    </div>
  );
}

export default BoldTextCell;
