import React, { useContext } from 'react';
import { ShopProductVariantWithProduct, Cart, ProductData } from '../lib/shopify/types';
import { addItems } from './actions';
import { ActionType, CartContext } from '../context/CartContext';
import classNames from 'classnames';
import ShopIcon from '../../Common/Icons/ShopIcon';
import { getCookie } from '../../Common/CookieSettings/CookieUtils';
import { ProductItemTechnicalDetails } from '../../Product/types';
import { getProductItemDetailPageUrl } from '../lib/shopify/leisterHelpers';
import { LANG } from '../../Common/CookieSettings/CookieConstants';
import { calculatePriceWithDiscountPercentage } from '../PriceDetails/PriceDetails';
import { applyDiscountToCart } from '../lib/shopify/bundleHelpers';

export default function AddBundleToCart({
  quantity = 1,
  discountCode = undefined,
  productVariants,
  technicalDetails,
  totalPriceOriginal,
  currencyCode,
  bundleDiscountPercentage
}: {
  quantity: number;
  discountCode?: string;
  productVariants: ShopProductVariantWithProduct[];
  technicalDetails: ProductItemTechnicalDetails[];
  totalPriceOriginal: number;
  currencyCode: string;
  bundleDiscountPercentage: number;
}): JSX.Element | null {
  const { dispatch } = useContext(CartContext);

  const bundleIsAvailableForSale = productVariants.every(productVariant => productVariant.availableForSale);
  const productVariantIds = productVariants.map(productVariant => productVariant.id);

  const addProductWithBundleDiscount = async (bundleDiscountCode: string, e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // If user tries to trick with the button, we don't allow to add to cart if not available for sale
    if (bundleIsAvailableForSale === false) {
      return;
    }

    if (productVariants.length === 0) {
      return;
    }

    // We should have technical details, so we can create proper product data, for the bundle items
    let productItemsData: ProductData[] = [];
    if (technicalDetails) {
      productItemsData = technicalDetails.map(techDetail => {
        const productItemDetailPageUrl = getProductItemDetailPageUrl(
          techDetail?.productInformation.assetNameNormalized ?? '',
          techDetail.articleNumber,
          getCookie(LANG)
        );
        const data: ProductData = {
          productVariantId: productVariants.find(variant => variant.sku === techDetail.articleNumber)?.id,
          name: techDetail.productInformation.assetName, // Important, this should be the EN name of the product. So it can match with other products within shopify cart
          brand: techDetail.productInformation.brandName,
          category: techDetail.productInformation.categoryNormalized,
          subcategory: techDetail.productInformation.subCategoryNormalized,
          productItemUrl: productItemDetailPageUrl
        };
        return data;
      });
    }

    const addItemsResponse = await addItems(productVariantIds, quantity, productItemsData, bundleDiscountCode);

    let updatedCartAfterAddItems: Cart | undefined = undefined;
    if (addItemsResponse.cart && typeof addItemsResponse.cart !== 'string') {
      updatedCartAfterAddItems = addItemsResponse.cart;
    }

    const cartId = getCookie('cartId');

    if (!cartId) {
      console.error('No cart id');
      return;
    }

    if (!updatedCartAfterAddItems) {
      console.error('No cart after adding items');
      return;
    }

    const { cart: updatedCartAfterAddedDiscount, userErrors: discountErrors } = await applyDiscountToCart(
      updatedCartAfterAddItems,
      bundleDiscountCode
    );

    if (updatedCartAfterAddItems) {
      const updatedRows = updatedCartAfterAddItems.lines.filter(
        item => !!productVariants.find(productVariant => productVariant.id === item.merchandise.id)
      );
      // add the variant id to the errors
      addItemsResponse.userErrors.map(error => {
        const lineIndexOfError = +error.field[1];
        const variantIdOfError = updatedCartAfterAddItems?.lines[lineIndexOfError]?.merchandise?.id ?? ''; // Strange TS error - doesn't get that this value is set
        error.variantId = variantIdOfError;
        // TODO LAGWEB-1966: If there is a quantity error - we need to adopt all other line items of this bundle to the lowest amount...
      });
      const allErrors = [...addItemsResponse.userErrors, ...(discountErrors ?? [])];

      // Check for user errors, like out of stock, or some stock
      if (allErrors.length > 0) {
        dispatch({
          type: ActionType.ERROR_WHEN_ADDING_TO_CART,
          payload: {
            userErrors: addItemsResponse.userErrors.map(error => ({
              message: error.message,
              field: error.field,
              variantId: error.variantId
            }))
          }
        });
      }

      if (!updatedRows) {
        return;
      }

      dispatch({
        type: ActionType.SET_CART,
        payload: {
          cart: updatedCartAfterAddedDiscount
        }
      });

      dispatch({
        type: ActionType.OPEN_CART_NOTIFICATION,
        payload: {
          cartItem: undefined,
          isBundle: true,
          bundleInfo: {
            title: discountCode ?? '',
            amount: (
              calculatePriceWithDiscountPercentage(totalPriceOriginal, bundleDiscountPercentage) * quantity
            ).toFixed(2),
            currencyCode: currencyCode,
            amountOfItems: productVariantIds.length
          }
        }
      });
    }
  };

  if (!discountCode) {
    return null;
  }

  return (
    <div>
      <button
        data-testid='add-bundle-to-cart-button'
        className={classNames('AddToCart__Button', { 'AddToCart__Button--disabled': !bundleIsAvailableForSale })}
        type='button'
        disabled={!bundleIsAvailableForSale}
        onClick={event => addProductWithBundleDiscount(discountCode, event)}
      >
        <ShopIcon />
      </button>
    </div>
  );
}
