import React from 'react';
import gql from 'graphql-tag';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from '../../../Common/Icons/ArrowLeft';
import GraphQLData from '../../../../lib/GraphQLData';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import GeneralLink from '../../GeneralLink';
import { getLocalStorage } from '../../../Common/CookieSettings/CookieUtils';
import { PRODUCT_PAGE_EXACT_ROUTE, WISHLIST_PAGE_ROUTE } from '../../../../AppRoutes';

const GetBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery = gql`
  query GetBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery(
    $previousItemId: String
    $curentItemId: String
    $lang: String
  ) {
    getBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery(
      previousItemId: $previousItemId
      curentItemId: $curentItemId
      lang: $lang
    ) {
      url
      description
    }
  }
`;

class SalesAndServiceCentersBackTo extends React.Component {
  getBackButton = () => {
    const { fields, querySalesMapData } = this.props;
    const backLabel =
      fields && fields.route && fields.route.fields['Back To Label']
        ? fields.route.fields['Back To Label']
        : { value: '' };
    const queryData =
      querySalesMapData && querySalesMapData.getBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery
        ? querySalesMapData.getBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery
        : {};
    const { url: backLink, description: backLinkTitle } = queryData,
      previousPageLocation = getLocalStorage('previousPageLocation'),
      previousPageName = getLocalStorage('previousPageTitle'),
      data =
        previousPageLocation &&
        (previousPageLocation.includes(PRODUCT_PAGE_EXACT_ROUTE) || previousPageLocation.includes(WISHLIST_PAGE_ROUTE))
          ? { link: previousPageLocation, title: previousPageName }
          : { link: backLink, title: backLinkTitle };

    return (
      <div>
        <GeneralLink
          fields={{ value: { href: data.link } }}
          className='SalesAndServiceCentersHolderTwoColumns-BackLink'
        >
          <ArrowLeft />
          <Text field={backLabel} />
          &nbsp;{data.title}
        </GeneralLink>
      </div>
    );
  };
  render() {
    const { fields, querySalesMapData } = this.props;
    if (
      !fields ||
      !(querySalesMapData && querySalesMapData.getBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery)
    ) {
      querySalesMapData.refetch();
      return <div className='SalesAndServiceCentersHolderTwoColumns-BackLink' />;
    }

    return <React.Fragment>{this.getBackButton()}</React.Fragment>;
  }
}

export default GraphQLData(GetBackToInfoForSalesAndServiceCentersHolderTwoColumnsQuery, {
  name: 'querySalesMapData',
  options: props => ({
    variables: {
      previousItemId: props.previousId,
      curentItemId: props.sitecoreContext && props.sitecoreContext.itemId ? props.sitecoreContext.itemId : null,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withSitecoreContext()(SalesAndServiceCentersBackTo));
