import React from 'react';
import ChangeQuantityButton, { ChangeQuantityButtonType } from './ChangeQuantityButton';

const EditItemQuantityButton = ({
  type,
  quantity,
  setQuantity
}: {
  type: ChangeQuantityButtonType;
  quantity: number;
  setQuantity: (quantity: number) => void;
}): JSX.Element => {
  async function handleClick() {
    let newQuantity = type === 'plus' ? quantity + 1 : quantity - 1;

    if (newQuantity < 0) {
      newQuantity = 0;
    }

    setQuantity(newQuantity);
  }

  return <ChangeQuantityButton type={type} clicked={handleClick} />;
};

export default EditItemQuantityButton;
