import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import IconLink from '../../Common/IconLink';

/**
 * @class Component that renders a column containing website details in the Footer component.
 *
 * @param {object} props Object that contains the details data for the footer.
 *
 * @returns Returns HTML.
 */
class FooterDetailsColumn extends React.Component {
  /**
   * @method that renders footer  icon links
   *
   */
  getFooterIconLinks(footerIconLinks) {
    const iconLinks = footerIconLinks.map((item, index) => {
      return <IconLink fields={item.fields} key={index + item.id} />;
    });
    return iconLinks;
  }
  render() {
    if (!this.props.fields) {
      return <div className='FooterDetailsColumn' />;
    }
    const { 'Company Title': companyTitle, Address } = this.props.fields;
    return (
      <div className='FooterDetailsColumn'>
        <div className='FooterDetailsColumn-address'>
          <div className='FooterDetailsColumn-address-title'>
            <Text field={companyTitle} />
          </div>
          <div className='FooterDetailsColumn-address-text'>
            <RichText field={Address} />
          </div>
        </div>
        <div>{this.getFooterIconLinks(this.props.itemFields)}</div>
      </div>
    );
  }
}

export default FooterDetailsColumn;
