import React, { createContext, useState, useEffect, ReactNode } from 'react';

export type CookieBotData = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

export type CookieConsentContextState = {
  isCookieConsentGiven: boolean;
};

export const CookieConsentContext = createContext({
  isCookieConsentGiven: false
});

export const CookieConsentProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isCookieConsentGiven, setCookieConsentGiven] = useState(false);

  const readCookiebotConsent = (): CookieBotData | undefined => {
    return typeof window !== 'undefined' ? (window?.Cookiebot?.consent as CookieBotData) ?? undefined : undefined;
  };

  // Function to update the state based on Cookiebot values
  const updateConsent = () => {
    // Currently we are only interested in statistics, which is only allowed when the user gives full consent
    setCookieConsentGiven(readCookiebotConsent()?.statistics ?? false);
  };

  // Check cookiebot consent and listen to cookiebot events
  useEffect(() => {
    if (readCookiebotConsent()) {
      // Consent was already initialized, we can just update it now
      updateConsent();
    } else {
      // In case its not initialized yet, we can listen to the following event
      window.addEventListener('CookiebotOnConsentReady', () => {
        updateConsent();
      });
    }

    // For this case, the user changes the consent during the session:

    // Accept event
    window.addEventListener('CookiebotOnAccept', () => {
      updateConsent();
    });

    // Decline
    window.addEventListener('CookiebotOnDecline', () => {
      updateConsent();
    });
  }, []);

  return <CookieConsentContext.Provider value={{ isCookieConsentGiven }}>{children}</CookieConsentContext.Provider>;
};
