import i18n from 'i18next';
import { SearchFacet } from '../../../CoveoSettings';

export const ExtrusionWeldersFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimfeatures',
    label: () => i18n.t('SEARCH | Facets pimfeatures')
  },
  {
    field: 'pimweldingadditive',
    label: () => i18n.t('SEARCH | Facets pimweldingadditive')
  },
  {
    field: 'pimweldingmaterials',
    label: () => i18n.t('SEARCH | Facets pimweldingmaterials')
  },
  {
    field: 'pimoutputma',
    label: () => i18n.t('SEARCH | Facets pimoutputma'),
    numeric: true,
    unit: 'kg/h'
  },
  {
    field: 'pimweightwithoutpowercordmin',
    label: () => i18n.t('SEARCH | Facets pimweight'),
    numeric: true,
    precision: 2,
    unit: 'kg'
  },
  {
    field: 'pimsheetthicknessrangema',
    label: () => i18n.t('SEARCH | Facets pimsheetthicknessrangema'),
    numeric: true,
    unit: 'mm'
  }
];
