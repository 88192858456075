import React from 'react';
import SubMenuItemsDesktop from './SubMenuItemsDesktop/index';
import { Visible } from 'react-grid-system';
import SubMenuItemsMobile from './SubMenuItemsMobile/index';

/**
 * @class SubMenuItems display and handle actions on sub menu links
 */
class SubMenuItems extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='SubMenuItems'>
          <Visible lg xl>
            <SubMenuItemsDesktop />
          </Visible>
          <Visible xs sm md>
            <SubMenuItemsMobile />
          </Visible>
        </div>
      </React.Fragment>
    );
  }
}

export default SubMenuItems;
