import { CoveoSitePipelinesType, CoveoSiteSearchHubsType } from '../../../CoveoSettings';

export const CoveoProductsMicroOpticsPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsMicroOpticsSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Micro-Optics',
  LeisterGroup: 'Micro-Optics',
  LeisterTechnologies: 'Micro-Optics',
  Weldy: 'Micro-Optics'
};
