import React, { useEffect } from 'react';
import i18n from 'i18next';
import { ShopProduct, ProductData, ShopProductVariant } from '../lib/shopify/types';
import Tag from '../../Common/Tag/Tag';
import PriceDetails, { PriceDetailVariants } from '../PriceDetails/PriceDetails';
import AvailabilityTooltip from '../AvailabilityTooltip';
import Techspec from '../../Product/Techspec';
import AddToCart from '../AddToCart';
import ChangeQuantityButton from '../Cart/EditItemQuantityButton/ChangeQuantityButton';
import { pushViewItemEvent } from '../lib/shopify/analytics/viewItem';
import classNames from 'classnames';
import FindADealerActions, { FindADealerActionsComponentProps } from '../FindADealerActions';
import { ProductItem } from '../../Product/types';
import { getSpec } from '../../Product/productItem';

type AddToCartTeaserProps = {
  productData?: ProductData;
  productItem: ProductItem;
  shopProduct?: ShopProduct;
  shopProductVariant?: ShopProductVariant;
  tags?: string[];
  noImage?: boolean;
  priceVariant?: PriceDetailVariants;
  findADealerProps?: FindADealerActionsComponentProps;
  showTechSpec?: boolean;
  shouldRenderShop?: boolean;
  onAddToCart?: () => void;
};

export default function AddToCartTeaser({
  productData,
  productItem,
  shopProduct = undefined,
  shopProductVariant,
  tags,
  noImage = false,
  priceVariant = '',
  findADealerProps,
  showTechSpec = true,
  shouldRenderShop = true,
  onAddToCart
}: AddToCartTeaserProps): JSX.Element {
  const [quantity, setQuantity] = React.useState<number>(1);
  const [spec, setSpec] = React.useState<{ name: string; value: string }[]>([]);

  function lowerQuanity() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  function increaseQuantity() {
    setQuantity(quantity + 1);
  }

  useEffect(() => {
    if (shopProduct && shopProductVariant) {
      pushViewItemEvent(shopProduct, shopProductVariant, productData);
    }

    if (productItem) {
      setSpec(getSpec(productItem));
    }
  }, [shopProduct, productItem, productData, shopProductVariant]);

  return (
    <div
      className={classNames('AddToCartTeaser', {
        'AddToCartTeaser--no-image': noImage,
        'AddToCartTeaser--no-variant': !productItem && !shopProductVariant
      })}
    >
      {productItem && (
        <div className='AddToCartTeaser__image-and-product-info'>
          {shopProduct && !noImage && shopProduct.featuredImage?.url && (
            <div className='AddToCartTeaser__image'>
              <img
                alt={shopProduct.featuredImage?.altText || shopProduct.title}
                src={`${shopProduct.featuredImage.url}&width=112&height=112`}
              />
            </div>
          )}
          {productItem && (
            <div className='AddToCartTeaser__product-info'>
              <div className='AddToCartTeaser__article-nr-label'>{i18n.t('SHOP | Article number')}</div>
              <div className='AddToCartTeaser__article-nr'>
                {shopProductVariant && shopProduct && <AvailabilityTooltip shopProduct={shopProduct} />}
                <div className='AddToCartTeaser__article-nr-text'>{productItem.articleNumber}</div>
              </div>
              {showTechSpec && <Techspec spec={spec} />}
              {tags && tags?.length > 0 ? (
                <div className='AddToCartTeaser__tags'>
                  {tags?.map(tag => (
                    <Tag key={tag} type='secondary'>
                      {tag}
                    </Tag>
                  ))}
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
      {(shopProductVariant || findADealerProps) && (
        <div className='AddToCartTeaser__price-and-add-to-cart'>
          {shopProductVariant && (
            <div className='AddToCartTeaser__price'>
              <div className='AddToCartTeaser__price-details'>
                <PriceDetails
                  currencyCode={shopProductVariant.price.currencyCode}
                  currentPriceAmount={shopProductVariant.price.amount}
                  oldPriceAmount={shopProductVariant.compareAtPrice?.amount ?? ''}
                  showOldPrice={!!shopProductVariant.compareAtPrice?.amount}
                  showDiscountTag={!!shopProductVariant.compareAtPrice?.amount}
                  variant={priceVariant}
                />
              </div>
            </div>
          )}

          {shopProductVariant?.availableForSale && (
            <div className='AddToCartTeaser__AddToCart'>
              <div className='CartItem__quantity'>
                <ChangeQuantityButton type='minus' clicked={lowerQuanity} />
                <p className='CartItem__quantity-text'>
                  <span data-testid='cart-item-quantity-text' className='CartItem__quantity-value'>
                    {quantity}
                  </span>
                </p>
                <ChangeQuantityButton type='plus' clicked={increaseQuantity} />
              </div>
              <AddToCart
                shopProductVariants={[shopProductVariant]}
                availableForSale={shopProductVariant?.availableForSale}
                quantity={quantity}
                productItem={productItem}
                productData={productData}
                hideButtonText
                isUsedProduct={shopProduct?.condition?.value === 'used' || false}
                onAddToCart={onAddToCart}
              />
            </div>
          )}
          {!shopProductVariant?.availableForSale && findADealerProps && (
            <div className='AddToCartTeaser__find-a-dealer click_button_find_dealer_pdp'>
              <FindADealerActions {...findADealerProps} shouldRenderShop={shouldRenderShop} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
