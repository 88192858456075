import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import classNames from 'classnames';
import GeneralLink from '../GeneralLink/index';
import ArrowRight from '../Icons/ArrowRight';
import { removeWhiteSpaces } from '../../../utils/Utils';

/**
 * Page Linker Automatically component with left right image display options that select one hero or page item to colect data
 */
class PageLinkerAutomaticallyComponent extends React.Component {
  /**
   *
   * the function that return the content with image
   */
  getImageContent = () => {
    const { RightImagePosition } = this.props.fields,
      pageSelection = this.props.fields['Hero Selection'];

    const { Image: ImageData, 'ClickText GTM English': englishGtm } = pageSelection.fields,
      PageSelection = pageSelection.fields[this.props.urlToUse] || null;

    const cssClass = classNames('PageLinker-Image', {
      'PageLinker-Right': RightImagePosition.value
    });
    return (
      <Col sm={12} md={12} lg={7} xl={7} className={cssClass}>
        <GeneralLink
          className='PageLinker-Image-Link'
          clickClassName={
            'click_content_block_detail_page_linker ' + removeWhiteSpaces(englishGtm ? englishGtm.value : null)
          }
          fields={PageSelection}
        >
          <Image className='PageLinker-Image-Item' field={ImageData} />
        </GeneralLink>
      </Col>
    );
  };

  /**
   * the function that return the content with text
   */
  getTextContent = isDesktop => {
    const { RightImagePosition } = this.props.fields,
      pageSelection = this.props.fields['Hero Selection'];

    const {
        'Linker Button Label': LinkerButtonLabel,
        Overline,
        Title,
        Text: TextField,
        'ClickText GTM English': englishGtm
      } = pageSelection.fields,
      PageSelection = pageSelection.fields[this.props.urlToUse] || null;
    const cssClass = classNames('PageLinker-NOPadding-Right', {
      'PageLinker-Left': !!RightImagePosition.value && isDesktop
    });

    return (
      <Col sm={12} md={12} lg={4} xl={4} className={cssClass}>
        <div className='PageLinker-TextContent'>
          <Text className='PageLinker-TextContent-Category' tag='div' field={Overline} />
          <Text className='PageLinker-TextContent-Title' tag='div' field={Title} />
          <RichText className='PageLinker-TextContent-Description' field={TextField} />
          <GeneralLink
            className='PageLinker-TextContent-Link'
            clickClassName={
              'click_content_block_detail_page_linker ' + removeWhiteSpaces(englishGtm ? englishGtm.value : null)
            }
            fields={PageSelection}
          >
            {LinkerButtonLabel && LinkerButtonLabel.value ? LinkerButtonLabel.value : ''}
            <ArrowRight />
          </GeneralLink>
        </div>
      </Col>
    );
  };

  render() {
    const propsFields = this.props.fields ? this.props.fields : null;
    if (!propsFields || !propsFields['Hero Selection'] || !propsFields['Hero Selection'].fields) {
      return <div className='whiteBackground componentContainer' />;
    }

    const { RightImagePosition, BackgroundColor } = this.props.fields,
      leftImage = isDesktop => {
        return (
          <React.Fragment>
            {this.getImageContent()}
            {this.getTextContent(isDesktop)}
          </React.Fragment>
        );
      },
      rightImage = isDesktop => {
        return (
          <React.Fragment>
            {this.getTextContent(isDesktop)}
            {this.getImageContent()}
          </React.Fragment>
        );
      },
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      };
    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='componentContainer'>
          <Row nogutter className='PageLinker click_content_block_detail_page_linker' justify='between' align='end'>
            <Visible lg xl>
              {RightImagePosition.value ? rightImage(true) : leftImage(true)}
            </Visible>
            <Visible xs sm md>
              {leftImage(false)}
            </Visible>
          </Row>
        </div>
      </div>
    );
  }
}

export default PageLinkerAutomaticallyComponent;
