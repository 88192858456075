import React from 'react';
import WishlistIcon from '../../../Common/Icons/WishlistIcon';

const WishlistButton = props => {
  return (
    <div
      className={props.cssClass}
      onClick={props.onClick}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          props.onClick();
        }
      }}
      role='button'
      tabIndex='0'
    >
      <WishlistIcon />
      {props.getTooltip}
    </div>
  );
};

export default WishlistButton;
