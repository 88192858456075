import {
  setCareerFormData,
  setContactPointInfoId,
  setDropdownForms,
  setJobInformationHidden
} from '../../../store/contactForms/actions';
import { setContactPersonData } from '../../../store/contactPerson/actions';
import { store } from '../../../store/store';
import { setSessionStorage } from '../CookieSettings/CookieUtils';

const getJobInformationOnRedux = (sitecoreContext: any) => {
  const routeFields = sitecoreContext.route.fields;

  if (routeFields) {
    const {
      Title,
      'Job Location': Location,
      'Job Work Load': workload,
      'Job Image': Image,
      'Job Country': Country,
      'Open Position Label': positionLabel
    } = routeFields;
    const country = Country?.fields?.RegionName;
    const location = Location?.fields?.['Category Tag'];
    const jobInfoHidden = `${Title?.value}${location?.value && ','}${location?.value}${country?.value && ','}${
      country?.value
    }`;

    store.dispatch(
      setCareerFormData({
        title: Title?.value,
        location: location?.value,
        workload: workload?.fields?.['Category Tag']?.value,
        country: country?.value,
        picture: Image,
        label: positionLabel?.value
      })
    );
    store.dispatch(setJobInformationHidden(jobInfoHidden));
  }
};

export const setCareerContactContext = ({
  sitecoreContext,
  contactPageForms,
  id,
  name,
  email,
  salesPoint,
  phone
}: any) => {
  if (id) {
    store.dispatch(setContactPointInfoId(id));
  }

  if (contactPageForms?.length > 0) {
    store.dispatch(
      setDropdownForms(
        contactPageForms?.length > 0
          ? contactPageForms.map((item: any) => ({
              title: item.fields.Name.value,
              link: item.fields.Link.value.href,
              form: item.fields.Form.value
            }))
          : []
      )
    );
  }

  store.dispatch(setContactPersonData({ name, email: email?.value, salesPoint }));

  getJobInformationOnRedux(sitecoreContext);
  setSessionStorage(
    'person',
    JSON.stringify({
      name,
      email,
      salesPoint,
      phone,
      forms: contactPageForms
    })
  );
};
