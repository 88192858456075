import React from 'react';
import FooterLabel from './FooterLabel';
import FooterCopyright from './FooterCopyright';
import SocialLink from './SocialLink';
import FooterDetailsColumn from './FooterDetailsColumn';
import FooterColumn from './FooterColumn';
import { FooterProps } from './types';

/**
 * @class Component that renders the footer of the application with all informations received on data.
 */
const Footer = ({ fields }: FooterProps) => {
  /**
   * @method that renders footer columns with general links items
   */
  const getFooterColumns = () => {
    const { 'Footer Columns': footerColumns } = fields;
    const footerColumnsArray = footerColumns ? footerColumns : [];
    const columns = footerColumnsArray.map((item: any) => {
      const linkItems = item.fields['Footer General Links'] ? item.fields['Footer General Links'] : [];
      return <FooterColumn fields={item.fields} itemFields={linkItems} key={item.id} />;
    });

    return columns;
  };
  /**
   * @method that renders footer details with icon links
   *
   *  @param  {object}  Object that contains  data
   */

  const getFooterDetailsColumn = () => {
    const iconFields = fields['Footer Icon Links'] ? fields['Footer Icon Links'] : [];
    return <FooterDetailsColumn fields={fields} itemFields={iconFields} />;
  };
  /**
   * @method that renders footer social icon links
   *
   * @param  {object}  Object that contains  data
   */
  const getSocialLinks = () => {
    const { 'Footer Social Links': footerSocialLinks } = fields;
    const socialLinksArray = footerSocialLinks ? footerSocialLinks : [];
    const socialLinks = socialLinksArray.map((item: any) => {
      // @ts-ignore TODO: TS JSX error, strange one, revisit after migrating all to TS, or upgrading React
      return <SocialLink fields={item.fields} key={item.id} />;
    });
    return socialLinks;
  };

  if (!fields) {
    return <div className='Footer' />;
  }

  return (
    <div className='Footer'>
      <div className='Footer-Top'>
        {getFooterColumns()}
        {getFooterDetailsColumn()}
      </div>
      <div className='Footer-Bottom'>
        <div className='Footer-Bottom-Group-Copyright'>
          {/*
						// @ts-ignore TODO: TS JSX error, strange one, revisit after migrating all to TS, or upgrading React */}
          <FooterCopyright fields={fields} />
        </div>
        <div className='Footer-Bottom-Group-Label'>
          <div className='Footer-Social'>{getSocialLinks()}</div>
          {/*
						// @ts-ignore TODO: TS JSX error, strange one, revisit after migrating all to TS, or upgrading React */}
          <FooterLabel fields={fields} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
