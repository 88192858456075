import { apiGatewayHost } from '../../Constants/General';
import { getSitecoreApiHost } from '../../AppGlobals';

const MASTER_PRODUCT_URL = apiGatewayHost + '/product-data/v1/products/';

export const MASTER_PRODUCT_ENDPOINT = (category_name, language, tenant) => {
  const endpoint = MASTER_PRODUCT_URL + tenant + '/' + category_name + '/' + language;

  return endpoint;
};

const SHORT_PRODUCT_URL = apiGatewayHost + '/product-data/v1/product/short';

export const SHORT_PRODUCT_ENDPOINT = (category_name, variantId, language, tenant) => {
  const endpoint = `${SHORT_PRODUCT_URL}?name=${category_name}&lang=${language}&tenant=${tenant}`;

  if (variantId) {
    return `${endpoint}&variantId=${variantId.replace('-', '.')}`;
  }

  return endpoint;
};

export const PRODUCT_ITEMS_ENDPOINT = (productId, countryCode, language, tenant) => {
  const endpoint =
    apiGatewayHost + '/product-data/v1/product-items/' + tenant + '/' + productId + '/' + countryCode + '/' + language;

  return endpoint;
};

export const PRODUCT_ITEMS_TECHNICAL_DETAILS_ENDPOINT = (arcticle_numbers, language) => {
  const articleNumbers = arcticle_numbers.map(article_number => 'articleNumbers=' + article_number).join('&');

  const endpoint = `${apiGatewayHost}/product-data/v1/product-items/technical-titles-with-subtitles/${language}?${articleNumbers}`;

  return endpoint;
};

export const ACCESSORIES_ENDPOINT = (accessoriesName, article_number, language, tenant) => {
  const articleNumber = article_number ? '/items/' + article_number : '';
  const endpoint = MASTER_PRODUCT_URL + tenant + '/' + accessoriesName + articleNumber + '/' + language;

  return endpoint;
};

export const MASTER_DOWNLOADS_ENDPOINT = (product_name, article_number, language) => {
  const articleNumberConstruction = article_number ? '&variantId=' + article_number.toString().replace('-', '.') : '';
  const endpoint =
    getSitecoreApiHost() +
    '/api/MasterProducts/getDownloadsExtended?name=' +
    product_name +
    '&lang=' +
    language +
    articleNumberConstruction;

  return endpoint;
};

export const COMPARE_PRODUCT_ENDPOINT = (language, tenant) => {
  const endpoint = apiGatewayHost + '/product-data/v1/product/comparedObjects?lang=' + language + '&tenant=' + tenant;

  return endpoint;
};

export const ADDRESS_LIST_ENDPOINT = '/api/Addresses/getAddresses';
export const CREATE_ADDRESS_ENDPOINT = '/api/Addresses/createAddress';
export const UPDATE_ADDRESS_ENDPOINT = '/api/Addresses/updateAddress';
export const DELETE_ADDRESS_ENDPOINT = addressId => '/api/Addresses/deleteAddress/' + addressId;
export const GET_COUNTRIES_ENDPOINT = language => `/api/Countries/getCountries?language=${language}`;
