import React, { useContext } from 'react';
import { ActionType, CartContext } from '../context/CartContext';
import ShopIcon24 from '../../Common/Icons/ShopIcon24';

export const FloatingCartToggle = (): JSX.Element | null => {
  const { state: cartState, dispatch } = useContext(CartContext);
  const cartTotalQuantity = cartState.cart?.totalQuantity || 0;

  const openCartModal = () => {
    dispatch({
      type: ActionType.OPEN_CART
    });
  };

  const rendering =
    cartTotalQuantity > 0 ? (
      <button data-testid='floating-cart-toggle' className='FloatingCartToggle' onClick={openCartModal}>
        <span data-testid='floating-cart-toggle-quantity' className='FloatingCartToggle__Number'>
          {cartTotalQuantity}
        </span>
        <ShopIcon24 color='white' size={24} />
      </button>
    ) : null;

  return rendering;
};

export default FloatingCartToggle;
