import React from 'react';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { setValueInput, setValueInSelect } from '../../../utils/Utils';

class ExpandedFormWrapper extends React.Component {
  async setValueForInput(selector, val, isSelecteInput) {
    if (selector && val) {
      const inputElement = await this.checkIFWeHaveInput(selector);
      if (inputElement) {
        const labelElement = inputElement.previousElementSibling;
        labelElement.classList.add('active');
        if (isSelecteInput) {
          setValueInSelect(inputElement, val);
        } else {
          setValueInput(inputElement, val);
        }
      }
    }
  }

  checkIFWeHaveInput = async selector => {
    while (document.querySelector(selector) === null && document.querySelector('form') === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }

    return document.querySelector(selector);
  };

  render() {
    return (
      <div className='ExpandedFormWrapper componentContainer'>
        <div className='ExpandedFormWrapper-Container'>
          <Placeholder name='jss-expanded-form-wrapper' rendering={this.props.rendering} />
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(ExpandedFormWrapper);
