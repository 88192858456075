import React, { ComponentType } from 'react';
import GeneralLink from '../../Common/GeneralLink';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { TextField } from '../../../utils/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type BackLinkProps = {
  backFieldData: { url: string; description: string };
  buttonText: TextField;
  location: any;
};

export const PageBackLink = ({ backFieldData, buttonText, location }: BackLinkProps) => {
  const { url: backLink, description: backLinkDescription } = backFieldData;

  if (!backLink || !backLinkDescription) {
    return null;
  }

  return (
    // @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React
    <GeneralLink
      fields={{ value: { href: location?.state?.origin === 'lister' ? location?.state?.from : backLink } }}
      className='PageBackLink'
    >
      <ArrowLeft />
      <Text field={buttonText} />
      &nbsp;{backLinkDescription}
    </GeneralLink>
  );
};

export default withRouter<RouteComponentProps & BackLinkProps, ComponentType<RouteComponentProps & BackLinkProps>>(
  PageBackLink
);
