import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CompareIcon from '../../../Common/Icons/CompareIcon';
import { getCookie } from '../../../Common/CookieSettings/CookieUtils';
import { COMPARE } from '../../../Common/CookieSettings/CookieConstants';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../../Common/GeneralLink/index';
import { setCurrentComparedCategory } from '../../../../store/compare/actions';
import { ACCESSORIES_PRODUCT_TYPE } from '../../../Common/GeneralConstants/GeneralConstants';
import { PRODUCT_PAGE_ROUTE } from '../../../../AppRoutes';

class CompareFloatingIcon extends React.Component {
  state = {
    numberOfItemsInCompare: 0
  };

  componentDidMount() {
    document.addEventListener('changedCompareStateOnCookies', this.getDataFromCookies, false);
    this.getDataFromCookies();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryEn !== this.props.categoryEn || prevProps.subCategoryEn !== this.props.subCategoryEn)
      this.getDataFromCookies();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getDataFromCookies, false);
  }

  updateNumberOfItemsInCompare(newNumber) {
    const { numberOfItemsInCompare } = this.state;
    if ((newNumber || newNumber === 0) && numberOfItemsInCompare !== newNumber) {
      this.setState({
        numberOfItemsInCompare: newNumber
      });
    }
  }

  getDataFromCookies = () => {
    const compareListCookie = JSON.parse(getCookie(COMPARE)),
      {
        categoryEn,
        subCategoryEn,
        match,
        productType,
        sitecoreContext: {
          route: { name: sitecorePageName }
        },
        updateSelectedCategory
      } = this.props,
      cookieListKeys = compareListCookie ? Object.keys(compareListCookie) : [],
      sitecorePageNameCategory = sitecorePageName ? sitecorePageName.replace(/-/g, ' ') : '',
      compareCategory =
        productType && productType === ACCESSORIES_PRODUCT_TYPE
          ? subCategoryEn && subCategoryEn.split(',')[0]
          : categoryEn && categoryEn.split(',')[0];

    let newNumberToUpdateWith = 0;

    if (
      compareCategory &&
      match &&
      match.params &&
      match.params.sitecoreRoute &&
      match.params.sitecoreRoute === PRODUCT_PAGE_ROUTE &&
      compareListCookie &&
      compareListCookie[compareCategory]
    ) {
      //this happens when we are on product detail page route
      //and we have categoryEn in sitecore
      newNumberToUpdateWith = compareListCookie[compareCategory].length;
      updateSelectedCategory(compareCategory);
    } else if (sitecorePageName && compareListCookie && compareListCookie[sitecorePageNameCategory]) {
      //this happens when we are on product listing
      //page and we have the category in sitecoreRoute
      updateSelectedCategory(sitecorePageNameCategory);
      newNumberToUpdateWith = compareListCookie[sitecorePageNameCategory].length;
    } else if (compareListCookie && cookieListKeys.length) {
      //on the rest of the pages we count all items from
      //compare cookies and display that number
      newNumberToUpdateWith = cookieListKeys.reduce((total, currentValue) => {
        return total + compareListCookie[currentValue].length;
      }, 0);
    }

    this.updateNumberOfItemsInCompare(newNumberToUpdateWith);
  };

  render() {
    if (!this.props || !this.props.fields || !this.props.fields['Compare Page Link']) return null;

    const {
        fields: { 'Compare Page Link': ComparePageLink },
        sitecoreContext: {
          route: {
            fields: { 'Show Compare Floating Icon': ShowCompareFloatingIcon }
          }
        }
      } = this.props,
      { numberOfItemsInCompare } = this.state;

    if (numberOfItemsInCompare === 0 || (ShowCompareFloatingIcon && !ShowCompareFloatingIcon.value)) {
      return <React.Fragment />;
    }
    return (
      <GeneralLink fields={ComparePageLink} className='CompareFloatingIcon'>
        <div className='CompareFloatingIcon-Number'>{numberOfItemsInCompare}</div>
        <CompareIcon />
      </GeneralLink>
    );
  }
}

const mapStateToProps = state => {
  return {
    categoryEn: state.productDetails.masterProductData ? state.productDetails.masterProductData.CategoryEn : null,
    subCategoryEn: state.productDetails.masterProductData ? state.productDetails.masterProductData.SubCategoryEn : null,
    productType: state.productDetails.masterProductData ? state.productDetails.masterProductData.ProductType : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSelectedCategory: category => dispatch(setCurrentComparedCategory(category))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(CompareFloatingIcon)));
