import React from 'react';
import { connect } from 'react-redux';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink';
import AddWishListButton from './AddWishListButton';
import OverlayDialog from '../../OverlayDialog';
import { setNewListErrorCode } from '../../../store/wishlist/actions';
import WishlistRegistrationBenefits from './WishlistRegistrationBenefits';
import CreateWishList from './CreateWishList';
import AuthenticatedUserWishlists from './AuthenticatedUserWishlists';
import { checkIfUserIsLoggedIn, logInRedirect, removeWhiteSpaces } from '../../../utils/Utils';

class WishlistComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showOverlay: false };
    this.showAddWishlistButtons = true;

    const showAddNewButtonsFieldName = 'Show Add New Buttons';
    if (
      this.props.fields[showAddNewButtonsFieldName] &&
      this.props.fields[showAddNewButtonsFieldName].value === false
    ) {
      this.showAddWishlistButtons = false;
    }
  }

  renderAnonymousUserWishlist = () => {
    const {
        'Wishlist Name': name,
        'Created By Leister Label': createdLabel,
        'Number Article Label': articlesAmountLabel,
        'Wishlist Items Link': wishlistLink,
        'Created Date': createdDate
      } = this.props.fields,
      createdLongDate = new Date(createdDate.value),
      { amountOfAllWishlistItems } = this.props,
      date = createdLongDate.getDate() + '.' + (createdLongDate.getMonth() + 1) + '.' + createdLongDate.getFullYear(),
      idText = name && name.value ? removeWhiteSpaces(name.value) : null;

    if (!amountOfAllWishlistItems) {
      return null;
    }

    return (
      <GeneralLink
        fields={wishlistLink}
        id={idText}
        className={!this.showAddWishlistButtons && 'SpacingWithoutAddWishlistButtons'}
      >
        <div className='WishlistComponent-Container'>
          <Text field={name} tag='div' className='WishlistComponent-Name' />

          <div className='WishlistComponent-Details'>
            <Text field={createdLabel} />
            &#58; {date} &#124;&nbsp;
            <Text field={articlesAmountLabel} />
            &#58;&nbsp;{amountOfAllWishlistItems}
          </div>
        </div>
      </GeneralLink>
    );
  };

  renderAuthenticatedUsersWishlists = () => {
    return <AuthenticatedUserWishlists fields={this.props.fields} />;
  };

  onAddWishListClick = () => {
    this.setState({ showOverlay: !this.state.showOverlay });
  };

  getOverlay = () => {
    const { fields, setNewListErrorCode, sitecoreContext } = this.props,
      { Title, 'Button Label': buttonLabel } = fields;

    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      return (
        <CreateWishList
          fields={fields}
          onClose={() => {
            this.onAddWishListClick();
            setNewListErrorCode(null);
          }}
        />
      );
    }

    return (
      // NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component
      <OverlayDialog Title={Title} buttonLabel={buttonLabel} onClose={this.onAddWishListClick} onClick={logInRedirect}>
        <WishlistRegistrationBenefits fields={fields} />
      </OverlayDialog>
    );
  };

  // We don't display the default wishlist if it is empty, that's why we need to calculate this number accordingly
  amountOfDisplayedWishlists = () => {
    const { wishLists } = this.props;
    const amountOfWishlists = wishLists.length;
    const defaultWishlistIsEmpty = wishLists.find(wishlist => wishlist.isDefault && wishlist.itemsCount === 0);
    const totalAmountOfDisplayedWishlists = amountOfWishlists - (defaultWishlistIsEmpty ? 1 : 0);
    return totalAmountOfDisplayedWishlists;
  };

  render() {
    const { fields, sitecoreContext } = this.props;
    if (!fields) {
      return <div className='WishlistComponent componentContainer' />;
    }

    const { 'Wishlists Number Label': wishlistNumberLabel } = fields;

    return (
      <div className='WishlistComponent componentContainer'>
        <div className='WishlistComponent-Info'>
          <div>
            {checkIfUserIsLoggedIn(sitecoreContext) && (
              <React.Fragment>{this.amountOfDisplayedWishlists()} &nbsp;</React.Fragment>
            )}
            <Text field={wishlistNumberLabel} tag='div' className='WishlistComponent-Info-NumberWishlist' />
          </div>

          {this.showAddWishlistButtons && <AddWishListButton onButtonClick={this.onAddWishListClick} fields={fields} />}
        </div>
        {checkIfUserIsLoggedIn(sitecoreContext)
          ? this.renderAuthenticatedUsersWishlists()
          : this.renderAnonymousUserWishlist()}

        {this.showAddWishlistButtons && (
          <AddWishListButton hasBackground onButtonClick={this.onAddWishListClick} fields={fields} />
        )}

        {this.state.showOverlay && this.getOverlay()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    amountOfAllWishlistItems: state.wishlistProducts && state.wishlistProducts.amountOfAllWishlistItems,
    wishLists: (state.wishlistProducts && state.wishlistProducts.wishLists) || []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewListErrorCode: error => dispatch(setNewListErrorCode(error))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(WishlistComponent));
