export class Environment {
  static get isServer() {
    return typeof window === 'undefined' || (Object.keys(window).length === 0 && window.constructor === Object);
  }

  static get serverUrl() {
    return `${window.location.protocol}//${window.location.host}`;
  }

  static get reactAppProcessEnv() {
    return process.env;
  }

  static checkGatewayHost(endpoint) {
    if (Environment.isServer && endpoint.includes('http://localhost:44015')) {
      return endpoint.replace('localhost', 'host.docker.internal');
    }
    return endpoint;
  }
}
