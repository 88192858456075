import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsInfraredSourcesPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsInfraredSourcesSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Infrared-Sources',
  LeisterGroup: 'Infrared-Sources',
  LeisterTechnologies: 'Infrared-Sources',
  Weldy: 'Infrared-Sources'
};

export const InfraredSourcesFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimpackagetype',
    label: () => i18n.t('SEARCH | Facets pimpackagetype')
  }
];
