import * as React from 'react';
import { SearchEngine } from '@coveo/headless';

export interface CoveoState {
  engine: SearchEngine;
}

export interface CoveoContextInterface {
  state: CoveoState;
}

export const initialState: CoveoState = {
  engine: {} as SearchEngine
};

const CoveoContext = React.createContext<CoveoContextInterface>({
  state: initialState
});

const CoveoContextProvider = ({
  children,
  engine
}: {
  children: React.ReactNode;
  engine: SearchEngine;
}): JSX.Element => {
  const state = React.useMemo(() => ({ engine }), [engine]);
  const value = React.useMemo(() => ({ state }), [state]);

  return <CoveoContext.Provider value={value}>{children}</CoveoContext.Provider>;
};

export { CoveoContext, CoveoContextProvider };
