import * as actionTypes from './actionTypes';

const initialState = {
  favoriteContactId: null,
  favoriteContactData: null
};

export const favoriteContact = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FAVORITE_CONTACT_ID:
      return {
        ...state,
        favoriteContactId: action.favoriteContactId
      };
    case actionTypes.DELETE_FAVORITE_CONTACT_ID:
      return {
        ...state,
        favoriteContactId: null
      };
    case actionTypes.SET_FAVORITE_CONTACT_DATA:
      return {
        ...state,
        favoriteContactData: action.favoriteContactData
      };
    default:
      return state;
  }
};
