import React from 'react';
import GeneralLink from '../../Common/GeneralLink/index';
import ArrowRight from '../../Common/Icons/ArrowRight';
import ArrowDown from '../../Common/Icons/ArrowDown';
import ArrowUp from '../../Common/Icons/ArrowUp';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class Component that renders a link and a direction arrow
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class LinkElement extends React.Component {
  /**
   * @method getArrowDirection generate the arrow in direction according to data
   */

  getArrowDirection = () => {
    const arrowDirection =
      this.props.fields['Arrow Direction'] && this.props.fields['Arrow Direction'].fields.Direction
        ? this.props.fields['Arrow Direction'].fields.Direction
        : '';
    if (arrowDirection && arrowDirection.value === 'Down') {
      return <ArrowDown />;
    } else if (arrowDirection && arrowDirection.value === 'Up') {
      return <ArrowUp />;
    } else if (arrowDirection && arrowDirection.value === 'Right') {
      return <ArrowRight />;
    }
    return null;
  };

  render() {
    if (!(this.props.fields && this.props.fields.Link)) {
      return <div className='LinkElement componentContainer' />;
    }
    const { Link, 'Button Description': buttonDescription } = this.props.fields;
    const BackgroundColor = this.props.fields['Background Color'];
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
    };
    return (
      <div className='LinkElement-Container whiteBackground' style={inlineStyle}>
        <div className='LinkElement componentContainer' id={removeWhiteSpaces(buttonDescription.value || null)}>
          <GeneralLink className='LinkElement-Link' fields={Link}>
            <Text field={buttonDescription} />
            {this.getArrowDirection()}
          </GeneralLink>
        </div>
      </div>
    );
  }
}

export default LinkElement;
