import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import GeneralLink from '../../Common/GeneralLink';
import ArrowRight from '../../Common/Icons/ArrowRight';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { getFavoriteContactId, setFavoriteContactData } from '../../../store/favoriteContact/actions';
import { getSitecoreGraphqlEndpoint } from '../../../AppGlobals';

class FavouriteContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: getLanguage(this.props.sitecoreContext)
    };
    this.query = `query ContactInfoQuery($id: String) {
			item(path: $id) {
				... on SalesAndServicePointHero {
				  companyName {
					value
				  }
				  street {
					value
				  }
				  streetNumber {
					value
				  }
				  streetAddendum {
					value
				  }
				  zipCode {
					value
				  }
				  city {
					value
				  }
				  country {
					targetItem {
					  field(name: "RegionName") {
						value
					  }
					}
				  }
				  phone {
					value
				  }
				}
			  }
		}`;
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./FavouriteContact.scss');
    const currentLanguage = getLanguage(this.props.sitecoreContext),
      { favoriteContactId, sitecoreContext, getFavoriteContactId } = this.props;
    if (favoriteContactId) {
      this.setState({ language: currentLanguage });
      this.getInfoPointData(favoriteContactId, currentLanguage);
    }

    getFavoriteContactId(favoriteContactId, sitecoreContext);
  }

  componentDidUpdate(prevProps, prevState) {
    const currentLanguage = getLanguage(this.props.sitecoreContext),
      { favoriteContactId } = this.props;
    if (
      favoriteContactId &&
      (prevProps.favoriteContactId !== favoriteContactId || prevState.language !== currentLanguage)
    ) {
      this.setState({ language: currentLanguage });
      this.getInfoPointData(favoriteContactId, currentLanguage);
    }
  }

  getInfoPointData(idVar, lang) {
    const endpoint = getSitecoreGraphqlEndpoint() + '&sc_lang=' + lang;
    this.props.setFavoriteContactData(this.query, endpoint, idVar);
  }

  getButton = label => {
    const { 'Change Contact Link': contactLink } = this.props.fields;
    return (
      <GeneralLink className='FavouriteContact-Button' fields={contactLink}>
        <Text field={label} />
        <ArrowRight />
      </GeneralLink>
    );
  };
  getDetails = () => {
    const { 'Change Label': changeLabel, 'Choose Label': chooseLabel } = this.props.fields,
      { favoriteContactId, favoriteContactData } = this.props;
    if (favoriteContactData && favoriteContactId) {
      const { companyName, street, streetNumber, zipCode, city, country, phone } = this.props.favoriteContactData,
        countryValue = country.targetItem ? country.targetItem.field : null;
      return (
        <div className='FavouriteContact-Details'>
          <Text className='FavouriteContact-Details-Name' field={companyName} tag='div' />
          <div className='FavouriteContact-Details-Street'>
            <Text field={street} />
            &nbsp;
            <Text field={streetNumber} />
          </div>
          <div className='FavouriteContact-Details-City'>
            <Text field={zipCode} />
            &nbsp;
            <Text field={city} />
          </div>

          <Text className='FavouriteContact-Details-Country' field={countryValue} tag='div' />
          <Text className='FavouriteContact-Details-Number' field={phone} tag='div' />
          {this.getButton(changeLabel)}
        </div>
      );
    }
    return this.getButton(chooseLabel);
  };

  render() {
    if (!this.props.fields) {
      return <div className='FavouriteContact' />;
    }
    const { Title, Description } = this.props.fields;
    return (
      <div className='FavouriteContact-Background'>
        <div className='FavouriteContact'>
          <div className='FavouriteContact-Wrapper'>
            <Text className='FavouriteContact-Title' field={Title} tag='h2' />
            <Text className='FavouriteContact-Description' field={Description} tag='span' />
            {this.getDetails()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    favoriteContactId: state.favoriteContact.favoriteContactId,
    favoriteContactData: state.favoriteContact.favoriteContactData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFavoriteContactId: (id, sitecoreContextFields) => dispatch(getFavoriteContactId(id, sitecoreContextFields)),
    setFavoriteContactData: (query, endpoint, contactId) => dispatch(setFavoriteContactData(query, endpoint, contactId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(FavouriteContact));
