import React from 'react';
import { connect } from 'react-redux';
import Accordion from '../../Accordion/index';
import { changeOpenAccordionAction, changeSecondLevelOpenAccordionAction } from '../../../../store/mobileMenu/actions';
import ImageTextRight from '../../../Common/ImageTextRight/index';
import { normalizeSitecoreId, removeWhiteSpaces } from '../../../../utils/Utils';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class SubMenuItemsMobile display and handle actions on sub menu links
 */
class SubMenuItemsMobile extends React.Component {
  /**
   * @method getAccordionContent
   * @returns menu items with text and or without iamage
   */
  getAccordionContent = (itemsArray, isDataFromSitecore, isShowThumbnails) => {
    if (!itemsArray) {
      return null;
    }
    return itemsArray.map((item, index) => {
      if (isDataFromSitecore) {
        const { 'ClickText GTM English': gtm } = item.fields,
          gtmText = gtm && gtm.value ? removeWhiteSpaces(gtm.value) : '';
        return (
          <ImageTextRight
            key={index + item.id}
            img={item.fields.Image}
            link={item.fields.Link}
            title={item.fields.Title}
            isDataFromSitecore={isDataFromSitecore}
            isShowThumbnails={isShowThumbnails}
            measuringClasses={'main_menu_sub_level ' + gtmText}
          />
        );
      } else {
        const productListinPageLink =
            '/' + getLanguage(this.props.sitecoreContext) + '/' + item.NameEn.replace(/ /g, '-'),
          clickText = item.NameEn ? item.NameEn.replace(/ /g, '_') : '';

        return (
          <ImageTextRight
            key={index + item.Name}
            img={item.Media}
            link={productListinPageLink}
            title={item.Name}
            isDataFromSitecore={isDataFromSitecore}
            isShowThumbnails={isShowThumbnails}
            measuringClasses={'main_menu_sub_level ' + clickText}
          />
        );
      }
    });
  };

  /**
   * @method getAccordionBody
   * @description get second level acordions or just items based on layout
   * @returns items or acordion with items
   */
  getAccordionBody = sectionItem => {
    const flyoutTemplate = sectionItem.fields.FlyoutDesignType ? sectionItem.fields.FlyoutDesignType.value : null;
    const targetItems = sectionItem.fields.Level3Items ? sectionItem.fields.Level3Items : null;
    const showThumbnails = sectionItem.fields.ShowThumbnails ? sectionItem.fields.ShowThumbnails.value : null;
    if (
      flyoutTemplate === null &&
      sectionItem.fields.Endpoint &&
      targetItems === null &&
      this.props.productsJson !== null
    ) {
      return this.getAccordionContent(this.props.productsJson, false, true);
    } else if (sectionItem.fields.Endpoint) {
      return;
    }

    if (targetItems.length > 0 && flyoutTemplate !== null) {
      switch (flyoutTemplate) {
        case 'OneSection':
          return this.getAccordionContent(targetItems[0].fields.NavigationElements, true, showThumbnails);
        case 'TwoSections':
        case 'ThreeSections':
          return targetItems.map((item, index) => {
            const accordionBodyItems = this.getAccordionContent(item.fields.NavigationElements, true, showThumbnails);
            const thisAccordionId = item.fields.LinkedItem ? item.fields.LinkedItem.id : item.id;
            const actualAccordionId = normalizeSitecoreId(thisAccordionId);
            return (
              <Accordion
                accordionClass='SecondLevelAccordion'
                key={index + 'mobileContent'}
                headerClicked={() => this.props.changeSecondLevelAccordion(actualAccordionId)}
                openAccordion={this.props.openAccordionSecondLevel}
                accordionId={actualAccordionId}
                headerContent={item.fields.HeadLine.value}
                accordionBody={accordionBodyItems}
              />
            );
          });
        default:
          return '';
      }
    }
    return '';
  };

  /**
   * @method getMobileContent
   * @description Iterate throught items and build html markup for each item.
   */
  getMobileContent = () => {
    if (this.props.submItems !== null) {
      return this.props.submItems.map((item, index) => {
        const thisAccordionId = item.fields.LinkedItem ? item.fields.LinkedItem.id : item.id,
          actualAccordionId = normalizeSitecoreId(thisAccordionId),
          accordionBody = this.getAccordionBody(item),
          { 'ClickText GTM English': gtm } = item.fields,
          gtmText = gtm && gtm.value ? removeWhiteSpaces(gtm.value) : '';
        return (
          <Accordion
            accordionClass={'Accordion'}
            key={index + 'mobileContent'}
            headerClicked={() => this.props.changeOpenAccordion(actualAccordionId)}
            openAccordion={this.props.openAccordion}
            accordionId={actualAccordionId}
            headerContent={item.fields.Name.value}
            accordionBody={accordionBody}
            clickClasses={'main_menu_main_level ' + gtmText}
          />
        );
      });
    }
    return '';
  };
  render() {
    return <React.Fragment>{this.getMobileContent()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    openAccordion: state.mobileMenu.openAccordion,
    openAccordionSecondLevel: state.mobileMenu.openAccordionSecondLevel,
    submItems: state.menuFlyout.submenuItems,
    productsJson: state.menuFlyout.productsJson
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeOpenAccordion: accordionId => dispatch(changeOpenAccordionAction(accordionId)),
    changeSecondLevelAccordion: secondAccordionId => dispatch(changeSecondLevelOpenAccordionAction(secondAccordionId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(SubMenuItemsMobile));
