import React from 'react';
import { SelectProps } from './types';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';

const Select = ({ className, label, options, onChange, name, value, required }: SelectProps): JSX.Element => {
  const [isActive, setIsActive] = React.useState(!!value);

  const handleFocus = (e: React.FocusEvent<HTMLSelectElement>) => {
    setIsActive(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      setIsActive(false);
    }
  };

  return (
    <div>
      <div className={classNames('select', className)}>
        <label
          className={classNames('select__label', {
            'select__label--active': isActive
          })}
          htmlFor={name}
        >
          {label}
          {required && <span className='select__required'>*</span>}
        </label>
        <select
          className='select__input'
          onChange={onChange}
          name={name}
          defaultValue={value}
          id={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <option value=''></option>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <ErrorMessage name={name} component='div' className='form-input-error' />
    </div>
  );
};

export default Select;
