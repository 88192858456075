import React from 'react';
import { connect } from 'react-redux';
import { setStickyItemOnRedux, checkIfRemovedFromStickyMenu } from '../store/stickyMenu/actions';
import { removeWhiteSpaces } from '../utils/Utils';
import { compose } from 'redux';
import { getLanguage } from '../components/Common/CookieSettings/SetLanguageData';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const WithStickyMenu = (WrappedComponent, callback) => {
  return class StickyWrapper extends React.PureComponent {
    constructor(props) {
      super(props);
      this.setStickyItemIfValue();
    }

    componentDidUpdate() {
      this.setStickyItemIfValue();
    }

    setStickyItemIfValue() {
      const { setStickyItem, checkIfRemovedFromStickyMenu } = this.props,
        stickyTitle = this.getStickyMenuTitle(),
        isDisplayedInPage = callback !== undefined ? callback(this.props) : false;

      if (stickyTitle && isDisplayedInPage) {
        setStickyItem(stickyTitle, getLanguage(this.props.sitecoreContext));
      } else if (stickyTitle) {
        checkIfRemovedFromStickyMenu(stickyTitle);
      }
    }

    getStickyMenuTitle() {
      const { fields } = this.props;
      return fields && fields['Sticky Menu Title'] && fields['Sticky Menu Title'].value
        ? fields['Sticky Menu Title'].value
        : null;
    }

    render() {
      const stickyTitle = this.getStickyMenuTitle(),
        isDisplayedInPage = callback !== undefined ? callback(this.props) : false;
      if (!stickyTitle || !isDisplayedInPage) {
        return <WrappedComponent {...this.props} />;
      }

      const stickyId = removeWhiteSpaces(stickyTitle);

      return (
        <div id={stickyId} className='Components-StickyMenu-Padding'>
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
};

const MapDispatchToProps = dispatch => {
  return {
    setStickyItem: (stickyTitle, currentLanguage) => dispatch(setStickyItemOnRedux(stickyTitle, currentLanguage)),
    checkIfRemovedFromStickyMenu: stickyTitle => dispatch(checkIfRemovedFromStickyMenu(stickyTitle))
  };
};

const composedWithStickyMenu = compose(connect(null, MapDispatchToProps), withSitecoreContext(), WithStickyMenu);
export default composedWithStickyMenu;
