import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deleteDeviceNewAddedDevices, addDevices, setDeviceErrorCode } from '../../../../../store/mydevices/actions';
import GeneralLink from '../../../../Common/GeneralLink';
import ArrowLeft from '../../../../Common/Icons/ArrowLeft';
import TitleAndDescription from './TitleAndDescription';
import AddDevice from './AddDevice';
import ConfirmationBanner from './ConfirmationBanner';
import NewAddedDeviceList from './NewAddedDeviceList';
import InfoIcon from '../../../../Common/Icons/InfoIcon';
import OverlayFindSerialAndArticle from './OverlayFindSerialAndArticle';
import DevicesAccountInformation from './DevicesAccountInformation';
import RegisterDeviceList from './RegisterDevicesList';
import DeviceConfirmationButtons from './DeviceConfirmationButtons';

class MyAccountDeviceRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isFindOverlay: false, isConfirmed: false };
  }
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./MyAccountDeviceRegistration.scss');
  }

  onWhereToFindSerialAndArticleClick = () => {
    this.setState({ isFindOverlay: !this.state.isFindOverlay });
  };
  getBackButton = () => {
    const { backButtonLabel } = this.props.fields.data.item;
    return (
      <GeneralLink className='DeviceRegistration-BackButton componentContainer' fields={{ value: { href: './' } }}>
        <ArrowLeft />
        <Text field={backButtonLabel} />
      </GeneralLink>
    );
  };

  getNoDevicesAddedYet = () => {
    const { noDevicesAddedText } = this.props.fields.data.item;
    return (
      <Text field={noDevicesAddedText} tag='div' className='DeviceRegistration-NoDevicesText  componentContainer' />
    );
  };

  getWhereFindSerialAndArticle = () => {
    const { whereFindSerialNumberButtonLabel } = this.props.fields.data.item;
    return (
      <div
        onClick={this.onWhereToFindSerialAndArticleClick}
        onKeyDown={e => {
          if (e.target === 13) this.onWhereToFindSerialAndArticleClick();
        }}
        role='button'
        tabIndex='0'
        aria-label='Opens Overlay'
        className='componentContainer'
      >
        <div className='DeviceRegistration-FindSerialAndArticle '>
          <InfoIcon />
          <Text field={whereFindSerialNumberButtonLabel} />
        </div>
        <OverlayFindSerialAndArticle
          fields={this.props.fields.data.item}
          isOverlay={this.state.isFindOverlay}
          onClose={this.onWhereToFindSerialAndArticleClick}
        />
      </div>
    );
  };

  toggleIsConfirmed = () => {
    this.setState({ isConfirmed: !this.state.isConfirmed });
  };

  onConfirmAllDevices = () => {
    const { newAddedDevicesList, addDevices } = this.props;
    const devicesData = newAddedDevicesList.map(item => ({
      ArticleNumber: item.articleNumber,
      SerialNumber: item.serialNumber,
      InventoryNumber: item.inventoryNumber
    }));
    addDevices(devicesData);
    this.setState({ isConfirmed: false });
  };

  render() {
    const { fields, newAddedDevicesList, deleteDeviceNewAddedDevices } = this.props,
      dataItem = fields && fields.data && fields.data.item,
      userData = fields && fields.data && fields.data.userQuery,
      { isConfirmed } = this.state;
    return (
      <div className='DeviceRegistration-Container container1920'>
        {(dataItem && (
          <div className='DeviceRegistration '>
            {this.getBackButton()}

            <TitleAndDescription fields={dataItem} />
            <div className='DeviceRegistration-Wrapper'>
              <ConfirmationBanner fields={dataItem} isConfirmed={isConfirmed} />
              {!isConfirmed && (
                <React.Fragment>
                  <AddDevice fields={dataItem} />
                  {(newAddedDevicesList && newAddedDevicesList.length && (
                    <NewAddedDeviceList
                      fields={dataItem}
                      onConfirmClick={() => {
                        this.toggleIsConfirmed();
                        this.props.setDeviceErrorCode(null);
                      }}
                      newAddedDevices={newAddedDevicesList}
                      deleteDevice={deleteDeviceNewAddedDevices}
                    />
                  )) ||
                    this.getNoDevicesAddedYet()}
                  {this.getWhereFindSerialAndArticle()}
                </React.Fragment>
              )}
              {isConfirmed && (
                <React.Fragment>
                  {userData && <DevicesAccountInformation fields={dataItem} dataUser={userData} />}

                  <RegisterDeviceList fields={dataItem} newAddedDevices={newAddedDevicesList} />
                  <DeviceConfirmationButtons
                    fields={dataItem}
                    onAddMoreClick={this.toggleIsConfirmed}
                    onConfirmData={this.onConfirmAllDevices}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        )) ||
          ''}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    newAddedDevicesList: state.myDevices.newAddedDevicesList,
    errorAtAddingDevice: state.myDevices.errorAtAddingDevice
  };
};
const mapDispatchToProps = dispatch => {
  return {
    deleteDeviceNewAddedDevices: (articleNumber, serialNumber) =>
      dispatch(deleteDeviceNewAddedDevices(articleNumber, serialNumber)),
    addDevices: data => dispatch(addDevices(data)),
    setDeviceErrorCode: errorCode => dispatch(setDeviceErrorCode(errorCode))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountDeviceRegistration);
