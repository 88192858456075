import React from 'react';
import { Visible } from 'react-grid-system';
import classNames from 'classnames';
import CardsCarousel from '../../CardsCarousel/CardsCarousel';
import SolutionCard from '../SolutionCard/index';

class MobileGallery extends React.Component {
  getCards() {
    const { fields, exploreMoreLabel, urlToUse } = this.props;
    return fields['Solution Heroes']
      .map((item, index) => {
        if (item.fields) {
          return (
            <SolutionCard
              isMobile={true}
              exploreMoreLabel={exploreMoreLabel}
              key={index + item.id}
              data={item}
              urlToUse={urlToUse}
            />
          );
        }
        return null;
      })
      .filter(item => item !== null);
  }

  getCarouselOrCards(nrOfItemsToShow) {
    if (this.props.fields['Solution Heroes'].length > nrOfItemsToShow) {
      return <CardsCarousel nrOfItemsToShow={nrOfItemsToShow}>{this.getCards()}</CardsCarousel>;
    }

    const cssClass = classNames({
      'SolutionCardsGallery-Mobile-MobileContainer': nrOfItemsToShow === 1,
      'SolutionCardsGallery-Mobile-TabletContainer': nrOfItemsToShow === 2
    });

    return <div className={cssClass}>{this.getCards()}</div>;
  }

  render() {
    if (!this.props.fields['Solution Heroes'] || this.props.fields['Solution Heroes'].length < 1) {
      return null;
    }

    return (
      <div className='SolutionCardsGallery-Mobile'>
        <Visible md>{this.getCarouselOrCards(2)}</Visible>
        <Visible xs sm>
          {this.getCarouselOrCards(1)}
        </Visible>
      </div>
    );
  }
}

export default MobileGallery;
