import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsIndustrialHotAirDevicesPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsIndustrialHotAirDevicesSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Industrial-hot-air-devices',
  LeisterGroup: 'Industrial-hot-air-devices',
  LeisterTechnologies: 'Industrial-hot-air-devices',
  Weldy: 'Industrial-hot-air-devices'
};

export const IndustrialHotAirDevicesFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimdevicetype',
    label: () => i18n.t('SEARCH | Facets pimdevicetype'),
    multi: false
  },
  {
    field: 'pimprocessheatproductfamily',
    label: () => i18n.t('SEARCH | Facets pimprocessheatproductfamily'),
    multi: false
  },
  {
    field: 'pimfeatures',
    label: () => i18n.t('SEARCH | Facets pimfeatures')
  },
  {
    field: 'pimpowerma',
    label: () => i18n.t('SEARCH | Facets pimpowerma'),
    numeric: true,
    unit: 'W'
  },
  {
    field: 'pimnozzleconnection',
    label: () => i18n.t('SEARCH | Facets pimnozzleconnection'),
    multi: false
  },
  {
    field: 'pimoutlettemperaturema',
    label: () => i18n.t('SEARCH | Facets pimoutlettemperaturema'),
    numeric: true,
    unit: '°C'
  },
  {
    field: 'pimlength',
    label: () => i18n.t('SEARCH | Facets pimlength'),
    numeric: true,
    unit: 'mm'
  },
  {
    field: 'pimwidth',
    label: () => i18n.t('SEARCH | Facets pimwidth'),
    numeric: true,
    unit: 'mm'
  },
  {
    field: 'pimheight',
    label: () => i18n.t('SEARCH | Facets pimheight'),
    numeric: true,
    unit: 'mm'
  }
];
