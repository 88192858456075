import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';

/**
 *  Component that renders two columns, each with title and richtext description, and an optional background, according to data
 * @param  {object} props Object containing data needed for the component.
 */

class TwoColumnContentBlock extends React.Component {
  render() {
    if (!this.props || !this.props.fields) {
      return <div className='TwoColumnContentBlock-Wrapper' />;
    }
    const fields = this.props.fields;
    const BackgroundColor = fields.BackgroundColor;
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
    };

    const cssClass = classNames('TwoColumnContentBlock componentContainer', {
      WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
    });
    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className={cssClass}>
          <Row className='TwoColumnContentBlock-Wrapper' nogutter>
            <Col xl={5} lg={5} md={12} sm={12} className='TwoColumnContentBlock-Column'>
              <Text field={fields.LeftTitle} tag='div' className='TwoColumnContentBlock-Title' />
              <RichText field={fields.LeftDescription} className='TwoColumnContentBlock-Description' />
            </Col>
            <Col xl={1} lg={1} md={0} sm={0} className='TwoColumnContentBlock-Column' />
            <Col xl={5} lg={5} md={12} sm={12} className='TwoColumnContentBlock-Column'>
              <Text field={fields.RightTitle} tag='div' className='TwoColumnContentBlock-Title' />
              <RichText field={fields.RightDescription} className='TwoColumnContentBlock-Description' />
            </Col>
            <Col xl={1} lg={1} md={0} sm={0} className='TwoColumnContentBlock-Column' />
          </Row>
        </div>
      </div>
    );
  }
}

export default TwoColumnContentBlock;
