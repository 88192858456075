import { referencedBundlesFragment } from '../fragments/bundle';
import { productWithoutVariantsFragment } from '../fragments/product';
import { getLanguageContext } from '../inContext/language';

/**
 * Get the bundle data by product variant IDs
 *
 * ATTENTION: We are adding the productWithoutVariantsFragment for the root product variant.
 * Further this fragment is required for the bundledProductVariantsFragment which is defined within the referencedBundlesFragment.
 * Reusability of the fragments is important to avoid duplication and to keep the code clean, however we are not allowed to include the fragment itself twice.
 * @see productWithoutVariantsFragment
 */
export const getBundleDataByIdQuery = (): string => /* GraphQL */ `
  query getBundleDataById($ids: [ID!]!) ${getLanguageContext()} {
    nodes(ids: $ids) {
      ... on ProductVariant {
        availableForSale
        id
        sku
        product {
          ...productWithoutVariants
        }
        price {
          amount
          currencyCode
        }
        ...referencedBundles
      }
    }
  }
  ${referencedBundlesFragment}
  ${productWithoutVariantsFragment}
`;
