import React from 'react';
import Carousel from 'react-alice-carousel';
import { Row, Col, Container } from 'react-grid-system';

class ObjectSlider extends React.Component {
  state = {
    currentIndex: 0,
    itemsInSlide: 3,
    responsive: { 0: { items: 1 }, 1024: { items: 3 }, 567: { items: 1 } },
    galleryItems: this.props.children
  };

  slidePrevPage = () => {
    if (!this.checkLastItem()) {
      const currentIndex = this.state.currentIndex - this.state.itemsInSlide;

      this.setState({ currentIndex });
    }
  };

  slideNextPage = () => {
    const {
      itemsInSlide,
      galleryItems: { length }
    } = this.state;
    let currentIndex = this.state.currentIndex + itemsInSlide;
    if (currentIndex > length) currentIndex = length;

    this.setState({ currentIndex });
  };

  handleOnSlideChange = event => {
    const { itemsInSlide, item } = event;
    this.setState({ itemsInSlide, currentIndex: item });
  };

  checkLastItem = () => {
    const { currentIndex } = this.state;
    if (currentIndex === 0) {
      return true;
    }
    return false;
  };

  render() {
    const { currentIndex, galleryItems, responsive } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <div
                className='objectSlider-arrowLeft'
                onClick={this.slidePrevPage}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.slidePrevPage();
                }}
                role='button'
                tabIndex='0'
              >
                &#8592;
              </div>
              <Carousel
                items={galleryItems}
                slideToIndex={currentIndex}
                responsive={responsive}
                onInitialized={this.handleOnSlideChange}
                onSlideChanged={this.handleOnSlideChange}
                onResized={this.handleOnSlideChange}
              />
              <div
                className='objectSlider-arrowRight'
                onClick={this.slideNextPage}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.slideNextPage();
                }}
                role='button'
                tabIndex='0'
              >
                &#8594;
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default ObjectSlider;
