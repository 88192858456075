import * as actionTypes from './actionTypes';
import {
  MY_DEVICE_LIST_ENDPOINT,
  DELETE_DEVICE_ENDPOINT,
  CHECK_AND_GET_DEVICE_ENDPOINT,
  ADD_DEVICE_ENDPOINT,
  UPDATE_INVENTORY_NUMBER_ENDPOINT
} from '../../../src/Constants/Devices';
import { FORBIDDEN_STATUS_CODE } from '../../Constants/General';
import { logOutRedirect } from '../../utils/Utils';

/**
 * @method myDevices
 * @description set an object on redux state with all the necessary data for product page
 * @returns {object} Return an object.
 */
export const setMyDevicesList = language => {
  return dispatch => {
    fetch(MY_DEVICE_LIST_ENDPOINT(language), {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setInitialDevicesList(responseJson));
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const deleteDevice = (articleNumber, serialNumber) => {
  return dispatch => {
    fetch(DELETE_DEVICE_ENDPOINT(articleNumber, serialNumber), {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        if (responseJson) {
          dispatch(deleteDeviceFromRedux(articleNumber, serialNumber));
        }
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const updateInventoryNumber = (articleNumber, serialNumber, inventoryNumber, language) => {
  return dispatch => {
    fetch(UPDATE_INVENTORY_NUMBER_ENDPOINT(articleNumber, serialNumber, inventoryNumber), {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          dispatch(setMyDevicesList(language));
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        }
      })

      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const setInitialDevicesList = resultJson => {
  return {
    type: actionTypes.SET_INITIAL_DEVICE_LIST,
    initialDeviceList: resultJson
  };
};

export const setCurrentDevicesList = currentDeviceList => {
  return {
    type: actionTypes.SET_CURRENT_DEVICE_LIST,
    currentDeviceList
  };
};

export const deleteDeviceFromRedux = (articleNumber, serialNumber) => {
  return {
    type: actionTypes.DELETE_DEVICE_FROM_REDUX,
    articleNumber,
    serialNumber
  };
};

export const setNumberOfDevices = (endpoint, oldNumberOfDevices) => {
  return dispatch => {
    if (endpoint) {
      fetch(endpoint)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === FORBIDDEN_STATUS_CODE) {
            logOutRedirect();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          if (responseJson && responseJson !== oldNumberOfDevices) {
            dispatch(setNumberOfDevicesOnRedux(responseJson));
          }
        })
        .catch(err => {
          throw new Error('Something went wrong');
        });
    }
  };
};

const setNumberOfDevicesOnRedux = numberOfDevices => {
  return {
    type: actionTypes.SET_NUMBER_OF_DEVICES,
    numberOfDevices
  };
};

export const checkAndGetDevice = (articleNumber, serialNumber, inventoryNumber) => {
  return dispatch => {
    fetch(CHECK_AND_GET_DEVICE_ENDPOINT(articleNumber, serialNumber, inventoryNumber), {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          dispatch(setDeviceErrorCode(response.status));
        }
      })
      .then(responseJson => {
        if (responseJson) {
          dispatch(setNewAddedDevicesList(responseJson));
        }
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const setNewAddedDevicesList = newAddedDevicesList => {
  return {
    type: actionTypes.SET_NEW_ADDED_DEVICES_LIST,
    newAddedDevicesList
  };
};

export const setDeviceErrorCode = errorStatus => {
  return {
    type: actionTypes.SET_DEVICE_ERROR_CODE,
    errorStatus
  };
};

export const deleteDeviceNewAddedDevices = (articleNumber, serialNumber) => {
  return {
    type: actionTypes.DELETE_DEVICE_FROM_NEW_ADDED_DEVICES,
    articleNumber,
    serialNumber
  };
};

export const addDevices = data => {
  return dispatch => {
    fetch(ADD_DEVICE_ENDPOINT(), {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain,/',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        if (response.ok) {
          dispatch(clearNewAddedDevicesList());
          return;
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

const clearNewAddedDevicesList = () => {
  return {
    type: actionTypes.CLEAR_NEW_ADDED_DEVICES_LIST
  };
};

export const setDeviceInfoToContactPage = deviceInfo => {
  return {
    type: actionTypes.SET_DEVICE_INFO_TO_CONTACT_PAGE,
    deviceInfo
  };
};
