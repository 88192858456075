import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const NewsletterConfirmation = props => {
  const { fields = {} } = props.rendering || {};
  //TODO replace email token with session storage email value
  return (
    <div className='newsletterconfirmation'>
      {' '}
      <Text field={fields.Text} />
    </div>
  );
};

export default NewsletterConfirmation;
