import React from 'react';

const FieldValidationErrors = props => {
  if (props.fieldValidationErrorsComponent) {
    const CustomError = props.fieldValidationErrorsComponent;

    // strip the errors component from the custom component props
    // (prevents infinite loop rendering if someone reuses this component as a custom error component)
    const { fieldValidationErrorsComponent, ...errorsComponentProps } = props;

    return <CustomError {...errorsComponentProps} />;
  }

  const { errors } = props;
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className='invalid'>
      {errors.map((error, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{
            __html: `<span>${error}</span>`
          }}
        />
      ))}
    </div>
  );
};

export { FieldValidationErrors };
