import React from 'react';

class CompareUSPItem extends React.Component {
  getUSPItemList = () => {
    return this.props.uspList.slice(0, 5).map((item, index) => {
      return <li key={index + item}>{item}</li>;
    });
  };
  render() {
    return (
      <div className='CompareUSPItem'>
        <span className='CompareUSPItem-Title'>{this.props.productTitle}</span>
        <ul>{this.getUSPItemList()}</ul>
      </div>
    );
  }
}

export default CompareUSPItem;
