import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

class MyAccountHeadline extends React.PureComponent {
  render() {
    const { fields } = this.props;
    if (!(fields && fields.data && fields.data.myAccountMenuQuery)) {
      return <div className='MyAccountHeadline-NoData' />;
    }
    const {
      item: { overline, title },
      myAccountMenuQuery: { lastName }
    } = fields.data;

    return (
      <div className='MyAccountHeadline'>
        <div className='MyAccountHeadline-Overline'>
          <Text field={overline} /> {lastName}
        </div>
        <Text tag='h1' className='MyAccountHeadline-Title' field={title} />
      </div>
    );
  }
}

export default MyAccountHeadline;
