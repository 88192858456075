import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import FlyoutFactory from './Factory/FlyoutFactory';
import { changeLayout } from '../../../store/menuflyout/actions';
import CloseIcon from '../../Common/Icons/CloseIcon';
import ProductsLayout from './ProductsLayout';

/**
 * @class Component that renders the flyout menu for each menu option.
 *
 * @param {object} fields Object containing the data (items) needed for the component.
 *
 * Returns HTML.
 */
class MenuFlyout extends React.Component {
  componentDidMount() {
    document.addEventListener('click', this.closeOnClickOutside);
  }

  componentWillUnmount() {
    this.onClose();
    document.removeEventListener('click', this.closeOnClickOutside);
  }

  /**
   * @method getItemsForFlyout
   * @description send data on separate ways based on sitecore or not sitecore related item
   */
  getItemsForFlyout = () => {
    if (this.props.sectionId === null && this.props.layout === null && this.props.productsJson !== null) {
      return <ProductsLayout items={this.props.productsJson} />;
    }
    if (this.props.sectionId !== null) {
      let currentItems = [];
      for (let i = 0; i < this.props.subItems.length; i++) {
        if (this.props.subItems[i].id === this.props.sectionId) {
          currentItems = this.props.subItems[i].fields.Level3Items;
          break;
        }
      }
      return <FlyoutFactory layout={this.props.layout} items={currentItems} showImage={this.props.showImage} />;
    }

    return null;
  };

  /**
   * @method onClose
   * @description change redux state to close the menu flyout
   */
  onClose() {
    this.props.changeLayout({
      sectionId: null,
      layout: null,
      opededFlayout: null,
      productsJson: null,
      showImage: false
    });
  }

  closeOnClickOutside = evt => {
    const menuFlyoutElement = document.querySelector('.MenuFlyout'),
      breadcrumbElement = document.querySelector('.Breadcrumb');
    let targetElement = evt.target; // clicked element
    if (targetElement) {
      if (breadcrumbElement && (breadcrumbElement === evt.target || breadcrumbElement.contains(evt.target))) {
        return;
      }
      do {
        if (targetElement && targetElement.classList) {
          if (
            targetElement.classList.contains('TwoSectionsLayout-Item') ||
            targetElement.classList.contains('ThreeColumnLayout-Item')
          ) {
            // This is a click on menu item.
            this.onClose();
            break;
          } else if (targetElement === menuFlyoutElement || targetElement.classList.contains('SubMenuItems-item')) {
            // This is a click inside. Do nothing, just return.
            return;
          }
        }
        // Go up the DOM
        targetElement = targetElement && targetElement.parentNode ? targetElement.parentNode : document;
      } while (targetElement !== document);
    }
    // This is a click outside.
    this.onClose();
  };

  render() {
    if (!this.props.isOpen) {
      return <div className='MenuFlyout-EmptyContainer' />;
    }
    return (
      <Visible lg xl>
        <div className='MenuFlyout-EmptyContainer'>
          <div className='MenuFlyout'>
            <div className='componentContainerMenuFlyout MenuFlyout-Wrapper'>
              <div
                className='MenuFlyout-Close'
                onClick={() => this.onClose()}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.onClose();
                }}
                role='button'
                tabIndex='0'
                aria-label='Close Menu'
              >
                <CloseIcon />
              </div>
              {this.getItemsForFlyout()}
            </div>
          </div>
        </div>
      </Visible>
    );
  }
}
/**
 * Function that calls actions from the store (transforms actions into props).
 *
 * @param {*} dispatch Middleware for doing an action from the store.
 */
const mapDispatchToProps = dispatch => {
  return {
    changeLayout: payload => dispatch(changeLayout(payload))
  };
};

/**
 * Function that transforms state from store into component props.
 *
 * @param {object} state Object from the store containing all the state values..
 */
const mapStateToProps = function (state) {
  return {
    isOpen: state.menuFlyout.isOpen,
    layout: state.menuFlyout.layout,
    openFlayout: state.menuFlyout.openFlayout,
    sectionId: state.menuFlyout.sectionId,
    productsJson: state.menuFlyout.productsJson,
    showImage: state.menuFlyout.showImage,
    subItems: state.menuFlyout.submenuItems
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuFlyout);
