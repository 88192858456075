import classNames from 'classnames';
import React from 'react';

export type TechspecProps = {
  spec: {
    name: string;
    value: string;
  }[];
  variant?: 'default' | 'columns';
};

export const Techspec: React.FC<TechspecProps> = ({ spec, variant }) => {
  if (!spec || spec.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames('Techspec', {
        'Techspec--Columns': variant === 'columns'
      })}
    >
      <dl className='Techspec__List'>
        {spec.map((item, index) => (
          <React.Fragment key={item.name}>
            <dt className='Techspec__Item-Name'>{item.name}</dt>
            <dd className='Techspec__Item-Value'>{item.value}</dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};

export default Techspec;
