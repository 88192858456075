import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * Display the logo image where the component is called (ex. in Menu Component).
 *
 * @param {object} props Object containing all the image data.
 *
 * Returns jsx markup containing an image wrapped in a div.
 */
const Logo = props => (
  <div className='Logo'>
    <Image media={props.logo} />
  </div>
);

export default Logo;
