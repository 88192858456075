import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';

export type ExpertiseResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const ExpertiseResultItem = ({ result, siteName, engine }: ExpertiseResultItemProps) => {
  const { raw } = result;
  const image = raw.image as string;
  const category = raw.category;
  const title = raw.ez120xpertisepageherotitle;
  const description = raw.tez120xt as string;
  const linkTitle = raw.linkerz32xbuttonz32xlabel as string;
  const resultLink = getResultUrl(raw, siteName);

  return (
    <div className='ExpertiseResultItem'>
      <div dangerouslySetInnerHTML={{ __html: image }}></div>
      <div className='ExpertiseResultItem__Wrapper'>
        <div className='ExpertiseResultItem__Wrapper-FoundIn'>
          {i18n.t('SEARCH | ResultItem Found In label')} {category}
        </div>
        <span className='ExpertiseResultItem__Wrapper-Title'>{title}</span>
        <RichText className='ExpertiseResultItem__Wrapper-Description' field={{ value: description }} />
        <ResultLink
          className='ResultLink--important ResultLink--larger'
          text={linkTitle}
          icon={<ArrowRight />}
          href={resultLink}
          engine={engine}
          result={result}
        />
      </div>
    </div>
  );
};

export default ExpertiseResultItem;
