import React, { Fragment } from 'react';
import { FieldValidationErrors } from './field-validation-errors';
import { Label } from './label';
import createErrorMessageLanguagesMap from '../../utils/createErrorMessageLanguagesMap';

const MultipleLineText = props => {
  const { field, value, onChange, tracker, errors } = props;

  const errorMessagesMap = createErrorMessageLanguagesMap({ field });

  return (
    <Fragment>
      <Label {...props} />
      <textarea
        className={field.model.cssClass}
        id={field.valueField.id}
        name={field.valueField.name}
        value={value}
        rows={field.model.rows}
        maxLength={field.model.maxLength}
        placeholder={field.model.placeholderText}
        onChange={e => handleOnChange(field, e.target.value, onChange, errorMessagesMap)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      />
      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

function handleOnChange(field, fieldValue, callback, errorMessagesMap) {
  let valid = true;
  const errorMessages = [];

  // custom client validation logic here
  if (field.model.required && !fieldValue) {
    valid = false;
    errorMessages.push(errorMessagesMap.required);
  }

  callback(field.valueField.name, fieldValue, valid, errorMessages);
}

export default MultipleLineText;
