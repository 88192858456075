import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CompareCategories from '../CompareCategories';
import CompareProductCard from '../CompareProductCard';

class CompareProducts extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 960 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }
  /**
   * @method getProducts return a list of CompareProductCard according to the selected category
   */

  getProducts() {
    const selectedCategory = this.props.selectedCategory;
    const {
        initialData,
        fields,
        removeProductFromStore,
        getQuoteLink,
        favouriteContact,
        productToQuote,
        linkForQuote
      } = this.props,
      firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : '',
      activeData = initialData.filter(item => [item.category, item.categoryEn].includes(firstSelectedCategory)),
      numberOfProductsToDisplay = this.state && this.state.isMobile ? activeData.length : 4;
    return activeData
      .slice(0, numberOfProductsToDisplay)
      .map((item, index) => {
        return (
          <CompareProductCard
            fields={fields}
            item={item}
            key={index + item.masterProductName}
            onClick={removeProductFromStore}
            getAQuoteLink={getQuoteLink}
            favouriteContact={favouriteContact}
            setProductToQuote={productToQuote}
            quoteValidation={linkForQuote}
          />
        );
      })
      .filter(item => item !== null);
  }

  render() {
    const { fields } = this.props,
      { 'Compare Products Label': compareLabel } = fields;

    return (
      <div className='CompareProducts'>
        <Text tag='div' field={compareLabel} className='CompareProducts-Heading' />
        <div className='CompareProducts-ProductsContainer'>
          <div className='CompareProducts-Categories'>
            <CompareCategories fields={fields} />
          </div>
          <div className='CompareProducts-Products'>{this.getProducts()}</div>
        </div>
      </div>
    );
  }
}

export default CompareProducts;
