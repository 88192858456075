import classNames from 'classnames';
import React, { ComponentType, useContext, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../SearchResultList';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { useCoveoQuery } from '../../CoveoQuery';
import { CoveoContext } from '../../context/CoveoContext';
import CategorFacet from '../../CategoryFacet';
import FacetsModal from '../../FacetsModal';
import FloatingFilterToggle from '../../FloatingFilterToggle';
import FacetManager from '../../FacetManager';
import SimpleSearchOmnibox from '../../SimpleSearchOmnibox';
import GeneralDownloadsResultItem from '../../ResultItem/GeneralDownloadsResultItem';
import { FacetStickyWrapper } from '../../FacetStickyWrapper/FacetStickyWrapper';
import { Result } from '@coveo/headless';
import Checkbox from '../../../Common/Checkbox/Checkbox';
import Button from '../../../Common/Button/Button';
import FileSaver from 'file-saver';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getCoveoGeneralDownloadsSource } from '../../../../AppGlobals';
import { useDesktopDevice } from '../../../../hooks/useDevice';
import { SearchContext } from '../../context/SearchContext';

type GeneralDownloadsListerComponentProps = {
  history?: RouteComponentProps['history'];
  sitecoreContext?: any;
};

export const GeneralDownloadsListerComponent: React.FC<GeneralDownloadsListerComponentProps> = ({
  sitecoreContext
}) => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);

  const coveoSource = getCoveoGeneralDownloadsSource();
  const userCanAccessRestrictedMediaAssets =
    sitecoreContext?.generalDownloads?.userCanAccessRestrictedMediaAssets ?? false;
  const accessProtectedQuery = !userCanAccessRestrictedMediaAssets ? ' AND @gd_is_restricted=="false"' : '';
  const GeneralDownloadsListerFields = useMemo(
    () => [
      'gd_is_restricted',
      'gd_file_extension',
      'gd_media_language_codes',
      'gd_cdn_url',
      'gd_media_assetname',
      `gd_media_name_${locale}`,
      `gd_media_type_${locale}`
    ],
    [locale]
  );

  const {
    buildFacetController,
    breadcrumbManagerController,
    resultListController,
    querySummaryController,
    searchBoxController,
    search
  } = useCoveoQuery({
    engine,
    aq: `@source=="${coveoSource}" AND @gd_tenant=="${siteName}" ${accessProtectedQuery}`,
    cq: '',
    fieldsToInclude: GeneralDownloadsListerFields
  });

  const [state, setState] = useState(querySummaryController.state);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [bulkDownloadItems, setBulkDownloadItems] = useState<Result[]>([]);
  const [resultsState, setResultsState] = useState(resultListController.state);
  const isBulkDownloadAllowed = useDesktopDevice();
  const { state: searchState } = useContext(SearchContext);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine, querySummaryController]);

  useEffect(
    () =>
      resultListController.subscribe(() => {
        setResultsState(resultListController.state);
      }),
    [resultListController]
  );

  useEffect(() => {
    setSelectAllChecked(
      resultsState.results.every(item => bulkDownloadItems?.some(bulkItem => bulkItem.uri === item.uri))
    );
  }, [resultsState.results, bulkDownloadItems]);

  useEffect(() => {
    // remove item from bulk download if it dissapears from the results - like when search query changes
    setBulkDownloadItems(bulkDownloadItems => {
      return bulkDownloadItems.filter(item => resultsState.results.some(result => result.uri === item.uri));
    });
  }, [resultsState.results]);

  const toggleAllItems = (checked: boolean) => {
    setBulkDownloadItems(checked ? resultsState.results : []);
  };

  const toggleItemInBulkDownload = (result: Result, checked: boolean) => {
    if (checked) {
      const isItemInBulkDownload = bulkDownloadItems.some(item => item.uri === result.uri);

      if (!isItemInBulkDownload) {
        setBulkDownloadItems(bulkDownloadItems => [...bulkDownloadItems, result]);
      }
    } else {
      setBulkDownloadItems(bulkDownloadItems => bulkDownloadItems.filter(item => item.uri !== result.uri));
    }
  };

  const bulkDownload = () => {
    if (bulkDownloadItems.length) {
      downloadMultipleFiles(bulkDownloadItems.length - 1);
    }
  };

  const downloadMultipleFiles = (fileIndex: number) => {
    setTimeout(function () {
      const file = bulkDownloadItems?.[fileIndex];

      if (file && file.raw.gd_cdn_url) {
        fetch(file.raw.gd_cdn_url as string)
          .then(res => res.blob())
          .then(blob => {
            FileSaver.saveAs(
              new Blob([blob], {
                type: 'application/octet-stream'
              }),
              file.raw.gd_media_assetname as string
            );
          });
      }

      if (fileIndex > 0) {
        downloadMultipleFiles(fileIndex - 1);
      }
    }, 1000); // if less than 1000, not all files are downloaded in iOS
  };

  const facets = [
    {
      field: `gd_category_names_${locale}`,
      label: i18n.t('SEARCH | Facets category')
    },
    {
      field: `gd_solution_names_${locale}`,
      label: i18n.t('SEARCH | Facets pimsolutions')
    },
    {
      field: `gd_media_type_${locale}`,
      label: i18n.t('SEARCH | Facets document type'),
      hasFilter: true
    },
    {
      field: `gd_media_language_names_${locale}`,
      label: i18n.t('SEARCH | Facets language'),
      hasFilter: true
    }
  ];

  return (
    <>
      <SimpleSearchOmnibox searchBoxController={searchBoxController} search={search} />
      <div
        className={classNames('GeneralDownloadsLister', {
          'SearchResults--executed': state.firstSearchExecuted
        })}
      >
        <div className='SearchResults__Main'>
          <FloatingFilterToggle />
          <FacetStickyWrapper>
            <FacetsModal breadcrumbManagerController={breadcrumbManagerController} />
            <FacetManager engine={engine} hasResults={state.hasResults || state.firstSearchExecuted}>
              {facets.map(facet => {
                const facetController = buildFacetController(facet.field);

                return (
                  <CategorFacet
                    key={facet.field}
                    controller={facetController}
                    title={facet.label}
                    id={facet.field}
                    hasFilter={facet.hasFilter}
                  />
                );
              })}
            </FacetManager>
            {bulkDownloadItems.length > 0 && (
              <div className='GeneralDownloadsLister__Bulk-Downloads-Container'>
                <div className='GeneralDownloadsLister__Bulk-Downloads AllDownloads-Bulk-Downloads'>
                  <Button
                    onClick={bulkDownload}
                    buttonTitle={i18n.t('PRODUCT_DOWNLOADS | Bulk download')}
                    beforeIcon='icon-web-24-download'
                    variant='secondary'
                    size='large'
                  />
                </div>
              </div>
            )}
          </FacetStickyWrapper>
          {state.hasResults && (
            <>
              <div
                className={classNames('SearchResults__Header', {
                  'SearchResults__Header--just-results': !isBulkDownloadAllowed
                })}
              >
                <span className='SearchResults__NumberOfResults'>
                  {state.total} {i18n.t('SEARCH | Total Results')}
                </span>
              </div>
              {isBulkDownloadAllowed && (
                <div
                  className={classNames(
                    'SearchResults__Header SearchResults__Header--select-all GeneralDownloadsLister__Header'
                  )}
                >
                  <div className='GeneralDownloadsLister__Select-all'>
                    <Checkbox
                      initialChecked={selectAllChecked}
                      onChange={event => {
                        setSelectAllChecked(event.target.checked);
                        toggleAllItems(event.target.checked);
                      }}
                    >
                      <span className='GeneralDownloadsLister__Select-all-text'>
                        {i18n.t('PRODUCT_DOWNLOADS | Select all')}
                      </span>
                    </Checkbox>
                  </div>
                </div>
              )}
            </>
          )}
          <SearchResultList
            className='GeneralDownloadsLister'
            layout='List'
            engine={engine}
            query={state.query}
            siteName={siteName}
            language={locale}
            ItemRendering={({ result, ...rest }) => {
              const isItemChecked = bulkDownloadItems.some(item => item.uri === result.uri);

              return (
                <GeneralDownloadsResultItem
                  {...rest}
                  accessProtected={userCanAccessRestrictedMediaAssets}
                  result={result}
                  checked={isItemChecked}
                  onChange={event => {
                    toggleItemInBulkDownload(result, event.target.checked);
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter<
  RouteComponentProps & GeneralDownloadsListerComponentProps,
  ComponentType<RouteComponentProps & GeneralDownloadsListerComponentProps>
>(withSitecoreContext()(GeneralDownloadsListerComponent));
