import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import ArrowLeft from '../../../Common/Icons/ArrowLeft';
import GeneralLink from '../../../Common/GeneralLink/GeneralLink';
import FulfillmentStatusTag from '../FulfillmentStatusTag';
import { Money, ShopifyLineItemNode, ShopifyOrderDetails } from '../../../Shopify/lib/shopify/types';
import Table, { Column, ImageWithText } from '../../../Common/Table';
import { SHOPIFY_MULTIPASS_TOKEN_ROUTE } from '../../../Shopify/lib/constants';
import { getCustomerAccessToken, getOrderDetails } from '../../../Shopify/lib/shopify/orderHelpers';
import Price from '../../../Shopify/Price/Price';
import { getLocalizedFinancialStatus, getLocalizedFulfillmentStatus } from '../lib/utils';
import LineItemsMobile from '../LineItemsMobile';
import Markdown from 'react-markdown';
import { useViewportSize } from '../../../../hooks/useViewportSize';

export type MyAccountOrderDetailsProps = {
  fields: {
    'Back to overview target'?: {
      id?: string;
      url?: string;
    };
  };
};

export type OrderLineItemData = {
  id: string;
  title: string;
  imageWithText: ImageWithText;
  articleNumber: string;
  price: Money;
  quantity: number;
  totalPrice: Money;
};

export type CostBreakdownData = {
  subTotal: Money;
  subtotalWithDiscounts: Money;
  discounts: Money;
  discountsNegative: Money;
  hasDiscounts: boolean;
  shipping: Money;
  tax: Money;
  hasTax: boolean;
  total: Money;
  taxPercentage: number;
};

const calculateCostBreakdown = (orderDetails: ShopifyOrderDetails): CostBreakdownData => {
  const subtotalWithDiscounts = parseFloat(orderDetails.subtotalPrice.amount);

  // Calculate the sum of all line items. Shopify's "subtotal" already includes discounts, so we need the sum to calculate the effective subtotal.
  const subTotalWithoutDiscounts = orderDetails.lineItems.nodes.reduce((acc, lineItem) => {
    return acc + parseFloat(lineItem.originalTotalPrice.amount);
  }, 0);

  // Calculate the sum of all discounts (subtotal - shopify's subtotal)
  const discounts = subTotalWithoutDiscounts - subtotalWithDiscounts;

  return {
    subTotal: {
      amount: subTotalWithoutDiscounts.toFixed(2),
      currencyCode: orderDetails.subtotalPrice.currencyCode
    },
    subtotalWithDiscounts: {
      amount: subtotalWithDiscounts.toFixed(2),
      currencyCode: orderDetails.subtotalPrice.currencyCode
    },
    discounts: {
      amount: discounts.toFixed(2),
      currencyCode: orderDetails.subtotalPrice.currencyCode
    },
    discountsNegative: {
      amount: (-discounts).toFixed(2),
      currencyCode: orderDetails.subtotalPrice.currencyCode
    },
    hasDiscounts: parseFloat(discounts.toFixed(2)) > 0,
    shipping: {
      amount: orderDetails.totalShippingPrice.amount,
      currencyCode: orderDetails.totalShippingPrice.currencyCode
    },
    tax: {
      amount: orderDetails.totalTax.amount,
      currencyCode: orderDetails.totalTax.currencyCode
    },
    hasTax: parseFloat(orderDetails.totalTax.amount) > 0,
    total: {
      amount: orderDetails.totalPrice.amount,
      currencyCode: orderDetails.totalPrice.currencyCode
    },
    taxPercentage:
      Math.round(
        (parseFloat(orderDetails.totalTax.amount) /
          (subtotalWithDiscounts + parseFloat(orderDetails.totalShippingPrice.amount))) *
          10000
      ) / 100
  };
};

export const MyAccountOrderDetails: React.FC<MyAccountOrderDetailsProps> = props => {
  const [multipassToken, setMultipassToken] = useState<string | undefined>();
  const [customerAccessToken, setCustomerAccessToken] = useState<string | undefined>();
  const [orderDetails, setOrderDetails] = useState<ShopifyOrderDetails>();
  const [orderLineItems, setOrderLineItems] = useState<OrderLineItemData[]>([]);
  const [costBreakdown, setCostBreakdown] = useState<CostBreakdownData>();
  const [orderLoading, setOrderLoading] = useState<boolean>(true);

  const { viewportIsDesktop } = useViewportSize();

  let orderId: string | undefined = undefined;
  if (typeof window !== 'undefined') {
    orderId = window.location.pathname.split('/').pop();
  }

  useEffect(() => {
    async function fetchMultipassToken() {
      const multipassResponse = await fetch(SHOPIFY_MULTIPASS_TOKEN_ROUTE());

      if (multipassResponse.status === 200) {
        const token = await multipassResponse.text();
        setMultipassToken(token);
      } else {
        setOrderLoading(false);
      }
    }

    fetchMultipassToken();
  }, []);

  useEffect(() => {
    async function fetchCustomerAccessToken() {
      if (multipassToken) {
        const accessToken = await getCustomerAccessToken(multipassToken);
        if (accessToken) {
          setCustomerAccessToken(accessToken);
        } else {
          setOrderLoading(false);
        }
      }
    }

    fetchCustomerAccessToken();
  }, [multipassToken]);

  useEffect(() => {
    async function fetchOrderDetails() {
      if (customerAccessToken && orderId) {
        const orderDetails = await getOrderDetails(customerAccessToken, orderId);
        if (orderDetails) {
          setOrderDetails(orderDetails);

          const lineItems: OrderLineItemData[] = orderDetails.lineItems.nodes.map((lineItem: ShopifyLineItemNode) => ({
            id: lineItem.variant.id,
            imageWithText: {
              text: lineItem.title,
              imageUrl: lineItem.variant.image?.url,
              imageAlt: lineItem.variant.image?.altText
            },
            title: lineItem.title,
            articleNumber: lineItem.variant.sku,
            price: {
              amount: (parseFloat(lineItem.originalTotalPrice.amount) / lineItem.quantity).toString(),
              currencyCode: lineItem.originalTotalPrice.currencyCode
            },
            quantity: lineItem.quantity,
            totalPrice: lineItem.originalTotalPrice
          }));
          setOrderLineItems(lineItems);

          const costBreakdown = calculateCostBreakdown(orderDetails);
          setCostBreakdown(costBreakdown);
        }
        setOrderLoading(false);
      }
    }

    fetchOrderDetails();
  }, [customerAccessToken, orderId]);

  const columnDef: Column<OrderLineItemData>[] = [
    {
      key: 'imageWithText',
      title: i18n.t('ORDERS | Line Item Product'),
      renderer: 'image-with-text'
    },
    {
      key: 'articleNumber',
      title: i18n.t('ORDERS | Line Item Article Number'),
      renderer: 'text'
    },
    {
      key: 'price',
      title: i18n.t('ORDERS | Line Item Price'),
      renderer: 'money',
      textAlign: 'right'
    },
    {
      key: 'quantity',
      title: i18n.t('ORDERS | Line Item Quantity'),
      renderer: 'text',
      textAlign: 'right'
    },
    {
      key: 'totalPrice',
      title: i18n.t('ORDERS | Line Item Total'),
      renderer: 'money',
      textAlign: 'right'
    }
  ];

  const getAddressBlock = (title: string, addressLines: string[], statusLine?: string) => {
    return (
      <div className='MyAccountOrderDetails__Address'>
        <span className='MyAccountOrderDetails__Address__Title'>{title}</span>

        <div className='MyAccountOrderDetails__Address__Lines'>
          {addressLines.map((line, index) => (
            <span key={index} className='MyAccountOrderDetails__Address__Lines__Line'>
              {line}
            </span>
          ))}
        </div>

        {statusLine && <span className='MyAccountOrderDetails__Address__StatusLine'>{statusLine}</span>}
      </div>
    );
  };

  return orderDetails && costBreakdown ? (
    <div className='MyAccountOrderDetails'>
      <div className='MyAccountOrderDetails__Header'>
        <GeneralLink
          className='MyAccountOrderDetails__Header__BackTo'
          fields={{ value: { href: props.fields['Back to overview target']?.url } }}
        >
          <ArrowLeft />
          {i18n.t('ORDERS | Back to overview')}
        </GeneralLink>

        <h1>
          {i18n.t('ORDERS | Order Number')} {orderDetails.orderNumber}
        </h1>

        <div className='MyAccountOrderDetails__Header__OrderProperties'>
          <span className='MyAccountOrderDetails__Summary'>
            {i18n.t('ORDERS | Order Date')}:{' '}
            {orderDetails?.processedAt && new Date(orderDetails.processedAt).toLocaleDateString()} |{' '}
            {i18n.t('ORDERS | Order Payment Status')}: {getLocalizedFinancialStatus(orderDetails.financialStatus)} |{' '}
            {i18n.t('ORDERS | Order Total')}:{' '}
            <Price
              className='MyAccountOrderDetails__Summary__Price'
              amount={orderDetails.totalPrice.amount}
              currencyCode={orderDetails.totalPrice.currencyCode}
            />
          </span>
          <FulfillmentStatusTag fulfillmentStatus={orderDetails.fulfillmentStatus} />
        </div>
      </div>

      <div className='MyAccountOrderDetails__DetailsWrapper'>
        <div className='MyAccountOrderDetails__Details'>
          <div className='MyAccountOrderDetails__OrderedProducts'>
            <span className='MyAccountOrderDetails__OrderedProducts__Title'>{i18n.t('ORDERS | Ordered Products')}</span>
            <div>
              {viewportIsDesktop ? (
                <Table<OrderLineItemData> data={orderLineItems} columns={columnDef} />
              ) : (
                <LineItemsMobile lineItems={orderLineItems} />
              )}

              <div className='MyAccountOrderDetails__CostSummary'>
                <div className='MyAccountOrderDetails__Breakdown'>
                  <div className='MyAccountOrderDetails__Breakdown__CostItem'>
                    <span className='MyAccountOrderDetails__Breakdown__CostItem__Label'>
                      {i18n.t('ORDERS | Subtotal')}
                    </span>
                    <span className='MyAccountOrderDetails__Breakdown__CostItem__Amount'>
                      <Price
                        amount={costBreakdown.subTotal.amount}
                        currencyCode={costBreakdown.subTotal.currencyCode}
                      />
                    </span>
                  </div>

                  {costBreakdown.hasDiscounts && (
                    <div className='MyAccountOrderDetails__Breakdown__CostItem'>
                      <span className='MyAccountOrderDetails__Breakdown__CostItem__Label'>
                        {i18n.t('ORDERS | Discount')}
                      </span>
                      <span className='MyAccountOrderDetails__Breakdown__CostItem__Amount'>
                        <Price
                          amount={costBreakdown.discountsNegative.amount}
                          currencyCode={costBreakdown.discountsNegative.currencyCode}
                        />
                      </span>
                    </div>
                  )}

                  <div className='MyAccountOrderDetails__Breakdown__CostItem'>
                    <span className='MyAccountOrderDetails__Breakdown__CostItem__Label'>
                      {i18n.t('ORDERS | Shipping')}
                    </span>
                    <span className='MyAccountOrderDetails__Breakdown__CostItem__Amount'>
                      <Price
                        amount={costBreakdown.shipping.amount}
                        currencyCode={costBreakdown.shipping.currencyCode}
                      />
                    </span>
                  </div>

                  {costBreakdown.hasTax && (
                    <div className='MyAccountOrderDetails__Breakdown__CostItem'>
                      <span className='MyAccountOrderDetails__Breakdown__CostItem__Label'>
                        {i18n.t('ORDERS | Tax').replace('{percentage}', costBreakdown.taxPercentage.toString())}
                      </span>
                      <span className='MyAccountOrderDetails__Breakdown__CostItem__Amount'>
                        <Price amount={costBreakdown.tax.amount} currencyCode={costBreakdown.tax.currencyCode} />
                      </span>
                    </div>
                  )}
                </div>

                <div className='MyAccountOrderDetails__CostSummary__Total'>
                  <span className='MyAccountOrderDetails__CostSummary__Total__Label'>
                    {i18n.t('ORDERS | Order Total')}
                  </span>
                  <span className='MyAccountOrderDetails__CostSummary__Total__Amount'>
                    <Price
                      amount={orderDetails.totalPrice.amount}
                      currencyCode={orderDetails.totalPrice.currencyCode}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='MyAccountOrderDetails__Details__Addresses'>
            {getAddressBlock(
              i18n.t('ORDERS | Order Billing Address'),
              orderDetails.billingAddress.formatted,
              `${i18n.t('ORDERS | Order Payment Status')}: ${getLocalizedFinancialStatus(orderDetails.financialStatus)}`
            )}
            {getAddressBlock(
              i18n.t('ORDERS | Order Shipping Address'),
              orderDetails.shippingAddress.formatted,
              `${i18n.t('ORDERS | Order Fulfillment Status')}: ${getLocalizedFulfillmentStatus(
                orderDetails.fulfillmentStatus
              )}`
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      {orderLoading ? (
        <div className='MyAccountOrderDetails__Loading'>{i18n.t('ORDERS | Loading Order Details')}</div>
      ) : (
        <div className='MyAccountOrderDetails__OrderNotFound'>
          <h2>{i18n.t('ORDERS | Not Found Title')}</h2>
          <Markdown className='Markdown--support-links'>{i18n.t('ORDERS | Not Found Text') || ''}</Markdown>
        </div>
      )}
    </div>
  );
};

export default MyAccountOrderDetails;
