import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { useViewportSize } from '../../../hooks/useViewportSize';
import { FacetValue, NumericFacetValue } from '@coveo/headless';
import CloseIcon from '../../Common/Icons/CloseIcon';
import CoveoMagnifier from '../../Common/Icons/CoveoMagnifier';

export type FacetOption = {
  label: string;
  selected: boolean;
  numberOfResults: number;
  value: FacetValue | NumericFacetValue;
};

type FacetProps = {
  isLoading?: boolean;
  options: FacetOption[];
  title: string;
  selectedValuesText: string;
  toggleSelect: (value: any) => void;
  needToHaveMinimumTwoOptions?: boolean;
  isVisible?: boolean;
  hasFilter?: boolean;
};

export const Facet = ({
  isLoading,
  options,
  title,
  selectedValuesText,
  toggleSelect,
  needToHaveMinimumTwoOptions = false,
  isVisible = true,
  hasFilter = false
}: FacetProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { viewportIsMobile } = useViewportSize();
  const facetRef = React.createRef<HTMLDivElement>();
  const [filterText, setFilterText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<FacetOption[]>(options);

  useClickAway(facetRef, () => {
    if (isOpen && !viewportIsMobile) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    if (hasFilter) {
      setFilteredOptions(
        options.filter(option => {
          return option.label.toLowerCase().includes(filterText.toLowerCase());
        })
      );
    } else {
      setFilteredOptions(options);
    }
  }, [filterText, options, hasFilter]);

  if (!options.length && !isLoading) {
    return <></>;
  }

  if (needToHaveMinimumTwoOptions && options?.length === 1) {
    return <></>;
  }

  const clearFilter = () => {
    setFilterText('');
  };

  return (
    <div
      className={classNames('Facet', {
        'Facet--opened': isOpen,
        'Facet--visible': isVisible,
        'Facet--has-value': selectedValuesText !== ''
      })}
      ref={facetRef}
    >
      <div
        className='Facet__Header'
        role='button'
        onClick={() => setIsOpen(isOpen => !isOpen)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setIsOpen(isOpen => !isOpen);
          }
        }}
        tabIndex={0}
      >
        <span className='Facet__Header-Title'>{title}</span>
        <div className='Facet__Header-Breadcrumbs'>{selectedValuesText}</div>
      </div>
      <div className='Facet__Values'>
        <div className='Facet__Values-Header'>
          <div className='Facet__Values-Header-Title-Section'>
            <div className='Facet__Values-Header-Title'>{title}</div>
          </div>
        </div>
        <div className='Facet__List'>
          {hasFilter && (
            <div className='Facet__Values-Filter'>
              <input
                type='text'
                className='Facet__Filter-Input'
                placeholder={i18n.t('SEARCH | Facets filter placeholder')}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
              {!filterText && <CoveoMagnifier className='Facet__Filter-Icon' />}
              {filterText ? (
                <button className='Facet__Filter-Clear' onClick={clearFilter}>
                  <CloseIcon />
                  <span className='visually-hidden'>{i18n.t('SEARCH | Facets filter input clear')}</span>
                </button>
              ) : null}
            </div>
          )}
          <ul className='Facet__Values-List'>
            {filteredOptions.map(option => (
              <li
                key={option.label}
                className={classNames('Facet__Values-List-Item', {
                  'Facet__Values-List-Item--selected': option.selected
                })}
              >
                <label className='Facet__Values-List-Item-Label'>
                  <input
                    type='checkbox'
                    aria-hidden='true'
                    checked={option.selected}
                    onChange={() => toggleSelect(option.value)}
                    disabled={isLoading}
                  />
                  <span className='Facet__Values-List-Item-Caption'>{option.label}</span>
                  <span className='Facet__Values-List-Item-Results-Count'>({option.numberOfResults})</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Facet;
