import { useContext } from 'react';
import { ViewportContext, ViewportContextState } from '../context/ViewportContext';

/**
 * New custom hook to access the current viewport context.
 *
 * This hook provides the current viewport state, which includes:
 * - `viewportSize`: The current viewport size ('mobile', 'tablet', or 'desktop').
 * - `viewportIsMobile`: Boolean indicating if the viewport is in mobile size.
 * - `viewportIsTablet`: Boolean indicating if the viewport is in tablet size.
 * - `viewportIsDesktop`: Boolean indicating if the viewport is in desktop size.
 *
 * Usage:
 * import { useViewportSize } from '../hooks/useViewportSize';
 * const { viewportSize, viewportIsMobile, viewportIsTablet, viewportIsDesktop } = useViewportSize();
 *
 * @returns {ViewportContextState} The current viewport context state.
 */
export const useViewportSize = (): ViewportContextState => {
  return useContext(ViewportContext);
};
