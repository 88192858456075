import { CartItemAttributeKey, CartItemAttribute } from './types';

type CartAttributesObject = {
  [key in CartItemAttributeKey]?: string | null | undefined;
};

/**
 * Generates the attributes for a cart item
 *
 * @param attributes CartAttributesObject
 * @returns cart attributes array
 */
export function createCartAttributes(attributes: CartAttributesObject): CartItemAttribute[] {
  const cartAttributes: CartItemAttribute[] = [];

  Object.entries(attributes).forEach(([key, value]) => {
    if (key && value) {
      cartAttributes.push({ key: key as CartItemAttributeKey, value: value || '' });
    }
  });

  return cartAttributes;
}
