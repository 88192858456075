import React from 'react';
import { Row, Col } from 'react-grid-system';
import GeneralLink from '../../../Common/GeneralLink/index';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import { getSitecoreApiHost } from '../../../../AppGlobals';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class Component that renders the products layout for the flyout menu.
 *
 * @param {object} items Object containing the data (items) needed for the component.
 *
 * Returns HTML.
 */
class ProductsLayout extends React.Component {
  /**
   * @method getItems
   * @description Function that transforms the items from the props into raw HTML so the browser can read it.
   *
   * Returns HTML containing all of the above items.
   */
  getItems(itemsToMap) {
    const language = getLanguage(this.props.sitecoreContext);
    return itemsToMap.map((item, index) => {
      const { Name, NameEn, Media, Description } = item,
        gtmName = NameEn ? 'main_menu_sub_level_' + NameEn.replace(/ /g, '_') : null;
      return (
        <Col className='noPadding ThreeColumnLayout-Item' key={Name + index} md={6} xl={4}>
          <GeneralLink
            clickClassName={gtmName}
            fields={{
              value: {
                href: getSitecoreApiHost() + '/' + language + '/' + NameEn.replace(/ /g, '-')
              }
            }}
          >
            {Media ? (
              <div className='ThreeColumnLayout-Item-Image'>
                <img
                  className='ThreeColumnLayout-Item-Image-Img'
                  src={Media + '?imwidth=100'}
                  alt={Name}
                  loading='lazy'
                />
              </div>
            ) : (
              <div className='ThreeColumnLayout-Item-Image ThreeColumnLayout-Item-Image-Placeholder' />
            )}
            <div className='ThreeColumnLayout-Item-Content'>
              <div className='ThreeColumnLayout-Item-Content-Title'>{Name}</div>
              <div className='ThreeColumnLayout-Item-Content-Description'>{Description}</div>
            </div>
          </GeneralLink>
        </Col>
      );
    });
  }

  render() {
    const itemsToMap = this.props.items || [];
    // this is from endpoint if items are from sitecore return null
    if (!itemsToMap || (itemsToMap[0] && itemsToMap[0].fields)) {
      return null;
    }
    return <Row>{this.getItems(itemsToMap)}</Row>;
  }
}

export default withSitecoreContext()(ProductsLayout);
