import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { withPlaceholder, Placeholder, withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class MediaCarousel display images and videos in a carousel
 */
class MediaCarousel extends React.Component {
  /**
   * @method constructor
   * @param {Object} props -needed data received
   * @description set initial image/video and caption
   */
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: 0
    };
  }

  /**
   *@method changeSelectedItem
   *@description change current item index on state
   */
  changeSelectedItem = index => {
    this.setState({ selectedItem: index });
    if (this.player) {
      this.player.pause();
    }
  };

  /**
   *@method showItemIndicators
   *@description displays bullet indicators when more than one image/video
   */
  showItemIndicators = () => {
    return this.props.mediaElementsPlaceholder.length > 1 ? true : false;
  };

  render() {
    if (!this.props.fields || !this.props.mediaElementsPlaceholder) {
      return <div className='MediaCarousel componentContainerMobile' />;
    }
    const selectedItem = this.props.mediaElementsPlaceholder[this.state.selectedItem]
        ? this.props.mediaElementsPlaceholder[this.state.selectedItem]
        : null,
      caption =
        selectedItem && selectedItem.props && selectedItem.props.fields && selectedItem.props.fields.Caption
          ? selectedItem.props.fields.Caption
          : '',
      infiniteLoopSwipe = this.props.mediaElementsPlaceholder.length > 1 ? true : false;

    return (
      <div className='whiteBackground'>
        <div className='MediaCarousel componentContainerMobile'>
          <Carousel
            className='MediaCarousel-Container'
            showThumbs={false}
            showArrows={true}
            infiniteLoop={infiniteLoopSwipe}
            showStatus={false}
            onChange={this.changeSelectedItem}
            selectedItem={this.state.selectedItem}
            showIndicators={this.showItemIndicators()}
            transitionTime={500}
            swipeScrollTolerance={16}
          >
            {this.props.sitecoreContext.pageEditing ? (
              <Placeholder name='jss-media-carousel-elements' rendering={this.props.rendering} />
            ) : (
              this.props.mediaElementsPlaceholder
            )}
          </Carousel>
          <Text field={caption} className='MediaCarouselCaption' tag='span' />
        </div>
      </div>
    );
  }
}

export default withPlaceholder({
  placeholder: 'jss-media-carousel-elements',
  prop: 'mediaElementsPlaceholder'
})(withSitecoreContext()(MediaCarousel));
