import classNames from 'classnames';
import React from 'react';

const Price = ({
  amount,
  currencyCode = 'USD',
  className = '',
  type = '',
  prefix = null,
  ...props
}: {
  amount: string;
  currencyCode: string;
  className?: string;
  type?: '' | 'big' | 'stroken-through' | 'old-price' | 'current-price' | 'bundle-price' | 'sell';
  prefix?: React.ReactNode;
  props?: any;
}): JSX.Element => (
  <div className={classNames(className, 'shopify-price', type)} {...props}>
    {prefix && <>{prefix}&nbsp;</>}
    {`${new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol'
    }).format(parseFloat(amount))}`}
  </div>
);

export default Price;
