import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import GeneralLink from '../../Common/GeneralLink/index';
import { toggleMobileMyAccount } from '../../../store/accountInformation/actions';
import { logOutRedirect, checkIfUserIsLoggedIn } from '../../../utils/Utils';

class MyAccountMobileMenu extends React.Component {
  getAccountMenuItems = menuItems => {
    const { toggleMobileMyAccount } = this.props,
      itemsToMap = menuItems && menuItems.targetItems ? menuItems.targetItems : [];

    return itemsToMap.map((item, index) => {
      const { name, link } = item;
      return (
        <div
          key={index + item.id}
          onClick={toggleMobileMyAccount}
          onKeyDown={e => {
            if (e.target === 13) toggleMobileMyAccount();
          }}
          role='button'
          tabIndex='0'
        >
          <GeneralLink className='MyAccountMobileMenu-Link' fields={link.jss}>
            <Text field={name} />
          </GeneralLink>
        </div>
      );
    });
  };

  getSalutationName(fields) {
    if (fields && fields.data && fields.data.myAccountMenuQuery) {
      const { lastName } = fields.data.myAccountMenuQuery;

      return <div>{lastName}</div>;
    }
  }

  render() {
    const { isMobileMyAccountOpen, fields, sitecoreContext } = this.props;
    if (
      !(isMobileMyAccountOpen && fields && fields.data && fields.data.item && checkIfUserIsLoggedIn(sitecoreContext))
    ) {
      return <div className='MyAccountMobileMenu-NoData' />;
    }
    const { hello, menuItems, signOut } = fields.data.item;

    return (
      <div className='MyAccountMobileMenu'>
        <div className='MyAccountMobileMenu-Welcome'>
          <Text field={hello} />
          {this.getSalutationName(fields)}
        </div>
        {this.getAccountMenuItems(menuItems)}
        <div
          className='MyAccountMobileMenu-Logout'
          onClick={logOutRedirect}
          onKeyDown={e => {
            if (e.target === 13) logOutRedirect();
          }}
          role='button'
          tabIndex='0'
        >
          <Text field={signOut} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMobileMyAccountOpen: state.accountInformation.isMobileMyAccountOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileMyAccount: () => dispatch(toggleMobileMyAccount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(MyAccountMobileMenu));
