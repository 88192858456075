import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../Common/Icons/ChevronDown';
import { withRouter } from 'react-router-dom';
import GeneralLink from '../../Common/GeneralLink/index';
import { apiGatewayHost } from '../../../Constants/General';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';

const MAX_ITEMS = 9;
const SHOW_MORE_ITEMS = 9;

class SimilarAccessoriesForMasterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfItemsToShow: MAX_ITEMS,
      similarAccessoriesData: []
    };
  }

  componentDidMount() {
    this.getDataFromRequest();
  }

  componentDidUpdate(prevProps) {
    const { sitecoreContext } = this.props,
      currentLanguage = sitecoreContext.language;
    if (prevProps.sitecoreContext.language !== currentLanguage) {
      this.getDataFromRequest();
    }
  }

  async getDataFromRequest() {
    const { match, sitecoreContext } = this.props;
    if (match && match.params && match.params.product_name) {
      const response = await fetch(
          apiGatewayHost +
            '/product-data/v1/product/similarAccessories?name=' +
            match.params.product_name +
            '&lang=' +
            sitecoreContext.language +
            '&tenant=' +
            sitecoreContext.site.name,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        ),
        dataJson = await response.json();
      if (dataJson && dataJson.length) {
        this.setState({
          similarAccessoriesData: dataJson
        });
      }
    }
  }

  getSimilarAccessories = () => {
    const { similarAccessoriesData, numberOfItemsToShow } = this.state;
    return similarAccessoriesData.slice(0, numberOfItemsToShow).map((item, index) => {
      const { articleID, articleNumber, masterProductENName } = item,
        link =
          masterProductENName && articleID
            ? {
                href:
                  PRODUCT_PAGE_ROUTE +
                  '/' +
                  masterProductENName.replace(/ /g, '-') +
                  '/' +
                  articleNumber.toString().replace('.', '-')
              }
            : null;
      if (link) {
        const clickClass =
          masterProductENName && articleNumber ? removeWhiteSpaces(masterProductENName + articleNumber) : '';
        return (
          <GeneralLink
            fields={{ value: link }}
            key={articleID + index}
            className='SimilarAccessories-Item'
            clickClassName={'similar_accessories_adp ' + clickClass}
          >
            {this.getCommonContent(item)}
          </GeneralLink>
        );
      }
      return (
        <div className='SimilarAccessories-Item' key={articleID + index}>
          {this.getCommonContent(item)}
        </div>
      );
    });
  };

  getCommonContent(item) {
    const { articleName, articleNumber, imageUrl, masterProductName, subTitleInfo } = item,
      subTitleValue = subTitleInfo && subTitleInfo.value ? subTitleInfo.value : '';
    return (
      <React.Fragment>
        <div className='SimilarAccessories-Item-Image'>
          {imageUrl ? (
            <img src={imageUrl + '?imwidth=320'} alt={articleName} loading='lazy' />
          ) : (
            <div className='SimilarAccessories-Item-Image-Placeholder' />
          )}
        </div>
        <div className='SimilarAccessories-Item-Description'>
          <div className='SimilarAccessories-Item-Description-Title'>{masterProductName}</div>
          <div className='SimilarAccessories-Item-Description-Details'>{subTitleValue}</div>
          <div className='SimilarAccessories-Item-Description-ArticleNumber'>{articleNumber}</div>
        </div>
      </React.Fragment>
    );
  }

  onShowMoreClick = () => {
    this.setState({
      numberOfItemsToShow: this.state.numberOfItemsToShow + SHOW_MORE_ITEMS
    });
  };

  getShowMore = () => {
    const { fields } = this.props,
      { similarAccessoriesData } = this.state;
    if (similarAccessoriesData.length > this.state.numberOfItemsToShow) {
      return (
        <div
          className='SimilarAccessories-More'
          onClick={() => {
            this.onShowMoreClick();
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onShowMoreClick();
          }}
          role='button'
          tabIndex='0'
        >
          <ChevronDown />
          <Text field={fields['Show More']} />
        </div>
      );
    }
  };

  render() {
    const { fields, subCategory } = this.props,
      { similarAccessoriesData } = this.state;
    return (
      <div className='click_similar_accessories_adp'>
        {similarAccessoriesData && similarAccessoriesData.length > 0 ? (
          <div className='SimilarAccessories componentContainer'>
            <div className='SimilarAccessories-Headline'>
              <h2 className='SimilarAccessories-Headline-Title'>
                <Text field={fields.Title} />
                {subCategory && fields['Show Subcategory On Title'] && fields['Show Subcategory On Title'].value
                  ? ' ' + subCategory
                  : ''}
              </h2>
              <Text tag='div' className='SimilarAccessories-Headline-Subtitle' field={fields.Subtitle} />
            </div>
            <div className='SimilarAccessories-Data'>{this.getSimilarAccessories()}</div>
            {this.getShowMore()}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withSitecoreContext()(withRouter(SimilarAccessoriesForMasterPage));
