import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import DesktopMenu from './DesktopMenu/index';
import MobileMenu from './MobileMenu/index';
import Breadcrumb from './Breadcrumb/index';
import { setMenuDataAction, setProductsJsonData, setRedirectPageId } from '../../store/menuflyout/actions';
import { normalizeSitecoreId } from '../../utils/Utils';
import { getLanguage } from '../Common/CookieSettings/SetLanguageData';
import { PRODUCT_PAGE_ROUTE } from '../../AppRoutes';
import { Environment } from './../../Environment';

/**
 * @class Component that renders the main navigation menu placeholders.
 *
 * @param {object} rendering Object containing the data (placeholders) needed for the component.
 *
 * Returns HTML.
 */

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.setData();

    this.headerContainerRef = createRef();
  }

  /**
   * @method componentDidUpdate
   * @description if changes ocur in TabsItems fields set data again
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.rendering.fields.TabsItems) !== JSON.stringify(this.props.rendering.fields.TabsItems) ||
      prevProps.sitecoreContext.language !== this.props.sitecoreContext.language
    ) {
      this.setData();
    }
  }

  setData() {
    let dataOfAllSubmenuItems = [],
      activeMenuFlyoutData = null;
    const rendering = this.props.rendering;

    if (rendering && rendering.fields && rendering.fields.TabsItems) {
      const tabsItems = rendering.fields.TabsItems;
      //iterrate trough tabsItems to se which one corespond to the current url in the browser
      const currentTabData = tabsItems.find(item => {
        const urlString = item.fields.Link.value.href;
        if (urlString && urlString.indexOf('http') < 0) {
          return true;
        }
        return false;
      });
      //store items for current site in activeMenuFlyoutData variable
      activeMenuFlyoutData = currentTabData && currentTabData.fields ? currentTabData.fields.Level2Items : [];
      for (let j = 0; j < activeMenuFlyoutData.length; j++) {
        const activeMenuFlyoutFields = activeMenuFlyoutData[j].fields;

        //store linked page id if Menu Item have Linked page id setted in sitecore
        let flyoutLinkedPageId = null;
        let flyoutLinkedPageUrl = null;
        if (activeMenuFlyoutFields.LinkedItem) {
          flyoutLinkedPageId = normalizeSitecoreId(activeMenuFlyoutFields.LinkedItem.id);

          flyoutLinkedPageUrl =
            activeMenuFlyoutFields.LinkedItem.url &&
            // Remove leading slashes
            activeMenuFlyoutFields.LinkedItem.url.replace(/^\/+/g, '');
        }

        // check if the page is one from the Menu and redirect to home page with open menu flyout on that item
        if (
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.sitecoreRoute === flyoutLinkedPageUrl &&
          flyoutLinkedPageUrl !== null &&
          this.props.match.params
        ) {
          if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.sitecoreRoute === PRODUCT_PAGE_ROUTE &&
            this.props.match.params.product_name &&
            this.props.match.params.product_name !== ''
          ) {
            continue;
          }
          this.props.setRedirectPageIdOnRedux(flyoutLinkedPageId);
          this.props.history.push('/');
        }
        //check if the current item is Products and make a request with endpoint setted on sitecore
        if (activeMenuFlyoutFields.Endpoint && this.props.productsMenuEndpoint !== activeMenuFlyoutFields.Endpoint) {
          this.props.setProductsJson(activeMenuFlyoutFields.Endpoint.value.href);
        }
        //add every Menu Item in this array formated
        dataOfAllSubmenuItems = [
          ...dataOfAllSubmenuItems,
          {
            sectionId: activeMenuFlyoutData[j].id,
            linkedItemId: flyoutLinkedPageId,
            sectionName: activeMenuFlyoutData[j].fields.Name.value.toLowerCase(),
            showImage: activeMenuFlyoutFields.ShowThumbnails ? activeMenuFlyoutFields.ShowThumbnails.value : true,
            layout: activeMenuFlyoutFields.FlyoutDesignType ? activeMenuFlyoutFields.FlyoutDesignType.value : null,
            endpoint: activeMenuFlyoutFields.Endpoint ? activeMenuFlyoutFields.Endpoint : null
          }
        ];
      }
    }
    //set Menu Items on Redux
    this.props.setMenuData(activeMenuFlyoutData, dataOfAllSubmenuItems);
  }

  render() {
    const { rendering } = this.props,
      homeUrl = '/' + getLanguage(this.props.sitecoreContext) + '/';
    return (
      <header className='Menu' ref={this.headerContainerRef}>
        <Visible lg xl>
          <div className='visible-from-lg'>
            <DesktopMenu rendering={rendering} homeUrl={homeUrl} />
          </div>
        </Visible>
        <Visible xs sm md>
          <div className='visible-until-md'>
            <MobileMenu rendering={rendering} homeUrl={homeUrl} />
          </div>
        </Visible>
        <Visible lg xl>
          <div className='Breadcrumb-Container'>
            <div className='Breadcrumb-Container-Row'>
              <Breadcrumb isMobile={false} />
            </div>
            <div className='Breadcrumb-Container-Row-Account'>
              <Placeholder name='jss-my-account-desktop-menu' rendering={rendering} />
            </div>
          </div>
        </Visible>
        <Visible xs sm md>
          <Breadcrumb isMobile={true} />
        </Visible>
      </header>
    );
  }
}

const MapStateToProps = state => {
  return {
    productsJson: state.menuFlyout.productsJson,
    productsMenuEndpoint: state.menuFlyout.productsMenuEndpoint,
    submenuItems: state.menuFlyout.submenuItems
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuData: (subMenuJson, dataOfAllSubmenuItems) =>
      dispatch(setMenuDataAction(subMenuJson, dataOfAllSubmenuItems)),
    setProductsJson: endpoint => dispatch(setProductsJsonData(Environment.checkGatewayHost(endpoint))),
    setRedirectPageIdOnRedux: menuFlyoutName => dispatch(setRedirectPageId(menuFlyoutName))
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(Menu)));
