import React from 'react';
import { Player } from 'video-react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class MediaCarousel display images and videos in a carousel
 */
class MediaCarouselElement extends React.Component {
  /**
   *@method getItem
   *@description displays video or image in carousel, from data
   */
  getItem = propsFields => {
    if (
      propsFields.IsVideo.value &&
      propsFields.Video &&
      propsFields.Video.value.src &&
      propsFields.Video.value.src !== '' &&
      propsFields.Video.value.src !== '#'
    ) {
      return (
        <div className='MediaCarousel-Video'>
          <Player
            playsInline
            poster={
              propsFields.VideoOverlay && propsFields.VideoOverlay.value.src ? propsFields.VideoOverlay.value.src : ''
            }
            aspectRatio='16:9'
            ref={player => {
              this.player = player;
            }}
          >
            <source src={propsFields.Video && propsFields.Video.value.src ? propsFields.Video.value.src : ''} />
          </Player>
          <p className='legend'>
            <Text field={propsFields.Caption} />
          </p>
        </div>
      );
    } else {
      return (
        <div className='MediaCarousel-Image'>
          <Image field={propsFields.Image} />
          <p className='legend'>
            <Text field={propsFields.Caption} />
          </p>
        </div>
      );
    }
  };

  render() {
    if (!this.props.fields) {
      return <div className='MediaCarousel-Image' />;
    }

    return this.getItem(this.props.fields);
  }
}

export default MediaCarouselElement;
