import * as ActionTypes from './actionTypes';
/**
 * @method toggleMobileMenu
 * @description Action that open and close the mobile menu
 * @returns {object} Return an object
 */
export const toggleMobileMenu = () => {
  return {
    type: ActionTypes.TOGGLE_MOBILE_MENU
  };
};

/**
 * @method changeOpenAccordionAction
 * @description Action that change the opened accordion.
 * @returns {object} Return an object
 */
export const changeOpenAccordionAction = openedAccordion => {
  return {
    type: ActionTypes.CHANGE_OPEN_ACCORDION,
    openedAccordion
  };
};

/**
 * @method changeSecondLevelOpenAccordionAction
 * @description Action that change the opened accordion.
 * @returns {object} Return an object
 */
export const changeSecondLevelOpenAccordionAction = secondOpenedAccordion => {
  return {
    type: ActionTypes.CHANGE_SECOND_OPEN_ACCORDION,
    secondOpenedAccordion
  };
};

/**
 * Set the id of the active sitecore item on the menu
 * @param {string} crumbId
 */
export const setUnderlineMenuItem = crumbId => {
  return {
    type: ActionTypes.SET_UNDERLINE_MENU_ITEM,
    crumbId
  };
};
