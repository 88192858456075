import { buildSearchStatus, buildUrlManager, SearchEngine } from '@coveo/headless';
import queryString from 'query-string';

/**
 * Search parameters, defined in the url's hash, should not be restored until all components are registered.
 *
 * Additionally, a search should not be executed until search parameters are restored.
 *
 * @param engine - A headless search engine instance.
 * @returns An unsubscribe function to remove attached event listeners.
 */
export function bindUrlManager(engine: SearchEngine) {
  const statusControllers = buildSearchStatus(engine);
  const fragment = () => {
    return window.location.hash.slice(1);
  };

  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: fragment() }
  });
  const onHashChange = () => {
    urlManager.synchronize(fragment());
  };

  window.addEventListener('hashchange', onHashChange);
  const unsubscribeManager = urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`;

    // Solution from https://github.com/coveo/ui-kit/issues/2863
    if (!statusControllers.state.firstSearchExecuted) {
      window.history.replaceState(null, document.title, hash);

      return;
    }

    window.history.pushState(null, document.title, hash);
  });

  return () => {
    window.removeEventListener('hashchange', onHashChange);
    unsubscribeManager();
  };
}

export const updateHashParam = (key: string, value: string) => {
  const hash = window.location.hash.slice(1);
  const params = queryString.parse(hash);
  params[key] = value;
  window.location.hash = queryString.stringify(params, {
    encode: false
  });
};

export const getHashParam = (key: string) => {
  const hash = window.location.hash.slice(1);
  const params = new URLSearchParams(hash);

  return params.get(key);
};
