import { useEffect, useState } from 'react';
import { ImageVideoHeroSlide } from './types';

const useImageVideoHeroSlider: {
  (slides: ImageVideoHeroSlide[]): {
    activeSlide: ImageVideoHeroSlide;
    activeSlideIndex: number;
    isSlider: boolean;
    toPrev: () => void;
    toNext: () => void;
  };
} = slides => {
  const [activeSlide, setActiveSlide] = useState(slides[0]);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const isSlider = slides.length > 1;

  useEffect(() => {
    setActiveSlide(slides[0]);
    setActiveSlideIndex(0);
  }, [slides]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeSlideIndex < slides.length - 1) {
        setActiveSlide(slides[activeSlideIndex + 1]);
        setActiveSlideIndex(activeSlideIndex + 1);
      } else {
        setActiveSlide(slides[0]);
        setActiveSlideIndex(0);
      }
    }, 7000);
    return () => clearInterval(interval);
  }, [isSlider, activeSlide, activeSlideIndex, slides]);

  const toPrev = () => {
    if (activeSlideIndex > 0) {
      setActiveSlide(slides[activeSlideIndex - 1]);
      setActiveSlideIndex(activeSlideIndex - 1);
    } else {
      setActiveSlide(slides[slides.length - 1]);
      setActiveSlideIndex(slides.length - 1);
    }
  };

  const toNext = () => {
    if (activeSlideIndex < slides.length - 1) {
      setActiveSlide(slides[activeSlideIndex + 1]);
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      setActiveSlide(slides[0]);
      setActiveSlideIndex(0);
    }
  };

  return {
    activeSlide,
    activeSlideIndex,
    isSlider,
    toPrev,
    toNext
  };
};

export default useImageVideoHeroSlider;
