import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import SuccessIcon from '../../../Common/Icons/SuccessIcon';

class WishlistRegistrationBenefits extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./WishlistRegistrationBenefits.scss');
  }

  render() {
    const {
      Description,
      'Benefit 1': benefitOne,
      'Benefit 2': benefitTwo,
      'Benefit 3': benefitThree
    } = this.props.fields;
    return (
      <div className='WishlistRegistrationBenefits'>
        <Text field={Description} />
        <div className='WishlistRegistrationBenefits-Item'>
          <SuccessIcon /> <Text field={benefitOne} />
        </div>
        <div className='WishlistRegistrationBenefits-Item'>
          <SuccessIcon /> <Text field={benefitTwo} />
        </div>
        <div className='WishlistRegistrationBenefits-Item'>
          <SuccessIcon /> <Text field={benefitThree} />
        </div>
      </div>
    );
  }
}

export default WishlistRegistrationBenefits;
