import gql from 'graphql-tag';

export const StoryBoxQueryString = urlToUse => {
  return gql`query StoryBoxQuery($dataSource: String, $lang: String) {
    storybox(dataSource: $dataSource, lang: $lang) {
        linkerButtonLabel
        storyItem {
            Image: field(name: "Image") {
                jss
            }
        }
        overlineTitle
        title
        gtm
        description
        ${urlToUse}
    }
}
`;
};
