import * as actionTypes from './actionTypes';

export const setContactPersonData = personData => {
  return {
    type: actionTypes.SET_CONTACT_PERSON_DATA,
    name: (personData && personData.name) || null,
    email: (personData && personData.email) || null,
    phone: (personData && personData.phone) || null,
    salesPoint: (personData && personData.salesPoint) || null
  };
};
