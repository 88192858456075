import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { changeLayout } from '../../../store/menuflyout/actions';
import { setSelectedVariant } from '../../../store/product/actions';
import { changeOpenAccordionAction, toggleMobileMenu, setUnderlineMenuItem } from '../../../store/mobileMenu/actions';
import GraphQLData from '../../../lib/GraphQLData';
import { normalizeSitecoreId, getCurrentArticleNumber } from '../../../utils/Utils';
import ChevronRightSmall from '../../Common/Icons/ChevronRightSmall';
import ChevronLeftSmall from '../../Common/Icons/ChevronLeftSmall';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import { SET_BREADCRUMB_TO_VISIBLE, REMOVE_BREADCRUMB_VISIBLE_STATE } from '../../../store/mobileMenu/actionTypes';
import { PRODUCT_PAGE_ROUTE, PRODUCT_PAGE_EXACT_ROUTE } from '../../../AppRoutes';
import {
  getArticleNumberFromPath,
  getProductNameFromPath,
  isProductDownloadPage,
  normalizeDownloadPagePath
} from '../../Product/AllDownloads/getDownloadsProductAndArticle';

const BreadcrumbQuery = gql`
  query BreadcrumbItems($id: String, $lang: String) {
    allpath(itemId: $id, lang: $lang) {
      breadcrumbs {
        id
        name
        path
      }
    }
  }
`;

/**
 * @class Component that renders the breadcrumb.
 *
 * Returns HTML.
 */
class Breadcrumb extends React.Component {
  componentDidUpdate(prevProps) {
    const {
        dataOfAllSubmenuItems,
        BreadcrumbItems,
        setBreadcrumbToVisible,
        removeBreadcrumbVisibleState,
        isBreadcrumbVisible,
        underlinedMenuItem,
        setUnderlineMenuItem
      } = this.props,
      isBreadcrumbDisplayed = this.isBreadcrumbDisplayed(dataOfAllSubmenuItems, BreadcrumbItems);
    if (!isBreadcrumbVisible && isBreadcrumbDisplayed) {
      setBreadcrumbToVisible();
    } else if (
      (isBreadcrumbVisible && !isBreadcrumbDisplayed) ||
      (prevProps.isBreadcrumbVisible && !isBreadcrumbVisible && !isBreadcrumbDisplayed)
    ) {
      removeBreadcrumbVisibleState();
    }

    if (isBreadcrumbDisplayed) {
      let doWeHaveMenuItem = false;
      for (const crumb of BreadcrumbItems.allpath.breadcrumbs) {
        if (this.checkIfIsSubMenuItem(crumb.id)) {
          doWeHaveMenuItem = true;
          const normalizedCrumbId = normalizeSitecoreId(crumb.id);
          if (underlinedMenuItem !== normalizedCrumbId) {
            setUnderlineMenuItem(normalizedCrumbId);
          }
        }
      }
      if (!doWeHaveMenuItem && underlinedMenuItem !== null) {
        setUnderlineMenuItem(null);
      }
    }
  }

  isBreadcrumbDisplayed(dataOfSubItems, crumbItems) {
    if (
      dataOfSubItems &&
      crumbItems &&
      crumbItems.allpath &&
      crumbItems.allpath.breadcrumbs &&
      crumbItems.allpath.breadcrumbs.length > 1
    ) {
      return true;
    }
    return false;
  }

  checkIfIsSubMenuItem = crumbId => {
    const cleanCrumbId = normalizeSitecoreId(crumbId);
    return this.props.dataOfAllSubmenuItems.some(
      subMenuItem => cleanCrumbId === subMenuItem.linkedItemId && cleanCrumbId !== null
    );
  };

  openMobileMenu(crumbId) {
    this.props.toggleMobileMenu();
    this.props.changeOpenAccordion(normalizeSitecoreId(crumbId));
  }

  openDesktopMenu(dataOfOneItem, flyoutTemplate) {
    const newSectionId = dataOfOneItem.endpoint ? null : dataOfOneItem.sectionId;
    this.props.changeLayout({
      sectionId: newSectionId,
      layout: flyoutTemplate,
      opededFlayout: dataOfOneItem.sectionName,
      showImage: dataOfOneItem.showImage
    });
  }

  getCrumbFaded = (crumbKey, crumb) => {
    return (
      <div className='Breadcrumb-Item-Active' key={crumbKey}>
        <span className='Breadcrumb-Arrow'>
          <ChevronRightSmall className='Breadcrumb-Arrow-Fade' />
        </span>
        {crumb}
      </div>
    );
  };
  getCrumbWithAction = (crumbKey, linkedCrumb) => {
    return (
      <div className='Breadcrumb-Item' key={crumbKey}>
        <span className='Breadcrumb-Arrow'>
          <ChevronRightSmall className='Breadcrumb-Arrow-Black' />
        </span>
        {linkedCrumb}
      </div>
    );
  };

  getMobileCrumb = crumb => {
    return (
      <div className='Breadcrumb-Item'>
        <span className='Breadcrumb-Arrow-Back'>
          <ChevronLeftSmall className='Breadcrumb-Arrow-Black' />
        </span>
        {crumb}
      </div>
    );
  };

  getMobileBreadcrumb = crumbsList => {
    let crumbToDisplay = crumbsList[crumbsList.length - 2];
    const { match } = this.props,
      pageRoute = match && match.params && match.params.sitecoreRoute ? match.params.sitecoreRoute : null;

    // Note pageRoute === "/product", no trailing slash
    if (pageRoute === PRODUCT_PAGE_ROUTE) {
      const { productName, productNameEN } = this.props,
        currentLanguage = getLanguage(this.props.sitecoreContext),
        productNameENForLink = productNameEN ? productNameEN.replace(/ /g, '-') : '',
        productLink = '/' + currentLanguage + PRODUCT_PAGE_EXACT_ROUTE + productNameENForLink,
        starBreadcrumbItem = '*',
        articleNumber = getCurrentArticleNumber(this.props),
        breadcrumbActionItemProduct = (
          <RoutableSitecoreLink
            link={{ value: { href: productLink } }}
            onClick={() => {
              this.props.setSelectedVariant(null);
            }}
          >
            {productName || ' '}
          </RoutableSitecoreLink>
        );
      if (articleNumber) {
        return this.getMobileCrumb(breadcrumbActionItemProduct);
      }

      if (crumbsList[crumbsList.length - 1].name !== starBreadcrumbItem) {
        crumbToDisplay = crumbsList[crumbsList.length - 1];
      }
    }

    let mobileBreadcrumbActionItem = (
      <RoutableSitecoreLink link={{ value: { href: crumbToDisplay.path } }}>{crumbToDisplay.name}</RoutableSitecoreLink>
    );
    if (this.checkIfIsSubMenuItem(crumbToDisplay.id)) {
      mobileBreadcrumbActionItem = (
        <div
          className='Breadcrumb-Item-Flyout'
          onClick={() => this.openMobileMenu(crumbToDisplay.id)}
          onKeyDown={e => {
            if (e.keyCode === 13) this.openMobileMenu(crumbToDisplay.id);
          }}
          role='button'
          tabIndex='0'
        >
          {crumbToDisplay.name}
        </div>
      );
    }

    return this.getMobileCrumb(mobileBreadcrumbActionItem);
  };

  getDesktopBreadcrumb = (arrayOfCrumbs, crumbsLength) => {
    const lastElementIndex = arrayOfCrumbs.length - 1;
    return arrayOfCrumbs.map((crumb, index) => {
      let breadcrumbActionItem = (
        <RoutableSitecoreLink link={{ value: { href: crumb.path } }}>{crumb.name}</RoutableSitecoreLink>
      );
      if (this.props.dataOfAllSubmenuItems) {
        for (let i = 0; i < this.props.dataOfAllSubmenuItems.length; i++) {
          const cleanCrumbId = normalizeSitecoreId(crumb.id),
            dataOfThisItem = this.props.dataOfAllSubmenuItems[i],
            cleanSubmenuId = normalizeSitecoreId(dataOfThisItem.linkedItemId);

          if (cleanCrumbId === cleanSubmenuId) {
            let flyoutTemplate = null;
            switch (dataOfThisItem.layout) {
              case 'OneSection':
                flyoutTemplate = 'THREE_COLUMN_LAYOUT';
                break;
              case 'TwoSections':
                flyoutTemplate = 'THREE_COLUMN_TITLE_LAYOUT';
                break;
              case 'ThreeSections':
                flyoutTemplate = 'THREE_COLUMN_ALL_TITLE_LAYOUT';
                break;
              default:
                break;
            }

            breadcrumbActionItem = (
              <div
                className='Breadcrumb-Item-Flyout'
                onClick={() => this.openDesktopMenu(dataOfThisItem, flyoutTemplate)}
                onKeyDown={e => {
                  if (e.keyCode === 13) this.openDesktopMenu(dataOfThisItem, flyoutTemplate);
                }}
                role='button'
                tabIndex='0'
              >
                {crumb.name}
              </div>
            );
          }
        }
      }

      const isLastItem = lastElementIndex === index,
        cssBreadcrumbItem = classNames({
          'Breadcrumb-Item': !isLastItem,
          'Breadcrumb-Item-Active': isLastItem
        }),
        cssArrow = classNames({
          'Breadcrumb-Arrow-Black': !isLastItem,
          'Breadcrumb-Arrow-Fade': isLastItem
        }),
        { productName, productNameEN, match } = this.props,
        currentLanguage = getLanguage(this.props.sitecoreContext),
        productNameENForLink = productNameEN ? productNameEN.replace(/ /g, '-') : '',
        productLink = '/' + currentLanguage + PRODUCT_PAGE_ROUTE + '/' + productNameENForLink,
        breadcrumbActionItemProduct = (
          <RoutableSitecoreLink
            link={{ value: { href: productLink } }}
            onClick={() => {
              this.props.setSelectedVariant(null);
            }}
          >
            {productName || ' '}
          </RoutableSitecoreLink>
        ),
        pageRoute = match && match.params && match.params.sitecoreRoute ? match.params.sitecoreRoute : null,
        starBreadcrumbItem = '*',
        articleNumber = getCurrentArticleNumber(this.props),
        downloadProductPageRoute = this.props.sitecoreContext?.productDownloads?.productDownloadsDetailPage || '',
        isDownloadPage = isProductDownloadPage(pageRoute, downloadProductPageRoute),
        isNotFound = crumb.path.split('/').includes('404');

      if (!isNotFound) {
        if (pageRoute === PRODUCT_PAGE_ROUTE && crumb.name === starBreadcrumbItem) {
          if (articleNumber) {
            return (
              <React.Fragment key={index + productName}>
                {this.getCrumbWithAction(index + productName, breadcrumbActionItemProduct)}

                {this.getCrumbFaded(index + articleNumber, articleNumber.replace('-', '.'))}
              </React.Fragment>
            );
          }
          return this.getCrumbFaded(index + productName, productName);
        } else if (pageRoute === PRODUCT_PAGE_ROUTE && isLastItem && crumb.name !== starBreadcrumbItem) {
          if (articleNumber) {
            return (
              <React.Fragment key={index + crumb.id}>
                {this.getCrumbWithAction(index + crumb.id, breadcrumbActionItem)}
                {this.getCrumbWithAction(index + productName, breadcrumbActionItemProduct)}

                {this.getCrumbFaded(index + articleNumber, articleNumber.replace('-', '.'))}
              </React.Fragment>
            );
          }
          return (
            <React.Fragment key={index + crumb.id}>
              {this.getCrumbWithAction(index + crumb.id, breadcrumbActionItem)}
              {this.getCrumbFaded(index + productName, productName)}
            </React.Fragment>
          );
        } else if (isDownloadPage && isLastItem) {
          const rawProductNameFromPath = getProductNameFromPath(pageRoute, downloadProductPageRoute);
          const productNameFromPath = rawProductNameFromPath?.replace(/-/g, ' ').toUpperCase();
          const articleNumberFromPath = getArticleNumberFromPath(pageRoute, downloadProductPageRoute);
          const actionItemLink = `${normalizeDownloadPagePath(downloadProductPageRoute)}/${rawProductNameFromPath}`;
          const actionItemProduct = (
            <RoutableSitecoreLink
              link={{ value: { href: actionItemLink } }}
              onClick={() => {
                this.props.setSelectedVariant(null);
              }}
            >
              {productNameFromPath || ' '}
            </RoutableSitecoreLink>
          );

          return articleNumberFromPath ? (
            <React.Fragment key={index + crumb.id}>
              {this.getCrumbWithAction(index + crumb.id, actionItemProduct)}
              {this.getCrumbFaded(index + articleNumberFromPath, articleNumberFromPath)}
            </React.Fragment>
          ) : (
            <React.Fragment key={index + crumb.id}>
              {this.getCrumbFaded(index + productNameFromPath, productNameFromPath)}
            </React.Fragment>
          );
        }
      }

      return (
        <div className={cssBreadcrumbItem} key={index + crumb.id}>
          {index === 3 && crumbsLength > 5 ? (
            <React.Fragment>
              <span className='Breadcrumb-Arrow'>
                <ChevronRightSmall className='Breadcrumb-Arrow-Black' />
              </span>
              ...
            </React.Fragment>
          ) : null}
          {index !== 0 ? (
            <span className='Breadcrumb-Arrow'>
              <ChevronRightSmall className={cssArrow} />
            </span>
          ) : null}
          {isLastItem ? crumb.name : breadcrumbActionItem}
        </div>
      );
    });
  };

  /**
   * @method getBreadcrumb
   * @description create and display the breadcrumbs
   *
   * Returns the breadcrumb in a jsx format.
   */
  getBreadcrumb() {
    let piecesOfBreadcrumb = false,
      arrayOfCrumbs = [];

    const { isMobile, BreadcrumbItems } = this.props,
      crumbs = BreadcrumbItems.allpath.breadcrumbs,
      crumbsLength = crumbs.length;

    if (isMobile) {
      piecesOfBreadcrumb = this.getMobileBreadcrumb(crumbs);
    } else {
      if (crumbsLength > 5) {
        arrayOfCrumbs = [...crumbs.slice(0, 3), ...crumbs.slice(crumbsLength - 2, crumbsLength)];
      } else {
        arrayOfCrumbs = crumbs;
      }

      piecesOfBreadcrumb = this.getDesktopBreadcrumb(arrayOfCrumbs, crumbsLength);
    }

    if (piecesOfBreadcrumb) {
      return <div className='Breadcrumb'>{piecesOfBreadcrumb}</div>;
    }

    return null;
  }

  render() {
    const { dataOfAllSubmenuItems, BreadcrumbItems } = this.props;
    if (!this.isBreadcrumbDisplayed(dataOfAllSubmenuItems, BreadcrumbItems)) {
      return null;
    }
    return this.getBreadcrumb();
  }
}

const MapStateToProps = state => {
  const { masterProductData } = state.productDetails;

  return {
    dataOfAllSubmenuItems: state.menuFlyout.dataOfAllSubmenuItems,
    isBreadcrumbVisible: state.mobileMenu.isBreadcrumbVisible,
    underlinedMenuItem: state.mobileMenu.underlinedMenuItem,
    productName: masterProductData ? masterProductData.MasterProductName : null,
    productNameEN: masterProductData ? masterProductData.MasterProductENName : null
  };
};

const MapDispatchToProps = dispatch => {
  return {
    changeOpenAccordion: categoryName => dispatch(changeOpenAccordionAction(categoryName)),
    changeLayout: payload => dispatch(changeLayout(payload)),
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
    removeBreadcrumbVisibleState: () => dispatch({ type: REMOVE_BREADCRUMB_VISIBLE_STATE }),
    setBreadcrumbToVisible: () => dispatch({ type: SET_BREADCRUMB_TO_VISIBLE }),
    setUnderlineMenuItem: crumbId => dispatch(setUnderlineMenuItem(crumbId)),
    setSelectedVariant: payload => dispatch(setSelectedVariant(payload))
  };
};

export default GraphQLData(BreadcrumbQuery, {
  name: 'BreadcrumbItems',
  options: props => ({
    variables: {
      id: props.sitecoreContext.itemId,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'cache-and-network'
  })
})(withSitecoreContext()(connect(MapStateToProps, MapDispatchToProps)(withRouter(Breadcrumb))));
