import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';

interface ProgressBarProps {
  className?: string;
  amountOfSlides: number;
  activeSlideIndex: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ amountOfSlides, activeSlideIndex, ...rest }) => {
  const pagerBars = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const highlightRef = useRef<HTMLDivElement>(null);
  const [highlightLeftPosition, setHighlightLeftPosition] = useState(0);
  const [highlightWidth, setHighlightWidth] = useState(0);

  const moveHighlight = useCallback(() => {
    const currentBar = pagerBars.current[activeSlideIndex];
    const highlight = highlightRef.current;
    if (currentBar && highlight) {
      setHighlightLeftPosition(currentBar.current?.offsetLeft ?? 0);
      setHighlightWidth(currentBar.current?.offsetWidth ?? 0);
    }
  }, [activeSlideIndex]);

  useEffect(() => {
    moveHighlight();
  }, [moveHighlight]);

  // Create the refs for the pager bars
  pagerBars.current = [];
  for (let i = 0; i < amountOfSlides; i++) {
    pagerBars.current.push(React.createRef<HTMLDivElement>());
  }

  return (
    <div {...rest} className={classNames('ProgressBar', rest.className)} data-testid='progress-bar'>
      {pagerBars.current.map((ref, i) => (
        <div key={i} className='ProgressBar__element' ref={ref} />
      ))}

      <div
        className='ProgressBar__highlight'
        ref={highlightRef}
        style={{ left: highlightLeftPosition, width: highlightWidth }}
      />
    </div>
  );
};

export default ProgressBar;
