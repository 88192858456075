import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';

/**
 * @class Component that shows the description teaser used in different components.
 *
 * @param {object} props Object containing the data (text) needed for the component.
 *
 * Returns jsx markup containing a Text and RichText component.
 */
const PageDescriptionTeaser = props => {
  return (
    <div className='PageDescriptionTeaser'>
      <Text field={props.rendering.fields.Headline} tag='span' className='PageDescriptionTeaser-headline' />
      <Row>
        <Col xl={8}>
          <RichText field={props.rendering.fields.Description} className='PageDescriptionTeaser-description' />
        </Col>
      </Row>
    </div>
  );
};

export default PageDescriptionTeaser;
