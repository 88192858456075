import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../Icons/ChevronDown';
import ChevronUp from '../../Icons/ChevronUp';
import WithStickyMenu from '../../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../HeadlineWithTitleAndSubtitle/index';
/**
 * @class Component that renders an text and a button that display another text if the user want it
 *
 * @param {object} props Object containing the Text (for RichText)
 *
 * @returns jsx
 */
class ReadMoreRichText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHiddenSecondAbstarct: true
    };
  }

  toggleSecondAbstract = () => {
    this.setState(prevState => ({
      isHiddenSecondAbstarct: !prevState.isHiddenSecondAbstarct
    }));
  };

  getButtonOrText = secondAbstract => {
    if (!secondAbstract || !secondAbstract.value || secondAbstract.value === '') {
      return null;
    }
    return this.state.isHiddenSecondAbstarct ? (
      <div
        className='ReadMoreRichText-More'
        onClick={this.toggleSecondAbstract}
        onKeyDown={e => {
          if (e.keyCode === 13) this.toggleSecondAbstract();
        }}
        role='button'
        tabIndex='0'
      >
        <ChevronDown />
        <Text field={this.props.fields['ReadMore Label']} />
      </div>
    ) : (
      <React.Fragment>
        <RichText className='ReadMoreRichText-SecondAbstract' field={secondAbstract} />
        <div
          className='ReadMoreRichText-Less'
          onClick={this.toggleSecondAbstract}
          onKeyDown={e => {
            if (e.keyCode === 13) this.toggleSecondAbstract();
          }}
          role='button'
          tabIndex='0'
        >
          <ChevronUp />
          <Text field={this.props.fields['Less Label']} />
        </div>
      </React.Fragment>
    );
  };

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground componentContainer' />;
    }

    const {
        Title,
        Subtitle,
        'Main Abstract': MainAbstract,
        'Second Abstract': SecondAbstract,
        BackgroundColor
      } = this.props.fields,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      };
    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='ReadMoreRichText componentContainer'>
          <HeadlineWithTitleAndSubtitle extraClasses='ReadMoreRichText-Headline' title={Title} subtitle={Subtitle} />
          <RichText className='ReadMoreRichText-FirstAbstract' field={MainAbstract} />
          {this.getButtonOrText(SecondAbstract)}
        </div>
      </div>
    );
  }
}

const hasItems = function (props) {
  const { fields } = props;
  if (!fields) {
    return false;
  }
  return true;
};

export default WithStickyMenu(ReadMoreRichText, hasItems);
