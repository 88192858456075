import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

class ContactCareerCard extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./ContactCareerCard.scss');
  }
  render() {
    const { title, location, country, picture, label, workload } = this.props.item;
    return (
      <div className='ContactCareerCard'>
        <div className='ContactCareerCard-Image'>
          <Image field={picture} />
        </div>
        <div className='ContactCareerCard-Details'>
          <div className='ContactCareerCard-Title'>
            {title}
            {title && workload && ' / '}
            {workload}
          </div>
          <div className='ContactCareerCard-Location'>
            {label}&nbsp;
            {location}
            {location && country && ' , '}
            {country}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactCareerCard;
