import React from 'react';
import PlayIcon from '../../Common/Icons/PlayIcon';
import Icon360 from '../../Common/Icons/Icon360';

class ProductHeroMainImage extends React.Component {
  getIcon = itemType => {
    if (itemType === 'video/mp4') {
      return <PlayIcon onClick={this.props.mainImageClicked} />;
    } else if (itemType === '3Dobject') {
      return <Icon360 onClick={this.props.mainImageClicked} />;
    }
  };

  render() {
    const imageItem = this.props.imageItem || null;
    if (!imageItem || !imageItem.url) {
      return <div className='ProductHeroMainImage' />;
    }
    return (
      <div
        className='ProductHeroMainImage'
        onClick={this.props.mainImageClicked}
        onKeyDown={e => {
          if (e.keyCode === 13) this.props.mainImageClicked();
        }}
        role='button'
        tabIndex='0'
      >
        {this.getIcon(imageItem.type)}
        <img src={imageItem.thumbnail + '?imwidth=768'} alt={imageItem.alt || null} loading='lazy' />
      </div>
    );
  }
}

export default ProductHeroMainImage;
