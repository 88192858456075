import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../GeneralLink/index';
import ArrowRight from '../../Icons/ArrowRight';
import { removeWhiteSpaces } from '../../../../utils/Utils';

const SolutionCard = props => {
  const { 'Portrait Image': Img, Overline, Title, Text: Txt, 'ClickText GTM English': englishGtm } = props.data.fields,
    PageSelection = props.data.fields[props.urlToUse] || null,
    gtmText = englishGtm && englishGtm.value ? removeWhiteSpaces(englishGtm.value) : '';

  return (
    <div className='SolutionCard'>
      <GeneralLink fields={PageSelection} clickClassName={'click_content_block_solutions ' + gtmText}>
        <Image className='SolutionCard-Image' field={Img} />
        <div className='SolutionCard-Overlay' />
        <div className='SolutionCard-Content'>
          <Text tag='div' className='SolutionCard-Content-Overline' field={Overline} />
          <Text tag='div' className='SolutionCard-Content-Title' field={Title} />
          {props.isMobile ? null : (
            <React.Fragment>
              <RichText tag='div' className='SolutionCard-Content-Description' field={Txt} />
              <div className='SolutionCard-Content-ExploreMore'>
                <Text field={props.exploreMoreLabel} />
                <ArrowRight />
              </div>
            </React.Fragment>
          )}
        </div>
      </GeneralLink>
    </div>
  );
};

export default SolutionCard;
