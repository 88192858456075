import { getJsonResponse } from '../../utils/fetch';
import { PRODUCT_ITEMS_ENDPOINT } from './productendpoint';
import { Product, ProductItem } from './types';

export const getSpec = (product: ProductItem): { name: string; value: string }[] => {
  return (
    product.filterValues.map(filter => ({
      name: filter.name,
      value: filter.value
    })) ?? []
  );
};

export const getProduct = (
  endpoint: string,
  countryCode: string,
  language: string,
  tenant: string
): Promise<
  | {
      product: Product;
      productItems: ProductItem[];
    }
  | undefined
> => {
  return getJsonResponse<Product>(endpoint)
    .then(async (product: Product) => {
      if (product && product.MasterProductID) {
        return {
          product: product,
          productItems: await getJsonResponse<ProductItem[]>(
            PRODUCT_ITEMS_ENDPOINT(product.MasterProductID, countryCode, language, tenant)
          )
        };
      }
    })
    .catch(error => {
      throw new Error('Something went wrong');
    });
};
