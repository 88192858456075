import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

class CompareApplicationDesktop extends React.Component {
  render() {
    return (
      <div className='CompareApplication-Row'>
        <div className='CompareApplication-Item CompareApplication-Name'>
          <div className='CompareApplication-Item-Image'>
            <Image
              field={{
                value: {
                  src: this.props.picture
                }
              }}
            />
          </div>
          <span className='CompareApplication-Item-Title'>{this.props.title}</span>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default CompareApplicationDesktop;
