import React from 'react';
import { connect } from 'react-redux';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Visible } from 'react-grid-system';
import { getLocalStorage } from '../../../Common/CookieSettings/CookieUtils';
import { setInitialData, setCurrentComparedCategory } from '../../../../store/compare/actions';
import GeneralLink from '../../../Common/GeneralLink';
import ArrowLeft from '../../../Common/Icons/ArrowLeft';
import CompareCategory from './CompareCategory';
import Dropdown from '../../../Common/Dropdown';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';

class CompareCategories extends React.Component {
  componentDidMount() {
    this.setSelectedCategoryOnRedux();
  }

  componentDidUpdate() {
    this.setSelectedCategoryOnRedux();
  }

  setSelectedCategoryOnRedux = () => {
    const { selectedCategory, updateSelectedCategory } = this.props;

    if (!selectedCategory) {
      const categories = this.getCategoriesInfo();
      const categoryName = Object.keys(categories)[0];

      updateSelectedCategory(categoryName);
    }
  };

  getBackLink = () => {
    const { 'Back Label': backLabel } = this.props.fields,
      previousPageLocation = getLocalStorage('previousPageLocation'),
      previusPageName = getLocalStorage('previousPageTitle'),
      currentLanguage = getLanguage(this.props.sitecoreContext),
      reg = new RegExp('^/[a-z]{2}/'),
      languageCheck = reg.test(previousPageLocation),
      previousPagePath = languageCheck ? '/' + currentLanguage + previousPageLocation.substr(3) : previousPageLocation;
    return (
      <GeneralLink fields={{ value: { href: previousPagePath } }} className='CompareCategories-BackButton'>
        <ArrowLeft /> <Text field={backLabel} />
        &nbsp;
        {previusPageName}
      </GeneralLink>
    );
  };

  getCategoriesInfo = () => {
    const { initialData } = this.props;

    return initialData
      .map(item => item.category)
      .reduce((names, name) => {
        const count = names[name] || 0;
        names[name] = count + 1;
        return names;
      }, {});
  };

  // render categories for desktop
  getCategories = () => {
    const categories = this.getCategoriesInfo();
    return Object.keys(categories).map((item, index) => {
      return (
        <CompareCategory
          fields={this.props.fields}
          categoryName={item}
          noOfProducts={categories[item]}
          key={index + item}
        />
      );
    });
  };

  // render dropdown categories for mobile
  getCategoriesForMobileDropdown = () => {
    const categories = this.getCategoriesInfo(),
      { selectedCategory } = this.props,
      { 'Choose List Label': listLabel } = this.props.fields,
      firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : '',
      categoriesDropdownList = Object.keys(categories).map(item => {
        return {
          title: `${item} (${categories[item]})`,
          name: item
        };
      });

    return (
      <Dropdown
        label={listLabel.value}
        currentItem={{
          title: `${firstSelectedCategory} (${categories[firstSelectedCategory]})`
        }}
        items={categoriesDropdownList}
        onSet={this.onSetCategory}
      />
    );
  };

  // update on redux current selected category for mobile dropdown
  onSetCategory = item => {
    const { updateSelectedCategory } = this.props;

    updateSelectedCategory(item.name);
  };
  render() {
    return (
      <div className='CompareCategories'>
        {this.getBackLink()}
        <div className='CompareCategories-Categories'>
          <Visible xs sm md>
            {this.getCategoriesForMobileDropdown()}
          </Visible>
          <Visible lg xl>
            {this.getCategories()}
          </Visible>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialData: state.compareProduct.initialData,
    selectedCategory: state.compareProduct.selectedCategory
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateInitialData: compareProducts => dispatch(setInitialData(compareProducts)),
    updateSelectedCategory: category => dispatch(setCurrentComparedCategory(category))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(CompareCategories));
