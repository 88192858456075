import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-grid-system';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import GeneralLink from '../../Common/GeneralLink/index';
import classNames from 'classnames';

/**
 *  Component that renders a title, a link button and remark text, on a background color
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class CustomerButton extends React.Component {
  getButton = (link, label) => {
    if (link && link.value.href) {
      return (
        <GeneralLink fields={link}>
          <Text field={label} />
        </GeneralLink>
      );
    }
    return (
      <div className='CustomerButton-ButtonDisable'>
        <Text field={label} tag='span' className='CustomerButton-ButtonDisable-Text' />
      </div>
    );
  };
  render() {
    if (!hasItems(this.props)) {
      return <div className='CustomerButton' />;
    }
    const {
      'Remark Text': remarkText,
      'Button Description': buttonDescription,
      Title,
      Overline,
      Link,
      BackgroundColor
    } = this.props.fields;
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
          ? BackgroundColor.fields.ColorCode.value
          : null,
      backgroundImage: BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? 'none' : null
    };
    /**
     * displays remark text if it exists in data
     */
    const remarkTextClass = classNames('CustomerButton-RemarkText', {
        WithoutRemarkText: !remarkText.value,
        WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
      }),
      titleClass = classNames('CustomerButton-Title', {
        WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
      }),
      overlineClass = classNames('CustomerButton-Overline', {
        WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
      });

    return (
      <div className='CustomerButton' style={inlineStyle}>
        <Row nogutter className='CustomerButton-Row componentContainer'>
          <Col xl={8} lg={8} md={12} sm={12} className='CustomerButton-Wrapper'>
            <Text field={Overline} tag='div' className={overlineClass} />
            <Text field={Title} className={titleClass} tag='h2' />
            {this.getButton(Link, buttonDescription)}
            <RichText field={remarkText} className={remarkTextClass} />
          </Col>
        </Row>
      </div>
    );
  }
}
const hasItems = function (props) {
  const { fields } = props;
  if (!fields) {
    return false;
  }
  return true;
};

export default WithStickyMenu(CustomerButton, hasItems);
