import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  stickyMenuData: [],
  currentLanguage: null,
  contextLanguage: null
};

/**
 * @method productDetails
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const stickyMenu = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STICKY_MENU_ITEM:
      return setStickyMenuData(state, action);

    case actionTypes.RESET_STICKY_MENU_DATA:
      return {
        ...state,
        stickyMenuData: []
      };
    case actionTypes.DELETE_STICKY_MENU_ITEM: {
      const newStickyMenuData = state.stickyMenuData.filter(item => item !== action.stickyTitle);

      if (newStickyMenuData.length === state.stickyMenuData.length) {
        return state;
      }

      return {
        ...state,
        stickyMenuData: newStickyMenuData
      };
    }
    default:
      return state;
  }
};

const setStickyMenuData = (state, action) => {
  const currentLanguage = action.currentLanguage;
  //if language changed set this item as the only item of the array
  if (currentLanguage !== state.currentLanguage) {
    return {
      ...state,
      stickyMenuData: [action.stickyTitle],
      currentLanguage
    };
  }

  //return state if item already exist
  if (state.stickyMenuData.includes(action.stickyTitle)) {
    return state;
  }

  const newStickyMenuData = [...state.stickyMenuData, action.stickyTitle];
  return {
    ...state,
    stickyMenuData: newStickyMenuData
  };
};
