import React, { useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../../Common/GeneralLink/index';
import { cutStringWithDots, removeWhiteSpaces, addProductOnDataLayer } from '../../../../utils/Utils';
import {
  setProductToCompareOnCookie,
  removeProductFromCompareCookie,
  getCookie,
  getRawValueFromCompare
} from '../../../Common/CookieSettings/CookieUtils';
import { COMPARE } from '../../../Common/CookieSettings/CookieConstants';
import CompareBox from '../../../Product/HeroActionButton/CompareBox';
import { CoveoProductLinkerCardProps } from '../types';

export const CoveoProductCarouselCard = ({ currentItem, fields }: CoveoProductLinkerCardProps) => {
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const cookieCompare = getCookie(COMPARE);
  const cookieCompareList = cookieCompare ? JSON.parse(cookieCompare) : {};
  const productCategory = currentItem.CategoryEn ? currentItem.CategoryEn.split(',') : [];
  const checkedNoOnComparelist = productCategory.find(
    item => (cookieCompareList[item] && cookieCompareList[item].length < 4) || !cookieCompareList[item]
  );
  const compareListId = getRawValueFromCompare();
  const [checkAlreadyAdded, setCheckAlreadyAdded] = useState(compareListId.some(element => element === currentItem.Id));

  const compareActionTriggered = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setProductToCompareOnCookie(currentItem.CategoryEn, currentItem.Id);
      setCheckAlreadyAdded(true);
      setIsCompareOpen(true);
    } else {
      removeProductFromCompareCookie(currentItem.Id);
      setCheckAlreadyAdded(false);
      setIsCompareOpen(false);
    }
  };

  const onCompareClose = () => {
    setIsCompareOpen(false);
  };

  return (
    <div className='CoveoProductCarousel-Card'>
      <div
        onClick={() => {
          addProductOnDataLayer(
            currentItem.Id,
            currentItem.NameEn,
            currentItem.CategoryEn,
            currentItem.ProductUrl,
            currentItem.pimbrandname
          );
        }}
        onKeyDown={e => {
          if (e.key === 'Enter')
            addProductOnDataLayer(
              currentItem.Id,
              currentItem.NameEn,
              currentItem.CategoryEn,
              currentItem.ProductUrl,
              currentItem.pimbrandname
            );
        }}
        role='button'
        tabIndex={0}
      >
        {/*
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
        <GeneralLink
          clickClassName={'click_content_block_product_linker_carousel ' + removeWhiteSpaces(currentItem.NameEn || '')}
          fields={{
            value: { href: currentItem.ProductUrl }
          }}
        >
          <div className='CoveoProductCarousel-Card-Image'>
            {currentItem.ImageUrl ? <img src={currentItem.ImageUrl} alt={currentItem.Name} loading='lazy' /> : null}
          </div>

          <div className='CoveoProductCarousel-Card-Content'>
            <div className='CoveoProductCarousel-Card-Content-Title'>{currentItem.Name}</div>
            <div className='CoveoProductCarousel-Card-Content-Description'>
              {cutStringWithDots(currentItem.Description, 120)}
            </div>
          </div>
        </GeneralLink>
      </div>
      <div className='CoveoProductCarousel-Card-Content-CompareBoxWrapper'>
        <CompareBox
          fields={fields}
          isCompareOpen={isCompareOpen}
          onClose={onCompareClose}
          handleClickOutside={onCompareClose}
          checkedNoOfProducts={checkedNoOnComparelist}
          categoryEn={currentItem.CategoryEn}
        />
      </div>
      <label className='CoveoProductCarousel-Card-Content-Compare'>
        <input type='checkbox' onChange={compareActionTriggered} checked={checkAlreadyAdded} />
        <span className='checkmark' />
        <Text field={fields['Compare Label']} />
      </label>
    </div>
  );
};

export default CoveoProductCarouselCard;
