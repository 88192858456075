import React from 'react';
import { ButtonProps } from './types';
import Spinner from '../Spinner';
import classNames from 'classnames';
import Icon from '../Icon';

// TODO: When we touch this component again, we should improve the button "focus" styling, currently there is no visible focus state

const Button = ({
  buttonTitle,
  className,
  beforeIcon,
  icon,
  variant = 'primary',
  size = 'medium',
  isLoading = false,
  square = false,
  iconSize = 16,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button
    className={classNames(
      'button',
      {
        'button--primary': variant === 'primary',
        'button--secondary': variant === 'secondary',
        'button--text': variant === 'text',
        'button--ghost': variant === 'ghost',
        'button--small': size === 'small',
        'button--no-size': size === 'no-size',
        'button--no-size-small': size === 'no-size-small',
        'button--medium': size === 'medium',
        'button--large': size === 'large',
        'button--square': square,
        'button--is-loading': isLoading
      },
      className
    )}
    type='button'
    disabled={isLoading}
    {...props}
  >
    {beforeIcon && (
      <Icon
        name={beforeIcon}
        size={iconSize}
        className='button__before-icon'
        color={variant === 'primary' ? 'white' : 'red'}
      />
    )}
    {icon && (
      <Icon name={icon} size={iconSize} className='button__icon' color={variant === 'primary' ? 'white' : 'red'} />
    )}
    {buttonTitle && (
      <span
        className={classNames({
          'visually-hidden': square
        })}
      >
        {buttonTitle}
      </span>
    )}
    {isLoading && (
      <div className='button__loading-spinner'>
        <Spinner />
      </div>
    )}
  </button>
);

export default Button;
