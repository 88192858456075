import React from 'react';
import classNames from 'classnames';

const Accordion = props => {
  //check if this is the opened accodrion
  const isThisAccordionOpen = props.openAccordion === props.accordionId;

  // set classes for header text and conditionaly add the active class if thios is the opened accordion
  let headerClasses = classNames(props.accordionClass + '-Header ' + (props.clickClasses || ''), {
    active: isThisAccordionOpen
  });

  /**
   * @method getAccordionBody
   * @return the html body of the accordion if this is the open accordion
   * or an empty string if it is closed
   */
  const getAccordionBody = () => {
    if (isThisAccordionOpen) {
      return <div className={props.accordionClass + '-Body'}>{props.accordionBody}</div>;
    }
    return '';
  };
  return (
    <React.Fragment>
      <div className={props.accordionClass}>
        <div
          className={headerClasses}
          onClick={props.headerClicked}
          onKeyDown={e => {
            if (e.keyCode === 13) props.headerClicked();
          }}
          role='button'
          tabIndex='0'
        >
          {props.headerContent}
        </div>
        {getAccordionBody()}
      </div>
    </React.Fragment>
  );
};

export default Accordion;
