import { FacetValueState, SearchEngine } from '@coveo/headless';
import * as React from 'react';

type Facet = {
  numberOfResults: number;
  state: FacetValueState;
  value: string;
};

export interface TabResultCount {
  numberOfResults: number;
  value: string;
}

export interface NumericFacet {
  field: string;
  unit: string;
  precision: number;
}

export interface SearchState {
  tabResultsCount: TabResultCount[];
  activeTab: string;
  activeTabValue: string;
  filterBannerTabId: string;
  selectedAccessory: string;
  selectedFacets: { [x: string]: Facet[] };
  storedSelectedFacets: { [x: string]: Facet[] };
  numericFacets: NumericFacet[];
  isFacetsModalOpen: boolean;
  layout: 'List' | 'Card';
}

export const initialState: SearchState = {
  tabResultsCount: [],
  activeTab: '',
  activeTabValue: '',
  filterBannerTabId: '',
  selectedAccessory: '',
  selectedFacets: {},
  storedSelectedFacets: {},
  numericFacets: [],
  isFacetsModalOpen: false,
  layout: 'Card'
};

export interface SearchContextInterface {
  state: SearchState;
  dispatch: React.Dispatch<SearchAction>;
}

const SearchContext = React.createContext<SearchContextInterface>({
  state: {
    tabResultsCount: [],
    activeTab: '',
    activeTabValue: '',
    filterBannerTabId: '',
    selectedAccessory: '',
    selectedFacets: {},
    storedSelectedFacets: {},
    numericFacets: [],
    isFacetsModalOpen: false,
    layout: 'Card'
  },
  dispatch: () => {}
});

export enum ActionType {
  UPDATE_TAB_RESULTS_COUNT = 'UPDATE_TAB_RESULTS_COUNT',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  OPEN_FACETS = 'OPEN_FACETS',
  TOGGLE_FACETS = 'TOGGLE_FACETS',
  CLOSE_FACETS = 'CLOSE_FACETS',
  UPDATE_FACET_SELECTED_COUNT = 'UPDATE_FACET_SELECTED_COUNT',
  SET_SELECTED_ACCESSORY = 'SET_SELECTED_ACCESSORY',
  REGISTER_NUMERIC_FACET = 'REGISTER_NUMERIC_FACET'
}

export type SearchAction = {
  type: ActionType;
  payload?: unknown;
};

const searchReducer = (state: SearchState, action: SearchAction): typeof initialState => {
  switch (action.type) {
    case 'UPDATE_TAB_RESULTS_COUNT': {
      return {
        ...state,
        tabResultsCount: action.payload as TabResultCount[]
      };
    }

    case 'SET_ACTIVE_TAB': {
      return {
        ...state,
        activeTab: (action.payload as { id: string }).id,
        activeTabValue: (action.payload as { activeTabValue: string }).activeTabValue,
        filterBannerTabId: (action.payload as { filterBannerTabId: string }).filterBannerTabId
      };
    }

    case 'UPDATE_FACET_SELECTED_COUNT': {
      const payload = action.payload as { id: string; selected: Facet[] };
      return {
        ...state,
        selectedFacets: {
          ...state.selectedFacets,
          [payload.id]: payload.selected
        }
      };
    }

    case 'CLOSE_FACETS': {
      return {
        ...state,
        isFacetsModalOpen: false
      };
    }

    case 'TOGGLE_FACETS': {
      return {
        ...state,
        isFacetsModalOpen: !state.isFacetsModalOpen
      };
    }

    case 'OPEN_FACETS': {
      return {
        ...state,
        isFacetsModalOpen: true,
        storedSelectedFacets: { ...state.selectedFacets }
      };
    }

    case 'SET_SELECTED_ACCESSORY': {
      return {
        ...state,
        selectedAccessory: action.payload as string
      };
    }

    case 'REGISTER_NUMERIC_FACET': {
      return {
        ...state,
        numericFacets: [...state.numericFacets, action.payload as NumericFacet]
      };
    }

    default:
      throw new Error();
  }
};

const SearchContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(searchReducer, initialState as SearchState);
  const value = { state, dispatch };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export { SearchContext, SearchContextProvider };
