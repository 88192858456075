import React, { useEffect, useState } from 'react';
import InlineConfirmationDialog from '../../../Common/InlineConfirmationDialog';
import i18n from 'i18next';
import DeleteItemButton from '../DeleteItemButton';
import EditItemQuantityButton from '../EditItemQuantityButton';
interface InlineConfirmationDialogWrapperProps {
  item: any;
  updateQuantity: (quantity: number) => void;
}

const CartItemActions: React.FC<InlineConfirmationDialogWrapperProps> = ({ item, updateQuantity }) => {
  const initialQuantity = item.quantity;
  const [visualQuantity, setVisualQuantity] = useState(initialQuantity);
  const [showConfirmation, setShowConfirmation] = useState(false); // update the quantity, if the request outside updated the value

  useEffect(() => {
    setVisualQuantity(item.quantity);
  }, [item, item.quantity]);

  const handleConfirm = () => {
    setShowConfirmation(false);
    updateQuantity(visualQuantity);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setVisualQuantity(initialQuantity);
  }; // We can handle quantity updates as well as deletions in the same handler
  // Because its simply done on the quantity 0 state
  // This way we can keep the logic in one place to show the confirmation dialog

  const handleQuantityChange = (newQuantity: number) => {
    if (newQuantity < 1) {
      setVisualQuantity(0);

      // Show confirmation dialog if the current item belongs to a bundle
      if (item._leisterRelatedBundle?.value) {
        setShowConfirmation(true);
      } else {
        // other items are just getting deleted
        updateQuantity(0);
      }
    } else {
      setVisualQuantity(newQuantity);
      updateQuantity(newQuantity);
    }
  };

  return (
    <>
      {showConfirmation ? (
        <InlineConfirmationDialog
          message={i18n.t('SHOP | Cart delete bundle confirmation message')}
          confirmText={i18n.t('SHOP | Cart delete bundle confirm text')}
          cancelText={i18n.t('SHOP | Cart delete bundle cancel text')}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      ) : (
        <>
          <div className='CartItem__quantity CartItem__quantity--small'>
            <EditItemQuantityButton type='minus' quantity={visualQuantity} setQuantity={handleQuantityChange} />
            <p className='CartItem__quantity-text' data-testid='cart-item-quantity-text'>
              <span className='CartItem__quantity-value'>{visualQuantity}</span>
            </p>
            <EditItemQuantityButton type='plus' quantity={visualQuantity} setQuantity={handleQuantityChange} />
          </div>
          <DeleteItemButton deleteAction={() => handleQuantityChange(0)} />
        </>
      )}
    </>
  );
};

export default CartItemActions;
