import i18n from 'i18next';

export const getLocalizedFinancialStatus = (status: string): string => {
  return i18n.t(`ORDERS | Order Payment Status ${status}`);
};

export const getLocalizedFulfillmentStatus = (status: string): string => {
  return i18n.t(`ORDERS | Order Fulfillment Status ${status}`);
};

export const getOrderListContextHint = (countryCode: string): string => {
  const text = `SHOP_${countryCode?.toUpperCase()} | Order list context hint`;
  return i18n.t(text);
};

export const extractShopifyOrderIdForNavigation = (originalOrderId: string): string => {
  // Shopify order ID format: gid://shopify/Order/5868665766177?key=c0992ecda2361d6fb7041353cc5a9671
  // We are only interested in the "5868665766177" part

  const orderId = originalOrderId.split('/').pop()?.split('?')[0];
  return orderId || '';
};

export const getOrderDetailPageUrl = (orderId: string, configuredDetailPageUrl: string): string => {
  // configuredDetailPageUrl looks something like /myAccount/my_orders_dev/,-w-,
  // We replace the wildcard (,-w-,) with the actual order ID.
  // If there is no wildcard, we just append the order ID to the end of the URL
  return configuredDetailPageUrl.includes(',-w-,')
    ? configuredDetailPageUrl.replace(',-w-,', orderId)
    : `${configuredDetailPageUrl}/${orderId}`;
};
