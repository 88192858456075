import * as ActionTypes from './actionTypes';

const initialState = {
  isMobileMyAccountOpen: false,
  addressList: {
    state: 'initial',
    data: []
  }
};

export const accountInformation = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MOBILE_MY_ACCOUNT_MENU:
      return {
        ...state,
        isMobileMyAccountOpen: !state.isMobileMyAccountOpen
      };
    case ActionTypes.SET_ADDRESS_LIST_LOADING:
      return {
        ...state,
        addressList: {
          ...state.addressList,
          state: 'loading'
        }
      };
    case ActionTypes.SET_ADDRESS_LIST_LOADED:
      return {
        ...state,
        addressList: {
          ...state.addressList,
          state: 'loaded',
          data: action.addressList
        }
      };
    case ActionTypes.SET_ADDRESS_LIST_LOAD_ERROR:
      return {
        ...state,
        addressList: {
          ...state.addressList,
          state: 'error'
        }
      };
    case ActionTypes.DELETE_ADDRESS:
      return {
        ...state,
        addressList: {
          ...state.addressList,
          data: state.addressList.data.filter(address => address.id !== action.addressId)
        }
      };
    default:
      return state;
  }
};
