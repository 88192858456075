import { InstantResultsState, Result } from '@coveo/headless';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import CloseIcon from '../Icons/CloseIcon';

type AutosuggestProps = {
  componentName?: string;
  value: string;
  instantResultsOpened: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  instantResultsState: InstantResultsState;
  setInstantResultsOpened: (instantResultsOpened: boolean) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submit: () => void;
  onSelect: (selectedResult: Result) => void;
  renderItem: (result: Result, selected: boolean) => React.ReactNode;
  clearInputAndFocus?: () => void;
  inputIcon?: React.ReactNode;
  preselectedSuggestion?: number;
  minCharsToSearch?: number;
  placeholderText: string;
};

const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';
const isEscKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Escape';

const Autosuggest: React.FC<AutosuggestProps> = ({
  componentName = 'Searchbox',
  value,
  instantResultsOpened,
  inputRef,
  handleInputChange,
  instantResultsState,
  setInstantResultsOpened,
  submit,
  clearInputAndFocus,
  renderItem,
  onSelect,
  inputIcon = null,
  preselectedSuggestion = -1,
  minCharsToSearch = 3,
  placeholderText = ''
}) => {
  const [suggestionsKey, setSuggestionsKey] = useState(0);
  const [currentSuggestionSelected, setCurrentSuggestionSelected] = useState(preselectedSuggestion);

  useEffect(() => {
    setCurrentSuggestionSelected(preselectedSuggestion);
  }, [value, preselectedSuggestion]);

  const toggleSuggestions = () => {
    if (instantResultsOpened) {
      setInstantResultsOpened(false);
    } else {
      if (instantResultsState.results.length && value.length >= minCharsToSearch) {
        setInstantResultsOpened(true);
      }
    }
  };

  const moveSelection = (direction: 'up' | 'down') => {
    const maxIndex = instantResultsState.results.length - 1;
    const currentIndex = currentSuggestionSelected;

    const nextIndex =
      direction === 'up'
        ? currentIndex <= 0
          ? maxIndex
          : currentIndex - 1
        : currentIndex === maxIndex
        ? 0
        : currentIndex + 1;

    setCurrentSuggestionSelected(nextIndex);
  };

  return (
    <div className={`${componentName}__searchbox`}>
      <label className={`${componentName}__label visually-hidden`} htmlFor='coveo-searchbox'>
        {i18n.t('SEARCH | Searchbox label')}
      </label>
      <div className={`${componentName}__input-wrapper`}>
        {/* see ticket: https://github.com/w3c/aria/issues/1875 */}
        {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
        <input
          className={`${componentName}__input`}
          ref={inputRef}
          value={value}
          type='text'
          id='coveo-searchbox'
          autoComplete='off'
          aria-describedby='coveo_search_box_description'
          aria-expanded={value && instantResultsState.results.length > 0 ? 'true' : 'false'}
          title={i18n.t('SEARCH | Searchbox title')}
          placeholder={placeholderText}
          onChange={handleInputChange}
          onClick={toggleSuggestions}
          onKeyDown={e => {
            if (isEnterKey(e)) {
              if (!instantResultsOpened || currentSuggestionSelected === -1) {
                submit();
              } else {
                const selectedResult = instantResultsState.results[currentSuggestionSelected];
                if (selectedResult) {
                  onSelect(selectedResult);
                }
              }
            } else if (isEscKey(e)) {
              if (instantResultsOpened) {
                setInstantResultsOpened(false);
                setSuggestionsKey(suggestionsKey + 1);
              }
            } else if (e.which === 38 || e.which === 40) {
              if (instantResultsOpened) {
                if (e.which === 38) {
                  moveSelection('up');
                } else {
                  moveSelection('down');
                }
              } else {
                if (instantResultsState.results.length > 0 && value.length >= minCharsToSearch) {
                  setInstantResultsOpened(true);
                }
              }
            }
          }}
        />
        {value ? (
          <button
            className={`${componentName}__clear`}
            onClick={clearInputAndFocus}
            aria-label={i18n.t('SEARCH | Searchbox clear button')}
          >
            <CloseIcon />
          </button>
        ) : (
          inputIcon
        )}
      </div>
      {instantResultsOpened && (
        <fieldset className={`${componentName}__suggestions-wrapper`}>
          <legend className='visually-hidden'>{i18n.t('SEARCH | Searchbox suggestions')}</legend>
          <div className='visually-hidden'>
            <p role='alert'>
              {instantResultsState.results.length} {i18n.t('SEARCH | Searchbox suggestions count')}
            </p>
          </div>
          <div className={`${componentName}__suggestions`} key={suggestionsKey}>
            {instantResultsState.results.map((result: Result, index: number) => (
              <div className={`${componentName}__suggestion`} key={result.uniqueId}>
                {renderItem(result, index === currentSuggestionSelected)}
              </div>
            ))}
          </div>
        </fieldset>
      )}
      <div className='visually-hidden' id='coveo_search_box_description'>
        {i18n.t('SEARCH | Searchbox suggestions description')}
      </div>
    </div>
  );
};

export default Autosuggest;
