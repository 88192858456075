import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Dropdown from '../Dropdown/index';
import { extractLanguageData, extractSelectedRegionCountry } from './LanguageUtils';
import { setCookie, getCookie } from '../CookieSettings/CookieUtils';
import { COUNTRY_CODE, LANG, DEFAULT_DATA, REGION } from '../CookieSettings/CookieConstants';
import CloseIcon from '../Icons/CloseIcon';
import { generateLink } from '../../../utils/Utils';
import OutsideClickHandler from 'react-outside-click-handler';

class LanguageFlyout extends React.Component {
  constructor(props) {
    super(props);

    const data = extractLanguageData(this.props.dropdownItems, this.props.currentRegion, this.props.currentCountry);

    const selectedData = extractSelectedRegionCountry(
      this.props.dropdownItems,
      this.props.currentRegion,
      this.props.currentCountry
    );

    let country = null;
    if (!selectedData[0].dropdownItems) {
      country = DEFAULT_DATA.region['Available Countries'][0];
    } else {
      country = selectedData[0].items[0];
    }

    this.state = {
      isDropdownOpenRegion: false,
      isDropdownOpenCountry: false,
      selectedRegion: selectedData[0] /** default selected region */,
      selectedCountry: country,
      selectedLanguage: '',
      itemsArray: this.props.dropdownItems || [] /** array with all data, for regions and countries */,

      itemsData: data,
      applyButtonActive: false
    };
  }
  componentDidMount() {
    const defaultLang = this.getDefaultDropdownLanguage(this.state.selectedCountry.dropdownItems);
    this.setState({
      selectedLanguage: defaultLang || ''
    });
  }

  /**
   * @description sets the selected option in state
   */

  onSetRegion = item => {
    const defaultLang = this.getDefaultDropdownLanguage(item.dropdownItems[0].dropdownItems),
      cookieCountry = getCookie(COUNTRY_CODE);

    this.setState({
      selectedRegion: item,
      selectedCountry: item.dropdownItems[0],
      selectedLanguage: defaultLang || '',
      applyButtonActive: item.dropdownItems[0].code !== cookieCountry
    });
  };

  /**
   * @description sets the selected option in state
   */

  onSetCountry = item => {
    const defaultLang = this.getDefaultDropdownLanguage(item.dropdownItems),
      cookieCountry = getCookie(COUNTRY_CODE);
    this.setState({
      selectedCountry: item,
      selectedLanguage: defaultLang || '',
      applyButtonActive: item.code !== cookieCountry
    });
  };
  /**
   * @description sets the selected language in state and in fast selection in SelectLanguage bar
   */
  onSetLanguage = item => {
    const cookieLanguage = getCookie(LANG);
    this.setState({
      selectedLanguage: item,
      applyButtonActive: item.code !== cookieLanguage
    });
  };
  /**
   * @description display Apply Button acive or disable, according to state
   */

  displayApplyButton = () => {
    if (this.state.applyButtonActive) {
      return (
        <div
          className='LanguageFlyout-ApplyButton'
          onClick={this.onApplyOptions}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onApplyOptions();
          }}
          role='button'
          tabIndex='0'
          aria-label='Apply selected options'
        >
          <Text field={this.props.buttonLabel} tag='div' className='LanguageFlyout-ApplyButton-Text' />
        </div>
      );
    }
    return (
      <div className='LanguageFlyout-ApplyButton-Disable'>
        <Text field={this.props.buttonLabel} tag='div' className='LanguageFlyout-ApplyButton-Text' />
      </div>
    );
  };

  /**
   * @description sets the selected language  in fast selection in SelectLanguage bar
   */
  onApplyOptions = () => {
    const { selectedLanguage, selectedCountry, selectedRegion } = this.state,
      language =
        selectedLanguage.code && selectedLanguage.code.value ? selectedLanguage.code.value : selectedLanguage.code,
      urlPath = generateLink(language);

    setCookie(REGION, selectedRegion.code, 365);
    setCookie(COUNTRY_CODE, selectedCountry.code, 365);

    this.props.onFLyoutChange(language, selectedCountry.code, selectedRegion.code);

    if (selectedLanguage !== '') {
      setCookie(LANG, language, 365);
    }

    if (typeof window !== 'undefined') {
      window.location.pathname = urlPath.value.href;
    }
  };

  /**
   * @description close dropdown on selected option
   */
  onDropdownClickRegion = () => {
    this.setState({
      isDropdownOpenRegion: !this.state.isDropdownOpenRegion,
      isDropdownOpenCountry: false
    });
  };

  /**
   * @description close dropdown on selected option
   */
  onDropdownClickCountry = () => {
    this.setState({
      isDropdownOpenCountry: !this.state.isDropdownOpenCountry,
      isDropdownOpenRegion: false
    });
  };

  /**
   * @description get default language om dropdown when open flyout
   */
  getDefaultDropdownLanguage = dropdownLanguages => {
    const currentLang = this.props.currentLang;
    if (dropdownLanguages) {
      return dropdownLanguages.find(
        item => currentLang === (item.code && item.code.value ? item.code.value : item.code)
      );
    }
  };

  render() {
    return (
      <OutsideClickHandler onOutsideClick={this.props.handleClickOutside}>
        <div className='LanguageFlyout'>
          <Text field={this.props.title} className='LanguageFlyout-Headline' tag='span' />
          <span
            className='LanguageFlyout-Close'
            onClick={() => {
              this.props.onClose();
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) this.props.onClose();
            }}
            role='button'
            tabIndex='0'
            aria-label='Close'
          >
            <CloseIcon />
          </span>
          <RichText field={this.props.description} className='LanguageFlyout-Description' />
          <Dropdown
            label={this.props.regionLabel}
            currentItem={this.state.selectedRegion}
            items={this.state.itemsData}
            onSet={this.onSetRegion}
            isDropdownOpen={this.state.isDropdownOpenRegion}
            onDropdownClick={this.onDropdownClickRegion}
            height={150}
          />
          <Dropdown
            label={this.props.countryLabel}
            currentItem={this.state.selectedCountry}
            items={this.state.selectedRegion.allItems || this.state.selectedRegion.dropdownItems}
            onSet={this.onSetCountry}
            hasSearch={true}
            isDropdownOpen={this.state.isDropdownOpenCountry}
            onDropdownClick={this.onDropdownClickCountry}
          />

          <Dropdown
            label={this.props.languageLabel}
            currentItem={this.state.selectedLanguage}
            items={this.state.selectedCountry.dropdownItems}
            onSet={this.onSetLanguage}
            height={150}
          />
          {this.displayApplyButton()}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default LanguageFlyout;
