import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class Component that renders a text and richtext.
 *
 * @param {object} props Object containing the data (text) needed for the component.
 *
 * Returns jsx markup containing a Text and RichText component wrapped in a div.
 */
const HeadlineRichtextTeaser = props => {
  const { Title, Description } = props.fields;
  if (!Title && !Description) {
    return null;
  }
  return (
    <div className='HeadlineRichtextTeaser'>
      <Text className='title' tag='h2' field={Title} />
      <RichText field={Description} />
    </div>
  );
};

export default HeadlineRichtextTeaser;
