import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-grid-system';

const TableRowTeaser = props => (
  <Row nogutter className='SimpleTableTeaser-Row'>
    <Col xs={4}>
      <Text tag='div' className='SimpleTableTeaser-Row-Item' field={props.fields.DescriptionLeft} />
    </Col>
    <Col xs={4}>
      <Text tag='div' className='SimpleTableTeaser-Row-Item' field={props.fields.DescriptionMiddle} />
    </Col>
    <Col xs={4}>
      <Text tag='div' className='SimpleTableTeaser-Row-Item' field={props.fields.DescriptionRight} />
    </Col>
  </Row>
);

export default TableRowTeaser;
