import classNames from 'classnames';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../SearchResultList';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { getCoveoSitecoreSource } from '../../../../AppGlobals';
import FacetsModal from '../../FacetsModal';
import FacetManager from '../../FacetManager';
import { useCoveoQuery } from '../../CoveoQuery';
import CategorFacet from '../../CategoryFacet';
import FloatingFilterToggle from '../../FloatingFilterToggle';
import { CoveoContext } from '../../context/CoveoContext';
import sortWorkLoadOptions from './sortOptions.workLoad';
import PageOmnibox from '../../PageOmnibox';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getResultUrl } from '../../ResultItemUtils';

type JobsListerComponentProps = {
  history: RouteComponentProps['history'];
};

export const JobsListerComponent: React.FC<JobsListerComponentProps> = ({ history }) => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, coveoSiteName, locale }
  } = useContext(SearchEngineContext);
  const { buildFacetController, querySummaryController, searchBoxController, instantResultsController, search } =
    useCoveoQuery({
      engine,
      aq: `@source=="${getCoveoSitecoreSource()}" AND @z95xlanguage=="${locale}"`,
      cq: `@site=="${coveoSiteName}" OR @z95xtemplatename=="Job"`
    });
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine, querySummaryController]);

  const facets = [
    {
      field: 'country',
      label: i18n.t('SEARCH | Facets country')
    },
    {
      field: 'location',
      label: i18n.t('SEARCH | Facets location')
    },
    {
      field: 'company',
      label: i18n.t('SEARCH | Facets company')
    },
    {
      field: 'department',
      label: i18n.t('SEARCH | Facets department')
    },
    {
      field: 'workz32xload',
      label: i18n.t('SEARCH | Facets workz32xload'),
      sort: sortWorkLoadOptions
    },
    {
      field: 'workz32xez120xperience',
      label: i18n.t('SEARCH | Facets workz32xez120xperience')
    },
    {
      field: 'primaryz32xlanguage',
      label: i18n.t('SEARCH | Facets primaryz32xlanguage')
    }
  ];

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <PageOmnibox
        instantResultsController={instantResultsController}
        searchBoxController={searchBoxController}
        search={search}
        onSuggestionClick={({ raw }) => {
          history?.push(getResultUrl(raw, siteName));
        }}
        placeholderText={i18n.t('SEARCH | Job lister searchbox placeholder')}
      />
      <div className='SearchResults__Main'>
        <FloatingFilterToggle />
        <FacetsModal hideOnDesktop={true} foldable={false} />
        <FacetManager
          staticOnDesktop={true}
          engine={engine}
          hasResults={(state.hasResults || state.firstSearchExecuted) && !state.isLoading}
        >
          {facets.map(facet => {
            const facetController = buildFacetController(facet.field);

            return (
              <CategorFacet
                key={facet.field}
                controller={facetController}
                title={facet.label}
                id={facet.field}
                sort={facet.sort}
              />
            );
          })}
        </FacetManager>
        {state.hasResults && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList engine={engine} layout='List' query={state.query} siteName={siteName} language={locale} />
      </div>
    </div>
  );
};

export default withRouter<
  RouteComponentProps & JobsListerComponentProps,
  ComponentType<RouteComponentProps & JobsListerComponentProps>
>(JobsListerComponent);
