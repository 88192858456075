import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  // formsData: [],
  //currentFormFields: null,
  //formsTitle: null,
  isContactChangeActive: false,
  currentInfoPointId: null,
  currentInfoPointIsOptOutForFormMailSubmission: false,
  currentInfoPointEmail: null,
  savedContactRelativePath: null,
  currentInfoSalesEmail: null,
  currentInfoSalesPageId: null,
  lastActiveFormTitle: null,
  careerFormData: null,
  jobInfoHidden: null,
  dropdownForms: []
};

/**
 * @method contactForms
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const contactForms = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_POINT_INFO_ID:
      return {
        ...state,
        currentInfoPointId: action.contactPointInfoId
      };
    case actionTypes.SET_CONTACT_POINT_INFO_EMAIL:
      return {
        ...state,
        currentInfoPointIsOptOutForFormMailSubmission: action.contactPointInfoIsOptOutForFormMailSubmission,
        currentInfoPointEmail: action.contactPointInfoEmail
      };
    case actionTypes.SET_SALES_CONTACT_EMAIL:
      return {
        ...state,
        currentInfoSalesEmail: action.currentInfoSalesEmail,
        currentInfoSalesPageId: action.currentInfoSalesPageId
      };
    case actionTypes.SET_STATUS_TO_CONTACT_CHANGE:
      return {
        ...state,
        isContactChangeActive: action.isActive,
        savedContactRelativePath: action.relativePath,
        lastActiveFormTitle: action.lastActiveFormTitle
      };

    case actionTypes.SET_CAREER_FORM_DATA:
      return {
        ...state,
        careerFormData: action.jobData
      };

    case actionTypes.SET_JOB_INFORMATION_HIDDEN:
      return {
        ...state,
        jobInfoHidden: action.jobInfo
      };
    case actionTypes.SET_DROPDOWN_FORMS:
      return {
        ...state,
        dropdownForms: action.data
      };

    default:
      return state;
  }
};
