import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { changeLayout } from '../../../../store/menuflyout/actions';
import { normalizeSitecoreId, removeWhiteSpaces } from '../../../../utils/Utils';
/**
 * @class SubMenuItemsDesktop display and handle actions on sub menu links
 */

class SubMenuItemsDesktop extends React.Component {
  /**
   * @method onClick
   * @description Handle click event on a item.
   */
  onClick = async item => {
    const lowercaseItem = item.fields.Name.value.toLowerCase();
    let itemLayout = null,
      itemId = null;
    if (!item.fields.Endpoint) {
      itemId = item.id;
      switch (item.fields.FlyoutDesignType.value) {
        case 'OneSection':
          itemLayout = 'THREE_COLUMN_LAYOUT';
          break;
        case 'TwoSections':
          itemLayout = 'THREE_COLUMN_TITLE_LAYOUT';
          break;
        case 'ThreeSections':
          itemLayout = 'THREE_COLUMN_ALL_TITLE_LAYOUT';
          break;
        default:
          break;
      }
    }
    this.props.changeLayout({
      sectionId: itemId,
      layout: itemLayout,
      opededFlayout: lowercaseItem,
      showImage: item.fields.ShowThumbnails ? item.fields.ShowThumbnails.value : true
    });
  };

  /**
   * @method getContent
   * @description Iterate through items and build html markup for each item.
   */
  getContent = () => {
    const { submItems, openedFlayout, underlinedMenuItem, flyoutToOpenFromRedirect } = this.props;
    if (submItems !== null) {
      return submItems.map((item, index) => {
        const { LinkedItem, Name, 'ClickText GTM English': gtm } = item.fields,
          normalizedItemId = normalizeSitecoreId(LinkedItem && LinkedItem.id ? LinkedItem.id : null),
          gtmText = gtm && gtm.value ? removeWhiteSpaces(gtm.value) : '',
          itemToLowerCase = Name.value.toLowerCase(),
          itemClasses = classNames('SubMenuItems-item main_menu_main_level ' + gtmText, {
            active:
              openedFlayout === itemToLowerCase ||
              (underlinedMenuItem && underlinedMenuItem === normalizedItemId && openedFlayout === null)
          });
        if (flyoutToOpenFromRedirect !== null && normalizedItemId && flyoutToOpenFromRedirect === normalizedItemId) {
          this.onClick(item);
        }
        return (
          <div
            key={index + 'desktopContent'}
            className={itemClasses}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onClick(item);
            }}
            onClick={() => {
              this.onClick(item);
            }}
            role='link'
            tabIndex='0'
          >
            <Text field={Name} />
          </div>
        );
      });
    }

    return '';
  };

  render() {
    return <React.Fragment>{this.getContent()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    openedFlayout: state.menuFlyout.openFlayout,
    submItems: state.menuFlyout.submenuItems,
    flyoutToOpenFromRedirect: state.menuFlyout.redirectedPageId,
    underlinedMenuItem: state.mobileMenu.underlinedMenuItem
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLayout: payload => dispatch(changeLayout(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuItemsDesktop);
