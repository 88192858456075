import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
/**
 * Display an image and a title in right of it
 * @param {object} props object that will contain a link, a title and an image
 */
class ImageTextRight extends React.Component {
  getContent = () => {
    let image = '',
      link = this.props.link,
      title = this.props.title,
      titleClass = 'ImageTextRight-Title';

    if (this.props.title.value) {
      title = <Text field={this.props.title} />;
    }

    if (this.props.isDataFromSitecore) {
      if (this.props.img.value.src && this.props.isShowThumbnails) {
        image = <Image className='ImageTextRight-Image' media={this.props.img.value} />;
      }
      if (this.props.link.value.href && this.props.link.value.href !== '') {
        link = this.props.link.value.href;
      } else {
        link = '#';
      }
    } else {
      if (this.props.img && this.props.img !== '' && !this.props.img.value && this.props.isShowThumbnails) {
        image = <img className='ImageTextRight-Image' src={this.props.img} alt={title} loading='lazy' />;
      }
    }
    if (image === '' && this.props.isShowThumbnails) {
      image = <div className='gray-placeholder' />;
    } else if (image === '' && !this.props.isShowThumbnails) {
      titleClass = 'ImageTextRight-Title-NoImage';
    }

    return (
      <a href={link} className={'ImageTextRight ' + (this.props.measuringClasses || '')}>
        {image}
        <div className={titleClass}>{title}</div>
      </a>
    );
  };
  render() {
    return this.getContent();
  }
}

export default ImageTextRight;
