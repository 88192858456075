import React from 'react';
import { SitecoreIcon } from '../../Common/Icon/SitecoreIcon';

class ShareEmailLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentLink: '' };
  }

  componentDidMount() {
    const currentLink = window.location.href;
    this.setState({ currentLink: currentLink });
  }

  render() {
    if (!this.props.fields) {
      return <div className='ShareEmailLink' />;
    }
    const { Subject, Body, Icon } = this.props.fields;

    return (
      <div className='ShareEmailLink'>
        <a
          href={'mailto:?subject=' + Subject.value + '&body=' + Body.value + '%0D%0A' + this.state.currentLink}
          className='ShareEmailLink-Link'
          target='blank'
        >
          <SitecoreIcon icon={Icon} hoverIcon color='black-30' size={32} />
        </a>
      </div>
    );
  }
}

export default ShareEmailLink;
