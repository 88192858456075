import React from 'react';
import CalendarIcon from '../../../../Icons/Calendar';
import { EventDateProps } from '../types';

export const getFormattedEventDate = (startDateObj: Date, endDateObj: Date, language: string): string => {
  // Servers timezone
  const timeZone = 'Europe/Amsterdam';

  const startDay = new Intl.DateTimeFormat(language, {
    day: '2-digit',
    timeZone
  }).format(startDateObj);
  const startMonth = new Intl.DateTimeFormat(language, {
    month: 'short',
    timeZone
  }).format(startDateObj);
  const startYear = new Intl.DateTimeFormat(language, {
    year: 'numeric',
    timeZone
  }).format(startDateObj);

  const endDay = new Intl.DateTimeFormat(language, { day: '2-digit', timeZone }).format(endDateObj);
  const endMonth = new Intl.DateTimeFormat(language, {
    month: 'short',
    timeZone
  }).format(endDateObj);
  const endYear = new Intl.DateTimeFormat(language, {
    year: 'numeric',
    timeZone
  }).format(endDateObj);

  // do not render the start year, if it's the same year
  // do not render the start month, if it's the same month of the same year
  // do not render the start day, if the event starts and ends in the same day
  const isSameDay = startDay === endDay && startMonth === endMonth && startYear === endYear;
  const isSameMonthAndYear = startMonth === endMonth && startYear === endYear;
  const isSameYear = startYear === endYear;

  const renderStartDay = isSameDay ? '' : `${startDay} `;
  const renderStartMonth = isSameMonthAndYear ? '' : `${startMonth} `;
  const renderStartYear = isSameYear ? '' : `${startYear} `;
  const renderSeparator = renderStartDay || renderStartMonth || renderStartYear ? '- ' : '';

  return `${renderStartDay}${renderStartMonth}${renderStartYear}${renderSeparator}${endDay} ${endMonth} ${endYear}`;
};

export const EventDate = ({ eventStartDate, eventEndDate, language }: EventDateProps): JSX.Element => {
  const startDateText = eventStartDate.value || '';
  const endDateText = eventEndDate.value || '';

  const startDateObj = new Date(startDateText);
  const endDateObj = new Date(endDateText);

  const formattedEventDate = getFormattedEventDate(startDateObj, endDateObj, language);

  return (
    <>
      <CalendarIcon />
      {formattedEventDate}
    </>
  );
};
