import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ImageGallery from 'react-image-gallery';

/**
 * @class Component that renders an image gallery.
 *
 * @param {object} props Object containing the data (images) needed for the component.
 *
 * Returns jsx markup containing an image gallery with text captions, all wrapped in a div.
 */
const MediaGalleryTeaser = props => {
  const images = props.fields.Media.map(image => {
    const obj = {
      original: image.fields.media.value.src,
      thumbnail: image.fields.media.value.src
    };
    return obj;
  });
  return (
    <div className='MediaGalleryTeaser'>
      <ImageGallery
        items={images}
        lazyLoad={true}
        showBullets={true}
        showFullscreenButton={false}
        showThumbnails={false}
        showPlayButton={false}
      />
      <Text className='caption' tag='div' field={props.fields.Caption} />
    </div>
  );
};
export default MediaGalleryTeaser;
