import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class Component that renders a column containing website details in the Footer component.
 *
 * @param {object} props Object that contains the details data for the footer.
 *
 * @returns Returns HTML.
 */
const FooterCopyright = props => (
  <div className='FooterCopyright'>
    © {new Date().getFullYear() + ' '}
    <Text field={props.fields.Copyright} />
  </div>
);

export default FooterCopyright;
