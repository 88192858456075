import React, { useContext } from 'react';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { getCoveoSitecoreSource } from '../../../../AppGlobals';
import { CoveoCareerPagePipelines, CoveoCareerPageSearchHubs } from '../../CoveoSettings';
import { SearchEngineProps } from '../../CoveoEngine';
import { ActionType, SearchContext } from '../../context/SearchContext';
import JobsListerComponent from './JobsLister';
import CoveoComponent from '../../CoveoComponent';

export const JobsLister = () => {
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);
  const { dispatch } = useContext(SearchContext);

  const advancedQuery = `@source=="${getCoveoSitecoreSource()}" AND @z95xlanguage=="${locale}"`;

  const searchEngineProps: SearchEngineProps = {
    pipeline: CoveoCareerPagePipelines[siteName],
    searchHub: CoveoCareerPageSearchHubs[siteName],
    groupBy: () => [
      {
        field: '@company',
        maximumNumberOfValues: 20,
        sortCriteria: 'occurrences',
        injectionDepth: 10000,
        completeFacetWithStandardValues: true,
        allowedValues: [],
        advancedQueryOverride: advancedQuery,
        constantQueryOverride: `@company==("Leister AG", "Leister Technologies AG", "Axetris AG", "International")`
      }
    ],
    responseMiddleware: response => {
      if (response?.body?.groupByResults?.length) {
        dispatch({
          type: ActionType.UPDATE_TAB_RESULTS_COUNT,
          payload: response?.body?.groupByResults.find((x: any) => x.field === 'company')?.values
        });
      }
    }
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <JobsListerComponent />
    </CoveoComponent>
  );
};

export default JobsLister;
