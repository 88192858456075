import React, { useEffect, useRef } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import EventMarker from './EventMarker';
import { MapScriptLoader, silver_style } from '../../../../../lib/MapScriptLoader';
import { EventMapProps } from './types';

export const EventMap = (props: EventMapProps): JSX.Element | null => {
  const mapRef = useRef(null);
  const zoomLevel = 13;
  const location = {
    lat: Number(props.eventCoordinatesLatitude?.value),
    lng: Number(props.eventCoordinatesLongitude?.value)
  };

  return location.lat && location.lng ? (
    <div ref={mapRef} data-testid='event-map'>
      <MapScriptLoader>
        <GoogleMap
          id='map'
          mapContainerClassName='EventMap'
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          center={location}
          zoom={zoomLevel}
          options={{ styles: silver_style, disableDefaultUI: true, zoomControl: true }}
        >
          <EventMarker {...props} mapRef={mapRef} />
        </GoogleMap>
      </MapScriptLoader>
    </div>
  ) : null;
};
export default EventMap;
