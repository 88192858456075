/**
 * @method extractLanguageData Method that extract data from server and add them in a new json to be more easy to procees
 * @param {*} items Array of objects that contains information about regions, country and languages
 * @return JSON array
 */
export function extractLanguageData(items) {
  if (typeof items === 'undefined' || items.constructor.name !== 'Array' || items.length === 0) {
    return null;
  }

  const resultJSON = items.map(item => {
    const {
      id,
      regionName: rn,
      regionCode: rc,
      iso: is,
      languageName: ln,
      availableCountries: avc,
      languages: lang,
      dropdownLanguages: langDropown
    } = item;
    const obj = {
      id,
      title: rn && rn.value ? rn.value : ln && ln.value ? ln.value : null,
      code: rc && rc.value ? rc.value : is && is.value ? is : null,
      items: extractLanguageData((avc && avc.tags) || (lang && lang.tags)),
      dropdownItems: extractLanguageData((avc && avc.tags) || (langDropown && langDropown.tags))
    };

    return obj;
  });

  return resultJSON;
}

export function extractSelectedRegionCountry(items, region, country) {
  if (typeof items === 'undefined' || items.constructor.name !== 'Array' || items.length === 0) {
    return null;
  }

  const resultJSON = [];

  for (var i = 0; i < items.length; i++) {
    const {
      id,
      regionName: rn,
      regionCode: rc,
      iso: is,
      languageName: ln,
      availableCountries: avc,
      languages: lang,
      dropdownLanguages: langDropown
    } = items[i];

    if (
      (rc &&
        rc.value &&
        rn &&
        rn.value &&
        ((region && rn.value === region.regionName.value) || (country && rn.value === country.regionName.value))) ||
      ln
    ) {
      const obj = {
        id,
        title: rn && rn.value ? rn.value : ln && ln.value ? ln.value : null,
        code: rc && rc.value ? rc.value : is && is.value ? is.value : null,
        items: extractSelectedRegionCountry((avc && avc.tags) || (lang && lang.tags), null, country),
        allItems: extractLanguageData((avc && avc.tags) || (lang && lang.tags)),
        dropdownItems: extractSelectedRegionCountry(
          (avc && avc.tags) || (langDropown && langDropown.tags),
          null,
          country
        )
      };

      resultJSON.push(obj);
    }
  }
  return resultJSON;
}
