import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  masterProductData: null,
  variants: [],
  selectedVariant: null,
  allDownloads: [],
  productPageLinkForGetAQuote: null,
  productDataForQuote: null,
  isLinkForQuote: false,
  restrictedVideos: [],
  howToVideos: [],
  isLinkForExpert: false,
  videoCampaignLoading: null,
  videoCampaignLoaded: null,
  shortProductDataLoading: null,
  shortProductDataLoaded: null,
  allDownloadsLoading: null,
  allDownloadsLoaded: null,
  amountOfBundles: 0,
  isLoading: false,
  shortMasterProductData: null
};

/**
 * @method productDetails
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const productDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PRODUCT_DETAILS:
      return {
        ...initialState
      };
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: true };
    case actionTypes.CLEAR_LOADING:
      return { ...state, isLoading: false };
    case actionTypes.SET_PRODUCT_DATA:
      return {
        ...state,
        masterProductData: action.productData
      };
    case actionTypes.SET_SHORT_PRODUCT_DATA_LOADING:
      return {
        ...state,
        shortProductDataLoading: action.productName
      };
    case actionTypes.SET_SHORT_PRODUCT_DATA_LOADED:
      return {
        ...state,
        shortProductDataLoading: null,
        shortProductDataLoaded: action.productName
      };
    case actionTypes.SET_SHORT_PRODUCT_DATA_LOAD_ERROR:
      return {
        ...state,
        shortProductDataLoading: null,
        shortProductDataLoaded: 'error'
      };
    case actionTypes.SET_SHORT_PRODUCT_DATA:
      return {
        ...state,
        shortMasterProductData: action.shortProductData // Note: This is confusing, not sure why its mapped to "master", it looks not like related to master product.
      };
    case actionTypes.SET_PRODUCT_PAGE_FOR_GET_A_QUOTE:
      return {
        ...state,
        productPageLinkForGetAQuote: action.productPageLink
      };
    case actionTypes.SET_SELECTED_VARIANT:
      return {
        ...state,
        selectedVariant: action.selectedVariant
      };
    case actionTypes.SET_VARIANTS:
      return {
        ...state,
        variants: action.variants
      };
    case actionTypes.SET_ALL_DOWNLOADS:
      return {
        ...state,
        allDownloads: action.allDownloads
      };
    case actionTypes.SET_ALL_DOWNLOADS_LOADING:
      return {
        ...state,
        allDownloadsLoading: action.productKey
      };
    case actionTypes.SET_ALL_DOWNLOADS_LOADED:
      return {
        ...state,
        allDownloadsLoading: null,
        allDownloadsLoaded: action.productKey
      };
    case actionTypes.SET_ALL_DOWNLOADS_LOAD_ERROR:
      return {
        ...state,
        allDownloadsLoading: null,
        allDownloadsLoaded: 'error'
      };
    case actionTypes.SET_DATA_FOR_PRODUCT_QUOTE:
      return {
        ...state,
        productDataForQuote: action.productDataForQuote
      };
    case actionTypes.SET_IS_LINK_FOR_QUOTE:
      return {
        ...state,
        isLinkForQuote: action.isLinkForQuote
      };
    case actionTypes.SET_VIDEO_CAMPAIGN_LOADING:
      return {
        ...state,
        videoCampaignLoading: action.productKey
      };
    case actionTypes.SET_VIDEO_CAMPAIGN_LOADED:
      return {
        ...state,
        videoCampaignLoading: null,
        videoCampaignLoaded: action.productKey
      };
    case actionTypes.SET_VIDEO_CAMPAIGN_LOAD_ERROR:
      return {
        ...state,
        videoCampaignLoading: null,
        videoCampaignLoaded: 'error'
      };
    case actionTypes.SET_VIDEO_CAMPAIGN:
      return {
        ...state,
        restrictedVideos: [...action.restrictedVideos],
        howToVideos: [...action.howToVideos]
      };
    case actionTypes.SET_IS_LINK_FOR_EXPERT:
      return {
        ...state,
        isLinkForExpert: action.isLinkForExpert
      };
    case actionTypes.SET_AMOUNT_OF_BUNDLES:
      return {
        ...state,
        amountOfBundles: action.amountOfBundles
      };

    default:
      return state;
  }
};
