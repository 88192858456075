import React from 'react';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';
import i18n from 'i18next';

export type StoryResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const UniversalStoryResultItem = ({ result, engine, siteName }: StoryResultItemProps) => {
  const { raw } = result;
  const image = raw.storyz32ximage as string;
  const category = raw.category;
  const date = new Date(raw.storyz32xdate as string).toLocaleDateString(
    (raw.culture as string) === 'en' ? 'en-uk' : (raw.culture as string),
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  );
  const linkTitle = i18n.t('STORY | Linker Button');
  const title = raw.title as string;
  const resultLink = getResultUrl(raw, siteName);

  return (
    <div className='UniversalStoryResultItem'>
      <div dangerouslySetInnerHTML={{ __html: image }}></div>
      <div className='UniversalStoryResultItem__Wrapper'>
        <div className='UniversalStoryResultItem__Wrapper-Info'>
          <span className='UniversalStoryResultItem__Wrapper-Info-Category'>{category}</span>|
          <span className='UniversalStoryResultItem__Wrapper-Info-Date'>{date}</span>
        </div>
        <span className='UniversalStoryResultItem__Wrapper-Title'>
          <ResultLink text={title} href={resultLink} className='ResultLink--larger' engine={engine} result={result} />
        </span>
        <ResultLink
          className='ResultLink--important'
          text={linkTitle}
          icon={<ArrowRight />}
          href={resultLink}
          engine={engine}
          result={result}
        />
      </div>
    </div>
  );
};

export default UniversalStoryResultItem;
