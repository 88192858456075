import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import GeneralLink from '../../../Common/GeneralLink/index';
import ArrowRight from '../../Icons/ArrowRight';
import ArrowLeft from '../../Icons/ArrowLeft';
import { removeWhiteSpaces, cutHtmlStringWithDots, cutStringWithDots } from '../../../../utils/Utils';

/**
 * @method ImageSliderComponent
 * @description return an image full with info box or an carousel of images with info box
 * @returns jsx
 */
export default class ImageSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount() {
    if (this.isMoreThanOneItem()) {
      this.setState({
        autoChangeSlide: setTimeout(() => this.onArrowClick(), this.state.slideChangeTimer)
      });
    }
  }

  /**
   * @method componentDidUpdate
   * @description here we check if the currentItemIndex is first or las element (one of the duplicates) and then we set state to move to the first valid image
   */
  async componentDidUpdate() {
    const { currentItemIndex, nrOfItems, widthPercentageByRatio } = this.state;
    if (currentItemIndex === 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.resetAutoPLayTimer();
      this.setState({
        translatedPercent: this.props.isMobile ? 100 : widthPercentageByRatio,
        currentItemIndex: 1
      });
    } else if (currentItemIndex === nrOfItems - 1) {
      await new Promise(resolve => setTimeout(resolve, 100));
      const lastNormalImageIndex = nrOfItems - 2;
      this.resetAutoPLayTimer();
      this.setState({
        translatedPercent: this.props.isMobile
          ? lastNormalImageIndex * 100
          : lastNormalImageIndex * widthPercentageByRatio,
        currentItemIndex: lastNormalImageIndex
      });
    }
  }
  /**
   * @method getInitialState
   * @description set initial state with an array of valid objects with images, number of them, curentItemIndex and translatedPercent
   * @returns initial state
   */
  getInitialState() {
    const widthPercentageByRatio = this.props.leftImageWidth,
      validCarouselElements = [],
      CarouselElements = this.props.fields.CarouselElements;
    let translatedPercentValue = this.props.isMobile ? 100 : widthPercentageByRatio,
      currentItemIndexValue = 1;
    for (let i = 0; i < CarouselElements.length; i++) {
      if (
        CarouselElements[i].fields &&
        CarouselElements[i].fields.Image &&
        CarouselElements[i].fields.Image.value.href !== ''
      ) {
        validCarouselElements.push(CarouselElements[i]);
      }
    }
    if (validCarouselElements.length > 1) {
      validCarouselElements.push(validCarouselElements[0]);
      validCarouselElements.unshift(validCarouselElements[validCarouselElements.length - 2]);
    } else {
      translatedPercentValue = 0;
      currentItemIndexValue = 0;
    }

    return {
      widthPercentageByRatio: widthPercentageByRatio,
      translatedPercent: translatedPercentValue,
      currentItemIndex: currentItemIndexValue,
      CarouselElements: validCarouselElements,
      nrOfItems: validCarouselElements.length,
      slideChangeTimer: 7000
    };
  }

  /**
   * @method resetAutoPLayTimer
   * @description when user make an action reset the timer for auto play and change the slide after the last user interaction
   */
  resetAutoPLayTimer() {
    if (this.isMoreThanOneItem()) {
      clearTimeout(this.state.autoChangeSlide);
      this.setState({
        autoChangeSlide: setTimeout(() => this.onArrowClick(), this.state.slideChangeTimer)
      });
    }
  }

  /**
   * @method onSwipeRight
   * @description decrement currentItemIndex or set it as last element of the array for infinite loop and set translatedPercent to show the right image
   */
  onSwipeRight = () => {
    const { currentItemIndex, nrOfItems, widthPercentageByRatio } = this.state;
    let previousItemIndex = currentItemIndex - 1 > 0 ? currentItemIndex - 1 : nrOfItems - 3;
    const widthOfTheImage = this.props.isMobile ? 100 : widthPercentageByRatio;
    let newTranslatedPercent = (currentItemIndex - 1) * widthOfTheImage;

    if (currentItemIndex === 1) {
      newTranslatedPercent = (nrOfItems - 1) * widthOfTheImage;
      previousItemIndex = nrOfItems - 1;
    }
    this.resetAutoPLayTimer();
    this.setState({
      translatedPercent: newTranslatedPercent,
      currentItemIndex: previousItemIndex
    });
  };

  /**
   * @method onArrowClick
   * @description increment currentItemIndex or set it as first element of the array for infinite loop and set translatedPercent to show the right image
   */
  onArrowClick = () => {
    const { currentItemIndex, nrOfItems, widthPercentageByRatio } = this.state;
    let nextItemIndex = currentItemIndex + 1 <= nrOfItems - 2 ? currentItemIndex + 1 : 1;
    const widthOfTheImage = this.props.isMobile ? 100 : widthPercentageByRatio;
    let newTranslatedPercent = (currentItemIndex + 1) * widthOfTheImage;

    if (currentItemIndex === nrOfItems - 2) {
      newTranslatedPercent = 0;
      nextItemIndex = 0;
    }
    this.resetAutoPLayTimer();
    this.setState({
      translatedPercent: newTranslatedPercent,
      currentItemIndex: nextItemIndex
    });
  };

  /**
   * @method onDotClick
   * @description set currentItemIndex and translatedPercent accordingly to the index of the dot + 1
   */
  onDotClick(dotIndex) {
    const newImageIndex = dotIndex + 1;
    this.resetAutoPLayTimer();
    this.setState({
      translatedPercent: newImageIndex * 100,
      currentItemIndex: newImageIndex
    });
  }

  /**
   * @method getInfo
   * @description get information for the info box with title, overline and button
   * @returns  jsx
   */
  getInfo() {
    if (this.state.CarouselElements[this.state.currentItemIndex]) {
      const currentItemFields = this.state.CarouselElements[this.state.currentItemIndex].fields,
        {
          Overline,
          Title,
          Text: Description,
          'Linker Button Label': LinkerButtonLabel,
          'ClickText GTM English': ClickTextGTM
        } = currentItemFields,
        PageSelection = currentItemFields[this.props.urlToUse] || null,
        descriptionString = Description && Description.value ? Description.value : null,
        titleString = Title && Title.value ? Title.value : null;
      return (
        <div className='ImageSlider-popup'>
          <Text field={Overline} tag='div' className='ImageSlider-popup-headline' />
          <div className='ImageSlider-popup-title'>{cutStringWithDots(titleString, 55)}</div>
          <div className='ImageSlider-popup-description'>{cutHtmlStringWithDots(descriptionString, 55)}</div>
          <GeneralLink
            clickClassName={
              removeWhiteSpaces(ClickTextGTM ? ClickTextGTM.value : null) + ' click_content_block_promotion_slider'
            }
            fields={PageSelection}
            className='ImageSlider-popup-button-link'
          >
            <Text field={LinkerButtonLabel} />
          </GeneralLink>
        </div>
      );
    }
  }

  /**
   * @method getNextLink
   * @description get information for the next arrow title and pagination of the images (ex: 1/3)
   * @returns jsx
   */
  getNextLink() {
    const { currentItemIndex, nrOfItems, CarouselElements } = this.state,
      nextItemIndex = currentItemIndex + 1 <= nrOfItems - 2 ? currentItemIndex + 1 : 1,
      { 'Next Label': nextLabel } = this.props.fields;
    return (
      <div className='ImageSlider-Container-Multiple-Caption-Next'>
        <div className='ImageSlider-Container-Multiple-Caption-Next-Pagination'>
          <div>
            <Text field={nextLabel} /> {nextItemIndex}/{nrOfItems - 2}
          </div>

          <Text field={CarouselElements[nextItemIndex].fields.Overline} tag='div' />
        </div>
        <div className='ImageSlider-Container-Multiple-Caption-Next-Arrow'>
          <ArrowRight />
        </div>
      </div>
    );
  }

  /**
   * @method getNextLink
   * @description get information for the previous arrow title and pagination of the images (ex: 1/3)
   * @returns jsx
   */
  getBackLink = () => {
    const { currentItemIndex, nrOfItems, CarouselElements } = this.state,
      previousItemIndex = currentItemIndex - 1 >= 1 ? currentItemIndex - 1 : nrOfItems - 2,
      { 'Back Label': backLabel } = this.props.fields;
    return (
      <div
        className='ImageSlider-Container-Multiple-Caption-Back'
        onClick={this.onSwipeRight}
        onKeyDown={e => {
          if (e.keyCode === 13) this.onArrowClick();
        }}
        role='button'
        tabIndex='0'
      >
        <div className='ImageSlider-Container-Multiple-Caption-Back-Arrow'>
          <ArrowLeft />
        </div>
        <div className='ImageSlider-Container-Multiple-Caption-Back-Pagination'>
          <div>
            <Text field={backLabel} /> {previousItemIndex}/{nrOfItems - 2}
          </div>

          <Text field={CarouselElements[previousItemIndex].fields.Overline} tag='div' />
        </div>
      </div>
    );
  };

  /**
   * @method getSliderImages
   * @description if is just an image return a full image else a carousel of images
   * @returns jsx
   */
  getSliderImages() {
    const { nrOfItems, CarouselElements, currentItemIndex } = this.state,
      { imageClass } = this.props;
    if (nrOfItems === 1) {
      const img = CarouselElements[0].fields.Image;

      return (
        <li className='ImageSlider-Container-Full'>
          <Image field={img} className='ImageSlider-Container-Full-Image' />
        </li>
      );
    } else if (nrOfItems > 1) {
      return CarouselElements.map((item, index) => {
        const hasOpacityClass =
            currentItemIndex + 1 === index
              ? 'ImageSlider-Container-Multiple-Wrapper LowOpacity'
              : 'ImageSlider-Container-Multiple-Wrapper',
          img = item.fields.Image;
        return (
          <li key={index + item.id} className={imageClass}>
            <div
              className={hasOpacityClass}
              onClick={currentItemIndex !== index ? this.onArrowClick : null}
              onKeyDown={
                currentItemIndex !== index
                  ? e => {
                      if (e.keyCode === 13) this.onArrowClick();
                    }
                  : null
              }
              role='button'
              tabIndex='0'
            >
              <Image field={img} className='ImageSlider-Container-Multiple-Image' />
            </div>
          </li>
        );
      });
    }
  }

  /**
   * @method getDotsNavigation
   * @description create a circle coresponding to each image and jump to that image when the circle is pressed
   * @returns jsx
   */
  getDotsNavigation() {
    let dots = [];
    for (let i = 0; i < this.state.nrOfItems - 2; i++) {
      const isSelectedDot = i + 1 === this.state.currentItemIndex,
        cssClass = classNames('ImageSlider-Dots-Item', {
          'ImageSlider-Dots-Item-Active': isSelectedDot
        });

      dots.push(
        <div
          key={i}
          onClick={() => this.onDotClick(i)}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onDotClick(i);
          }}
          role='button'
          tabIndex='0'
          className={cssClass}
        >
          {isSelectedDot ? <div className='ImageSlider-Dots-Item-Active-Circle' /> : null}
        </div>
      );
    }
    return <div className='ImageSlider-Dots'>{dots}</div>;
  }

  /**
   * @method getNavigationElement
   * @description chose which navigation to show between next arrow and dots novigation
   * @returns jsx
   */
  getNavigationElement() {
    const {
        isMobile,
        fields: { 'Show Back Button': ShowBackButton }
      } = this.props,
      { nrOfItems } = this.state;
    if (nrOfItems > 1 && !isMobile) {
      return (
        <React.Fragment>
          {ShowBackButton && ShowBackButton.value ? this.getBackLink() : null}
          {this.getNextLink()}
        </React.Fragment>
      );
    } else if (nrOfItems > 1 && isMobile) {
      return this.getDotsNavigation();
    }
  }

  /**
   * @method handleStart
   * @description set state properly to start swipe action
   */
  handleStart(touchObject) {
    this.setState({
      direction: null,
      distance: 0,
      elapsedTime: null,
      timeOfLastDragEvent: Date.now(),
      touchStartX: touchObject.pageX,
      touchStartY: touchObject.pageY,
      beingTouched: true
    });
  }
  /**
   * @method handleMove
   * @description set state properly with direction and distance
   */
  handleMove(touchObject) {
    if (this.state.beingTouched) {
      const distX = touchObject.pageX - this.state.touchStartX, // get horizontal dist traveled by finger while in contact with surface
        distY = touchObject.pageY - this.state.touchStartY; // get vertical dist traveled by finger while in contact with surface

      // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
      if (Math.abs(distX) > Math.abs(distY)) {
        const direction = distX < 0 ? 'left' : 'right';
        this.setState({
          direction: direction,
          distance: distX
        });
      }
    }
  }

  /**
   * @method handleEnd
   * @description check if time was short enough and distance is long enough to execute a swipe action and reset swipe details from state
   */
  handleEnd() {
    const allowedTime = 500,
      minDistance = 150,
      elapsedTime = Date.now() - this.state.timeOfLastDragEvent;

    if (elapsedTime <= allowedTime && Math.abs(this.state.distance) >= minDistance) {
      if (this.state.direction === 'right') {
        this.onSwipeRight();
      } else if (this.state.direction === 'left') {
        this.onArrowClick();
      }
    }
    this.resetAutoPLayTimer();
    this.setState({
      beingTouched: false,
      direction: null,
      distance: 0
    });
  }

  handleTouchStart(touchStartEvent) {
    if (this.isMoreThanOneItem()) {
      touchStartEvent.preventDefault();
      this.handleStart(touchStartEvent.targetTouches[0]);
    }
  }

  handleTouchMove(touchMoveEvent) {
    if (this.isMoreThanOneItem()) {
      this.handleMove(touchMoveEvent.targetTouches[0]);
    }
  }

  handleTouchEnd() {
    if (this.isMoreThanOneItem()) {
      this.handleEnd();
    }
  }

  isMoreThanOneItem() {
    if (this.state.CarouselElements.length > 1) {
      return true;
    }
    return false;
  }

  render() {
    const { currentItemIndex, nrOfItems, translatedPercent } = this.state;
    //Return empty jsx div if no images was send to the component
    if (this.state.nrOfItems < 1) {
      return <div className='click_content_block_promotion_slider' />;
    }

    const inlineStyle = {
      transform: 'translate3d(-' + translatedPercent + '%, 0px, 0px)',
      transitionDuration: currentItemIndex === 0 || currentItemIndex === nrOfItems - 1 ? '0ms' : '350ms'
    };
    return (
      <div
        className='ImageSlider click_content_block_promotion_slider'
        onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => this.handleTouchEnd()}
      >
        <ul className='ImageSlider-Container' style={inlineStyle}>
          {this.getSliderImages()}
        </ul>
        {this.getInfo()}
        {this.getNavigationElement()}
      </div>
    );
  }
}
