import React from 'react';
import { ImageWithText } from '../..';

export function ImageWithTextCell({ imageWithText }: { imageWithText: ImageWithText }): JSX.Element {
  return (
    <div className='ImageWithTextCell'>
      {imageWithText.imageUrl && (
        <img src={imageWithText.imageUrl} alt={imageWithText.imageAlt || imageWithText.text} />
      )}

      <span>{imageWithText.text}</span>
    </div>
  );
}

export default ImageWithTextCell;
