import { DELETE_ACCOUNT_ROUTE } from './AppRoutes';
import { Environment } from './Environment';

export const SITE_LEISTER_TECHNOLOGIES = 'LeisterTechnologies';
export const SITE_WELDY = 'Weldy';
const TENANTS_WITH_SHOP = [SITE_LEISTER_TECHNOLOGIES, SITE_WELDY];

export function getGlobalData() {
  return window.app || {};
}

export function getApiGatewayHost() {
  return Environment.isServer
    ? Environment.checkGatewayHost(Environment.reactAppProcessEnv.REACT_APP_API_GATEWAY_HOST)
    : getGlobalData().apiGatewayHost;
}

export function getProductApiHost() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_PRODUCT_API_HOST
    : getGlobalData().productApiHost;
}

export function getSitecoreGraphqlEndpoint() {
  let sitecoreApiHost, sitecoreJssAppName, sitecoreApiKey;
  if (Environment.isServer) {
    sitecoreApiHost = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST;
    sitecoreJssAppName = Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME;
    sitecoreApiKey = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY;
  } else {
    const globalData = getGlobalData();
    sitecoreApiHost = '';
    sitecoreJssAppName = globalData.sitecoreJssAppName;
    sitecoreApiKey = globalData.sitecoreApiKey;
  }

  return `${sitecoreApiHost}/api/${sitecoreJssAppName}?sc_apikey=${sitecoreApiKey}`;
}

export function getSitecoreDictionaryServiceRoute() {
  let sitecoreApiHost, sitecoreJssAppName, sitecoreApiKey;
  if (Environment.isServer) {
    sitecoreApiHost = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST;
    sitecoreJssAppName = Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME;
    sitecoreApiKey = Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY;
  } else {
    const globalData = getGlobalData();
    sitecoreApiHost = ''; // use proxy on current domain, to prevent CORS issues
    sitecoreJssAppName = globalData.sitecoreJssAppName;
    sitecoreApiKey = globalData.sitecoreApiKey;
  }
  return `${sitecoreApiHost}/sitecore/api/jss/dictionary/${sitecoreJssAppName}/{{lng}}?sc_apikey=${sitecoreApiKey}`;
}

export function getSitecoreApiHost() {
  return Environment.isServer ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_HOST : Environment.serverUrl;
}

export function getSitecoreApiKey() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_API_KEY
    : getGlobalData().sitecoreApiKey;
}

export function getSitecoreJssAppName() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_JSS_APP_NAME
    : getGlobalData().sitecoreJssAppName;
}

export function getSitecoreDefaultLanguage() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_SITECORE_DEFAULT_LANGUAGE
    : getGlobalData().sitecoreDefaultLanguage;
}

export function getCoveoSitecoreSource() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_COVEO_SITECORE_SOURCE
    : getGlobalData().coveoSitecoreSource;
}

export function getCoveoProductsSource() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_COVEO_PRODUCTS_SOURCE
    : getGlobalData().coveoProductsSource;
}

export function getCoveoOrganizationId() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_COVEO_ORGANIZATION_ID
    : getGlobalData().coveoOrganizationId;
}

export function getCoveoPlatformUrl() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_COVEO_PLATFORM_URL
    : getGlobalData().coveoPlatformUrl;
}

export function getDeleteEndpoint() {
  return getSitecoreApiHost() + DELETE_ACCOUNT_ROUTE;
}

const getShopifySetting = (country, siteName) => {
  const shopifySettingsValue = Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_SHOPIFY_PUBLIC
    : getGlobalData().shopify;

  const shopifySettings =
    typeof shopifySettingsValue === 'string' ? JSON.parse(shopifySettingsValue) : shopifySettingsValue;

  return shopifySettings?.[siteName]?.stores?.[country];
};

export function getShopifyDomain(country, siteName) {
  return getShopifySetting(country, siteName)?.domain;
}

export function getShopifyStorefrontAccessToken(country, siteName) {
  return getShopifySetting(country, siteName)?.storefrontAccessToken;
}

export function isShopifyProtected(country, siteName) {
  return getShopifySetting(country, siteName)?.protected;
}

export async function getShopifySettings(country, shopifyTestAccessKey, siteName) {
  if (isShopifyProtected(country, siteName)) {
    if (shopifyTestAccessKey) {
      return fetch('/protected/shopify')
        .then(response => response.json())
        .then(data => {
          return data;
        });
    }

    return {};
  }

  return {
    domain: getShopifyDomain(country, siteName),
    storefrontAccessToken: getShopifyStorefrontAccessToken(country, siteName)
  };
}

export async function renderShop(country, shopifyTestAccessKey, siteName) {
  if (!TENANTS_WITH_SHOP.includes(siteName)) return false;

  const { storefrontAccessToken } = await getShopifySettings(country, shopifyTestAccessKey, siteName);

  if (!storefrontAccessToken) {
    return false;
  }

  return true;
}

export function getBrandName(coveoSiteName) {
  return coveoSiteName === SITE_LEISTER_TECHNOLOGIES ? 'Leister' : 'Weldy';
}

export const getSiteNameFromContext = sitecoreContext => {
  return sitecoreContext?.site?.name || SITE_LEISTER_TECHNOLOGIES;
};

export function getCoveoGeneralDownloadsSource() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_COVEO_GENERAL_DOWNLOADS_SOURCE
    : getGlobalData().coveoGeneralDownloadsSource;
}

export function getShopifyAppLeisterDiscountBundlesId() {
  return Environment.isServer
    ? Environment.reactAppProcessEnv.REACT_APP_SHOPIFY_APP_LEISTER_DISCOUNT_BUNDLES_ID
    : getGlobalData().shopifyAppLeisterDiscountBundlesId;
}
