import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { setCoordinates, setMainContactPoint } from '../../../../store/salesandservicefilters/actions';
import { setStatusToContactChange } from '../../../../store/contactForms/actions';
import ContactPointInfo from '../ContactPointInfo/index';
import { addFavouriteContactIdOnRedux } from '../../../../store/favoriteContact/actions';
import { prepareContactPoint, checkServicesAndSolutionsFilter, getMainContactPoint } from './utils';
import { DispatchProps, SitecoreContextProps, StateProps } from './types';
import { getCookie } from '../../CookieSettings/CookieUtils';
import { COUNTRY_CODE } from '../../CookieSettings/CookieConstants';

type Props = StateProps & DispatchProps & SitecoreContextProps;

const SalesAndServicePointsList: React.FC<Props> = props => {
  const {
    storeFilters,
    allCoordinates,
    fields,
    favoriteContactId,
    setCoordinatesInfo,
    addFavouriteContactIdOnRedux,
    setStatusToContactChangeOnRedux,
    setMainContactPointOnRedux
  } = props;
  const { getFavoriteContactId, getItemListByTemplateId, restrictedContactQuery } = fields.data;

  useEffect(() => {
    const preparedContactPoints = prepareContactPoint(getItemListByTemplateId);
    setCoordinatesInfo(preparedContactPoints);
  }, [getItemListByTemplateId, setCoordinatesInfo]);

  // Handle the main contact point

  useEffect(() => {
    // We need to update it whenever the filters or coordinates change
    const mainContact = getMainContactPoint(allCoordinates, getCookie(COUNTRY_CODE), storeFilters, favoriteContactId);
    setMainContactPointOnRedux(mainContact);
  }, [storeFilters, allCoordinates, favoriteContactId, setMainContactPointOnRedux]);

  // Handle the favorite contact point
  useEffect(() => {
    const favoriteContactId = getFavoriteContactId?.id?.toLowerCase() ?? undefined;
    if (favoriteContactId) {
      addFavouriteContactIdOnRedux(favoriteContactId);
    }
  }, [getFavoriteContactId, addFavouriteContactIdOnRedux]);

  // Unmount hook
  useEffect(() => {
    return () => {
      // Component unmount
      setStatusToContactChangeOnRedux(false, null);
    };
  }, [setStatusToContactChangeOnRedux]);

  const renderContactPointsList = () => {
    const { coordinatesOnCurrentMapView, isLinkForQuote } = props;

    const contactPoints = coordinatesOnCurrentMapView
      .map(function (item, index) {
        const contactPointMatchesFilter = checkServicesAndSolutionsFilter(item, props.storeFilters);
        const isRestrictedContact = item.info?.isRestrictedContact?.value === '1';
        const noFiltersEnabled = storeFilters.length === 0;

        // Don't render the contact point if it's the default contact point or if it's a restricted contact
        if (
          (isLinkForQuote && restrictedContactQuery.isRestrictedUser && isRestrictedContact) ||
          props.mainContactPoint?.id === item.info.id
        ) {
          return null;
        }
        if (noFiltersEnabled || contactPointMatchesFilter) {
          return (
            <ContactPointInfo
              {...item}
              key={item.info.id ?? index}
              isRestrictedUser={restrictedContactQuery && restrictedContactQuery.isRestrictedUser}
            />
          );
        }
        return null;
      })
      .filter(item => item !== undefined && item !== null)
      .slice(0, 20);

    // Handle rendering for the default contact point
    if (props.mainContactPoint) {
      const checkRestrictedContact = props.mainContactPoint.info?.isRestrictedContact?.value === '1';
      let mainContactPointHtml = null;

      if (!(isLinkForQuote && restrictedContactQuery.isRestrictedUser && checkRestrictedContact)) {
        mainContactPointHtml = (
          <ContactPointInfo
            {...props.mainContactPoint}
            key={`mainContactPoint-${props.mainContactPoint.info.id}`}
            isRestrictedUser={restrictedContactQuery && restrictedContactQuery.isRestrictedUser}
          />
        );
        // Puts the default contact point at the top of the list
        contactPoints.unshift(mainContactPointHtml);
      }
    }

    return contactPoints;
  };

  return <div className='SalesAndServicePointsList'>{fields && renderContactPointsList()}</div>;
};

const mapStateToProps = (state: any): StateProps => {
  return {
    storeFilters: state.salesAndServiceFilter.filters,
    mainContactPoint: state.salesAndServiceFilter.mainContactPoint,
    coordinatesOnCurrentMapView: state.salesAndServiceFilter.currentCoordinates,
    allCoordinates: state.salesAndServiceFilter.coordinates,
    isLinkForQuote: state.productDetails.isLinkForQuote,
    favoriteContactId: state.favoriteContact.favoriteContactId
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    setCoordinatesInfo: items => dispatch(setCoordinates(items)),
    setMainContactPointOnRedux: item => dispatch(setMainContactPoint(item)),
    addFavouriteContactIdOnRedux: id => dispatch(addFavouriteContactIdOnRedux(id)),
    setStatusToContactChangeOnRedux: (isActive, relativePath) =>
      dispatch(setStatusToContactChange(isActive, relativePath))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(SalesAndServicePointsList));
