import React from 'react';
import { Row, Col, Visible } from 'react-grid-system';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { SitecoreIcon } from '../../Common/Icon/SitecoreIcon';
import GeneralLink from '../../Common/GeneralLink';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import classNames from 'classnames';

/**
 *  Component that renders an image, title, subtitle, a description and a link button and displays StoreLink (component) icons
 * @param  {object} props Object containing data needed for the component.
 */

class MyLeisterAppPromotion extends React.Component {
  /**
   *  @method getStoreItems
   * @description renders a link with an image from a multilist (Store icon links)
   */

  getStoreItems() {
    const { 'Store Items': items } = this.props.fields;
    if (items) {
      const storeItems = items.map(element => {
        const { SocialIcon, SocialLink } = element.fields;
        return (
          <div className='StoreLink' key={element.id}>
            <GeneralLink fields={SocialLink} className='StoreLink-Link'>
              <SitecoreIcon icon={SocialIcon} hoverIcon className='StoreLink-Icon' color='black-30' />
            </GeneralLink>
          </div>
        );
      });
      return storeItems;
    }
  }

  /**
   *  @method getImage
   * @description renders a div with an image according to data
   */

  getImage = () => {
    const { Image: Img, RightImagePosition } = this.props.fields,
      imageClass = classNames('MyLeisterAppPromotion-ImageWrapper', {
        ImageRight: RightImagePosition.value
      });
    return (
      <Col xl={6} lg={6} md={6} sm={12} className={imageClass}>
        <Image className='MyLeisterAppPromotion-Image' field={Img} />
      </Col>
    );
  };

  /**
   *  @method getDetails
   * @description renders a container with texts , a richtext and a button with a link
   */

  getDetails = () => {
    const { Title, Subtitle, Button, 'Button Text': buttonText, Description, BackgroundColor } = this.props.fields,
      detailsCLass = classNames('MyLeisterAppPromotion-Wrapper', {
        WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000'
      });
    return (
      <Col xl={6} lg={6} md={6} sm={12}>
        <div className={detailsCLass}>
          <div className='MyLeisterAppPromotion-StoreLink'>{this.getStoreItems()}</div>
          <Text field={Title} className='MyLeisterAppPromotion-Title' tag='div' />
          <Text field={Subtitle} className='MyLeisterAppPromotion-Subtitle' tag='div' />
          <RichText field={Description} className='MyLeisterAppPromotion-Description' tag='div' />
          <GeneralLink fields={Button} className='MyLeisterAppPromotion-Button'>
            <Text field={buttonText} />
          </GeneralLink>
        </div>
      </Col>
    );
  };

  /**
   *  @method getImageLeft
   * @description renders elements in a specific order
   */

  getImageLeft = () => {
    return (
      <React.Fragment>
        {this.getImage()}
        {this.getDetails()}
      </React.Fragment>
    );
  };

  /**
   *  @method getImageRight
   * @description renders elements in a specific order
   */

  getImageRight = () => {
    return (
      <React.Fragment>
        {this.getDetails()}
        {this.getImage()}
      </React.Fragment>
    );
  };

  /**
   *  @method getImageLeft
   * @description renders elements in a specific order according to data
   */

  getLeftOrRight = () => {
    const { RightImagePosition } = this.props.fields;
    return (
      <React.Fragment>
        <Visible xl lg md>
          {RightImagePosition.value ? this.getImageRight() : this.getImageLeft()}
        </Visible>
        <Visible sm xs>
          {this.getImageLeft()}
        </Visible>
      </React.Fragment>
    );
  };

  render() {
    if (!hasItems(this.props)) {
      return <div className='MyLeisterAppPromotion' />;
    }
    const { BackgroundColor } = this.props.fields,
      backgroundColorValue = BackgroundColor && BackgroundColor.fields.ColorCode.value !== '',
      inlineStyle = {
        backgroundColor: backgroundColorValue ? BackgroundColor.fields.ColorCode.value : null,
        backgroundImage: backgroundColorValue ? 'none' : null
      };
    return (
      <div className='grayBackgroundImage' style={inlineStyle}>
        <Row nogutter className='MyLeisterAppPromotion componentContainerMobile'>
          {this.getLeftOrRight()}
        </Row>
      </div>
    );
  }
}

const hasItems = function (props) {
  const { fields } = props;
  if (!fields) {
    return false;
  }
  return true;
};

export default WithStickyMenu(MyLeisterAppPromotion, hasItems);
