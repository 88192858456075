import React, { Fragment } from 'react';
import { Dialog as DialogComponent, Transition } from '@headlessui/react';
import i18n from 'i18next';
import CloseIcon from '../Icons/CloseIcon';

export function Dialog({
  isOpen,
  onClose,
  title,
  children
}: {
  isOpen: boolean;
  onClose: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}): JSX.Element | null {
  return (
    <Transition show={isOpen}>
      <DialogComponent onClose={onClose} className='Dialog'>
        <Transition.Child
          as={Fragment}
          enter='Dialog__backdrop--enter'
          enterFrom='Dialog__backdrop--enter-from'
          enterTo='Dialog__backdrop--enter-to'
          leave='Dialog__backdrop--leave'
          leaveFrom='Dialog__backdrop--leave-from'
          leaveTo='Dialog__backdrop--leave-to'
        >
          <div className='Dialog__backdrop' aria-hidden='true' />
        </Transition.Child>
        <div className='Dialog__screen'>
          <div className='Dialog__placement'>
            <Transition.Child
              as={Fragment}
              enter='Dialog--enter'
              enterFrom='Dialog--enter-from'
              enterTo='Dialog--enter-to'
              leave='Dialog--leave'
              leaveFrom='Dialog--leave-from'
              leaveTo='Dialog--leave-to'
            >
              <DialogComponent.Panel className='Dialog__wrapper'>
                <div className='Dialog__header'>
                  <div className='Dialog__title'>{title}</div>

                  <button aria-label={i18n.t('COMMON | Close slide over')} onClick={onClose} className='Dialog__close'>
                    <CloseIcon />
                  </button>
                </div>

                {children}
              </DialogComponent.Panel>
            </Transition.Child>
          </div>
        </div>
      </DialogComponent>
    </Transition>
  );
}

export default Dialog;
