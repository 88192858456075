import { getSitecoreApiHost } from '../AppGlobals';

export const MY_DEVICE_LIST_ENDPOINT = language => {
  return getSitecoreApiHost() + '/api/Devices/getAllDevices?lang=' + language;
};

export const ADD_DEVICE_ENDPOINT = () => {
  return getSitecoreApiHost() + '/api/Devices/addDevices';
};

export const DELETE_DEVICE_ENDPOINT = (articleNumber, serialNumber) => {
  return (
    getSitecoreApiHost() + '/api/Devices/deleteDevice?articleNumber=' + articleNumber + '&serialNumber=' + serialNumber
  );
};
export const GET_NUMBER_OF_DEVICES_ENDPOINT = () => {
  return getSitecoreApiHost() + '/api/Devices/getNoOfDevices';
};

export const CHECK_AND_GET_DEVICE_ENDPOINT = (articleNumber, serialNumber, inventoryNumber) => {
  return (
    getSitecoreApiHost() +
    '/api/Devices/checkDevice?articleNumber=' +
    articleNumber +
    '&serialNumber=' +
    serialNumber +
    '&inventoryNumber=' +
    inventoryNumber
  );
};

export const UPDATE_INVENTORY_NUMBER_ENDPOINT = (articleNumber, serialNumber, inventoryNumber) => {
  return (
    getSitecoreApiHost() +
    '/api/Devices/updateInventoryNo?articleNumber=' +
    articleNumber +
    '&serialNumber=' +
    serialNumber +
    '&inventoryNumber=' +
    inventoryNumber
  );
};
