import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink/index';
import { logOutRedirect, checkIfUserIsLoggedIn } from '../../../utils/Utils';

class MyAccountDesktopMenu extends React.Component {
  getSalutationName(fields) {
    if (fields && fields.data && fields.data.myAccountMenuQuery) {
      const { lastName } = fields.data.myAccountMenuQuery;

      return ' ' + lastName;
    }
  }

  render() {
    const { fields, sitecoreContext } = this.props;
    if (!(fields && fields.data && fields.data.item && checkIfUserIsLoggedIn(sitecoreContext))) {
      return <div className='MyAccountDesktopMenu-NoData' />;
    }
    const { hello, myAccount, myProfilePageLink, signOut } = fields.data.item;

    return (
      <div className='MyAccountDesktopMenu'>
        <div className='MyAccountDesktopMenu-Welcome'>
          <Text field={hello} />
          {this.getSalutationName(fields)}
        </div>
        <div className='MyAccountDesktopMenu-Separator'>|</div>
        <GeneralLink className='MyAccountDesktopMenu-Link' fields={myProfilePageLink.jss}>
          <Text field={myAccount} />
        </GeneralLink>
        <div className='MyAccountDesktopMenu-Separator'>|</div>
        <div
          className='MyAccountDesktopMenu-Logout'
          onClick={logOutRedirect}
          onKeyDown={e => {
            if (e.target === 13) logOutRedirect();
          }}
          role='button'
          tabIndex='0'
        >
          <Text field={signOut} />
        </div>
      </div>
    );
  }
}
export default withSitecoreContext()(MyAccountDesktopMenu);
