import { t } from 'i18next';

const createErrorMessageLanguagesMap = ({ field }) => {
  // add an error map, so it will be easy to add different error types in the future
  // besides the existing 'required'
  const errorMessagesMap = {
    required: ''
  };

  const errorMessageRequiredLang = t('{0} is required.'); // if the key contain a dot, must set keySeparator: false, in i18next config on initialization (src/i18n.js)

  if (errorMessageRequiredLang) {
    errorMessagesMap.required = errorMessageRequiredLang.replace(
      '{0}', // placeholder for the field name in sitecore
      field.model.title
    );
  }

  return errorMessagesMap;
};

export default createErrorMessageLanguagesMap;
