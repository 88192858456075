import { Facet, FacetValue } from '@coveo/headless';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import i18n from 'i18next';
import { AccessoriesTabs } from '../Lister/Products/AccessoriesLister/AccessoriesLister.settings';

type ImageFacetOption = {
  label: string;
  selected: boolean;
  numberOfResults: number;
  value: FacetValue;
  image?: {
    src: string;
    alt: string;
  } | null;
};

type ImageFacetProps = {
  controller: Facet;
  fields: any;
};

export const ImageFacetOptions = [
  {
    value: AccessoriesTabs.Nozzles,
    imageMapping: 'Nozzles Facet Image',
    labelKey: 'SEARCH | Accessories Facets Nozzles'
  },
  {
    value: AccessoriesTabs['Control Components'],
    imageMapping: 'Control Components Facet Image',
    labelKey: 'SEARCH | Accessories Facets Control Components'
  },
  {
    value: AccessoriesTabs['Testing Instruments'],
    imageMapping: 'Testing Equipment Facet Image',
    labelKey: 'SEARCH | Accessories Facets Testing Instruments'
  },
  {
    value: AccessoriesTabs['Grooving Devices'],
    imageMapping: 'Grooving Devices Facet Image',
    labelKey: 'SEARCH | Accessories Facets Grooving Devices'
  },
  {
    value: AccessoriesTabs.Consumables,
    imageMapping: 'Consumables Facet Image',
    labelKey: 'SEARCH | Accessories Facets Consumables'
  },
  {
    value: AccessoriesTabs['Welding Shoes'],
    imageMapping: 'Welding Shoes Facet Image',
    labelKey: 'SEARCH | Accessories Facets Welding Shoes'
  },
  {
    value: AccessoriesTabs['Spare Parts'],
    imageMapping: 'Spare Parts Facet Image',
    labelKey: 'SEARCH | Accessories Facets Spare Parts'
  },
  {
    value: AccessoriesTabs.Blowers,
    imageMapping: 'Blowers Facet Image',
    labelKey: 'SEARCH | Accessories Facets Blowers'
  },
  {
    value: AccessoriesTabs['General Accessories'],
    imageMapping: 'General Accessories Facet Image',
    labelKey: 'SEARCH | Accessories Facets General Accessories'
  },
  {
    value: AccessoriesTabs['Machine Specific Accessories'],
    imageMapping: 'Machine Specific Accessories Facet Image',
    labelKey: 'SEARCH | Accessories Facets Machine Specific Accessories'
  },
  {
    value: AccessoriesTabs['Laser Components'],
    imageMapping: 'Laser Components Facet Image',
    labelKey: 'SEARCH | Accessories Facets Laser Components'
  }
];

const prepareItem = (items: FacetValue[]) => {
  items.sort((a, b) => {
    const firstElement = ImageFacetOptions.find(facetMapping => facetMapping.value === a.value);
    const secondElement = ImageFacetOptions.find(facetMapping => facetMapping.value === b.value);

    if (!firstElement) {
      return -1;
    }

    if (!secondElement) {
      return 1;
    }

    return ImageFacetOptions.indexOf(firstElement) > ImageFacetOptions.indexOf(secondElement) ? 1 : -1;
  });

  return items.filter(item => ImageFacetOptions.some(imageFacet => imageFacet.value === item.value));
};

export const ImageFacet = ({ controller, fields }: ImageFacetProps) => {
  const [state, setState] = useState(controller.state);
  const [options, setOptions] = useState<ImageFacetOption[]>([]);

  useEffect(
    () =>
      controller.subscribe(() => {
        setState(controller.state);
      }),
    []
  );

  useEffect(() => {
    if (state.values.length) {
      const tempOptions = prepareItem([...controller.state.values]);

      setOptions(
        tempOptions.map(value => {
          const imageFacetOption = ImageFacetOptions.find(facetMapping => facetMapping.value === value.value);
          const imageProp = imageFacetOption ? fields[imageFacetOption?.imageMapping] : {};
          const imageSrc = imageProp?.value?.srcSet?.split('320w')?.[0];
          const imageAlt = imageProp?.value?.alt;

          return {
            value: value,
            image: imageSrc
              ? {
                  src: imageSrc,
                  alt: imageAlt
                }
              : null,
            label: i18n.t(imageFacetOption?.labelKey || ''),
            numberOfResults: value.numberOfResults,
            selected: controller.isValueSelected(value)
          };
        })
      );
    } else {
      setOptions([]);
    }
  }, [state]);

  return (
    <div className='ImageFacet__Container'>
      <ul className='ImageFacet'>
        {options.map(option => (
          <li
            key={option.label}
            className={classNames('ImageFacet__Item', {
              'ImageFacet__Item--Selected': option.selected
            })}
          >
            <label className='ImageFacet__Label'>
              <img className='ImageFacet__Image' src={option.image?.src} alt={option.image?.alt} />
              <input
                type='radio'
                aria-hidden='true'
                name={controller.state.facetId}
                checked={option.selected}
                onChange={() => {
                  controller.toggleSingleSelect(option.value);
                }}
              />
              <span className='ImageFacet__Caption'>{option.label}</span>
              <span className='ImageFacet__Results-Count'>
                {option.numberOfResults} {i18n.t('SEARCH | Tabs Accessories Result Number')}
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageFacet;
