import { getSitecoreApiHost } from '../AppGlobals';

export const SAVE_FAVOURITE_CONTACT = id => {
  return getSitecoreApiHost() + '/api/FavouriteContact/saveFavouriteContact?contactId=' + id;
};

export const DELETE_FAVOURITE_CONTACT = id => {
  return getSitecoreApiHost() + '/api/FavouriteContact/deleteFavouriteContact?contactId=' + id;
};

export const GET_FAVOURITE_CONTACT = () => {
  return getSitecoreApiHost() + '/api/FavouriteContact/getFavouriteContact';
};
