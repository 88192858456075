import React from 'react';
import CloseIcon from '../Icons/CloseIcon';
/**
 *  Component that renders a video in a lightbox
 */

const LightboxVideo = props => {
  return (
    <div className='LightboxVideo'>
      <div className='LightboxVideo-Details componentContainer'>
        <div className='LightboxVideo-Details-Name'>{props.title}</div>
        <div className='LightboxVideo-Details-Close'>
          <div
            className='LightboxVideo-Details-Close-Button'
            onClick={props.onClick}
            onKeyDown={e => {
              if (e.keyCode === 13) props.onClick();
            }}
            role='button'
            tabIndex='0'
          >
            <CloseIcon />
          </div>
        </div>
      </div>
      <div className='LightboxVideo-Video componentContainer'>
        <video width='auto' height='auto' controls autoPlay>
          <source src={props.src} type='video/mp4' />
          <track src='' kind='captions' lang='en' label='english_captions' />
          Your browser does not support HTML5 video.
        </video>
      </div>
    </div>
  );
};

export default LightboxVideo;
