import { RawContactPoint, ContactPoint } from './types';

export const prepareContactPoint = (items: RawContactPoint[]): ContactPoint[] => {
  return items.map(item => ({
    info: item,
    id: item.id,
    name: item.name,
    lat: item.latitude?.value,
    lon: item.longitude?.value,
    countryCode: item.country?.targetItem?.RegionCode?.value,
    kindOfPartnerId: item.kindOfPartner?.targetItem?.id?.value,
    servicesAndSolutions: getServicesAndSolutions(item)
  }));
};

export const getServicesAndSolutions = (contactPoint: RawContactPoint): string[] => {
  const services = contactPoint.services.targetItems.map(element => element.id.value);
  const solutions = contactPoint.solutionProvider.targetItems.map(element => element.field.value);
  const servicesAndSolutions = [...services, ...solutions];
  const lowercaseItems = servicesAndSolutions.map(item => item.toLowerCase());
  return lowercaseItems;
};

export const checkServicesAndSolutionsFilter = (contactPoint: ContactPoint, filters: string[]): boolean => {
  // No filters set, means we show all contact points
  if (filters.length === 0) return true;
  // Otherwise check for matching filters
  const indexOfMatchedItem = contactPoint.servicesAndSolutions.findIndex(serciceOrSolution => {
    return filters.includes(serciceOrSolution);
  });
  return indexOfMatchedItem !== -1;
};

const HQ_COUNTRY_CODE = 'CH';
const DEBUG = false;

export function getMainContactPoint(
  allCoordinates: ContactPoint[],
  country: string,
  serviceAndSolutionFilters: string[],
  favoriteContactId?: string | null
): ContactPoint | null {
  DEBUG && console.log('allCoordinates', allCoordinates);
  // 1. Rule, bail out if we have a favorite contact point
  if (favoriteContactId) {
    favoriteContactId = favoriteContactId.toUpperCase();
    const favoriteContact = allCoordinates.find(item => favoriteContactId === item.info.id);
    if (favoriteContact) {
      DEBUG && console.log('1st rule - Favorite contact point', favoriteContact);
      return favoriteContact;
    }
  }

  // Limit entries to current country
  const filteredContactsByCountry = allCoordinates.filter(item => item.countryCode === country);
  DEBUG && console.log('country filter', country, filteredContactsByCountry);

  // 2. Rule, check for KindOfPartner for LeisterSubsidiary
  const filteredContactsByLeisterSubsidiary = filteredContactsByCountry.filter(
    item => item.kindOfPartnerId === 'LeisterSubsidiary'
  );
  DEBUG && console.log('2nd rule - Country has Subsidiary', filteredContactsByLeisterSubsidiary);
  // Bail out with the first contact point of subsidiary match
  if (filteredContactsByLeisterSubsidiary[0]) {
    return filteredContactsByLeisterSubsidiary[0];
  }

  // 3. Rule, check for Official Distributor
  const kindOfPartnersForOfficialDistributor = [
    'OfficialSalesandIntegrationPartnerIrs',
    'OfficialSalesandIntegrationPartner',
    'OfficialSalesandIntegrationPartnerLgd',
    'DistributorHeadquarter'
  ];
  const filteredContactsByOfficialDistributor = filteredContactsByCountry.filter(
    contactPoint =>
      contactPoint.kindOfPartnerId && kindOfPartnersForOfficialDistributor.includes(contactPoint.kindOfPartnerId)
  );
  DEBUG && console.log('3rd rule - Country has Official Distributor', filteredContactsByOfficialDistributor);
  // Check if contact points services and solutions match the filters
  const filteredContactsByOfficialDistributorAndFilters = filteredContactsByOfficialDistributor.filter(contactPoint =>
    checkServicesAndSolutionsFilter(contactPoint, serviceAndSolutionFilters)
  );
  DEBUG && console.log('3rd rule - And matches filters', filteredContactsByOfficialDistributorAndFilters);
  if (filteredContactsByOfficialDistributorAndFilters[0]) {
    return filteredContactsByOfficialDistributorAndFilters[0];
  }

  // 4. Rule, check for Official Dealers
  const kindOfPartnersForOfficialDealers = ['AxetrisOfficialDealer', 'SubDealerHeadquarter'];
  const filteredContactsByOfficialDealers = filteredContactsByCountry.filter(
    contactPoint =>
      contactPoint.kindOfPartnerId && kindOfPartnersForOfficialDealers.includes(contactPoint.kindOfPartnerId)
  );
  DEBUG && console.log('4th rule - Country has Official Dealers', filteredContactsByOfficialDealers);
  // Check if contact points services and solutions match the filters
  const filteredContactsByOfficialDealersAndFilters = filteredContactsByOfficialDealers.filter(contactPoint =>
    checkServicesAndSolutionsFilter(contactPoint, serviceAndSolutionFilters)
  );
  DEBUG && console.log('4th rule - And matches filters', filteredContactsByOfficialDealersAndFilters);
  if (filteredContactsByOfficialDealersAndFilters[0]) {
    return filteredContactsByOfficialDealersAndFilters[0];
  }

  // 5. Rule, display the HQ of the site
  // Filter out all swiss contact points
  const contactPointsInSwitzerland = allCoordinates.filter(item => item.countryCode === HQ_COUNTRY_CODE);
  DEBUG && console.log('5th rule - Contact Points in switzerland', contactPointsInSwitzerland);
  const filteredContactsByHeadquarter = contactPointsInSwitzerland.filter(
    item => item.kindOfPartnerId === 'LeisterHeadquarter'
  );
  DEBUG && console.log('5th rule - Display HQ', filteredContactsByHeadquarter);
  if (filteredContactsByHeadquarter[0]) {
    return filteredContactsByHeadquarter[0];
  }

  // If nothing matches, set main contact point to null - however, this should never happen
  DEBUG && console.error('No main contact point found!');
  return null;
}
