import React, { useContext, useEffect } from 'react';
import i18n from 'i18next';
import Price from '../../Price/Price';
import { CartItem as CartItemType, CartItemAttribute } from '../../lib/shopify/types';
import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { updateItemQuantity } from '../../AddToCart/actions';
import { ActionType, CartContext } from '../../context/CartContext';
import InlineError from '../../../Common/InlineError';
import { createCartAttributes } from '../../lib/shopify/cartHelpers';
import CartItemActions from './CartItemActions';

export default function CartItem({ item, closeCart }: { item: CartItemType; closeCart: () => void }): JSX.Element {
  const [quantity, setQuantity] = React.useState(item.quantity);
  const { state, dispatch } = useContext(CartContext);
  const isDiscounted = !!item.merchandise.product.variants?.find(variant => variant.sku === item.merchandise.sku)
    ?.compareAtPrice?.amount;

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item, item.quantity]);

  // attributes array must always match - those attributes here must match those that were used when adding the item to the cart
  const payload: { lineId: string; variantId: string; attributes: CartItemAttribute[] } = {
    lineId: item.id,
    variantId: item.merchandise.id,
    attributes: []
  };

  // Add attributes to the payload
  payload.attributes = createCartAttributes({
    _leisterProductUrl: item._leisterProductUrl?.value,
    _leisterProductName: item._leisterProductName?.value,
    _leisterProductBrand: item._leisterProductBrand?.value,
    _leisterProductCategory: item._leisterProductCategory?.value,
    _leisterProductSubcategory: item._leisterProductSubcategory?.value,
    _leisterRelatedBundle: item._leisterRelatedBundle?.value
  });

  // Debounce the update cart function to prevent multiple requests
  // We also bring the button into a disabled state, because the requests otherwise will badly overload each other
  // However we only want to debounce and wait, if the quantity is higher than 0, as the 0 means deletion, which can happen immediately
  useDebounce(updateCart, quantity > 0 ? 600 : 0, [quantity]);

  async function updateCart() {
    if (quantity === item.quantity) return;

    dispatch({
      type: ActionType.CART_UPDATING
    });

    const updateItemQuantityResponse = await updateItemQuantity({
      ...payload,
      quantity,
      cart: state.cart
    });

    if (updateItemQuantityResponse.cart && typeof updateItemQuantityResponse.cart !== 'string') {
      dispatch({
        type: ActionType.SET_CART,
        payload: {
          cart: updateItemQuantityResponse.cart
        }
      });

      if (updateItemQuantityResponse.userErrors.length > 0) {
        dispatch({
          type: ActionType.ERROR_WHEN_ADDING_TO_CART,
          payload: {
            userErrors: updateItemQuantityResponse.userErrors.map(error => ({
              message: error.message,
              field: error.field,
              variantId: item.merchandise.id
            }))
          }
        });
      }
    }

    dispatch({
      type: ActionType.CART_UPDATED
    });
  }

  return (
    <div className='CartItem'>
      <Link
        to={item?._leisterProductUrl?.value || '/'}
        onClick={closeCart}
        className='CartItem__info'
        data-testid='cart-item-link'
      >
        <div className='CartItem__image'>
          {item.merchandise.product.featuredImage?.url && (
            <img
              width={56}
              height={56}
              data-testid='cart-item-image'
              alt={item.merchandise.product.featuredImage?.altText || item.merchandise.product.title}
              src={`${item.merchandise.product.featuredImage.url}&width=112&height=112`}
            />
          )}
        </div>
        <div className='CartItem__variant'>
          <div className='CartItem__product-name'>
            <span className='CartItem__product-title' data-testid='cart-item-title'>
              {item.merchandise.product.title}
            </span>
            <span className='CartItem__product-sku' data-testid='cart-item-sku'>
              {i18n.t('SHOP | Cart article number')}: {item.merchandise.sku}
            </span>
            {item._leisterRelatedBundle?.value && (
              <span className='CartItem__related-bundle' data-testid='cart-item-related-bundle'>
                {i18n.t('SHOP | Cart item bundle info', { bundleName: item._leisterRelatedBundle?.value })}
              </span>
            )}
          </div>
          <Price
            className='CartItem__price'
            type={isDiscounted ? 'sell' : ''}
            amount={item.cost.totalAmount.amount}
            currencyCode={item.cost.totalAmount.currencyCode}
          />
        </div>
      </Link>
      <div className='CartItem__actions'>
        <CartItemActions item={item} updateQuantity={setQuantity} />
      </div>
      {state.userErrors.length > 0 ? (
        <InlineError
          lineErrors={state.userErrors.filter(lineError => lineError.variantId === item.merchandise.id)}
          variantId={item.merchandise.id}
        />
      ) : null}
    </div>
  );
}
