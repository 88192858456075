import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import DeviceCard from '../../../MyAccountDeviceList/DeviceList/DeviceCard';

class RegisterDevicesList extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./RegisterDevicesList.scss');
  }
  getDevicesList = () => {
    const { newAddedDevices, fields } = this.props;

    return newAddedDevices.map((item, index) => {
      return <DeviceCard key={index + item.name} fields={fields} item={item} />;
    });
  };
  render() {
    const { registerDevicesTitle } = this.props.fields;
    return (
      <div className='RegisterDevicesList'>
        <Text className='RegisterDevicesList-Title' field={registerDevicesTitle} tag='div' />
        <div>{this.getDevicesList()}</div>
      </div>
    );
  }
}

export default RegisterDevicesList;
