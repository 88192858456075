import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GreenCircleCheckIcon from '../../../../Common/Icons/GreenCircleCheckIcon';
import ArrowDown from '../../../../Common/Icons/ArrowDown';

class CompareApplicationMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemsToShow: 5 };
  }

  componentDidUpdate(prevProps) {
    const { selectedCategory } = this.props;
    if (selectedCategory && prevProps.selectedCategory !== selectedCategory) {
      this.setState({ itemsToShow: 5 });
    }
  }

  //	// display button to show more application if there are more on mobile
  getMoreButton = () => {
    const remainingItems = this.props.data.length - this.state.itemsToShow;
    if (remainingItems > 0) {
      return (
        <div
          onClick={this.incrementDisplayedItems}
          onKeyDown={e => {
            if (e.target === 13) this.incrementDisplayedItems();
          }}
          role='button'
          tabIndex='0'
          className='CompareApplication-MoreButton'
        >
          <ArrowDown />
          <Text field={this.props.fields['Show More Label']} tag='span' />
        </div>
      );
    }
    return <div />;
  };

  // render check mark for existing application for each product in current category on mobile
  getCheckedApplication = () => {
    const { data } = this.props;
    if (data && data.length > 0) {
      return data.slice(0, this.state.itemsToShow).map((element, index) => {
        return (
          <div className='CompareApplication-Column-Value' key={index + element.name}>
            <GreenCircleCheckIcon />
            <span className='CompareApplication-Column-Value-Name'>{element.name}</span>
          </div>
        );
      });
    }
  };

  incrementDisplayedItems = () => {
    const numberToIncrement = 5;
    this.setState(state => ({
      itemsToShow: state.itemsToShow + numberToIncrement
    }));
  };

  render() {
    return (
      <div className='CompareApplication-Column'>
        <div className='CompareApplication-Column-Title'>{this.props.productName}</div>

        {this.getCheckedApplication()}
        {this.getMoreButton()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCategory: state.compareProduct.selectedCategory
  };
};

export default connect(mapStateToProps)(CompareApplicationMobile);
