import React from 'react';
import Price from '../../Price/Price';
import { ShopProductVariantWithProduct as BundleItemType } from '../../lib/shopify/types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PlusIcon from '../../../Common/Icons/PlusIcon';
import { ProductItemTechnicalDetails } from '../../../Product/types';
import { getProductItemDetailPageUrl } from '../../lib/shopify/leisterHelpers';
import { LANG } from '../../../Common/CookieSettings/CookieConstants';
import { getCookie } from '../../../Common/CookieSettings/CookieUtils';

export default function BundleItem({
  item,
  type,
  technicalDetail
}: {
  item: BundleItemType;
  type: 'main' | 'additional';
  technicalDetail?: ProductItemTechnicalDetails;
}): JSX.Element {
  const productItemDetailPageUrl = getProductItemDetailPageUrl(
    technicalDetail?.productInformation.assetNameNormalized ?? '',
    item.sku,
    getCookie(LANG)
  );
  const BundleItemContentRender = (
    <>
      <div className='BundleItem__image'>
        <img
          width={40}
          height={40}
          data-testid='bundle-item-image'
          alt={item.product.featuredImage?.altText || item.product.title}
          src={`${item.product.featuredImage?.url}&width=112&height=112`}
        />
      </div>

      <div
        className='BundleItem__variant'
        data-testid={type === 'main' ? 'bundle-main-item' : 'bundle-additional-item'}
      >
        <div className='BundleItem__product-name'>
          <span className='BundleItem__product-title' data-testid='bundle-item-title'>
            {technicalDetail?.productInformation.title}
          </span>
          <span className='BundleItem__product-sku' data-testid='bundle-item-sku'>
            {type === 'main' ? item.sku : technicalDetail?.subTitle}
          </span>
        </div>
        <Price className='BundleItem__price' amount={item.price.amount} currencyCode={item.price.currencyCode} />
      </div>

      {type === 'main' && (
        <div className='BundleItem__main-plus-icon'>
          <PlusIcon color='gray' />
        </div>
      )}
    </>
  );

  return type === 'main' ? (
    <div className='BundleItem'>{BundleItemContentRender}</div>
  ) : productItemDetailPageUrl ? (
    // Until we have URLs for additional items, we will not render the link
    <Link
      to={productItemDetailPageUrl}
      className={classNames('BundleItem', { 'BundleItem--additional': type === 'additional' })}
      data-testid='bundle-item-link'
    >
      {BundleItemContentRender}
    </Link>
  ) : (
    <span
      className={classNames('BundleItem', { 'BundleItem--additional': type === 'additional' })}
      data-testid='bundle-item-link'
    >
      {BundleItemContentRender}
    </span>
  );
}
