import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import { Col } from 'react-grid-system';

/**
 * @class Component that renders a list of items with link and image
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class ColumnCategory extends React.Component {
  render() {
    if (!this.props.fields) {
      return <div className='ColumnCategory' />;
    }

    return (
      <Col sm={12} md={4} xl={4} className='ColumnCategory'>
        <Text field={this.props.fields.Title} className='ColumnCategory-Title' tag='div' />
        <Placeholder name='jss-three-column-category-item' rendering={this.props.rendering} />
      </Col>
    );
  }
}

export default ColumnCategory;
