import React from 'react';
import PageLinkerAutomaticallyComponent from '../index';
import { LTAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class PageLinkerAutomatically extends React.Component {
  render() {
    return <PageLinkerAutomaticallyComponent {...this.props} urlToUse={LTAG_PAGE_LINK} />;
  }
}

export default PageLinkerAutomatically;
