import React from 'react';
import GeneralLink from '../../../../GeneralLink';
import ArrowRight from '../../../../Icons/ArrowRight';
import { EventLinkOrganizerProps } from '../types';
import { getLinkLabel } from './LinkUtils';

export const EventLinkOrganizer = ({ eventLinkOrganizer }: EventLinkOrganizerProps) => {
  const label = getLinkLabel(eventLinkOrganizer);

  return label ? (
    <div className='EventInformationSection-LinkRow' data-testid='event-organizer-link'>
      {/*
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
      <GeneralLink className='LinkElement-Link' fields={eventLinkOrganizer}>
        {label}
        <ArrowRight />
      </GeneralLink>
    </div>
  ) : null;
};
