import React from 'react';
import WeldyHeatGunsListerComponent from './WeldyHeatGunsLister';
import CoveoComponent from '../../../CoveoComponent';

export const WeldyHeatGunsLister: React.FC = () => {
  const searchEngineProps = {
    pipeline: 'SearchProduct',
    searchHub: 'Heat-Guns'
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WeldyHeatGunsListerComponent />
    </CoveoComponent>
  );
};

export default WeldyHeatGunsLister;
