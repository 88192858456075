import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';
import MapPinIcon from '../../../Common/Icons/MapPin';
import TimeIcon from '../../../Common/Icons/TimeIcon';
import Home2Icon from '../../../Common/Icons/Home2Icon';

export type CareerResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const CareerResultItem = ({ result, engine, siteName }: CareerResultItemProps) => {
  const { raw } = result;
  const location = raw.location;
  const country = (raw.country as string[])?.join(', ');
  const title = raw.careerz32xpagez32xtitle;
  const workload = raw.workz32xload;
  const linkClass = raw.englishz32xtitle ? (raw.englishz32xtitle as string).replace(/ /g, '') : '';
  const resultLink = getResultUrl(raw, siteName);
  const company = raw.company;

  return (
    <div className='CareerResultItem'>
      <ResultLink
        className={`CareerResultItem__Link ${linkClass}`}
        href={resultLink}
        data-testid='career-result-link'
        origin='lister'
        engine={engine}
        result={result}
      >
        <span className='CareerResultItem__Link-Content'>
          <h3 className='CareerResultItem__Link-Content-Title' data-testid='career-result-title'>
            {title}
          </h3>
          <span className='CareerResultItem__Link-Content-Row'>
            <span className='CareerResultItem__Link-Content-Details' data-testid='career-result-location'>
              <MapPinIcon />
              {location}, {country}
            </span>
            <span className='CareerResultItem__Link-Content-Details' data-testid='career-result-company'>
              <Home2Icon />
              {company}
            </span>
            <span className='CareerResultItem__Link-Content-Details' data-testid='career-result-workload'>
              <TimeIcon />
              {workload}
            </span>
            <span className='CareerResultItem__Link-Content-DetailsLabel' data-testid='career-result-open-details'>
              {i18n.t('SEARCH | ResultItem Career Details label')}
              <ArrowRight />
            </span>
          </span>
        </span>
      </ResultLink>
    </div>
  );
};

export default CareerResultItem;
