import React from 'react';
import { Placeholder, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import './GatedDownload.scss';

const GatedDownload = props => {
  const { fields, rendering } = props;

  if (!fields || !rendering) {
    return <div>No datasource item defined</div>;
  }

  const { 'Gated Download Title': gatedDownloadTitle, 'Gated Download Content': gatedDownloadContent } = fields;

  const renderGatedDownloadTitle = gatedDownloadTitle && (
    <div className='GatedDownloadCard-Title'>
      <Text field={gatedDownloadTitle} />
    </div>
  );
  const renderGatedDownloadContent = gatedDownloadContent && (
    <div className='GatedDownloadCard-Article'>
      <RichText field={gatedDownloadContent} />
    </div>
  );
  const renderPlaceholderJSSGatedDownloadForm = rendering && (
    <Placeholder name='jss-gated-download-form' rendering={rendering} />
  );

  return (
    <Row nogutter className='GatedDownloadContainer componentContainer'>
      <Col xs={12} sm={12} md={12} lg={5} xl={5}>
        <div className='GatedDownloadCard'>
          {renderGatedDownloadTitle}
          {renderGatedDownloadContent}
        </div>
      </Col>
      <Col xs={0} sm={0} md={0} lg={1} xl={1} />
      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
        {renderPlaceholderJSSGatedDownloadForm}
      </Col>
    </Row>
  );
};

export default withSitecoreContext()(GatedDownload);
