import { SearchEngine, buildSearchEngine } from '@coveo/headless';
import { getCoveoOrganizationId, getCoveoPlatformUrl } from '../../AppGlobals';
import { useEffect, useState } from 'react';
import { useCookieConsent } from '../Common/CookieSettings/useCookieConsent';

export type SearchEngineProps = {
  searchToken?: string;
  coveoSiteName?: string;
  locale?: string;
  pipeline?: string;
  searchHub?: string;
  groupBy?: (body: any) => {
    field: string;
    maximumNumberOfValues: number;
    sortCriteria: string;
    injectionDepth: number;
    completeFacetWithStandardValues: boolean;
    queryOverride?: string;
    constantQueryOverride?: string;
  }[];
  responseMiddleware?: (response: any) => any;
};

export const useBuildEngine = ({
  searchToken,
  locale,
  pipeline,
  searchHub,
  groupBy,
  responseMiddleware
}: SearchEngineProps) => {
  const [engine, setEngine] = useState<SearchEngine | null>(null);

  const { isCookieConsentGiven } = useCookieConsent();

  useEffect(() => {
    try {
      if (searchToken) {
        const engine = buildSearchEngine({
          configuration: {
            analytics: {
              enabled: isCookieConsentGiven
            },
            organizationId: getCoveoOrganizationId() as string,
            accessToken: searchToken,
            platformUrl: getCoveoPlatformUrl(),
            search: {
              locale,
              pipeline,
              searchHub,
              preprocessSearchResponseMiddleware: (response: any) => {
                responseMiddleware?.(response);

                return response;
              }
            },
            preprocessRequest: (request, clientOrigin) => {
              if (clientOrigin === 'searchApiFetch') {
                const body = JSON.parse(request.body as string);

                body.groupBy = groupBy?.(body);

                request.body = JSON.stringify(body);
              }

              return request;
            }
          }
        });

        setEngine(engine);
      } else {
        console.error('Error when initialising Coveo engine: No search token provided');
      }
    } catch (e) {
      console.error('Error when initialising Coveo engine', e);
    }

    return () => undefined;
  }, [pipeline, searchHub]);

  return engine;
};
