import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';

export type SolutionResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const SolutionResultItem = ({ result, siteName, engine }: SolutionResultItemProps) => {
  const { raw } = result;
  const image = raw.image as string;
  const category = raw.category;
  const title = raw.solutionpageherotitle;
  const description = raw.tez120xt as string;
  const resultLink = getResultUrl(raw, siteName);

  return (
    <div className='SolutionResultItem'>
      <div dangerouslySetInnerHTML={{ __html: image }}></div>
      <div className='SolutionResultItem__Wrapper'>
        <div className='SolutionResultItem__Wrapper-FoundIn'>
          {i18n.t('SEARCH | ResultItem Found In label')} {category}
        </div>
        <span className='SolutionResultItem__Wrapper-Title'>{title}</span>
        <RichText
          className='SolutionResultItem__Wrapper-Description'
          field={{
            value: description
          }}
        />
        <ResultLink
          className='ResultLink--important ResultLink--larger'
          text={i18n.t('SEARCH | ResultItem Explore more label')}
          icon={<ArrowRight />}
          href={resultLink}
          engine={engine}
          result={result}
        />
      </div>
    </div>
  );
};

export default SolutionResultItem;
