import React from 'react';
import Spinner from '../../Spinner';
import classNames from 'classnames';
import Icon from '../../Icon';
import RoutableSitecoreLink from '../../../../utils/RoutableSitecoreLink';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { ButtonProps } from '../types';

type ButtonLinkProps = {
  link?: LinkField;
  className?: string;
  text?: string;
  tooltip?: string;
  icon?: ButtonProps['icon'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  isLoading?: boolean;
  disabled?: boolean;
};

const ButtonLink = ({
  link,
  className,
  icon,
  text,
  tooltip,
  variant = 'primary',
  size = 'medium',
  isLoading = false,
  disabled = false
}: ButtonLinkProps): JSX.Element => {
  const linkClassNames = classNames(
    'button',
    {
      'button--primary': variant === 'primary',
      'button--secondary': variant === 'secondary',
      'button--small': size === 'small',
      'button--medium': size === 'medium',
      'button--large': size === 'large',
      'button--is-loading': isLoading,
      'button--disabled': disabled
    },
    className
  );

  return disabled || isLoading ? (
    <div className={linkClassNames}>
      {icon && <Icon name={icon} size={16} className='button__icon' color={variant === 'primary' ? 'white' : 'red'} />}
      {tooltip && <span className='button__tooltip'>{tooltip}</span>}
      {text && <span>{text}</span>}
      {isLoading && (
        <div className='button__loading-spinner'>
          <Spinner />
        </div>
      )}
    </div>
  ) : (
    <RoutableSitecoreLink link={link} className={linkClassNames}>
      {icon && <Icon name={icon} size={16} className='button__icon' color={variant === 'primary' ? 'white' : 'red'} />}
      {tooltip && <span className='button__tooltip'>{tooltip}</span>}
      {text && <span>{text}</span>}
    </RoutableSitecoreLink>
  );
};

export default ButtonLink;
