import classNames from 'classnames';
import React from 'react';
import { CellTextAlign, CellTextVariant } from '../..';

export function TextCell({
  text,
  textBreak = true,
  textAlign,
  textVariant = 'regular'
}: {
  text: string;
  textBreak?: boolean;
  textAlign?: CellTextAlign;
  textVariant?: CellTextVariant;
}): JSX.Element {
  return (
    <div
      className={classNames('TextCell', {
        'TextCell--no-break': textBreak === false,
        [`TextCell--${textAlign}`]: textAlign,
        'TextCell--primary': textVariant === 'primary'
      })}
    >
      {text}
    </div>
  );
}

export default TextCell;
