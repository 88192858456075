import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { FooterGeneralLinkProps } from './types';
import GeneralLink from '../../Common/GeneralLink';

/**
 * Component that renders a link a text for link description
 */
const FooterGeneralLink = ({ fields }: FooterGeneralLinkProps) => {
  if (!fields) {
    return <div className='FooterGeneralLink' />;
  }

  const { Link, 'Text Link': textLink } = fields;

  return (
    <div className='FooterGeneralLink'>
      {/*
				// @ts-ignore TODO: TS JSX error, strange one, revisit after migrating all to TS, or upgrading React */}
      <GeneralLink fields={Link}>
        <Text field={textLink} />
      </GeneralLink>
    </div>
  );
};

export default FooterGeneralLink;
