import React from 'react';
import CompareTechnicalSpecsNavigationHeader from './CompareTechnicalSpecsNavigationHeader';

class CompareTechnicalSpecsMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { specsName: null, currentItemIndex: 0 };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data) && this.state.currentItemIndex !== 0) {
      this.setState({
        currentItemIndex: 0,
        specsName: null
      });
    }
  }

  getUniqueSpecifications = () => {
    const { data } = this.props;

    if (data && data.length > 0) {
      const specs = data.map(item => item.technicalSpecs[this.props.system].map(elem => elem.name));
      return [...new Set([].concat(...specs))];
    }

    return [];
  };

  getValueForSpec = item => {
    const metric = item.technicalSpecs[this.props.system],
      { currentItemIndex, specsName } = this.state,
      uniqueSpecifications = this.getUniqueSpecifications();
    if (specsName) {
      if (uniqueSpecifications[currentItemIndex] === specsName) {
        for (let i = 0; i < metric.length; i++) {
          if (metric[i].name === specsName) {
            return metric[i].value;
          }
        }
      } else {
        const asd = metric.find(element => element.name === uniqueSpecifications[currentItemIndex]);
        return asd && asd.value;
      }
    }
  };
  getDefaultSpecsName = () => {
    const { specsName, currentItemIndex } = this.state,
      uniqueSpecifications = this.getUniqueSpecifications();
    if (!specsName) {
      const defaultSpecs = uniqueSpecifications[currentItemIndex];
      this.setState({ specsName: defaultSpecs });
    }
  };

  getProducts = () => {
    const { data } = this.props;

    return data.map((item, index) => {
      return (
        <div className='CompareTechnicalSpecsMobile-Products-Item' key={index + item.masterProductName}>
          <div className='CompareTechnicalSpecsMobile-Products-Item-Title'>{item.masterProductName}</div>
          <div className='CompareTechnicalSpecsMobile-Products-Item-Value'>
            {this.getDefaultSpecsName()}
            {this.getValueForSpec(item)}
          </div>
        </div>
      );
    });
  };

  onSpecChanged = (specsName, currentItemIndex) => {
    this.setState({
      specsName,
      currentItemIndex
    });
  };

  render() {
    return (
      <div className='CompareTechnicalSpecsMobile-Column'>
        <CompareTechnicalSpecsNavigationHeader
          items={this.getUniqueSpecifications()}
          onSpecChanged={this.onSpecChanged}
          currentItemIndex={this.state.currentItemIndex}
        />
        <div className='CompareTechnicalSpecsMobile-Products'>{this.getProducts()}</div>
      </div>
    );
  }
}

export default CompareTechnicalSpecsMobile;
