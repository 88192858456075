import React from 'react';
import GeneralLink from '../../../../GeneralLink';
import ArrowRight from '../../../../Icons/ArrowRight';
import { EventLinkDirectionsProps } from '../types';
import MapPinIcon from '../../../../Icons/MapPin';
import i18n from 'i18next';

export const EventLinkDirections = ({ eventLinkDirections }: EventLinkDirectionsProps): JSX.Element | null => {
  return eventLinkDirections.value.href ? (
    <div className='EventLinkDirections' data-testid='event-directions-link'>
      {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
      <GeneralLink className='LinkElement-Link' fields={eventLinkDirections}>
        <MapPinIcon />
        {i18n.t('EVENT_DETAIL | Directions')}
        <ArrowRight />
      </GeneralLink>
    </div>
  ) : null;
};
