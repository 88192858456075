import React, { ComponentType, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchBox } from '@coveo/headless';
import SearchIcon from '../../Common/Icons/SearchIcon';
import i18n from 'i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CoveoMagnifier from '../../Common/Icons/CoveoMagnifier';
import CloseIcon from '../../Common/Icons/CloseIcon';

type SimpleSearchOmniboxComponentProps = {
  history: RouteComponentProps['history'];
  searchBoxController: SearchBox;
  search: (query: string) => void;
  placeholderText?: string;
};

export const SimpleSearchOmniboxComponent: React.FC<SimpleSearchOmniboxComponentProps> = ({
  searchBoxController,
  search,
  placeholderText
}) => {
  const [query, setQuery] = useState('');
  const [, cancel] = useDebounce(
    () => {
      if (searchBoxController.state.value !== query) {
        searchBoxController.updateText(query);
      }
    },
    250,
    [query]
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchBoxController.subscribe(() => {
      setQuery(searchBoxController.state.value);
    });
  }, [searchBoxController]);

  // handle clear input
  const clearInput = () => {
    setQuery('');
    cancel();
    search('');
  };

  const clearInputAndFocus = () => {
    clearInput();

    inputRef?.current?.focus();
  };

  // handle input change - ony key down
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const submitSearch = () => {
    search(query);
  };

  return (
    <div className='PageOmnibox__background-wrapper'>
      <div className='PageOmnibox PageOmnibox--downloads'>
        <div className='PageOmnibox__wrapper'>
          <div className='PageOmnibox__searchbox'>
            <label className='PageOmnibox__label visually-hidden' htmlFor='coveo-searchbox'>
              {i18n.t('SEARCH | Searchbox label')}
            </label>
            <div className='PageOmnibox__input-wrapper'>
              <input
                className='PageOmnibox__input'
                ref={inputRef}
                value={query}
                type='text'
                id='coveo-searchbox'
                autoComplete='off'
                title={i18n.t('SEARCH | Searchbox title')}
                placeholder={placeholderText || i18n.t('SEARCH | Simple Searchbox placeholder')}
                onChange={handleInputChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    submitSearch();
                  }
                }}
              />
              {query ? (
                <button
                  className='PageOmnibox__clear'
                  onClick={clearInputAndFocus}
                  aria-label={i18n.t('SEARCH | Searchbox clear button')}
                >
                  <CloseIcon />
                </button>
              ) : (
                <SearchIcon className='PageOmnibox__search-icon' />
              )}
            </div>
          </div>
          <button
            className='button button--square max-mobile PageOmnibox__submit-search-button'
            onClick={submitSearch}
            aria-label={i18n.t('SEARCH | Searchbox search button')}
          >
            <CoveoMagnifier className='Searchbox__search-button-magnifier' />
          </button>
          <button className='button button--large min-tablet reset-margin' onClick={submitSearch}>
            {i18n.t('SEARCH | Searchbox search button')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter<
  RouteComponentProps & SimpleSearchOmniboxComponentProps,
  ComponentType<RouteComponentProps & SimpleSearchOmniboxComponentProps>
>(SimpleSearchOmniboxComponent);
