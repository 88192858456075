import React, { useContext } from 'react';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { CoveoEventsPipelines, CoveoEventsSearchHubs } from '../../CoveoSettings';
import EventsListerComponent from './EventsLister';
import CoveoComponent from '../../CoveoComponent';

export const EventsLister = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoEventsPipelines[siteName],
    searchHub: CoveoEventsSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <EventsListerComponent />
    </CoveoComponent>
  );
};

export default EventsLister;
