import React, { useContext } from 'react';
import PlusIcon from '../../../Common/Icons/PlusIcon';
import MinusIcon from '../../../Common/Icons/MinusIcon';
import classNames from 'classnames';
import { CartContext } from '../../context/CartContext';

export type ChangeQuantityButtonType = 'plus' | 'minus';

export default function SubmitButton({
  clicked,
  type
}: {
  clicked: () => void;
  type: ChangeQuantityButtonType;
}): JSX.Element {
  const { state } = useContext(CartContext);
  return (
    <button
      onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        clicked();
      }}
      type='button'
      disabled={state.isUpdating}
      className={classNames('ChangeQuantityButton', {
        'ChangeQuantityButton--minus': type === 'minus'
      })}
      data-testid={`change-quantity-${type}`}
    >
      {type === 'plus' && <PlusIcon />}
      {type === 'minus' && <MinusIcon />}
    </button>
  );
}
