import React from 'react';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import PlusIcon from '../../../Common/Icons/PlusIcon';

class AddWishListButton extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./AddWishListButton.scss');
  }

  render() {
    const { hasBackground, onButtonClick, fields } = this.props,
      { 'New List Label': newListLabel } = fields,
      cssClass = classNames('AddWishListButton', {
        'AddWishListButton-No-Background': !hasBackground,
        'AddWishListButton-With-Background': hasBackground
      });

    return (
      <div
        className={cssClass}
        onClick={() => {
          onButtonClick();
        }}
        onKeyDown={e => {
          if (e.target === 13) onButtonClick();
        }}
        role='button'
        tabIndex='0'
      >
        <div className='AddWishListButton-Details'>
          <PlusIcon /> <Text field={newListLabel} />
        </div>
      </div>
    );
  }
}

export default AddWishListButton;
