import React from 'react';
import { getFormattedEventDate } from '../../../Common/Content/Events/EventInformation/parts/EventDate';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import CalendarIcon from '../../../Common/Icons/Calendar';
import MapPinIcon from '../../../Common/Icons/MapPin';
import MedicalIcon from '../../../Common/Icons/Medical';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';

export type EventResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const EventResultItem = ({ result, engine, siteName }: EventResultItemProps) => {
  const { raw } = result;
  const title = raw.eventz32xtitle;
  const venue = raw.eventz32xvenue;
  const city = raw.eventz32xcity;
  const country = raw.eventz32xcountry;
  const linkClass = raw.eventz32xtitle ? (raw.eventz32xtitle as string).replace(/ /g, '') : '';
  const eventDetailsLinkText = raw.eventz32xdetailsz32xlinkz32xtez120xt;
  const industries = (raw.eventz32xindustries as string[])?.join(', ');
  const resultLink = getResultUrl(raw, siteName);

  return (
    <div className='EventResultItem'>
      <ResultLink
        className={`EventResultItem__Link ${linkClass}`}
        href={resultLink}
        origin='lister'
        engine={engine}
        result={result}
      >
        <span className='EventResultItem__Link-Content'>
          <h3 className='EventResultItem__Link-Content-Title'>{title}</h3>
          <span className='EventResultItem__Link-Content-Row'>
            {city || country ? (
              <span className='EventResultItem__Link-Content-Details'>
                <MapPinIcon />
                <div className='wrap-context-wrapper'>
                  {venue && (
                    <span className='wrap-context'>
                      {venue}
                      {(city || country) && <span>,&nbsp;</span>}
                    </span>
                  )}
                  <span className='wrap-context'>
                    {city}
                    {city && country && ' / '}
                    {country}
                  </span>
                </div>
              </span>
            ) : null}
            <span className='EventResultItem__Link-Content-Details'>
              <CalendarIcon />
              {getFormattedEventDate(
                new Date(raw.eventz32xstartz32xdate as string),
                new Date(raw.eventz32xendz32xdate as string),
                raw.z95xlanguage as string
              )}
            </span>
            <span className='EventResultItem__Link-Content-Details'>
              <MedicalIcon />
              {industries}
            </span>
            <span className='EventResultItem__Link-Content-DetailsLabel'>
              {eventDetailsLinkText}
              <ArrowRight />
            </span>
          </span>
        </span>
      </ResultLink>
    </div>
  );
};

export default EventResultItem;
