import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import ArrowLeft from '../../../Icons/ArrowLeft';
import GeneralLink from '../../../GeneralLink';
import { withRouter } from 'react-router-dom';

const BackToOverview = props => {
  const { fields, rendering } = props;

  if (!fields || !rendering) {
    return <div>No datasource item defined</div>;
  }

  const { 'Back to Link': backToLink, 'Back to Link Text': backToLinkText } = fields;

  let backLink = backToLink?.value?.href;

  if (props.location?.state?.origin === 'lister') {
    backLink = props.location.state.from;
  }

  return (
    <div className='componentContainer'>
      <Row nogutter className='BackToOverviewSection'>
        <Col offset={{ lg: 2 }} sm={12} md={12} xl={7} lg={7}>
          <GeneralLink className='LinkElement-Link' fields={{ value: { href: backLink } }}>
            <ArrowLeft />
            <Text field={backToLinkText} />
          </GeneralLink>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(BackToOverview);
