import React from 'react';
import SolutionCardsGalleryComponent from '../index';
import { LTAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class SolutionCardsGallery extends React.Component {
  render() {
    return <SolutionCardsGalleryComponent {...this.props} urlToUse={LTAG_PAGE_LINK} />;
  }
}

export default SolutionCardsGallery;
