import React from 'react';
import { Visible } from 'react-grid-system';
import MobileGallery from './MobileGallery/index';
import DesktopGallery from './DesktopGallery/index';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../HeadlineWithTitleAndSubtitle/index';
import classNames from 'classnames';

/**
 * @method SolutionCardsGalleryComponent
 *
 * Display Solutions Cards with image, title, overline, description and a call to action link
 *
 * Returns jsx
 */
class SolutionCardsGalleryComponent extends React.PureComponent {
  render() {
    const propsFields = this.props.fields;
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }

    const {
        Title,
        Subtitle,
        'Explore More Label': exploreMoreLabel,
        'Background Color': BackgroundColor
      } = propsFields,
      inlineStyle = {
        background:
          BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      },
      grayImage = classNames({
        grayBackgroundImage: inlineStyle.background === null
      });
    return (
      <div className={grayImage} style={inlineStyle}>
        <div className='SolutionCardsGallery carouselComponentContainer click_content_block_solutions'>
          <HeadlineWithTitleAndSubtitle
            extraClasses='SolutionCardsGallery-Headline'
            title={Title}
            subtitle={Subtitle}
          />
          <Visible lg xl>
            <DesktopGallery fields={propsFields} exploreMoreLabel={exploreMoreLabel} urlToUse={this.props.urlToUse} />
          </Visible>
          <Visible xs sm md>
            <MobileGallery fields={propsFields} exploreMoreLabel={exploreMoreLabel} urlToUse={this.props.urlToUse} />
          </Visible>
        </div>
      </div>
    );
  }
}
const hasItems = function (props) {
  const { fields } = props;
  if (!fields || !fields['Solution Heroes'] || !fields['Solution Heroes'].length) {
    return false;
  }
  return true;
};

export default WithStickyMenu(SolutionCardsGalleryComponent, hasItems);
