export const FloatLabel = (() => {
  // add active class
  const handleFocus = e => {
    const target = e.target;

    makeLabelActive(target.getAttribute('id'));
  };

  // remove active class
  const handleBlur = e => {
    const target = e.target;
    if (!target.value) {
      removeLabelActive(target.getAttribute('id'));
    }
  };

  // register events
  const bindEvents = element => {
    element.addEventListener('focus', handleFocus);
    element.addEventListener('blur', handleBlur);
  };

  const makeLabelActive = id => {
    const labels = document.querySelectorAll('.SitecoreForm label');

    [...labels].forEach(element => {
      if (element.getAttribute('for') === id && !element.classList.contains('Form-FileUploadLabel')) {
        element.classList.add('active');
      }
    });
  };

  const removeLabelActive = id => {
    const labels = document.querySelectorAll('.SitecoreForm label');

    [...labels].forEach(element => {
      if (element.getAttribute('for') === id) {
        element.classList.remove('active');
      }
    });
  };

  // get DOM elements
  const init = () => {
    const floatContainers = document.querySelectorAll(
      '.SitecoreForm input, .SitecoreForm textarea, .SitecoreForm select'
    );

    [...floatContainers].forEach(element => {
      if (element.value) {
        makeLabelActive(element.getAttribute('id'));
      }

      bindEvents(element);
    });
  };

  return {
    init: init
  };
})();
