import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../../Common/Icons/ChevronDown';
import classNames from 'classnames';
import { Visible } from 'react-grid-system';
const METRIC = 'metric';
const IMPERIAL = 'imperial';

class ProductComparisonAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }

  toggleAccordion = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  getSystem() {
    if (this.props.hasSystem) {
      const { 'Metric Label': Metric, 'Imperial Label': Imperial } = this.props.fields;

      const cssMetricClass = classNames('ProductComparisonAccordion-Heading-Systems-Metric', {
        'ProductComparisonAccordion-Heading-Systems-Metric-Active': this.props.system === METRIC
      });
      const cssImperialClass = classNames('ProductComparisonAccordion-Heading-Systems-Imperial', {
        'ProductComparisonAccordion-Heading-Systems-Imperial-Active': this.props.system === IMPERIAL
      });
      return (
        <div className='ProductComparisonAccordion-Heading-Systems'>
          <div
            className={cssMetricClass}
            role='button'
            tabIndex='0'
            onClick={e => {
              this.props.onSystemClick(METRIC);
              e.stopPropagation();
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) this.props.onSystemClick(METRIC);
              e.stopPropagation();
            }}
          >
            <Text field={Metric} />
          </div>
          <div
            className={cssImperialClass}
            role='button'
            tabIndex='0'
            onClick={e => {
              this.props.onSystemClick(IMPERIAL);
              e.stopPropagation();
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) this.props.onSystemClick(IMPERIAL);
              e.stopPropagation();
            }}
          >
            <Text field={Imperial} />
          </div>
        </div>
      );
    }
  }

  getAcordionTitle = () => {
    return <Text field={this.props.Label} tag='span' className='ProductComparisonAccordion-Heading-Title' />;
  };

  render() {
    const cssClassContent = classNames('ProductComparisonAccordion-Content', {
        Active: this.state.isOpen
      }),
      cssClassHeading = classNames('ProductComparisonAccordion-Heading', {
        Active: this.state.isOpen
      });

    if (!this.props.shouldDisplay) {
      return <div />;
    }
    return (
      <div className='ProductComparisonAccordion'>
        <div
          className={cssClassHeading}
          onClick={this.toggleAccordion}
          onKeyDown={e => {
            if (e.target === 13) this.toggleAccordion();
          }}
          role='button'
          tabIndex='0'
          aria-label='Open/Close Accordion'
        >
          <Visible xs sm md>
            <div className='ProductComparisonAccordion-Heading-Container'>
              {this.getAcordionTitle()}
              <ChevronDown />
            </div>
            {this.getSystem()}
          </Visible>
          <Visible xl lg>
            {this.getAcordionTitle()}
            <div>
              {this.getSystem()}
              <ChevronDown />
            </div>
          </Visible>
        </div>
        <div className={cssClassContent}>{this.props.children}</div>
      </div>
    );
  }
}

export default ProductComparisonAccordion;
