import React from 'react';
import Carousel from 'react-alice-carousel';
import ArrowRight from '../Icons/ArrowRight';
import ArrowLeft from '../Icons/ArrowLeft';

class CardsCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      itemsInSlide: this.props.nrOfItemsToShow,
      responsive: this.props.responsiveRule || {
        0: { items: 1 },
        760: { items: 2 },
        960: { items: 3 }
      }
    };
  }

  slidePrevPage = () => {
    const { currentIndex, itemsInSlide } = this.state;
    if (currentIndex !== 0) {
      const nextItemIndex = currentIndex - itemsInSlide >= 0 ? currentIndex - itemsInSlide : 0;
      this.setState({
        currentIndex: nextItemIndex
      });
    }
  };

  slideNextPage = () => {
    const { itemsInSlide, currentIndex } = this.state,
      galleryItems = this.props.children;

    let nextItemIndex = currentIndex + itemsInSlide;
    if (nextItemIndex + itemsInSlide > galleryItems.length) {
      nextItemIndex = galleryItems.length - itemsInSlide >= 0 ? galleryItems.length - itemsInSlide : 0;
    }
    if (currentIndex !== nextItemIndex && nextItemIndex < galleryItems.length) {
      this.setState({
        currentIndex: nextItemIndex
      });
    }
  };

  checkIfIsTheLastIndex() {
    const { currentIndex, itemsInSlide } = this.state,
      galleryItems = this.props.children,
      numerOfSlides = Math.ceil(galleryItems.length / itemsInSlide),
      curentSlideNumber = Math.ceil((currentIndex + itemsInSlide) / itemsInSlide);

    return galleryItems.length - itemsInSlide >= 0 &&
      (curentSlideNumber === numerOfSlides || curentSlideNumber > numerOfSlides)
      ? true
      : false;
  }

  handleOnSlideChange = event => {
    const { itemsInSlide, item } = event;
    this.setState({ itemsInSlide, currentIndex: item });
  };

  render() {
    const { currentIndex, responsive } = this.state,
      galleryItems = this.props.children;

    if (galleryItems === null || galleryItems.length < 1) {
      return null;
    }
    const arrowLeftDisabled = currentIndex === 0 ? 'ArrowDisabled' : '';
    let arrowRightDisabled = '',
      overlayJsx = <div className='CardsCarousel-Overlay' />;

    if (this.checkIfIsTheLastIndex()) {
      arrowRightDisabled = 'ArrowDisabled';
      overlayJsx = null;
    }

    return (
      <React.Fragment>
        <div className='CardsCarousel'>
          {overlayJsx}
          <div
            className={'CardsCarousel-ArrowLeft ' + arrowLeftDisabled}
            onClick={this.slidePrevPage}
            onKeyDown={e => {
              if (e.keyCode === 13) this.slidePrevPage();
            }}
            role='button'
            tabIndex='0'
          >
            <ArrowLeft />
          </div>
          <Carousel
            duration={400}
            infinite={false}
            items={galleryItems}
            dotsDisabled={this.props.disableDots ? this.props.disableDots : false}
            slideToIndex={currentIndex}
            responsive={responsive}
            onInitialized={this.handleOnSlideChange}
            onSlideChanged={this.handleOnSlideChange}
            onResized={this.handleOnSlideChange}
          />
          <div
            className={'CardsCarousel-ArrowRight ' + arrowRightDisabled}
            onClick={this.slideNextPage}
            onKeyDown={e => {
              if (e.keyCode === 13) this.slideNextPage();
            }}
            role='button'
            tabIndex='0'
          >
            <ArrowRight />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CardsCarousel;
