import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Visible } from 'react-grid-system';
import StarIcon from '../../../Icons/StarIcon';
import StarFilledIcon from '../../../Icons/StarFilledIcon';
import { logInRedirect, checkIfUserIsLoggedIn } from '../../../../../utils/Utils';
import { addFavouriteContactId, deleteFavouriteContactId } from '../../../../../store/favoriteContact/actions';

class FavouriteContactPointButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false
    };
  }
  onStarClick() {
    const { addFavouriteContactId, contactPointId, onClickStar } = this.props;
    addFavouriteContactId(contactPointId);
    onClickStar();
  }

  onStarFilledClick() {
    const { deleteFavouriteContactId, contactPointId, onClickStarFilled } = this.props;
    deleteFavouriteContactId(contactPointId);
    onClickStarFilled();
  }

  getFavouriteIcon = () => {
    const { contactPointId, favoriteContactId } = this.props;
    if (contactPointId === favoriteContactId) {
      return <StarFilledIcon />;
    }
    return <StarIcon color='red' />;
  };

  getCallBacks = () => {
    const { contactPointId, favoriteContactId } = this.props;
    if (contactPointId === favoriteContactId) {
      return this.onStarFilledClick();
    }
    return this.onStarClick();
  };

  getTooltip(label, onTooltipMethod) {
    if (label && label.value) {
      return (
        <OutsideClickHandler onOutsideClick={this.handleOutsideClick}>
          <span
            onClick={onTooltipMethod}
            onKeyDown={e => {
              if (e.target === 13) onTooltipMethod();
            }}
            role='button'
            tabIndex='0'
            className='ContactPointInfo-Title-Tooltip'
          >
            {label.value}
          </span>
        </OutsideClickHandler>
      );
    }
  }

  getStarsWithUser = () => {
    const { contactPointId, favoriteContactId } = this.props,
      starClassName = classNames({
        'ContactPointInfo-Title-StarFilled': contactPointId === favoriteContactId,
        'ContactPointInfo-Title-Star': contactPointId !== favoriteContactId
      }),
      { 'Star Tooltip Text For Logged User': tooltipUser } = this.props.fields;

    return (
      <span className='ContactPointInfo-Title-StarContainer'>
        <span
          onClick={this.getCallBacks}
          onKeyDown={e => {
            if (e.target === 13) this.getCallBacks();
          }}
          role='button'
          tabIndex='0'
          className={starClassName}
        >
          {this.getFavouriteIcon()}
        </span>
        {this.getTooltip(tooltipUser)}
      </span>
    );
  };
  getStarWithoutUser = () => {
    const { 'Star Tooltip Text For Not Logged User': tooltipNoUser } = this.props.fields;
    return (
      <span className='ContactPointInfo-Title-StarContainer ContactPointInfo-Title-Star'>
        <StarIcon color='red' />
        {this.getTooltip(tooltipNoUser, logInRedirect)}
      </span>
    );
  };
  getStarWithoutUserForMobileAndDesktop = () => {
    const cssClass = classNames('ContactPointInfo-Title-StarContainerWithClick', {
        Active: this.state.isTooltipOpen
      }),
      { contactPointId } = this.props;
    return (
      <React.Fragment>
        <Visible xl lg>
          {this.getStarWithoutUser()}
        </Visible>
        <Visible xs sm md>
          <div
            onClick={this.toggleTooltip}
            onKeyDown={e => {
              if (e.target === 13) this.getTooltip();
            }}
            role='button'
            tabIndex='0'
            className={cssClass}
            id={'ContactPointInfoStar' + contactPointId}
          >
            {this.getStarWithoutUser()}
          </div>
        </Visible>
      </React.Fragment>
    );
  };

  toggleTooltip = () => {
    this.setState(state => ({ isTooltipOpen: !state.isTooltipOpen }));
  };

  handleOutsideClick = evt => {
    const { contactPointId } = this.props;
    const starElement = document.getElementById('ContactPointInfoStar' + contactPointId),
      spanElement = starElement
        ? starElement.querySelector('.ContactPointInfo-Title-StarContainer.ContactPointInfo-Title-Star')
        : null,
      svgElement = starElement
        ? starElement.querySelector('.ContactPointInfo-Title-StarContainer.ContactPointInfo-Title-Star svg')
        : null,
      pathElement = starElement
        ? starElement.querySelector('.ContactPointInfo-Title-StarContainer.ContactPointInfo-Title-Star svg path')
        : null;
    if (
      starElement !== evt.target &&
      spanElement !== evt.target &&
      svgElement !== evt.target &&
      pathElement !== evt.target
    ) {
      this.setState({ isTooltipOpen: false });
    }
  };

  displayStar = () => {
    const { sitecoreContext, isRestrictedUser, isRestrictedContact } = this.props,
      checkrestrictedContact =
        isRestrictedContact && isRestrictedContact.value && isRestrictedContact.value === '1' ? true : false;
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      if (!isRestrictedUser || (isRestrictedUser && !checkrestrictedContact)) {
        return this.getStarsWithUser();
      }
      return null;
    }
    return this.getStarWithoutUserForMobileAndDesktop();
  };

  render() {
    return <React.Fragment>{this.displayStar()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    favoriteContactId: state.favoriteContact.favoriteContactId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addFavouriteContactId: contactId => dispatch(addFavouriteContactId(contactId)),
    deleteFavouriteContactId: contactId => dispatch(deleteFavouriteContactId(contactId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(FavouriteContactPointButton));
