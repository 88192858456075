import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import OutsideClickHandler from 'react-outside-click-handler';
import CloseIcon from '../../../Common/Icons/CloseIcon';
import Dropdown from '../../../Common/Dropdown/index';
import { cutHtmlStringWithDots, checkIfUserIsLoggedIn, removeWhiteSpaces } from '../../../../utils/Utils';
import { getWishList, addNewList } from '../../../../store/wishlist/actions';

class WishlistBox extends React.Component {
  constructor(props) {
    super(props);
    const defaultWishList = this.getDefaultWishList();
    this.state = {
      selectedListName: defaultWishList
    };
  }

  componentDidMount() {
    const defaultWishList = this.getDefaultWishList();
    this.setState({ selectedListName: defaultWishList });
    if (checkIfUserIsLoggedIn(this.props.sitecoreContext)) {
      this.props.getWishList();
    }
  }

  componentDidUpdate() {
    if (this.state.selectedListName === '') {
      const defaultWishList = this.getDefaultWishList();
      this.setState({ selectedListName: defaultWishList });
    }
  }

  getDefaultWishList = () => {
    const { wishLists, sitecoreContext, fields } = this.props;
    const { Wishlists } = fields;
    const defaultWishlistName = Wishlists?.fields?.['Wishlist Name']?.value ?? '';
    if (checkIfUserIsLoggedIn(sitecoreContext) && wishLists.length && wishLists[0].name !== '') {
      // If the logged in user has a wishlist, use that one as default
      return wishLists[0].name;
    }
    // Otherwise (also anonymous!) use the default wishlist from the sitecore field
    return defaultWishlistName;
  };

  getWishLists = () => {
    const { wishLists, sitecoreContext } = this.props;
    if (checkIfUserIsLoggedIn(sitecoreContext) && wishLists) {
      return wishLists.map(item => {
        return {
          title: item.name
        };
      });
    }
    return null;
  };

  onListSet = listName => {
    this.setState({
      selectedListName: listName.title
    });
  };

  addNewList = listName => {
    this.setState({
      selectedListName: listName
    });
    this.props.addNewList(listName);
  };

  render() {
    const {
        'Wishlist Card Title': wishlistCardTitle,
        'Wishlist Card Text': wishlistCardText,
        'Wishlist Card Dropdown Label': wishlistCardDropdownLabel,
        'Wishlist Card Button Label': wishlistCardButtonLabel,
        'Create New Wish List': createNewLabel
      } = this.props.fields,
      {
        isWishlistOpen,
        onWishlistClick,
        handleClickOutside,
        onAddClick,
        wishLists,
        measuringClassname,
        sitecoreContext
      } = this.props,
      cssWishlistCardClass = classNames('WishlistBox', {
        'WishlistBox-Active': isWishlistOpen
      });
    return (
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        <div className={cssWishlistCardClass}>
          <div className='WishlistBox-Heading'>
            <Text field={wishlistCardTitle} />
            <CloseIcon
              onClick={onWishlistClick}
              onKeyDown={e => {
                if (e.keyCode === 13) onWishlistClick();
              }}
              role='button'
              tabIndex='0'
            />
          </div>
          <Text field={wishlistCardText} tag='div' className='WishlistBox-Text' />
          <Dropdown
            label={wishlistCardDropdownLabel.value}
            currentItem={{
              title: cutHtmlStringWithDots(this.state.selectedListName || this.getDefaultWishList(), 24)
            }}
            items={this.getWishLists()}
            onSet={this.onListSet}
            addNewItemLabel={
              checkIfUserIsLoggedIn(sitecoreContext) && wishLists.length < 9 ? { title: createNewLabel.value } : null
            }
            onCreate={this.addNewList}
          />
          <div
            className={'WishlistBox-AddButton button_wishlist_pdp ' + removeWhiteSpaces(measuringClassname || null)}
            onClick={() => onAddClick(this.state.selectedListName)}
            onKeyDown={e => {
              if (e.target === 13) onAddClick(this.state.selectedListName);
            }}
            role='button'
            tabIndex='0'
          >
            <Text field={wishlistCardButtonLabel} />
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getWishList: () => dispatch(getWishList()),
    addNewList: listName => dispatch(addNewList(listName))
  };
};

const mapStateToProps = state => {
  return {
    wishLists: state.wishlistProducts && state.wishlistProducts.wishLists
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(WishlistBox));
