import { getCookie, setCookie } from '../../Common/CookieSettings/CookieUtils';
import { addToCart, createCart, getCart, removeFromCart, updateCart } from '../lib/shopify';
import { createCartAttributes } from '../lib/shopify/cartHelpers';
import { Cart, CartItemAttribute, ProductData, ShopifyLineError } from '../lib/shopify/types';

export async function addItems(
  productVariantIds: string[],
  amount = 1,
  productItemsData?: ProductData[] | undefined,
  relatedBundle?: string
): Promise<{
  cart: string | Cart | undefined;
  userErrors: ShopifyLineError[];
}> {
  let cartId = getCookie('cartId');
  let cart;

  if (cartId) {
    cart = await getCart(cartId);
  }

  if (!cartId || !cart) {
    cart = await createCart();
    cartId = cart.id;
    setCookie('cartId', cartId, 30);
  }

  if (!productVariantIds.length) {
    return {
      cart: 'Missing variant IDs',
      userErrors: []
    };
  }

  try {
    const lineItems = productVariantIds.map(productVariantId => {
      // attributes array must always match - so when editing cart entry - the same attributes must be passed
      let lineItemAttributes: CartItemAttribute[] = [];

      if (productItemsData) {
        // Get the right product data for the line item
        const productVariantData = productItemsData.find(
          productItem => productItem.productVariantId === productVariantId
        );

        lineItemAttributes = createCartAttributes({
          _leisterProductUrl: productVariantData?.productItemUrl,
          _leisterProductName: productVariantData?.name,
          _leisterProductBrand: productVariantData?.brand,
          _leisterProductCategory: productVariantData?.category,
          _leisterProductSubcategory: productVariantData?.subcategory,
          _leisterRelatedBundle: relatedBundle
        });
      }

      return {
        merchandiseId: productVariantId,
        quantity: amount,
        attributes: lineItemAttributes
      };
    });

    return await addToCart(cartId, lineItems);
  } catch (e) {
    return {
      cart: 'Error adding items to cart',
      userErrors: []
    };
  }
}

export async function removeItem(lineId: string, cart?: Cart): Promise<string | Cart | undefined> {
  const cartId = getCookie('cartId');

  if (!cartId) {
    return 'Missing cart ID';
  }

  if (!cart) {
    return 'Missing cart';
  }

  try {
    return await removeFromCart(cart, [lineId]);
  } catch (e) {
    return 'Error removing item from cart';
  }
}

export async function updateItemQuantity(payload: {
  cart?: Cart;
  lineId: string;
  variantId: string;
  quantity: number;
  attributes: CartItemAttribute[];
}): Promise<{
  cart: string | Cart | undefined;
  userErrors: ShopifyLineError[];
}> {
  const { cart } = payload;

  if (!cart) {
    return {
      cart: 'Missing cart',
      userErrors: []
    };
  }

  const { lineId, variantId, quantity, attributes } = payload;

  try {
    if (quantity === 0) {
      return {
        cart: await removeFromCart(
          cart,
          [lineId],
          // Handover also the related bundle, so we can handle the details inside the removeFromCart function
          attributes.find(attribute => attribute.key === '_leisterRelatedBundle')?.value
        ),
        userErrors: []
      };
    }

    return await updateCart(cart.id, [
      {
        id: lineId,
        merchandiseId: variantId,
        quantity,
        attributes
      }
    ]);
  } catch (e) {
    return {
      cart: 'Error updating item quantity',
      userErrors: []
    };
  }
}
