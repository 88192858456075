import i18n from 'i18next';
import { AccessoryCategoryFacet } from './Lister/Products/AccessoriesLister/AccessoriesLister.settings';
import { type KnownSiteNamesType, KnownSiteNames } from '../../Constants/KnownSiteNames';

export type SearchPageTab = {
  id: string;
  caption: () => string;
  value: string[];
  expression: string;
  defaultActive?: boolean;
  isProduct?: boolean;
};

export type KnownSites = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in KnownSiteNamesType]: any;
};

export type SearchPageFacet = {
  field: string;
  label: () => string;
  tabs: string[];
};

export type CoveoSiteSearchHubsType = { [key in KnownSiteNamesType]: string };
export type CoveoSitePipelinesType = { [key in KnownSiteNamesType]: string };

export type CoveoSiteNamesType = { [key in KnownSiteNamesType]: KnownSiteNamesType };
export type CoveoSearchPagePipelinesType = { [key in KnownSiteNamesType]: string };
export type CoveoCareerPagePipelinesType = { [key in KnownSiteNamesType]: string };
export type CoveoSearchHubsType = { [key in KnownSiteNamesType]: string };
export type CoveoSearchPageTabDefinitions = { [key in KnownSiteNamesType]: SearchPageTab[] };
export type CoveoSearchPageFacetsType = { [key in KnownSiteNamesType]: SearchPageFacet[] };
export type CoveoResultItemUrlsType = { [key in KnownSiteNamesType]: string };
export type CoveoEventBrandsType = { [key in KnownSiteNamesType]: string };

export const CoveoSiteNames: CoveoSiteNamesType = {
  Axetris: 'Axetris',
  LeisterGroup: 'LeisterGroup',
  LeisterTechnologies: 'LeisterTechnologies',
  Weldy: 'Weldy'
};

export const CoveoSearchProductPipelines: CoveoSearchPagePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoSearchProductSearchHubs: CoveoSearchHubsType = {
  Axetris: 'SearchProduct-AXAG',
  LeisterGroup: 'SearchProduct-LAG',
  LeisterTechnologies: 'SearchProduct-LTAG',
  Weldy: 'SearchProduct-WELDY'
};

export const CoveoOmniboxPipelines: CoveoSearchPagePipelinesType = {
  Axetris: 'GeneralSearchPage',
  LeisterGroup: 'GeneralSearchPage',
  LeisterTechnologies: 'GeneralSearchPage',
  Weldy: 'GeneralSearchPage'
};

export const CoveoOmniboxSearchHubs: CoveoSearchHubsType = {
  Axetris: 'Omnibox-AXAG',
  LeisterGroup: 'Omnibox-LAG',
  LeisterTechnologies: 'Omnibox-LTAG',
  Weldy: 'OmniBox-WELDY'
};

export const CoveoSearchResultsPipelines: CoveoSearchPagePipelinesType = {
  Axetris: 'GeneralSearchPage',
  LeisterGroup: 'GeneralSearchPage',
  LeisterTechnologies: 'GeneralSearchPage',
  Weldy: 'GeneralSearchPage'
};

export const CoveoSearchResultsSearchHubs: CoveoSearchHubsType = {
  Axetris: 'SearchResults-AXAG',
  LeisterGroup: 'SearchResults-LAG',
  LeisterTechnologies: 'SearchResults-LTAG',
  Weldy: 'GeneralSearchPage-WELDY'
};

export const CoveoStoriesPipelines: CoveoSearchPagePipelinesType = {
  Axetris: 'Stories',
  LeisterGroup: 'Stories',
  LeisterTechnologies: 'Stories',
  Weldy: 'Stories'
};

export const CoveoStoriesSearchHubs: CoveoSearchHubsType = {
  Axetris: 'Stories-AXAG',
  LeisterGroup: 'Stories-LAG',
  LeisterTechnologies: 'Stories-LTAG',
  Weldy: 'Stories-WELDY'
};

export const CoveoCareerPagePipelines: CoveoCareerPagePipelinesType = {
  Axetris: 'Careers',
  LeisterGroup: 'Careers',
  LeisterTechnologies: 'Careers',
  Weldy: 'Careers'
};

export const CoveoCareerPageSearchHubs: CoveoSearchHubsType = {
  Axetris: 'Careers-AXAG',
  LeisterGroup: 'Careers-LAG',
  LeisterTechnologies: 'Careers-LTAG',
  Weldy: 'Careers-WELDY'
};

export const CoveoResultItemUrls: CoveoResultItemUrlsType = {
  Axetris: 'itemurlaz120xetris',
  LeisterGroup: 'itemurlleistergroup',
  LeisterTechnologies: 'itemurlleistertechnologies',
  Weldy: 'itemurlweldy'
};

export const CoveoEventsPipelines: CoveoSearchPagePipelinesType = {
  Axetris: 'Events',
  LeisterGroup: 'Events',
  LeisterTechnologies: 'Events',
  Weldy: 'Events'
};

export const CoveoEventsSearchHubs: CoveoSearchHubsType = {
  Axetris: 'Events-AXAG',
  LeisterGroup: 'Events-LAG',
  LeisterTechnologies: 'Events-LTAG',
  Weldy: 'Events-WELDY'
};

export const CoveoEventBrands: CoveoEventBrandsType = {
  Axetris: `"${KnownSiteNames.Axetris}"`,
  LeisterGroup: `"${KnownSiteNames.LeisterGroup}", "${KnownSiteNames.LeisterTechnologies}", "${KnownSiteNames.Axetris}"`,
  LeisterTechnologies: `"${KnownSiteNames.LeisterTechnologies}"`,
  Weldy: `"${KnownSiteNames.Weldy}"`
};

export const CoveoGeneralDownloadsPageSearchHubs: CoveoSearchHubsType = {
  Axetris: 'General-Downloads-AXAG',
  LeisterGroup: 'General-Downloads-LAG',
  LeisterTechnologies: 'General-Downloads-LTAG',
  Weldy: 'General-Downloads-WELDY'
};

export const StaticFacets = [AccessoryCategoryFacet];

export type SearchFacet = {
  field: string;
  label: () => string;
  multi?: boolean;
  numeric?: boolean;
  unit?: string;
  precision?: number;
  categories?: string[];
};

export const SearchResultsTabDefinitions: CoveoSearchPageTabDefinitions = {
  Axetris: [
    {
      isProduct: true,
      id: 'AXAG-CategoryTabSearchProducts',
      caption: () => i18n.t('SEARCH | Tabs Products'),
      value: ['product'],
      defaultActive: true,
      expression: '@herotemplatename==product'
    },
    {
      id: 'AXAG-CategoryTabSearchContents',
      caption: () => i18n.t('SEARCH | Tabs Contents'),
      value: ['SolutionHero', 'UniversalHero', 'ExpertiseHero'],
      expression: '@herotemplatename==(ExpertiseHero, SolutionHero, UniversalHero)'
    },
    {
      id: 'AXAG-CategoryTabSearchStories',
      caption: () => i18n.t('SEARCH | Tabs Stories'),
      value: ['StoryHero', 'UniversalStoryHero'],
      expression: '(@herotemplatename==StoryHero OR @z95xtemplatename==Story)'
    },
    {
      id: 'AXAG-CategoryTabSearchCareers',
      caption: () => i18n.t('SEARCH | Tabs Careers'),
      value: ['CareerHero', 'UniversalJobHero'],
      expression: '@herotemplatename=CareerHero OR @z95xtemplatename==Job'
    },
    {
      isProduct: true,
      id: 'AXAG-CategoryTabSearchVariants',
      caption: () => i18n.t('SEARCH | Tabs Variants'),
      value: ['variant'],
      expression: '@herotemplatename==variant'
    }
  ],
  LeisterGroup: [
    {
      id: 'LAG-CategoryTabSearchContents',
      caption: () => i18n.t('SEARCH | Tabs Contents'),
      value: ['SolutionHero', 'UniversalHero', 'ExpertiseHero'],
      defaultActive: true,
      expression: '@herotemplatename==(ExpertiseHero, SolutionHero, UniversalHero)'
    },
    {
      id: 'LAG-CategoryTabSearchStories',
      caption: () => i18n.t('SEARCH | Tabs Stories'),
      value: ['StoryHero', 'UniversalStoryHero'],
      expression: '(@herotemplatename==StoryHero OR @z95xtemplatename==Story)'
    },
    {
      id: 'LAG-CategoryTabSearchCareers',
      caption: () => i18n.t('SEARCH | Tabs Careers'),
      value: ['CareerHero', 'UniversalJobHero'],
      expression: '@herotemplatename=CareerHero OR @z95xtemplatename==Job'
    }
  ],
  LeisterTechnologies: [
    {
      isProduct: true,
      id: 'LTAG-CategoryTabSearchProducts',
      caption: () => i18n.t('SEARCH | Tabs Products'),
      value: ['product'],
      defaultActive: true,
      expression: '@herotemplatename==product'
    },
    {
      isProduct: true,
      id: 'LTAG-CategoryTabSearchAccessories',
      caption: () => i18n.t('SEARCH | Tabs Accessories'),
      value: ['accessory'],
      expression: '@herotemplatename==accessory'
    },
    {
      id: 'LTAG-CategoryTabSearchContents',
      caption: () => i18n.t('SEARCH | Tabs Contents'),
      value: ['SolutionHero', 'UniversalHero', 'ExpertiseHero'],
      expression: '@herotemplatename==(ExpertiseHero, SolutionHero, UniversalHero)'
    },
    {
      id: 'LTAG-CategoryTabSearchStories',
      caption: () => i18n.t('SEARCH | Tabs Stories'),
      value: ['StoryHero', 'UniversalStoryHero'],
      expression: '(@herotemplatename==StoryHero OR @z95xtemplatename==Story)'
    },
    {
      id: 'LTAG-CategoryTabSearchCareers',
      caption: () => i18n.t('SEARCH | Tabs Careers'),
      value: ['CareerHero', 'UniversalJobHero'],
      expression: '@herotemplatename=CareerHero OR @z95xtemplatename==Job'
    },
    {
      isProduct: true,
      id: 'LTAG-CategoryTabSearchVariants',
      caption: () => i18n.t('SEARCH | Tabs Variants'),
      value: ['variant'],
      expression: '@herotemplatename==variant'
    }
  ],
  Weldy: [
    {
      isProduct: true,
      id: 'WELDY-CategoryTabSearchProducts',
      caption: () => i18n.t('SEARCH | Tabs Products'),
      value: ['product'],
      defaultActive: true,
      expression: '@herotemplatename==product'
    },
    {
      isProduct: true,
      id: 'WELDY-CategoryTabSearchAccessories',
      caption: () => i18n.t('SEARCH | Tabs Accessories'),
      value: ['accessory'],
      expression: '@herotemplatename==accessory'
    },
    {
      id: 'WELDY-CategoryTabSearchContents',
      caption: () => i18n.t('SEARCH | Tabs Contents'),
      value: ['SolutionHero', 'UniversalHero', 'ExpertiseHero'],
      expression: '@herotemplatename==(ExpertiseHero, SolutionHero, UniversalHero)'
    },
    {
      id: 'WELDY-CategoryTabSearchStories',
      caption: () => i18n.t('SEARCH | Tabs Stories'),
      value: ['StoryHero', 'UniversalStoryHero'],
      expression: '(@herotemplatename==StoryHero OR @z95xtemplatename==Story)'
    },
    {
      isProduct: true,
      id: 'WELDY-CategoryTabSearchVariants',
      caption: () => i18n.t('SEARCH | Tabs Variants'),
      value: ['variant'],
      expression: '@herotemplatename==variant'
    }
  ]
};

export const CoveoSearchPageFacets: CoveoSearchPageFacetsType = {
  Axetris: [
    {
      field: 'pimapplications',
      label: () => i18n.t('SEARCH | Facets pimapplications'),
      tabs: ['AXAG-CategoryTabSearchProducts']
    },
    {
      field: 'pimclassificationtranslated',
      label: () => i18n.t('SEARCH | Facets pimclassificationtranslated'),
      tabs: ['LTAG-CategoryTabSearchProducts']
    },
    {
      field: 'category',
      label: () => i18n.t('SEARCH | Facets category'),
      tabs: ['AXAG-CategoryTabSearchContents', 'AXAG-CategoryTabSearchStories']
    },
    {
      field: 'region',
      label: () => i18n.t('SEARCH | Facets region'),
      tabs: ['AXAG-CategoryTabSearchStories']
    },
    {
      field: 'country',
      label: () => i18n.t('SEARCH | Facets country'),
      tabs: ['AXAG-CategoryTabSearchCareers']
    },
    {
      field: 'location',
      label: () => i18n.t('SEARCH | Facets location'),
      tabs: ['AXAG-CategoryTabSearchCareers']
    },
    {
      field: 'company',
      label: () => i18n.t('SEARCH | Facets company'),
      tabs: ['AXAG-CategoryTabSearchCareers']
    },
    {
      field: 'department',
      label: () => i18n.t('SEARCH | Facets department'),
      tabs: ['AXAG-CategoryTabSearchCareers']
    },
    {
      field: 'workz32xload',
      label: () => i18n.t('SEARCH | Facets workz32xload'),
      tabs: ['AXAG-CategoryTabSearchCareers']
    }
  ],
  LeisterGroup: [
    {
      field: 'category',
      label: () => i18n.t('SEARCH | Facets category'),
      tabs: ['LAG-CategoryTabSearchContents', 'LAG-CategoryTabSearchStories']
    },
    {
      field: 'region',
      label: () => i18n.t('SEARCH | Facets region'),
      tabs: ['LAG-CategoryTabSearchStories']
    },
    {
      field: 'country',
      label: () => i18n.t('SEARCH | Facets country'),
      tabs: ['LAG-CategoryTabSearchCareers']
    },
    {
      field: 'location',
      label: () => i18n.t('SEARCH | Facets location'),
      tabs: ['LAG-CategoryTabSearchCareers']
    },
    {
      field: 'company',
      label: () => i18n.t('SEARCH | Facets company'),
      tabs: ['LAG-CategoryTabSearchCareers']
    },
    {
      field: 'department',
      label: () => i18n.t('SEARCH | Facets department'),
      tabs: ['LAG-CategoryTabSearchCareers']
    },
    {
      field: 'workz32xload',
      label: () => i18n.t('SEARCH | Facets workz32xload'),
      tabs: ['LAG-CategoryTabSearchCareers']
    }
  ],
  LeisterTechnologies: [
    {
      field: 'pimaccessorycategorytranslated',
      label: () => i18n.t('SEARCH | Facets pimaccessorycategory'),
      tabs: ['LTAG-CategoryTabSearchAccessories']
    },
    {
      field: 'pimapplications',
      label: () => i18n.t('SEARCH | Facets pimapplications'),
      tabs: ['LTAG-CategoryTabSearchProducts', 'LTAG-CategoryTabSearchAccessories']
    },
    {
      field: 'pimclassificationtranslated',
      label: () => i18n.t('SEARCH | Facets pimclassificationtranslated'),
      tabs: ['LTAG-CategoryTabSearchProducts']
    },
    {
      field: 'category',
      label: () => i18n.t('SEARCH | Facets category'),
      tabs: ['LTAG-CategoryTabSearchContents', 'LTAG-CategoryTabSearchStories']
    },
    {
      field: 'region',
      label: () => i18n.t('SEARCH | Facets region'),
      tabs: ['LTAG-CategoryTabSearchStories']
    },
    {
      field: 'country',
      label: () => i18n.t('SEARCH | Facets country'),
      tabs: ['LTAG-CategoryTabSearchCareers']
    },
    {
      field: 'location',
      label: () => i18n.t('SEARCH | Facets location'),
      tabs: ['LTAG-CategoryTabSearchCareers']
    },
    {
      field: 'company',
      label: () => i18n.t('SEARCH | Facets company'),
      tabs: ['LTAG-CategoryTabSearchCareers']
    },
    {
      field: 'department',
      label: () => i18n.t('SEARCH | Facets department'),
      tabs: ['LTAG-CategoryTabSearchCareers']
    },
    {
      field: 'workz32xload',
      label: () => i18n.t('SEARCH | Facets workz32xload'),
      tabs: ['LTAG-CategoryTabSearchCareers']
    }
  ],
  Weldy: []
};
