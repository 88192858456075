import classNames from 'classnames';
import React from 'react';
import Price from '../Price/Price';
import Tag from '../../Common/Tag/Tag';
import i18n from 'i18next';

export const calculateDiscountPercentage = (
  oldPriceAmount: string,
  currentPriceAmount?: string,
  forceDiscountPercentage?: number
): string => {
  if (forceDiscountPercentage !== undefined) {
    return `${forceDiscountPercentage}%`;
  } else if (currentPriceAmount !== undefined) {
    return `${(
      ((parseFloat(oldPriceAmount) - parseFloat(currentPriceAmount)) / parseFloat(oldPriceAmount)) *
      100
    ).toFixed(0)}%`;
  }
  return '';
};

export const calculatePriceWithDiscountPercentage = (
  oldPriceAmount: number,
  forceDiscountPercentage?: number
): number => {
  if (forceDiscountPercentage !== undefined) {
    return oldPriceAmount - oldPriceAmount * (forceDiscountPercentage / 100);
  }
  return oldPriceAmount;
};

export type PriceDetailVariants = '' | 'bundle' | 'upSell';

const PriceDetails = ({
  currentPriceAmount = undefined,
  forceDiscountPercentage = undefined,
  oldPriceAmount,
  currencyCode = 'USD',
  className = '',
  variant = '',
  showCurrentPrice = true,
  showOldPrice = false,
  showDiscountTag = false
}: {
  currentPriceAmount?: string;
  forceDiscountPercentage?: number;
  oldPriceAmount: string;
  currencyCode: string;
  className?: string;
  variant?: PriceDetailVariants;
  showCurrentPrice?: boolean;
  showOldPrice?: boolean;
  showDiscountTag?: boolean;
}): JSX.Element => {
  const getDiscountTagContent = (variant: PriceDetailVariants) => {
    if (variant === 'bundle') {
      return i18n.t('SHOP | Tag bundle discount', {
        discountPercentage: calculateDiscountPercentage(oldPriceAmount, currentPriceAmount, forceDiscountPercentage)
      });
    } else if (variant === 'upSell') {
      return i18n.t('SHOP | Tag up sell discount', {
        discountPercentage: calculateDiscountPercentage(oldPriceAmount, currentPriceAmount, forceDiscountPercentage)
      });
    }
    return `-${calculateDiscountPercentage(oldPriceAmount, currentPriceAmount, forceDiscountPercentage)}`;
  };

  // only render a sale tag and old price, if the current price is lower than the old price
  const productHasSalePrice =
    forceDiscountPercentage || parseFloat(oldPriceAmount ?? '0') > parseFloat(currentPriceAmount ?? '0');

  const discountTagRendering =
    showDiscountTag && productHasSalePrice ? <Tag type='primary'>{getDiscountTagContent(variant)}</Tag> : null;

  // Take the currentPriceAmount or calculate the discount from the forceDiscountPercentage
  let currentPrice = currentPriceAmount ? currentPriceAmount : '';
  if (forceDiscountPercentage) {
    currentPrice = calculatePriceWithDiscountPercentage(parseFloat(oldPriceAmount), forceDiscountPercentage).toFixed(2);
  }

  const currentPriceRendering = showCurrentPrice ? (
    <Price
      amount={currentPrice}
      currencyCode={currencyCode}
      prefix={variant === 'upSell' ? i18n.t('SHOP | Price up sell prefix') : null}
      type={variant === 'bundle' ? 'bundle-price' : 'current-price'}
    />
  ) : null;
  const oldPriceRendering =
    showOldPrice && productHasSalePrice ? (
      <Price
        amount={oldPriceAmount}
        currencyCode={currencyCode}
        prefix={variant !== 'bundle' ? i18n.t('SHOP | Old price prefix') : null}
        type='old-price'
      />
    ) : null;

  return (
    <div
      className={classNames(className, 'shopify-price-details', {
        [`shopify-price-details--variant-${variant}`]: variant
      })}
    >
      <div className='shopify-price-details__discountTag'>{discountTagRendering}</div>
      <div className='shopify-price-details__oldPrice'>{oldPriceRendering}</div>
      <div
        className={classNames('shopify-price-details__currentPrice', {
          'has-old-price': oldPriceAmount && productHasSalePrice
        })}
      >
        {currentPriceRendering}
      </div>
    </div>
  );
};

export default PriceDetails;
