import productFragment from '../fragments/product';
import { getLanguageContext } from '../inContext/language';

export const getProductVariantsByGidsQuery = (): string => /* GraphQL */ `
  query getProductVariantsByGids($query: [ID!]!) ${getLanguageContext()} {
    nodes(ids: $query) {
      ... on ProductVariant {
        id
        product {
          ...product
        }
      }
    }
  }
  ${productFragment}
`;
