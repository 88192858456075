import React, { ComponentType } from 'react';
import i18n from 'i18next';
import { getSitecoreApiHost } from '../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';
import GeneralLink from '../../Common/GeneralLink/GeneralLink';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import { DownloadsComponentFields } from './types';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type DownloadsBackLinkProps = {
  fields: DownloadsComponentFields;
  language: string;
  downloadProductName: string;
  downloadArticleNumber: string;
  location: any;
};

const getBackLink = (backLabel: string, name: string, link: string) => {
  return (
    <GeneralLink className='AllDownloads-BackTo' fields={{ value: { href: link } }}>
      <ArrowLeft />
      {backLabel + ' ' + name}
    </GeneralLink>
  );
};

const DownloadsBackLink: React.FC<DownloadsBackLinkProps> = ({
  fields,
  language,
  downloadProductName,
  downloadArticleNumber,
  location
}) => {
  const backToDevice = typeof window !== 'undefined' ? window.sessionStorage.getItem('mydevices') : null;
  const { 'My Devices Link': MyDevicesLink, 'Product Downloads Lister Link': ProductDownloadsListerLink } = fields;

  if (backToDevice) {
    if (MyDevicesLink?.value) {
      return getBackLink(i18n.t('PRODUCT_DOWNLOADS | Back to my devices'), '', MyDevicesLink?.value.href ?? '');
    }
  } else {
    if (ProductDownloadsListerLink?.value) {
      const backLinkFromState = location?.state?.from || null;

      return getBackLink(
        '',
        i18n.t('PRODUCT_DOWNLOADS | Back to Downloads'),
        backLinkFromState || ProductDownloadsListerLink?.value.href
      );
    } else if (downloadProductName) {
      const articleNumber = downloadArticleNumber ? '/' + downloadArticleNumber : '';
      const backToLink = `${getSitecoreApiHost()}/${language}${PRODUCT_PAGE_ROUTE}/${downloadProductName}${articleNumber}`;
      return getBackLink(i18n.t('PRODUCT_DOWNLOADS | Back to'), downloadProductName.replace(/-/g, ' '), backToLink);
    }
  }

  return null;
};

export default withRouter<
  RouteComponentProps & DownloadsBackLinkProps,
  ComponentType<RouteComponentProps & DownloadsBackLinkProps>
>(DownloadsBackLink);
