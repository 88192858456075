import React from 'react';

const Label = props => {
  if (props.labelComponent) {
    const CustomLabel = props.labelComponent;

    // strip the label component from the custom component props
    // (prevents infinite loop rendering if someone reuses this component as a custom label component)
    const { labelComponent, ...labelComponentProps } = props;

    return <CustomLabel {...labelComponentProps} />;
  }

  const { field, isValid, children } = props;

  let className = field.model.labelCssClass;

  if (!isValid) {
    className += ' invalid ';
  }

  return (
    <label htmlFor={field.valueField.id} className={className}>
      {children}
      {field.model.title}
    </label>
  );
};

export { Label };
