export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const SET_SHORT_PRODUCT_DATA = 'SET_SHORT_PRODUCT_DATA';
export const SET_SHORT_PRODUCT_DATA_LOADING = 'SET_SHORT_PRODUCT_DATA_LOADING';
export const SET_SHORT_PRODUCT_DATA_LOADED = 'SET_SHORT_PRODUCT_DATA_LOADED';
export const SET_SHORT_PRODUCT_DATA_LOAD_ERROR = 'SET_SHORT_PRODUCT_DATA_LOAD_ERROR';
export const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';
export const SET_VARIANTS = 'SET_VARIANTS';
export const SET_ALL_DOWNLOADS = 'SET_ALL_DOWNLOADS';
export const SET_ALL_DOWNLOADS_LOADING = 'SET_ALL_DOWNLOADS_LOADING';
export const SET_ALL_DOWNLOADS_LOADED = 'SET_ALL_DOWNLOADS_LOADED';
export const SET_ALL_DOWNLOADS_LOAD_ERROR = 'SET_ALL_DOWNLOADS_ERROR';
export const SET_PRODUCT_PAGE_FOR_GET_A_QUOTE = 'SET_PRODUCT_PAGE_FOR_GET_A_QUOTE';
export const SET_DATA_FOR_PRODUCT_QUOTE = 'SET_DATA_FOR_PRODUCT_QUOTE';
export const SET_IS_LINK_FOR_QUOTE = 'SET_IS_LINK_FOR_QUOTE';
export const SET_VIDEO_CAMPAIGN_LOADING = 'SET_VIDEO_CAMPAIGN_LOADING';
export const SET_VIDEO_CAMPAIGN_LOADED = 'SET_VIDEO_CAMPAIGN_LOADED';
export const SET_VIDEO_CAMPAIGN_LOAD_ERROR = 'SET_VIDEO_CAMPAIGN_LOAD_ERROR';
export const SET_VIDEO_CAMPAIGN = 'SET_VIDEO_CAMPAIGN';
export const SET_IS_LINK_FOR_EXPERT = 'SET_IS_LINK_FOR_EXPERT';
export const SET_AMOUNT_OF_BUNDLES = 'SET_AMOUNT_OF_BUNDLES';
export const RESET_PRODUCT_DETAILS = 'RESET_PRODUCT_DETAILS';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
