import React from 'react';
import { connect } from 'react-redux';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { removeProduct, deleteProductFromWishList } from '../../../../../src/store/wishlist/actions';
import {
  removeProductFromWishlistCookie,
  setProductToCompareOnCookie,
  getCookie,
  getRawValueFromCompare
} from '../../../Common/CookieSettings/CookieUtils';
import { LANG, COMPARE } from '../../../Common/CookieSettings/CookieConstants';
import CompareIcon from '../../../Common/Icons/CompareIcon';
import GeneralLink from '../../../Common/GeneralLink';
import DeleteIcon from '../../../Common/Icons/DeleteIcon';
import RemoveApprovalOverlay from '../../../../components/RemoveApprovalOverlay';
import CompareBox from '../../HeroActionButton/CompareBox';
import GreenDotCheckmarkIcon from '../../../Common/Icons/GreenDotCheckmarkIcon';
import { checkIfUserIsLoggedIn, removeWhiteSpaces, addProductOnDataLayer } from '../../../../utils/Utils';
import { PRODUCT_PAGE_ROUTE } from '../../../../AppRoutes';

class ItemProductWishlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      isCompareOpen: false
    };
  }

  getTooltip(label) {
    if (label && label.value) {
      return <span className='ItemProductWishlist-Actions-Compare-Tooltip'>{label.value}</span>;
    }
  }

  deleteProductFromWishlist() {
    if (checkIfUserIsLoggedIn(this.props.sitecoreContext)) {
      const { currentWishListIndex, wishLists, item } = this.props,
        { variantId, masterProductNameEn } = item;
      this.props.deleteProductFromWishList(wishLists[currentWishListIndex].name, masterProductNameEn, variantId);
    } else {
      const { variantId } = this.props.item,
        { 'Wishlist Items Name': wishlistName } = this.props.fields,
        wishlistTitle = wishlistName && wishlistName.fields && wishlistName.fields['Wishlist Name'].value;

      removeProductFromWishlistCookie(wishlistTitle, variantId);
      this.props.removeProduct(variantId);
    }
  }

  toggleOverlay = () => {
    this.setState({ isOverlayOpen: !this.state.isOverlayOpen });
  };

  setProductToCompareList = () => {
    const { variantId, categoryEn } = this.props.item;
    setProductToCompareOnCookie(categoryEn, variantId);
    this.onCompareClick();
  };

  onCompareClick = () => {
    this.setState({ isCompareOpen: !this.state.isCompareOpen });
  };

  handleClickOutsideCompareBox = e => {
    const { name, articleNo } = this.props.item,
      itemId = removeWhiteSpaces('ItemProductWishlist' + name + articleNo),
      itemElement = document.getElementById(itemId),
      svgElement = itemElement ? itemElement.querySelector('svg') : null,
      pathElement = itemElement ? itemElement.querySelector('svg path') : null;
    if (itemElement !== e.target && svgElement !== e.target && pathElement !== e.target) {
      this.setState({ isCompareOpen: false });
    }
  };

  getCompareButton = (compareMethod, label) => {
    const { categoryEn, name, articleNo } = this.props.item,
      cookieCompare = getCookie(COMPARE),
      cookieCompareList = cookieCompare ? JSON.parse(cookieCompare) : {},
      productCategory = categoryEn ? categoryEn.split(',') : [],
      checkedNoOnComparelist = productCategory.find(
        item => (cookieCompareList[item] && cookieCompareList[item].length < 4) || !cookieCompareList[item]
      ),
      itemId = removeWhiteSpaces('ItemProductWishlist' + name + articleNo);

    return (
      <React.Fragment>
        <div
          className='ItemProductWishlist-Actions-Compare click_button_product_comparison_pdp'
          id={itemId}
          onClick={() => {
            compareMethod();
          }}
          onKeyDown={e => {
            if (e.target === 13) {
              compareMethod();
            }
          }}
          role='button'
          tabIndex='0'
          aria-label='Open/Close Compare Box'
        >
          <CompareIcon />
        </div>
        {!this.state.isCompareOpen ? this.getTooltip(label) : null}
        <CompareBox
          fields={this.props.fields}
          isCompareOpen={this.state.isCompareOpen}
          onClose={this.onCompareClick}
          handleClickOutside={this.handleClickOutsideCompareBox}
          checkedNoOfProducts={checkedNoOnComparelist}
          categoryEn={categoryEn}
        />
      </React.Fragment>
    );
  };

  displayCompareButton = () => {
    const { variantId } = this.props.item,
      { 'Compare Tooltip Label': compareTooltip, 'Compare Already Tooltip Label': compareAlreadyTooltip } =
        this.props.fields,
      rawCompareList = getRawValueFromCompare(),
      checkAlreadyAdded = rawCompareList.find(item => item === variantId);
    if (checkAlreadyAdded) {
      return (
        <div className='ItemProductWishlist-Actions-CompareWrapper'>
          {this.getCompareButton(this.onCompareClick, compareAlreadyTooltip)}

          <span className='ItemProductWishlist-Actions-CompareWrapper-CheckIcon'>
            <GreenDotCheckmarkIcon />
          </span>
        </div>
      );
    }
    return (
      <div className='ItemProductWishlist-Actions-CompareWrapper'>
        {this.getCompareButton(this.setProductToCompareList, compareTooltip)}
      </div>
    );
  };
  render() {
    const trimmedArticleNo = this.props.item?.articleNo?.trim() ?? '';
    const {
        'Article Number Label': articleLabel,
        'Added Label': addedLabel,
        'Delete Approval Label': deleteText,
        'Delete Button Description': deleteButtonLabel,
        'Cancel Delete Label': cancelDelete
      } = this.props.fields,
      { picture, name, addDate, masterProductNameEn, categoryEn, variantId, brandName } = this.props.item,
      dateData = new Date(addDate),
      date = dateData.getDate() + '.' + (dateData.getMonth() + 1) + '.' + dateData.getFullYear(),
      currentLang = getCookie(LANG),
      productUrl =
        '/' +
        currentLang +
        PRODUCT_PAGE_ROUTE +
        '/' +
        masterProductNameEn.replace(/ /g, '-') +
        '/' +
        trimmedArticleNo.replace('.', '-');

    return (
      <div className='ItemProductWishlist'>
        <div
          onClick={() => {
            addProductOnDataLayer(variantId, masterProductNameEn, categoryEn, productUrl, brandName, trimmedArticleNo);
          }}
          onKeyDown={e => {
            if (e.target === 13)
              addProductOnDataLayer(
                variantId,
                masterProductNameEn,
                categoryEn,
                productUrl,
                brandName,
                trimmedArticleNo
              );
          }}
          role='button'
          tabIndex='0'
        >
          <GeneralLink
            fields={{
              value: {
                href: productUrl
              }
            }}
          >
            <div className='ItemProductWishlist-Details'>
              <div className='ItemProductWishlist-Details-Image'>
                <img src={picture + '?imwidth=177'} alt={name} loading='lazy' />
              </div>
              <div className='ItemProductWishlist-Details-ProductInfo'>
                <div className='ItemProductWishlist-Details-ProductInfo-Title'>{name}</div>
                <div className='ItemProductWishlist-Details-ProductInfo-Details'>
                  <Text field={articleLabel} tag='span' />
                  &#58; {trimmedArticleNo}&nbsp;&#124;&nbsp;
                  <Text field={addedLabel} tag='span' />
                  &#58;&nbsp;{date}
                </div>
              </div>
            </div>
          </GeneralLink>
        </div>
        <div className='ItemProductWishlist-Actions'>
          {this.displayCompareButton()}
          <div
            onClick={this.toggleOverlay}
            onKeyDown={e => {
              if (e.target === 13) {
                this.toggleOverlay();
              }
            }}
            role='button'
            tabIndex='0'
            aria-label='Open Approval Overlay'
            className='ItemProductWishlist-Actions-Delete'
          >
            <DeleteIcon />
          </div>
          {/* NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component */}
          <RemoveApprovalOverlay
            onClose={this.toggleOverlay}
            isOverlay={this.state.isOverlayOpen}
            onClick={() => this.deleteProductFromWishlist()}
            DeleteButtonLabel={deleteButtonLabel}
            DeleteText={deleteText}
            CancelDeleteLabel={cancelDelete}
          />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    removeProduct: productId => dispatch(removeProduct(productId)),
    deleteProductFromWishList: (listName, productName, variantId) =>
      dispatch(deleteProductFromWishList(listName, productName, variantId))
  };
};

export default connect(null, mapDispatchToProps)(withSitecoreContext()(ItemProductWishlist));
