import React from 'react';
import { getResultImageSrc, getResultTitle, getResultCategory } from '../ResultItemUtils';
import classNames from 'classnames';
import EnterIcon from '../../Common/Icons/EnterIcon';

export const AccessoriesInstantResultsProperties = [
  'category',
  'variantarticlenumber',
  'variantarticlenumberraw',
  'variantnametranslated',
  'variantname',
  'imgsrc',
  'mainpicture320',
  'pimclassificationtranslated',
  'masterproductrelativeurl',
  'masterproductname',
  'ez120xpertisepageherotitle',
  'storypageherotitle',
  'itemurlaz120xetris',
  'itemurlleistergroup',
  'itemurlleistertechnologies',
  'solutionpageherotitle',
  'careerz32xpagez32xtitle',
  'universalpageherotitle',
  'title',
  'pimproductid'
];

type AccessoriesResultItemProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  raw: any;
  selectAccessory: (selectAccessory: string, selectedAccessoryName: string) => void;
  onClick: (selectedAccessoryName: string) => void;
  selected: boolean;
};

export const AccessoriesResultItem: React.FC<AccessoriesResultItemProps> = ({
  raw,
  selectAccessory,
  onClick,
  selected
}) => {
  return (
    <>
      <input
        type='radio'
        defaultChecked={selected}
        className='visually-hidden'
        name='search'
        value={raw.pimproductid}
        id={raw.pimproductid}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames('AccessoriesResultItem', {
          AccessoriesResultItem__selected: selected
        })}
        onClick={() => {
          selectAccessory(raw.pimproductid, getResultTitle(raw));
          onClick(getResultTitle(raw));
        }}
      >
        <span className='AccessoriesResultItem__image'>
          <img src={getResultImageSrc(raw)} alt={getResultTitle(raw)} />
        </span>
        <label className='AccessoriesResultItem__text' htmlFor={raw.pimproductid}>
          <span className='AccessoriesResultItem__category'>{getResultCategory(raw)}</span>
          <span className='AccessoriesResultItem__title'>{getResultTitle(raw)}</span>
        </label>
        <EnterIcon className='AccessoriesResultItem__icon' />
      </div>
    </>
  );
};

export default AccessoriesResultItem;
