import React from 'react';
import GenericForm from '../GenericForm';

class ContactForm extends React.Component {
  render() {
    if (!this.props.fields) {
      return null;
    }
    return (
      <div className='ContactForm'>
        <GenericForm fields={this.props.fields} />
      </div>
    );
  }
}

export default ContactForm;
