import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import classNames from 'classnames';
import GeneralLink from '../GeneralLink/index';
import ArrowRight from '../Icons/ArrowRight';

/**
 * Page Linker component with left right image display options that collect data inserted manually
 */
class PageLinker extends React.Component {
  /**
   *
   * the function that return the content with image
   */
  getImageContent = () => {
    const { Image: ImageData, RightImagePosition, LinkUrl } = this.props.fields;

    const cssClass = classNames('PageLinker-Image', {
      'PageLinker-Right': RightImagePosition.value
    });
    const url = LinkUrl && LinkUrl.value.href ? LinkUrl : { value: { href: '#' } };
    return (
      <Col sm={12} md={12} lg={7} xl={7} className={cssClass}>
        <GeneralLink className='PageLinker-Image-Link' fields={url}>
          <Image className='PageLinker-Image-Item' field={ImageData} />
        </GeneralLink>
      </Col>
    );
  };

  /**
   * the function that return the content with text
   */
  getTextContent = isDesktop => {
    const { Category, Description, Title, LinkUrl, LinkText, RightImagePosition } = this.props.fields;

    const cssClass = classNames('PageLinker-NOPadding-Right', {
      'PageLinker-Left': !!RightImagePosition.value && isDesktop
    });

    return (
      <Col sm={12} md={12} lg={4} xl={4} className={cssClass}>
        <div className='PageLinker-TextContent'>
          <Text className='PageLinker-TextContent-Category' tag='div' field={Category} />
          <Text className='PageLinker-TextContent-Title' tag='div' field={Title} />
          <RichText className='PageLinker-TextContent-Description' field={Description} />
          <GeneralLink className='PageLinker-TextContent-Link' fields={LinkUrl}>
            {LinkText && LinkText.value ? LinkText.value : null}
            <ArrowRight />
          </GeneralLink>
        </div>
      </Col>
    );
  };

  render() {
    if (!this.props.fields) {
      return <div className='whiteBackground componentContainer' />;
    }

    const { RightImagePosition, BackgroundColor } = this.props.fields,
      leftImage = isDesktop => {
        return (
          <React.Fragment>
            {this.getImageContent()}
            {this.getTextContent(isDesktop)}
          </React.Fragment>
        );
      },
      rightImage = isDesktop => {
        return (
          <React.Fragment>
            {this.getTextContent(isDesktop)}
            {this.getImageContent()}
          </React.Fragment>
        );
      },
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      };

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='componentContainer'>
          <Row nogutter className='PageLinker click_content_block_detail_page_linker' justify='between' align='end'>
            <Visible lg xl>
              {RightImagePosition.value ? rightImage(true) : leftImage(true)}
            </Visible>
            <Visible xs sm md>
              {leftImage(false)}
            </Visible>
          </Row>
        </div>
      </div>
    );
  }
}

export default PageLinker;
