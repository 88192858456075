import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../../Common/Icons/ChevronDown';
import CompareTechnicalSpecs from './CompareTechnicalSpecs';
import CompareTechnicalSpecsMobile from './CompareTechnicalsSpecsMobile';

class CompareTechnicalSpecsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemsToShow: 5 };
  }

  componentDidUpdate(prevProps) {
    const { selectedCategory } = this.props;
    if (selectedCategory && prevProps.selectedCategory !== selectedCategory) {
      this.setState({ itemsToShow: 5 });
    }
  }

  //get data with products in current category
  getActiveProductsList() {
    const { initialData, selectedCategory } = this.props;
    if (initialData && initialData.length > 0) {
      return initialData.filter(item => {
        return [item.category, item.categoryEn].includes(selectedCategory);
      });
    }
  }

  //get data unique application in current category
  getUniqueTechnicalSpecsList() {
    const activeProducts = this.getActiveProductsList();
    if (activeProducts && activeProducts.length > 0) {
      const initialTehnicalSpecs = activeProducts.slice(0, 4).map(item => {
        return item.technicalSpecs[this.props.system];
      });
      const initialTehnicalSpecsList = [].concat(...initialTehnicalSpecs);

      return Array.from(new Set(initialTehnicalSpecsList.map(a => a.name))).map(name => {
        return initialTehnicalSpecsList.find(a => a.name === name);
      });
    }

    return [];
  }

  // render a list of application for products in current category

  getTechnicalSpecsList = () => {
    const uniqueTechnicalSpecsList = this.getUniqueTechnicalSpecsList();
    if (uniqueTechnicalSpecsList && uniqueTechnicalSpecsList.length > 0) {
      const initialTechnicalSpecs = this.getActiveProductsList()
        .slice(0, 4)
        .map(item => {
          return item.technicalSpecs[this.props.system];
        });
      return uniqueTechnicalSpecsList.slice(0, this.state.itemsToShow).map((element, index) => {
        return (
          <CompareTechnicalSpecs
            key={element.name + index}
            fields={this.props.fields}
            title={element.name}
            picture={element.imageUrl}
          >
            {this.getTechnicalSpecsValue(initialTechnicalSpecs, element.name)}
          </CompareTechnicalSpecs>
        );
      });
    }
  };

  // render a list of application for products in current category for mobile

  getTechnicalSpecsListMobile = () => {
    const activeProducts = this.getActiveProductsList();
    if (activeProducts && activeProducts.length > 0) {
      return (
        <CompareTechnicalSpecsMobile fields={this.props.fields} data={activeProducts} system={this.props.system} />
      );
    }
    return <React.Fragment />;
  };

  // render check mark for existing application for each product in current category
  getTechnicalSpecsValue = (data, technicalSpecTitle) => {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        const machTechnicalSpec = item.find(item => item.name === technicalSpecTitle);
        if (machTechnicalSpec) {
          return (
            <div className='CompareApplication-Item CompareApplication-Value' key={index + technicalSpecTitle}>
              {machTechnicalSpec.value}
            </div>
          );
        }
        return <div className='CompareApplication-Item CompareApplication-Value' key={index + technicalSpecTitle} />;
      });
    }
  };

  // display button to show more application if there are more

  getMoreButton = () => {
    const uniqueTehnicalSpecsList = this.getUniqueTechnicalSpecsList(),
      remainingItems = uniqueTehnicalSpecsList.length - this.state.itemsToShow;
    if (remainingItems > 0) {
      return (
        <div
          onClick={this.incrementDisplayedItems}
          onKeyDown={e => {
            if (e.target === 13) this.incrementDisplayedItems();
          }}
          role='button'
          tabIndex='0'
          className='CompareApplication-MoreButton'
        >
          <ChevronDown />
          <Text field={this.props.fields['Show More Label']} tag='span' />
        </div>
      );
    }
    return <div />;
  };

  incrementDisplayedItems = () => {
    const numberToIncrement = 5;
    this.setState(state => ({
      itemsToShow: state.itemsToShow + numberToIncrement
    }));
  };

  render() {
    return (
      <div>
        <Visible xl lg>
          {this.getTechnicalSpecsList()}
          {this.getMoreButton()}
        </Visible>
        <Visible xs sm md>
          {this.getTechnicalSpecsListMobile()}
        </Visible>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCategory: state.compareProduct.selectedCategory
  };
};

export default connect(mapStateToProps)(CompareTechnicalSpecsList);
