import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

export type ContactInfoTitleProps = {
  fields: any;
  contactData: any;
};
export const ContactInfoTitle = ({ fields, contactData }: ContactInfoTitleProps) => {
  const { contactInfoTitle } = fields.data.item;
  const { onlyProviderLabel } = fields.data.item;
  const partnerType = contactData?.kindOfPartner?.targetItem?.name?.value;
  const provider =
    contactData?.solutionProvider?.targetItems?.length === 1 ? contactData.solutionProvider.targetItems[0] : '';

  return (
    <>
      <Text field={contactInfoTitle} className='ContactInfo-Title' tag='div' />
      {contactData?.name ? (
        <div className='ContactInfo-Container' />
      ) : (
        <div className='ContactInfo-ContainerPartner'>
          <Text field={contactData?.kindOfPartner?.targetItem?.name} tag='div' className='ContactInfo-Partner' />
          {provider?.name?.value && partnerType && (
            <span className='ContactInfo-Provider'>
              (
              <Text field={onlyProviderLabel} />
              &nbsp;
              <Text field={provider.name} />)
            </span>
          )}
        </div>
      )}
      <Text field={contactData?.companyName} className='ContactInfo-Subtitle' tag='div' />
    </>
  );
};
