import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
// note we're aliasing the router's link component name, since it conflicts with JSS' link component
import { Link as RouterLink } from 'react-router-dom';

/** React component that turns Sitecore link values that start with / into react-router route links */
const RoutableSitecoreLinkComponent = props => {
  const hasValidHref = props.field && props.field.value && props.field.value.href;
  const isEditing = props.editable && props.field.editable;

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if (hasValidHref && !isEditing) {
    const value = props.field.value;

    // determine if a link is a route or not. This logic may not be appropriate for all usages.
    if (value.href.startsWith('/')) {
      return (
        <RouterLink
          to={value.href}
          className={props.className || ''}
          id={props.id || null}
          data-id={props['data-id'] || null}
          onClick={props.onClick || null}
        >
          {props.children || value.text || value.href}
        </RouterLink>
      );
    } else {
      return <Link {...props} />;
    }
  } else if (props.field && props.field.href && props.field.href !== '') {
    return <Link {...props} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

// usage - drop-in replacement for JSS' Link component
const RoutableSitecoreLink = props => {
  return (
    <RoutableSitecoreLinkComponent
      field={props.link}
      className={props.className || null}
      id={props.id || null}
      data-id={props.dataId || null}
      onClick={props.onClick || null}
    >
      {props.children}
    </RoutableSitecoreLinkComponent>
  );
};

export default RoutableSitecoreLink;
