import * as ActionTypes from './actionTypes';

const initialState = {
  isWishlistOpen: false,
  wishlistItems: [],
  amountOfAllWishlistItems: 0,
  wishLists: [],
  currentWishListIndex: null,
  errorCode: null,
  currentWishListName: null
};

export const wishlistProducts = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_WISHLIST_FLYOUT:
      return {
        ...state,
        isWishlistOpen: !state.isWishlistOpen
      };
    case ActionTypes.SET_WISHLIST_ITEMS: {
      if (state.wishLists.length === 0) return state;
      let indexOfWishlist = action.listName && state.wishLists.findIndex(element => element.name === action.listName);
      // If we don't have a listName, we fall back to 0 index for the default wishlist
      if (!(indexOfWishlist >= 0)) indexOfWishlist = 0;
      const newWishlists = [...state.wishLists];
      newWishlists[indexOfWishlist].items = action.wishlistItems;

      return {
        ...state,
        wishLists: newWishlists
      };
    }
    case ActionTypes.SET_WISH_LISTS_ANONYMOUS: {
      return {
        ...state,
        wishLists: action.wishLists
      };
    }
    // New case to get the current items from the wishlist array
    case ActionTypes.SET_CURRENT_WISHLIST_ITEMS: {
      if (state.wishLists.length === 0) return state;
      const currentWishList =
        state.wishLists.find(list => list.name === state.currentWishListName) ?? state.wishLists[0];
      if (currentWishList?.items) {
        return {
          ...state,
          wishlistItems: [...currentWishList.items]
        };
      }
      return state;
    }

    case ActionTypes.REMOVE_PRODUCTS: {
      const newArray = state.wishlistItems.filter(element => element.variantId !== action.productId),
        number = newArray.length;
      return {
        ...state,
        wishlistItems: newArray,
        amountOfAllWishlistItems: number
      };
    }
    case ActionTypes.REMOVE_PRODUCT_FROM_LISTS: {
      const newArray = state.wishlistItems.filter(element => element.variantId !== action.productId);
      return {
        ...state,
        wishlistItems: newArray
      };
    }
    case ActionTypes.SET_AMOUNT_OF_ALL_WISHLIST_ITEMS:
      if (action.number === state.amountOfAllWishlistItems) {
        return state;
      }
      return {
        ...state,
        amountOfAllWishlistItems: action.number
      };
    case ActionTypes.EMPTY_WISHLIST:
      return {
        ...state,
        wishlistItems: [],
        amountOfAllWishlistItems: 0
      };
    case ActionTypes.ADD_NEW_LIST: {
      const newArray = JSON.parse(JSON.stringify(state));
      newArray.wishLists.push({ name: action.newList });

      return {
        ...state,
        wishLists: newArray.wishLists
      };
    }
    case ActionTypes.SET_WISH_LISTS: {
      if (!action.wishLists.length) {
        return state;
      }
      const numberList = action.wishLists.map(element => element.itemsCount),
        number = numberList.reduce((a, b) => a + b, 0);
      return {
        ...state,
        wishLists: action.wishLists,
        amountOfAllWishlistItems: number
      };
    }
    case ActionTypes.SET_CURRENT_WISHLIST_INDEX:
      return {
        ...state,
        currentWishListIndex: action.currentWishListIndex
      };
    case ActionTypes.REMOVE_WISHLIST: {
      const newArray = state.wishLists.filter(element => element.name !== action.wishListName),
        number = newArray.map(element => element.itemsCount).reduce((a, b) => a + b, 0);
      return {
        ...state,
        wishLists: newArray,
        amountOfAllWishlistItems: number
      };
    }
    case ActionTypes.SET_ERROR_CODE: {
      return {
        ...state,
        errorCode: action.errorCode
      };
    }

    case ActionTypes.SET_CURRENT_WISHLIST_NAME: {
      return {
        ...state,
        currentWishListName: action.currentWishListName
      };
    }

    case ActionTypes.SET_AMOUNT_OF_WISHLIST_ITEMS:
      return {
        ...state,
        amountOfAllWishlistItems: action.number
      };
    default:
      return state;
  }
};
