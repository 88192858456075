import { TAGS } from '../../lib/constants';
import {
  addDiscountCodeToCartMutation,
  addToCartMutation,
  createCartMutation,
  editCartItemsMutation,
  removeFromCartMutation
} from './mutations/cart';
import { getCartQuery } from './queries/cart';
import {
  Cart,
  CartItem,
  Connection,
  ShopProduct,
  ShopProductVariant,
  ShopifyAddToCartOperation,
  ShopifyCart,
  ShopifyCartDiscountCodesUpdateOperation,
  ShopifyCartItem,
  ShopifyCartOperation,
  ShopifyCreateCartOperation,
  ShopifyDiscountCodeError,
  ShopifyLineError,
  ShopifyProduct,
  ShopifyRemoveFromCartOperation,
  ShopifyUpdateCartOperation,
  CartItemAttribute,
  ShopifyUpdateCartOperationLine
} from './types';
import { shopifyFetch } from './shopifyFetch';
import { pushAddToCartEvent } from './analytics/addToCart';
import { pushRemoveFromCartEvent } from './analytics/removeFromCart';
import { applyDiscountToCart } from './bundleHelpers';

export const removeEdgesAndNodes = <T>(array: Connection<T>): T[] => {
  if (array) {
    return array.edges.map(edge => edge?.node);
  }
  return [];
};

export const reshapeCart = (cart: ShopifyCart): Cart => {
  if (!cart.cost?.totalTaxAmount) {
    cart.cost.totalTaxAmount = {
      amount: '0.0',
      currencyCode: 'USD'
    };
  }

  const shopifyLines = removeEdgesAndNodes<ShopifyCartItem>(cart.lines);

  const lines = shopifyLines.map(shopifyLine => {
    const line: CartItem = {
      ...shopifyLine,
      merchandise: {
        ...shopifyLine.merchandise,
        product: reshapeProduct(shopifyLine.merchandise.product)
      }
    };

    return line;
  });

  return {
    ...cart,
    lines
  };
};

// Use this for preparing the product with the correct data
export const reshapeProduct = (product: ShopifyProduct): ShopProduct => {
  const { variants, ...rest } = product;

  return {
    ...rest,
    deliveryTime: product.deliveryTime?.value ?? '',
    variants: removeEdgesAndNodes<ShopProductVariant>(variants)
  };
};

export const reshapeProducts = (products: ShopifyProduct[]): ShopProduct[] => {
  const reshapedProducts = [];

  for (const product of products) {
    if (product) {
      const reshapedProduct = reshapeProduct(product);

      if (reshapedProduct) {
        reshapedProducts.push(reshapedProduct);
      }
    }
  }

  return reshapedProducts;
};

export async function createCart(): Promise<Cart> {
  const res = await shopifyFetch<ShopifyCreateCartOperation>({
    query: createCartMutation(),
    cache: 'no-store'
  });

  return reshapeCart(res.body.data.cartCreate.cart);
}

export async function addToCart(
  cartId: string,
  lines: { merchandiseId: string; quantity: number; attributes: CartItemAttribute[] }[]
): Promise<{
  cart: Cart;
  userErrors: ShopifyLineError[];
}> {
  const res = await shopifyFetch<ShopifyAddToCartOperation>({
    query: addToCartMutation(),
    variables: {
      cartId,
      lines
    },
    cache: 'no-store'
  });

  if (res.status === 200) {
    pushAddToCartEvent(reshapeCart(res.body.data.cartLinesAdd.cart), lines);
  }

  return {
    cart: reshapeCart(res.body.data.cartLinesAdd.cart),
    userErrors: res.body.data.cartLinesAdd.userErrors ?? []
  };
}

export async function removeFromCart(cart: Cart, lineIds: string[], relatedBundle?: string): Promise<Cart> {
  const res = await shopifyFetch<ShopifyRemoveFromCartOperation>({
    query: removeFromCartMutation(),
    variables: {
      cartId: cart.id,
      lineIds
    },
    cache: 'no-store'
  });

  if (res.status === 200) {
    pushRemoveFromCartEvent(cart, lineIds);
  }

  // If we removed a product item, which belongs to a bundle, we need to cleanup the bundle attributes
  if (relatedBundle) {
    const { cart } = res.body.data.cartLinesRemove;
    const updateLines: ShopifyUpdateCartOperationLine[] = [];

    // Iterate over all the cart lines, and remove a matching bundle name from the attributes
    cart.lines.edges.forEach(edge => {
      const line = edge?.node;
      const lineRelatedBundle = line?._leisterRelatedBundle?.value;

      // If the line has the same bundle name, we should remove the attribute
      if (relatedBundle === lineRelatedBundle) {
        updateLines.push({
          id: line.id,
          merchandiseId: line.merchandise.id,
          quantity: line.quantity,
          attributes: line.attributes.filter(attribute => attribute.key !== '_leisterRelatedBundle')
        });
      }
    });

    const attributeCleanupResponse = await updateCart(cart.id, updateLines);

    const { cart: cleanedUpCart } = await applyDiscountToCart(attributeCleanupResponse.cart, relatedBundle, true);

    return cleanedUpCart;
  }

  return reshapeCart(res.body.data.cartLinesRemove.cart);
}

export async function updateCart(
  cartId: string,
  lines: ShopifyUpdateCartOperationLine[]
): Promise<{
  cart: Cart;
  userErrors: ShopifyLineError[];
}> {
  const res = await shopifyFetch<ShopifyUpdateCartOperation>({
    query: editCartItemsMutation(),
    variables: {
      cartId,
      lines
    },
    cache: 'no-store'
  });

  return {
    cart: reshapeCart(res.body.data.cartLinesUpdate.cart),
    userErrors: res.body.data.cartLinesUpdate.userErrors ?? []
  };
}

export async function getCart(cartId: string | null): Promise<Cart | undefined> {
  if (cartId === null) {
    return undefined;
  }

  const res = await shopifyFetch<ShopifyCartOperation>({
    query: getCartQuery(),
    variables: { cartId },
    tags: [TAGS.cart],
    cache: 'no-store'
  });

  // Old carts becomes `null` when you checkout.
  if (!res.body?.data?.cart) {
    return undefined;
  }

  return reshapeCart(res.body.data.cart);
}

export async function setDiscountCodesOnCart(
  cartId: string,
  discountCodes: string[]
): Promise<{
  cart: Cart;
  userErrors: ShopifyDiscountCodeError[];
}> {
  const res = await shopifyFetch<ShopifyCartDiscountCodesUpdateOperation>({
    query: addDiscountCodeToCartMutation(),
    variables: {
      cartId,
      discountCodes
    },
    cache: 'no-store'
  });

  return {
    cart: reshapeCart(res.body.data.cartDiscountCodesUpdate.cart),
    userErrors: res.body.data.cartDiscountCodesUpdate.userErrors ?? []
  };
}
