import React, { Fragment, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import StockInformation from '../ProductAvailability/StockInformation/StockInformation';
import { ShopProduct } from '../lib/shopify/types';
import DeliveryTime from '../ProductAvailability/DeliveryTime/DeliveryTime';
import classNames from 'classnames';

type AvailabilityTooltipProps = {
  shopProduct: ShopProduct;
};

const timeoutDuration = 120;

const AvailabilityTooltip = ({ shopProduct }: AvailabilityTooltipProps): JSX.Element | null => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const timeOutRef = useRef<ReturnType<typeof setTimeout>>();

  const shopProductVariant = shopProduct.variants[0];

  const isCurrentlyNotInStock = shopProductVariant?.currentlyNotInStock && shopProductVariant?.availableForSale;
  const isAvailable = shopProductVariant?.availableForSale;
  const isNotAvailable = !shopProductVariant?.availableForSale && !shopProductVariant?.currentlyNotInStock;

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    if (!isOpen) {
      triggerRef.current?.click();
    }
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      if (isOpen && document.activeElement === triggerRef.current) {
        triggerRef.current?.click();
      }
    }, timeoutDuration);
  };

  return (
    <Popover
      className={classNames('AvailabilityTooltip', {
        'AvailabilityTooltip--available': isAvailable,
        'AvailabilityTooltip--unavailable': isNotAvailable,
        'AvailabilityTooltip--out-of-stock': isCurrentlyNotInStock
      })}
    >
      {({ open }) => (
        <div
          className='AvailabilityTooltip__wrapper'
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button className='AvailabilityTooltip__trigger' ref={triggerRef}>
            <span className='visually-hidden'>Show availability details</span>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter='AvailabilityTooltip--enter'
            enterFrom='AvailabilityTooltip--enter-from'
            enterTo='AvailabilityTooltip--enter-to'
            leave='AvailabilityTooltip--leave'
            leaveFrom='AvailabilityTooltip--leave-from'
            leaveTo='AvailabilityTooltip--leave-to'
          >
            <Popover.Panel className='AvailabilityTooltip__content'>
              <StockInformation product={shopProduct} />
              <DeliveryTime product={shopProduct} hideLabel />
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

export default AvailabilityTooltip;
