import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  initialDeviceList: [],
  currentDeviceList: [],
  numberOfDevices: null,
  newAddedDevicesList: [],
  errorAtAddingDevice: null,
  deviceInfo: null
};

/**
 * @method myDevices
 * @description Reducer used to update current state based on action.
 * @param {object} The current state.
 * @param {object} The item that should be added/updated on state.
 * @return {object} Represent the updated state
 */

export const myDevices = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INITIAL_DEVICE_LIST:
      return {
        ...state,
        initialDeviceList: action.initialDeviceList,
        currentDeviceList: action.initialDeviceList
      };
    case actionTypes.SET_CURRENT_DEVICE_LIST:
      return {
        ...state,
        currentDeviceList: action.currentDeviceList
      };
    case actionTypes.DELETE_DEVICE_FROM_REDUX: {
      const newInitialDeviceList = state.initialDeviceList.filter(
          item => item.articleNumber !== action.articleNumber || item.serialNumber !== action.serialNumber
        ),
        newCurrentDeviceList = state.currentDeviceList.filter(
          item => item.articleNumber !== action.articleNumber || item.serialNumber !== action.serialNumber
        ),
        newNumberOfDevices = state.numberOfDevices && state.numberOfDevices >= 1 ? state.numberOfDevices - 1 : null;
      return {
        ...state,
        initialDeviceList: newInitialDeviceList,
        currentDeviceList: newCurrentDeviceList,
        numberOfDevices: newNumberOfDevices
      };
    }
    case actionTypes.SET_NUMBER_OF_DEVICES:
      return {
        ...state,
        numberOfDevices: action.numberOfDevices
      };

    case actionTypes.SET_NEW_ADDED_DEVICES_LIST: {
      return {
        ...state,
        newAddedDevicesList: [...state.newAddedDevicesList, action.newAddedDevicesList],
        errorAtAddingDevice: null
      };
    }
    case actionTypes.SET_DEVICE_ERROR_CODE:
      return {
        ...state,
        errorAtAddingDevice: action.errorStatus
      };
    case actionTypes.DELETE_DEVICE_FROM_NEW_ADDED_DEVICES: {
      const updatedNewAddedDevicesList = state.newAddedDevicesList.filter(
        item => item.articleNumber !== action.articleNumber || item.serialNumber !== action.serialNumber
      );
      return { ...state, newAddedDevicesList: updatedNewAddedDevicesList };
    }
    case actionTypes.CLEAR_NEW_ADDED_DEVICES_LIST:
      return {
        ...state,
        newAddedDevicesList: [],
        errorAtAddingDevice: null
      };
    case actionTypes.SET_DEVICE_INFO_TO_CONTACT_PAGE:
      return {
        ...state,
        deviceInfo: action.deviceInfo
      };

    default:
      return state;
  }
};
