import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  setAmountOfWishlistItemsAnonymousUser,
  getWishList,
  addProductToWishList
} from '../../../store/wishlist/actions';
import UserIcon from '../../Common/Icons/UserIcon';
import SearchIcon from '../../Common/Icons/SearchIcon';
import HeartIcon from '../../Common/Icons/HeartIcon';
import CloseIcon from '../../Common/Icons/CloseIcon';
import WishlistPopup from '../../Product/HeroActionButton/WishlistPopup';
import GeneralLink from '../../Common/GeneralLink';
import {
  WISHLIST,
  SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME,
  COUNTRY_CODE
} from '../../Common/CookieSettings/CookieConstants';
import { checkIfUserIsLoggedIn, logInRedirect } from '../../../utils/Utils';
import { toggleMobileMenu } from '../../../store/mobileMenu/actions';
import { toggleMobileMyAccount } from '../../../store/accountInformation/actions';
import { getCookie, deleteCookie } from '../../Common/CookieSettings/CookieUtils';
import { ActionType, OmniboxContext } from '../../Coveo/context/OmniboxContext.tsx';
import Cart from '../../Shopify/Cart';
import Cookies from 'js-cookie';
import { SITE_LEISTER_TECHNOLOGIES, renderShop } from '../../../AppGlobals';

/**
 * @class Component that renders the icon for the menu.
 *
 * Returns HTML.
 */
class IconsMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderShop: false
    };
  }

  static contextType = OmniboxContext;

  async componentDidMount() {
    const { sitecoreContext, getWishList, setAmountOfWishlistItemsAnonymousUser } = this.props;
    const { site } = sitecoreContext;
    const siteName = site?.name || SITE_LEISTER_TECHNOLOGIES;

    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      getWishList();
      this.getCookieWishlistToAccountWishlist();
    } else {
      setAmountOfWishlistItemsAnonymousUser(WISHLIST);
    }

    const countryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();
    const shopifyTestAccessKey = Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME);

    const shouldRenderShop = await renderShop(countryCode, shopifyTestAccessKey, siteName);

    this.setState({ shouldRenderShop });
  }

  // get Wishlist from cookies and added to account when user logs in
  getCookieWishlistToAccountWishlist() {
    const cookieWishlistData = getCookie(WISHLIST) ? JSON.parse(getCookie(WISHLIST)) : {},
      cookieWishlist = Object.keys(cookieWishlistData),
      { addProductToWishList, fields, getWishList } = this.props,
      { 'New Wishlist Label': newWishlist } = fields; // It will use this sitecore field to create a new wishlist with and put all products from the cookie wishlist in it
    if (cookieWishlist.length && newWishlist && newWishlist.value) {
      for (let i = 0; i < cookieWishlistData[cookieWishlist[0]].length; i++) {
        addProductToWishList(
          newWishlist.value,
          cookieWishlistData[cookieWishlist[0]][i].MasterProductName,
          cookieWishlistData[cookieWishlist[0]][i].VariantId
        );
      }
      getWishList();
      deleteCookie(WISHLIST);
    }
  }

  // Toggle the mobile my account menu.
  toggleMenu = () => {
    const { toggleMobileMyAccount, toggleMobileMenu, isMobileMenuOpen } = this.props;

    toggleMobileMyAccount();

    if (isMobileMenuOpen) {
      toggleMobileMenu();
    }
  };

  getMobileIcon = () => {
    if (this.props.isMobileMyAccountOpen) {
      return <CloseIcon />;
    }
    return <UserIcon color='red' />;
  };

  getBodyScroll() {
    const bodyElement = typeof document !== 'undefined' ? document.querySelector('body') : null;
    if (bodyElement && window.getComputedStyle(bodyElement).overflow === 'hidden') {
      bodyElement.style.overflow = 'auto';
    }
  }

  // Display the user icon depending if he is logged in or not logged in.
  showUserIcon = () => {
    const { sitecoreContext, isMobileMyAccountOpen } = this.props;

    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      const cssClass = classNames('IconsMenu-userSignedIn', {
        MobileMyAccountOpen: isMobileMyAccountOpen
      });

      return (
        <React.Fragment>
          <Visible lg xl>
            {this.getBodyScroll()}
            <GeneralLink className='IconsMenu-userSignedIn' fields={this.props.fields['MyAccount Link']}>
              <UserIcon color='red' />
            </GeneralLink>
          </Visible>
          <Visible xs sm md>
            <div
              className={cssClass}
              onClick={this.toggleMenu}
              onKeyDown={e => {
                if (e.keyCode === 13) this.toggleMenu();
              }}
              role='button'
              tabIndex='0'
            >
              {this.getMobileIcon()}
            </div>
          </Visible>
        </React.Fragment>
      );
    } else {
      return (
        <div
          className='IconsMenu-user'
          onClick={logInRedirect}
          onKeyDown={e => {
            if (e.keyCode === 13) logInRedirect();
          }}
          role='button'
          tabIndex='0'
        >
          <UserIcon color='black-80' />
        </div>
      );
    }
  };
  getWishlistItemsNumber() {
    const { amountOfAllWishlistItems } = this.props,
      number = amountOfAllWishlistItems > 9 ? '9+' : amountOfAllWishlistItems;
    if (amountOfAllWishlistItems > 0) {
      return <span className='IconsMenu-heart-number'>{number}</span>;
    }
  }

  onSearchIconClick = () => {
    this.context.dispatch({ type: ActionType.TOGGLE_SEARCH_BAR });
  };

  render() {
    const { isOmniboxBoxOpened } = this.context.state;
    const { showMyAccount, rendering, showFavorites, menuIcon } = this.props;
    return (
      <div className='IconsMenu'>
        {showMyAccount && showMyAccount.value ? this.showUserIcon() : null}
        <Placeholder name='jss-my-account-flyout' rendering={rendering} />
        {showFavorites && showFavorites.value ? (
          <div className='IconsMenu-heart'>
            <GeneralLink fields={this.props.fields['Wishlist Link']}>
              <HeartIcon />
              {this.getWishlistItemsNumber()}
            </GeneralLink>
            <WishlistPopup {...rendering} />
          </div>
        ) : null}
        <div
          className='IconsMenu-search'
          onClick={() => {
            this.onSearchIconClick();
          }}
          onKeyDown={e => {
            if (e.target === 13) this.onSearchIconClick();
          }}
          role='button'
          tabIndex='0'
        >
          {isOmniboxBoxOpened ? <CloseIcon /> : <SearchIcon />}
        </div>
        {this.state.shouldRenderShop ? <Cart /> : null}
        {menuIcon}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMobileMyAccountOpen: state.accountInformation.isMobileMyAccountOpen,
    amountOfAllWishlistItems: state.wishlistProducts && state.wishlistProducts.amountOfAllWishlistItems,
    isMobileMenuOpen: state.mobileMenu.isMobileMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleMobileMyAccount: () => dispatch(toggleMobileMyAccount()),
    setAmountOfWishlistItemsAnonymousUser: wishlistCookie =>
      dispatch(setAmountOfWishlistItemsAnonymousUser(wishlistCookie)),
    getWishList: () => dispatch(getWishList()),
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
    addProductToWishList: (listName, prodName, variantId) =>
      dispatch(addProductToWishList(listName, prodName, variantId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(IconsMenu));
