import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../../Common/GeneralLink/index';
import { removeWhiteSpaces, getSmallestImageCrop } from '../../../../utils/Utils';

class ColumnCategoryItem extends React.Component {
  render() {
    if (!this.props.fields) {
      return <div className='ColumnCategoryItem' />;
    }
    const { Image: Img, Link, 'Shared Title': sharedTitle, 'Button Text': buttonText } = this.props.fields,
      smallestImg = getSmallestImageCrop(Img.value.srcSet);

    return (
      <div className='ColumnCategoryItem'>
        <div className='ColumnCategoryItem-Image'>
          <img
            src={smallestImg ? smallestImg : Img.value.src + '?imwidth=100'}
            alt={Img.value.alt ? Img.value.alt : ''}
            loading='lazy'
          />
        </div>
        <GeneralLink
          fields={Link}
          className='ColumnCategoryItem-Link'
          clickClassName={removeWhiteSpaces(sharedTitle.value || null) + ' click_content_block_category_three_column'}
        >
          <Text field={buttonText} />
        </GeneralLink>
      </div>
    );
  }
}

export default ColumnCategoryItem;
