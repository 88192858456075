import React from 'react';
import i18n from 'i18next';
import { ShopProduct } from '../../lib/shopify/types';
import Truck from '../../../Common/Icons/Truck';

interface Props {
  product: ShopProduct;
  hideLabel?: boolean;
}

type DeliverTimeState = 'short' | 'middle' | 'long' | 'unknown';

export const DeliveryTimeShopifyValueShort = 'Lieferzeit 1-4 Werktage';
export const DeliveryTimeShopifyValueMiddle = 'Lieferzeit 4-10 Werktage';
export const DeliveryTimeShopifyValueLong = 'voraussichtlich längere Lieferzeit';

const DeliveryTime: React.FC<Props> = ({ product, hideLabel = false }): JSX.Element | null => {
  let content = null;

  function getDeliverTimeState(): DeliverTimeState {
    if (product.deliveryTime === DeliveryTimeShopifyValueShort) {
      return 'short';
    } else if (product.deliveryTime === DeliveryTimeShopifyValueMiddle) {
      return 'middle';
    } else if (product.deliveryTime === DeliveryTimeShopifyValueLong) {
      return 'long';
    }
    return 'unknown';
  }

  const deliverTimeState = getDeliverTimeState();

  if (deliverTimeState === 'short') {
    content = (
      <div className='DeliveryTime DeliveryTime--Short'>
        <Truck color='green' />
        {!hideLabel && <span className='DeliveryTime__Label'>{i18n.t('SHOP | Delivery time label')}</span>}
        <span className='DeliveryTime__Value'>{i18n.t('SHOP | Delivery time 1-4 working days')}</span>
      </div>
    );
  } else if (deliverTimeState === 'middle') {
    content = (
      <div className='DeliveryTime DeliveryTime--Middle'>
        <Truck color='orange' />
        {!hideLabel && <span className='DeliveryTime__Label'>{i18n.t('SHOP | Delivery time label')}</span>}
        <span className='DeliveryTime__Value'>{i18n.t('SHOP | Delivery time 4-10 working days')}</span>
      </div>
    );
  } else if (deliverTimeState === 'long') {
    content = (
      <div className='DeliveryTime DeliveryTime--Long'>
        <Truck color='red' />
        {!hideLabel && <span className='DeliveryTime__Label'>{i18n.t('SHOP | Delivery time label')}</span>}
        <span className='DeliveryTime__Value'>{i18n.t('SHOP | Delivery time longer')}</span>
      </div>
    );
  } else {
    content = null;
  }

  return content;
};

export default DeliveryTime;
