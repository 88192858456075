import React from 'react';
import ChevronDown from '../Icons/ChevronDown';
import ChevronUp from '../Icons/ChevronUp';
import { IconPropsOnIconWrapper } from '../Icon';

export type UpDownProps = IconPropsOnIconWrapper & { isUp: boolean };

const UpDown = (props: UpDownProps): JSX.Element => {
  const { isUp, ...rest } = props;
  return isUp ? <ChevronUp {...rest} color='gray' /> : <ChevronDown {...rest} color='gray' />;
};

export default UpDown;
