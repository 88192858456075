import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../../SearchResultList';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { getCoveoProductsSource } from '../../../../../AppGlobals';
import FacetsModal from '../../../FacetsModal';
import FacetManager from '../../../FacetManager';
import { useCoveoQuery } from '../../../CoveoQuery';
import { ActionType, SearchContext } from '../../../context/SearchContext';
import { buildNumericFacet, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import RangeFacet from '../../../RangeFacet';
import CategorFacet from '../../../CategoryFacet';
import { AccessoriesFacets, CateogryFacet, AccessoryCategoryFacet } from './WeldyAccessoriesLister.settings';
import ImageFacet from '../../../ImageFacet';
import AccessoriesOmnibox from '../../../AccessoriesOmnibox';
import { getHashParam, updateHashParam } from '../../../UrlManager';
import FloatingFilterToggle from '../../../FloatingFilterToggle';
import { CoveoContext } from '../../../context/CoveoContext';
import { useViewportSize } from '../../../../../hooks/useViewportSize';
import { FacetStickyWrapper } from '../../../FacetStickyWrapper/FacetStickyWrapper';

type AccessoriesListerProp = {
  fields: any;
};

export const WeldyAccessoriesListerComponent: React.FC<AccessoriesListerProp> = props => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);
  const { state: searchState, dispatch } = useContext(SearchContext);
  const coveoProductSource = getCoveoProductsSource();
  const [isQuickFilterOptionSelected, setIsQuickFilterOptionSelected] = useState<string | null>(null);
  const { viewportIsMobile } = useViewportSize();

  const advancedQuery = `@source=="${coveoProductSource}" AND @z95xlanguage=="${locale}" AND NOT @isphasedout=="true"`;

  const { addAdvancedQuery, buildFacetController, querySummaryController, breadcrumbManagerController } = useCoveoQuery(
    {
      engine,
      cq: '@pimclassification=="Accessories" AND @tenant="Weldy"',
      aq: advancedQuery
    }
  );
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    const selectedAccessory = getHashParam('accessory') || '';

    updateAccessoryQuery(selectedAccessory);

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine, querySummaryController]);

  const selectAccessory = (selectedAccessory: string, selectedAccessoryName: string) => {
    updateAccessoryQuery(selectedAccessory);

    updateHashParam('accessory', selectedAccessory);
    updateHashParam('accessoryName', selectedAccessoryName);
  };

  const updateAccessoryQuery = (selectedAccessory: string) => {
    const accessoryQuery = selectedAccessory ? `(@pimcompatibleproducts==${selectedAccessory}) AND ` : '';

    addAdvancedQuery({
      aq: `${accessoryQuery}${advancedQuery}`
    });

    const { logTriggerExecute } = loadSearchAnalyticsActions(engine);
    const { executeSearch } = loadSearchActions(engine);

    engine.dispatch(executeSearch(logTriggerExecute()));
  };

  const imageFacetController = buildFacetController(CateogryFacet.field, AccessoryCategoryFacet);

  useEffect(() => {
    const updatedIsQuickFilterOptionSelected =
      imageFacetController.state.values.find(value => imageFacetController.isValueSelected(value))?.value || null;

    if (updatedIsQuickFilterOptionSelected !== isQuickFilterOptionSelected) {
      setIsQuickFilterOptionSelected(updatedIsQuickFilterOptionSelected);

      if (!viewportIsMobile) {
        dispatch({
          type: ActionType.OPEN_FACETS
        });
      }
    }
  }, [imageFacetController, isQuickFilterOptionSelected, dispatch, viewportIsMobile]);

  useEffect(() => {
    const yOffset = viewportIsMobile ? 0 : -70; // adjust header height
    const element = document.querySelector('.FacetStickyWrapper');
    const y = (element?.getBoundingClientRect()?.top || 0) + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }, [isQuickFilterOptionSelected, viewportIsMobile]);

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <div className='SearchResults__Main'>
        {isQuickFilterOptionSelected ? <FloatingFilterToggle /> : null}
        <AccessoriesOmnibox selectAccessory={selectAccessory} />
        <ImageFacet controller={imageFacetController} fields={props.fields} />
        <FacetStickyWrapper>
          <div
            className={classNames('SearchResults__Filters', {
              'SearchResults__Filters--visible': isQuickFilterOptionSelected
            })}
          >
            <FacetsModal breadcrumbManagerController={breadcrumbManagerController} />
          </div>
          <FacetManager engine={engine} hasResults={state.hasResults || state.firstSearchExecuted}>
            {AccessoriesFacets.map(facet => {
              const isVisible = facet?.categories?.includes(
                imageFacetController.state.values.find(value => value.state === 'selected')?.value || ''
              );

              if (facet.numeric) {
                const facetController = buildNumericFacet(engine, {
                  options: {
                    field: facet.field,
                    generateAutomaticRanges: true,
                    facetId: facet.field,
                    injectionDepth: 1000,
                    numberOfValues: 5
                  }
                });

                return (
                  <RangeFacet
                    key={facet.field}
                    controller={facetController}
                    title={facet.label()}
                    id={facet.field}
                    precision={facet.precision}
                    unit={facet.unit || ''}
                    isVisible={isVisible}
                  />
                );
              }
              const facetController = buildFacetController(facet.field);

              return (
                <CategorFacet
                  needToHaveMinimumTwoOptions={true}
                  key={facet.field}
                  multi={facet.multi !== false}
                  controller={facetController}
                  title={facet.label()}
                  id={facet.field}
                  isVisible={isVisible}
                />
              );
            })}
          </FacetManager>
        </FacetStickyWrapper>
        {(state.hasResults || state.firstSearchExecuted) && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList
          engine={engine}
          layout={searchState.layout}
          query={state.query}
          siteName={siteName}
          language={locale}
          noResultsText={i18n.t('SEARCH | Accessories No Results')}
        />
      </div>
    </div>
  );
};

export default WeldyAccessoriesListerComponent;
