import * as ActionTypes from './actionTypes';
import { getCookie } from '../../components/Common/CookieSettings/CookieUtils';
import { CONFLICT_ERROR_CODE, FORBIDDEN_STATUS_CODE, apiGatewayHost } from '../../Constants/General';
import { logOutRedirect } from '../../utils/Utils';
import { getSitecoreApiHost } from '../../AppGlobals';
import { WISHLIST, LANG } from '../../components/Common/CookieSettings/CookieConstants';

export const addNewList = listName => {
  return dispatch => {
    return fetch(`${getSitecoreApiHost()}/api/Wishlist/addWishlist?listName=${listName}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain,/',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(response => {
        if (response === CONFLICT_ERROR_CODE) {
          dispatch(setNewListErrorCode(CONFLICT_ERROR_CODE));
        } else {
          dispatch(getWishList());
          dispatch(setNewListErrorCode(null));
        }
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const getWishList = () => {
  return dispatch => {
    const currentLang = getCookie(LANG);
    return fetch(`${getSitecoreApiHost()}/api/Wishlist/getWishlists?language=${currentLang}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain,/',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setWishLists(responseJson));
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const deleteWishList = listName => {
  return dispatch => {
    fetch(`${getSitecoreApiHost()}/api/Wishlist/deleteWishlist?listName=${listName}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain,/',
        'Content-Type': 'application/json'
      }
    })
      .then(() => {
        dispatch(removeWishList(listName));
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const addProductToWishList = (listName, productName, variantId) => {
  return dispatch => {
    fetch(
      `${getSitecoreApiHost()}/api/Wishlist/addProductToWishlist?listName=${listName}&masterProductName=${productName}&variantId=${variantId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain,/',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setAmountOfWishlistItems(responseJson));
      })

      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

/**
 * We needed to load further information of cookie data.
 * We don't need it for loading items when the user is logged in - getWishList will load all items completely
 * But if the user is anonymous, and he uses the cookies, we don't have all the information in there.
 */
export const getWishlistProducts = (data, tenant) => {
  const currentLang = getCookie(LANG);
  return dispatch => {
    // Get the items of the wishlists:
    data.forEach(async list => {
      await fetch(`${apiGatewayHost}/product-data/v1/product/wishlistObjects?tenant=${tenant}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain,/',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ items: list.items, lang: currentLang })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Something went wrong');
          }
        })
        .then(responseJson => {
          dispatch(setWishlistProducts(responseJson, list.name));
        })
        .catch(error => {
          throw new Error('Something went wrong');
        });
    });
  };
};

export const deleteProductFromWishList = (listName, productName, variantId) => {
  return dispatch => {
    fetch(
      `${getSitecoreApiHost()}/api/Wishlist/deleteProductFromWishlist?listName=${listName}&masterProductName=${productName}&variantId=${variantId}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain,/',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(removeProductFromLists(variantId));
        dispatch(setAmountOfWishlistItems(responseJson));
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const renameList = (oldName, newName) => {
  return dispatch => {
    return fetch(`${getSitecoreApiHost()}/api/Wishlist/editWishlist?oldListName=${oldName}&newListName=${newName}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain,/',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === FORBIDDEN_STATUS_CODE) {
          logOutRedirect();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(response => {
        if (response === CONFLICT_ERROR_CODE) {
          dispatch(setNewListErrorCode(CONFLICT_ERROR_CODE));
        } else {
          dispatch(getWishList());
          dispatch(setNewListErrorCode(null));
        }
      })
      .catch(error => {
        throw new Error('Something went wrong');
      });
  };
};

export const toggleWishlist = () => {
  return {
    type: ActionTypes.TOGGLE_WISHLIST_FLYOUT
  };
};

// Set the wishlist items for a specific list
// Called for the anonymous user to extend the items based on the cookie information
export const setWishlistProducts = (resultJson, listName) => {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_WISHLIST_ITEMS,
      wishlistItems: resultJson,
      listName
    });
    dispatch(setCurrentWishlistItems());
  };
};

// Sets the wishlist and its items for the anonymous user, if undefined items, we use the cookie data
export const setWishlistProductsAnonymous = (wishlistName, customWishlistItems, tenant) => {
  return dispatch => {
    const cookieWishlistData = getCookie(WISHLIST) ? JSON.parse(getCookie(WISHLIST)) : {};
    const cookieData = cookieWishlistData[Object.keys(cookieWishlistData)[0]];
    const items = customWishlistItems ?? cookieData ?? [];
    let anonymousUserWishlist = {
      name: wishlistName,
      addDate: '',
      items: items,
      itemsCount: items.length,
      isDefault: true
    };
    if (!customWishlistItems && cookieData) {
      // Fix rendering issues because of incomplete data
      const newList = anonymousUserWishlist.items.map(item => {
        return { ...item, articleNo: `${item.VariantId ?? ''}`, masterProductNameEn: '' };
      });
      anonymousUserWishlist.items = newList;
    }
    dispatch(setWishListsAnonymous([anonymousUserWishlist]));
    // NOTE: We need to enrich the cookie saved items. They are not completely filled with all data
    dispatch(getWishlistProducts([anonymousUserWishlist], tenant));
  };
};

export const removeProduct = productId => {
  return {
    type: ActionTypes.REMOVE_PRODUCTS,
    productId
  };
};

export const removeProductFromLists = productId => {
  return {
    type: ActionTypes.REMOVE_PRODUCT_FROM_LISTS,
    productId
  };
};

export const setAmountOfWishlistItemsAnonymousUser = wishlistCookie => {
  const wishlist = JSON.parse(getCookie(wishlistCookie)),
    number = wishlist && Object.keys(wishlist).length > 0 ? wishlist[Object.keys(wishlist)[0]].length : 0;
  return {
    type: ActionTypes.SET_AMOUNT_OF_ALL_WISHLIST_ITEMS,
    number
  };
};

export const emptyWishlist = () => {
  return {
    type: ActionTypes.EMPTY_WISHLIST
  };
};

export const setNewList = listName => {
  return {
    type: ActionTypes.ADD_NEW_LIST,
    newList: listName
  };
};

export const setWishLists = wishLists => {
  return {
    type: ActionTypes.SET_WISH_LISTS,
    wishLists
  };
};

export const setWishListsAnonymous = wishLists => {
  return {
    type: ActionTypes.SET_WISH_LISTS_ANONYMOUS,
    wishLists
  };
};

export const setCurrentWishlistItems = () => {
  return {
    type: ActionTypes.SET_CURRENT_WISHLIST_ITEMS
  };
};

export const setCurrentWishListIndex = index => {
  return {
    type: ActionTypes.SET_CURRENT_WISHLIST_INDEX,
    currentWishListIndex: index
  };
};

// When the wishlist name is set, we also want to update the current wishlistItems
export const setCurrentWishListName = name => {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_CURRENT_WISHLIST_NAME,
      currentWishListName: name
    });
    dispatch(setCurrentWishlistItems());
  };
};

export const removeWishList = listName => {
  return {
    type: ActionTypes.REMOVE_WISHLIST,
    wishListName: listName
  };
};

export const setNewListErrorCode = errorCode => {
  return {
    type: ActionTypes.SET_ERROR_CODE,
    errorCode
  };
};

export const setAmountOfWishlistItems = number => {
  return {
    type: ActionTypes.SET_AMOUNT_OF_WISHLIST_ITEMS,
    number
  };
};
