import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsLaserGasDetectionPipelines,
  CoveoProductsLaserGasDetectionSearchHubs
} from './LaserGasDetectionLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import LaserGasDetectionListerComponent from './LaserGasDetectionLister';

export const LaserGasDetectionLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsLaserGasDetectionPipelines[siteName],
    searchHub: CoveoProductsLaserGasDetectionSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <LaserGasDetectionListerComponent />
    </CoveoComponent>
  );
};

export default LaserGasDetectionLister;
