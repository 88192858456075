import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import Checkmark from '../../../Common/Icons/Checkmark';
/**
 * Component that renders  on item with text and checkmark
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

const CheckmarksItem = props => {
  if (!props.fields) {
    return <div className='CheckmarksItem' />;
  }
  const { Text } = props.fields;
  return (
    <div className='CheckmarksItem'>
      <Checkmark />
      <RichText field={Text} className='CheckmarksItem-Text' />
    </div>
  );
};

export default CheckmarksItem;
