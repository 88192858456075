import React from 'react';
import { Visible } from 'react-grid-system';

/**
 * @class Component that renders simple space component
 *
 * @param {object} props Object containing the Text (for RichText)
 *
 * @returns jsx
 */
class SpaceComponent extends React.Component {
  getContent(mobile, tablet, desktop, color) {
    return (
      <React.Fragment>
        <Visible lg xl>
          <div className='SpaceComponent whiteBackground' style={{ height: desktop, backgroundColor: color }} />
        </Visible>
        <Visible md>
          <div className='SpaceComponent  whiteBackground' style={{ height: tablet, backgroundColor: color }} />
        </Visible>
        <Visible sm>
          <div className='SpaceComponent  whiteBackground' style={{ height: mobile, backgroundColor: color }} />
        </Visible>
      </React.Fragment>
    );
  }

  render() {
    if (!this.props.fields) {
      return <div className='SpaceComponent' />;
    }
    const { DesktopHeight, TabletHeight, MobileHeight, BackgroundColor } = this.props.fields,
      color =
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
          ? BackgroundColor.fields.ColorCode.value
          : null,
      desktop = DesktopHeight && DesktopHeight.value !== '' ? DesktopHeight.value + 'px' : null,
      tablet = TabletHeight && TabletHeight.value !== '' ? TabletHeight.value + 'px' : null,
      mobile = MobileHeight && MobileHeight.value !== '' ? MobileHeight.value + 'px' : null;

    return <React.Fragment>{this.getContent(mobile, tablet, desktop, color)}</React.Fragment>;
  }
}

export default SpaceComponent;
