import React, { createContext, useState, useEffect, ReactNode } from 'react';

export type ViewportSize = 'mobile' | 'tablet' | 'desktop';

export const DefaultViewport: ViewportSize = 'desktop';

export interface ViewportContextState {
  viewportSize: ViewportSize;
  viewportIsMobile: boolean;
  viewportIsTablet: boolean;
  viewportIsDesktop: boolean;
}

const DefaultViewportContextState: ViewportContextState = {
  viewportSize: DefaultViewport,
  viewportIsMobile: false,
  viewportIsTablet: false,
  viewportIsDesktop: true
};

export const ViewportContext = createContext<ViewportContextState>(DefaultViewportContextState);

export const ViewportProvider = ({ children }: { children: ReactNode }) => {
  const [viewport, setViewport] = useState<ViewportContextState>(DefaultViewportContextState);

  useEffect(() => {
    function handleResize() {
      const newViewportSize: ViewportSize =
        window.innerWidth <= 768 ? 'mobile' : window.innerWidth <= 960 ? 'tablet' : 'desktop';
      const newViewport = {
        viewportSize: newViewportSize,
        viewportIsMobile: newViewportSize === 'mobile',
        viewportIsTablet: newViewportSize === 'tablet',
        viewportIsDesktop: newViewportSize === 'desktop'
      };

      setViewport(prevViewport => {
        if (newViewport.viewportSize !== prevViewport.viewportSize) {
          return newViewport;
        }
        return prevViewport;
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ViewportContext.Provider value={viewport}>{children}</ViewportContext.Provider>;
};
