import React, { useEffect, useState } from 'react';
import { Image, Text, RichText, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import EmailGreyIcon from '../../Common/Icons/EmailGreyIcon';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { renderDateString } from '../../../utils/Date';
import PageBackLink from '../PageBackLink';
import i18n from 'i18next';
import { TextField } from '../../../utils/types';

export const UniversalStoryHero = (props: any) => {
  const { fields: componentFields, sitecoreContext, rendering } = props;
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    const currentLink = window.location.href;

    setCurrentLink(currentLink);
  }, []);

  if (!sitecoreContext?.route?.fields) {
    return <div className='componentContainerHero' />;
  }

  const backFieldData = componentFields?.data?.getBackToUniversalStoryQuery || {};
  const { fields } = sitecoreContext?.route;
  const {
    'Story Category': category,
    'Story Date': date,
    'Story Text': leadText,
    'Story Image': img,
    Title,
    'Show Email Share': showEmailShare
  } = fields;
  const backLinkText: TextField = { value: i18n.t('STORY | Back to') };
  const categoryText = category?.fields?.['Category Tag'];
  const renderingDate = renderDateString(date.value, getLanguage(sitecoreContext));

  return (
    <div className='componentContainerHero'>
      <div className='UniversalStoryHero'>
        <PageBackLink backFieldData={backFieldData} buttonText={backLinkText} />
        <Text className='UniversalStoryHero__Title' tag='h1' field={Title} />
        <Image className='UniversalStoryHero__Image' field={img} editable={false} />

        <div className='UniversalStoryHero__SocialLinkWrapper'>
          <Placeholder name='jss-share-social-link' rendering={rendering} />
          {showEmailShare.value ? (
            <a
              href={`mailto:?subject=${Title.value}&body=${Title.value}%0D%0A${currentLink}`}
              className='UniversalStoryHero__EmailShare click_button_share'
              target='blank'
            >
              <EmailGreyIcon />
            </a>
          ) : null}
        </div>
        <div className='UniversalStoryHero__CategoryWrapper'>
          <Text className='UniversalStoryHero__Category' tag='span' field={categoryText} />
          {date.value ? <span className='UniversalStoryHero__Date'>{renderingDate}</span> : null}
        </div>
        <RichText field={leadText} className='UniversalStoryHero__LeadText' />
      </div>
    </div>
  );
};

export default withSitecoreContext()(UniversalStoryHero);
