import React from 'react';
import { Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-grid-system';

const HeaderTableTeaser = props => (
  <div className='SimpleTableTeaser'>
    <Row nogutter className='SimpleTableTeaser-Header'>
      <Col xs={4}>
        <Text tag='div' className='SimpleTableTeaser-Header-Item' field={props.fields.HeadlineLeft} />
      </Col>
      <Col xs={4}>
        <Text tag='div' className='SimpleTableTeaser-Header-Item' field={props.fields.HeadlineMiddle} />
      </Col>
      <Col xs={4}>
        <Text tag='div' className='SimpleTableTeaser-Header-Item' field={props.fields.HeadlineRight} />
      </Col>
    </Row>
    <Placeholder name='table-raw' rendering={props.rendering} />
  </div>
);

export default HeaderTableTeaser;
