import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { CoveoProductsAccessoriesPipelines, CoveoProductsAccessoriesSearchHubs } from './AccessoriesLister.settings';
import AccessoriesListerComponent from './AccessoriesLister';
import CoveoComponent from '../../../CoveoComponent';

type AccessoriesListerProp = {
  fields: any;
};

export const AccessoriesLister: React.FC<AccessoriesListerProp> = props => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsAccessoriesPipelines[siteName],
    searchHub: CoveoProductsAccessoriesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <AccessoriesListerComponent {...props} />
    </CoveoComponent>
  );
};

export default AccessoriesLister;
