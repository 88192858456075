import React, { useContext } from 'react';
import SearchResultTab from '../SearchResultTab';
import ScrollSnap from '../../Common/ScrollSnap';
import { SearchContext } from '../context/SearchContext';

export type SearchResultTabsProps = {
  tabs: any;
};

export const SearchResultTabs = ({ tabs }: SearchResultTabsProps): JSX.Element => {
  const { state: searchState } = useContext(SearchContext);

  return (
    <ScrollSnap focusKey={searchState.activeTab}>
      <div className='SearchResultTabs'>
        {tabs.map((tab: any) => (
          <SearchResultTab
            key={tab.caption}
            id={tab.id}
            value={tab.value}
            caption={tab.caption}
            controller={tab.controller}
            formatCount={tab.formatCount}
          />
        ))}
      </div>
    </ScrollSnap>
  );
};

export default SearchResultTabs;
