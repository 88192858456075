import {
  SearchEngine,
  SortBy,
  buildBreadcrumbManager,
  buildFacet,
  buildInstantResults,
  buildQuerySummary,
  buildResultList,
  buildSearchBox,
  buildSort,
  buildStandaloneSearchBox,
  loadAdvancedSearchQueryActions
} from '@coveo/headless';
import { useCallback, useEffect, useMemo } from 'react';
import { useCookieConsent } from '../Common/CookieSettings/useCookieConsent';
import { bindUrlManager } from './UrlManager';

type CoveoQueryProps = {
  engine: SearchEngine;
  fieldsToInclude?: string[];
  redirectUrl?: string;
  aq?: string;
  cq?: string;
  initUrlManager?: boolean;
  facet?: {
    key: string;
    value: string;
  };
};

type CoveoQuery = {
  breadcrumbManagerController: ReturnType<typeof buildBreadcrumbManager>;
  buildFacetController: (field: string, id?: string) => ReturnType<typeof buildFacet>;
  querySummaryController: ReturnType<typeof buildQuerySummary>;
  resultListController: ReturnType<typeof buildResultList>;
  search: (query: string) => void;
  searchBoxController: ReturnType<typeof buildSearchBox>;
  standaloneSearchBoxController: ReturnType<typeof buildStandaloneSearchBox>;
  instantResultsController: ReturnType<typeof buildInstantResults>;
  addAdvancedQuery: (query: { cq?: string; aq?: string }) => void;
};

export const useCoveoQuery = ({
  engine,
  fieldsToInclude,
  redirectUrl,
  aq,
  cq,
  initUrlManager = true
}: CoveoQueryProps): CoveoQuery => {
  const sharedIdBetweenSearchboxAndInstantResult = 'sample-instant-results';
  const querySummaryController = useMemo(() => buildQuerySummary(engine), [engine]);
  const { isCookieConsentGiven } = useCookieConsent();

  useEffect(() => {
    const unbindUrlManager = initUrlManager ? bindUrlManager(engine) : () => undefined;
    const action = loadAdvancedSearchQueryActions(engine).registerAdvancedSearchQueries({
      aq: aq || '',
      cq: cq || ''
    });
    engine.dispatch(action);

    return unbindUrlManager;
  }, [engine, aq, cq, initUrlManager]);

  const breadcrumbManagerController = useMemo(() => buildBreadcrumbManager(engine), [engine]);

  useMemo(
    () =>
      buildSort(engine, {
        initialState: { criterion: { by: SortBy.Relevancy } }
      }),
    [engine]
  );

  const searchBoxController = useMemo(
    () =>
      buildSearchBox(engine, {
        options: {
          enableQuerySyntax: true,
          numberOfSuggestions: 0,
          id: sharedIdBetweenSearchboxAndInstantResult
        }
      }),
    [engine]
  );

  const standaloneSearchBoxController = useMemo(
    () =>
      buildStandaloneSearchBox(engine, {
        options: {
          redirectionUrl: redirectUrl || '/Search',
          enableQuerySyntax: true,
          numberOfSuggestions: 0,
          id: sharedIdBetweenSearchboxAndInstantResult
        }
      }),
    [engine, redirectUrl]
  );

  const instantResultsController = useMemo(
    () =>
      buildInstantResults(engine, {
        options: {
          maxResultsPerQuery: 5,
          searchBoxId: sharedIdBetweenSearchboxAndInstantResult
        }
      }),
    [engine]
  );

  const addAdvancedQuery = useCallback(
    (query: { cq?: string; aq?: string }) => {
      const AdvancedSearchQueryActionCreators = loadAdvancedSearchQueryActions(engine);
      const action = AdvancedSearchQueryActionCreators.registerAdvancedSearchQueries(query);
      engine.dispatch(action);
    },
    [engine]
  );

  const resultListController = useMemo(
    () =>
      buildResultList(engine, {
        options: {
          fieldsToInclude
        }
      }),
    [engine, fieldsToInclude]
  );

  const search = useCallback(
    query => {
      searchBoxController.updateText(query);
      searchBoxController.submit();
    },
    [searchBoxController]
  );

  // If user changes the cookie consent, enable or disable analytics on coveo engine
  useEffect(() => {
    if (isCookieConsentGiven) {
      engine.enableAnalytics();
    } else {
      engine.disableAnalytics();
    }
  }, [engine, isCookieConsentGiven]);

  const buildFacetController = (field: string, id?: string) =>
    buildFacet(engine, {
      options: {
        field,
        facetId: id || field,
        numberOfValues: 999,
        sortCriteria: 'alphanumeric'
      }
    });

  return {
    breadcrumbManagerController,
    buildFacetController,
    querySummaryController,
    standaloneSearchBoxController,
    resultListController,
    search,
    searchBoxController,
    instantResultsController,
    addAdvancedQuery
  };
};

export const sanitizeQuery = (query: string): string => {
  const sanitizedQuery = query.replace(/,/g, '');
  return sanitizedQuery;
};
