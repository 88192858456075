import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import { getCategoryDescriptionApi } from '../../../Constants/Product';

class ListingPageTitleWithDescription extends React.Component {
  state = {
    title: null,
    description: null
  };

  componentDidMount() {
    const { sitecoreContext } = this.props,
      language = getLanguage(this.props.sitecoreContext);

    if (sitecoreContext.route.name && language) {
      fetch(
        getCategoryDescriptionApi +
          sitecoreContext.route.name +
          '&lang=' +
          language +
          '&tenant=' +
          sitecoreContext.site.name,
        {
          method: 'GET',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'text/html'
          }
        }
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              'Failed to fetch category description from Product API. The server did not respond with 200. Response status: ' +
                response.status
            );
          }
        })
        .then(response => {
          this.setState({
            title: response.title || null,
            description: response.description || null
          });
        })
        .catch(err => {
          throw new Error(
            'Failed to fetch category description from Product API. The fetch operation failed: ' + err.message
          );
        });
    }
  }

  render() {
    const { title, description } = this.state;
    if (!title && !description) {
      return <div className='ListingPageTitleWithDescription-NoData' />;
    }
    return (
      <div className='ListingPageTitleWithDescription'>
        <h1 className='ListingPageTitleWithDescription-Title'>{title}</h1>
        <div className='ListingPageTitleWithDescription-Description'>{description}</div>
      </div>
    );
  }
}

export default withSitecoreContext()(ListingPageTitleWithDescription);
