import React from 'react';

/**
 * Maps field type IDs from Sitecore (/sitecore/system/Settings/Forms/Field Types)
 * into an implementing React component - this is very similar to the JSS componentFactory,
 * but it maps form element components instead of layout components
 */
class FieldFactory {
  constructor() {
    this._fieldMap = new Map();
    // eslint-disable-next-line react/display-name
    this._defaultComponent = props =>
      React.createElement(
        'div',
        { key: props.model.fieldTypeItemId },
        props.model.name,
        ': No renderer for form element type',
        ' ',
        props.model.fieldTypeItemId
      );
  }
  setComponentNotFoundComponent(component) {
    this._defaultComponent = component;
  }
  setComponent(type, component) {
    this._fieldMap.set(type, component);
  }
  get(field, props) {
    const Result = this._fieldMap.get(field.model.fieldTypeItemId);
    if (!Result) {
      const Default = this._defaultComponent;
      return React.createElement(Default, Object.assign({}, field));
    }
    return React.createElement(Result, Object.assign({}, props));
  }
}
export default FieldFactory;
