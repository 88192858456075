import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import { removeWhiteSpaces, cutHtmlStringWithDots } from '../../../utils/Utils';
import GeneralLink from '../../Common/GeneralLink/index';

/**
 * @class Component that renders a title, a subcategory , a button and an image,
 * with possibility to choose  on which side the image should be displayed (left or right).
 * @param {object} props Object containing data needed for the component.
 *
 */

class StoryBoxComponent extends React.Component {
  /**
   * @method getImage  generates images from data
   *
   */
  getImage = () => {
    const { storyItem } = this.props.StoryBoxData.storybox;

    if (!storyItem) {
      return null;
    }
    const { storybox } = this.props.StoryBoxData,
      pageUrl = storybox[this.props.urlToUse],
      { gtm } = storybox;
    return (
      <Col md={6} xs={12} xl={6} sm={12} className='StoryBox-ImageWrapper'>
        <GeneralLink
          fields={{ value: { href: pageUrl } }}
          clickClassName={removeWhiteSpaces(gtm || null) + ' click_content_block_promotion_long_content'}
        >
          <Image className='StoryBox-Image' field={storyItem.Image.jss} />
        </GeneralLink>
      </Col>
    );
  };
  /**
   * @method getDetails  generates title, subcategory and button from data
   *
   */
  getDetails = () => {
    const { linkerButtonLabel, overlineTitle, title, gtm, description } = this.props.StoryBoxData.storybox,
      pageUrl = this.props.StoryBoxData.storybox[this.props.urlToUse];

    return (
      <Col md={6} xs={12} xl={6} sm={12} className='StoryBox-Wrapper-Aside'>
        <div className='StoryBox-Aside'>
          <div className='StoryBox-Subcategory'>{overlineTitle}</div>
          <div className='StoryBox-Title'>
            <GeneralLink
              fields={{ value: { href: pageUrl } }}
              className=''
              clickClassName={removeWhiteSpaces(gtm || null) + ' click_content_block_promotion_long_content'}
              isSpan
            >
              {title}
            </GeneralLink>
          </div>
          <p>{cutHtmlStringWithDots(description, 140)}</p>
          <div className='StoryBox-Button'>
            <GeneralLink
              fields={{ value: { href: pageUrl } }}
              className='StoryBox-Button-Link'
              clickClassName={removeWhiteSpaces(gtm || null) + ' click_content_block_promotion_long_content'}
            >
              {linkerButtonLabel}
            </GeneralLink>
          </div>
        </div>
      </Col>
    );
  };

  getImageRight = () => {
    return (
      <React.Fragment>
        {this.getDetails()}
        {this.getImage()}
      </React.Fragment>
    );
  };

  getImageLeft = () => {
    return (
      <React.Fragment>
        {this.getImage()}
        {this.getDetails()}
      </React.Fragment>
    );
  };

  /**
   * @method getLeftOrRight  displays on which side the image should be, according to data
   *
   */
  getLeftOrRight = () => {
    const imagePosition = this.props.fields.RightImagePosition.value;

    return (
      <React.Fragment>
        <Visible xl lg md>
          {imagePosition ? this.getImageRight() : this.getImageLeft()}
        </Visible>
        <Visible sm xs>
          {this.getImageLeft()}
        </Visible>
      </React.Fragment>
    );
  };

  render() {
    if (!this.props.StoryBoxData || !this.props.StoryBoxData.storybox || !this.props.fields) {
      return <div className='click_content_block_promotion_long_content' />;
    }
    const BackgroundColor = this.props.fields.BackgroundColor;
    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
          ? BackgroundColor.fields.ColorCode.value
          : '#fff'
    };

    const { title } = this.props.StoryBoxData.storybox;
    return (
      <div style={inlineStyle}>
        <Row
          className='StoryBox click_content_block_promotion_long_content componentContainer'
          id={removeWhiteSpaces(title || null)}
        >
          {this.getLeftOrRight()}
        </Row>
      </div>
    );
  }
}
export default StoryBoxComponent;
