import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink';
import { Col, Row, Visible } from 'react-grid-system';
import { cutHtmlStringWithDots, removeWhiteSpaces } from '../../../utils/Utils';
import { StoryDetailsProps, StoryImageProps, UniversalStoryLinkerLRProps } from './types';

const StoryImage = ({ storyItem, pageUrl, gtm }: StoryImageProps) => {
  if (!storyItem) {
    return null;
  }

  return (
    <Col md={6} xs={12} xl={6} sm={12} className='UniversalStoryLinkerLR__ImageWrapper'>
      {/*
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
      <GeneralLink fields={{ value: { href: pageUrl } }} clickClassName={removeWhiteSpaces(gtm || null)}>
        <Image className='UniversalStoryLinkerLR__Image' field={storyItem.storyImage.jss} />
      </GeneralLink>
    </Col>
  );
};

const StoryDetails = ({
  story: { url, linkerButtonLabel, overlineTitle, title, gtmText, description }
}: StoryDetailsProps) => {
  return (
    <Col md={6} xs={12} xl={6} sm={12} className='UniversalStoryLinkerLR__Wrapper-Aside'>
      <div className='UniversalStoryLinkerLR__Aside'>
        <div className='UniversalStoryLinkerLR__Subcategory'>{overlineTitle}</div>
        <div className='UniversalStoryLinkerLR__Title'>
          {/*
            // @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
          <GeneralLink
            fields={{ value: { href: url } }}
            className=''
            clickClassName={removeWhiteSpaces(gtmText || '')}
            isSpan
          >
            {title}
          </GeneralLink>
        </div>
        <p>{cutHtmlStringWithDots(description, 140)}</p>
        <div className='UniversalStoryLinkerLR__Button'>
          {/*
            // @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
          <GeneralLink
            fields={{ value: { href: url } }}
            className='UniversalStoryLinkerLR__Button-Link'
            clickClassName={removeWhiteSpaces(gtmText || '')}
          >
            {linkerButtonLabel}
          </GeneralLink>
        </div>
      </div>
    </Col>
  );
};

export const UniversalStoryLinkerLR = ({ fields }: UniversalStoryLinkerLRProps) => {
  if (!fields?.data?.universalStoryLinkerLR) {
    return null;
  }

  const { BackgroundColor, rightImagePosition } = fields?.data?.fields;
  const { universalStoryLinkerLR: story } = fields.data;

  const inlineStyle = {
    backgroundColor:
      BackgroundColor?.fields?.ColorCode?.value && BackgroundColor?.fields?.ColorCode?.value !== ''
        ? BackgroundColor.fields.ColorCode.value
        : '#ffffff'
  };

  return (
    <div style={inlineStyle}>
      <Row className='UniversalStoryLinkerLR componentContainer' id={removeWhiteSpaces(story.title || '')}>
        <Visible xl lg md>
          {rightImagePosition?.value ? (
            <>
              <StoryDetails story={story} />
              <StoryImage storyItem={story.storyItem} pageUrl={story.url} gtm={story.gtmText || ''} />
            </>
          ) : (
            <>
              <StoryImage storyItem={story.storyItem} pageUrl={story.url} gtm={story.gtmText || ''} />
              <StoryDetails story={story} />
            </>
          )}
        </Visible>
        <Visible sm xs>
          <StoryImage storyItem={story.storyItem} pageUrl={story.url} gtm={story.gtmText || ''} />
          <StoryDetails story={story} />
        </Visible>
      </Row>
    </div>
  );
};

export default UniversalStoryLinkerLR;
