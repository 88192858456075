export const TOGGLE_WISHLIST_FLYOUT = 'TOGGLE_WISHLIST_FLYOUT';
export const SET_WISHLIST_ITEMS = 'SET_WISHLIST_ITEMS';
export const SET_WISHLIST_ITEMS_ANONYMOUS = 'SET_WISHLIST_ITEMS_ANONYMOUS';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';
export const SET_AMOUNT_OF_ALL_WISHLIST_ITEMS = ' SET_AMOUNT_OF_ALL_WISHLIST_ITEMS';
export const EMPTY_WISHLIST = 'EMPTY_WISHLIST';
export const ADD_NEW_LIST = 'ADD_NEW_LIST';
export const SET_WISH_LISTS = 'SET_WISH_LISTS';
export const SET_WISH_LISTS_ANONYMOUS = 'SET_WISH_LISTS_ANONYMOUS';
export const SET_CURRENT_WISHLIST_INDEX = 'SET_CURRENT_WISHLIST_INDEX';
export const REMOVE_WISHLIST = 'REMOVE_WISHLIST';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_CURRENT_WISHLIST_NAME = 'SET_CURRENT_WISHLIST_NAME';
export const SET_AMOUNT_OF_WISHLIST_ITEMS = 'SET_AMOUNT_OF_WISHLIST_ITEMS';
export const REMOVE_PRODUCT_FROM_LISTS = 'REMOVE_PRODUCT_FROM_LISTS';
export const SET_CURRENT_WISHLIST_ITEMS = 'SET_CURRENT_WISHLIST_ITEMS';
