import React from 'react';
import SolutionCardsGalleryComponent from '../index';
import { AXAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

class AXAGSolutionCardsGallery extends React.Component {
  render() {
    return <SolutionCardsGalleryComponent {...this.props} urlToUse={AXAG_PAGE_LINK} />;
  }
}

export default AXAGSolutionCardsGallery;
