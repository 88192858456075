import * as actionTypes from './actionTypes';

export const setContactInfoDetails = contactInfoDetails => {
  return {
    type: actionTypes.SET_CONTACT_INFO_DETAILS,
    name: (contactInfoDetails && contactInfoDetails.name) || '',
    street: (contactInfoDetails && contactInfoDetails.street) || '',
    number: (contactInfoDetails && contactInfoDetails.number) || '',
    city: (contactInfoDetails && contactInfoDetails.city) || '',
    country: (contactInfoDetails && contactInfoDetails.country) || ''
  };
};
