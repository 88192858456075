import React from 'react';
import { EventAddressProps } from '../types';
import { EventLinkDirections } from './EventLinkDirections';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18n from 'i18next';

export const EventAddress = (props: EventAddressProps): JSX.Element => {
  const venueHtml = props.eventVenue?.value && (
    <div className='EventAddressInfo-Content-Venue' data-testid='event-address-venue'>
      <Text field={props.eventVenue} />
    </div>
  );

  const address = props.eventAddress?.value && (
    <div style={{ whiteSpace: 'pre-wrap' }} data-testid='event-address-address'>
      <Text field={props.eventAddress} />
    </div>
  );

  const directionLinkHtml = props.eventLinkDirections?.value?.href && (
    <div className='EventAddressInfo-Directions' data-testid='event-address-directions'>
      <EventLinkDirections eventLinkDirections={props.eventLinkDirections} />
    </div>
  );

  const boothInformation = props.eventBoothInformation?.value && (
    <div data-testid='event-address-booth'>
      <Text field={props.eventBoothInformation} />
    </div>
  );

  return (
    <div className='EventAddress' data-testid='event-address'>
      {(venueHtml || address || directionLinkHtml) && (
        <div className='EventAddressInfo'>
          <div className='EventAddressInfo-Label'>{i18n.t('EVENT_DETAIL | Venue')}</div>
          <div className='EventAddressInfo-Content'>
            {venueHtml}
            {address}
            {directionLinkHtml}
          </div>
        </div>
      )}
      {boothInformation && (
        <div className='EventAddressInfo'>
          <div className='EventAddressInfo-Label'>{i18n.t('EVENT_DETAIL | Booth')}</div>
          <div className='EventAddressInfo-Content'>{boothInformation}</div>
        </div>
      )}
    </div>
  );
};
