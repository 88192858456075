import React from 'react';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';

const ImageRightTeaser = props => {
  return (
    <Row className='ImageRightTeaser'>
      <Col sm={12} xs={12} lg={6} className='ImageRightTeaser-left'>
        <div className='ImageRightTeaser-left-wrapper'>
          <Text field={props.fields.Headline} tag='span' className='ImageRightTeaser-left-headline' />
          <RichText field={props.fields.Description} className='ImageRightTeaser-left-description' />
          <div className='ImageRightTeaser-left-button'>
            <RoutableSitecoreLink link={props.fields.Link} className='ImageRightTeaser-left-button-link' />
          </div>
        </div>
      </Col>
      <Col sm={12} xs={12} lg={6}>
        <Image className='ImageRightTeaser-img' field={props.fields.Image} />
      </Col>
    </Row>
  );
};

export default ImageRightTeaser;
