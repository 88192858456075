import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsWaferProcessingServicesPipelines,
  CoveoProductsWaferProcessingServicesSearchHubs
} from './WaferProcessingServicesLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import WaferProcessingServicesListerComponent from './WaferProcessingServicesLister';

export const WaferProcessingServicesLister = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsWaferProcessingServicesPipelines[siteName],
    searchHub: CoveoProductsWaferProcessingServicesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WaferProcessingServicesListerComponent />
    </CoveoComponent>
  );
};

export default WaferProcessingServicesLister;
