import React, { useEffect, useState } from 'react';
import GeneralLink from '../../Common/GeneralLink';
import ArrowRight from '../../Common/Icons/ArrowRight';
import { setStatusToContactChange } from '../../../store/contactForms/actions';
import { useDispatch } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';

type ContactInfoChangeContactProps = {
  sitecoreContext: any;
  fields: any;
  contactData: any;
};

export const ContactInfoChangeContact = ({ sitecoreContext, fields, contactData }: ContactInfoChangeContactProps) => {
  const { 'Contact Form': contactForm } = sitecoreContext.route.fields;
  const { contactInfoDescription, contactInfoLink, contactInfoButtomSubtitle, contactInfoLinkLabel } = fields.data.item;
  const Link = contactInfoLink?.jss ? contactInfoLink.jss : {};
  const form = contactForm && contactForm.value;
  const { 'Is Success Page': successPage } = sitecoreContext.route.fields;
  const [pageUrl, setPageUrl] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setPageUrl(window.location.pathname + window.location.search);
  }, []);

  const shouldDisplayChangeContact = () => {
    return !(
      contactData?.name ||
      !contactInfoLink ||
      (contactInfoLink && Link && !Link.value.href) ||
      successPage?.value
    );
  };

  const setContactData = (title: string) => {
    window._CHANGE_MY_CONTACT_ = true;
    dispatch(setStatusToContactChange(true, pageUrl, title));
  };

  return shouldDisplayChangeContact() ? (
    <React.Fragment>
      <div className='ContactInfo-MiddleLine' />
      <Text field={contactInfoButtomSubtitle} className='ContactInfo-BottomSubtitle' tag='div' />
      <Text field={contactInfoDescription} className='ContactInfo-Description' tag='div' />
      <div
        onClick={() => {
          setContactData(form);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setContactData(form);
          }
        }}
        role='button'
        tabIndex={0}
        className='click_change_my_contact'
      >
        {/*
        // @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
        <GeneralLink className='ContactInfo-Link' fields={Link}>
          <Text field={contactInfoLinkLabel} />
          <ArrowRight />
        </GeneralLink>
      </div>
    </React.Fragment>
  ) : null;
};
