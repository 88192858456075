import * as ActionTypes from './actionTypes';

const initialState = {
  countries: [],
  provinces: []
};

export const countries = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries.Countries.map(country => {
          return {
            value: country.CountryCode,
            label: country.Name
          };
        }),
        provinces: action.countries.Provinces
      };
    default:
      return state;
  }
};
