import React from 'react';
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
/**
 * @class Component that renders an image(left or right) and a rich text in the same row
 *
 * @param {object} props Object containing the Text (for RichText), the Image,
 * the place of the image (RightImagePosition: true/false) and the Background Color of the entire component
 *
 * Returns jsx markup containing a container with an Image and a RichText in the same line.
 */
const ImageWithRichText = props => {
  if (!props.fields) {
    return <div className='RightImageWithRichText' />;
  }
  const { Text, BackgroundColor, Image: Img, RightImagePosition } = props.fields;
  const inlineStyle = {
    backgroundColor:
      BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
  };
  const cssClass = classNames({
    WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000',
    RightImageWithRichText: RightImagePosition.value,
    LeftImageWithRichText: !RightImagePosition.value
  });

  const classByPosition = classNames({
    RightImageWithRichText: RightImagePosition.value,
    LeftImageWithRichText: !RightImagePosition.value
  });

  return (
    <div className='whiteBackground' style={inlineStyle}>
      <div className='componentContainerMobile'>
        <div className={cssClass}>
          <div className={classByPosition + '-ImageContainer'}>
            <Image field={Img} />
          </div>

          <RichText className={classByPosition + '-Text'} field={Text} />
        </div>
      </div>
    </div>
  );
};

export default ImageWithRichText;
