import React from 'react';
import GeneralLink from '../../../../Common/GeneralLink/index';
import { getLanguage } from '../../../../Common/CookieSettings/SetLanguageData';
import { addProductOnDataLayer } from '../../../../../utils/Utils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { PRODUCT_PAGE_ROUTE } from '../../../../../AppRoutes';
class SubCategoriesItem extends React.Component {
  getItems = () => {
    const { itemsToShow } = this.props;

    return this.props.items.slice(0, itemsToShow).map((item, index) => {
      const {
          ArticleID,
          ArticleName,
          ArticleNumber,
          imageUrl,
          MasterProductName,
          MasterProductENName,
          SubTitleInfo,
          BrandName
        } = item,
        { categoryName } = this.props,
        link =
          MasterProductENName && ArticleID
            ? {
                href:
                  '/' +
                  getLanguage(this.props.sitecoreContext) +
                  PRODUCT_PAGE_ROUTE +
                  '/' +
                  MasterProductENName.replace(/ /g, '-') +
                  '/' +
                  ArticleNumber.toString().replace('.', '-')
              }
            : null,
        subTitleValue = SubTitleInfo && SubTitleInfo.value ? SubTitleInfo.value : '';
      if (link) {
        return (
          <div
            className='SubCategoriesItem'
            onClick={() => {
              addProductOnDataLayer(ArticleID, MasterProductENName, categoryName, link.href, BrandName, ArticleNumber);
            }}
            key={ArticleID + index}
            onKeyDown={e => {
              if (e.target === 13)
                addProductOnDataLayer(
                  ArticleID,
                  MasterProductENName,
                  categoryName,
                  link.href,
                  BrandName,
                  ArticleNumber
                );
            }}
            role='button'
            tabIndex='0'
          >
            <GeneralLink fields={{ value: link }} key={ArticleID + index}>
              <div className='SubCategoriesItem-Image'>
                {imageUrl ? <img src={imageUrl + '?imwidth=320'} alt={ArticleName} loading='lazy' /> : null}
              </div>
              <div className='SubCategoriesItem-Description'>
                <div className='SubCategoriesItem-Description-Title'>{MasterProductName}</div>
                <div className='SubCategoriesItem-Description-Details'>{subTitleValue}</div>
                <div className='SubCategoriesItem-Description-ArticleNumber'>{ArticleNumber}</div>
              </div>
            </GeneralLink>
          </div>
        );
      }
      return (
        <div className='SubCategoriesItem' key={ArticleID + index}>
          <div className='SubCategoriesItem-Image'>
            {imageUrl ? <img src={imageUrl + '?imwidth=320'} alt={ArticleName} loading='lazy' /> : null}
          </div>
          <div className='SubCategoriesItem-Description'>
            <div className='SubCategoriesItem-Description-Title'>{MasterProductName}</div>
            <div className='SubCategoriesItem-Description-Details'>{subTitleValue}</div>
            <div className='SubCategoriesItem-Description-ArticleNumber'>{ArticleNumber}</div>
          </div>
        </div>
      );
    });
  };

  render() {
    if (!this.props.items) {
      return <div className='SubCategoriesItem' alt='' />;
    }
    return <React.Fragment>{this.getItems()}</React.Fragment>;
  }
}

export default withSitecoreContext()(SubCategoriesItem);
