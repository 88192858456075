import React, { useContext } from 'react';
import { SearchEngineProps, useBuildEngine } from './CoveoEngine';
import { SearchEngineContext } from './context/SearchEngineContext';
import { CoveoContextProvider } from './context/CoveoContext';

type CoveoComponentProps = {
  children: React.ReactNode;
  searchEngineProps: SearchEngineProps;
};

export const CoveoComponent: React.FC<CoveoComponentProps> = ({ children, searchEngineProps }) => {
  const {
    state: { searchToken, coveoSiteName, locale }
  } = useContext(SearchEngineContext);

  const engine = useBuildEngine({
    ...searchEngineProps,
    searchToken,
    coveoSiteName,
    locale
  });

  return engine ? <CoveoContextProvider engine={engine}>{children}</CoveoContextProvider> : null;
};

export default CoveoComponent;
