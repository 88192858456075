import discountsFragment from './discount';
import productFragment from './product';

const cartFragment = /* GraphQL */ `
  fragment cart on Cart {
    id
    checkoutUrl
    ...discounts
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          attributes {
            key
            value
          }
          _leisterProductUrl: attribute(key: "_leisterProductUrl") {
            value
          }
          _leisterProductName: attribute(key: "_leisterProductName") {
            value
          }
          _leisterProductBrand: attribute(key: "_leisterProductBrand") {
            value
          }
          _leisterProductCategory: attribute(key: "_leisterProductCategory") {
            value
          }
          _leisterProductSubcategory: attribute(key: "_leisterProductSubcategory") {
            value
          }
          _leisterRelatedBundle: attribute(key: "_leisterRelatedBundle") {
            value
          }
          quantity
          cost {
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              sku
              product {
                ...product
              }
            }
          }
        }
      }
    }
    totalQuantity
  }
  ${productFragment}
  ${discountsFragment}
`;

export default cartFragment;
