import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Col, Row } from 'react-grid-system';
import GeneralLink from '../../../Common/GeneralLink/index';
import classNames from 'classnames';
import { getSmallestImageCrop } from '../../../../utils/Utils';

/**
 * @class Component that renders the layout (3 col all title section) for the flyout menu.
 *
 * @param {object} items Object containing the data (items) needed for the component.
 *
 * Returns HTML.
 */
class OneSectionsLayout extends React.Component {
  /**
   * @method getImage
   * @description If the show image props is set then display the image from the sitecore otherwise display the default gray square
   * @param {object} item Object that contains or not the item image
   *
   * Returns the image or the default gray square in a jsx format.
   */
  getImage = item => {
    if (this.props.showImage) {
      const { Image: img } = item.fields;
      if (img && img.value.src && img.value.src !== '') {
        const smallestImg = getSmallestImageCrop(img.value.srcSet);
        return (
          <img
            src={smallestImg ? smallestImg : img.value.src + '?imwidth=100'}
            alt={img.value.alt ? img.value.alt : ''}
            loading='lazy'
            className='TwoSectionsLayout-Item-Sitecore-Image'
          />
        );
      }
      return <div className='TwoSectionsLayout-Item-Image' />;
    }
  };

  /**
   * @method getColData
   * @description Function that get items and put them in one column
   *
   * Returns HTML containing all of the above items.
   */
  getColData = (itemsToMap, fromIndex, untilIndex) => {
    const itemClass = classNames('TwoSectionsLayout-Item', {
      'TwoSectionsLayout-Item-NoImage': this.props.showImage === false
    });

    const itemData = itemsToMap.slice(fromIndex, untilIndex).map((item, index) => {
      if (!item.fields.Link?.value?.href) {
        // do not render the link or the <Col /> if there is no "HREF" value
        // ex: on protected pages, the link data will be return, without HREF,
        // but the <Col /> will render with a min-height, which doesn't lok great in the design
        return null;
      }

      return (
        <Col className={itemClass} key={item.fields.Title.value + index} xl={12}>
          <GeneralLink fields={item.fields.Link}>
            {this.getImage(item)}
            <Text field={item.fields.Title} tag='div' className={'TwoSectionsLayout-Item-Title'} />
          </GeneralLink>
        </Col>
      );
    });

    return (
      <React.Fragment>
        <Row xl={4} className='TwoSectionsLayout-Section-Row OneSectionsLayout-Section-Row'>
          {itemData}
        </Row>
      </React.Fragment>
    );
  };

  render() {
    const itemsToMap = this.props.items;
    if (!itemsToMap || !itemsToMap[0] || !itemsToMap[0].fields || !itemsToMap[0].fields.NavigationElements) {
      return null;
    }
    const navigationElements = itemsToMap[0].fields.NavigationElements;
    return (
      <React.Fragment>
        <Row nogutter>
          <Col md={4} xl={4}>
            {this.getColData(navigationElements, 0, 4)}
          </Col>
          <Col md={4} xl={4}>
            {this.getColData(navigationElements, 4, 8)}
          </Col>
          <Col md={4} xl={4}>
            {this.getColData(navigationElements, 8, 12)}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default OneSectionsLayout;
