import React from 'react';
import { Visible } from 'react-grid-system';
import ImageSliderComponent from './ImageSliderComponent/ImageSliderComponent';

export default class UniversalImageSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Visible lg xl>
          <ImageSliderComponent
            fields={this.props.fields}
            isMobile={false}
            leftImageWidth={this.props.leftImageWidth}
            imageClass={this.props.imageClass}
            urlToUse={this.props.urlToUse}
          />
        </Visible>
        <Visible xs sm md>
          <ImageSliderComponent
            fields={this.props.fields}
            isMobile={true}
            leftImageWidth={this.props.leftImageWidth}
            imageClass={this.props.imageClass}
            urlToUse={this.props.urlToUse}
          />
        </Visible>
      </React.Fragment>
    );
  }
}
