/**
 * @method getLanguages Method that extract data from server and add them in a new json to be more easy to procees
 * @param {*} items Array of objects that contains information about regions, country and languages
 * @return JSON array
 */

import {
  DEFAULT_COUNTRY_CODE,
  ONE_YEAR,
  DEFAULT_LANGUAGE,
  COUNTRY_CODE,
  DEFAULT_DATA
} from '../CookieSettings/CookieConstants';
import { setCookie } from '../../Common/CookieSettings/CookieUtils';
import { setLanguage } from '../../../utils/Utils';

export function getLanguages(items, country, region) {
  if (typeof items === 'undefined' || items.constructor.name !== 'Array' || items.length === 0) {
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    if (region !== null && items[i].regionCode.value === region) {
      const availableCountries = items[i].availableCountries.tags;
      for (let j = 0; j < availableCountries.length; j++) {
        if (availableCountries[j].regionCode.value === country) {
          return {
            getdefaultLanguage: availableCountries[j].defaultLanguage.tags.iso.value.value,
            languages: availableCountries[j].languages.tags,
            dropdownLanguages: availableCountries[j].dropdownLanguages.tags,
            country: availableCountries[j],
            region: items[i]
          };
        }
      }
    } else {
      const availableCountries = items[i].availableCountries.tags;
      for (let j = 0; j < availableCountries.length; j++) {
        if (availableCountries[j].regionCode.value === country) {
          return {
            getdefaultLanguage: availableCountries[j].defaultLanguage.tags.iso.value.value,
            languages: availableCountries[j].languages.tags,
            dropdownLanguages: availableCountries[j].dropdownLanguages.tags,
            country: availableCountries[j],
            region: items[i]
          };
        }
      }
    }
  }

  if (country === DEFAULT_COUNTRY_CODE) {
    return DEFAULT_DATA;
  }

  setCookie(COUNTRY_CODE, DEFAULT_COUNTRY_CODE, ONE_YEAR);
  setLanguage(DEFAULT_LANGUAGE);
  return getLanguages(items, DEFAULT_COUNTRY_CODE);
}

export function extractLanguagesCode(languages) {
  const lang = [];
  for (let i = 0; i < languages.length; i++) {
    lang.push(languages[i].iso.value);
  }
  return lang;
}

export function getDefaultLanguage(languages, language, defaultLanguage) {
  for (let i = 0; i < languages.length; i++) {
    if (languages[i] === language) {
      return language;
    }
  }

  if (defaultLanguage && defaultLanguage !== null) {
    setLanguage(defaultLanguage);
    return defaultLanguage;
  } else {
    setLanguage(DEFAULT_LANGUAGE);
    return DEFAULT_LANGUAGE;
  }
}
