import React, { useContext } from 'react';
import FilterIcon from '../../Common/Icons/Filter';
import { ActionType, SearchContext } from '../context/SearchContext';

export const FloatingFilterToggle: React.FC = () => {
  const { state: searchState, dispatch } = useContext(SearchContext);
  const openFacetsModal = () => {
    dispatch({
      type: ActionType.OPEN_FACETS
    });
  };

  return (
    <button className='FloatingFilterToggle' onClick={openFacetsModal}>
      <span className='FloatingFilterToggle__Number'>
        {searchState.selectedFacets
          ? Object.values(searchState.selectedFacets).reduce((acc, curr) => acc + curr.length, 0)
          : 0}
      </span>
      <FilterIcon />
    </button>
  );
};

export default FloatingFilterToggle;
