import React from 'react';
import { connect } from 'react-redux';
import BenefitsWithImages from './BenefitsWithImages/index';
import BenefitsWithCheckmarks from './BenefitsWithCheckmarks/index';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';

/**
 * Benefits component with left right image display options
 */
class Benefits extends React.Component {
  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }

    const { benefits, fields } = this.props,
      { Title } = fields,
      benefitsData = benefits.data;

    return (
      <div className='whiteBackground'>
        <div className='componentContainer'>
          <HeadlineWithTitleAndSubtitle extraClasses='Benefits-Headline' title={Title} subtitle={benefits.subTitle} />
          {benefits.isBullet ? (
            <BenefitsWithCheckmarks benefitsData={benefitsData} />
          ) : (
            <BenefitsWithImages benefitsData={benefitsData} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    benefits: state.productDetails.masterProductData ? state.productDetails.masterProductData.benefits : null
  };
};

const hasItems = function (props) {
  const { benefits, fields } = props;
  if (!benefits || !benefits.data || !benefits.data.length || !fields) {
    return false;
  }
  return true;
};

export default connect(mapStateToProps)(WithStickyMenu(Benefits, hasItems));
