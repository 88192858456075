import React from 'react';
import { Text, RichText, Image, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import EmailGreyIcon from '../../Common/Icons/EmailGreyIcon';
import gql from 'graphql-tag';
import GraphQLData from '../../../lib/GraphQLData';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import GeneralLink from '../../Common/GeneralLink';
import { withRouter } from 'react-router-dom';
import MapPinIcon from '../../Common/Icons/MapPin';
import Home2Icon from '../../Common/Icons/Home2Icon';
import TimeIcon from '../../Common/Icons/TimeIcon';

const getBackToInfoForStoryAndCareerQuery = gql`
  query getBackToInfoForStoryAndCareerQuery($dataSource: String, $lang: String) {
    getBackToInfoForStoryAndCareerQuery(currentItemId: $dataSource, lang: $lang) {
      url
      description
    }
  }
`;

class HeroCareer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLink: ''
    };
  }

  componentDidMount() {
    const currentLink = window.location.href;
    this.setState({ currentLink: currentLink });
  }

  /**
   * @method showLinkBack displays back link to one level up url , if there is one
   */
  showLinkBack = () => {
    const { 'Back To Label': backLabel } = this.props.fields,
      queryData =
        this.props.queryCareerData && this.props.queryCareerData.getBackToInfoForStoryAndCareerQuery
          ? this.props.queryCareerData.getBackToInfoForStoryAndCareerQuery
          : {};

    let { url: backLink, description: backLinkDescription } = queryData;

    if (this.props.location?.state?.origin === 'lister') {
      backLink = this.props.location.state.from;
    }

    if (!backLink || !backLinkDescription) {
      return <div className='HeroCareer-Button' />;
    }

    return (
      <GeneralLink fields={{ value: { href: backLink } }} className='HeroCareer-Button'>
        <ArrowLeft />
        <Text field={backLabel} />
        &nbsp;
        {backLinkDescription}
      </GeneralLink>
    );
  };
  showShareEmail = () => {
    const {
        Title,
        'Open Position Label': positionLabel,
        Location,
        'Work Load': workLoad,
        'Show Email Share': showEmail,
        Company,
        Country
      } = this.props.fields,
      company = Company && Company.fields['Company Name'] ? Company.fields['Company Name'].value : '',
      country = Country && Country.fields && Country.fields.RegionName ? Country.fields.RegionName.value : '';
    if (showEmail.value) {
      return (
        <a
          href={`mailto:?subject=${positionLabel.value}%20${Location.value},%20${country}%20@%20${company}&body=${Title.value}/

						${workLoad.value},%20${Location.value},%20${country}%0D%0A${this.state.currentLink}`}
          className='HeroCareer-EmailShare click_button_share'
          target='blank'
        >
          <EmailGreyIcon />
        </a>
      );
    }
    return null;
  };

  render() {
    if (!this.props.fields) {
      return <div className='HeroCareer-Container componentContainer' />;
    }
    const { fields } = this.props,
      { Abstract, Image: img, Title, Location, 'Work Load': workLoad, Country, Company } = fields,
      company = Company && Company.fields['Company Name'] ? Company.fields['Company Name'] : { value: '' },
      country = Country && Country.fields && Country.fields.RegionName ? Country.fields.RegionName : { value: '' };

    return (
      <div className='HeroCareer-Container componentContainerHero'>
        <div className='HeroCareer '>
          {this.showLinkBack()}
          <h1 className='HeroCareer-Title'>
            <Text tag='span' field={Title} />
          </h1>
          <span className='HeroCareer-Info-Row'>
            <span className='HeroCareer-Info-Details'>
              <MapPinIcon />
              <span className='wrap-context-wrapper'>
                {Location.value && (
                  <span className='wrap-context'>
                    <Text tag='span' field={Location} />
                    {country.value && <span>,&nbsp;</span>}
                  </span>
                )}
                {country.value && (
                  <span className='wrap-context'>
                    <Text tag='span' field={country} />
                  </span>
                )}
              </span>
            </span>
            <span className='HeroCareer-Info-Details'>
              <Home2Icon />
              <Text tag='span' field={company} />
            </span>
            <span className='HeroCareer-Info-Details'>
              <TimeIcon />
              <Text tag='span' field={workLoad} />
            </span>
          </span>
          <Image className='HeroCareer-Image' field={img} />

          <div className='HeroCareer-SocialLinkWrapper'>
            <Placeholder name='jss-share-social-link' rendering={this.props.rendering} />
            {this.showShareEmail()}
          </div>
          <RichText field={Abstract} className='HeroCareer-Description' />
        </div>
      </div>
    );
  }
}

export default GraphQLData(getBackToInfoForStoryAndCareerQuery, {
  name: 'queryCareerData',
  options: props => ({
    variables: {
      dataSource: props.sitecoreContext && props.sitecoreContext.itemId ? props.sitecoreContext.itemId : null,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withRouter(withSitecoreContext()(HeroCareer)));
