import React, { useState, useEffect, ComponentType } from 'react';
import { Visible } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import TelephoneIcon from '../../Common/Icons/TelephoneIcon';
import { setContactPointInfo, setDropdownForms } from '../../../store/contactForms/actions';
import { setContactPersonData } from '../../../store/contactPerson/actions';
import { setContactInfoDetails } from '../../../store/contactInfoDetails/actions';
import { getSessionStorage } from '../../Common/CookieSettings/CookieUtils';
import { getSitecoreGraphqlEndpoint } from '../../../AppGlobals';
import query from './ContactInfoQuery';
import { ContactInfoChangeContact } from './ContactInfoChangeContact';
import { ContactInfoTitle } from './ContactInfoTitle';

type Person = {
  name: string;
  email: string;
  salesPoint: any;
  forms: any;
};

type RootState = {
  contactPerson: Person;
  contactForms: any;
};

type ContactInfoProps = {
  fields: any;
  sitecoreContext: any;
};

const getExpertContactData = (fields: any) => {
  if (fields?.data?.item?.expert?.jss?.fields) {
    const {
      'Contact Page Forms': forms,
      'E-mail address': emailAddress,
      'First name': firstName,
      'Last name': lastName,
      'Sales And Service Point': salesPoint,
      'Phone number': phone,
      isOptOutForFormMailSubmission
    } = fields.data.item.expert.jss.fields;

    return {
      companyName: { value: `${firstName.value} ${lastName.value}` },
      name: `${firstName.value} ${lastName.value}`,
      email: emailAddress.value,
      salesPoint: salesPoint,
      forms,
      phone,
      street: salesPoint.fields.Street,
      streetNumber: salesPoint.fields['Street Number'],
      city: salesPoint.fields.City,
      zipCode: salesPoint.fields['Zip Code'],
      country: salesPoint.fields.Country.fields.RegionName,
      isOptOutForFormMailSubmission
    };
  }

  return null;
};

const ContactInfo = (props: ContactInfoProps) => {
  const { fields, sitecoreContext } = props;
  // contact from redux
  const person = useSelector((state: RootState) => state.contactPerson);
  // contact to use, either the `person` from redux, or a person from session storage, if any
  const [personContact, setPersonContact] = useState<Person | null>(null);
  // contact is not a person but a company, from redux
  const currentInfoPointId = useSelector((state: RootState) => state.contactForms.currentInfoPointId);
  // info point id, either the one from redux or the one from session storage, if any
  const [infoPointId, setInfoPointId] = useState<string | null>(null);
  // this is possible person contact data to display
  const [personData, setPersonData] = useState<any | null>(null);
  // this is possible company contact data to display
  const [queryInfoPointData, setQueryInfoPointData] = useState<{
    contactPageItems: any;
  } | null>(null);
  const fallbackExpertContact = getExpertContactData(fields);
  // MOST IMPORTANT - this is what renders after figuring out which contact data to display
  const [contactData, setContactData] = useState<any | null>(null);
  const [infoPointDataFetching, setInfoPointDataFetching] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // set person contact, it's either the one from redux or the one from session storage, if any
    setPersonContact(
      person?.name ? person : getSessionStorage('person') ? JSON.parse(getSessionStorage('person') || '') : null
    );

    return () => {
      if (person.name || person.email) {
        dispatch(setContactPersonData(null));
      }
    };
  }, [person, dispatch]);

  useEffect(() => {
    // set company info point contact id, it's either the one from redux or the one from session storage, if any
    setInfoPointId(currentInfoPointId ? currentInfoPointId : getSessionStorage('contactPointInfoId'));
  }, [currentInfoPointId]);

  useEffect(() => {
    if (infoPointId && !queryInfoPointData) {
      if (!person?.name) {
        getInfoPointData(infoPointId, sitecoreContext.language);
      }
    }
  }, [infoPointId, queryInfoPointData, sitecoreContext.language]);

  useEffect(() => {
    if (checkIfFormsSelection() && !isPersonContact()) {
      const contactPageItems = queryInfoPointData?.contactPageItems?.targetItems
        ? queryInfoPointData?.contactPageItems?.targetItems
        : fields.data.getContactByCountryId.contactPageItems.targetItems;
      dispatch(
        setDropdownForms(
          contactPageItems.map((item: any) => ({
            title: item.name.value,
            link: item.link.jss.value.href,
            form: item.form.value
          }))
        )
      );
    }
  }, [queryInfoPointData]);

  useEffect(() => {
    if (personContact?.salesPoint?.fields) {
      const {
        City: city,
        Street: street,
        'Street Addendum': streetAddendum,
        'Street Number': streetNumber,
        Phone: phone,
        'Zip Code': zipCode
      } = personContact.salesPoint.fields;
      setPersonData({
        companyName: { value: personContact.name },
        name: personContact.name,
        phone,
        city,
        country: personContact.salesPoint.fields.Country.fields.RegionName,
        street,
        streetAddendum,
        streetNumber,
        zipCode
      });
    }
  }, [personContact]);

  // IMPORTANT - this determines order of checking which contact data to display
  useEffect(() => {
    if (personData || queryInfoPointData) {
      const contactDetailsData = personData || queryInfoPointData;

      setContactData(contactDetailsData);

      // loaded contact data, since this is asonchronous, we need to set the form fields after the data is loaded
      dispatch(
        setContactPointInfo(
          contactDetailsData?.isOptOutForFormMailSubmission?.value === '1',
          contactDetailsData?.email?.value
        )
      );
    } else {
      setFormFields();
      setContactData(!infoPointDataFetching ? fallbackExpertContact || fields.data.getContactByCountryId : null);
    }
  }, [fields?.data?.item?.expert?.jss?.fields, personData, queryInfoPointData, infoPointDataFetching]);

  const setFormFields = () => {
    if (!infoPointDataFetching && fallbackExpertContact) {
      // setting hidden form fields for expert contact
      if ((fallbackExpertContact?.email?.match(/@/g) || []).length === 1) {
        const fieldsData = fallbackExpertContact || fields.data.getContactByCountryId;

        const contactPointInfoDetails = {
          name: fieldsData.companyName.value,
          street: fieldsData.street.value,
          number: fieldsData.streetNumber.value,
          city: fieldsData.city.value,
          country: fieldsData?.country?.value
        };

        dispatch(setContactPointInfo(fieldsData?.isOptOutForFormMailSubmission?.value === '1', fieldsData.email));
        dispatch(setContactInfoDetails(contactPointInfoDetails));
        // this sets the dropdown options for expert contact
        dispatch(
          setDropdownForms(
            fallbackExpertContact.forms.map((item: any) => ({
              title: item.fields.Name.value,
              link: item.fields.Link.value.href,
              form: item.fields.Form.value,
              key: `${item.fields.Name.value} ${fieldsData.companyName.value}`
            }))
          )
        );
      }
    } else if (!infoPointDataFetching && fields.data.getContactByCountryId) {
      // setting hidden form fields for country contact
      if ((fields?.data?.getContactByCountryId?.email?.value?.match(/@/g) || []).length === 1) {
        const fieldsData = fields.data.getContactByCountryId;

        const contactPointInfoDetails = {
          name: fieldsData.companyName.value,
          street: fieldsData.street.value,
          number: fieldsData.streetNumber.value,
          city: fieldsData.city.value,
          country: fieldsData?.country?.targetItem?.field ? fieldsData.country.targetItem.field : fieldsData.country
        };

        dispatch(setContactPointInfo(fieldsData?.isOptOutForFormMailSubmission?.value === '1', fieldsData.email.value));
        dispatch(setContactInfoDetails(contactPointInfoDetails));
        // this sets the dropdown options for country contact
        dispatch(
          setDropdownForms(
            fieldsData?.contactPageItems?.targetItems?.map((item: any) => ({
              title: item.name.value,
              link: item.link.jss.value.href,
              form: item.form.value,
              key: `${item.name.value} ${fieldsData.companyName.value}`
            }))
          )
        );
      }
    }
  };

  const checkIfFormsSelection = () => !!fields?.data?.getContactByCountryId?.contactPageItems?.targetItems;
  const isPersonContact = () => !!person?.name;

  const getInfoPointData = async (idVar: string, lang?: string) => {
    setInfoPointDataFetching(true);
    const response = await fetch(getSitecoreGraphqlEndpoint() + '&sc_lang=' + lang, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: { id: idVar }
        })
      }),
      dataJson = await response.json();
    setInfoPointDataFetching(false);
    if (dataJson?.data?.item && JSON.stringify(dataJson.data.item) !== JSON.stringify(queryInfoPointData)) {
      const item = dataJson.data.item;

      const contactDetails = {
        name: item?.companyName?.value,
        street: item?.street?.value,
        number: item?.streetNumber?.value,
        city: item?.city?.value,
        country: item?.country?.targetItem?.field ? item.country.targetItem.field : item.country
      };

      if (item.email && (item.email.value.match(/@/g) || []).length === 1) {
        dispatch(setContactPointInfo(item?.isOptOutForFormMailSubmission?.value === '1', item.email.value));
        dispatch(setContactInfoDetails(contactDetails));
      }

      setQueryInfoPointData(item);
    }
  };

  if (!props?.fields?.data?.getContactByCountryId) {
    return <div className='ContactInfo' />;
  }

  return contactData ? (
    <div className='ContactInfo'>
      <ContactInfoTitle fields={fields} contactData={contactData} />
      <div className='ContactInfo-Address'>
        {isPersonContact() && <Text field={personContact?.salesPoint.fields['Company Name']} tag='div' />}
        <div>
          <Text field={contactData?.street} />
          {contactData?.street && contactData?.streetNumber ? ' ' : ''}
          <Text field={contactData?.streetNumber} />
        </div>
        {contactData?.streetAddendum ? <Text field={contactData?.streetAddendum} tag='div' /> : null}
        <Text field={contactData?.zipCode} />
        {contactData?.zipCode && contactData?.city ? ' ' : ''}
        <Text field={contactData?.city} />
        <Text field={contactData?.country?.targetItem?.field || contactData?.country} tag='div' />
      </div>
      <Visible xl lg>
        <Text field={contactData?.phone} className='ContactInfo-Phone' tag='div' />
      </Visible>
      <Visible sm md>
        <a href={'tel:' + contactData?.phone.value} className='ContactInfo-PhoneIcon'>
          <TelephoneIcon />
        </a>
      </Visible>
      <ContactInfoChangeContact contactData={contactData} sitecoreContext={sitecoreContext} fields={fields} />
    </div>
  ) : null;
};

export default withSitecoreContext()(
  withRouter<RouteComponentProps & ContactInfoProps, ComponentType<RouteComponentProps & ContactInfoProps>>(ContactInfo)
);
