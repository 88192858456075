import React from 'react';
import i18n from 'i18next';
import Techspec from '../../Product/Techspec';
import LinkButton from '../../Common/LinkButton/LinkButton';
import classNames from 'classnames';
import Tag from '../../Common/Tag/Tag';
import AvailabilityTooltip from '../AvailabilityTooltip';
import Price from '../Price/Price';
import { ProductVariantSelectorData } from '../ProductVariantSelector';

type SelectVariantMobileProps = {
  row: ProductVariantSelectorData;
  onClick: (url: string) => void;
};

export const SelectVariantMobile = ({
  row: {
    articleNumber,
    highlighted = false,
    techSpec,
    shopProduct,
    url,
    shopProductVariant,
    tags,
    isInOnlineShop = false
  },
  onClick
}: SelectVariantMobileProps): JSX.Element => {
  return (
    <div
      className={classNames('SelectVariantMobile', {
        'SelectVariantMobile--Selected': highlighted
      })}
      onClick={() => onClick(url)}
      onKeyDown={() => onClick(url)}
      role='button'
      tabIndex={0}
    >
      <div className='SelectVariantMobile__article-nr'>{articleNumber}</div>
      <Techspec variant='columns' spec={techSpec} />
      {isInOnlineShop && (
        <div className='SelectVariantMobile__availability-and-price'>
          <div className='SelectVariantMobile__label'>{i18n.t('SHOP | Availability label')}</div>
          <AvailabilityTooltip shopProduct={shopProduct} />
          {shopProductVariant && (
            <>
              <div className='SelectVariantMobile__label'>{i18n.t('SHOP | Price label')}</div>
              <div className='SelectVariantMobile__price-details'>
                {shopProductVariant?.compareAtPrice?.amount && (
                  <Price
                    amount={shopProductVariant?.compareAtPrice?.amount}
                    currencyCode={shopProductVariant?.compareAtPrice?.currencyCode}
                    type='stroken-through'
                    className='SelectVariantMobile__price--compare-at'
                  />
                )}
                <Price
                  className={classNames('SelectVariantMobile__price', {
                    'SelectVariantMobile__price--promo': shopProductVariant?.compareAtPrice?.amount
                  })}
                  amount={shopProductVariant?.price?.amount}
                  currencyCode={shopProductVariant?.price?.currencyCode}
                />
              </div>
            </>
          )}
        </div>
      )}
      {tags && tags?.length > 0 ? (
        <div className='SelectVariantMobile__tags'>
          {tags?.map(tag => (
            <Tag key={tag} type='secondary'>
              {tag}
            </Tag>
          ))}
        </div>
      ) : null}
      {highlighted ? (
        <div className='SelectVariantMobile__selected'>{i18n.t('SHOP | Selected variant')}</div>
      ) : (
        <LinkButton
          className='SelectVariantMobile__button'
          withArrow={false}
          title={i18n.t('SHOP | Select variant')}
          onClick={() => onClick(url)}
        />
      )}
    </div>
  );
};

export default SelectVariantMobile;
