import React, { useEffect, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { checkIfUserIsLoggedIn } from '../../../utils/Utils';
import { getSitecoreGraphqlEndpoint, getSitecoreApiHost } from '../../../AppGlobals';
import { t } from 'i18next';

const MyNewsletters = props => {
  const { sitecoreContext } = props;

  const [emailValue, setEmailValue] = useState('');
  const [subscriptionData, setSubscriptionData] = useState();
  const [subscriptionsAvailable, setSubscriptionsAvailable] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  useEffect(() => {
    if (checkIfUserIsLoggedIn(sitecoreContext)) {
      if (!emailValue) {
        handleOnLoadGetUserEmail();
      }

      if (!subscriptionData) {
        handleOnLoadGetUserSubscriptions();
      }
    }
  });

  const handleOnLoadGetUserEmail = async () => {
    const query = `query {
			xProfileQuery {
                email
            }
		}`;

    const apiURL = `${getSitecoreGraphqlEndpoint()}&sc_lang=${sitecoreContext.language}`;
    await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        query
      })
    })
      .then(response => response.json())
      .then(data => {
        const email = data?.data?.xProfileQuery?.email;
        if (email) {
          setEmailValue(email);
        }
      });
  };

  const handleOnLoadGetUserSubscriptions = async () => {
    const apiURL = `${getSitecoreApiHost()}/api/subscriptions/getsubscriptions?sc_lang=${sitecoreContext.language}`;

    await fetch(apiURL, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSubscriptionData(data);
          setSubscriptionsAvailable(data.SubscriptionStates.length > 0);
        }
      });
  };

  const handleCheckboxChange = async (event, key) => {
    subscriptionData.SubscriptionStates.find(x => {
      if (x.ContactListId === key) {
        x.IsSubscribed = event.target.checked;
        return true;
      } else {
        return false;
      }
    });

    setSubscriptionData(subscriptionData);
  };

  const updateSubscriptions = async () => {
    setShowSaveConfirmation(false);

    const apiURL = `${getSitecoreApiHost()}/api/subscriptions/updatesubscriptions?sc_lang=${sitecoreContext.language}`;

    await fetch(apiURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        SubscriptionStates: subscriptionData.SubscriptionStates,
        ContextItemId: props.sitecoreContext.route.itemId
      })
    }).then(() => {
      setShowSaveConfirmation(true);
    });
  };

  return (
    <div className='MyNewsletters'>
      <div className='MyNewsletters-Wrapper'>
        <div className='MyNewsletters-Subscriptions'>
          <h3 className='MyNewsletters-Subscriptions-Title'>{t('MYACCOUNT_MYNEWSLETTERS | Title')}</h3>

          {emailValue && (
            <div className='MyNewsletters-Subscriptions-Text'>
              {t('MYACCOUNT_MYNEWSLETTERS | Your E-Mail-Address')} <b>{emailValue}</b>
            </div>
          )}

          {subscriptionsAvailable &&
            subscriptionData?.SubscriptionStates?.map(subscriptionSate => (
              <div className='MyNewsletters-Subscriptions-Row' key={subscriptionSate.ContactListId}>
                <label className='MyNewsletters-Subscriptions-Row-Selection'>
                  <input
                    id={subscriptionSate.ContactListId}
                    type={'checkbox'}
                    defaultChecked={subscriptionSate.IsSubscribed}
                    onChange={e => handleCheckboxChange(e, subscriptionSate.ContactListId)}
                  />
                  <span className='checkmark' />
                  {subscriptionSate.SubscriptionName}
                </label>
              </div>
            ))}

          {subscriptionsAvailable && (
            <div
              className='MyNewsletters-Subscriptions-Save'
              onClick={updateSubscriptions}
              onKeyDown={e => {
                if (e.target === 13) updateSubscriptions();
              }}
              role='button'
              tabIndex='0'
            >
              {t('MYACCOUNT_MYNEWSLETTERS | Save')}
            </div>
          )}

          {subscriptionData && !subscriptionsAvailable && (
            <div className='MyNewsletters-Subscriptions-Text'>
              {t('MYACCOUNT_MYNEWSLETTERS | No Newsletters Available')}
            </div>
          )}

          {showSaveConfirmation && (
            <div className='MyNewsletters-Subscriptions-Success'>
              {t('MYACCOUNT_MYNEWSLETTERS | Preferences Saved')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(MyNewsletters);
