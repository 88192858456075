import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsHeatGunsPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsHeatGunsSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Heat-Guns',
  LeisterGroup: 'Heat-Guns',
  LeisterTechnologies: 'Heat-Guns',
  Weldy: 'Heat-Guns'
};

export const HeatGunsFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimapplications',
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimdeviceshape',
    label: () => i18n.t('SEARCH | Facets pimdeviceshape'),
    multi: false
  },
  {
    field: 'pimnozzleconnection',
    label: () => i18n.t('SEARCH | Facets pimnozzleconnection'),
    multi: false
  },
  {
    field: 'pimfeatures',
    label: () => i18n.t('SEARCH | Facets pimfeatures')
  },
  {
    field: 'pimpowerma',
    label: () => i18n.t('SEARCH | Facets pimpowerma'),
    numeric: true,
    unit: 'W'
  },
  {
    field: 'pimtemperaturerangema',
    label: () => i18n.t('SEARCH | Facets pimtemperaturerangema'),
    numeric: true,
    unit: '°C'
  },
  {
    field: 'pimairflowrangema',
    label: () => i18n.t('SEARCH | Facets pimairflowrangema'),
    numeric: true,
    unit: 'l/min'
  },
  {
    field: 'pimweightwithoutpowercordmin',
    label: () => i18n.t('SEARCH | Facets pimweightwithoutpowercordmin'),
    numeric: true,
    precision: 2,
    unit: 'kg'
  }
];
