import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import DeviceCard from './DeviceCard';

class DeviceList extends React.Component {
  getCardList = () => {
    const { devices, fields } = this.props;

    return devices.slice(0, this.props.noDevicesToShow).map((item, index) => {
      return <DeviceCard key={index + item.name} fields={fields} item={item} hasDelete hasApprovalOverlay hasOptions />;
    });
  };

  getNoSearchResultCard = () => {
    const { 'No Search Results Text': noResultLabel } = this.props.fields;
    return (
      <div className='DeviceList-NoSearchResult'>
        <Text field={noResultLabel} />
      </div>
    );
  };

  render() {
    const { devices } = this.props;
    return (
      <div className='DeviceList'>
        {(devices && devices.length && this.getCardList()) || this.getNoSearchResultCard()}
      </div>
    );
  }
}

export default DeviceList;
