import React from 'react';
import { ShopProduct } from '../../../../Shopify/lib/shopify/types';
import AvailabilityTooltip from '../../../../Shopify/AvailabilityTooltip';
import Tag from '../../../Tag/Tag';

export function AvailabilityCell({ shopProduct, tags }: { shopProduct: ShopProduct; tags?: string[] }): JSX.Element {
  return (
    <div className='AvailabilityCell'>
      <AvailabilityTooltip shopProduct={shopProduct} />
      {tags &&
        tags.length > 0 &&
        tags.map(tag => (
          <Tag key={tag} type='secondary'>
            {tag}
          </Tag>
        ))}
    </div>
  );
}

export default AvailabilityCell;
