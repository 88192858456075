import React from 'react';
import i18n from 'i18next';
import Price from '../../Price/Price';
import type { Cart, Discount } from '../../lib/shopify/types';

import OpenCart from '../OpenCart';
import CartItem from '../CartItem';
import Cookies from 'js-cookie';
import { getShopifySettings, getSiteNameFromContext } from '../../../../AppGlobals';
import { SHOPIFY_MULTIPASS_ROUTE, SHOPIFY_MULTIPASS_TOKEN_ROUTE } from '../../lib/constants';
import { ActionType, CartContext } from '../../context/CartContext';
import { COUNTRY_CODE, SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME } from '../../../Common/CookieSettings/CookieConstants';
import { checkIfUserIsLoggedIn } from '../../../../utils/Utils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Markdown from 'react-markdown';
import AddToCartNotification from '../../AddToCartNotification';
import { getTaxText } from './taxText';
import { pushBeginCheckoutEvent } from '../../lib/shopify/analytics/beginCheckout';
import { pushViewCartEvent } from '../../lib/shopify/analytics/viewCart';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import SlideOver from '../../../Common/SlideOver';
import Button from '../../../Common/Button/Button';

export function CartModal({
  cart,
  sitecoreContext
}: {
  cart: Cart | undefined;
  sitecoreContext: any;
}): JSX.Element | null {
  const [isCheckoutLoading, setIsCheckoutLoading] = React.useState(false);
  const { state, dispatch } = React.useContext(CartContext);
  const openCart = () => {
    dispatch({ type: ActionType.OPEN_CART });

    if (state.cart) {
      pushViewCartEvent(state.cart);
    }
  };
  const closeCart = () => dispatch({ type: ActionType.CLOSE_CART });
  const cartTotalQuantity = cart?.totalQuantity || 0;
  const renderTaxText = getTaxText(Cookies.get(COUNTRY_CODE));
  const siteName = getSiteNameFromContext(sitecoreContext);
  const language: string = getLanguage(sitecoreContext).toUpperCase();

  const tryToLogInAndCheckout = async () => {
    const shopifyTestAccessKey = Cookies.get(SHOPIFY_TEST_ACCESS_KEY_COOKIE_NAME);
    const currentCountryCode = Cookies.get(COUNTRY_CODE)?.toLowerCase();
    const isUserLoggedIn = checkIfUserIsLoggedIn(sitecoreContext);

    if (!cart) return;

    // Set the cart to updating state and show loading spinner
    dispatch({
      type: ActionType.CART_UPDATING
    });
    setIsCheckoutLoading(true);

    pushBeginCheckoutEvent(cart);

    // Bail out to default checkout if user is not logged in
    if (!isUserLoggedIn) {
      goToCheckout();
      return;
    }

    // Logged in users will try to get multipass token
    try {
      const multipassResponse = await fetch(SHOPIFY_MULTIPASS_TOKEN_ROUTE(cart.checkoutUrl));

      let token: string | undefined;
      if (multipassResponse.status === 200) {
        token = await multipassResponse.text();
      }

      if (token) {
        const { domain } = await getShopifySettings(currentCountryCode, shopifyTestAccessKey, siteName);
        goToCheckout(SHOPIFY_MULTIPASS_ROUTE(domain, token));
      } else {
        // If multipass token is not available, redirect to checkout
        goToCheckout();
      }
    } catch (error) {
      console.error(error);
      goToCheckout();
    }
  };

  const goToCheckout = (destinationUrl = cart?.checkoutUrl) => {
    if (!destinationUrl) return;

    const url = new URL(destinationUrl);
    // Note: This must be uppercase to match the Shopify locale format
    url.searchParams.append('locale', language.toUpperCase());
    window.location.href = url.toString();
  };

  // The cart discounts are not grouped by title, so we need to do that manually
  const getSummaryOfCartDiscounts = (): Discount[] => {
    const discounts: Discount[] = [];

    cart?.discounts?.forEach(discount => {
      const { code, discountedAmount } = discount;
      const amount = parseFloat(discountedAmount?.amount);

      const existingDiscount = discounts.find(d => d.code === code);
      if (existingDiscount) {
        existingDiscount.discountedAmount.amount = (
          parseFloat(existingDiscount.discountedAmount.amount) + amount
        ).toString();
      } else {
        discounts.push({
          code,
          discountedAmount: { amount: amount.toString(), currencyCode: discountedAmount?.currencyCode }
        });
      }
    });
    return discounts;
  };
  const summaryOfCartDiscounts = getSummaryOfCartDiscounts();

  return (
    <>
      <button
        aria-label={i18n.t('SHOP | Open cart')}
        data-testid='open-cart'
        onClick={openCart}
        className='Modal__OpenCart'
      >
        <OpenCart quantity={cart?.totalQuantity} />
      </button>

      <AddToCartNotification />

      <SlideOver
        isOpen={state.isCartOpen ?? false}
        onClose={closeCart}
        title={`${i18n.t('SHOP | Cart title')} (${cartTotalQuantity})`}
      >
        <>
          {!cart || cart.lines.length === 0 ? (
            <div className='Cart__empty'>
              <p className='Cart__empty-text'>{i18n.t('SHOP | Empty cart')}</p>
              <button onClick={closeCart} className='Cart__empty-close button'>
                {i18n.t('SHOP | Continue shopping')}
              </button>
            </div>
          ) : (
            <div className='Cart__main'>
              <ul className='Cart__items'>
                {cart.lines.map(item => {
                  return (
                    <li key={item.id} className='Cart__item'>
                      <CartItem item={item} closeCart={closeCart} />
                    </li>
                  );
                })}
              </ul>
              <div className='Cart__bottom'>
                {summaryOfCartDiscounts.length > 0 && (
                  <>
                    <div className='Cart__sub-total' data-testid='cart-subtotal'>
                      <span>{i18n.t('SHOP | Subtotal')}</span>
                      <Price
                        amount={cart.cost.subtotalAmount.amount}
                        currencyCode={cart.cost.subtotalAmount.currencyCode}
                      />
                    </div>
                    <div className='Cart__discounts'>
                      {summaryOfCartDiscounts.map(discount => (
                        <div key={discount.code} className='Cart__discount' data-testid='cart-discount'>
                          <span>
                            {i18n.t('SHOP | Discount') + ': '}
                            {discount.code}
                          </span>
                          <Price
                            amount={discount.discountedAmount.amount}
                            currencyCode={discount.discountedAmount?.currencyCode}
                            prefix='-'
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {cart.cost.totalAmount && (
                  <div className='Cart__total' data-testid='cart-total'>
                    <span>{i18n.t('SHOP | Total')}</span>
                    <Price amount={cart.cost.totalAmount.amount} currencyCode={cart.cost.totalAmount?.currencyCode} />
                  </div>
                )}

                {renderTaxText && (
                  <Markdown className='Cart__tax-info Markdown--support-links'>{renderTaxText}</Markdown>
                )}
                <Button
                  onClick={tryToLogInAndCheckout}
                  disabled={state.isUpdating}
                  className='Cart__go-to-checkout'
                  buttonTitle={i18n.t('SHOP | To checkout')}
                  isLoading={isCheckoutLoading}
                />
              </div>
            </div>
          )}
        </>
      </SlideOver>
    </>
  );
}

export default withSitecoreContext()(CartModal);
