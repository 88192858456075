import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from '../../../../../Common/Icons/ArrowLeft';

class DeviceConfirmationButtons extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./DeviceConfirmationButtons.scss');
  }
  render() {
    const { addMoreDevicesButtonLabel, yesConfirmLabel } = this.props.fields,
      { onAddMoreClick, onConfirmData } = this.props;
    return (
      <div className='DeviceConfirmationButtons'>
        <div className='DeviceConfirmationButtons-Container'>
          <div
            onClick={onAddMoreClick}
            onKeyDown={e => {
              if (e.target === 13) onAddMoreClick();
            }}
            role='button'
            tabIndex='0'
            className='DeviceConfirmationButtons-AddMore'
          >
            <ArrowLeft />
            <Text field={addMoreDevicesButtonLabel} />
          </div>
          <div
            onClick={onConfirmData}
            onKeyDown={e => {
              if (e.target === 13) onConfirmData();
            }}
            role='button'
            tabIndex='0'
            className='DeviceConfirmationButtons-Confirm'
          >
            <Text field={yesConfirmLabel} />
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceConfirmationButtons;
