import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import SuccessIcon from '../../../../../Common/Icons/SuccessIcon';

class ConfirmationBanner extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./ConfirmationBanner.scss');
  }
  getStepRegistration = (stepClass, label) => {
    return (
      <div className={stepClass}>
        <SuccessIcon />
        <Text field={label} />
      </div>
    );
  };
  render() {
    const { addDevicesLabel, confirmationLabel } = this.props.fields,
      confirmationCssClass = classNames('ConfirmationBanner-Confirmation', { Confirmed: this.props.isConfirmed });
    return (
      <div className='ConfirmationBanner'>
        <div className='ConfirmationBanner-Container'>
          {this.getStepRegistration('ConfirmationBanner-AddDevices', addDevicesLabel)}

          <div className='ConfirmationBanner-Delimiter' />
          {this.getStepRegistration(confirmationCssClass, confirmationLabel)}
        </div>
      </div>
    );
  }
}

export default ConfirmationBanner;
