import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { setSalesAndServiceFilterOpenStatus } from '../../../../store/salesandservicefilters/actions';
import ChevronDownSmall from '../../Icons/ChevronDownSmall';
import ChevronUpSmall from '../../Icons/ChevronUpSmall';
import FilterIcon from '../../Icons/Filter';

class SalesAndServicePointsFilterHeader extends React.Component {
  getChevron = isOpen => (isOpen ? <ChevronUpSmall /> : <ChevronDownSmall />);

  render() {
    const { fields, isOpen, setIsOpenStatus } = this.props;
    if (!fields) {
      return <div className='SalesAndServicePointsFilterHeader' />;
    }

    return (
      <div
        className='SalesAndServicePointsFilterHeader'
        onClick={() => {
          setIsOpenStatus(!isOpen);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) setIsOpenStatus(!isOpen);
        }}
        role='button'
        tabIndex='0'
      >
        <span className='SalesAndServicePointsFilterHeader-FilterIcon'>
          <FilterIcon />
        </span>
        <span className='SalesAndServicePointsFilterHeader-Label'>
          <Text field={fields.Title} />
        </span>
        <span className='SalesAndServicePointsFilterHeader-Chevron'>{this.getChevron(isOpen)}</span>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOpen: state.salesAndServiceFilter.isOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsOpenStatus: value => dispatch(setSalesAndServiceFilterOpenStatus(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesAndServicePointsFilterHeader);
