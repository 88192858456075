export const TAGS = {
  collections: 'collections',
  products: 'products',
  cart: 'cart'
};

export const SHOPIFY_GRAPHQL_API_VERSION = '2024-04';
export const SHOPIFY_GRAPHQL_API_ENDPOINT = `/api/${SHOPIFY_GRAPHQL_API_VERSION}/graphql.json`;

export const SHOPIFY_MULTIPASS_ROUTE = (domain: string, token: string): string =>
  `${domain}/account/login/multipass/${token}`;

  let route = '/api/shopify/multipass';
export const SHOPIFY_MULTIPASS_TOKEN_ROUTE = (returnTo: string | undefined = undefined): string => {

  if (returnTo) {
    route += `?return_to=${encodeURIComponent(returnTo)}`;
  }

  return route;
};
