import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../GeneralLink/index';
import { SitecoreIcon } from '../../Common/Icon/SitecoreIcon';
/**
 * @class Component that renders an image with link.
 *
 * @param {object} props Object containing the image data and link needed for the component.
 *
 * Returns jsx markup containing an image and a link wrapped in a div.
 */
const IconLink = props => {
  if (!props.fields) {
    return <div className='IconLink' />;
  }
  const { Link, Icon, 'Text Link': textLink } = props.fields;
  return (
    <div className='IconLink'>
      <GeneralLink fields={Link}>
        <SitecoreIcon icon={Icon} size={16} className='IconLink-img' hoverIcon />
        <Text field={textLink} />
      </GeneralLink>
    </div>
  );
};

export default IconLink;
