import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsLaserGasDetectionPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsLaserGasDetectionSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Laser-Gas-Detection',
  LeisterGroup: 'Laser-Gas-Detection',
  LeisterTechnologies: 'Laser-Gas-Detection',
  Weldy: 'Laser-Gas-Detection'
};

export const LaserGasDetectionFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimtargetgas',
    label: () => i18n.t('SEARCH | Facets pimtargetgas')
  },
  {
    field: 'pimcelltemperature',
    label: () => i18n.t('SEARCH | Facets pimcelltemperature')
  }
];
