import classNames from 'classnames';
import React from 'react';
import i18n from 'i18next';
import { DownloadType, DownloadsList } from './types';
import { Text } from '@sitecore-jss/sitecore-jss-react';

type DownloadTabsProps = {
  selectedTab: DownloadType;
  changeTab: (tab: DownloadType) => void;
  allDownloads: Record<DownloadType, DownloadsList> | null;
  isUserLoggedIn: boolean;
};

const downloadsTabs: { key: DownloadType; name: string }[] = [
  { key: 'info', name: 'PRODUCT_DOWNLOADS | Information Material' },
  { key: 'media', name: 'PRODUCT_DOWNLOADS | Media Assets' }
];

const DownloadTabs: React.FC<DownloadTabsProps> = ({ selectedTab, changeTab, allDownloads, isUserLoggedIn }) => {
  if (!allDownloads) {
    return null;
  }

  return (
    <>
      {downloadsTabs
        .map((tab, index) => {
          if (allDownloads[tab.key] && (tab.key !== 'media' || (tab.key === 'media' && isUserLoggedIn))) {
            const cssClass = classNames('AllDownloads-Tabs-Tab', {
              'AllDownloads-Tabs-Tab Active': selectedTab === tab.key
            });

            return (
              <div
                key={'tab' + index}
                className={cssClass}
                role='button'
                tabIndex={0}
                onClick={() => changeTab(tab.key)}
                onKeyUp={e => {
                  if (e.key === '13') {
                    changeTab(tab.key);
                  }
                }}
              >
                <Text
                  field={{
                    value: i18n.t(tab.name)
                  }}
                />
              </div>
            );
          }

          return null;
        })
        .filter(item => item !== null)}
    </>
  );
};

export default DownloadTabs;
