import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

class ContactProductCard extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./ContactProductCard.scss');
  }
  render() {
    const { picture, name, articleNo, serialNo } = this.props.item,
      { 'Article Number Label': articleNumberLabel, 'Serial Number Label': serialLabel } = this.props.fields;
    return (
      <div className='ContactProductCard'>
        <div className='ContactProductCard-Image'>
          <img src={picture + '?imwidth=100'} alt={name} loading='lazy' />
        </div>
        <div className='ContactProductCard-Details'>
          <div className='ContactProductCard-Title'>{name}</div>
          {articleNo && (
            <div className='ContactProductCard-Article'>
              <Text field={articleNumberLabel} />
              &#58; {articleNo}
              {serialNo && (
                <React.Fragment>
                  &nbsp;|&nbsp;
                  <Text field={serialLabel} />
                  &#58;
                  {articleNo}
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ContactProductCard;
