import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import CloseIcon from '../Common/Icons/CloseIcon';
import classNames from 'classnames';

// NOTE: Whenever you come here and use this component, please migrate over to the new ConfirmationDialog component
class RemoveApprovalOverlay extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./RemoveApprovalOverlay.scss');
  }

  render() {
    const { isOverlay, onClose, DeleteText, DeleteButtonLabel, onClick, CancelDeleteLabel } = this.props,
      cssClass = classNames('RemoveApprovalOverlay', {
        'RemoveApprovalOverlay-Active': isOverlay
      });
    return (
      <div className={cssClass}>
        <div className='RemoveApprovalOverlay-DetailsContainer'>
          <div className='RemoveApprovalOverlay-Heading'>
            <Text field={DeleteText} tag='span' className='RemoveApprovalOverlay-Text' />
            <CloseIcon onClick={onClose} />
          </div>
          <div className='RemoveApprovalOverlay-Buttons'>
            <div
              className='RemoveApprovalOverlay-Buttons-DeleteButton'
              onClick={onClick}
              onKeyDown={e => {
                if (e.target === 13) {
                  onClick();
                }
              }}
              role='button'
              tabIndex='0'
            >
              <Text field={DeleteButtonLabel} />
            </div>
            <div
              className='RemoveApprovalOverlay-Buttons-CancelButton'
              onClick={onClose}
              onKeyDown={e => {
                if (e.target === 13) {
                  onClose();
                }
              }}
              role='button'
              tabIndex='0'
            >
              <Text field={CancelDeleteLabel} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveApprovalOverlay;
