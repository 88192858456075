import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';

export type UniversalSearchCareerResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const UniversalSearchCareerResultItem = ({ result, engine, siteName }: UniversalSearchCareerResultItemProps) => {
  const { raw } = result;
  const image = raw.jobz32ximage as string;
  const location = raw.location;
  const country = raw.country;
  const title = raw.jobz32xtitle;
  const resultLink = getResultUrl(raw, siteName);
  const company = raw.company;

  return (
    <div className='UniversalSearchCareerResultItem'>
      <div dangerouslySetInnerHTML={{ __html: image }}></div>
      <div className='UniversalSearchCareerResultItem__Wrapper'>
        <div className='UniversalSearchCareerResultItem__Wrapper-Info'>
          {location ? `${location}, ` : null}
          {country}
        </div>
        <div className='UniversalSearchCareerResultItem__Wrapper-Company'>{company}</div>
        <div className='UniversalSearchCareerResultItem__Wrapper-Title'>{title}</div>
        <ResultLink
          className='ResultLink--important ResultLink--larger'
          text={i18n.t('SEARCH | ResultItem Career Details label')}
          icon={<ArrowRight />}
          href={resultLink}
          engine={engine}
          result={result}
        />
      </div>
    </div>
  );
};

export default UniversalSearchCareerResultItem;
