import React from 'react';
import SubCategoriesItem from '../SubCategoriesItems/index';
import ArrowLeft from '../../../../Common/Icons/ArrowLeft';
import ChevronDown from '../../../../Common/Icons/ChevronDown';
import { Visible } from 'react-grid-system';

class SubCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubCategory: null,
      showItems: 1
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.accessories) !== JSON.stringify(this.props.accessories)) {
      this.setState({
        activeSubCategory: null,
        showItems: 1
      });
    }
  }

  onSubCategoryClick = item => {
    this.setState({
      activeSubCategory: item
    });
  };

  getUniqueSubCategories = () => {
    const accessories = this.props.accessories,
      articleLabel = this.props.articleLabel.value,
      filteredCategory = accessories.filter(val => {
        return val.category === this.props.activeCategoryName;
      }, this),
      subCategories = filteredCategory[0] && filteredCategory[0].Types ? filteredCategory[0].Types : [];

    return subCategories.map((item, index) => {
      return (
        <div className='SubCategory-Wrapper' key={item.Name + index}>
          <div
            className='SubCategory'
            onClick={() => {
              this.onSubCategoryClick(item);
            }}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onSubCategoryClick(item);
            }}
            role='button'
            tabIndex='0'
          >
            <div className='SubCategory-Image'>
              {item.imageUrl ? <img src={item.imageUrl + '?imwidth=320'} alt={item.Name} loading='lazy' /> : null}
            </div>
            <div className='SubCategory-Name'>{item.Name}</div>
            <div className='SubCategory-Articles'>
              {item.Items ? item.Items.length : null} {articleLabel}
            </div>
          </div>
        </div>
      );
    });
  };

  getSubCategoryItems = () => {
    return this.state.activeSubCategory.Items || [];
  };
  /**
   * @method handleBack
   * @description onClick displays SubCategories , and reset state to initial values
   */
  handleBack = () => {
    this.setState({ activeSubCategory: null, showItems: 1 });
  };
  /**
   * @method incrementShowItems
   * @description increment showItems from old state whith one
   */
  incrementShowItems = () => {
    this.setState(state => ({
      showItems: state.showItems + 1
    }));
  };
  /**
   * @method getShowNextLabel
   * @description Display the label "Show x more" if there are more  items than showed
   * @returns jxs item or empty string
   */

  getShowNextLabel = () => {
    const subCategoryItems = this.getSubCategoryItems();
    const remainingItems = subCategoryItems.length - this.state.showItems * 9;
    if (remainingItems > 0) {
      const numberToShow = remainingItems > 9 ? 9 : remainingItems;
      return (
        <div
          className='Accessories-Bottom-ShowMore'
          onClick={this.incrementShowItems}
          onKeyUp={e => {
            if (e.key === 13) {
              this.incrementShowItems();
            }
          }}
          role='button'
          tabIndex={0}
        >
          <ChevronDown />
          {this.props.show + ' ' + numberToShow + ' ' + this.props.more}
        </div>
      );
    }
    return null;
  };
  getBackButton = label => {
    return (
      <div
        className='Accessories-Bottom-BackButton'
        onClick={this.handleBack}
        onKeyDown={e => {
          if (e.keyCode === 13) this.handleBack();
        }}
        role='button'
        tabIndex='0'
      >
        <ArrowLeft />
        {label}
      </div>
    );
  };

  getItems = () => {
    const activeSubCategory = this.state.activeSubCategory;
    if (activeSubCategory) {
      const subCategoryItems = this.getSubCategoryItems();
      return (
        <React.Fragment>
          <div className='Accessories-Bottom-TitleWrapper'>
            <Visible md sm>
              {this.getBackButton()}
            </Visible>
            <div className='Accessories-Bottom-Title'>
              {(activeSubCategory.Name || '') + ' '}
              <span className='Accessories-Bottom-Title-ItemNumbers'>({subCategoryItems.length})</span>
            </div>
            <Visible xl lg>
              {this.getBackButton(this.props.backButtonLabel)}
            </Visible>
          </div>
          <div className='Accessories-Bottom-Categories'>
            <SubCategoriesItem
              categoryName={activeSubCategory.Name}
              items={subCategoryItems}
              itemsToShow={9 * this.state.showItems}
              accessoryDetailPageLink={this.props.accessoryDetailPageLink}
            />
          </div>
          {this.getShowNextLabel()}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className='Accessories-Bottom-Categories WithoutTitle'>{this.getUniqueSubCategories()}</div>
      </React.Fragment>
    );
  };

  render() {
    return <div className='Accessories-SubCategories'>{this.props.activeCategoryName ? this.getItems() : ''}</div>;
  }
}

export default SubCategories;
