import React from 'react';
import { connect } from 'react-redux';
import { setCurrentCoordinates } from '../../../store/salesandservicefilters/actions';
import { checkServicesAndSolutionsFilter } from '../SalesAndServiceCentersHolderTwoColumns/SalesAndServicePointsList/utils';
import CustomMarker from './CustomMarker';

class MarkerList extends React.Component {
  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.bounds) !== JSON.stringify(this.props.bounds)) {
      this.visibleMarkers();
    }
  }

  extractToStringArray = array => {
    return array.map(item => {
      return item.id.value;
    });
  };

  generateMarkers = () => {
    const { coordinates, storeFilters, isLinkForQuote, isRestrictedUser, mainContactPoint } = this.props;

    return coordinates
      .map((element, index) => {
        const contactPointMatchesFilter = checkServicesAndSolutionsFilter(element, this.props.storeFilters);
        const noFiltersEnabled = storeFilters.length === 0;
        const isMainContactPoint = this.props.mainContactPoint && mainContactPoint.id === element.id;
        const checkRestrictedContact =
          element.info &&
          element.info.isRestrictedContact &&
          element.info.isRestrictedContact.value &&
          element.info.isRestrictedContact.value === '1'
            ? true
            : false;

        if (isLinkForQuote && isRestrictedUser && checkRestrictedContact) {
          return null;
        }
        // Also the main contact point should be visible on the map
        if (noFiltersEnabled || contactPointMatchesFilter || isMainContactPoint) {
          return <CustomMarker item={element} key={element.name + index} isRestrictedUser={isRestrictedUser} />;
        }
        return null;
      })
      .filter(element => element !== null);
  };

  visibleMarkers() {
    const bounds = this.props.bounds,
      coordinates = this.props.coordinates,
      nextCoordinates = [];

    if (bounds) {
      for (let i = 0; i < coordinates.length; i++) {
        const nLat = Number(coordinates[i].lat),
          nLon = Number(coordinates[i].lon);

        if (bounds.contains({ lat: nLat, lng: nLon }) === true) {
          nextCoordinates.push(coordinates[i]);
        }
      }

      this.props.setNextCoordinatesInfo(nextCoordinates);
    }
  }

  render() {
    return <React.Fragment>{this.generateMarkers()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    storeFilters: state.salesAndServiceFilter.filters,
    coordinates: state.salesAndServiceFilter.coordinates,
    bounds: state.salesAndServiceFilter.bounds,
    isLinkForQuote: state.productDetails.isLinkForQuote,
    mainContactPoint: state.salesAndServiceFilter.mainContactPoint
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNextCoordinatesInfo: items => dispatch(setCurrentCoordinates(items))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkerList);
