import React from 'react';
import i18n from 'i18next';
import { SearchResultItemProps } from './types';
import { Link } from 'react-router-dom';
import { buildInteractiveResult } from '@coveo/headless';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { normalizeDownloadPagePath } from '../../../Product/AllDownloads/getDownloadsProductAndArticle';

export const DownloadResultItem: React.FC<SearchResultItemProps> = ({ result, engine, sitecoreContext }) => {
  const { raw } = result;
  const mainPicture = raw.mainpicture320 ? (raw.mainpicture320 as string) : (raw.mainpicture as string);
  const variantNameTranslated = raw.variantnametranslated ? raw.variantnametranslated : raw.masterproductname;
  const pimMetaDescription = raw.pimmetadescription;
  const productName = raw.productassetnamenormalized;
  const isPhasedOut = raw.isphasedout === 'true';

  const interactiveResultController = buildInteractiveResult(engine, {
    options: { result }
  });

  return (
    <div className='ProductResultItem ProductResultItem--downloads'>
      <Link
        to={{
          pathname: `${normalizeDownloadPagePath(
            sitecoreContext?.productDownloads?.productDownloadsDetailPage
          )}/${productName}`,
          state: { from: `${window.location.pathname}${window.location.search}${window.location.hash}` }
        }}
        className='ProductResultItem__Link CoveoResultLink'
        onClick={() => {
          interactiveResultController.select();
        }}
      >
        <img src={mainPicture} loading='lazy' decoding='async' alt='' />
        <div className='ProductResultItem__Wrapper'>
          <h3 className='ProductResultItem__Wrapper-Title'>{variantNameTranslated}</h3>
          {isPhasedOut && (
            <div className='PhasedOut__Badge ProductResultItem__PhasedOut'>
              {i18n.t('PRODUCT_DOWNLOADS | Phased out')}
            </div>
          )}
          <div className='ProductResultItem__Wrapper-Description'>{pimMetaDescription}</div>
        </div>
        <div className='ProductResultItem__Downloads-Label'>
          <div className='ProductResultItem__Downloads-Label-Text'>{i18n.t('PRODUCT_DOWNLOADS | Downloads label')}</div>
          <ArrowRight />
        </div>
      </Link>
    </div>
  );
};

export default withSitecoreContext()(DownloadResultItem);
