import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

class TitleAndDescription extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./TitleAndDescription.scss');
  }
  render() {
    const { title, description } = this.props.fields;
    return (
      <div className='TitleAndDescription componentContainer'>
        <Text field={title} className='TitleAndDescription-Title' tag='h1' />
        <RichText field={description} className='TitleAndDescription-Description' />
      </div>
    );
  }
}

export default TitleAndDescription;
