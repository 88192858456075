import React from 'react';
import { Visible } from 'react-grid-system';
import ProductCarouselThumbnailsComponent from './ProductCarouselThumbnailsComponent/index';

/**
 * @method ProductCarouselThumbnails
 *
 * @description Return a carousel when over 4 images are given or a list of images if bellow
 *
 * @returns jsx
 */
class ProductCarouselThumbnails extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Visible xs sm md>
          <ProductCarouselThumbnailsComponent isMobile={true} />
        </Visible>
        <Visible lg xl>
          <ProductCarouselThumbnailsComponent isMobile={false} />
        </Visible>
      </React.Fragment>
    );
  }
}

export default ProductCarouselThumbnails;
