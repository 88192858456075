import React from 'react';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

/**
 * Function that creates the list of link for the component.
 *
 * @param {object} fields Variable that contains the values (text, href) for the links.
 *
 * Returns a list of links.
 */
const renderLinks = function (fields) {
  if (fields.multilistFieldName) {
    const fieldsCopy = fields.multilistFieldName;
    return fieldsCopy.map((field, index) => {
      return (
        <div className='FooterLinkColumn-link' key={index}>
          <Link field={field.fields.Link} />
        </div>
      );
    });
  }
};

/**
 * @class Component that renders the links in the Footer component.
 *
 * @param {object} fields Object that contains the data for all the links.
 *
 * @returns Returns HTML.
 */
const FooterLinkColumn = ({ fields }) => (
  <div className='FooterLinkColumn'>
    <div className='FooterLinkColumn-title'>
      <Text field={fields.title} />
    </div>
    {renderLinks(fields)}
  </div>
);

export default FooterLinkColumn;
