import React from 'react';

interface VideoSlideProps {
  video: string;
  poster?: string;
}

const VideoSlide: React.FC<VideoSlideProps> = ({ video, poster, ...props }) => {
  return (
    <div className='VideoSlide' {...props}>
      <video data-testid='video' src={video} autoPlay loop playsInline muted poster={poster} preload='auto' />
    </div>
  );
};

export default VideoSlide;
