import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import ContactPointInfo from '../SalesAndServiceCentersHolderTwoColumns/ContactPointInfo/index';
import OutsideClickHandler from 'react-outside-click-handler';

class CustomMarker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlace: null
    };
  }

  onMarkerClick = props => {
    this.setState({
      selectedPlace: props
    });
  };

  closeInfoWindow = () => {
    this.setState({ selectedPlace: null });
  };

  isMarkerVisible = () => {
    return true;
  };

  render() {
    const { lat, lon } = this.props.item,
      nLat = Number(lat),
      nLon = Number(lon),
      targetIt = this.props.item.info.kindOfPartner.targetItem,
      iconUrl = targetIt ? targetIt.icon.jss.value.src || '' : '';

    return (
      <Marker
        icon={iconUrl}
        position={{ lat: nLat, lng: nLon }}
        onClick={this.onMarkerClick}
        onVisibleChanged={this.isMarkerVisible}
      >
        {this.state.selectedPlace && (
          <InfoWindow
            position={{ lat: nLat, lng: nLon }}
            options={{
              pixelOffset: { width: 0, height: -48 },
              maxWidth: 280
            }}
            onCloseClick={this.closeInfoWindow}
          >
            <OutsideClickHandler onOutsideClick={this.closeInfoWindow}>
              <ContactPointInfo {...this.props.item} isRestrictedUser={this.props.isRestrictedUser} />
            </OutsideClickHandler>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

export default CustomMarker;
