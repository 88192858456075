import React from 'react';
import ChevronRightSmall from '../../../../../Common/Icons/ChevronRightSmall';

class CompareTechnicalSpecsNavigationHeader extends React.Component {
  onArrowClick = incrementNumber => {
    const { items, currentItemIndex, onSpecChanged } = this.props;

    let toIncrementIndex = currentItemIndex + incrementNumber;

    if (toIncrementIndex < 0) {
      toIncrementIndex = items.length - 1;
    }

    if (toIncrementIndex > items.length - 1) {
      toIncrementIndex = 0;
    }

    onSpecChanged(items[toIncrementIndex], toIncrementIndex);
  };

  render() {
    const { items, currentItemIndex } = this.props;

    return (
      <div className='CompareTechnicalSpecsNavigationHeader'>
        <div
          className='CompareTechnicalSpecsNavigationHeader-ArrowLeft'
          onClick={() => this.onArrowClick(-1)}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onArrowClick(-1);
          }}
          role='button'
          tabIndex='0'
        >
          <ChevronRightSmall />
        </div>
        <div className='CompareTechnicalSpecsNavigationHeader-SpecsName'>{items[currentItemIndex]}</div>
        <div
          className='CompareTechnicalSpecsNavigationHeader-ArrowRight'
          onClick={() => this.onArrowClick(1)}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onArrowClick(1);
          }}
          role='button'
          tabIndex='0'
        >
          <ChevronRightSmall />
        </div>
      </div>
    );
  }
}

export default CompareTechnicalSpecsNavigationHeader;
