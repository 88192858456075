import React from 'react';
import { FieldsetProps } from './types';
import classNames from 'classnames';

const Fieldset = ({ className, legend, children }: FieldsetProps): JSX.Element => {
  return (
    <div className={classNames('fieldset', className)}>
      {legend && <legend>{legend}</legend>}
      {children}
    </div>
  );
};

export default Fieldset;
