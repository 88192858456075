import React from 'react';
import { withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setValueInput } from '../../../utils/Utils';
import { setProductForGetAQuoteLink } from '../../../store/product/actions';
import { getCookie } from '../../Common/CookieSettings/CookieUtils';
import { LANG } from '../../Common/CookieSettings/CookieConstants';
import { getSitecoreApiHost } from '../../../AppGlobals';
import { PRODUCT_PAGE_ROUTE } from '../../../AppRoutes';

class ContactOneColumn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToShow: 3,
      uploadInputToShow: 0
    };
  }

  // Removed Wishlist feature: Lets log if this case happens in the future somewhere
  wishlistFeatureRemoved() {
    console.log('Wishlist is not supported within ContactOneColumn anymore, use ContactTwoColumnNew instead');
  }

  componentDidMount() {
    const { rendering, fields } = this.props,
      { 'Get A Quote Form': quoteForm } = fields,
      formName =
        rendering.placeholders['jss-form'] &&
        rendering.placeholders['jss-form'][0] &&
        rendering.placeholders['jss-form'][0].fields &&
        rendering.placeholders['jss-form'][0].fields.metadata &&
        rendering.placeholders['jss-form'][0].fields.metadata.name;

    if (formName === quoteForm.value) {
      this.wishlistFeatureRemoved();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.fields) {
      const { rendering, fields } = this.props,
        { 'Get A Quote Form': quoteForm } = fields,
        formName =
          rendering.placeholders['jss-form'] &&
          rendering.placeholders['jss-form'][0] &&
          rendering.placeholders['jss-form'][0].fields &&
          rendering.placeholders['jss-form'][0].fields.metadata &&
          rendering.placeholders['jss-form'][0].fields.metadata.name;

      this.setEmailInHiddenInput(prevProps, prevState);
      this.setProductUrlInHiddenInput();
      this.displayAllFileUpload();
      if (formName === quoteForm.value) {
        this.wishlistFeatureRemoved();
      }
    }
  }

  /**
   * @method checkIFWeHaveInput
   * @description  check if the element exists in html and returns the element
   * @param {string} selector the css selector of the checked element
   */

  checkIFWeHaveInput = async selector => {
    while (document.querySelector(selector) === null && document.querySelector('form') === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }

    return document.querySelector(selector);
  };

  async setEmailInHiddenInput(prevProps, prevState) {
    const { currentInfoSalesEmail, contactInfoDetails } = this.props,
      { name, street, number, city, country } = contactInfoDetails;

    if (currentInfoSalesEmail !== prevProps.currentInfoSalesEmail && currentInfoSalesEmail !== null) {
      const emailInputElement = await this.checkIFWeHaveInput('.ContactInfoEmail input'),
        detailsInputElement = await this.checkIFWeHaveInput('.ContactInfoDetails input'),
        contactAddress =
          name +
          this.setComma(name) +
          street +
          ' ' +
          number +
          this.setComma(number || street) +
          city +
          this.setComma(city && country) +
          country;

      setValueInput(emailInputElement, currentInfoSalesEmail);
      setValueInput(detailsInputElement, contactAddress);
    }
  }

  setComma = item => (item ? ', ' : '');

  async setProductUrlInHiddenInput() {
    const { productPageLinkForGetAQuote } = this.props,
      productLinkInputElement = await this.checkIFWeHaveInput('.ProductLink input');
    if (
      productPageLinkForGetAQuote &&
      productLinkInputElement &&
      productLinkInputElement.value !== productPageLinkForGetAQuote
    ) {
      setValueInput(productLinkInputElement, productPageLinkForGetAQuote);
    }
  }

  async displayFileUpload(fileInputClass) {
    const inputFileElement = await this.checkIFWeHaveInput(fileInputClass);
    if (inputFileElement) {
      inputFileElement.addEventListener('change', this.onInputFileChange);
      const closeIcon = document.createElement('span'),
        textClose = document.createTextNode('+');
      closeIcon.classList.add('FormFileUploadClose');
      closeIcon.appendChild(textClose);
      if (!inputFileElement.nextElementSibling) {
        inputFileElement.insertAdjacentElement('afterend', closeIcon);
      }
      closeIcon.addEventListener('click', this.onFileClose);
    }
  }

  onInputFileChange = e => {
    const element = e.target;
    const closeElement = element.nextElementSibling;
    if (element.value !== '') {
      element.style.display = 'inline-block';
      closeElement.style.display = 'inline-block';
    } else {
      element.style.display = 'none';
      closeElement.style.display = 'none';
    }
  };

  onFileClose = e => {
    const element = e.target,
      inputFile = element.previousElementSibling;
    inputFile.value = '';
    inputFile.style.display = 'none';
    element.style.display = 'none';
  };

  displayAllFileUpload = () => {
    this.displayFileUpload('.Form-FileUpload.File-0 input');
    this.displayFileUpload('.Form-FileUpload.File-1 input');
    this.displayFileUpload('.Form-FileUpload.File-2 input');
    this.displayFileUpload('.Form-FileUpload.File-3 input');
    this.displayFileUpload('.Form-FileUpload.File-4 input');
    const allInputUpload = document.querySelectorAll('div.Form-FileUpload');
    [...allInputUpload].forEach((item, index) => {
      if (index === this.state.uploadInputToShow) {
        item.style.display = 'block';
      }
    });

    this.getLoadMoreUploadButton();
  };

  incrementDisplayedUploadInput = () => {
    const numberToIncrement = 1;
    this.setState(state => ({
      uploadInputToShow: state.uploadInputToShow + numberToIncrement
    }));
  };

  async getLoadMoreUploadButton() {
    const buttonFileElement = await this.checkIFWeHaveInput('.LoadMoreUploadInput p');
    if (buttonFileElement) {
      buttonFileElement.addEventListener('click', this.incrementDisplayedUploadInput);
    }
    const numberOfInputFileUpload = document.querySelectorAll('div.Form-FileUpload').length;
    if (buttonFileElement && numberOfInputFileUpload === this.state.uploadInputToShow + 1) {
      buttonFileElement.style.display = 'none';
    }
  }

  incrementDisplayedItems = () => {
    const numberToIncrement = 1;
    this.setState(state => ({
      itemsToShow: state.itemsToShow + numberToIncrement
    }));
  };

  getProductsLink = data => {
    const currentLang = getCookie(LANG);

    let links = [];
    data.map(item => {
      const link =
        getSitecoreApiHost() +
        '/' +
        currentLang +
        PRODUCT_PAGE_ROUTE +
        '/' +
        item.masterProductNameEn.replace(/ /g, '-') +
        '/' +
        item.articleNo.replace('.', '-');
      links.push(link);

      return links;
    });
    return links.join('; ');
  };

  render() {
    if (!this.props.fields || !this.props.rendering) {
      return <div className='ContactOneColumn componentContainer' />;
    }
    return (
      <div className='ContactOneColumn componentContainer'>
        <div className='ContactOneColumn-Container'>
          <Placeholder name='jss-form' rendering={this.props.rendering} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentInfoSalesEmail: state.contactForms.currentInfoSalesEmail,
    contactInfoDetails: state.contactInfoDetails,
    productPageLinkForGetAQuote: state.productDetails.productPageLinkForGetAQuote
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setProductForGetAQuoteLink: link => dispatch(setProductForGetAQuoteLink(link))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(withRouter(ContactOneColumn)));
