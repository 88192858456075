import React from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { MapScriptLoader } from '../../../../lib/MapScriptLoader';
import { setSearchLocation } from '../../../../store/salesandservicefilters/actions';

class SearchLocationInput extends React.Component {
  onLoad = ref => (this.searchBox = ref);
  onPlacesChanged = () => {
    const locations = this.searchBox.getPlaces();
    if (locations.length > 0) {
      this.props.setNewLocation({
        lat: locations[0].geometry.location.lat(),
        lng: locations[0].geometry.location.lng()
      });
    }
  };

  render() {
    return (
      <MapScriptLoader>
        <StandaloneSearchBox onLoad={this.onLoad} onPlacesChanged={this.onPlacesChanged}>
          <div className='SalesAndServiceCentersHolderTwoColumns-LocationInput'>
            <input type='text' placeholder={this.props.searchBoxLabel} />
          </div>
        </StandaloneSearchBox>
      </MapScriptLoader>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNewLocation: location => dispatch(setSearchLocation(location))
  };
};

export default connect(null, mapDispatchToProps)(SearchLocationInput);
