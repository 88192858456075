import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Toggle from '../../../Common/Toggle/Toggle';
import Button from '../../../Common/Button/Button';
import { FloatLabel } from '../../../Forms/FloatLabel';
import TextInput from '../../../Common/TextInput/TextInput';
import Fieldset from '../../../Common/Form/Fieldset/Fieldset';
import Select from '../../../Common/Form/Select/Select';
import { Address, CountryOption, Province } from '../types';
import DeleteAddress from '../DeleteAddress';
import { createAddress, updateAddress } from '../../../../store/accountInformation/actions';
import { connect } from 'react-redux';

type EditAddressFormProps = {
  address?: Address;
  countries: CountryOption[];
  provinces: Province[];
  createAddress: (address: Address) => void;
  updateAddress: (address: Address) => void;
  onClose: () => void;
};

const EditAddressForm = ({
  createAddress,
  updateAddress,
  onClose,
  countries,
  provinces,
  address = {
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    company: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    countryCode: '',
    provinceCode: '',
    isDefault: false
  }
}: EditAddressFormProps): JSX.Element => {
  const [isBusinessAddress, setIsBusinessAddress] = React.useState(!!address?.company);
  const [isDefaultAddress, setIsDefaultAddress] = React.useState(address?.isDefault);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  useEffect(() => {
    FloatLabel.init();
  }, []);

  const handleIsBuissnesAddressChange = (checked: boolean) => {
    setIsBusinessAddress(checked);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | First name required validation message')),
    lastName: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | Last name required validation message')),
    phone: Yup.string().nullable(),
    isBusinessAddress: Yup.boolean(),
    isDefault: Yup.boolean(),
    company: Yup.string()
      .nullable()
      .when('isBusinessAddress', {
        is: true,
        then: schema => schema.required(i18n.t('ADDRESS_MANAGEMENT | Company name required validation message'))
      }),
    address1: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | Address required validation message')),
    address2: Yup.string().nullable(),
    zip: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | ZIP code required validation message')),
    city: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | City required validation message')),
    countryCode: Yup.string().required(i18n.t('ADDRESS_MANAGEMENT | Country required validation message')),
    provinceCode: Yup.string().nullable()
  });

  return (
    <>
      <Formik
        initialValues={{
          ...address,
          isBusinessAddress
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { id, isBusinessAddress, company, ...addressData } = values;
          const addressToSave = addressData as Address;

          if (isBusinessAddress) {
            addressToSave.company = company;
          }

          // means we're editing an existing address
          if (address?.id) {
            addressToSave.id = address.id;
            updateAddress(addressToSave);
          } else {
            createAddress(addressToSave);
          }

          setSubmitting(false);
          onClose();
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <form className='EditAddressForm' onSubmit={handleSubmit}>
            <div className='EditAddressForm__Content'>
              <Fieldset legend={i18n.t('ADDRESS_MANAGEMENT | Contact person fieldset legend')}>
                <TextInput
                  label={i18n.t('ADDRESS_MANAGEMENT | First name field label')}
                  required
                  name='firstName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                <TextInput
                  label={i18n.t('ADDRESS_MANAGEMENT | Last name field label')}
                  required
                  name='lastName'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                <TextInput
                  label={i18n.t('ADDRESS_MANAGEMENT | Phone field label')}
                  name='phone'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                />
              </Fieldset>
              <hr className='hr hr--spacing-bottom' />
              <Fieldset>
                <Toggle
                  label={i18n.t('ADDRESS_MANAGEMENT | Business address toggle field label')}
                  onChange={event => {
                    handleIsBuissnesAddressChange(event.target.checked);
                    handleChange(event);
                  }}
                  name={'isBusinessAddress'}
                  initialChecked={isBusinessAddress}
                />
                {isBusinessAddress && (
                  <TextInput
                    label={i18n.t('ADDRESS_MANAGEMENT | Company field label')}
                    required
                    name='company'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company ?? ''}
                  />
                )}
              </Fieldset>
              <hr className='hr hr--spacing-bottom' />
              <Fieldset legend={i18n.t('ADDRESS_MANAGEMENT | Address fieldset legend')}>
                <TextInput
                  label={i18n.t('ADDRESS_MANAGEMENT | Address line 1 field label')}
                  required
                  name='address1'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address1}
                />
                <TextInput
                  label={i18n.t('ADDRESS_MANAGEMENT | Address line 2 field label')}
                  name='address2'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address2}
                />
                <div className='form-row'>
                  <TextInput
                    label={i18n.t('ADDRESS_MANAGEMENT | Zip code field label')}
                    required
                    fieldSize='one-third'
                    name='zip'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                  />
                  <TextInput
                    label={i18n.t('ADDRESS_MANAGEMENT | City field label')}
                    required
                    fieldSize='two-thirds'
                    name='city'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                </div>
                <Select
                  label={i18n.t('ADDRESS_MANAGEMENT | Country field label')}
                  required
                  name='countryCode'
                  value={values.countryCode}
                  options={countries}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setFieldValue('provinceCode', '');
                    handleChange(event);
                  }}
                />
                {provinces?.filter(province => province.CountryCode === values.countryCode)?.length > 0 && (
                  <Select
                    label={i18n.t('ADDRESS_MANAGEMENT | Province field label')}
                    name='provinceCode'
                    value={values.provinceCode}
                    options={
                      provinces
                        .filter(province => province.CountryCode === values.countryCode)
                        .map(province => ({
                          value: province.ProvinceCode,
                          label: province.Name
                        })) || []
                    }
                    onChange={handleChange}
                  />
                )}
                <hr className='hr' />
                <Toggle
                  label={i18n.t('ADDRESS_MANAGEMENT | Set as default toggle field label')}
                  initialChecked={isDefaultAddress}
                  onChange={event => {
                    setIsDefaultAddress(event.target.checked);
                    handleChange(event);
                  }}
                  name={'isDefault'}
                />
                <hr className='hr' />
              </Fieldset>
              <div className='EditAddressForm__Disclaimer'>{i18n.t('ADDRESS_MANAGEMENT | Mandatory info')}</div>
            </div>

            <div className='EditAddressForm__Footer'>
              <Button
                type='submit'
                buttonTitle={
                  address?.id
                    ? i18n.t('ADDRESS_MANAGEMENT | Edit address confirm button')
                    : i18n.t('ADDRESS_MANAGEMENT | Create address confirm button')
                }
                size='large'
                disabled={isSubmitting}
              />
              {address?.id && (
                <Button
                  buttonTitle={i18n.t('ADDRESS_MANAGEMENT | Delete address button')}
                  onClick={() => setIsDeleteDialogOpen(true)}
                  size='large'
                  variant='secondary'
                  disabled={isSubmitting}
                />
              )}
            </div>
          </form>
        )}
      </Formik>
      {isDeleteDialogOpen && address.id && (
        <DeleteAddress
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          onConfirm={onClose}
          addressId={address.id}
        />
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  const { countries } = state;

  return {
    countries: countries?.countries || [],
    provinces: countries?.provinces || []
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => {
  return {
    createAddress: (address: Address) => dispatch(createAddress(address)),
    updateAddress: (address: Address) => dispatch(updateAddress(address))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressForm);
