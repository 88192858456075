import React from 'react';
import ThreeColumnLayout from '../OneSectionLayout';
import TwoSectionsLayout from '../TwoSectionsLayout';
import ThreeColumnAllTitleSectionLayout from '../ThreeSectionsLayout';
import { Container } from 'react-grid-system';

/**
 * @class Component that renders a layout (conditionally) for the flyout menu.
 *
 * @param {string} layout String containing the layout that needs to be rendered.
 *
 * Returns HTML.
 */
class FlyoutFactory extends React.Component {
  /**
   * @method getComponent
   * @description Function that checks which layout needs to be rendered
   * based on a parameter (layout). Also has a default layout that can be rendered.
   *
   * Returns HTMl containing all of the above items.
   */
  getComponent() {
    switch (this.props.layout) {
      case 'THREE_COLUMN_LAYOUT':
        return <ThreeColumnLayout items={this.props.items} showImage={this.props.showImage} />;
      case 'THREE_COLUMN_TITLE_LAYOUT':
        return <TwoSectionsLayout items={this.props.items} showImage={this.props.showImage} />;
      case 'THREE_COLUMN_ALL_TITLE_LAYOUT':
        return <ThreeColumnAllTitleSectionLayout items={this.props.items} showImage={this.props.showImage} />;
      default:
        return <ThreeColumnLayout />;
    }
  }

  render() {
    return (
      <Container fluid className='FlyoutFactory-Container'>
        {this.getComponent()}
      </Container>
    );
  }
}

export default FlyoutFactory;
