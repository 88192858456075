import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import CategoryPipeDate from '../../Common/CategoryPipeDate/index';

const StoryProductCard = ({ fields }) => (
  <div className='StoryProductCard'>
    <Image className='StoryProductCard-image' field={fields.Image} />
    <div className='StoryProductCard-details'>
      <CategoryPipeDate fields={fields} />
      <Text tag='div' className='StoryProductCard-details-title' field={fields.Description} />
      <Link className='StoryProductCard-details-link' field={fields.Link} />
    </div>
  </div>
);

export default StoryProductCard;
