import React from 'react';
import gql from 'graphql-tag';
import { Image, Text, RichText, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import GraphQLData from '../../../lib/GraphQLData';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';
import GeneralLink from '../../Common/GeneralLink';

const getBackToInfoForExpertiseQuery = gql`
  query getBackToInfoForExpertiseQuery($previousItemId: String, $curentItemId: String, $lang: String) {
    getBackToInfoForExpertiseQuery(previousItemId: $previousItemId, curentItemId: $curentItemId, lang: $lang) {
      url
      description
    }
  }
`;

class Expertise extends React.Component {
  showLinkBack = () => {
    const { 'Back To Label': backTo } = this.props.fields;

    const queryData = this.props.queryExpertiseData && this.props.queryExpertiseData.getBackToInfoForExpertiseQuery;

    if (!queryData || !queryData.url || !queryData.description) {
      return <div className='Expertise-Button' />;
    }
    return (
      <GeneralLink fields={{ value: { href: queryData.url } }} className='Expertise-Button'>
        <ArrowLeft />
        <Text field={backTo} />
        &nbsp;{queryData.description}
      </GeneralLink>
    );
  };

  getSocialLink = () => {
    const jssSharePlaceholder = this.props.rendering.placeholders['jss-share-social-link'];

    const cssClass = classNames('Expertise-SocialLinkWrapper', {
      'Expertise-SocialLinkWrapper-Hide': jssSharePlaceholder.length === 0
    });

    return (
      <div className={cssClass}>
        <Placeholder name='jss-share-social-link' rendering={this.props.rendering} />
      </div>
    );
  };

  render() {
    if (!this.props.fields) {
      return <div className='Expertise' />;
    }
    const { fields } = this.props,
      { Text: Txt, Image: img, Title } = fields;
    return (
      <div className='Expertise componentContainerHero'>
        <div className='Expertise-Container '>
          {this.showLinkBack()}
          <Text className='Expertise-Title' tag='h1' field={Title} />
          <Image className='Expertise-Image' field={img} />

          {this.getSocialLink()}

          <RichText field={Txt} className='Expertise-Description' />
        </div>
      </div>
    );
  }
}

export default GraphQLData(getBackToInfoForExpertiseQuery, {
  name: 'queryExpertiseData',
  options: props => ({
    variables: {
      previousItemId: props.previousId,
      curentItemId: props.sitecoreContext && props.sitecoreContext.itemId ? props.sitecoreContext.itemId : null,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withSitecoreContext()(Expertise));
