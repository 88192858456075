import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../../Common/GeneralLink/index';
import { removeWhiteSpaces } from '../../../utils/Utils';
import WithStickyMenu from '../../../hoc/WithStickyMenu';

const PromotionBox = props => {
  if (!hasItems(props)) {
    return <div className='PromotionBox componentContainerMobile' />;
  }
  const {
    'ClickText GTM English': ClickTextGTMEnglish,
    Image: Img,
    Title,
    SubTitle,
    Link,
    'Button Text': buttonText
  } = props.fields;

  return (
    <div className='whiteBackground'>
      <div className='PromotionBox componentContainerMobile click_content_block_promotion_topic'>
        <div className='PromotionBox-Container'>
          <Image field={Img} className='PromotionBox-Image' />
          <div className='PromotionBox-Wrapper'>
            <Text tag='div' field={Title} className='PromotionBox-Title' />
            <Text tag='div' field={SubTitle} className='PromotionBox-SubTitle' />
            <GeneralLink
              fields={Link}
              className='PromotionBox-Link'
              clickClassName={
                removeWhiteSpaces(ClickTextGTMEnglish.value || null) + ' click_content_block_promotion_topic'
              }
            >
              <Text field={buttonText} />
            </GeneralLink>
          </div>
        </div>
      </div>
    </div>
  );
};

const hasItems = function (props) {
  const { fields } = props;
  if (!fields) {
    return false;
  }
  return true;
};

export default WithStickyMenu(PromotionBox, hasItems);
