import * as actionTypes from './actionTypes';

//define the default state
const initialState = {
  name: null,
  street: null,
  number: null,
  city: null,
  country: null
};

export const contactInfoDetails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_INFO_DETAILS:
      return {
        ...state,
        name: action.name,
        street: action.street,
        number: action.number,
        city: action.city,
        country: action.country
      };
    default:
      return state;
  }
};
