import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ChevronDown from '../../Common/Icons/ChevronDown';
import ChevronUp from '../../Common/Icons/ChevronUp';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';
import { ACCESSORIES_PRODUCT_TYPE } from '../../Common/GeneralConstants/GeneralConstants';

/**
 * @class Component that renders an text and a button that display another text if the user want it
 *
 * @param {object} props Object containing the Text (for RichText)
 *
 * @returns jsx
 */
class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHiddenSecondAbstarct: true
    };
  }

  getArrow = () => {
    if (this.state.isHiddenSecondAbstarct) {
      return <ChevronDown color='red' />;
    }
    return <ChevronUp color='red' />;
  };

  toggleSecondAbstract = () => {
    this.setState(prevState => ({
      isHiddenSecondAbstarct: !prevState.isHiddenSecondAbstarct
    }));
  };

  getActionButton(textToUse, classes) {
    return (
      <div
        className={classes}
        onClick={this.toggleSecondAbstract}
        onKeyDown={e => {
          if (e.keyCode === 13) this.toggleSecondAbstract();
        }}
        role='button'
        tabIndex='0'
      >
        {this.getArrow()}
        <Text field={textToUse} />
      </div>
    );
  }
  getButtonOrText = descriptionToUse => {
    const { fields } = this.props,
      { isHiddenSecondAbstarct } = this.state;
    if (!descriptionToUse || !descriptionToUse.description3) {
      return null;
    }

    const cssClass = classNames('ProductDetails-SecondAbstract', {
      'ProductDetails-SecondAbstract-Hide': isHiddenSecondAbstarct
    });

    return (
      <React.Fragment>
        {isHiddenSecondAbstarct
          ? this.getActionButton(fields['Read More Label'], 'ProductDetails-More click_description_read_more_pdp')
          : null}
        <div className={cssClass}>{descriptionToUse.description3}</div>
        {!isHiddenSecondAbstarct
          ? this.getActionButton(fields['Less Label'], 'ProductDetails-Less click_description_read_less_pdp')
          : null}
      </React.Fragment>
    );
  };

  getCurrentDescription = () => {
    const { description, match, productType, listOfVariants } = this.props,
      matchArticleNumber = match && match.params && match.params.article_number ? match.params.article_number : null;
    if (productType !== ACCESSORIES_PRODUCT_TYPE) {
      return description;
    }

    const currentVariant = listOfVariants.find(
      element =>
        element.description &&
        (element.description.masterProductDescriptionIntro || element.description.masterProductDescriptionBody) &&
        matchArticleNumber.replace('-', '.') === element.articleNumber
    );
    return currentVariant
      ? {
          title: currentVariant.description.masterProductDescriptionTitle,
          description2: currentVariant.description.masterProductDescriptionIntro,
          description3: currentVariant.description.masterProductDescriptionBody
        }
      : null;
  };

  render() {
    if (!hasItems(this.props)) {
      return <div className='ProductDetails' />;
    }

    const descriptionToUse = this.getCurrentDescription(),
      { BackgroundColor, Title } = this.props.fields,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      };

    return (
      <div className='ProductDetails' style={inlineStyle}>
        <div className='ProductDetails-Wrapper componentContainer'>
          <HeadlineWithTitleAndSubtitle
            extraClasses='ProductDetails-Headline'
            title={Title}
            subtitle={descriptionToUse.title}
          />
          <div className='ProductDetails-FirstAbstract'>{descriptionToUse.description2}</div>
          {this.getButtonOrText(descriptionToUse)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { masterProductData, variants } = state.productDetails;
  return {
    description: masterProductData
      ? {
          title: masterProductData.Description.MasterProductDescriptionTitle,
          description2: masterProductData.Description.MasterProductDescriptionIntro,
          description3: masterProductData.Description.MasterProductDescriptionBody
        }
      : null,
    productType: masterProductData && masterProductData.ProductType,
    listOfVariants: variants
  };
};

const hasItems = function (props) {
  const { description, match, productType, listOfVariants } = props,
    matchArticleNumber = match && match.params && match.params.article_number ? match.params.article_number : null;
  if (
    (productType !== ACCESSORIES_PRODUCT_TYPE &&
      description &&
      (description.description2 || description.description3)) ||
    (productType === ACCESSORIES_PRODUCT_TYPE &&
      matchArticleNumber &&
      listOfVariants &&
      listOfVariants.some(
        element =>
          element.description &&
          (element.description.masterProductDescriptionIntro || element.description.masterProductDescriptionBody) &&
          matchArticleNumber.replace('-', '.') === element.articleNumber
      ))
  ) {
    return true;
  }
  return false;
};

export default connect(mapStateToProps)(withRouter(WithStickyMenu(ProductDetails, hasItems)));
