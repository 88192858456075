import React from 'react';
import { connect } from 'react-redux';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col, Visible } from 'react-grid-system';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import EmailIcon from '../Icons/EmailIcon';
import TelephoneIcon from '../Icons/TelephoneIcon';
import { removeWhiteSpaces } from '../../../utils/Utils';
import {
  setContactPointInfoId,
  //	setContactFormsData,
  setCareerFormData,
  setJobInformationHidden,
  setDropdownForms
} from '../../../store/contactForms/actions';
import { setContactPersonData } from '../../../store/contactPerson/actions';
import { setSessionStorage } from '../CookieSettings/CookieUtils';

/**
 * Person Holder Box component with left right image display options
 */
class PersonHolderBox extends React.Component {
  /**
   * @function getJobInformationOnRedux set on redux  information form first Hero Career component on the page, if there is one
   */
  getJobInformationOnRedux = () => {
    const { sitecoreContext, setCareerFormData, setJobInformationHidden } = this.props,
      componentName = 'Hero Career',
      component = sitecoreContext.route.placeholders['jss-main'].find(
        item => item.componentName === componentName && item.fields && item.fields.Title && item.fields.Title.value
      );

    if (component) {
      const {
          Title,
          Location,
          'Work Load': workload,
          Image,
          Country,
          'Open Position Label': positionLabel
        } = component.fields,
        country = Country && Country.fields.RegionName,
        jobInformation = {
          title: Title && Title.value,
          location: Location && Location.value,
          workload: workload && workload.value,
          country: country && country.value,
          picture: Image,
          label: positionLabel && positionLabel.value
        },
        jobInfoHidden = `${Title && Title.value}${Location && Location.value && ','}${Location && Location.value}${
          country && country.value && ','
        }${country && country.value}`;

      setCareerFormData(jobInformation);
      setJobInformationHidden(jobInfoHidden);
    }
  };

  /**
   * @function getTabletDesign returns component content for tablet size
   */

  getTabletDesign = () => {
    if (!this.props.fields.Expert || !this.props.fields.Expert.fields) {
      return null;
    }
    const {
      'First name': firstName,
      'Last name': lastName,
      'Text field': description,
      Picture
    } = this.props.fields.Expert.fields;

    const { 'Teaser Title': title } = this.props.fields;
    return (
      <Col md={12}>
        <div className='PersonHolderBox-TextContent Tablet'>
          <Text className='PersonHolderBox-TextContent-Title' tag='div' field={title} />
          <Text className='PersonHolderBox-TextContent-FirstName' tag='span' field={firstName} />
          &nbsp;
          <Text className='PersonHolderBox-TextContent-LastName' tag='span' field={lastName} />
          <RichText className='PersonHolderBox-TextContent-Description' field={description} />
          {this.getButtonOrLinks()}
          <Image field={Picture} className='PersonHolderBox-Image' />
        </div>
      </Col>
    );
  };

  /**
   * @function getImageContent returns the content with image
   */
  getImageContent = () => {
    if (!this.props.fields.Expert.fields.Picture) {
      return null;
    }

    const { Picture } = this.props.fields.Expert.fields;

    return (
      <Col sm={12} md={12} lg={5} xl={5} className='PersonHolderBox-Image-Wrapper'>
        <Image field={Picture} className='PersonHolderBox-Image' />
      </Col>
    );
  };
  /**
   * @function getEmailLink returns an email  link and an icon
   */

  getEmailLink = email => {
    if (!email || email.value === '') {
      return null;
    }
    const link = { href: 'mailto:' + email.value };
    return (
      <RoutableSitecoreLink link={link}>
        <span className='PersonHolderBox-TextContent-Email-Icon'>
          <EmailIcon />
        </span>
        <span className='PersonHolderBox-TextContent-Email-Text'>{email.value || ''}</span>
      </RoutableSitecoreLink>
    );
  };

  /**
   * @function getTelephonelLink returns a link and an icon
   */

  getTelephonelLink = phone => {
    if (!phone || phone.value === '') {
      return null;
    }
    const link = { href: 'tel:' + removeWhiteSpaces(phone.value) };
    return (
      <RoutableSitecoreLink link={link}>
        <span className='PersonHolderBox-TextContent-Telephone-Icon'>
          <TelephoneIcon />
        </span>
        <span className='PersonHolderBox-TextContent-Telephone-Text'>{phone.value || ''}</span>
      </RoutableSitecoreLink>
    );
  };

  /**
   * @function setContactPersonInfo set data on Redux
   */

  setContactPersonInfo = (id, forms, dataForms, name, email, salesPoint) => {
    id && this.props.setContactPointInfoIdOnRedux(id);
    forms && forms.length > 0 && this.props.setDropdownForms(dataForms);
    this.props.setContactPersonInfoOnRedux(name, email && email.value, salesPoint);
  };

  setPersonInfoOnSessionStorage = data => {
    setSessionStorage('person', data);
  };

  /**
   * @function getButtonOrLinks returns buttons with links  and on click event according to data
   */

  getButtonOrLinks = () => {
    const { 'CTA Button': isButton, Button, 'CTA Text': buttonText } = this.props.fields;
    const {
        'Phone number': phone,
        'E-mail address': email,
        'First name': firstName,
        'Last name': lastName,
        'Sales And Service Point': salesPoint,
        'Contact Page Forms': contactPageForms
      } = this.props.fields.Expert.fields,
      formsData =
        contactPageForms && contactPageForms.length
          ? contactPageForms.map(item => ({
              title: item.fields.Name.value,
              link: item.fields.Link.value.href,
              form: item.fields.Form.value
            }))
          : [],
      id = salesPoint && salesPoint.id ? salesPoint.id : null,
      name = firstName && lastName && firstName.value + ' ' + lastName.value,
      contactPageLink = contactPageForms && contactPageForms.length ? contactPageForms[0].fields.Link : Button,
      objPersonData = {
        name: name,
        email: email,
        salesPoint: salesPoint,
        phone: phone,
        forms: contactPageForms
      };
    if (isButton.value && Button.value.href && id) {
      return (
        <div
          onClick={() => {
            this.setContactPersonInfo(id, contactPageForms, formsData, {
              name,
              email,
              phone,
              salesPoint
            });
            this.getJobInformationOnRedux();
            this.setPersonInfoOnSessionStorage(JSON.stringify(objPersonData));
          }}
          onKeyDown={e => {
            if (e.keyCode === 13)
              this.setContactPersonInfo(id, contactPageForms, formsData, {
                name,
                email,
                phone,
                salesPoint
              });
            this.getJobInformationOnRedux();
            this.setPersonInfoOnSessionStorage(JSON.stringify(objPersonData));
          }}
          role='button'
          tabIndex='0'
        >
          <RoutableSitecoreLink
            link={contactPageLink}
            className='PersonHolderBox-TextContent-Button click_content_block_person_holder'
          >
            <Text field={buttonText} />
          </RoutableSitecoreLink>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className='PersonHolderBox-TextContent-Telephone'>{this.getTelephonelLink(phone)}</div>
        <div className='PersonHolderBox-TextContent-Email'>{this.getEmailLink(email)}</div>
      </React.Fragment>
    );
  };

  /**
   * @function getTextContent  returns the content with text
   */
  getTextContent = () => {
    const {
      'First name': firstName,
      'Last name': lastName,
      'Text field': description
    } = this.props.fields.Expert.fields;

    const { 'Teaser Title': title } = this.props.fields;

    return (
      <Col sm={12} md={12} lg={6} xl={6}>
        <div className='PersonHolderBox-TextContent'>
          <Text className='PersonHolderBox-TextContent-Title' tag='div' field={title} />
          <div className='PersonHolderBox-TextContent-Name'>
            <Text field={firstName} />
            &nbsp;
            <Text field={lastName} />
          </div>
          <RichText className='PersonHolderBox-TextContent-Description' field={description} />
          {this.getButtonOrLinks()}
        </div>
      </Col>
    );
  };

  render() {
    if (!this.props.fields || !this.props.fields.Expert || !this.props.fields.Expert.fields) {
      return <div className='componentContainerMobile' />;
    }

    const { BackgroundColor } = this.props.fields,
      inlineStyle = {
        backgroundColor:
          BackgroundColor && BackgroundColor.fields.ColorCode && BackgroundColor.fields.ColorCode.value !== ''
            ? BackgroundColor.fields.ColorCode.value
            : null
      };

    return (
      <div className='PersonHolderBox-Container whiteBackground' style={inlineStyle}>
        <Row nogutter className='PersonHolderBox componentContainerMobile click_content_block_person_holder'>
          <Visible xs sm lg xl>
            {this.getImageContent()}
            {this.getTextContent()}
          </Visible>
          <Visible md>{this.getTabletDesign()}</Visible>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContactPointInfoIdOnRedux: data => dispatch(setContactPointInfoId(data)),
    setContactPersonInfoOnRedux: personData => dispatch(setContactPersonData(personData)),
    setCareerFormData: jobData => dispatch(setCareerFormData(jobData)),
    setJobInformationHidden: jobInfo => dispatch(setJobInformationHidden(jobInfo)),
    setDropdownForms: data => dispatch(setDropdownForms(data))
  };
};

export default connect(null, mapDispatchToProps)(withSitecoreContext()(PersonHolderBox));
