import React, { useContext } from 'react';
import { SearchEngineContext } from '../context/SearchEngineContext';
import { ActionType, SearchContext } from '../context/SearchContext';
import { getCoveoProductsSource, getCoveoSitecoreSource } from '../../../AppGlobals';
import { CoveoSearchResultsPipelines, CoveoSearchResultsSearchHubs } from '../CoveoSettings';
import { SearchEngineProps } from '../CoveoEngine';
import { sanitizeQuery } from '../CoveoQuery';
import CoveoComponent from '../CoveoComponent';
import SearchResultsComponent from './SearchResults';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { normalizeDownloadPagePath } from '../../Product/AllDownloads/getDownloadsProductAndArticle';

type SearchResultsProps = {
  sitecoreContext: any;
};

export const SearchResults: React.FC<SearchResultsProps> = ({ sitecoreContext }) => {
  const {
    state: { siteName, coveoSiteName, locale }
  } = useContext(SearchEngineContext);
  const { dispatch } = useContext(SearchContext);
  const coveoProductSource = getCoveoProductsSource();
  const coveoSitecoreSource = getCoveoSitecoreSource();
  const downloadPagePath = normalizeDownloadPagePath(
    sitecoreContext?.productDownloads?.productDownloadsDetailPage || ''
  );

  const advancedQuery = `
    @z95xlanguage=="${locale}" AND (
      (@source=="${coveoProductSource}" AND @tenant=="${siteName}" AND ((@z95xtemplatename=="Accessory" AND NOT @isphasedout=="true") OR NOT @z95xtemplatename=="Accessory")) OR
      (@source=="${coveoSitecoreSource}" AND (@site=="${coveoSiteName}" OR @storyz32xbrands="${coveoSiteName}" OR @z95xtemplatename=="Job"))
    )
  `;

  const searchEngineProps: SearchEngineProps = {
    pipeline: CoveoSearchResultsPipelines[siteName],
    searchHub: CoveoSearchResultsSearchHubs[siteName],
    groupBy: (body: any) => [
      {
        field: '@herotemplatename',
        maximumNumberOfValues: 20,
        sortCriteria: 'occurrences',
        injectionDepth: 10000,
        completeFacetWithStandardValues: true,
        queryOverride: sanitizeQuery(body.q as string),
        // Items in current language. Can either be from Sitecore source or products source, but tenant (products) or site (content) must match current site
        advancedQueryOverride: advancedQuery,
        constantQueryOverride: `@herotemplatename==("Product", "Accessory", "Variant", "CareerHero", "StoryHero", "ExpertiseHero", "SolutionHero", "UniversalHero", "UniversalStoryHero", "UniversalJobHero")`
      }
    ],
    responseMiddleware: response => {
      if (response?.body?.groupByResults?.length) {
        dispatch({
          type: ActionType.UPDATE_TAB_RESULTS_COUNT,
          payload: response?.body?.groupByResults.find((x: any) => x.field === 'herotemplatename')?.values
        });
      }
    }
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <SearchResultsComponent downloadPagePath={downloadPagePath} />
    </CoveoComponent>
  );
};

export default withSitecoreContext()(SearchResults);
