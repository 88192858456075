import React from 'react';
import classNames from 'classnames';
import { SitecoreIcon } from '../../Common/Icon/SitecoreIcon';

class ShareSocialLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLink: ''
    };
  }

  componentDidMount() {
    const currentLink = window.location.href;
    this.setState({ currentLink: currentLink });
  }
  render() {
    if (!this.props.fields) {
      return <div className='ShareSocialLink' />;
    }

    const onMobile = this.props.fields.OnlyForMobile.value,
      cssClass = classNames('ShareSocialLink', {
        'Only-mobile': onMobile
      }),
      { SocialLink, SocialIcon } = this.props.fields;

    return (
      <div className={cssClass}>
        <a href={SocialLink.value.href + this.state.currentLink} className='ShareSocialLink-Link' target='blank'>
          <SitecoreIcon icon={SocialIcon} hoverIcon color='black-30' size={32} />
        </a>
      </div>
    );
  }
}

export default ShareSocialLink;
