import React from 'react';
import FulfillmentStatusTag from '../../../../Account/MyOrders/FulfillmentStatusTag';
import { ShopifyOrderFulfillmentStatus } from '../../../../Shopify/lib/shopify/types';

export function FulfillmentStatusCell({
  fulfillmentStatus
}: {
  fulfillmentStatus: ShopifyOrderFulfillmentStatus;
}): JSX.Element {
  return (
    <div className='TagCell'>
      <FulfillmentStatusTag fulfillmentStatus={fulfillmentStatus} />
    </div>
  );
}

export default FulfillmentStatusCell;
