import React from 'react';
import { connect } from 'react-redux';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import { setContactPointInfoId } from '../../../../store/contactForms/actions';
import HomeIcon from '../../Icons/HomeIcon';
import GeneralLink from '../../../Common/GeneralLink/index';
import TelephoneIcon from '../../../Common/Icons/TelephoneIcon';
import EmailIcon from '../../../Common/Icons/EmailIcon';
import ShopIcon from '../../../Common/Icons/ShopIcon';
import WebIcon from '../../../Common/Icons/WebIcon';
import { removeWhiteSpaces, normalizeSitecoreId } from '../../../../utils/Utils';
import ArrowRight from '../../Icons/ArrowRight';
import FavouriteContactPointButton from './FavouriteContactPointButton';
import { setSessionStorage } from '../../../Common/CookieSettings/CookieUtils';
import FaxIcon from '../../Icons/FaxIcon';

class ContactPointInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMessageOpen: false };
  }

  getEmail(Email) {
    if (Email && Email.value) {
      return (
        <GeneralLink fields={{ value: { href: 'mailto:' + Email.value } }} className='ContactPointInfo-Link'>
          <EmailIcon hoverIcon />
          <Text field={Email} />
        </GeneralLink>
      );
    }
  }

  getPhone(Phone) {
    if (Phone && Phone.value) {
      return (
        <GeneralLink
          className='ContactPointInfo-Number'
          fields={{
            value: { href: 'tel:' + removeWhiteSpaces(Phone.value) }
          }}
        >
          <TelephoneIcon hoverIcon />
          <Text field={Phone} />
        </GeneralLink>
      );
    }
  }

  getFax(Fax) {
    if (Fax && Fax.value) {
      return (
        <GeneralLink
          className='ContactPointInfo-Number'
          fields={{
            value: { href: 'tel:' + removeWhiteSpaces(Fax.value) }
          }}
        >
          <FaxIcon hoverIcon />
          <Text field={Fax} />
        </GeneralLink>
      );
    }
  }

  getHowToFindUs(findUs) {
    const { sitecoreContext } = this.props;
    const findUsLabel =
      sitecoreContext.route && sitecoreContext.route.fields['How To Find Us']
        ? sitecoreContext.route.fields['How To Find Us']
        : { value: '' };
    if (findUs) {
      return (
        <GeneralLink fields={{ value: { href: findUs.url } }} className='ContactPointInfo-Link'>
          <HomeIcon hoverIcon />
          <Text field={findUsLabel} />
        </GeneralLink>
      );
    }
  }

  getWebsite(website) {
    if (website) {
      return (
        <GeneralLink fields={{ value: { href: website.url, target: 'blank' } }} className='ContactPointInfo-Link'>
          <WebIcon hoverIcon />
          <Text field={{ value: website.text }} />
        </GeneralLink>
      );
    }
  }

  getShop(website) {
    if (website) {
      return (
        <GeneralLink fields={{ value: { href: website.url, target: 'blank' } }} className='ContactPointInfo-Link'>
          <ShopIcon hoverIcon />
          <Text field={{ value: website.text }} />
        </GeneralLink>
      );
    }
  }

  getDetailsLink() {
    const { info, sitecoreContext } = this.props,
      detailsLabel = sitecoreContext && sitecoreContext.route && sitecoreContext.route.fields['Details Label'],
      link = info.pageSelection && info.pageSelection.targetItem && info.pageSelection.targetItem.url;

    return (
      <GeneralLink fields={{ value: { href: link } }} className='ContactPointInfo-DetailsLink'>
        <Text field={detailsLabel} />
        <ArrowRight />
      </GeneralLink>
    );
  }

  getProvider() {
    const { onlyLabel, kindOfPartner, solutionProvider } = this.props.info,
      partnerType = kindOfPartner && kindOfPartner.targetItem && kindOfPartner.targetItem.name.value,
      provider =
        solutionProvider && solutionProvider.targetItems && solutionProvider.targetItems.length === 1
          ? solutionProvider.targetItems[0]
          : '';

    if (provider && provider.name.value && partnerType) {
      return (
        <span className='ContactPointInfo-Provider'>
          (
          <Text field={onlyLabel} />
          &nbsp;
          <Text field={provider.name} />)
        </span>
      );
    }
  }

  contactButton = (id, label, link) => {
    return (
      <div
        onClick={() => {
          setSessionStorage('contactPointInfoId', id);
          this.props.setContactPointInfoIdOnRedux(id);
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setSessionStorage('contactPointInfoId', id);
            this.props.setContactPointInfoIdOnRedux(id);
          }
        }}
        role='button'
        tabIndex='0'
      >
        <GeneralLink fields={{ value: { href: link } }} className='ContactPointInfo-ContactLink'>
          <Text field={label} />
        </GeneralLink>
      </div>
    );
  };

  getContactButton() {
    const { info, sitecoreContext, savedContactRelativePath, lastActiveFormTitle, productPageLinkForGetAQuote } =
        this.props,
      fields = sitecoreContext && sitecoreContext.route && sitecoreContext.route.fields,
      {
        'Contact Label': contactLabel,
        'Get A Quote Label': getQuoteLabel,
        'Contact Page Link': contactLink,
        'Get A Quote Form Link': getQuoteLink,
        'Get in Touch Label': getInTouchLabel,
        'Talk to an Expert Form Link': talkToAnExpertLink
      } = fields,
      formsSelection = info.contactPageItems && info.contactPageItems.targetItems;

    if (!formsSelection || formsSelection.length === 0) {
      return (
        <div className='ContactPointInfo-ContactLink-Disable'>
          <Text field={contactLabel} />
        </div>
      );
    }

    if (info.id) {
      const checkCurrentForm = formsSelection.find(item => item.form.value === lastActiveFormTitle),
        { isLinkForQuote, isLinkForExpert } = this.props,
        linkContact = savedContactRelativePath && checkCurrentForm ? savedContactRelativePath : contactLink.value.href,
        getAQuoteFormHref = getQuoteLink && getQuoteLink.value && getQuoteLink.value.href,
        talkToAnExpertFormHref = talkToAnExpertLink && talkToAnExpertLink.value && talkToAnExpertLink.value.href;

      if (productPageLinkForGetAQuote && isLinkForQuote) {
        return this.contactButton(info.id, getQuoteLabel, getAQuoteFormHref);
      } else if (productPageLinkForGetAQuote && isLinkForExpert) {
        return this.contactButton(info.id, getInTouchLabel, talkToAnExpertFormHref);
      }
      return this.contactButton(info.id, contactLabel, linkContact);
    }
  }

  getTitleWithStar = () => {
    const {
        info: { companyName: title, id, isRestrictedContact },
        info,
        sitecoreContext,
        isRestrictedUser
      } = this.props,
      contactId = info.id ? normalizeSitecoreId(info.id) : null;

    return (
      <div className='ContactPointInfo-Title'>
        <Text tag='span' id={'ContactPointInfo-Title-' + id} field={title} />
        <FavouriteContactPointButton
          contactPointId={contactId}
          fields={sitecoreContext.route.fields}
          onClickStar={() => {
            this.setState({ isMessageOpen: true });
          }}
          onClickStarFilled={() => {
            this.setState({ isMessageOpen: false });
          }}
          isRestrictedUser={isRestrictedUser}
          isRestrictedContact={isRestrictedContact}
        />
      </div>
    );
  };

  render() {
    if (!this.props.info) {
      return <div className='ContactPointInfo' />;
    }
    const { info, sitecoreContext, favoriteContactId } = this.props,
      {
        street,
        streetNumber,
        city,
        phone,
        email,
        howToFindUs,
        website1,
        zipCode,
        kindOfPartner,
        country,
        fax,
        shop1,
        pageSelection,
        id
      } = info,
      Country =
        country && country.targetItem && country.targetItem.field.value ? country.targetItem.field : { value: '' },
      partnerType =
        kindOfPartner && kindOfPartner.targetItem && kindOfPartner.targetItem.name.value
          ? kindOfPartner.targetItem.name
          : { value: '' },
      detailsLink = pageSelection && pageSelection.targetItem && pageSelection.targetItem.url,
      linksClass = classNames('ContactPointInfo-BottomLinks', {
        WithoutDetailsLink: !detailsLink
      }),
      contactId = id ? normalizeSitecoreId(id) : null,
      { 'Message Saved Favorite Contact': messageSavedContact } = sitecoreContext.route.fields;

    return (
      <div className='ContactPointInfo'>
        <Text tag='span' field={partnerType} className='ContactPointInfo-Category' />
        {this.getProvider()}
        {this.getTitleWithStar()}
        <Text tag='span' field={street} className='ContactPointInfo-Address' />
        &nbsp;
        <Text tag='span' field={streetNumber} className='ContactPointInfo-Address' />
        , &nbsp;
        <Text tag='span' field={zipCode} className='ContactPointInfo-Address' />
        &nbsp;
        <Text tag='span' field={city} className='ContactPointInfo-Address' />
        , &nbsp;
        <Text tag='span' field={Country} className='ContactPointInfo-Address' />
        {favoriteContactId === contactId && this.state.isMessageOpen && (
          <Text field={messageSavedContact} tag='div' className='ContactPointInfo-MessageSavedContact' />
        )}
        <div className='ContactPointInfo-Contact'>
          {this.getPhone(phone)}
          {this.getFax(fax)}
          {this.getEmail(email)}
          {this.getWebsite(website1)}
          {this.getShop(shop1)}
          {this.getHowToFindUs(howToFindUs)}
          <div className={linksClass}>
            {this.getDetailsLink()}
            {this.getContactButton()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    savedContactRelativePath: state.contactForms.savedContactRelativePath,
    lastActiveFormTitle: state.contactForms.lastActiveFormTitle,
    favoriteContactId: state.favoriteContact.favoriteContactId,
    productPageLinkForGetAQuote: state.productDetails.productPageLinkForGetAQuote,
    isLinkForQuote: state.productDetails.isLinkForQuote,
    isLinkForExpert: state.productDetails.isLinkForExpert
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setContactPointInfoIdOnRedux: data => dispatch(setContactPointInfoId(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(ContactPointInfo));
