export const getJsonResponse = <T>(endpoint: string): Promise<T> => {
  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .catch(error => {
      throw new Error('Something went wrong');
    });
};
