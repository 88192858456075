import * as React from 'react';

export interface OmniboxState {
  isOmniboxBoxOpened: boolean;
  isInstantResultsVisible: boolean;
}

export const initialState: OmniboxState = {
  isOmniboxBoxOpened: false,
  isInstantResultsVisible: false
};

export interface OmniboxContextInterface {
  state: OmniboxState;
  dispatch: React.Dispatch<OmniboxAction>;
}

const OmniboxContext = React.createContext<OmniboxContextInterface>({
  state: {
    isOmniboxBoxOpened: false,
    isInstantResultsVisible: false
  },
  dispatch: () => undefined
});

export enum ActionType {
  TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR',
  HIDE_SEARCH_BAR = 'HIDE_SEARCH_BAR',
  SHOW_INSTANT_RESULTS = 'SHOW_INSTANT_RESULTS',
  HIDE_INSTANT_RESULTS = 'HIDE_INSTANT_RESULTS'
}

export type OmniboxAction = {
  type: ActionType;
};

const omniboxReducer = (state: OmniboxState, action: OmniboxAction): typeof initialState => {
  switch (action.type) {
    case 'TOGGLE_SEARCH_BAR': {
      return {
        ...state,
        isOmniboxBoxOpened: !state.isOmniboxBoxOpened
      };
    }

    case 'HIDE_SEARCH_BAR': {
      return {
        ...state,
        isOmniboxBoxOpened: false
      };
    }

    case 'SHOW_INSTANT_RESULTS': {
      return {
        ...state,
        isInstantResultsVisible: true
      };
    }

    case 'HIDE_INSTANT_RESULTS': {
      return {
        ...state,
        isInstantResultsVisible: false
      };
    }

    default:
      throw new Error();
  }
};

const OmniboxContextProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = React.useReducer(omniboxReducer, initialState as OmniboxState);
  const value = { state, dispatch };

  return <OmniboxContext.Provider value={value}>{children}</OmniboxContext.Provider>;
};

export { OmniboxContext, OmniboxContextProvider };
