import React from 'react';
import { connect } from 'react-redux';
import { Visible } from 'react-grid-system';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import GreenCircleCheckIcon from '../../../../Common/Icons/GreenCircleCheckIcon';
import CompareApplicationMobile from '../CompareApplicationMobile';
import ChevronDown from '../../../../Common/Icons/ChevronDown';
import CompareApplicationDesktop from '../CompareApplicationDesktop';

class CompareApplicationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemsToShow: 5 };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    const { selectedCategory } = this.props;
    if (selectedCategory && prevProps.selectedCategory !== selectedCategory) {
      this.setState({ itemsToShow: 5 });
    }
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 960 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  //get data with products in current category
  getActiveProductsList() {
    const { initialData, selectedCategory } = this.props,
      firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : null;
    if (initialData && initialData.length > 0) {
      return initialData.filter(item => {
        return [item.category, item.categoryEn].includes(firstSelectedCategory);
      });
    }
  }

  //get data unique application in current category
  getUniqueAplicationList() {
    const activeProducts = this.getActiveProductsList();
    if (activeProducts && activeProducts.length > 0) {
      const numberOfProductsToDisplay = this.state && this.state.isMobile ? activeProducts.length : 4,
        initialApplications = activeProducts.slice(0, numberOfProductsToDisplay).map(item => {
          return item.applications;
        });
      const applicationsList = [].concat(...initialApplications);

      return Array.from(new Set(applicationsList.map(a => a.name))).map(name => {
        return applicationsList.find(a => a.name === name);
      });
    }
    return [];
  }

  // render check mark for existing application for each product in current category
  getCheckedApplication = (data, applicationTitle) => {
    if (data && data.length > 0) {
      return data.map((element, index) => {
        const machApplication = element.find(item => item.name === applicationTitle);

        if (machApplication) {
          return (
            <div className='CompareApplication-Item CompareApplication-Value' key={index + applicationTitle}>
              <GreenCircleCheckIcon />
            </div>
          );
        }
        return <div className='CompareApplication-Item CompareApplication-Value' key={index + applicationTitle} />;
      });
    }
  };

  // render a list of application for products in current category

  getAplicationsList = () => {
    const uniqueApplicationList = this.getUniqueAplicationList();
    if (uniqueApplicationList && uniqueApplicationList.length > 0) {
      const initialApplications = this.getActiveProductsList()
        .slice(0, 4)
        .map(item => {
          return item.applications;
        });
      return uniqueApplicationList.slice(0, this.state.itemsToShow).map((element, index) => {
        return (
          <CompareApplicationDesktop
            key={element.name + index}
            fields={this.props.fields}
            title={element.name}
            picture={element.imageUrl}
          >
            {this.getCheckedApplication(initialApplications, element.name)}
          </CompareApplicationDesktop>
        );
      });
    }
  };

  // render a list of application for products in current category for mobile

  getAplicationsListMobile = () => {
    const activeProducts = this.getActiveProductsList();
    if (activeProducts && activeProducts.length > 0) {
      return activeProducts.map((item, index) => {
        return (
          <CompareApplicationMobile
            key={item.masterProductName + index}
            productName={item.masterProductName}
            fields={this.props.fields}
            data={item.applications}
          />
        );
      });
    }
    return <React.Fragment />;
  };

  // display button to show more application if there are more

  getMoreButton = () => {
    const uniqueApplicationList = this.getUniqueAplicationList(),
      remainingItems = uniqueApplicationList.length - this.state.itemsToShow;
    if (remainingItems > 0) {
      return (
        <div
          onClick={this.incrementDisplayedItems}
          onKeyDown={e => {
            if (e.target === 13) this.incrementDisplayedItems();
          }}
          role='button'
          tabIndex='0'
          className='CompareApplication-MoreButton'
        >
          <ChevronDown />
          <Text field={this.props.fields['Show More Label']} tag='span' />
        </div>
      );
    }
    return <div />;
  };

  incrementDisplayedItems = () => {
    const numberToIncrement = 5;
    this.setState(state => ({
      itemsToShow: state.itemsToShow + numberToIncrement
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Visible xl lg>
          {this.getAplicationsList()}
          {this.getMoreButton()}
        </Visible>
        <Visible xs sm md>
          {this.getAplicationsListMobile()}
        </Visible>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCategory: state.compareProduct.selectedCategory
  };
};

export default connect(mapStateToProps)(CompareApplicationList);
