import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';
import EmailIcon from '../Icons/EmailIcon';
import TelephoneIcon from '../Icons/TelephoneIcon';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { setCareerContactContext } from './CareerContactFormContext';

export const PersonHolderBoxContextAware = ({ fields: rootFields, sitecoreContext }: any) => {
  const datasource = rootFields?.data?.datasource;
  const siteConfiguration = rootFields?.data?.siteConfiguration;
  const fields = datasource?.expert?.jss?.fields;

  if (!fields) {
    return <div className='componentContainerMobile' />;
  }

  const workFormUrl = siteConfiguration?.jobConfiguration?.workForm?.url || '';
  const { BackgroundColor, cTAButton: isButton, cTAText: buttonText, teaserTitle: title } = datasource;

  const {
    'Phone number': phone,
    'E-mail address': email,
    'First name': firstName,
    'Last name': lastName,
    'Sales And Service Point': salesPoint,
    'Contact Page Forms': contactPageForms,
    'Text field': description
  } = fields;

  const link = { href: 'tel:' + removeWhiteSpaces(phone.value) };
  const emailLink = { href: 'mailto:' + email?.value };
  const id = salesPoint?.id || null;
  const name = firstName?.value + ' ' + lastName?.value;

  return (
    <div
      className='PersonHolderBoxContextAware__Container whiteBackground'
      style={{
        backgroundColor: BackgroundColor?.fields?.ColorCode?.value || null
      }}
    >
      <Row nogutter className='PersonHolderBox componentContainerMobile'>
        {fields.Picture ? (
          <Col sm={12} md={12} lg={5} xl={5} className='PersonHolderBoxContextAware__Image-Wrapper'>
            <Image field={fields.Picture} className='PersonHolderBoxContextAware__Image' />
          </Col>
        ) : null}
        <Col sm={12} md={12} lg={6} xl={6}>
          <div className='PersonHolderBoxContextAware__TextContent'>
            <Text className='PersonHolderBoxContextAware__TextContent-Title' tag='div' field={title} />
            <div className='PersonHolderBoxContextAware__TextContent-Name'>
              <Text field={firstName} />
              &nbsp;
              <Text field={lastName} />
            </div>
            <RichText className='PersonHolderBoxContextAware__TextContent-Description' field={description} />
            {isButton.value && workFormUrl && id ? (
              <div
                onClick={() =>
                  setCareerContactContext({ sitecoreContext, contactPageForms, id, name, email, salesPoint, phone })
                }
                onKeyDown={e => {
                  if (e.keyCode === 13) {
                    setCareerContactContext({ sitecoreContext, contactPageForms, id, name, email, salesPoint, phone });
                  }
                }}
                role='button'
                tabIndex={0}
              >
                <RoutableSitecoreLink
                  link={{ value: { href: workFormUrl } }}
                  className='PersonHolderBoxContextAware__TextContent-Button'
                >
                  <Text field={buttonText} />
                </RoutableSitecoreLink>
              </div>
            ) : (
              <>
                <div className='PersonHolderBoxContextAware__TextContent-Telephone'>
                  {phone && phone.value !== '' ? (
                    <RoutableSitecoreLink link={link}>
                      <span className='PersonHolderBoxContextAware__TextContent-Telephone-Icon'>
                        <TelephoneIcon />
                      </span>
                      <span className='PersonHolderBoxContextAware__TextContent-Telephone-Text'>
                        {phone.value || ''}
                      </span>
                    </RoutableSitecoreLink>
                  ) : null}
                </div>
                <div className='PersonHolderBoxContextAware__TextContent-Email'>
                  {email && email.value !== '' ? (
                    <RoutableSitecoreLink link={emailLink}>
                      <span className='PersonHolderBoxContextAware__TextContent-Email-Icon'>
                        <EmailIcon />
                      </span>
                      <span className='PersonHolderBoxContextAware__TextContent-Email-Text'>{email.value || ''}</span>
                    </RoutableSitecoreLink>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withSitecoreContext()(PersonHolderBoxContextAware);
