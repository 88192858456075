import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { CoveoProductsHeatGunsPipelines, CoveoProductsHeatGunsSearchHubs } from './HeatGunsLister.settings';
import HeatGunsListerComponent from './HeatGunsLister';
import CoveoComponent from '../../../CoveoComponent';

export const HeatGunsLister = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsHeatGunsPipelines[siteName],
    searchHub: CoveoProductsHeatGunsSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <HeatGunsListerComponent />
    </CoveoComponent>
  );
};

export default HeatGunsLister;
