import React from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import Download from '../../Icons/DownloadIcon';
import { fileSizeConverter, removeWhiteSpaces } from '../../../../utils/Utils';

/**
 * @method DownloadItem
 * @description return an Download Item for Download Box with icon, title, extension and size in MB
 * @returns jxs items
 */
class DownloadItem extends React.Component {
  /**
   * @method getSizeToMB
   * @description Get the size and make it to display properly as the acceptance criteria of this component
   * @returns the size of the item + MB string
   */
  getSizeToMB = () => {
    const sizeNumber = String(fileSizeConverter(this.props.fields.fields.Size.value, 'b', 'mb'));
    const splitedAfterDot = sizeNumber.split('.'),
      splitedLength = splitedAfterDot.length;
    if (splitedLength > 1) {
      if (splitedAfterDot[0] < 10) {
        const firstNumberAfterDot = splitedAfterDot[1].substr(0, 1),
          numberAfterDot = firstNumberAfterDot > 0 ? firstNumberAfterDot : 1;
        return splitedAfterDot[0] + '.' + numberAfterDot + ' MB';
      }
    }

    return splitedAfterDot[0] + ' MB';
  };

  render() {
    const { fileformat, filename, src } = this.props.fields.jss.value,
      fileFormatText = fileformat ? fileformat.toUpperCase() : null;
    return (
      <Row
        nogutter
        id={removeWhiteSpaces(filename || null)}
        component='a'
        href={src || '#'}
        download
        target='_blank'
        align='center'
        className='DownloadItem'
      >
        <Col xs={1} md={1} lg={1} xl={1}>
          <Download />
        </Col>
        <Col className='DownloadItem-Title' xs={9} md={8} xl={8} lg={8}>
          <div className='DownloadItem-Title-Inline'>{filename || null}</div>
        </Col>
        <Visible lg xl md>
          <Col className='DownloadItem-Size' md={3} xl={3} lg={3}>
            {
              fileFormatText
              // +
              // 	', ' +
              // 	this.getSizeToMB()
            }
          </Col>
        </Visible>
      </Row>
    );
  }
}

export default DownloadItem;
