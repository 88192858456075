import React from 'react';
import WeldyAccessoriesListerComponent from './WeldyAccessoriesLister';
import CoveoComponent from '../../../CoveoComponent';

type AccessoriesListerProp = {
  fields: any;
};

export const WeldyAccessoriesLister: React.FC<AccessoriesListerProp> = props => {
  const searchEngineProps = {
    pipeline: 'Accessories',
    searchHub: 'Accessories'
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WeldyAccessoriesListerComponent {...props} />
    </CoveoComponent>
  );
};

export default WeldyAccessoriesLister;
