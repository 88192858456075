import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

/**
 *  Component that renders a title under an oveline. overline color changes according to data
 * @param {object} props Object containing data needed for the component.
 *
 */

const HeroListingTitleWithOverline = props => {
  if (!props.fields) {
    return <div className='HeroListingTitleWithOverline componentContainer' />;
  }

  const inlineStyle = {
    color:
      props.fields['Overline Color'] && props.fields['Overline Color'].fields.ColorCode.value !== ''
        ? props.fields['Overline Color'].fields.ColorCode.value
        : '#676767'
  };
  return (
    <div className='HeroListingTitleWithOverline componentContainer' style={inlineStyle}>
      <div className='HeroListingTitleWithOverline-Container'>
        <Text field={props.fields.Overline} tag='span' className='HeroListingTitleWithOverline-Overline' />
        <Text field={props.fields.Title} tag='h1' className='HeroListingTitleWithOverline-Title' />
      </div>
    </div>
  );
};

export default HeroListingTitleWithOverline;
