import React from 'react';
import { connect } from 'react-redux';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { getCookie } from '../../../Common/CookieSettings/CookieUtils';
import { COMPARE } from '../../../Common/CookieSettings/CookieConstants';

class NoComparedData extends React.Component {
  render() {
    const cookieCompareList = getCookie(COMPARE) ? JSON.parse(getCookie(COMPARE)) : null;

    if (!this.props.fields || (cookieCompareList && Object.keys(cookieCompareList).length)) {
      return <div />;
    }
    return (
      <div className='NoComparedData componentContainer'>
        <Text className='NoComparedData-Title' field={this.props.fields.Title} tag='h1' />
        <RichText className='NoComparedData-Description' field={this.props.fields.Description} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    initialData: state.compareProduct.initialData
  };
};

export default connect(mapStateToProps, null)(NoComparedData);
