import React from 'react';
import { Visible } from 'react-grid-system';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GeneralLink from '../GeneralLink';
import { getLanguage, getAppLocalizationDetails } from '../CookieSettings/SetLanguageData';
import LanguageFlyout from '../LanguageFlyout/index';
import { getLanguages, extractLanguagesCode, getDefaultLanguage } from './DefaultLanguage';
import { DEFAULT_LANGUAGE } from '../CookieSettings/CookieConstants';
import classNames from 'classnames';
import WebIcon from '../Icons/WebIcon';
import { generateLink } from '../../../utils/Utils';
import ChevronDown from '../Icons/ChevronDown';
import PlusIconSmall from '../Icons/PlusIconSmall';
import { getSitecoreApiHost } from '../../../AppGlobals';

let isMounted = false;

/**
 * Display the flag and the string of the current language of the page
 *
 * @param {object} props expect to get an image with the flag of the language and the language text
 *
 * Returns jsx markup containing an image and a text wrapped in a div.
 */
class SelectLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '',
      languages: [],
      showFlyout: false,
      isDataReceived: this.checkData(),
      displayedCountry: { value: '' }
    };

    isMounted = false;
  }

  componentDidMount() {
    if (!this.state.isDataReceived) {
      return;
    }

    const cookieLang = getLanguage(this.props.sitecoreContext);
    this.setDataOnState(cookieLang);

    isMounted = true;
  }

  componentDidUpdate() {
    const cookieLang = getLanguage(this.props.sitecoreContext);
    if (this.state.currentLang !== cookieLang && this.state.isDataReceived) {
      this.setDataOnState(cookieLang);
    }
  }

  checkData() {
    if (this.props.fields && this.props.rendering.fields && this.props.rendering.fields.data.item.continents.tags) {
      return true;
    }
    return false;
  }

  setDataOnState = language => {
    const localizationDetails = getAppLocalizationDetails(this.props.sitecoreContext),
      continents = getLanguages(
        this.props.rendering.fields.data.item.continents.tags,
        localizationDetails.countryCode,
        localizationDetails.region
      ),
      languagesA = extractLanguagesCode(continents.languages);

    this.setState({
      currentLang: language || getDefaultLanguage(languagesA, localizationDetails.lang, continents.getdefaultLanguage),
      languages: languagesA,
      countryCode: localizationDetails.countryCode,
      currentCountry: continents.country,
      currentRegion: continents.region,
      displayedCountry: continents.country.regionName
    });
  };

  onFLyoutChange = (lang, countryCode, regionCode) => {
    const continents = getLanguages(this.props.rendering.fields.data.item.continents.tags, countryCode, regionCode);

    const languagesA = extractLanguagesCode(continents.languages);

    this.setState({
      currentLang: lang,
      languages: languagesA,
      countryCode: countryCode,
      currentCountry: continents.country,
      currentRegion: continents.region,
      displayedCountry: continents.country.regionName,
      showFlyout: !this.state.showFlyout
    });
  };

  getAvailableLanguages() {
    const currentLang = getLanguage(this.props.sitecoreContext);

    return this.state.languages.map((item, index) => {
      const cssClass = classNames({
        'SelectLanguage-Direct-Lang-Default': item === currentLang
      });
      const url = generateLink(item);
      url.value.href = getSitecoreApiHost() + url.value.href;

      return (
        <GeneralLink className={cssClass} fields={url} key={item + index}>
          {item}
          <span className='SelectLanguage-Direct-Lang-Default-Separator'>/</span>
        </GeneralLink>
      );
    });
  }

  getMobileAvailableLanguages = () => {
    const currentLang = this.state.currentLang;

    return this.state.languages.map((item, index) => {
      const cssClass = classNames('SelectLanguage-Direct-Lang', {
        'SelectLanguage-Direct-Lang-Default': item === currentLang
      });

      const url = generateLink(item);
      url.value.href = getSitecoreApiHost() + url.value.href;

      return (
        <GeneralLink key={item + index} className={cssClass} fields={url}>
          {item}
        </GeneralLink>
      );
    });
  };

  onIconClick = () => {
    this.setState({
      showFlyout: !this.state.showFlyout
    });
  };

  getFlyoutComponent = () => {
    if (isMounted && this.state.showFlyout) {
      const rendringFields = this.props.rendering.fields.data.item;

      return (
        <LanguageFlyout
          dropdownItems={rendringFields.continents.tags}
          title={rendringFields.title}
          description={rendringFields.description}
          currentRegion={this.state.currentRegion}
          currentCountry={this.state.currentCountry}
          currentLang={this.state.currentLang}
          onClose={this.onIconClick}
          onFLyoutChange={this.onFLyoutChange}
          countryLabel={rendringFields.countryLabel.value}
          regionLabel={rendringFields.regionLabel.value}
          languageLabel={rendringFields.languageLabel.value}
          buttonLabel={rendringFields.buttonLabel}
          handleClickOutside={this.handleClickOutsideFlyout}
        />
      );
    }
  };

  handleClickOutsideFlyout = evt => {
    const iconElement = document.querySelector('.SelectLanguage-Icon'),
      svgElement = document.querySelector('.SelectLanguage-Icon svg'),
      pathElement = document.querySelector('.SelectLanguage-Icon svg path'),
      languageElement = document.querySelector('.SelectLanguage-Selected'),
      languageSelectedElement = document.querySelector('.SelectLanguage-Selected span'),
      selectLanguageElement = document.querySelector('.SelectLanguage-Wrapper-Inner'),
      plusElement = document.querySelector('.SelectLanguage-Direct-Lang-Default-Plus'),
      svgPlusElement = document.querySelector('.SelectLanguage-Direct-Lang-Default-Plus svg'),
      pathPlusElement = document.querySelector('.SelectLanguage-Direct-Lang-Default-Plus svg path');

    if (
      svgElement !== evt.target &&
      pathElement !== evt.target &&
      iconElement !== evt.target &&
      languageElement !== evt.target &&
      languageSelectedElement !== evt.target &&
      selectLanguageElement !== evt.target &&
      plusElement !== evt.target &&
      svgPlusElement !== evt.target &&
      pathPlusElement !== evt.target
    ) {
      this.onIconClick();
    }
  };

  /**
   * @function showIcon Function that shows the language icon depending on the state.
   */
  showIcon = () => {
    const { showFlyout } = this.state;
    if (showFlyout) {
      return <WebIcon color='gray' className='SelectLanguage-Icon-ShowFlyout' />;
    }

    return <WebIcon color='gray' />;
  };

  getDesktopVersion() {
    return (
      <div className='SelectLanguage'>
        <div className='SelectLanguage-Wrapper'>
          <div
            className='SelectLanguage-Wrapper-Inner'
            onClick={this.onIconClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onIconClick();
            }}
            role='button'
            tabIndex='0'
            aria-label='Select language'
          >
            <div className='SelectLanguage-Icon'>{this.showIcon()}</div>
            <div className='SelectLanguage-Selected'>
              <span>{this.state.countryCode}</span>
              <span className='SelectLanguage-currentLanguage'>/{this.state.currentLang}</span>
            </div>
          </div>
          {this.getFlyoutComponent()}
        </div>
        <div className='SelectLanguage-Separator' />
        <div className='SelectLanguage-Direct'>
          {this.getAvailableLanguages()}
          <span
            className='SelectLanguage-Direct-Lang-Default SelectLanguage-Direct-Lang-Default-Plus'
            onClick={this.onIconClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onIconClick();
            }}
            role='button'
            tabIndex='0'
            aria-label='Select more lanaguges'
          >
            <PlusIconSmall color='gray' />
          </span>
        </div>
      </div>
    );
  }

  getMobileVersion() {
    return (
      <div className='SelectLanguage-Mobile'>
        {this.getFlyoutComponent()}
        <div
          className='SelectLanguage-Mobile-Wrapper'
          onClick={this.onIconClick}
          onKeyDown={e => {
            if (e.keyCode === 13) this.onIconClick();
          }}
          role='button'
          tabIndex='0'
          aria-label='Select language'
        >
          <WebIcon />
          <Text field={this.state.displayedCountry} tag='span' className='SelectLanguage-Mobile-Label' />
          <ChevronDown className='SelectLanguage-Mobile-ChevronDown' />
        </div>
        <div className='SelectLanguage-Direct'>
          {this.getMobileAvailableLanguages()}
          <div
            className='SelectLanguage-Direct-Lang SelectLanguage-Direct-Lang-Plus'
            onClick={this.onIconClick}
            onKeyDown={e => {
              if (e.keyCode === 13) this.onIconClick();
            }}
            role='button'
            tabIndex='0'
            aria-label='Select more languages'
          >
            <PlusIconSmall color='gray' />
          </div>
        </div>
      </div>
    );
  }

  fallbackLanguageUrl = () => {
    const urlPath = generateLink(DEFAULT_LANGUAGE);

    return urlPath.value.href;
  };

  render() {
    if (!this.state.isDataReceived) {
      return <React.Fragment />;
    }
    const rendringFields = this.props.rendering.fields.data.item;
    if (
      (!rendringFields.continents.tags ||
        (rendringFields.continents.tags && rendringFields.continents.tags.length === 0)) &&
      typeof window !== 'undefined'
    ) {
      window.location.pathname = this.fallbackLanguageUrl();
      return <React.Fragment />;
    }

    return (
      <React.Fragment>
        <Visible lg xl>
          {this.getDesktopVersion()}
        </Visible>
        <Visible xs sm md>
          {this.getMobileVersion()}
        </Visible>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(SelectLanguage);
