import React from 'react';
import i18n from 'i18next';

type LoadMoreProps = {
  onClick: () => void;
};

export const LoadMore: React.FC<LoadMoreProps> = ({ onClick }) => {
  return (
    <div className='LoadMore'>
      <button className='LoadMore__Button' onClick={onClick}>
        {i18n.t('SEARCH | Results Load More')}
      </button>
    </div>
  );
};

export default LoadMore;
