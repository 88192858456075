import React from 'react';
import WeldyExtrusionWeldersListerComponent from './WeldyExtrusionWeldersLister';
import CoveoComponent from '../../../CoveoComponent';

export const WeldyExtrusionWeldersLister: React.FC = () => {
  const searchEngineProps = {
    pipeline: 'SearchProduct',
    searchHub: 'Extrusion-Welders'
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WeldyExtrusionWeldersListerComponent />
    </CoveoComponent>
  );
};

export default WeldyExtrusionWeldersLister;
