import React from 'react';
import CompareUSPItem from './CompareUSPItem';

class CompareUSP extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ isMobile: window.innerWidth <= 960 });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  //get data with products in current category
  getActiveProductsList() {
    const { initialData, selectedCategory } = this.props;
    if (initialData && initialData.length > 0) {
      return initialData.filter(item => {
        return [item.category, item.categoryEn].includes(selectedCategory);
      });
    }
  }

  getUSPItems = () => {
    const activeProducts = this.getActiveProductsList(),
      numberOfProductsToDisplay = this.state && this.state.isMobile ? activeProducts.length : 4;
    return activeProducts.slice(0, numberOfProductsToDisplay).map((element, index) => {
      return (
        <CompareUSPItem
          key={index + element.masterProductName + index}
          uspList={element.usp}
          productTitle={element.masterProductName}
        />
      );
    });
  };
  render() {
    return (
      <div className='CompareUSP'>
        <div className='CompareUSP-Title' />
        {this.getUSPItems()}
      </div>
    );
  }
}

export default CompareUSP;
