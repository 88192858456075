import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import OverlayDialog from '../../../OverlayDialog';
import { addNewList, renameList } from '../../../../store/wishlist/actions';
import { updateInventoryNumber } from '../../../../store/mydevices/actions';
import { CONFLICT_ERROR_CODE } from '../../../../Constants/General';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';

class CreateWishList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./CreateWishList.scss');

    const { editOverlay, editInputPlaceholder, inventoryOverlay, inventoryNamePlaceholder } = this.props,
      inputValue = editOverlay ? editInputPlaceholder : inventoryOverlay ? inventoryNamePlaceholder : '';

    this.setState({
      name: inputValue
    });
  }

  onInputChange = e => {
    this.setState({
      name: e.target.value
    });
  };

  onAddButtonClick = () => {
    const {
        setNewList,
        onClose,
        editOverlay,
        editInputPlaceholder,
        renameList,
        inventoryOverlay,
        updateInventoryNumber,
        article,
        serial
      } = this.props,
      { name } = this.state;
    if (editOverlay) {
      renameList(editInputPlaceholder, name).then(() => {
        if (!this.props.errorCode) {
          onClose();
        }
      });
    } else if (inventoryOverlay) {
      updateInventoryNumber(article, serial, name, getLanguage(this.props.sitecoreContext));
      onClose();
    } else {
      setNewList(name).then(() => {
        if (!this.props.errorCode) {
          onClose();
        }
      });
    }
  };

  render() {
    const {
        'New WishList Description': description,
        'New WishList Input Placeholder': placeholderLabel,
        'New WishList Title': newWishListTitle,
        'New WishList Button Label': creeateNewWishListLabel,
        'Duplicate List Error Message': duplicateErrorMessage,
        'General Error Message': generalErrorMessage,
        'Rename List Title': renameListTitle,
        'Rename List Button Label': renameListButtonLabel,
        'Change Inventory Name Title': changeInventoryNameTitle,
        'Change Inventory Name Text': changeInventoryNameText,
        'Save Button Label': saveButton
      } = this.props.fields,
      { errorCode, onClose, editOverlay, inventoryOverlay } = this.props,
      textDescription = inventoryOverlay ? changeInventoryNameText : description;

    return (
      // NOTE: Whenever you come here and update this component, please migrate over to the new ConfirmationDialog component
      <OverlayDialog
        Title={editOverlay ? renameListTitle : inventoryOverlay ? changeInventoryNameTitle : newWishListTitle}
        buttonLabel={editOverlay ? renameListButtonLabel : inventoryOverlay ? saveButton : creeateNewWishListLabel}
        onClose={onClose}
        onClick={this.onAddButtonClick}
      >
        <div className='CreateWishList'>
          {!editOverlay && <Text field={textDescription} />}
          <input
            type='text'
            className='CreateWishList-Input'
            placeholder={placeholderLabel ? placeholderLabel.value : ''}
            onChange={this.onInputChange}
            value={this.state.name || ''}
          />
          {!inventoryOverlay && errorCode && (
            <div className='CreateWishList-Error'>
              {errorCode === CONFLICT_ERROR_CODE ? (
                <Text field={duplicateErrorMessage} />
              ) : (
                <Text field={generalErrorMessage} />
              )}
            </div>
          )}
        </div>
      </OverlayDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorCode: state.wishlistProducts && state.wishlistProducts.errorCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewList: listName => dispatch(addNewList(listName)),
    renameList: (oldName, newName) => dispatch(renameList(oldName, newName)),
    updateInventoryNumber: (article, serial, inventory, language) =>
      dispatch(updateInventoryNumber(article, serial, inventory, language))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(CreateWishList));
