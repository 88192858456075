import { GET_COUNTRIES_ENDPOINT } from '../../components/Product/productendpoint';
import * as ActionTypes from './actionTypes';

export const getCountries = language => {
  return dispatch => {
    fetch(GET_COUNTRIES_ENDPOINT(language), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(responseJson => {
        dispatch(setCountries(responseJson));
      })
      .catch(err => {
        throw new Error('Something went wrong');
      });
  };
};

const setCountries = countries => {
  return {
    type: ActionTypes.SET_COUNTRIES,
    countries
  };
};
