import * as actionTypes from './actionTypes';

export const setSalesAndServiceFilter = filters => {
  return {
    type: actionTypes.SET_SALES_AND_SERVICE_FILTER,
    filters
  };
};

export const setSalesAndServiceFilterOpenStatus = isOpen => {
  return {
    type: actionTypes.SET_SALES_AND_SERVICE_FILTER_OPEN_STATUS,
    isOpen
  };
};

export const setCoordinates = coordinates => {
  return {
    type: actionTypes.SET_COORDINATES,
    coordinates
  };
};

export const setCurrentCoordinates = coordinates => {
  return {
    type: actionTypes.SET_CURRENT_COORDINATES,
    coordinates
  };
};

export const setBounds = bounds => {
  return {
    type: actionTypes.SET_BOUNDS,
    bounds
  };
};

export const setPreviousPageId = previousId => {
  return {
    type: actionTypes.SET_PREVIOUS_PAGE_ID,
    previousId
  };
};

export const setSearchLocation = searchLocation => {
  return { type: actionTypes.SET_SEARCH_LOCATION, searchLocation };
};

export const setMainContactPoint = mainContactPoint => {
  return { type: actionTypes.SET_MAIN_CONTACT_POINT, mainContactPoint };
};
