import React from 'react';
import AccountInformations from './AccountInformations/index';
import PersonalInformations from './PersonalInformations/index';
import CompanyInformations from './CompanyInformations/index';

class MyProfileComponent extends React.Component {
  render() {
    const { fields } = this.props;
    return (
      <div className='MyProfileComponent'>
        <div className='MyProfileComponent-Wrapper'>
          <AccountInformations fields={fields} />
          <PersonalInformations fields={fields} />
          <CompanyInformations fields={fields} />
        </div>
      </div>
    );
  }
}
export default MyProfileComponent;
