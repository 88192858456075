import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import React from 'react';
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink';

type GeneralLinkProps = {
  fields: Partial<LinkField> & { Link?: LinkField };
  id?: string;
  clickClassName?: string;
  className?: string;
  children: React.ReactNode;
};

/**
 * @class Component that renders a link.
 *
 * Returns jsx markup containing a link wrapped in a div container.
 */
const GeneralLink: React.FC<GeneralLinkProps> = ({ fields, id = '', className, clickClassName = '', children }) => {
  if (!fields) {
    return null;
  }

  const linkFields = fields?.value?.href ? fields : fields.Link ? fields.Link : null;

  if (linkFields === null) {
    return null;
  }

  return (
    <div className={className ? 'GeneralLink ' + className : 'GeneralLink'}>
      <RoutableSitecoreLink link={linkFields} id={id} className={clickClassName}>
        {children}
      </RoutableSitecoreLink>
    </div>
  );
};

export default GeneralLink;
