import React from 'react';
import Tag, { TagType } from '../../../Common/Tag/Tag';
import { ShopifyOrderFulfillmentStatus } from '../../../Shopify/lib/shopify/types';
import { getLocalizedFulfillmentStatus } from '../lib/utils';

export function FulfillmentStatusTag({
  fulfillmentStatus
}: {
  fulfillmentStatus: ShopifyOrderFulfillmentStatus;
}): JSX.Element {
  const tagText = getLocalizedFulfillmentStatus(fulfillmentStatus);

  let tagType: TagType;
  switch (fulfillmentStatus) {
    case 'FULFILLED':
      tagType = 'ok';
      break;
    case 'IN_PROGRESS':
    case 'ON_HOLD':
    case 'OPEN':
    case 'PARTIALLY_FULFILLED':
    case 'PENDING_FULFILLMENT':
    case 'RESTOCKED':
    case 'SCHEDULED':
    case 'UNFULFILLED':
      tagType = 'warning';
      break;
    default:
      tagType = 'secondary';
      break;
  }

  return (
    <Tag key={tagText} type={tagType}>
      {tagText}
    </Tag>
  );
}

export default FulfillmentStatusTag;
