import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Player } from 'video-react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

/**
 * @class MediaCarousel display images and videos in a carousel
 */
class MediaCarousel extends React.Component {
  /**
   * @method constructor
   * @param {Object} props -needed data received
   * @description set initial image/video and caption
   */
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: 0
    };
  }

  /**
   *@method getItems
   *@description displays videos and images in carousel, from data
   */

  getItems = () => {
    const itemsArray = this.props.fields.ImagesAndVideos;
    const items = [];
    itemsArray.forEach(item => {
      const isVideo = item.fields.IsVideo && item.fields.IsVideo.value,
        video = item.fields.Video;
      if (isVideo) {
        if (video && video.value.src && video.value.src !== '' && video.value.src !== '#') {
          items.push(
            <div key={item.id} className='MediaCarousel-Video'>
              <Player
                playsInline
                poster={
                  item.fields.VideoOverlay && item.fields.VideoOverlay.value.src
                    ? item.fields.VideoOverlay.value.src
                    : ''
                }
                aspectRatio='16:9'
                ref={player => {
                  this.player = player;
                }}
              >
                <source src={video && video.value.src ? video.value.src : ''} />
              </Player>
              <p className='legend'>
                <Text field={item.fields.Caption} />
              </p>
            </div>
          );
        }
      } else {
        items.push(
          <div key={item.id} className='MediaCarousel-Image'>
            <Image field={item.fields.Image} />
            <p className='legend'>
              <Text field={item.fields.Caption} />
            </p>
          </div>
        );
      }
    });
    return items;
  };

  /**
   *@method changeSelectedItem
   *@description change current item index on state
   */
  changeSelectedItem = index => {
    this.setState({ selectedItem: index });
    if (this.player) {
      this.player.pause();
    }
  };

  /**
   *@method showItemIndicators
   *@description displays bullet indicators when more than one image/video
   */
  showItemIndicators = () => {
    const itemsArray = this.props.fields.ImagesAndVideos;
    if (itemsArray.length > 1) {
      return true;
    }
    return false;
  };

  render() {
    if (!this.props.fields || !this.props.fields.ImagesAndVideos || this.props.fields.ImagesAndVideos.length < 1) {
      return <div className='MediaCarousel componentContainerMobile' />;
    }
    const caption = this.props.fields.ImagesAndVideos[this.state.selectedItem].fields.Caption || '',
      infiniteLoopSwipe = this.props.fields.ImagesAndVideos.length > 1 ? true : false;

    return (
      <div className='whiteBackground'>
        <div className='MediaCarousel componentContainerMobile'>
          <Carousel
            className='MediaCarousel-Container'
            showThumbs={false}
            showArrows={true}
            infiniteLoop={infiniteLoopSwipe}
            showStatus={false}
            onChange={this.changeSelectedItem}
            selectedItem={this.state.selectedItem}
            showIndicators={this.showItemIndicators()}
            transitionTime={500}
            swipeScrollTolerance={16}
          >
            {this.getItems()}
          </Carousel>
          <Text field={caption} className='MediaCarouselCaption' tag='span' />
        </div>
      </div>
    );
  }
}

export default MediaCarousel;
