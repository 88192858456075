import React from 'react';
import { Row, Col } from 'react-grid-system';
import Checkmark from '../../../Common/Icons/Checkmark';

/**
 * BenefitsWithCheckmarks component with a list of text with checkmark in front
 */
class BenefitsWithCheckmarks extends React.Component {
  getElements() {
    return this.props.benefitsData.map((item, index) => {
      return (
        <div key={item.title + index} className='BenefitsWithCheckmarks'>
          <Checkmark className='BenefitsWithCheckmarks-Checkmark' />
          <div className='BenefitsWithCheckmarks-Text'>{item.title || null}</div>
        </div>
      );
    });
  }

  render() {
    if (!this.props.benefitsData) {
      return <div className='whiteBackground' />;
    }

    return (
      <Row nogutter className='BenefitsWithCheckmarks-Container'>
        <Col lg={8}>{this.getElements()}</Col>
      </Row>
    );
  }
}

export default BenefitsWithCheckmarks;
