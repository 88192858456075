import React from 'react';
import { withRouter } from 'react-router-dom';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CloseIcon from '../../Common/Icons/CloseIcon';
import PlayFullIcon from '../../Common/Icons/PlayFullIcon';
import PlayIcon from '../../Common/Icons/PlayIcon';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import ChevronDown from '../../Common/Icons/ChevronDown';
import ChevronUp from '../../Common/Icons/ChevronUp';
import { removeWhiteSpaces } from '../../../utils/Utils';
import { setVideoCampaign } from '../../../store/product/actions';

const HOW_TO_VIDEOS = 'HOW_TO_VIDEOS',
  RESTRICTED_VIDEOS = 'RESTRICTED_VIDEOS',
  DEFAULT_NUMBER = 3;

/**
 * Video campaign component
 */
class VideoCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentVideo: null,
      numberOfRestrictedVideos: DEFAULT_NUMBER,
      numberOfHowToVideos: DEFAULT_NUMBER
    };
  }

  componentDidMount() {
    this.getDataFromRequest();
  }

  componentDidUpdate(prevProps) {
    const { restrictedVideos, howToVideos, videoCampaignLoaded } = this.props;

    this.getDataFromRequest();

    if (prevProps.videoCampaignLoaded !== videoCampaignLoaded) {
      const firstVideoToDisplay = restrictedVideos[0] || howToVideos[0];

      this.setState({
        currentVideo: firstVideoToDisplay
      });
    }
  }

  async getDataFromRequest() {
    const { sitecoreContext, setVideoCampaign, videoCampaignLoading, videoCampaignLoaded } = this.props;
    const productName = this.getRequestedProductName();

    if (
      productName &&
      videoCampaignLoading !== `${productName}-${sitecoreContext.language}` &&
      videoCampaignLoaded !== `${productName}-${sitecoreContext.language}` &&
      'error' !== videoCampaignLoaded
    ) {
      setVideoCampaign(productName, sitecoreContext.language);
    }
  }

  getRequestedProductName() {
    const { match } = this.props;
    return match && match.params && match.params.product_name;
  }

  /**
   * @method popupbox
   * @description Used to show video in a lightbox
   */
  popupbox(videoPromotionFields) {
    if (!this.state.isOpen) {
      return;
    }

    const { url, title, thumbnail } = videoPromotionFields;
    return (
      <div className='VideoCampaign-Overlay'>
        <div className='VideoCampaign-Overlay-Details componentContainer'>
          <div className='VideoCampaign-Overlay-Details-Name'>{title}</div>
          <div className='VideoCampaign-Overlay-Details-Close'>
            <div
              className='VideoCampaign-Overlay-Details-Close-Button'
              onClick={this.openPopupbox}
              onKeyDown={e => {
                if (e.keyCode === 13) this.openPopupbox();
              }}
              role='button'
              tabIndex='0'
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className='VideoCampaign-Overlay-Video componentContainer'>
          <video
            width='100%'
            height='auto'
            controls
            autoPlay
            preload='none'
            poster={thumbnail}
            controlsList='nodownload'
            onContextMenu='return false;'
          >
            <source src={url + '#t=0.1'} type='video/mp4' />
            <track src='' kind='captions' lang='en' label='english_captions' />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </div>
    );
  }

  getVideosListContent(typeOfVideoList, videoList, titleLabel) {
    const { numberOfHowToVideos, numberOfRestrictedVideos, currentVideo } = this.state;

    if (videoList.length > 0) {
      return (
        <React.Fragment>
          <div className='VideoCampaign-MoreVideos-Title'>
            <Text field={titleLabel} />
          </div>
          <div className='VideoCampaign-MoreVideos-Content'>
            {(() =>
              videoList
                .map((video, index) => {
                  if (video.url && video.url.trim() !== '') {
                    const videoCssClass = classNames('VideoCampaign-MoreVideos-Content-Video', {
                      Active: video.url == currentVideo.url
                    });
                    return (
                      <div
                        key={index + video.title}
                        id={removeWhiteSpaces(video.title)}
                        className={videoCssClass}
                        onClick={() =>
                          this.setState({
                            currentVideo: video
                          })
                        }
                        onKeyDown={e => {
                          if (e.keyCode === 13)
                            this.setState({
                              currentVideo: video
                            });
                        }}
                        role='button'
                        tabIndex='0'
                        aria-label='Play video'
                      >
                        <PlayFullIcon />
                        {video.title}
                      </div>
                    );
                  }
                  return null;
                })
                .filter(item => item !== null)
                .slice(0, typeOfVideoList === HOW_TO_VIDEOS ? numberOfHowToVideos : numberOfRestrictedVideos))()}
          </div>
        </React.Fragment>
      );
    }
  }

  incrementNumberOFMoreVideos = (typeOfVideoList, resetNumber) => {
    if (typeOfVideoList == HOW_TO_VIDEOS) {
      this.setState({
        numberOfHowToVideos: resetNumber ? DEFAULT_NUMBER : this.state.numberOfHowToVideos + DEFAULT_NUMBER
      });
    } else if (typeOfVideoList == RESTRICTED_VIDEOS) {
      this.setState({
        numberOfRestrictedVideos: resetNumber ? DEFAULT_NUMBER : this.state.numberOfRestrictedVideos + DEFAULT_NUMBER
      });
    }
  };
  /**
   * @method openPopupbox
   * @description Used to show or hide video lightbox
   */
  openPopupbox = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  getListOfVideos = typeOfVideoList => {
    const { numberOfHowToVideos, numberOfRestrictedVideos } = this.state,
      { howToVideos, restrictedVideos } = this.props,
      { 'How To Label': HowToLabel, 'Repair Label': RepairLabel } = this.props.fields;
    if (typeOfVideoList == HOW_TO_VIDEOS) {
      const howToVideosLength = howToVideos.length,
        cssMoreClass = classNames('VideoCampaign-MoreVideos-LoadMore', {
          'VideoCampaign-MoreVideos-LoadMore-Hide': howToVideosLength <= DEFAULT_NUMBER
        }),
        showLessResults = numberOfHowToVideos <= howToVideosLength && numberOfHowToVideos > DEFAULT_NUMBER;

      return (
        <div className='VideoCampaign-MoreVideos componentContainer'>
          {this.getVideosListContent(HOW_TO_VIDEOS, howToVideos, HowToLabel)}
          {this.getShowMoreButton(cssMoreClass, showLessResults, HOW_TO_VIDEOS)}
        </div>
      );
    } else if (typeOfVideoList == RESTRICTED_VIDEOS) {
      const restrictedVideosLength = restrictedVideos.length,
        cssMoreClassRestricted = classNames('VideoCampaign-MoreVideos-LoadMore', {
          'VideoCampaign-MoreVideos-LoadMore-Hide': restrictedVideosLength <= DEFAULT_NUMBER
        }),
        showLessRestrictedResults =
          numberOfRestrictedVideos <= restrictedVideosLength && numberOfRestrictedVideos > DEFAULT_NUMBER;

      return (
        <div className='VideoCampaign-MoreVideos componentContainer'>
          {this.getVideosListContent(RESTRICTED_VIDEOS, restrictedVideos, RepairLabel)}
          {this.getShowMoreButton(cssMoreClassRestricted, showLessRestrictedResults, RESTRICTED_VIDEOS)}
        </div>
      );
    }
  };

  getShowMoreButton = (cssClass, showLessResults, typeOfVideoList) => {
    const { 'Load More Label': LoadMoreLabel, 'Less Label': LessLabel } = this.props.fields,
      resetNumber = showLessResults ? true : false;

    return (
      <div
        className={cssClass}
        onClick={() => this.incrementNumberOFMoreVideos(typeOfVideoList, resetNumber)}
        onKeyDown={e => {
          if (e.keyCode === 13) this.incrementNumberOFMoreVideos(typeOfVideoList, resetNumber);
        }}
        role='button'
        tabIndex='0'
      >
        {showLessResults ? (
          <React.Fragment>
            <ChevronUp />
            <Text field={LessLabel} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ChevronDown />
            <Text field={LoadMoreLabel} />
          </React.Fragment>
        )}
      </div>
    );
  };

  render() {
    const { fields, productTitle, howToVideos, restrictedVideos } = this.props,
      { currentVideo } = this.state;
    if (!(howToVideos || restrictedVideos) || !fields || !currentVideo) {
      return null;
    }

    return (
      <div className='click_content_block_promotion_video'>
        <div
          className={
            'VideoCampaign blackBackground click_content_block_promotion_video ' +
            removeWhiteSpaces(currentVideo.title || null)
          }
          onClick={this.openPopupbox}
          onKeyDown={e => {
            if (e.keyCode === 13) this.openPopupbox();
          }}
          role='button'
          tabIndex='0'
        >
          <Image field={{ value: { src: currentVideo.thumbnail } }} />
          <div className='VideoCampaign-Play'>
            <PlayIcon />
          </div>
          <div className='VideoCampaign-Content componentContainer'>
            <div className='VideoCampaign-Content-Rectangle' />
            <div className='VideoCampaign-Content-Category'>{productTitle}</div>
            <div className='VideoCampaign-Content-Title'>{currentVideo.title}</div>
          </div>
        </div>
        {this.popupbox(currentVideo)}
        {this.getListOfVideos(HOW_TO_VIDEOS)}
        {this.getListOfVideos(RESTRICTED_VIDEOS)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productTitle: state.productDetails.masterProductData
      ? state.productDetails.masterProductData.MasterProductName
      : null,
    howToVideos: state.productDetails && state.productDetails.howToVideos ? state.productDetails.howToVideos : [],
    restrictedVideos:
      state.productDetails && state.productDetails.restrictedVideos ? state.productDetails.restrictedVideos : [],
    videoCampaignLoading: state.productDetails.videoCampaignLoading,
    videoCampaignLoaded: state.productDetails.videoCampaignLoaded
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setVideoCampaign: (productName, language) => dispatch(setVideoCampaign(productName, language))
  };
};

const hasItems = function (props) {
  const { fields, restrictedVideos, howToVideos } = props;
  if (!fields || !(restrictedVideos.length || howToVideos.length)) {
    return false;
  }
  return true;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSitecoreContext()(withRouter(WithStickyMenu(VideoCampaign, hasItems))));
