import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { ShopifyLineError } from '../../Shopify/lib/shopify/types';
import { ActionType, CartContext } from '../../Shopify/context/CartContext';

export type InlineErrorProps = {
  variantId: string;
  lineErrors: ShopifyLineError[];
  className?: string;
  autoClose?: boolean;
  autoCloseTime?: number;
};

export const InlineError: React.FC<InlineErrorProps> = ({
  variantId,
  lineErrors,
  className,
  autoClose = true,
  autoCloseTime = 7000
}) => {
  const { dispatch } = useContext(CartContext);
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);

  useEffect(() => {
    const errorsForLine = lineErrors.map(lineError => lineError.message);

    if (errorMessages.length !== errorsForLine.length) {
      setErrorMessages(errorsForLine);
    }
  }, [lineErrors, errorMessages]);

  useEffect(() => {
    let autoCloseCallback: NodeJS.Timeout;

    if (errorMessages.length > 0 && autoClose) {
      autoCloseCallback = setTimeout(() => {
        dispatch({
          type: ActionType.CLOSE_CART_LINE_ERROR,
          payload: {
            variantId
          }
        });
      }, autoCloseTime);
    }

    return () => {
      clearTimeout(autoCloseCallback);
    };
  }, [errorMessages, variantId, autoClose, autoCloseTime, dispatch]);

  return errorMessages.length > 0 ? (
    <div className={classNames('InlineError', className)}>
      {errorMessages.map((message, index) => (
        <div key={index} className='InlineError__Message'>
          {message}
        </div>
      ))}
    </div>
  ) : null;
};

export default InlineError;
