import gql from 'graphql-tag';

export const StoriesQueryString = urlToUse => {
  return gql`
	query StoriesQuery($dataSource: String, $lang: String) {
		stories(dataSource: $dataSource, lang: $lang) {
			storiestype {
				image
				title
				category
				date
				gtm
				linkerButtonLabel
				url
                ${urlToUse}
			}
		}
	}
`;
};
