import React from 'react';
import { Image, Text, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from '../../Common/Icons/ArrowLeft';
import GeneralLink from '../../Common/GeneralLink';
import gql from 'graphql-tag';
import GraphQLData from '../../../lib/GraphQLData';
import { getLanguage } from '../../Common/CookieSettings/SetLanguageData';

const GetBackToInfoForSalesAndServiceQuery = gql`
  query GetBackToInfoForSalesAndServiceQuery(
    $currentItemId: String
    $previousItemId: String
    $componentId: String
    $lang: String
  ) {
    getBackToInfoForSalesAndServiceQuery(
      currentItemId: $currentItemId
      previousItemId: $previousItemId
      componentId: $componentId
      lang: $lang
    ) {
      url
      description
    }
  }
`;

/**
 * @class Component that renders a link, title, image ,text
 *
 * @param {object} props Object containing data needed for the component.
 *
 */

class HeroTopSalesAndServicePoint extends React.Component {
  showLinkBack = () => {
    const queryData =
      this.props.querySalesData && this.props.querySalesData.getBackToInfoForSalesAndServiceQuery
        ? this.props.querySalesData.getBackToInfoForSalesAndServiceQuery
        : {};
    const { url: backLink, description: backLinkDescription } = queryData;

    if (!backLink || !backLinkDescription) {
      return <div className='HeroTopSalesAndServicePoint-Button' />;
    }

    return (
      <GeneralLink fields={{ value: { href: backLink } }} className='HeroTopSalesAndServicePoint-Button'>
        <ArrowLeft />
        <Text field={{ value: backLinkDescription }} />
      </GeneralLink>
    );
  };

  render() {
    if (!this.props.fields) {
      return <div className='HeroTopSalesAndServicePoint-Container componentContainerHero' />;
    }
    const { Title, Image: Img, Description } = this.props.fields;
    return (
      <div className='HeroTopSalesAndServicePoint-Container componentContainerHero'>
        <div className='HeroTopSalesAndServicePoint'>
          {this.showLinkBack()}
          <Text className='HeroTopSalesAndServicePoint-Title' tag='h1' field={Title} />
          <Image className='HeroTopSalesAndServicePoint-Image' field={Img} />
          <RichText className='HeroTopSalesAndServicePoint-Description' tag='div' field={Description} />
        </div>
      </div>
    );
  }
}

export default GraphQLData(GetBackToInfoForSalesAndServiceQuery, {
  name: 'querySalesData',
  options: props => ({
    variables: {
      currentItemId: props.sitecoreContext && props.sitecoreContext.itemId ? props.sitecoreContext.itemId : null,

      previousItemId: props.previousId,
      componentId: props.rendering.dataSource ? props.rendering.dataSource : null,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withSitecoreContext()(HeroTopSalesAndServicePoint));
