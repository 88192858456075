import React, { Fragment } from 'react';
import { FieldValidationErrors } from './field-validation-errors';
import { Label } from './label';
import createErrorMessageLanguagesMap from '../../utils/createErrorMessageLanguagesMap';

const DropdownList = props => {
  const {
    field,
    field: {
      model: { items }
    },
    value,
    onChange,
    tracker,
    errors
  } = props;

  const errorMessagesMap = createErrorMessageLanguagesMap({ field });

  return (
    <Fragment>
      <Label {...props} />
      <div className='dropdown-list'>
        <select
          className={field.model.cssClass}
          id={field.valueField.id}
          name={field.valueField.name}
          value={value[0]}
          onChange={e => handleOnChange(field, e.target.value, onChange, errorMessagesMap)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
        >
          {field.model.showEmptyItem ? <option label=' ' /> : null}
          {items.map(item => (
            <option key={item.itemId} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>

      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

function handleOnChange(field, newValue, callback, errorMessagesMap) {
  let valid = true;
  const errorMessages = [];

  // custom client validation logic here
  if (field.model.required && !newValue) {
    valid = false;
    errorMessages.push(errorMessagesMap.required);
  }

  callback(field.valueField.name, [newValue], valid, errorMessages);
}

export default DropdownList;
