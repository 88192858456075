import React, { ComponentType, useContext } from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import className from 'classnames';
import GeneralLink from '../../Common/GeneralLink';
import { BackgroundColorField, TextField } from '../../../utils/types';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import { ImageField } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { SearchContext } from '../../Coveo/context/SearchContext';

type FilterBannerFIelds = {
  'Leister Background Image': BackgroundColorField;
  'Leister Logo Image': ImageField;
  'Leister Subtitle': TextField;
  'Leister Title': TextField;
  'Leister Button Description': TextField;
  'Weldy Background Image': BackgroundColorField;
  'Weldy Logo Image': ImageField;
  'Weldy Subtitle': TextField;
  'Weldy Title': TextField;
  'Weldy Button Description': TextField;
};

type FilterBannerProps = {
  fields: FilterBannerFIelds;
  match: {
    url: string;
  };
};

const getDisplayData = (fields: FilterBannerFIelds, activeTabValue: string) => {
  if (activeTabValue === 'Weldy') {
    const {
      'Leister Background Image': BackgroundImg,
      'Leister Logo Image': LogoImg,
      'Leister Subtitle': Subtitle,
      'Leister Title': Title,
      'Leister Button Description': buttonDescription
    } = fields;

    return {
      BackgroundImg,
      LogoImg,
      Subtitle,
      Title,
      buttonDescription
    };
  }

  if (activeTabValue === 'Leister') {
    const {
      'Weldy Background Image': BackgroundImg,
      'Weldy Logo Image': LogoImg,
      'Weldy Subtitle': Subtitle,
      'Weldy Title': Title,
      'Weldy Button Description': buttonDescription
    } = fields;

    return {
      BackgroundImg,
      LogoImg,
      Subtitle,
      Title,
      buttonDescription
    };
  }

  return null;
};

export const FilterBanner = ({ fields, match }: FilterBannerProps) => {
  const { state } = useContext(SearchContext);
  const dataToDisplay = getDisplayData(fields, state.activeTabValue);
  const cssClassName = className('FilterBanner', {
    'FilterBanner-Leister': state.activeTabValue === 'Weldy'
  });

  const buttonHref = `#tab=${state.filterBannerTabId}`;
  const currentPathname = match?.url?.slice(match?.url?.indexOf('/'));
  const pathnameParams = currentPathname.indexOf('&') !== -1 ? currentPathname.slice(currentPathname.indexOf('&')) : '';
  const buttonLink = buttonHref + pathnameParams;

  return dataToDisplay ? (
    <div className={cssClassName}>
      <Image className='FilterBanner-BackgroundImage' field={dataToDisplay.BackgroundImg} />
      <div className='FilterBanner-Details'>
        <Image className='FilterBanner-LogoImage' field={dataToDisplay.LogoImg} />
        <Text className='FilterBanner-Title' tag='div' field={dataToDisplay.Title} />
        <Text className='FilterBanner-Subtitle' tag='div' field={dataToDisplay.Subtitle} />
        {/*
				// @ts-ignore TODO: TS JSX error, revisit after migrating all to TS, or upgrading React */}
        <GeneralLink
          fields={{ value: { href: buttonLink } }}
          className='FilterBanner-Button'
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }}
        >
          <Text field={dataToDisplay.buttonDescription} />
        </GeneralLink>
      </div>
    </div>
  ) : null;
};

export default withRouter<
  RouteComponentProps & FilterBannerProps,
  ComponentType<RouteComponentProps & FilterBannerProps>
>(FilterBanner);
