import React from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import getStickyMenuAnchor from '../../../../utils/getStickyMenuAnchor';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BundleIcon from '../../../Common/Icons/Bundle';

type Props = { amountOfBundles: number; sitecoreContext: any };

const BundleSectionLink: React.FC<Props> = ({ amountOfBundles, sitecoreContext }) => {
  const bundleSectionAnchor = getStickyMenuAnchor('ProductBundles', sitecoreContext);

  if (amountOfBundles === 0) {
    return null;
  }

  return (
    <div className='BundleSectionLink'>
      <div className='BundleSectionLink__label'>{i18n.t('SHOP | Bundle section link label')}</div>
      <div className='BundleSectionLink__text'>
        <a href={`#${bundleSectionAnchor}`}>
          <BundleIcon />
          {i18n.t('SHOP | Bundle section link text', { amount: amountOfBundles })}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    amountOfBundles: state.productDetails?.amountOfBundles ?? 0
  };
};

export default connect(mapStateToProps)(withSitecoreContext()(BundleSectionLink));
