import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsWeldingMachinesPipelines,
  CoveoProductsWeldingMachinesSearchHubs
} from './WeldingMachinesLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import WeldingMachinesListerComponent from './WeldingMachinesLister';

export const WeldingMachinesLister = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsWeldingMachinesPipelines[siteName],
    searchHub: CoveoProductsWeldingMachinesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <WeldingMachinesListerComponent />
    </CoveoComponent>
  );
};

export default WeldingMachinesLister;
