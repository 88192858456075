import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

class DevicesAccountInformation extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    import('./DevicesAccountInformation.scss');
  }

  getRowInfo = (label, info1, info2, info3, info4, info5) => {
    return (
      <div className='DevicesAccountInformation-Row'>
        <Text field={label} tag='span' className='DevicesAccountInformation-Row-Label' />
        <span className='DevicesAccountInformation-Row-Info'>
          <span>
            {info1}&nbsp;{info2}
            {info3 || info4 ? <br /> : null}
            {info3}&nbsp;{info4}
            {info5 ? <br /> : null}
            {info5}
          </span>
        </span>
      </div>
    );
  };
  render() {
    const { accountInformationTitleLabel, companyNameLabel, customerNumberLabel, addressLabel, userNameLabel } =
        this.props.fields,
      {
        companyname,
        customernumber,
        firstname,
        lastname,
        streetaddress,
        streetnumber,
        postalcode,
        country,
        city,
        salutation
      } = this.props.dataUser,
      firstNameSalution = salutation ? salutation + ' ' + firstname : firstname;

    return (
      <div className='DevicesAccountInformation'>
        <Text field={accountInformationTitleLabel} tag='div' className='DevicesAccountInformation-Title' />
        {this.getRowInfo(companyNameLabel, companyname)}
        {this.getRowInfo(customerNumberLabel, customernumber)}
        {this.getRowInfo(addressLabel, streetaddress, streetnumber, postalcode, city, country)}
        {this.getRowInfo(userNameLabel, firstNameSalution, lastname)}
      </div>
    );
  }
}

export default DevicesAccountInformation;
