import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Download from '../../../Common/Icons/DownloadIcon';
import { TextField } from '../../../../utils/types';
import i18n from 'i18next';
import { DownloadItem } from '../types';
import FileSaver from 'file-saver';
import { useDesktopDevice } from '../../../../hooks/useDevice';
import classNames from 'classnames';
import Checkbox from '../../../Common/Checkbox/Checkbox';

export const getAvailableLanguages = (languageShort: string[]): string => {
  if (languageShort && languageShort.length) {
    if (languageShort.length > 5) {
      return [...languageShort.slice(0, 5), '...'].join(', ');
    }

    return languageShort.join(', ');
  }

  return '';
};

type AllDownloadItemProps = {
  ItemRelationLabel: TextField;
  toggleItemInBulkDownload: (url: string, checked: boolean) => void;
  downloadItem: DownloadItem;
  initialChecked?: boolean;
};

/**
 * @method AllDownloadItem
 * @description return an Download Item with icon, title, extension and size in MB + language
 * @returns jxs items
 */
const AllDownloadItem: React.FC<AllDownloadItemProps> = ({
  ItemRelationLabel,
  toggleItemInBulkDownload,
  downloadItem,
  initialChecked
}) => {
  const lang = getAvailableLanguages(downloadItem.languageShort);
  const isBulkDownloadAllowed = useDesktopDevice();

  const downloadFile = () => {
    fetch(downloadItem.url)
      .then(res => res.blob())
      .then(blob => {
        FileSaver.saveAs(
          new Blob([blob], {
            type: 'application/octet-stream'
          }),
          `${downloadItem.title}.${downloadItem.extension}`
        );
      });
  };

  // To improve the hovering experience on desktop devices, we don't want the download button within the label, otherwise the checkbox would get hover state as well
  const renderDownloadButton = (
    <button className='AllDownloadItem-Icon' onClick={downloadFile}>
      <Download />
    </button>
  );

  return (
    <div
      className={classNames('AllDownloadItem click_productdetail_block_downloads', {
        isBulkDownloadAllowed: 'BulkDownloadAllowed'
      })}
    >
      <Checkbox
        className='AllDownloadItem-Label'
        checkedLabel={i18n.t('PRODUCT_DOWNLOADS | Remove from bulk download')}
        uncheckedLabel={i18n.t('PRODUCT_DOWNLOADS | Add to bulk download')}
        initialChecked={initialChecked}
        onChange={event => {
          toggleItemInBulkDownload(downloadItem.url, event.target.checked);
        }}
        enabled={isBulkDownloadAllowed}
      >
        <div className='AllDownloadItem-Title'>
          <div className='AllDownloadItem-Title-Inline'>
            {downloadItem.title ?? null}
            {downloadItem.sharedAmongVariants ? (
              <div className='AllDownloadItem-Title-Inline-Subtitle'>
                <Text field={ItemRelationLabel} />: {downloadItem.sharedAmongVariants}
              </div>
            ) : null}
          </div>
          {lang ? (
            <div className='AllDownloadItem-Title-Inline-Language' title={downloadItem.languageShort.join(', ')}>
              {lang}
            </div>
          ) : null}
          <div className='GeneralDownloadsResultItem__Format'>{downloadItem.extension}</div>
        </div>
        {!isBulkDownloadAllowed && renderDownloadButton}
      </Checkbox>
      {isBulkDownloadAllowed && renderDownloadButton}
    </div>
  );
};

export default AllDownloadItem;
