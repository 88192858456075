import React from 'react';
import Helmet from 'react-helmet';
import { getCookie } from '../Common/CookieSettings/CookieUtils';
import { LANG } from '../Common/CookieSettings/CookieConstants';

class MetaDataForShare extends React.Component {
  render() {
    const { showPageData, title, metaDescription, image, variantItem, masterProductLink, currentArticleNumber } =
        this.props,
      currentLanguage = getCookie(LANG),
      metaDataLanguage = currentLanguage ? currentLanguage + '_' + currentLanguage.toUpperCase() : 'en_EN',
      fullTitle = currentArticleNumber ? title + ' ' + currentArticleNumber : title;

    // Do not encode strings here, helmet will do it
    return (
      <Helmet>
        {showPageData && fullTitle && <title>{fullTitle}</title>}
        {showPageData && metaDescription && <meta name='description' content={metaDescription} />}
        {typeof window !== 'undefined' ? <meta property='og:url' content={window.location.href} /> : ''}
        {title && <meta property='og:title' content={fullTitle} />}
        {metaDescription && <meta property='og:description' content={metaDescription} />}
        {image && <meta property='og:image' content={image} />}

        <meta property='og:locale:alternate' content={metaDataLanguage} />
        {variantItem && title && <link rel='canonical' href={masterProductLink} />}
      </Helmet>
    );
  }
}

export default MetaDataForShare;
