import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { CoveoProductsLaserSystemsPipelines, CoveoProductsLaserSystemsSearchHubs } from './LaserSystemsLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import LaserSystemsListerComponent from './LaserSystemsLister';

export const LaserSystemsLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsLaserSystemsPipelines[siteName],
    searchHub: CoveoProductsLaserSystemsSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <LaserSystemsListerComponent />
    </CoveoComponent>
  );
};

export default LaserSystemsLister;
