import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { ComponentType, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useClickAway, useDebounce } from 'react-use';
import { getCoveoProductsSource, getCoveoSitecoreSource } from '../../../AppGlobals';
import CoveoLoading from '../../Common/Icons/CoveoLoading';
import CoveoMagnifier from '../../Common/Icons/CoveoMagnifier';
import { useCoveoQuery } from '../CoveoQuery';
import i18n from 'i18next';
import InstantResultItem, { InstantResultsProperties } from '../InstantResultItem';
import { ActionType, OmniboxContext } from '../context/OmniboxContext';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { SearchEngineContext } from '../context/SearchEngineContext';
import { setLocalStorage } from '../../Common/CookieSettings/CookieUtils';
import { CoveoContext } from '../context/CoveoContext';
import Autosuggest from '../../Common/Autosuggest/Autosuggest';
import { Result } from '@coveo/headless';
import { getResultUrl } from '../ResultItemUtils';
import { normalizeDownloadPagePath } from '../../Product/AllDownloads/getDownloadsProductAndArticle';

const NumberOfMinCharsForInstantSearch = 3;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CoveoOmniboxComponentProps = { fields: any; history: any; sitecoreContext: any };

export const CoveoOmniboxComponent: React.FC<CoveoOmniboxComponentProps> = props => {
  const { fields, history, sitecoreContext } = props;
  const downloadPagePath = sitecoreContext?.productDownloads?.productDownloadsDetailPage || '';
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, coveoSiteName, locale }
  } = useContext(SearchEngineContext);
  const coveoProductsSource = getCoveoProductsSource();
  const coveoSitecoreSource = getCoveoSitecoreSource();
  const { instantResultsController, standaloneSearchBoxController } = useCoveoQuery({
    engine,
    fieldsToInclude: InstantResultsProperties,
    redirectUrl: fields?.['Search Results Page']?.url,
    initUrlManager: false,
    // Items in current language. Can either be from Sitecore source or products source, but tenant (products) or site (content) must match current site
    aq: `@z95xlanguage=="${locale}" AND (
      (@source=="${coveoProductsSource}" AND
        @tenant=="${siteName}"
      ) OR
      (@source=="${coveoSitecoreSource}" AND
        @site=="${coveoSiteName}"))`,
    // Products, Accessories or Variants OR content which has an image and category and one of the "*herotitle" fields is set
    cq: `@herotemplatename==("Product", "Variant") OR
          (@z95xtemplatename=="Accessory" AND NOT @isphasedout=="true") OR
          (@imgsrc @category AND (
            @ez120xpertisepageherotitle OR
            @solutionpageherotitle OR
            @universalpageherotitle OR
            @careerz32xpagez32xtitle OR
            @storypageherotitle))`
  });
  const { state: omniboxState, dispatch } = useContext(OmniboxContext);
  const [query, setQuery] = useState('');
  const [, cancel] = useDebounce(
    () => {
      standaloneSearchBoxController.updateText(query);
    },
    250,
    [query]
  );
  const omniboxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [instantResultsState, setStateInstantResultsState] = useState(instantResultsController.state);
  const [instantResultsOpened, setInstantResultsOpened] = useState(false);
  const [standaloneSearchBox, setStandaloneSearchBox] = useState(standaloneSearchBoxController.state);

  // handle clear input
  const clearInput = useCallback(() => {
    setQuery('');
    standaloneSearchBoxController.updateText('');
    cancel();
  }, [standaloneSearchBoxController, cancel]);

  const handleInstantResultsState = useCallback(() => {
    if (instantResultsController.state.results.length > 0) {
      setInstantResultsOpened(true);
    } else {
      setInstantResultsOpened(false);
    }

    setStateInstantResultsState(instantResultsController.state);
  }, [instantResultsController]);

  useEffect(() => {
    return standaloneSearchBoxController.subscribe(() => setStandaloneSearchBox(standaloneSearchBoxController.state));
  }, [standaloneSearchBoxController]);

  // closing instant results on click outside
  useClickAway(omniboxRef, () => {
    if (instantResultsOpened) {
      setInstantResultsOpened(false);
    }
  });

  // focus on input on mount
  useEffect(() => {
    if (omniboxState.isOmniboxBoxOpened) {
      inputRef.current?.focus();
    } else {
      clearInput();
    }

    return () => undefined;
  }, [omniboxState.isOmniboxBoxOpened, clearInput]);

  useEffect(() => {
    return dispatch({ type: instantResultsOpened ? ActionType.SHOW_INSTANT_RESULTS : ActionType.HIDE_INSTANT_RESULTS });
  }, [dispatch, instantResultsOpened]);

  useEffect(() => {
    return history?.listen(() => dispatch({ type: ActionType.HIDE_SEARCH_BAR }));
  }, [dispatch, history]);

  // close instant results on empty query
  useEffect(() => {
    if (!query) {
      setInstantResultsOpened(false);
    }
  }, [query]);

  // subscribe to searchbox controller - update instant results controller on searchbox change
  useEffect(
    () =>
      standaloneSearchBoxController.subscribe(() => {
        // if query is different than searchbox query - it means that standaloneSearchBoxController got it's value form URL query, no need to process anything, as it's not user input
        if (inputRef?.current?.value !== standaloneSearchBoxController.state.value) {
          return;
        }

        setStandaloneSearchBox(standaloneSearchBoxController.state);

        // if query is longer than 3 chars - check instant results state
        if (standaloneSearchBoxController.state.value.length >= NumberOfMinCharsForInstantSearch) {
          // if query is different than instant results query - update instant results query
          if (instantResultsController.state.q !== standaloneSearchBoxController.state.value) {
            instantResultsController.updateQuery(standaloneSearchBoxController.state.value);
          } else {
            // if query is the same - try to use previous results
            handleInstantResultsState();
          }
        } else {
          // if query is shorter than 3 chars - hide instant results
          setInstantResultsOpened(false);
        }
      }),
    [standaloneSearchBoxController, setStandaloneSearchBox, instantResultsController, handleInstantResultsState]
  );

  // subscribe to instant results controller - on results toggle instant results flyout
  useEffect(
    () => instantResultsController.subscribe(() => handleInstantResultsState()),
    [instantResultsController, handleInstantResultsState]
  );

  const clearInputAndFocus = () => {
    clearInput();

    inputRef?.current?.focus();
  };

  // handle input change - ony key down
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  // search submit
  const submitSearch = () => {
    if (query) {
      standaloneSearchBoxController.updateText(query);
      standaloneSearchBoxController.submit();
    }
  };

  if (standaloneSearchBox.redirectTo) {
    const { redirectTo, value, analytics } = standaloneSearchBox;
    const data = JSON.stringify({ value, analytics });

    setLocalStorage('standaloneSearchBoxStorageKey', data, 'necessary');
    if (window) {
      window.location.href = redirectTo;
    }

    return null;
  }

  return (
    <div className='Searchbox' ref={omniboxRef}>
      <div className='Searchbox__wrapper'>
        <Autosuggest
          value={query}
          instantResultsOpened={instantResultsOpened}
          inputRef={inputRef}
          handleInputChange={handleInputChange}
          instantResultsState={instantResultsState}
          setInstantResultsOpened={setInstantResultsOpened}
          submit={submitSearch}
          clearInputAndFocus={clearInputAndFocus}
          minCharsToSearch={NumberOfMinCharsForInstantSearch}
          onSelect={(selectedResult: Result) => {
            const raw = selectedResult.raw;
            const isPhasedOut = raw.isphasedout === 'true';
            let downloadPageLink = `${normalizeDownloadPagePath(downloadPagePath)}/${raw.productassetnamenormalized}`;

            if (raw.variantarticlenumberraw) {
              downloadPageLink = `${downloadPageLink}/${raw.variantarticlenumberraw}`;
            }

            const url = isPhasedOut ? downloadPageLink : getResultUrl(raw, siteName);

            window.location.href = url;
          }}
          renderItem={(result: Result, selected: boolean) => (
            <InstantResultItem
              selected={selected}
              result={result}
              engine={engine}
              siteName={siteName}
              downloadPagePath={downloadPagePath}
            />
          )}
          placeholderText={i18n.t('SEARCH | Searchbox placeholder')}
        />
        <button
          onClick={submitSearch}
          className='Searchbox__search-button'
          aria-label={i18n.t('SEARCH | Searchbox search button')}
        >
          {instantResultsState.isLoading ? (
            <CoveoLoading className='Searchbox__search-button-loading' color='white' />
          ) : (
            <CoveoMagnifier className='Searchbox__search-button-magnifier' />
          )}
        </button>
      </div>
    </div>
  );
};

export default withRouter<
  RouteComponentProps & CoveoOmniboxComponentProps,
  ComponentType<RouteComponentProps & CoveoOmniboxComponentProps>
>(withSitecoreContext()(CoveoOmniboxComponent));
