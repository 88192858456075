import React from 'react';

interface ImageSlideProps {
  src?: string;
  alt: string;
  sizes: string;
  srcSet: string;
  className?: string;
}

const ImageSlide: React.FC<ImageSlideProps> = ({ src, alt, sizes, srcSet, ...rest }) => {
  return src ? (
    <div className='ImageSlide' {...rest}>
      <img data-testid='image' src={src} alt={alt} sizes={sizes} srcSet={srcSet} />
    </div>
  ) : null;
};

export default ImageSlide;
