import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsInfraredSourcesPipelines,
  CoveoProductsInfraredSourcesSearchHubs
} from './InfraredSourcesLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import InfraredSourcesListerComponent from './InfraredSourcesLister';

export const InfraredSourcesLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsInfraredSourcesPipelines[siteName],
    searchHub: CoveoProductsInfraredSourcesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <InfraredSourcesListerComponent />
    </CoveoComponent>
  );
};

export default InfraredSourcesLister;
