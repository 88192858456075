import React, { useEffect } from 'react';
import { ToggleProps } from './types';
import classNames from 'classnames';

const Toggle = ({ onChange, initialChecked = false, label, name }: ToggleProps): JSX.Element => {
  const [checked, setChecked] = React.useState(!!initialChecked);

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <label className='Toggle_wrapper'>
      <span
        aria-hidden='true'
        className={classNames('Toggle', {
          'Toggle--checked': checked
        })}
      ></span>
      <span
        aria-hidden='true'
        className={classNames('Toggle__marker', {
          'Toggle__marker--checked': checked
        })}
      />
      <input
        id={name}
        checked={checked}
        onChange={event => {
          setChecked(event.target.checked);
          onChange?.(event);
        }}
        name={name}
        type='checkbox'
        className='toggle__input'
      />
      <div className='Toggle_label'>{label}</div>
    </label>
  );
};
export default Toggle;
