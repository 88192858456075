import classNames from 'classnames';
import React from 'react';

export type TagType = 'secondary' | 'ok' | 'warning' | 'primary';

const Tag = ({ type = 'primary', children }: { type?: TagType; children: React.ReactNode }): JSX.Element => (
  <div className={classNames(`tag tag--${type}`)}>{children}</div>
);

export default Tag;
