import React from 'react';
import { UAParser } from 'ua-parser-js';

export type DeviceSize = 'mobile' | 'tablet' | 'desktop';
export const DefaultDevice: DeviceSize = 'desktop';

const uaString = typeof window !== 'undefined' ? window.navigator.userAgent : '';
const uaParserWithFeatureCheck = UAParser(uaString).withFeatureCheck();

function useMobileDevice(): boolean {
  return React.useMemo(() => {
    return uaParserWithFeatureCheck.device.is('mobile');
  }, []);
}

function useTabletDevice(): boolean {
  return React.useMemo(() => {
    return uaParserWithFeatureCheck.device.is('tablet');
  }, []);
}

/**
 * Custom hook to determine if the user is using a desktop device.
 * This is useful for features/output that should be different on desktop vs mobile/tablet devices.
 *
 * Attention! This is for device detection - NOT VIEWPORT size!
 * For viewport size detection, use useViewport
 * Uses React's useMemo to memoize the result.
 * @returns {boolean} True if the user is on a desktop device, false otherwise.
 */
function useDesktopDevice(): boolean {
  return React.useMemo(() => {
    const isDesktop = !['mobile', 'tablet', 'console', 'smarttv', 'wearable'].includes(
      uaParserWithFeatureCheck.device.type ?? ''
    );
    return isDesktop;
  }, []);
}

export { useMobileDevice, useTabletDevice, useDesktopDevice };
