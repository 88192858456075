import React from 'react';
import i18n from 'i18next';
import ProductCategoryItems from './ProductCategoryItems/ProductCategoryItems';
import { DownloadItem, DownloadsList } from './types';

type DownloadItemsProps = {
  downloadList: DownloadsList;
  toggleItemInBulkDownload: (url: string, checked: boolean) => void;
  bulkDownloadItems?: DownloadItem[];
  currentLanguage: string | null;
};

const DownloadItems: React.FC<DownloadItemsProps> = ({
  downloadList,
  toggleItemInBulkDownload,
  bulkDownloadItems,
  currentLanguage
}) => {
  if (!downloadList?.categories) {
    return null;
  }

  return (
    <>
      {downloadList.categories
        .filter(
          category =>
            currentLanguage === null ||
            currentLanguage === i18n.t('PRODUCT_DOWNLOADS | All') ||
            downloadList.items.some(
              item =>
                item.category === category &&
                (item.isVisibleForAllLanguages || (item.language && item.language.includes(currentLanguage)))
            )
        )
        .map(category => {
          return (
            <ProductCategoryItems
              key={category}
              downloadList={downloadList}
              category={category}
              currentLanguage={currentLanguage}
              toggleItemInBulkDownload={toggleItemInBulkDownload}
              bulkDownloadItems={bulkDownloadItems}
            />
          );
        })}
    </>
  );
};

export default DownloadItems;
