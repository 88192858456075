import React from 'react';
import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import { changeOpenAccordionAction, toggleMobileMenu } from '../../../store/mobileMenu/actions';
import Logo from '../../Common/Logo/index';
import MenuTabs from '../MenuTabs/index';
import MenuInfo from '../MenuInfo/index';
import IconsMenu from '../IconsMenu/index';
import SubMenuItems from '../SubMenuItems/index';
import CloseIcon from '../../Common/Icons/CloseIcon';
import GeneralLink from '../../Common/GeneralLink';
import { toggleMobileMyAccount } from '../../../store/accountInformation/actions';
import { OmniboxContext } from '../../Coveo/context/OmniboxContext.tsx';
import MenuIcon from '../../Common/Icons/MenuIcon';

class MobileMenu extends React.Component {
  static contextType = OmniboxContext;

  constructor(props) {
    super(props);
    this.state = {
      scrollFromTop: 0,
      isMenuSticky: true
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), {
      passive: true
    });
    const { isInstantResultsVisible } = this.context.state;
    const { flyoutToOpenFromRedirect, toggleMobileMenu, changeOpenAccordion, isMobileMyAccountOpen, isMobileMenuOpen } =
      this.props;
    if (flyoutToOpenFromRedirect !== null) {
      toggleMobileMenu();
      changeOpenAccordion(flyoutToOpenFromRedirect);
    }
    if (isMobileMyAccountOpen || isMobileMenuOpen || isInstantResultsVisible) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  componentDidUpdate(prevProps) {
    const { isInstantResultsVisible } = this.context.state;

    const { isMobileMenuOpen, urlOnOpenMenu, isMobileMyAccountOpen } = this.props,
      curentUrl = window.location.href;
    if (isMobileMenuOpen && urlOnOpenMenu && curentUrl !== urlOnOpenMenu) {
      this.handleCloseIcon();
    }
    const bodyElement = document.querySelector('body');
    if (
      (isMobileMyAccountOpen || isMobileMenuOpen || isInstantResultsVisible) &&
      window.getComputedStyle(bodyElement).overflow === 'auto'
    ) {
      bodyElement.style.overflow = 'hidden';
    } else if (
      !isMobileMenuOpen &&
      !isMobileMyAccountOpen &&
      !isInstantResultsVisible &&
      window.getComputedStyle(bodyElement).overflow === 'hidden'
    ) {
      bodyElement.style.overflow = 'auto';
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    let newStickyState = true;
    let newScrollPosition = window.pageYOffset;
    // Don't let the sticky state change, if the Omnibox is open!
    // Note: Otherwise it will break when you try to scroll the search results.
    const isOmniboxBoxClosed = !this.context.state.isOmniboxBoxOpened;
    if (isOmniboxBoxClosed && this.state.scrollFromTop < newScrollPosition && newScrollPosition > 50) {
      newStickyState = false;
    }
    this.setState({
      scrollFromTop: newScrollPosition,
      isMenuSticky: newStickyState
    });
  }

  handleCloseIcon = () => {
    const { toggleMobileMenu, toggleMobileMyAccount, isMobileMyAccountOpen } = this.props;
    toggleMobileMenu();
    if (isMobileMyAccountOpen) {
      toggleMobileMyAccount();
    }
  };

  getOpenCloseIcon() {
    if (this.props.isMobileMenuOpen) {
      document.querySelector('body').style.overflow = 'hidden';
      return (
        <div
          onClick={() => {
            this.handleCloseIcon();
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.handleCloseIcon();
          }}
          role='button'
          tabIndex='0'
          className='IconsMenu-menu-Close'
        >
          <CloseIcon />
        </div>
      );
    }

    document.querySelector('body').style.overflow = 'auto';
    return (
      <div
        onClick={() => {
          this.handleCloseIcon();
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) this.handleCloseIcon();
        }}
        role='button'
        tabIndex='0'
        className='IconsMenu-menu'
      >
        <MenuIcon />
      </div>
    );
  }

  getMenuContent = rendering => {
    const { isOmniboxBoxOpened } = this.context.state;
    const cssClass = classNames('Menu-Global-Searchbox', {
      Active: isOmniboxBoxOpened
    });

    return (
      <React.Fragment>
        <div className='Menu-Content'>
          <GeneralLink className='Menu-Content-Logo' fields={{ value: { href: this.props.homeUrl } }}>
            <Logo logo={rendering.fields.SmallLogo} />
          </GeneralLink>
          <IconsMenu
            rendering={rendering}
            fields={rendering.fields}
            showMyAccount={rendering.fields.ShowMyAccount}
            showFavorites={rendering.fields.ShowFavorites}
            menuIcon={this.getOpenCloseIcon()}
          />
        </div>
        <div className={cssClass}>
          <Placeholder name='jss-custom-global-searchbox' rendering={rendering} />
        </div>
      </React.Fragment>
    );
  };

  /**
   * @method getMobileMenu
   * @param {object} rendering object that contain placheolders from sitecore for
   * each mobile menu section with specific data for each of it
   * @returns jsx that contain the mobile menu
   */
  getMobileMenu = rendering => {
    if (this.props.isMobileMenuOpen) {
      return (
        <div className='MobileMenu'>
          <MenuTabs rendering={rendering} />
          <SubMenuItems subMenuItems={rendering.fields.TabsItems} />
          <MenuInfo rendering={rendering} />
        </div>
      );
    }
  };

  render() {
    const rendering = this.props.rendering;
    return (
      <Sticky className='zIndex10' disabled={!this.state.isMenuSticky}>
        {this.getMenuContent(rendering)}
        {this.getMobileMenu(rendering)}
      </Sticky>
    );
  }
}

const MapStateToProps = state => {
  return {
    isMobileMenuOpen: state.mobileMenu.isMobileMenuOpen,
    urlOnOpenMenu: state.mobileMenu.urlOnOpenMenu,
    flyoutToOpenFromRedirect: state.menuFlyout.redirectedPageId,
    isMobileMyAccountOpen: state.accountInformation.isMobileMyAccountOpen
  };
};
const MapDispatchToProps = dispatch => {
  return {
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
    changeOpenAccordion: accordionId => dispatch(changeOpenAccordionAction(accordionId)),
    toggleMobileMyAccount: () => dispatch(toggleMobileMyAccount())
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(withSitecoreContext()(MobileMenu));
