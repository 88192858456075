import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';
import TimeIcon from '../../../Common/Icons/TimeIcon';
import Home2Icon from '../../../Common/Icons/Home2Icon';
import MapPinIcon from '../../../Common/Icons/MapPin';

export type UniversalCareerResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const UniversalCareerResultItem = ({ result, engine, siteName }: UniversalCareerResultItemProps) => {
  const { raw } = result;
  const location = raw.location;
  const country = raw.country;
  const title = raw.jobz32xtitle;
  const workload = raw.workz32xload;
  const linkClass = raw.englishz32xtitle ? (raw.englishz32xtitle as string).replace(/ /g, '') : '';
  const resultLink = getResultUrl(raw, siteName);
  const company = raw.company;

  return (
    <div className='UniversalCareerResultItem'>
      <ResultLink
        className={`UniversalCareerResultItem__Link ${linkClass}`}
        href={resultLink}
        data-testid='career-result-link'
        origin='lister'
        engine={engine}
        result={result}
      >
        <span className='UniversalCareerResultItem__Link-Content'>
          <h3 className='UniversalCareerResultItem__Link-Content-Title' data-testid='career-result-title'>
            {title}
          </h3>
          <span className='UniversalCareerResultItem__Link-Content-Row'>
            <span className='UniversalCareerResultItem__Link-Content-Details' data-testid='career-result-location'>
              <MapPinIcon />
              {location}, {country}
            </span>
            <span className='UniversalCareerResultItem__Link-Content-Details' data-testid='career-result-company'>
              <Home2Icon />
              {company}
            </span>
            <span className='UniversalCareerResultItem__Link-Content-Details' data-testid='career-result-workload'>
              <TimeIcon />
              {workload}
            </span>
            <span
              className='UniversalCareerResultItem__Link-Content-DetailsLabel'
              data-testid='career-result-open-details'
            >
              {i18n.t('SEARCH | ResultItem Career Details label')}
              <ArrowRight />
            </span>
          </span>
        </span>
      </ResultLink>
    </div>
  );
};

export default UniversalCareerResultItem;
