import { setProductToCompareOnCookie, getCookie } from '../Common/CookieSettings/CookieUtils';

function showDescriptionOnCompareBox(productId: string, showMore: boolean) {
  const elID = productId + '_COMPARE_BOX',
    elem = document.getElementById(elID),
    moreElem = elem?.getElementsByClassName('Compare-Text-More'),
    excededElem = elem?.getElementsByClassName('Compare-Text-Exceded');

  if (showMore) {
    moreElem && moreElem[0] && ((moreElem[0] as HTMLElement).style.display = 'block');
    excededElem && excededElem[0] && ((excededElem[0] as HTMLElement).style.display = 'none');
  } else {
    moreElem && moreElem[0] && ((moreElem[0] as HTMLElement).style.display = 'none');
    excededElem && excededElem[0] && ((excededElem[0] as HTMLElement).style.display = 'block');
  }
}

function closeOtherCompareBox(elID: string) {
  //close other opened elements
  const listOfCompareBox = document.querySelectorAll(`div.CompareBox:not([id*="` + elID + `"]`);
  for (let i = 0; i < listOfCompareBox.length; i++) {
    (listOfCompareBox[i] as HTMLElement).style.display = 'none';
  }
}

export function openCloseCompareBox(productId: string, isOpen = false): void {
  const elID = productId + '_COMPARE_BOX',
    elem = document.getElementById(elID);

  closeOtherCompareBox(elID);

  if (elem) {
    if (isOpen) {
      elem.style.display = 'block';

      const compareBoxRect = elem.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const willFitInScreen = compareBoxRect.right <= windowWidth;

      if (!willFitInScreen) {
        elem.classList.add('CompareBox-Right');
      }
    } else {
      elem.style.display = 'none';
    }
  }
}

export function addProductToCompareCategory(productId: string, category: string, successCallback: () => void): void {
  const compareListCookie = JSON.parse(getCookie('compare') || '{}'),
    compareList = compareListCookie ? compareListCookie : {};

  if (compareList && compareList[category] && compareList[category].length === 4) {
    showDescriptionOnCompareBox(productId, false);
  } else {
    showDescriptionOnCompareBox(productId, true);
    successCallback();
  }

  openCloseCompareBox(productId, true);
  setProductToCompareOnCookie(category, productId);
}

export function compareCheckboxEvent(categories: string[], productId: string, successCallback: () => void): void {
  for (let i = 0; i < categories.length; i++) {
    addProductToCompareCategory(productId, categories[i], successCallback);
  }
}
