import Cookies from 'js-cookie';
import { COUNTRY_CODE } from '../../../../Common/CookieSettings/CookieConstants';
import { Cart } from '../types';
import {
  getCartCurrency,
  getVariantById,
  priceToNumber,
  getTotalRemovedValue,
  getCartItemById
} from './ecomAnalyticsCommon';

const getRemoveFromCartEvent = (cart: Cart, lineIds: string[]) => {
  return {
    event: 'remove_from_cart',
    ecommerce: {
      currency: getCartCurrency(cart),
      value: getTotalRemovedValue(cart, lineIds),
      affiliation: Cookies.get(COUNTRY_CODE)?.toLowerCase() ?? 'de',
      items: lineIds.map(lineId => {
        const cartItem = getCartItemById(cart, lineId);
        const product = cartItem?.merchandise.product;

        if (!product) {
          return null;
        }

        const variant = getVariantById(product, cartItem.merchandise.id);

        if (!variant) {
          return null;
        }

        return {
          // required fields
          item_id: cartItem.merchandise.id,

          // recommended fields
          item_name: cartItem._leisterProductName?.value ?? null,
          item_brand: cartItem._leisterProductBrand?.value ?? null,
          item_category: cartItem._leisterProductCategory?.value ?? null,
          item_category2: cartItem._leisterProductSubcategory?.value ?? null,
          price: priceToNumber(variant.price.amount),
          quantity: cartItem.quantity,

          // custom fields (up to 27)
          sku: variant.sku,
          compareAtPrice: variant.compareAtPrice?.amount ? priceToNumber(variant.compareAtPrice.amount) : null,
          condition: product.condition?.value ?? null
        };
      })
    }
  };
};

export const pushRemoveFromCartEvent = (cart: Cart, lineIds: string[]): void => {
  const event = getRemoveFromCartEvent(cart, lineIds);

  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(event);
};
