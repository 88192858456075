import React from 'react';
import { connect } from 'react-redux';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withRouter } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Visible } from 'react-grid-system';
import { toggleWishlist } from '../../../../store/wishlist/actions';
import GeneralLink from '../../../Common/GeneralLink';
import CloseIcon from '../../../Common/Icons/CloseIcon';
import { getCurrentArticleNumber } from '../../../../utils/Utils';

class WishlistPopup extends React.Component {
  getProductDetails(productName, productImage, productNumber) {
    const { 'Wishlist Popup Product Article Label': wishlistPopupProductArticleLabel } = this.props.fields;
    return (
      <div className='WishlistPopup-Product'>
        <div className='WishlistPopup-Product-Image'>
          <img src={productImage + '?imwidth=100'} alt='' loading='lazy' />
        </div>
        <div className='WishlistPopup-Product-Details'>
          <span className='WishlistPopup-Product-Details-Title'>{productName}</span>
          <Text
            tag='span'
            className='WishlistPopup-Product-Details-ArticleLabel'
            field={wishlistPopupProductArticleLabel}
          />
          &#58;&nbsp;
          <span className='WishlistPopup-Product-Details-Article'>{productNumber}</span>
        </div>
      </div>
    );
  }
  getVariantOrAccessorie() {
    const { selectedVariant, masterProductData } = this.props,
      itemImage =
        masterProductData && masterProductData.media && masterProductData.media.length > 0
          ? masterProductData.media[0].url
          : '';
    if (selectedVariant) {
      return this.getProductDetails(
        masterProductData && masterProductData.MasterProductName,
        itemImage,
        selectedVariant.articleNumber
      );
    }

    const accesoriesArticleNumber = getCurrentArticleNumber(this.props),
      currentAccesoriesArticleNumber = accesoriesArticleNumber ? accesoriesArticleNumber.replace('-', '.') : '';

    return this.getProductDetails(
      masterProductData && masterProductData.MasterProductName,
      itemImage,
      currentAccesoriesArticleNumber
    );
  }
  render() {
    const { isWishlistOpen, toggleWishlist } = this.props;
    const {
      'Wishlist Popup Title': wishlistPopupTitle,
      'Wishlist Popup Text': wishlistPopupText,
      'Wishlist Popup Button Description': wishlistPopupButton,
      'Wishlist Popup Button Continue': wishlistPopupButtonContinue,
      'Wishlist Link': wishlistLink
    } = this.props.fields;

    if (!isWishlistOpen) {
      return <div />;
    }

    return (
      <OutsideClickHandler onOutsideClick={toggleWishlist}>
        <div className='WishlistPopup'>
          <div className='WishlistPopup-Heading'>
            <Text field={wishlistPopupTitle} tag='div' className='WishlistPopup-Title' />
            <CloseIcon
              onClick={toggleWishlist}
              onKeyDown={e => {
                if (e.keyCode === 13) toggleWishlist();
              }}
              role='button'
              tabIndex='0'
            />
          </div>
          <Text field={wishlistPopupText} tag='div' className='WishlistPopup-Text' />
          {this.getVariantOrAccessorie()}
          <div
            onClick={toggleWishlist}
            onKeyDown={e => {
              if (e.keyCode === 13) toggleWishlist();
            }}
            role='button'
            tabIndex='0'
          >
            <GeneralLink fields={wishlistLink} className='WishlistPopup-WishlistButton'>
              <Text field={wishlistPopupButton} />
            </GeneralLink>
          </div>
          <Visible sm xs>
            <div
              className='WishlistPopup-ContinueButton'
              onClick={toggleWishlist}
              onKeyDown={e => {
                if (e.keyCode === 13) toggleWishlist();
              }}
              role='button'
              tabIndex='0'
            >
              <Text field={wishlistPopupButtonContinue} />
            </div>
          </Visible>
        </div>
      </OutsideClickHandler>
    );
  }
}

const mapStateToProps = state => {
  return {
    isWishlistOpen: state.wishlistProducts.isWishlistOpen,
    selectedVariant: state.productDetails.selectedVariant,
    masterProductData: state.productDetails.masterProductData ? state.productDetails.masterProductData : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleWishlist: () => dispatch(toggleWishlist())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WishlistPopup));
