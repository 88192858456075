import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import i18n from 'i18next';
import CloseIcon from '../Icons/CloseIcon';
import classNames from 'classnames';

export function SlideOver({
  isOpen,
  onClose,
  title,
  children,
  variant = 'narrow'
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  variant?: 'narrow' | 'wide';
}): JSX.Element | null {
  return (
    <Transition show={isOpen}>
      <Dialog
        onClose={onClose}
        className={classNames('SlideOver', {
          'SlideOver--narrow': variant === 'narrow',
          'SlideOver--wide': variant === 'wide'
        })}
      >
        <Transition.Child
          as={Fragment}
          enter='SlideOver__backdrop--enter'
          enterFrom='SlideOver__backdrop--enter-from'
          enterTo='SlideOver__backdrop--enter-to'
          leave='SlideOver__backdrop--leave'
          leaveFrom='SlideOver__backdrop--leave-from'
          leaveTo='SlideOver__backdrop--leave-to'
        >
          <div className='SlideOver__backdrop' aria-hidden='true' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='SlideOver--enter'
          enterFrom='SlideOver--enter-from'
          enterTo='SlideOver--enter-to'
          leave='SlideOver--leave'
          leaveFrom='SlideOver--leave-from'
          leaveTo='SlideOver--leave-to'
        >
          <Dialog.Panel className='SlideOver__wrapper'>
            <div className='SlideOver__header'>
              <div className='SlideOver__title'>{title}</div>

              <button aria-label={i18n.t('COMMON | Close slide over')} onClick={onClose} className='SlideOver__close'>
                <CloseIcon />
              </button>
            </div>

            {children}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default SlideOver;
