import React from 'react';
import StoryBoxComponent from '../index';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import GraphQLData from '../../../../lib/GraphQLData';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
import { StoryBoxQueryString } from '../StoryBoxQuery';

const StoryBoxQuery = StoryBoxQueryString('lagPageUrl');

class LAGStoryBox extends React.Component {
  render() {
    return <StoryBoxComponent {...this.props} urlToUse='lagPageUrl' />;
  }
}

export default GraphQLData(StoryBoxQuery, {
  name: 'StoryBoxData',
  options: props => ({
    variables: {
      dataSource: props.rendering.dataSource,
      lang: getLanguage(props.sitecoreContext) || 'en'
    },
    fetchPolicy: 'network-only'
  })
})(withSitecoreContext()(LAGStoryBox));
