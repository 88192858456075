import React from 'react';
import i18n from 'i18next';
import ArrowRight from '../../../Common/Icons/ArrowRight';
import ResultLink from '../../ResultLink';
import { getResultUrl } from '../../ResultItemUtils';
import { type KnownSiteNamesType } from '../../../../Constants/KnownSiteNames';
import { Result, SearchEngine } from '@coveo/headless';

export type SearchCareerResultItemProps = {
  result: Result;
  siteName: KnownSiteNamesType;
  engine: SearchEngine;
};

export const SearchCareerResultItem = ({ result, engine, siteName }: SearchCareerResultItemProps) => {
  const { raw } = result;
  const image = raw.image as string;
  const location = raw.location;
  const country = (raw.country as string[])?.join(', ');
  const title = raw.careerz32xpagez32xtitle;
  const workload = raw.workz32xload;
  const resultLink = getResultUrl(raw, siteName);
  const company = raw.company;

  return (
    <div className='SearchCareerResultItem'>
      <div dangerouslySetInnerHTML={{ __html: image }}></div>
      <div className='SearchCareerResultItem__Wrapper'>
        <div className='SearchCareerResultItem__Wrapper-Info'>
          {location}, {country}
        </div>
        <div className='SearchCareerResultItem__Wrapper-Company'>{company}</div>
        <div className='SearchCareerResultItem__Wrapper-Title'>
          {title}
          <span className='SearchCareerResultItem__Wrapper-Title-Slash'> / </span>
          {workload}
        </div>
        <ResultLink
          className='ResultLink--important ResultLink--larger'
          text={i18n.t('SEARCH | ResultItem Career Details label')}
          icon={<ArrowRight />}
          href={resultLink}
          engine={engine}
          result={result}
        />
      </div>
    </div>
  );
};

export default SearchCareerResultItem;
