import i18n from 'i18next';
import { SearchFacet } from '../../../CoveoSettings';

export const AccessoryCategoryFacet = 'AccessoryCategoryFacet';

export const AccessoriesTabs = {
  Nozzles: 'Nozzles',
  'Control Components': 'Control Components',
  'Testing Instruments': 'Testing Instruments',
  'Grooving Devices': 'Grooving Devices',
  Consumables: 'Consumables',
  'Welding Shoes': 'Welding Shoes',
  'Spare Parts': 'Spare Parts',
  Blowers: 'Blowers',
  'General Accessories': 'General Accessories',
  'Machine Specific Accessories': 'Machine Specific Accessories',
  'Laser Components': 'Laser Components'
};

export const CateogryFacet: SearchFacet = {
  field: 'pimaccessorycategory',
  label: () => i18n.t('SEARCH | Facets pimaccessorycategory')
};

export const AccessoriesFacets: SearchFacet[] = [
  {
    field: 'pimapplications',
    categories: [AccessoriesTabs.Nozzles, AccessoriesTabs['Laser Components']],
    label: () => i18n.t('SEARCH | Facets pimapplications')
  },
  {
    field: 'pimaccessoriescategorynozzles',
    categories: [AccessoriesTabs.Nozzles],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategorynozzles')
  },
  {
    field: 'pimnozzleconnection',
    categories: [AccessoriesTabs.Nozzles],
    label: () => i18n.t('SEARCH | Facets pimnozzleconnection')
  },
  {
    field: 'pimnozzleconnectiontype',
    categories: [AccessoriesTabs.Nozzles],
    label: () => i18n.t('SEARCH | Facets pimnozzleconnectiontype')
  },
  {
    field: 'pimaccessoriescategorycontrolcomponents',
    categories: [AccessoriesTabs['Control Components']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategorycontrolcomponents')
  },
  {
    field: 'pimaccessoriescategorytestinginstruments',
    categories: [AccessoriesTabs['Testing Instruments']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategorytestinginstruments')
  },
  {
    field: 'pimaccessoriescategorygroovingdevices',
    categories: [AccessoriesTabs['Grooving Devices']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategorygroovingdevices')
  },
  {
    field: 'pimaccessoriescategoryconsumables',
    categories: [AccessoriesTabs.Consumables],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategoryconsumables')
  },
  {
    field: 'pimweldingrodprofile',
    categories: [AccessoriesTabs.Consumables],
    label: () => i18n.t('SEARCH | Facets pimweldingrodprofile')
  },
  {
    field: 'pimweldingrodmaterial',
    categories: [AccessoriesTabs.Consumables],
    label: () => i18n.t('SEARCH | Facets pimweldingrodmaterial')
  },
  {
    field: 'pimweldingseamgeometry',
    categories: [AccessoriesTabs['Welding Shoes']],
    label: () => i18n.t('SEARCH | Facets pimweldingseamgeometry')
  },
  {
    field: 'pimairguide',
    categories: [AccessoriesTabs['Welding Shoes']],
    label: () => i18n.t('SEARCH | Facets pimairguide')
  },
  {
    field: 'pimaccessoriescategoryspareparts',
    categories: [AccessoriesTabs['Spare Parts']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategoryspareparts')
  },
  {
    field: 'pimconstructiontype',
    categories: [AccessoriesTabs.Blowers],
    label: () => i18n.t('SEARCH | Facets pimconstructiontype')
  },
  {
    field: 'pimstaticpressurerangema',
    categories: [AccessoriesTabs.Blowers],
    label: () => i18n.t('SEARCH | Facets pimstaticpressurerangema'),
    numeric: true
  },
  {
    field: 'pimairflowrange20cma',
    categories: [AccessoriesTabs.Blowers],
    label: () => i18n.t('SEARCH | Facets pimairflowrange20cma'),
    numeric: true
  },
  {
    field: 'pimaccessoriescategorygeneralaccossories',
    categories: [AccessoriesTabs['General Accessories']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategorygeneralaccossories')
  },
  {
    field: 'pimaccessoriescategoryequipmentacc',
    categories: [AccessoriesTabs['Machine Specific Accessories']],
    label: () => i18n.t('SEARCH | Facets pimaccessoriescategoryequipmentacc')
  },
  {
    field: 'pimproductcategory',
    categories: [AccessoriesTabs['Laser Components']],
    label: () => i18n.t('SEARCH | Facets pimproductcategory')
  },
  {
    field: 'pimlaserweldingconcept',
    categories: [AccessoriesTabs['Laser Components']],
    label: () => i18n.t('SEARCH | Facets pimlaserweldingconcept')
  },
  {
    field: 'pimbeamshape',
    categories: [AccessoriesTabs['Laser Components']],
    label: () => i18n.t('SEARCH | Facets pimbeamshape')
  }
];
