import i18n from 'i18next';
import React, { useState } from 'react';
import Dropdown from '../../Common/Dropdown';
import { DownloadsList } from './types';

type DownloadsFiltersProps = {
  downloadList: DownloadsList;
  currentLanguage: string | null;
  setCurrentLanguage: (language: string) => void;
  currentCategory: string | null;
  setCurrentCategory: (category: string) => void;
};

const DownloadsFilters: React.FC<DownloadsFiltersProps> = ({
  downloadList,
  currentLanguage,
  setCurrentLanguage,
  currentCategory,
  setCurrentCategory
}) => {
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const AssetTypeLabel = i18n.t('PRODUCT_DOWNLOADS | Asset Type');
  const AssetLanguageLabel = i18n.t('PRODUCT_DOWNLOADS | Asset Language');
  const AllLabel = i18n.t('PRODUCT_DOWNLOADS | All');
  const doWeHaveCategories = downloadList?.categories?.length > 0;
  const doWeHaveLanguages = downloadList?.languages?.length > 0;
  const categoriesList = [{ title: AllLabel }];
  const languagesList = [{ title: AllLabel }];

  if (doWeHaveCategories) {
    const isLanguageSelected = currentLanguage && currentLanguage !== AllLabel;
    const filteredCategories = downloadList.categories.filter(categoryItem => {
      if (isLanguageSelected) {
        return downloadList.items.some(
          item => item.category === categoryItem && item.language && item.language.includes(currentLanguage)
        );
      } else {
        return true;
      }
    });

    for (const element of filteredCategories) {
      categoriesList.push({ title: element });
    }
  }

  if (doWeHaveLanguages) {
    const isCategorySelected = (currentCategory && currentCategory !== AllLabel) ?? false;
    const filteredLanguages = downloadList.languages.filter(languageItem => {
      if (isCategorySelected && currentLanguage) {
        return downloadList.items.some(
          item => item.category === currentCategory && item.language && item.language.includes(languageItem.language)
        );
      } else {
        return true;
      }
    });

    for (const element of filteredLanguages) {
      languagesList.push({ title: element.language });
    }
  }

  return (
    <div className='AllDownloads-Dropdowns'>
      {doWeHaveCategories ? (
        <Dropdown
          label={AssetTypeLabel}
          currentItem={currentCategory ? { title: currentCategory } : { title: AllLabel }}
          items={categoriesList || []}
          onSet={(item: { title: string }) => setCurrentCategory(item.title)}
          isDropdownOpen={isCategoryDropdownOpen}
          onDropdownClick={() => setIsCategoryDropdownOpen(!isCategoryDropdownOpen)}
          hasSearch={true}
          height={280}
        />
      ) : null}
      {doWeHaveLanguages ? (
        <Dropdown
          label={AssetLanguageLabel}
          hasSearch={true}
          currentItem={currentLanguage ? { title: currentLanguage } : { title: AllLabel }}
          items={languagesList || []}
          onSet={(item: { title: string }) => setCurrentLanguage(item.title)}
          isDropdownOpen={isLanguageDropdownOpen}
          onDropdownClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)}
          height={280}
        />
      ) : null}
    </div>
  );
};

export default DownloadsFilters;
