import React from 'react';
import i18n from 'i18next';
import { SearchResultItemProps } from '../ProductResultItem/types';
import Download from '../../../Common/Icons/DownloadIcon';
import Checkbox from '../../../Common/Checkbox/Checkbox';
import Icon from '../../../Common/Icon';
import { getAvailableLanguages } from '../../../Product/AllDownloads/AllDownloadItem';
import FileSaver from 'file-saver';
import { useDesktopDevice } from '../../../../hooks/useDevice';

type GeneralDownloadsResultItemProps = {
  accessProtected: boolean;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & SearchResultItemProps;

export const GeneralDownloadsResultItem = ({
  result,
  language,
  checked = false,
  onChange,
  accessProtected
}: GeneralDownloadsResultItemProps): JSX.Element => {
  const { raw } = result;
  const mediaName = raw[`gd_media_name_${language}`] ?? '';
  const documentType = raw[`gd_media_type_${language}`] ?? '';
  const languages = raw.gd_media_language_codes ?? [];
  const extension = raw.gd_file_extension ?? '';
  const locked = accessProtected ? false : raw.gd_is_restricted === 'True' ?? false;
  const isBulkDownloadAllowed = useDesktopDevice();

  const downloadFile = () => {
    fetch(raw.gd_cdn_url as string)
      .then(res => res.blob())
      .then(blob => {
        FileSaver.saveAs(
          new Blob([blob], {
            type: 'application/octet-stream'
          }),
          raw.gd_media_assetname as string
        );
      });
  };

  const downloadsItemContent = (
    <>
      {locked && <Icon name='icon-web-16-lock' color='black-30' className='GeneralDownloadsResultItem__Locked-icon' />}
      <div className='GeneralDownloadsResultItem__Main'>
        <h3 className='GeneralDownloadsResultItem__Name'>{mediaName}</h3>
        <div className='GeneralDownloadsResultItem__Type'>{documentType}</div>
        <div className='GeneralDownloadsResultItem__File-info'>
          <div className='GeneralDownloadsResultItem__Language'>
            {getAvailableLanguages((languages as string[]) ?? [])}
          </div>
          <div className='GeneralDownloadsResultItem__Format'>{extension}</div>
        </div>
      </div>
    </>
  );

  return (
    <div className='GeneralDownloadsResultItem'>
      {isBulkDownloadAllowed ? (
        <Checkbox
          enabled={!locked}
          checkedLabel={i18n.t('PRODUCT_DOWNLOADS | Remove from list')}
          uncheckedLabel={i18n.t('PRODUCT_DOWNLOADS | Add to list')}
          initialChecked={checked}
          onChange={onChange}
        >
          {downloadsItemContent}
        </Checkbox>
      ) : (
        <div
          onClick={downloadFile}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              downloadFile();
            }
          }}
          role='button'
          tabIndex={0}
          className='GeneralDownloadsResultItem__Content'
        >
          {downloadsItemContent}
        </div>
      )}
      <div className='GeneralDownloadsResultItem__Downloads'>
        {locked ? (
          <>
            <Icon name='icon-web-24-envelope' />
            <div className='GeneralDownloadsResultItem__Locked'>
              <div className='GeneralDownloadsResultItem__Locked-text'>{i18n.t('PRODUCT_DOWNLOADS | Request')}</div>
            </div>
          </>
        ) : (
          <button className='GeneralDownloadsResultItem__Button' onClick={downloadFile}>
            <div className='visually-hidden'>{i18n.t('PRODUCT_DOWNLOADS | Downloads label')}</div>
            <Download />
          </button>
        )}
      </div>
    </div>
  );
};

export default GeneralDownloadsResultItem;
