export const normalizeDownloadPagePath = (path: string, removeLanguage = false): string => {
  let normalizedPath = path?.replace('/,-w-,', '') ?? '';

  if (removeLanguage) {
    normalizedPath = normalizedPath.split('/').splice(2).join('/');
  }

  return normalizedPath;
};

export const cleanPath = (path: string): string => {
  return path?.toLowerCase().split('?')[0];
};

export const isProductDownloadPage = (path: string, productDownloadsPagePath: string): boolean => {
  if (!path || !productDownloadsPagePath) {
    return false;
  }

  const normalizedDownloadPagePath = normalizeDownloadPagePath(productDownloadsPagePath, true);
  const isProductDownloadsPage = cleanPath(path).includes(normalizedDownloadPagePath.toLowerCase());

  if (!isProductDownloadsPage) {
    return false;
  }

  const productName = getProductNameFromPath(path, productDownloadsPagePath);

  return !!productName;
};

export const getProductNameFromPath = (path: string, productDownloadsPagePath: string): string => {
  const normalizedDownloadPagePath = normalizeDownloadPagePath(productDownloadsPagePath, true);
  const pathAfterDownloadsPrefix = cleanPath(path).split(normalizedDownloadPagePath.toLowerCase())?.[1];

  if (!pathAfterDownloadsPrefix) {
    return '';
  }

  const pathParts = pathAfterDownloadsPrefix.split('/');
  let productName = pathParts.pop();

  if (productName?.match(/^[0-9]{3}[-.][0-9]{3}$/)) {
    productName = pathParts.pop();
  }

  return productName ?? '';
};

export const getArticleNumberFromPath = (path: string, productDownloadsPagePath: string): string => {
  const normalizedDownloadPagePath = normalizeDownloadPagePath(productDownloadsPagePath, true);
  const pathAfterDownloadsPrefix = cleanPath(path).split(normalizedDownloadPagePath.toLowerCase())?.[1];

  if (!pathAfterDownloadsPrefix) {
    return '';
  }

  const pathParts = pathAfterDownloadsPrefix.split('/');
  const articleNumber = pathParts.pop();

  if (articleNumber?.match(/^[0-9]{3}[-.][0-9]{3}$/)) {
    return articleNumber;
  }

  return '';
};

export const getProductName = (
  match: {
    params?: {
      product_name?: string;
      article_number?: string;
      sitecoreRoute?: string;
    };
  },
  productDownloadsPagePath: string
): string => {
  if (match?.params?.product_name) {
    return match.params.product_name;
  }

  if (match?.params?.sitecoreRoute) {
    return getProductNameFromPath(match.params.sitecoreRoute, productDownloadsPagePath);
  }

  return '';
};

export const getArticleNumber = (
  match: {
    params?: {
      product_name?: string;
      article_number?: string;
      sitecoreRoute?: string;
    };
  },
  productDownloadsPagePath: string
): string => {
  if (match?.params?.article_number) {
    return match.params.article_number;
  }

  if (match?.params?.sitecoreRoute) {
    return getArticleNumberFromPath(match.params.sitecoreRoute, productDownloadsPagePath);
  }

  return '';
};
