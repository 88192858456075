import OutsideClickHandler from 'react-outside-click-handler';
import React, { Fragment, useContext, useEffect } from 'react';
import i18n from 'i18next';
import { CartContext, ActionType } from '../context/CartContext';
import { Transition } from '@headlessui/react';
import Price from '../Price/Price';

const AddToCartNotification = (): JSX.Element | null => {
  const { state, dispatch } = useContext(CartContext);

  useEffect(() => {
    if (!state.isCartNotificationOpen) return;

    const timeId = setTimeout(() => {
      dispatch({ type: ActionType.CLOSE_CART_NOTIFICATION });
    }, 7000);

    return () => {
      clearTimeout(timeId);
    };
  }, [dispatch, state.isCartNotificationOpen]);

  const handleClickOutside = (evt: MouseEvent) => {
    // Only handle it if the notification is open
    if (!state.isCartNotificationOpen) return;

    const userIconElement = document.querySelector('.AddToCartNotification');

    if (userIconElement !== evt.target) {
      dispatch({ type: ActionType.CLOSE_CART_NOTIFICATION });
    }
  };

  const openCart = () => {
    dispatch({ type: ActionType.CLOSE_CART_NOTIFICATION });
    dispatch({ type: ActionType.OPEN_CART });
  };

  const title = state.isBundle ? state.bundleInfo.title : state.cartItem?.merchandise.product.title;
  const amount = state.isBundle ? state.bundleInfo.amount : state.cartItem?.cost.totalAmount.amount;
  const currencyCode = state.isBundle ? state.bundleInfo.currencyCode : state.cartItem?.cost.totalAmount.currencyCode;
  const sku = state.isBundle ? undefined : state.cartItem?.merchandise.sku;
  const amountOfItems = state.isBundle ? state.bundleInfo.amountOfItems : undefined;

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Transition show={state.isCartNotificationOpen}>
        <Transition.Child
          as={Fragment}
          enter='CartNotification--enter'
          enterFrom='CartNotification--enter-from'
          enterTo='CartNotification--enter-to'
          leave='CartNotification--leave'
          leaveFrom='CartNotification--leave-from'
          leaveTo='CartNotification--leave-to'
        >
          <div className='AddToCartNotification'>
            <div className='AddToCartNotification__Wrapper'>
              <div className='AddToCartNotification__Container'>
                <div className='AddToCartNotification__Title'>{i18n.t('SHOP | Added to Cart')}</div>
                <div className='AddToCartNotification__Description'>{i18n.t('SHOP | View or continue')}</div>
                <div className='AddToCartNotification__ProductInfo'>
                  {state.cartItem?.merchandise.product.featuredImage?.url && (
                    <div className='AddToCartNotification__ProductInfo-image'>
                      <img
                        width={56}
                        height={56}
                        data-testid='cart-item-image'
                        alt={
                          state.cartItem?.merchandise.product.featuredImage?.altText ||
                          state.cartItem?.merchandise.product.title
                        }
                        src={`${state.cartItem?.merchandise.product.featuredImage.url}&width=112&height=112`}
                      />
                    </div>
                  )}
                  <div className='AddToCartNotification__ProductInfo-variant'>
                    <div className='AddToCartNotification__ProductInfo-product-title' data-testid='cart-item-title'>
                      {title}
                    </div>
                    {amount && currencyCode && (
                      <Price
                        className='AddToCartNotification__ProductInfo-price'
                        data-testid='cart-item-price'
                        amount={amount}
                        currencyCode={currencyCode}
                      />
                    )}

                    {sku && (
                      <div className='AddToCartNotification__ProductInfo-product-text' data-testid='cart-item-sku'>
                        {i18n.t('SHOP | Cart article number')}: {state.cartItem?.merchandise.sku}
                      </div>
                    )}

                    {amountOfItems && (
                      <div
                        className='AddToCartNotification__ProductInfo-product-text'
                        data-testid='cart-item-amountOfItems'
                      >
                        {i18n.t('SHOP | Bundle notification amount of items', {
                          amount: amountOfItems
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className='AddToCartNotification__Buttons'>
                  <button className='button' onClick={openCart}>
                    {i18n.t('SHOP | View Cart')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </OutsideClickHandler>
  );
};

export default AddToCartNotification;
