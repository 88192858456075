import React, { useEffect, useRef, useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import MapPinEvent from '../../../../../assets/svg/map/map-pin-event.svg';
import { EventMarkerProps } from './types';

export const EventMarker = (props: EventMarkerProps): JSX.Element => {
  const [showInfoWindows, setShowInfoWindow] = useState<boolean>(true);
  const infoRef = useRef<HTMLDivElement>(null);
  const regionName = props.eventCountry?.fields?.RegionName;
  const eventCity = props.eventCity;
  const position = {
    lat: Number(props.eventCoordinatesLatitude?.value),
    lng: Number(props.eventCoordinatesLongitude?.value)
  };

  const locationHtml = (eventCity?.value || regionName?.value) && (
    <div className='EventMarker-Location'>
      <span className='wrap-context'>
        {eventCity?.value && <Text field={eventCity} />}
        {eventCity?.value && regionName?.value && <span className='EventInformationSection-DetailsSeparator'>/</span>}
      </span>
      <span className='wrap-context'>{regionName?.value && <Text field={regionName} />}</span>
    </div>
  );

  const addressHtml = (
    <div className='EventMarker-Address'>
      {props.eventVenue && <Text field={props.eventVenue} />}
      <div className='EventMarker-Address-MultiLine'>{props.eventAddress && <Text field={props.eventAddress} />}</div>
    </div>
  );

  const titleHtml = props.eventTitle && (
    <div className='EventMarker-Title'>
      <Text field={props.eventTitle} />
    </div>
  );

  const onMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const closeInfoWindow = () => {
    setShowInfoWindow(false);
  };

  const onOutsideClick = (e: Event) => {
    closeInfoWindow();
  };

  useEffect(() => {
    if (!showInfoWindows) return;
    const handleClickOutside = (event: Event) => {
      // Check if the click was outside of the info window
      if (event.target && infoRef.current && !(infoRef.current as HTMLElement).contains(event.target as Node)) {
        onOutsideClick(event);
      }
    };

    const mapRefDom = props.mapRef?.current;
    if (mapRefDom) {
      mapRefDom.addEventListener('click', handleClickOutside, true);
      return () => {
        mapRefDom.removeEventListener('click', handleClickOutside, true);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoRef.current]); // Note: This should be fine, as it does re-render the InfoWindow part

  return (
    <Marker
      icon={{
        url: MapPinEvent,
        scaledSize: new window.google.maps.Size(40, 40)
      }}
      position={position}
      onClick={onMarkerClick}
    >
      {showInfoWindows && (
        <InfoWindow
          position={position}
          options={{
            pixelOffset: { width: 0, height: -48 },
            maxWidth: 280
          }}
          onCloseClick={closeInfoWindow}
        >
          <div className='ContactPointInfo' ref={infoRef}>
            {locationHtml}
            {titleHtml}
            {addressHtml}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default EventMarker;
