import React from 'react';
import { connect } from 'react-redux';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getCompareProductsData } from '../../../../store/compare/actions';
import { getRawValueFromCompare, getCookie } from '../../../Common/CookieSettings/CookieUtils';
import { COMPARE } from '../../../Common/CookieSettings/CookieConstants';
import ProductComparisonAccordion from '../ProductComparisonAccordion';
import CompareApplicationList from '../CompareApplication/CompareApplicationList';
import CompareUSP from '../CompareUSP';
import CompareProductsWithStickyMenu from '../CompareProductsWithStickyMenu';
import CompareTechnicalSpecsList from '../CompareTechnicalSpecsList';
import { getFavoriteContactId } from '../../../../store/favoriteContact/actions';
import { getLanguage } from '../../../Common/CookieSettings/SetLanguageData';
const METRIC = 'metric';

class CompareProductsHero extends React.Component {
  constructor(props) {
    super(props);
    this.state = { system: METRIC };
  }

  componentDidMount() {
    const cookieCompareList = getCookie(COMPARE),
      { getFavoriteContactId, favoriteContactId, sitecoreContext } = this.props;

    getFavoriteContactId(favoriteContactId, sitecoreContext);

    if (cookieCompareList && Object.keys(JSON.parse(cookieCompareList)).length) {
      this.props.setComparedProductData(
        getRawValueFromCompare(),
        getLanguage(this.props.sitecoreContext),
        this.props.sitecoreContext.site.name
      );
    }
  }

  onSystemClick = value => {
    this.setState({ system: value });
  };

  shouldDisplayApplication = () => {
    const { initialData, selectedCategory } = this.props;

    if (!selectedCategory) {
      return initialData[0] && initialData[0].applications && initialData[0].applications.length > 0;
    }

    if (selectedCategory) {
      const firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : '';
      return initialData.find(item => {
        return item.categoryEn === firstSelectedCategory && item.applications && item.applications.length > 0;
      });
    }

    return false;
  };

  shouldDisplayUSP = () => {
    const { initialData, selectedCategory } = this.props;

    if (!selectedCategory) {
      return initialData[0] && initialData[0].usp && initialData[0].usp.length > 0;
    }

    if (selectedCategory) {
      const firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : '';
      return initialData.find(item => {
        return item.categoryEn === firstSelectedCategory && item.usp && item.usp.length > 0;
      });
    }

    return false;
  };

  render() {
    const { fields, initialData, selectedCategory, favouriteContactId } = this.props,
      firstSelectedCategory = selectedCategory ? selectedCategory.split(',')[0] : null;
    if (!fields || initialData.length === 0) {
      return <div className='CompareProductsHero' />;
    }

    const { 'Tehnical Specs Label': specsLabel, 'Application Label': applicationLabel, 'USP Label': uspLabel } = fields;

    return (
      <div className='CompareProductsHero'>
        <CompareProductsWithStickyMenu
          fields={fields}
          initialData={initialData}
          selectedCategory={firstSelectedCategory}
          favouriteContact={favouriteContactId}
        />
        <ProductComparisonAccordion
          Label={specsLabel}
          fields={fields}
          onSystemClick={this.onSystemClick}
          system={this.state.system}
          hasSystem
          shouldDisplay
        >
          <CompareTechnicalSpecsList
            initialData={initialData}
            selectedCategory={firstSelectedCategory}
            fields={fields}
            system={this.state.system}
          />
        </ProductComparisonAccordion>
        <ProductComparisonAccordion Label={applicationLabel} shouldDisplay={this.shouldDisplayApplication()}>
          <CompareApplicationList initialData={initialData} selectedCategory={firstSelectedCategory} fields={fields} />
        </ProductComparisonAccordion>
        <ProductComparisonAccordion Label={uspLabel} shouldDisplay={this.shouldDisplayUSP()}>
          <CompareUSP initialData={initialData} selectedCategory={firstSelectedCategory} />
        </ProductComparisonAccordion>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialData: state.compareProduct.initialData,
    selectedCategory: state.compareProduct.selectedCategory,
    favouriteContactId: state.favoriteContact && state.favoriteContact.favoriteContactId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setComparedProductData: (productsid, language, tenant) =>
      dispatch(getCompareProductsData(productsid, language, tenant)),
    getFavoriteContactId: (id, sitecoreContextFields) => dispatch(getFavoriteContactId(id, sitecoreContextFields))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withSitecoreContext()(CompareProductsHero));
