import React from 'react';
import UniversalImageSlider from '../UniversalImageSlider';
import { LAG_PAGE_LINK } from '../../GeneralConstants/GeneralConstants';

export default class LAGImageSlider extends React.Component {
  render() {
    return (
      <UniversalImageSlider
        leftImageWidth={66.66}
        imageClass='ImageSlider-Container-Multiple'
        fields={this.props.fields}
        urlToUse={LAG_PAGE_LINK}
      />
    );
  }
}
