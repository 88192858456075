import React, { useContext } from 'react';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import {
  CoveoProductsMassFlowDevicesPipelines,
  CoveoProductsMassFlowDevicesSearchHubs
} from './MassFlowDevicesLister.settings';
import CoveoComponent from '../../../CoveoComponent';
import MassFlowDevicesListerComponent from './MassFlowDevicesLister';

export const MassFlowDevicesLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);

  const searchEngineProps = {
    pipeline: CoveoProductsMassFlowDevicesPipelines[siteName],
    searchHub: CoveoProductsMassFlowDevicesSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <MassFlowDevicesListerComponent />
    </CoveoComponent>
  );
};

export default MassFlowDevicesLister;
