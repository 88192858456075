import React, { useContext } from 'react';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { CoveoSearchProductPipelines, CoveoSearchProductSearchHubs } from '../../CoveoSettings';
import CoveoComponent from '../../CoveoComponent';
import DownloadsListerComponent from './DownloadsLister';

export const DownloadsLister: React.FC = () => {
  const {
    state: { siteName }
  } = useContext(SearchEngineContext);
  const searchEngineProps = {
    pipeline: CoveoSearchProductPipelines[siteName],
    searchHub: CoveoSearchProductSearchHubs[siteName]
  };

  return (
    <CoveoComponent searchEngineProps={searchEngineProps}>
      <DownloadsListerComponent />
    </CoveoComponent>
  );
};

export default DownloadsLister;
