import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import HeadlineWithTitleAndSubtitle from '../../Common/HeadlineWithTitleAndSubtitle/index';
import ChevronDown from '../../Common/Icons/ChevronDown';
import WithStickyMenu from '../../../hoc/WithStickyMenu';
import { getCurrentArticleNumber } from '../../../utils/Utils';

/**
 * ProductApplications component with images and text
 */
class ProductApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nrOfItemsToShow: 9
    };
  }

  getApplicationImage(item) {
    if (item.imageUrl) {
      return <img src={item.imageUrl + '?imwidth=100'} alt={item.name} loading='lazy' />;
    }
  }
  getUniqueAplicationList() {
    const { variants, Applications } = this.props;
    let initialApplications = [];
    if (variants && variants.length > 0) {
      variants.map(item => {
        if (item.applications) {
          initialApplications = [...initialApplications, ...item.applications];
        }
        return null;
      });
    }
    const masterProductApplications = Applications && Applications.length ? Applications : [];

    const applicationsList = [...initialApplications, ...masterProductApplications];
    if (applicationsList.length > 0) {
      return Array.from(new Set(applicationsList.map(a => a.name))).map(name => {
        return applicationsList.find(a => a.name === name);
      });
    }
    return [];
  }

  getApplicationData = () => {
    const { variants } = this.props,
      masterProductApplications = this.getUniqueAplicationList(),
      currentArticleNumber = getCurrentArticleNumber(this.props);
    let applicationsData = [];

    if (currentArticleNumber && variants && variants.length) {
      const currentVariant = variants.find(
        item => item.articleNumber === currentArticleNumber.replace('-', '.').trim()
      );
      applicationsData = currentVariant && currentVariant.applications ? currentVariant.applications : [];
    } else {
      applicationsData = masterProductApplications;
    }

    return Array.from(new Set(applicationsData.map(a => a.name))).map(name => {
      return applicationsData.find(a => a.name === name);
    });
  };

  getApplicationItems() {
    const applicationsData = this.getApplicationData();
    return applicationsData
      .slice(0, this.state.nrOfItemsToShow)
      .map((item, index) => {
        const cssClass = classNames('ProductApplications-Item-Image', {
          'ProductApplications-Item-Image-Placeholder': !item.imageUrl
        });
        if (!item.name) {
          return null;
        }
        return (
          <Col md={4} key={item.name + index} className='ProductApplications-Item'>
            <div className={cssClass}>{this.getApplicationImage(item)}</div>
            <div className='ProductApplications-Item-Name'>{item.name}</div>
          </Col>
        );
      })
      .filter(el => el !== null);
  }

  changeNumberOfItemsToShow(newNumber) {
    this.setState({
      nrOfItemsToShow: newNumber
    });
  }

  getLoadMoreBtn(LoadMoreLabel) {
    const applicationsData = this.getApplicationData(),
      dataLength = applicationsData.length,
      cssMoreClass = classNames('ProductApplications-More', {
        'ProductApplications-More-Hide': dataLength <= this.state.nrOfItemsToShow
      });
    return (
      <div
        className={cssMoreClass}
        onClick={() => this.changeNumberOfItemsToShow(dataLength)}
        onKeyDown={e => {
          if (e.keyCode === 13) this.changeNumberOfItemsToShow(dataLength);
        }}
        role='button'
        tabIndex='0'
      >
        <ChevronDown />
        <Text field={LoadMoreLabel} />
      </div>
    );
  }

  render() {
    if (!hasItems(this.props)) {
      return <div className='whiteBackground' />;
    }
    const { Title, Subtitle, 'Load More Label': LoadMoreLabel } = this.props.fields;

    return (
      <div className='whiteBackground'>
        <div className='ProductApplications-Wrapper componentContainer '>
          <HeadlineWithTitleAndSubtitle title={Title} subtitle={Subtitle} />
          <Row className='ProductApplications' nogutter>
            {this.getApplicationItems()}
            {this.getLoadMoreBtn(LoadMoreLabel)}
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    Applications: state.productDetails.masterProductData ? state.productDetails.masterProductData.Applications : null,
    variants: state.productDetails.variants ? state.productDetails.variants : null,
    ProductType: state.productDetails.masterProductData ? state.productDetails.masterProductData.ProductType : null
  };
};

const hasItems = function (props) {
  const { Applications, variants } = props,
    matchArticleNumber = getCurrentArticleNumber(props);

  if (
    (!matchArticleNumber &&
      ((Applications && Applications.length) ||
        (variants &&
          variants.length &&
          variants.some(element => element.applications && element.applications.length > 0)))) ||
    (matchArticleNumber &&
      variants &&
      variants.length &&
      variants.find(
        element =>
          element.articleNumber === matchArticleNumber.replace('-', '.') &&
          element.applications &&
          element.applications.length
      ))
  ) {
    return true;
  }
  return false;
};

export default connect(mapStateToProps)(withRouter(WithStickyMenu(ProductApplications, hasItems)));
