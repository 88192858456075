import i18n from 'i18next';
import { CoveoSitePipelinesType, CoveoSiteSearchHubsType, SearchFacet } from '../../../CoveoSettings';

export const CoveoProductsMassFlowDevicesPipelines: CoveoSitePipelinesType = {
  Axetris: 'SearchProduct',
  LeisterGroup: 'SearchProduct',
  LeisterTechnologies: 'SearchProduct',
  Weldy: 'SearchProduct'
};

export const CoveoProductsMassFlowDevicesSearchHubs: CoveoSiteSearchHubsType = {
  Axetris: 'Mass-Flow-Devices',
  LeisterGroup: 'Mass-Flow-Devices',
  LeisterTechnologies: 'Mass-Flow-Devices',
  Weldy: 'Mass-Flow-Devices'
};

export const MassFlowDevicesFacets: SearchFacet[] = [
  {
    field: 'pimsolutions',
    label: () => i18n.t('SEARCH | Facets pimsolutions')
  },
  {
    field: 'pimtargetgas',
    label: () => i18n.t('SEARCH | Facets pimtargetgas')
  }
];
