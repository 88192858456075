import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../../SearchResultList';
import { SearchEngineContext } from '../../../context/SearchEngineContext';
import { getCoveoProductsSource } from '../../../../../AppGlobals';
import FacetsModal from '../../../FacetsModal';
import FacetManager from '../../../FacetManager';
import { useCoveoQuery } from '../../../CoveoQuery';
import { SearchContext } from '../../../context/SearchContext';
import { buildNumericFacet } from '@coveo/headless';
import RangeFacet from '../../../RangeFacet';
import CategorFacet from '../../../CategoryFacet';
import { WeldingMachinesFacets } from './WeldyWeldingMachinesLister.settings';
import FloatingFilterToggle from '../../../FloatingFilterToggle';
import { CoveoContext } from '../../../context/CoveoContext';
import { FacetStickyWrapper } from '../../../FacetStickyWrapper/FacetStickyWrapper';

export const WeldyWeldingMachinesListerComponent: React.FC = () => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);
  const { state: searchState } = useContext(SearchContext);
  const coveoProductSource = getCoveoProductsSource();

  const advancedQuery = `@source=="${coveoProductSource}" AND @z95xlanguage=="${locale}" AND NOT @isphasedout=="true"`;

  const { buildFacetController, querySummaryController, breadcrumbManagerController } = useCoveoQuery({
    engine,
    cq: '@pimclassification=="WeldingMachines" AND @tenant="Weldy"',
    aq: advancedQuery
  });
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine, querySummaryController]);

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <div className='SearchResults__Main'>
        <FloatingFilterToggle />
        <FacetStickyWrapper>
          <FacetsModal breadcrumbManagerController={breadcrumbManagerController} />
          <FacetManager engine={engine} hasResults={state.hasResults || state.firstSearchExecuted}>
            {WeldingMachinesFacets.map(facet => {
              if (facet.numeric) {
                const facetController = buildNumericFacet(engine, {
                  options: {
                    field: facet.field,
                    generateAutomaticRanges: true,
                    facetId: facet.field,
                    injectionDepth: 1000,
                    numberOfValues: 5
                  }
                });

                return (
                  <RangeFacet
                    key={facet.field}
                    controller={facetController}
                    title={facet.label()}
                    id={facet.field}
                    precision={facet.precision}
                    unit={facet.unit || ''}
                  />
                );
              }
              const facetController = buildFacetController(facet.field);

              return (
                <CategorFacet
                  key={facet.field}
                  multi={facet.multi !== false}
                  controller={facetController}
                  title={facet.label()}
                  id={facet.field}
                />
              );
            })}
          </FacetManager>
        </FacetStickyWrapper>
        {(state.hasResults || state.firstSearchExecuted) && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList
          engine={engine}
          layout={searchState.layout}
          query={state.query}
          siteName={siteName}
          language={locale}
        />
      </div>
    </div>
  );
};

export default WeldyWeldingMachinesListerComponent;
