import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Row, Col } from 'react-grid-system';
import classNames from 'classnames';

const Quote = props => {
  if (!props.fields) {
    return <div className='Quote' />;
  }
  const cssClass = classNames('Quote-Row', {
    TopLine: props.fields['Top Border'].value === true,
    BottomLine: props.fields['Bottom Border'].value === true
  });

  return (
    <div className='whiteBackground'>
      <div className='componentContainerMobile'>
        <Row nogutter className={cssClass}>
          <Col lg={10} xl={10} xs={12} sm={12} md={12} className='Quote-Container'>
            <div className='Quote'>
              <Text field={props.fields.Title} tag='h2' className='Quote-Title' />
              <Text field={props.fields.Text} tag='p' className='Quote-Description' />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Quote;
