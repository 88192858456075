import React from 'react';
import { TextInputProps } from './types';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';

const TextInput = ({
  className,
  fieldSize = 'full',
  required = false,
  label = '',
  value,
  name,
  ...props
}: TextInputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element => {
  const [isActive, setIsActive] = React.useState(!!value);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsActive(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setIsActive(false);
    }
  };

  return (
    <div
      className={classNames(
        {
          'Form-Input-50': fieldSize === 'medium',
          'Form-Input-one-third': fieldSize === 'one-third',
          'Form-Input-two-thirds': fieldSize === 'two-thirds'
        },
        className
      )}
    >
      <div className={classNames('text-input', className)}>
        <label>
          <span
            className={classNames('text-input__label', {
              'text-input__label--active': isActive
            })}
          >
            {label}
            {required && <span className='text-input__required'>*</span>}
          </span>
          <input
            value={value}
            name={name}
            className='text-input__input'
            type='text'
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
          ></input>
        </label>
      </div>
      <ErrorMessage name={name} component='div' className='form-input-error' />
    </div>
  );
};

export default TextInput;
