import React from 'react';
import ShopIcon24 from '../../../Common/Icons/ShopIcon24';

export default function OpenCart({ quantity }: { quantity?: number }): JSX.Element {
  return (
    <div className='OpenCart' data-testid='open-cart-icon'>
      <ShopIcon24 color='black-80' size={24} />
      {quantity ? <span className='IconsMenu-heart-number'>{quantity}</span> : null}
    </div>
  );
}
