import React from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import LightboxVideo from '../../LightboxVideo/index';
import PlayIcon from '../../Icons/PlayIcon';
import classNames from 'classnames';

/**
 * @class Component that renders an image(left or right, with a play Button that opens LightBoxVideo component) and a rich text in the same row,
 *
 * @param {object} props Object containing the Text (for RichText), the Image,
 * the place of the image (RightImagePosition: true/false), the video and the Background Color of the entire component
 *
 * Returns jsx markup containing a container with an Image and a RichText in the same line.
 */

class VideoWithRichText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  /**
   * @method playVideo
   * @description displays video in an lightbox
   *
   */
  playVideo = () => {
    this.setState({ isOpen: true });
  };
  /**
   * @method closeVideo
   * @description close lightbox video
   *
   */
  closeVideo = () => {
    this.setState({ isOpen: false });
  };

  /**
   * @method showLightbox
   * @description renders LightboxVideo Component in the page according to state
   *
   */
  showLightbox = () => {
    const { Video, Title } = this.props.fields;
    if (this.state.isOpen === true) {
      return <LightboxVideo src={Video.value.src} onClick={this.closeVideo} title={Title.value} />;
    }
    return null;
  };
  render() {
    if (!this.props.fields) {
      return <div className='RightVideoWithRichText' />;
    }
    const {
      Text: Description,
      BackgroundColor,
      Image: Img,
      RightVideoPosition,
      Title,
      'Overline Title': Overline
    } = this.props.fields;

    // Img.value['src'] = Img.value.href

    const inlineStyle = {
      backgroundColor:
        BackgroundColor && BackgroundColor.fields.ColorCode.value !== '' ? BackgroundColor.fields.ColorCode.value : null
    };
    const cssClass = classNames({
      WhiteColorStyle: BackgroundColor && BackgroundColor.fields.ColorCode.value === '#000',
      RightVideoWithRichText: RightVideoPosition.value,
      LeftVideoWithRichText: !RightVideoPosition.value
    });

    const classByPosition = classNames({
      RightVideoWithRichText: RightVideoPosition.value,
      LeftVideoWithRichText: !RightVideoPosition.value
    });

    return (
      <div className='whiteBackground' style={inlineStyle}>
        <div className='componentContainerMobile'>
          <div className={cssClass}>
            <div
              className={classByPosition + '-VideoContainer'}
              onClick={this.playVideo}
              onKeyDown={e => {
                if (e.keyCode === 13) this.playVideo();
              }}
              role='button'
              tabIndex='0'
            >
              <Image field={Img} />
              <div className='VideoPlayButton'>
                <PlayIcon />
              </div>
              <div className='VideoDetails-Wrapper'>
                <div className='VideoDetails-Line' />
                <Text className='VideoDetails-Overline' field={Overline} tag='div' />
                <Text className='VideoDetails-Title' field={Title} tag='div' />
              </div>
            </div>
            {this.showLightbox()}
            <RichText className={classByPosition + '-Text'} field={Description} />
          </div>
        </div>
      </div>
    );
  }
}

export default VideoWithRichText;
