import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import SearchResultList from '../../SearchResultList';
import { SearchEngineContext } from '../../context/SearchEngineContext';
import { getCoveoSitecoreSource } from '../../../../AppGlobals';
import FacetsModal from '../../FacetsModal';
import FacetManager from '../../FacetManager';
import { CoveoEventBrands } from '../../CoveoSettings';
import { useCoveoQuery } from '../../CoveoQuery';
import CategorFacet from '../../CategoryFacet';
import FloatingFilterToggle from '../../FloatingFilterToggle';
import { CoveoContext } from '../../context/CoveoContext';
import sortEventDateOptions from './sortOptions.eventDate';
import convertEventDateFacetOptionLabel from './convertOptions.eventDate';

export const EventsListerComponent = () => {
  const {
    state: { engine }
  } = useContext(CoveoContext);
  const {
    state: { siteName, locale }
  } = useContext(SearchEngineContext);

  const { buildFacetController, querySummaryController } = useCoveoQuery({
    engine,
    aq: `@source=="${getCoveoSitecoreSource()}" AND @z95xlanguage=="${locale}"`,
    cq: `@eventz32xbrands=(${CoveoEventBrands[siteName]})`
  });
  const [state, setState] = useState(querySummaryController.state);

  useEffect(() => {
    engine.executeFirstSearch();

    return querySummaryController.subscribe(() => setState(querySummaryController.state));
  }, [engine]);

  const facets = [
    {
      field: 'eventz32xregion',
      label: i18n.t('SEARCH | Facets eventz32xregion')
    },
    {
      field: 'eventz32xcountry',
      label: i18n.t('SEARCH | Facets eventz32xcountry')
    },
    {
      field: 'eventz32xindustries',
      label: i18n.t('SEARCH | Facets eventz32xindustries')
    },
    {
      field: 'eventz32xtypes',
      label: i18n.t('SEARCH | Facets eventz32xtypes')
    },
    {
      field: 'eventz32xdatez32xasz32xyearz32xmonthz32xstring',
      label: i18n.t('SEARCH | Facets eventz32xdatez32xasz32xyearz32xmonthz32xstring'),
      sort: sortEventDateOptions,
      optionsLabelConverter: convertEventDateFacetOptionLabel
    }
  ];

  return (
    <div
      className={classNames({
        'SearchResults--executed': state.firstSearchExecuted
      })}
    >
      <div className='SearchResults__Main' data-testid='main'>
        <FloatingFilterToggle />
        <FacetsModal hideOnDesktop={true} foldable={false} />
        <FacetManager
          staticOnDesktop={true}
          engine={engine}
          hasResults={(state.hasResults || state.firstSearchExecuted) && !state.isLoading}
        >
          {facets.map(facet => {
            const facetController = buildFacetController(facet.field);

            return (
              <CategorFacet
                key={facet.field}
                controller={facetController}
                title={facet.label}
                id={facet.field}
                sort={facet.sort}
                optionsLabelConverter={facet.optionsLabelConverter}
              />
            );
          })}
        </FacetManager>
        {state.hasResults && (
          <div className='SearchResults__Header'>
            <span className='SearchResults__NumberOfResults' data-testid='number-of-results'>
              {state.total} {i18n.t('SEARCH | Total Results')}
            </span>
          </div>
        )}
        <SearchResultList engine={engine} layout='List' query={state.query} siteName={siteName} language={locale} />
      </div>
    </div>
  );
};

export default EventsListerComponent;
