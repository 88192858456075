export const createUrl = (pathname: string, params: URLSearchParams): string => {
  const paramsString = params.toString();
  const queryString = `${paramsString.length ? '?' : ''}${paramsString}`;

  return `${pathname}${queryString}`;
};

/**
 * @returns Returns a boolean indicating if the condition is "used" in the URL.
 */
export const getIsConditionUsedFromUrl = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return (new URLSearchParams(window.location?.search).get('condition') ?? '') === 'used';
};
