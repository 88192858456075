import React, { useEffect } from 'react';
import i18n from 'i18next';
import TextCell from './Cell/TextCell';
import AvailabilityCell from './Cell/Availability';
import BoldTextCell from './Cell/BoldText';
import PriceCell from './Cell/Price';
import { Money, ShopProduct, ShopProductVariant, ShopifyOrderFulfillmentStatus } from '../../Shopify/lib/shopify/types';
import classNames from 'classnames';
import ChevronUpSmall from '../Icons/ChevronUpSmall';
import ChevronDownSmall from '../Icons/ChevronDownSmall';
import MoneyCell from './Cell/Money';
import { FulfillmentStatusCell } from './Cell/FulfillmentStatus';
import ImageWithTextCell from './Cell/ImageWithText';
import { sortProductsByAvailability } from '../../Shopify/lib/shopify/leisterHelpers';

export type Column<T> = {
  key: keyof T;
  title: string;
  renderer?: ColumnRenderer;
  sortBy?: ColumnSortBy;
  sortKey?: keyof T;
  size?: CellSize;
  textBreak?: boolean;
  textAlign?: CellTextAlign;
  textVariant?: CellTextVariant;
};

export type ImageWithText = {
  text: string;
  imageUrl: string | null;
  imageAlt: string | null;
};

export type ColumnRenderer =
  | 'text'
  | 'bold-text'
  | 'availability'
  | 'price'
  | 'money'
  | 'date'
  | 'fulfillment-status'
  | 'image-with-text';
export type ColumnSortBy = 'alphabetical' | 'numerical' | 'date';
export type CellSize = 'regular' | 'large';
export type CellTextAlign = 'left' | 'center' | 'right';
export type CellTextVariant = 'regular' | 'primary';
export type ColumnSortDirection = 'asc' | 'desc';

export function Table<
  T extends {
    id: number | string;
    shopProduct?: ShopProduct;
    fulfillmentStatus?: ShopifyOrderFulfillmentStatus;
    tags?: string[];
    highlighted?: boolean;
    shopProductVariant?: ShopProductVariant;
  }
>({
  data,
  columns,
  onRowClick,
  equalWidthColumns = false
}: {
  data: T[];
  columns: Column<T>[];
  onRowClick?: (row: T) => void;
  equalWidthColumns?: boolean;
}): JSX.Element {
  const [sortState, setSortState] = React.useState<{
    [key: string]: ColumnSortDirection;
  }>({});
  const [sortedData, setSortedData] = React.useState<T[]>(data);

  useEffect(() => {
    if (Object.keys(sortState).length === 0) {
      setSortedData(data);
      return;
    }

    const sorted = [...data].sort((a, b) => {
      const key = Object.keys(sortState)[0] as keyof T;
      const direction = sortState[key as string];
      const column = columns.find(column => column.key === key);

      if (direction === 'asc' && column?.sortBy === 'alphabetical') {
        return (a[key] as string).localeCompare(b[key] as string);
      }

      if (direction === 'desc' && column?.sortBy === 'alphabetical') {
        return (b[key] as string).localeCompare(a[key] as string);
      }

      if (direction === 'asc' && column?.sortBy === 'numerical' && column?.renderer === 'price') {
        if (!a.shopProductVariant?.price?.amount) {
          return 1;
        }

        if (!b.shopProductVariant?.price?.amount) {
          return -1;
        }

        return parseFloat(a.shopProductVariant?.price?.amount) - parseFloat(b.shopProductVariant?.price?.amount);
      }

      if (direction === 'desc' && column?.sortBy === 'numerical' && column?.renderer === 'price') {
        if (!a.shopProductVariant?.price?.amount) {
          return 1;
        }

        if (!b.shopProductVariant?.price?.amount) {
          return -1;
        }

        return parseFloat(b.shopProductVariant?.price?.amount) - parseFloat(a.shopProductVariant?.price?.amount);
      }

      if (direction === 'asc' && column?.renderer === 'availability') {
        return sortProductsByAvailability(a.shopProductVariant, b.shopProductVariant);
      }

      if (direction === 'desc' && column?.renderer === 'availability') {
        return sortProductsByAvailability(b.shopProductVariant, a.shopProductVariant);
      }

      if (direction === 'asc' && column?.sortBy === 'numerical') {
        return parseFloat(a[key] as string) - parseFloat(b[key] as string);
      }

      if (direction === 'desc' && column?.sortBy === 'numerical') {
        return parseFloat(b[key] as string) - parseFloat(a[key] as string);
      }

      if (direction === 'asc' && column?.sortBy === 'date') {
        return Date.parse(a[key] as string) - Date.parse(b[key] as string);
      }

      if (direction === 'desc' && column?.sortBy === 'date') {
        return Date.parse(b[key] as string) - Date.parse(a[key] as string);
      }

      return 0;
    });

    setSortedData(sorted);
  }, [sortState, data, columns]);

  const getCellValue = (column: Column<T>, row: T) => {
    const value = row[column.key];

    switch (column.renderer) {
      case 'bold-text':
        return <BoldTextCell text={value as string} textVariant={column.textVariant} />;
      case 'availability':
        return row.shopProduct ? (
          <AvailabilityCell shopProduct={row.shopProduct} tags={row.tags} />
        ) : (
          <TextCell
            text={value as string}
            textAlign={column.textAlign}
            textBreak={column.textBreak}
            textVariant={column.textVariant}
          />
        );
      case 'price':
        return row.shopProductVariant?.price ? (
          <PriceCell textAlign={column.textAlign} shopProductVariant={row.shopProductVariant} />
        ) : (
          <TextCell text={value as string} textAlign={column.textAlign} textVariant={column.textVariant} />
        );
      case 'fulfillment-status':
        if (row.fulfillmentStatus !== undefined) {
          return <FulfillmentStatusCell fulfillmentStatus={row.fulfillmentStatus} />;
        }
        break;
      case 'money':
        return <MoneyCell money={value as Money} textAlign={column.textAlign} />;
      case 'date':
        return (
          <TextCell
            text={new Date(value as string).toLocaleDateString()}
            textAlign={column.textAlign}
            textVariant={column.textVariant}
          />
        );
      case 'image-with-text':
        return <ImageWithTextCell imageWithText={value as ImageWithText} />;
      default:
        return (
          <TextCell
            text={value as string}
            textAlign={column.textAlign}
            textBreak={column.textBreak}
            textVariant={column.textVariant}
          />
        );
    }
  };

  return (
    <table
      className={classNames('Table', {
        'Table--sticky-first': true,
        'Table--sticky-last': true
      })}
    >
      {equalWidthColumns && (
        <colgroup>
          {columns.map(column => (
            <col key={column.title} style={{ width: `${100 / columns.length}%` }} />
          ))}
        </colgroup>
      )}
      <thead>
        <tr>
          {columns.map(column => (
            <th key={column.title}>
              <div
                className={classNames('Table_header', {
                  'Table_header--large': column.size === 'large',
                  'Table_header--no-break': column.textBreak === false,
                  [`Table_header--${column.textAlign}`]: column.textAlign
                })}
              >
                {column.title}
                {column.sortBy && (
                  <div className='Table__sorting'>
                    <button
                      className={classNames('Table__sort', {
                        'Table__sort--active': sortState[column.key as string] === 'asc'
                      })}
                      onClick={() => setSortState({ [column.key as string]: 'asc' })}
                    >
                      <ChevronUpSmall />
                      <span className='visually-hidden'>{i18n.t('COMMON | Table sort asc')}</span>
                    </button>
                    <button
                      className={classNames('Table__sort', {
                        'Table__sort--active': sortState[column.key as string] === 'desc'
                      })}
                      onClick={() => setSortState({ [column.key as string]: 'desc' })}
                    >
                      <ChevronDownSmall />
                      <span className='visually-hidden'>{i18n.t('COMMON | Table sort desc')}</span>
                    </button>
                  </div>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, index) => (
          <tr
            key={`${row.id}${index}`}
            className={classNames({
              'Table__Row--highlighted': row.highlighted
            })}
            onClick={() => onRowClick?.(row)}
          >
            {columns.map(column => (
              <td key={`${column.title}${row.id}`}>{getCellValue(column, row)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
