import React from 'react';
import { ImageVideoHeroSlide } from '../types';
import { LinkField } from '@sitecore-jss/sitecore-jss-react/types/components/Link';
import classNames from 'classnames';
import Icon from '../../../Common/Icon';

interface TextBoxProps {
  title?: ImageVideoHeroSlide['title']['value'];
  overline?: ImageVideoHeroSlide['overline']['value'];
  linkText?: LinkField['value']['title'];
  className?: string;
}

const TextBox: React.FC<TextBoxProps> = ({ title = '', overline = '', linkText = false, ...rest }) => {
  const hasLink = linkText !== false;

  return (
    <div data-testid='text-box' {...rest} className={classNames('TextBox', rest.className, { 'has-link': hasLink })}>
      <div className='text-wrapper'>
        <span className='overline'>{overline}</span>
        <h1 className='title'>{title}</h1>
      </div>
      {hasLink && (
        <div className='link' data-testid='text-box-link'>
          <span className='link-text'>{linkText}</span>
          <Icon name='icon-web-16-arrow-right' color='white' className='text-box-link-icon' />
        </div>
      )}
    </div>
  );
};

export default TextBox;
