import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
/**
 *  Component that renders a link with an icon
 * @param  {object} props Object containing data needed for the component.
 */

const StoreLink = props => {
  const { SocialIcon, 'SocialIcon Hover': socialIconHover } = props.fields,
    hoverIcon = socialIconHover && socialIconHover.value.src ? socialIconHover : SocialIcon;

  if (!props.fields) {
    return <div className='StoreLink' />;
  }

  return (
    <div className='StoreLink'>
      <a href={props.fields.SocialLink.value.href} className='StoreLink-Link' target='blank'>
        <Image media={SocialIcon} className='StoreLink-Icon' />
        <Image media={hoverIcon} className='StoreLink-Icon-Hover' />
      </a>
    </div>
  );
};

export default StoreLink;
