import React from 'react';

type AlertBoxProps = {
  children: React.ReactNode;
};

const AlertBox: React.FC<AlertBoxProps> = props => {
  return <div className='AlertBox'>{props.children}</div>;
};

export default AlertBox;
